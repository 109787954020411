import configuredMachineActionTypes from "./actionTypes";
const INITIAL_STATE = {
  message: "",
  loading: false,
  configured_machines: [],
  configured_machine: []
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case configuredMachineActionTypes.SHOW_CONFIGURED_MACHINE_REQUEST:
      return {
        ...state,
        loading: true
      };

    case configuredMachineActionTypes.SHOW_CONFIGURED_MACHINE_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
        configured_machines: action.configured_machines
      };

    case configuredMachineActionTypes.SHOW_CONFIGURED_MACHINE_FALIURE:
      return {
        ...state,
        loading: false,
        message: action.message
      };

    default:
      return state;
  }
}
