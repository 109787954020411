const clientConfigurationActionTypes = {
  CLEAR_FORMS_REQUEST: "clear.forms.request",
  CLEAR_FORMS_SUCCESS: "clear.forms.success",
  CLEAR_FORMS_FALIURE: "clear.forms.faliure",

  CLEAR_MESSAGE_REQUEST: "clear.message.request",
  CLEAR_MESSAGE_SUCCESS: "clear.message.success",
  CLEAR_MESSAGE_FALIURE: "clear.message.faliure",

  CLEAR_SECOND_FORM_REQUEST: "clear.second.form.request",
  CLEAR_SECOND_FORM_SUCCESS: "clear.second.form.success",
  CLEAR_SECOND_FORM_FALIURE: "clear.second.form.faliure",

  SAVE_FIRST_FORM_REQUEST: "save.first.form.request",
  SAVE_FIRST_FORM_SUCCESS: "save.first.form.success",
  SAVE_FIRST_FORM_FALIURE: "save.first.form.faliure",

  SAVE_SECOND_FORM_REQUEST: "save.second.form.request",
  SAVE_SECOND_FORM_SUCCESS: "save.second.form.success",
  SAVE_SECOND_FORM_FALIURE: "save.second.form.faliure",

  SAVE_THIRD_FORM_REQUEST: "save.third.form.request",
  SAVE_THIRD_FORM_SUCCESS: "save.third.form.success",
  SAVE_THIRD_FORM_FALIURE: "save.third.form.faliure",

  SAVE_CONFIGURATION_REQUEST: "save.configuration.request",
  SAVE_CONFIGURATION_SUCCESS: "save.configuration.success",
  SAVE_CONFIGURATION_FALIURE: "save.configuration.faliure",

  CREATE_CONFIGURATION_REQUEST: "create.configuration.request",
  CREATE_CONFIGURATION_SUCCESS: "create.configuration.success",
  CREATE_CONFIGURATION_FALIURE: "create.configuration.faliure",

  EDIT_CONFIGURATION_REQUEST: "edit.configuration.request",
  EDIT_CONFIGURATION_SUCCESS: "edit.configuration.success",
  EDIT_CONFIGURATION_FALIURE: "edit.configuration.faliure",

  EDIT_LIBRARY_CONFIGURATION_REQUEST: "edit.library.configuration.request",
  EDIT_LIBRARY_CONFIGURATION_SUCCESS: "edit.library.configuration.success",
  EDIT_LIBRARY_CONFIGURATION_FALIURE: "edit.library.configuration.faliure",

  SHOW_USER_CONFIGURATIONS_REQUEST: "show.user.configurations.request",
  SHOW_USER_CONFIGURATIONS_SUCCESS: "show.user.configurations.success",
  SHOW_USER_CONFIGURATIONS_FALIURE: "show.user.configurations.faliure",

  SHOW_SINGLE_USER_CONFIGURATION_REQUEST:
    "show.single.user.configuration.request",
  SHOW_SINGLE_USER_CONFIGURATION_SUCCESS:
    "show.single.user.configuration.success",
  SHOW_SINGLE_USER_CONFIGURATION_FALIURE:
    "show.single.user.configuration.faliure",

  SHOW_SINGLE_LIBRARY_CONFIGURATION_REQUEST:
    "show.single.library.configuration.request",
  SHOW_SINGLE_LIBRARY_CONFIGURATION_SUCCESS:
    "show.single.library.configuration.success",
  SHOW_SINGLE_LIBRARY_CONFIGURATION_FALIURE:
    "show.single.library.configuration.faliure",

  SHOW_SINGLE_USER_CONFIGURATION_FOR_EDIT_REQUEST:
    "show.single.user.configuration.for.edit.request",
  SHOW_SINGLE_USER_CONFIGURATION_FOR_EDIT_SUCCESS:
    "show.single.user.configuration.for.edit.success",
  SHOW_SINGLE_USER_CONFIGURATION_FOR_EDIT_FALIURE:
    "show.single.user.configuration.for.edit.faliure",

  SHOW_SINGLE_LIBRARY_CONFIGURATION_FOR_EDIT_REQUEST:
    "show.single.library.configuration.for.edit.request",
  SHOW_SINGLE_LIBRARY_CONFIGURATION_FOR_EDIT_SUCCESS:
    "show.single.library.configuration.for.edit.success",
  SHOW_SINGLE_LIBRARY_CONFIGURATION_FOR_EDIT_FALIURE:
    "show.single.library.configuration.for.edit.faliure",

  GET_CONFIGURATION_FOR_EDITING_REQUEST:
    "get.configuration.for.editing.request",
  GET_CONFIGURATION_FOR_EDITING_SUCCESS:
    "get.configuration.for.editing.success",
  GET_CONFIGURATION_FOR_EDITING_FALIURE: "get.configuration.for.editing.faliure"
};
export default clientConfigurationActionTypes;
