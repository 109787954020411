import { Api } from "./../../config/api";

// api call to show all brands
function showAllBulletin() {
	let data = Api.get("bulletin").then(function(response) {
		return response.data;
	});

	if (data) {
		return data;
	} else {
		return false;
	}
}

//api call to show a single brand
function showSingleBulletin(param) {
	let data = Api.get("bulletin/" + param).then(function(response) {
		return response.data;
	});

	if (data) {
		return data;
	} else {
		return false;
	}
}

//api call to add a brand into the system
function createBulletin(params) {
	let data = Api.post("bulletin", params).then(function(response) {
		return response.data;
	});

	if (data) {
		return data;
	} else {
		return false;
	}
}

//api call to edit brand exisitng in the system
function updateBulletin(params) {
	let data = Api.post("bulletin/edit/" + params.get("id"), params).then(
		function(response) {
			return response.data;
		}
	);

	if (data) {
		return data;
	} else {
		return false;
	}
}

//api call to delete exisitng brand from the system
function deleteBulletin(params) {
	let data = Api.get("bulletin/delete/" + params).then(function(response) {
		return response.data;
	});

	if (data) {
		return data;
	} else {
		return false;
	}
}

//api call to edit brand exisitng in the system
function addOrUpdateBulletinForRole(id, params) {
	let data = Api.post(
		"bulletin/addOrUpdateBulletinForRole/" + id,
		params
	).then(function(response) {
		return response.data;
	});

	if (data) {
		return data;
	} else {
		return false;
	}
}

//api call to delete exisitng brand from the system
function getAllBulletinAccordingToRole(params) {
	let data = Api.get("bulletin/getAllBulletinAccordingToRole/" + params).then(
		function(response) {
			return response.data;
		}
	);

	if (data) {
		return data;
	} else {
		return false;
	}
}
function getCurrentBulletinForRole(params) {
	let data = Api.get("bulletin/getCurrentBulletinForRole/" + params).then(
		function(response) {
			return response.data;
		}
	);

	if (data) {
		return data;
	} else {
		return false;
	}
}

export const BulletinService = {
	showAllBulletin,
	showSingleBulletin,
	createBulletin,
	updateBulletin,
	deleteBulletin,
	getAllBulletinAccordingToRole,
    addOrUpdateBulletinForRole,
    getCurrentBulletinForRole
};
