import { Api } from "./../../config/api";

function showOrders(user) {
  let data = Api.get("order/user/" + user)
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {});

  if (data) {
    return data;
  } else {
    return false;
  }
}

function showOrdersSalesSupport(user) {
  let data = Api.get("sales-support/getAllOrdersSalesSupport/" + user)
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {});

  if (data) {
    return data;
  } else {
    return false;
  }
}

function showSingleOrder(user, params) {
  let data = Api.get("order/user/" + user + "/" + params)
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {});

  if (data) {
    return data;
  } else {
    return false;
  }
}

function showSingleOrderSales(user, params) {
  let data = Api.get(
    "sales-support/getSingleOrderSalesSupport/" + user + "/" + params
  )
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {});

  if (data) {
    return data;
  } else {
    return false;
  }
}

function createOrder(params) {
  let data = Api.post("order", params)
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {});

  if (data) {
    return data;
  } else {
    return false;
  }
}

function showChildrenOrders(user) {
  let data = Api.get("user-hierarchy/showChildrenOrders/" + user)
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {});

  if (data) {
    return data;
  } else {
    return false;
  }
}

function fetchDocumentS3(document_name) {
  let data = Api.post("quote/download-doc",{url: document_name})
      .then(function (response) {
          return response.data;
      })
      .catch(function (error) {
          return false;
      });

  if (data) {
      return data;
  } else {
      return false;
  }
}

export const ClientOrderService = {
  showOrders,
  showChildrenOrders,
  showOrdersSalesSupport,
  showSingleOrderSales,
  showSingleOrder,
  createOrder,
  fetchDocumentS3
};
