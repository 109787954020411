import React, { Component } from "react";
import { show_machine_parts, clean_message } from "./../action";
import { connect } from "react-redux";
import { MachinePartService } from "./../service";
import { Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Tooltip, notification } from "antd";

class ListMachinePartOnly extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			search: "",
			machine_part_list: false,
			parts: false
		};
		this.onSearch = this.onSearch.bind(this);
		this.onDelete = this.onDelete.bind(this);
	}

	async onDelete(id) {
		let confirmation = window.confirm("Are you sure?");

		if (confirmation) {
			let data = await MachinePartService.deleteMachineParts(id);

			if (data.message.includes("success")) {
				notification.success({
					message: "Success!",
					description: data.message
				});
			} else {
				notification.error({
					message: "Error!",
					description: data.message
				});
			}

			let data1 = await MachinePartService.showMachineParts();
			this.setState({ machine_part_list: data1 });
			this.setState({ parts: data1 });
		}
	}

	onSearch(name, value) {
		let redirect = this;
		switch (name) {
			case "search":
				redirect.setState({ search: value });
				let parts = redirect.state.machine_part_list;
				parts = parts.filter(
					item =>
						item.machine_part_name.toLowerCase().includes(value) ||
						item.machine_part_model_number.toLowerCase().includes(value)
				);

				redirect.setState({ parts: parts });
				break;

			default:
				break;
		}
	}

	async componentDidMount() {
		let redirect = this;

		let data = await MachinePartService.showMachinePartsOnly();
		redirect.setState({ machine_part_list: data });
		redirect.setState({ parts: data });

		if (
			redirect.props.message !== "" &&
			redirect.props.message !== undefined
		) {
			if (redirect.props.message.toLowerCase().includes("success")) {
				notification.success({
					message: "Success!",
					description: redirect.props.message
				});
			} else {
				notification.error({
					message: "Error!",
					description: redirect.props.message
				});
			}
		}

		await redirect.props.clean_message();
		redirect.setState({ loading: false });
	}

	render() {
		if (this.state.parts) {
			return (
				<div>
					<div
						className="content"
						style={{
							minHeight: 500
						}}
					>
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-header">
										<strong className="card-title">
											Machine Parts
										</strong>
									</div>
									<div className="card-body">
										<div className="card-content">
											<div className="todo-list">
												<div className="tdl-holder">
													<div className="tdl-content">
														<div className="row">
															<div className="col-sm-6"></div>
															<div className="col-sm-2"></div>
															<div className="col-sm-4">
																<div className="form-group">
																	<input
																		name="search"
																		className="form-control"
																		id="search"
																		placeholder="Filter Parts"
																		value={this.state.search}
																		onChange={e =>
																			this.onSearch(
																				"search",
																				e.target.value
																			)
																		}
																	/>
																</div>
															</div>
														</div>
														<ul>
															{this.state.parts.map(
																(machine_part, i) => (
																	<li key={i}>
																		<label
																			style={{
																				color: "black"
																			}}
																		>
																			<div className="row">
																				<div className="col-sm-8">
																					{"[" +
																						machine_part.machine_part_model_number +
																						"]\t-\t" +
																						machine_part.machine_part_name}
																				</div>
																				<div className="col-sm-3 d-flex flex-row justify-content-end">
																					<div
																						className="d-flex flex-row"
																						style={{
																							gap: "1rem"
																						}}
																					>
																						<Link
																							to={
																								"/update-machine-part-only/" +
																								machine_part.id
																							}
																						>
																							<i className="fa text-dark fa-pencil"></i>
																						</Link>

																						<Tooltip
																							title={
																								machine_part.discount_applicable
																									? "Discount is applied in this part"
																									: "Discount is not applied in this part (Fixed Price)"
																							}
																						>
																							<p>
																								{machine_part.discount_applicable ? (
																									<i className="fa fa-percent"></i>
																								) : (
																									"Discount not Applied"
																								)}
																							</p>
																						</Tooltip>
																					</div>
																				</div>
																			</div>
																		</label>
																	</li>
																)
															)}
														</ul>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div>
					<div
						className="content"
						style={{
							minHeight: 500
						}}
					>
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-header">
										<strong className="card-title">
											Machine Parts
										</strong>
									</div>
									<div className="card-body">
										<div className="card-content">
											<div className="todo-list">
												<div className="tdl-holder">
													<div className="tdl-content">
														<div className="row">
															<div className="col"></div>
															<div className="col">
																<div className="text-center">
																	<Loader
																		type="Rings"
																		color="#00BFFF"
																		height={100}
																		width={100}
																	/>
																	<div className="text-center client-roboto-condensed">
																		LOADING...
																	</div>
																</div>
															</div>
															<div className="col"></div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}
}
const mapStateToProps = state => {
	return {
		machine_parts: state.showMachinePartReducer.machine_parts,
		message: state.showMachinePartReducer.message
	};
};

export default connect(mapStateToProps, { show_machine_parts, clean_message })(
	ListMachinePartOnly
);
