const clientQuoteActionTypes = {
  SAVE_QUOTE_FIRST_FORM_REQUEST: "save.quote.first.form.request",
  SAVE_QUOTE_FIRST_FORM_SUCCESS: "save.quote.first.form.success",
  SAVE_QUOTE_FIRST_FORM_FALIURE: "save.quote.first.form.faliure",

  SAVE_QUOTE_SECOND_FORM_REQUEST: "save.quote.second.form.request",
  SAVE_QUOTE_SECOND_FORM_SUCCESS: "save.quote.second.form.success",
  SAVE_QUOTE_SECOND_FORM_FALIURE: "save.quote.second.form.faliure",

  SAVE_QUOTE_THIRD_FORM_REQUEST: "save.quote.third.form.request",
  SAVE_QUOTE_THIRD_FORM_SUCCESS: "save.quote.third.form.success",
  SAVE_QUOTE_THIRD_FORM_FALIURE: "save.quote.third.form.faliure",

  SHOW_QUOTES_REQUEST: "show.quotes.request",
  SHOW_QUOTES_SUCCESS: "show.quotes.success",
  SHOW_QUOTES_FALIURE: "show.quotes.faliure",

  SHOW_SINGLE_QUOTE_REQUEST: "show.single.quote.request",
  SHOW_SINGLE_QUOTE_SUCCESS: "show.single.quote.success",
  SHOW_SINGLE_QUOTE_FALIURE: "show.single.quote.faliure",

  CREATE_QUOTE_REQUEST: "create.quote.request",
  CREATE_QUOTE_SUCCESS: "create.quote.success",
  CREATE_QUOTE_FALIURE: "create.quote.faliure",

  EDIT_QUOTE_REQUEST: "edit.quote.request",
  EDIT_QUOTE_SUCCESS: "edit.quote.success",
  EDIT_QUOTE_FALIURE: "edit.quote.faliure",

  GET_DATA_FOR_EDITING_REQUEST: "get.data.for.editing.request",
  GET_DATA_FOR_EDITING_SUCCESS: "get.data.for.editing.success",
  GET_DATA_FOR_EDITING_FALIURE: "get.data.for.editing.faliure",

  ORDER_QUOTE_REQUEST: "order.quote.request",
  ORDER_QUOTE_SUCCESS: "order.quote.success",
  ORDER_QUOTE_FALIURE: "order.quote.faliure",

  DOWNLOAD_XLSX_REQUEST: "download.xlsx.request",
  DOWNLOAD_XLSX_SUCCESS: "download.xlsx.success",
  DOWNLOAD_XLSX_FALIURE: "download.xlsx.faliure",

  DOWNLOAD_DOCX_REQUEST: "download.docx.request",
  DOWNLOAD_DOCX_SUCCESS: "download.docx.success",
  DOWNLOAD_DOCX_FALIURE: "download.docx.faliure",

  CLEAN_MESSAGE_REQUEST: "clean.message.request",
  CLEAN_MESSAGE_SUCCESS: "clean.message.success",
  CLEAN_MESSAGE_FALIURE: "clean.message.faliure",

  CLEAN_FORM_REQUEST: "clean.forms.request",
  CLEAN_FORM_SUCCESS: "clean.forms.success",
  CLEAN_FORM_FALIURE: "clean.frms.faliure"
};
export default clientQuoteActionTypes;
