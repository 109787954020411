import React, { Component } from "react";
import { add_year } from "./../action";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

class AddYear extends Component {
  constructor(props) {
    super(props);
    this.state = {
      year: ""
    };
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.onYearChange = this.onYearChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(e) {
    e.preventDefault();
    let redirect = this;

    if (this.validator.allValid()) {
      let params = {
        year: redirect.state.year
      };

      this.props.add_year(params);
    } else {
      this.validator.showMessages();
    }
  }

  onYearChange(name, value) {
    switch (name) {
      case "year":
        this.setState({ year: value });
        break;

      default:
        break;
    }
  }

  componentDidMount() {}

  render() {
    if (this.props.message.includes("Adding Year Success")) {
      return <Redirect to="/year" />;
    } else {
      if (this.props.loading) {
        return (
          <div>
            <div
              className="content"
              style={{
                minHeight: 500
              }}
            >
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <strong className="card-title">
                        Add Year Data for Yearly Price
                      </strong>
                    </div>
                    <div className="card-body">
                      <div className="card-content">
                        <div className="todo-list">
                          <div className="tdl-holder">
                            <div className="tdl-content">
                              <div className="row">
                                <div className="col"></div>
                                <div className="col">
                                  <div className="text-center">
                                    <Loader
                                      type="Rings"
                                      color="#00BFFF"
                                      height={100}
                                      width={100}
                                    />
                                    <div className="text-center client-roboto-condensed">
                                      LOADING...
                                    </div>
                                  </div>
                                </div>
                                <div className="col"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div>
            <div
              className="content"
              style={{
                minHeight: 500
              }}
            >
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <strong className="card-title">
                        Add Year Data for Yearly Price
                      </strong>
                    </div>
                    <div className="card-body">
                      <form>
                        <div className="form-group">
                          <label htmlFor="year" className="control-label mb-1">
                            Year
                          </label>
                          <input
                            id="year"
                            name="year"
                            type="text"
                            className="form-control"
                            aria-required="true"
                            onChange={e =>
                              this.onYearChange("year", e.target.value)
                            }
                            value={this.state.year}
                          />
                          <div
                            className="mt-4"
                            style={{
                              color: "red"
                            }}
                          >
                            {this.validator.message(
                              "year",
                              this.state.year,
                              "required|numeric"
                            )}
                          </div>
                        </div>

                        <div>
                          <button
                            onClick={this.onSubmit}
                            id="payment-button"
                            type="submit"
                            className="btn btn-secondary btn-block"
                          >
                            <i className="fa fa-truck"></i>&nbsp;
                            <span id="payment-button-amount">Add Year</span>
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
  }
}

const mapStateToProps = state => {
  return {
    year: state.yearReducer.year,
    message: state.yearReducer.message,
    loading: state.yearReducer.loading
  };
};

export default connect(mapStateToProps, { add_year })(AddYear);
