import machineCategoryActionTypes from "./actionTypes";
import { MachineCategoryService } from "./service";
import { take, call, put } from "redux-saga/effects";

function* showMachineCategories() {
  while (true) {
    yield take(machineCategoryActionTypes.SHOW_MACHINE_CATEGORY_REQUEST);

    const machine_categories = yield call(
      MachineCategoryService.showMachineCategories
    );

    if (
      machine_categories !== "" &&
      machine_categories !== null &&
      machine_categories
    ) {
      yield put({
        type: machineCategoryActionTypes.SHOW_MACHINE_CATEGORY_SUCCESS,
        message: "Getting Machine Category Success",
        machine_categories: machine_categories
      });
    } else {
      yield put({
        type: machineCategoryActionTypes.SHOW_MACHINE_CATEGORY_FALIURE,
        message: "Getting Machine Category Faliure"
      });
    }
  }
}

function* addMachineCategories(params) {
  while (true) {
    const { params } = yield take(
      machineCategoryActionTypes.ADD_MACHINE_CATEGORY_REQUEST
    );

    const data = yield call(
      MachineCategoryService.addMachineCategories,
      params
    );

    if (data !== "" && data !== null && data && data !== undefined) {
      yield put({
        type: machineCategoryActionTypes.ADD_MACHINE_CATEGORY_SUCCESS,
        message: "Adding Machine Category Success"
      });
    } else {
      yield put({
        type: machineCategoryActionTypes.ADD_MACHINE_CATEGORY_FALIURE,
        message: "Adding Machine Category Faliure"
      });
    }
  }
}

function* editMachineCategories(params) {
  while (true) {
    const { params } = yield take(
      machineCategoryActionTypes.EDIT_MACHINE_CATEGORY_REQUEST
    );

    const data = yield call(
      MachineCategoryService.editMachineCategories,
      params
    );

    if (data !== "" && data !== null && data) {
      yield put({
        type: machineCategoryActionTypes.EDIT_MACHINE_CATEGORY_SUCCESS,
        message: "Editing Machine Category Success"
      });
    } else {
      yield put({
        type: machineCategoryActionTypes.EDIT_MACHINE_CATEGORY_FALIURE,
        message: "Editing Machine Category Faliure"
      });
    }
  }
}

function* deleteMachineCategories(params) {
  while (true) {
    const { params } = yield take(
      machineCategoryActionTypes.DELETE_MACHINE_CATEGORY_REQUEST
    );

    const data = yield call(
      MachineCategoryService.deleteMachineCategories,
      params
    );

    if (data !== "" && data !== null && data) {
      yield put({
        type: machineCategoryActionTypes.DELETE_MACHINE_CATEGORY_SUCCESS,
        message: "Deleting Machine Category Success"
      });
    } else {
      yield put({
        type: machineCategoryActionTypes.DELETE_MACHINE_CATEGORY_FALIURE,
        message: "Deleting Machine Category Faliure"
      });
    }
  }
}

function* cleanMachineCategoryMessage() {
  while (true) {
    yield take(machineCategoryActionTypes.CLEAN_MESSAGE_REQUEST);

    yield put({
      type: machineCategoryActionTypes.CLEAN_MESSAGE_SUCCESS
    });
  }
}

export {
  showMachineCategories,
  cleanMachineCategoryMessage,
  addMachineCategories,
  editMachineCategories,
  deleteMachineCategories
};
