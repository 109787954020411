import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { css } from "@emotion/react";
import { PulseLoader } from "react-spinners";
import { Api } from "./../../config/api";
import Swal from "sweetalert2";
import SimpleReactValidator from "simple-react-validator";
import { logout } from "./../action";

class PasswordReset extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			email: "",
			password: "",
			password_confirmation: "",
			langauge_id: "",
			token: "",
			countries: [],
			selected_country: "",
			message: "",
			redirectToLogin: false
		};
		this.validator = new SimpleReactValidator();
		this.onTextChange = this.onTextChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	onTextChange(name, value) {
		let redirect = this;
		switch (name) {
			case "email":
				redirect.setState({ email: value });
				break;

			case "password":
				redirect.setState({ password: value });
				break;

			case "password_confirmation":
				redirect.setState({ password_confirmation: value });
				break;

			default:
				break;
		}
	}

	async onSubmit(e) {
		e.preventDefault();
		this.setState({ loading: true });
		let that = this;

		if (this.validator.allValid()) {
			if (this.state.password === this.state.password_confirmation) {
				let params = {
					email: this.state.email,
					password: this.state.password,
					password_confirmation: this.state.password_confirmation,
					token: this.state.token
				};

				await Api.post("/password/reset", params)
					.then(function(response) {
						that.setState({ message: response.data });
						that.setState({ email: "" });
						that.setState({ password_confirmation: "" });
						that.setState({ password: "" });
						that.setState({ redirectToLogin: true });

						Swal.fire({
							title: "Password reset successful!",
							text:
								"Password resetting successful! Please proceed to login with new password",
							icon: "success",
							confirmButtonText: "OK"
						});
					})
					.catch(function(error) {
						Swal.fire({
							title: "Password reset failed!",
							text: "Password resetting failed! Please try again",
							icon: "error",
							confirmButtonText: "OK"
						});
					});
			} else {
				Swal.fire({
					title: "Password Information",
					text:
						"Your passwords do not match, please try again with matching passwords!!",
					icon: "info",
					confirmButtonText: "OK"
				});
			}
		} else {
			this.validator.showMessages();
			this.forceUpdate();
		}

		this.setState({ loading: false });
	}

	async componentDidMount() {
		if (
			this.props.loggedInToken &&
			this.props.loggedInToken !== "" &&
			this.props.loggedInToken !== null &&
			this.props.loggedInToken !== undefined
		)
			await this.props.logout(this.props.loggedInToken);
		let { token } = this.props.match.params;
		await this.setState({ token: token });

		await this.setState({ loading: false });
	}

  render() {
    const override = css`
      display: block;
      margin: 0 auto;
      border-color: red;
    `;
    if (this.state.redirectToLogin) {
      return <Redirect to="/" />;
    } else {
      if (this.props.loading) {
        return (
          <div className="sweet-loading">
            <div className="container loading-container">
              <div className="row m-5 p-5">
                <PulseLoader
                  css={override}
                  sizeUnit={"px"}
                  size={24}
                  color={"#B9373D"}
                />
              </div>
            </div>
          </div>
        );
      } else {
        if (this.state.loading) {
          return (
            <div className="sweet-loading">
              <div className="container loading-container">
                <div className="row m-5 p-5">
                  <PulseLoader
                    css={override}
                    sizeUnit={"px"}
                    size={24}
                    color={"#B9373D"}
                  />
                </div>
              </div>
            </div>
          );
        } else {
          return (
            <div>
              <div className="mt-4 mb-4 roboto-condensed container">
                <div className="row">
                  <div className="col"></div>
                  <div className="col-8">
                    <div className="text-center">
                      <div className="content-section introduction">
                        <div className="feature-intro">
                          <h3>Password Reset | Reesink UK CPQ</h3>
                        </div>
                      </div>
                      <div className="row m-5">
                        <div className="col"></div>
                        <div className="col-6">
                          <form>
                            <div className="form-group">
                              <label>E-mail Address</label>
                              <input
                                type="text"
                                value={this.state.email}
                                onChange={e =>
                                  this.onTextChange("email", e.target.value)
                                }
                                className="form-control"
                                placeholder="Enter E-mail"
                              />
                              <div
                                className="mt-4"
                                style={{
                                  color: "red"
                                }}
                              >
                                {this.validator.message(
                                  "Email",
                                  this.state.email,
                                  "required|string|email"
                                )}
                              </div>
                            </div>
                            <div className="form-group">
                              <label>Password</label>
                              <input
                                type="password"
                                value={this.state.password}
                                onChange={e =>
                                  this.onTextChange("password", e.target.value)
                                }
                                className="form-control"
                                placeholder="Enter Password"
                              />
                              <div
                                className="mt-4"
                                style={{
                                  color: "red"
                                }}
                              >
                                {this.validator.message(
                                  "Password",
                                  this.state.password,
                                  "required|string|min:8|max:32"
                                )}
                              </div>
                            </div>
                            <div className="form-group">
                              <label>Confirm Password</label>
                              <input
                                type="password"
                                value={this.state.password_confirmation}
                                onChange={e =>
                                  this.onTextChange(
                                    "password_confirmation",
                                    e.target.value
                                  )
                                }
                                className="form-control"
                                placeholder="Confirm Password"
                              />
                              <div
                                className="mt-4"
                                style={{
                                  color: "red"
                                }}
                              >
                                {this.validator.message(
                                  "Confirmatio Password",
                                  this.state.password_confirmation,
                                  "required|string|min:8|max:32"
                                )}
                              </div>
                            </div>
                            {/* <div className="text-center mb-4">
                              <Link className="red-link" to="/login">
                                Login
                              </Link>
                            </div> */}
                            <div>
                              <button
                                type="submit"
                                onClick={this.onSubmit}
                                className="roboto-condensed client-login m-2"
                              >
                                Change Password
                              </button>
                            </div>
                          </form>
                        </div>
                        <div className="col"></div>
                      </div>
                    </div>
                  </div>
                  <div className="col"></div>
                </div>
              </div>
            </div>
          );
        }
      }
    }
  }
}
const mapStateToProps = state => {
	return {
		loggedInToken: state.loginReducer.token
	};
};

export default connect(mapStateToProps, { logout })(PasswordReset);
