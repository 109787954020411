import React, { Component } from "react";
import { edit_machine_parts_only } from "./../action";
import { MachinePartService } from "../service";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { CountryService } from "./../../country/service";
import SimpleReactValidator from "simple-react-validator";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Api } from "./../../../config/api";
import config from "./../../../config/config";
import { BrandService } from "./../../brand/service";
import Swal from "sweetalert2";

class UpdateMachinePartOnly extends Component {
	constructor(props) {
		super(props);

		this.state = {
			data: false,
			loading: true,
			loaded: false,
			machine_part_model_number: "",
			machine_part_name: "",
			machine_part_type: "",
			machine_part_note: "",
			machine_part_image: null,
			machine_part_remarks: "",
			machine_part_priority: 1,
			is_visible: 1,
			is_included: 0,
			parent_model_number: [],
			negative_parent_model_number: [],
			countries: [],
			country: "",
			keys: [],
			tabs: [],
			current_image: null,
			compatible_machine_categories: false,
			brands: false,
			brand: "",
		};
		this.validator = new SimpleReactValidator({ autoForceUpdate: this });
		this.onTextChange = this.onTextChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.handleFileChange = this.handleFileChange.bind(this);
		this.removeImage = this.removeImage.bind(this);
	}

	async removeImage(value) {
		let result1 = await Swal.fire({
			title: "Are you sure?",
			text: "Do you want delete this image?",
			icon: "info",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes!"
		}).then(result => {
			return result;
		});

		if (result1.value) {
			this.setState({ loading: true });
			let result = await Api.get(
				"machine-parts/removeMachinePartImage/" + value
			)
				.then(function(response) {
					return true;
				})
				.catch(function(error) {
					return false;
				});

			if (result) {
				let id = this.props.match.params.id;
				let redirect = this;

				let machine_part = await MachinePartService.showSingleMachinePartOnly(
					id
				);

				let brands = await BrandService.showBrands();
				await redirect.setState({ brands: brands });

				let countries = await CountryService.showCountries();

				await redirect.setState({ countries: countries });

				await redirect.setState({ country: machine_part[0].country_id });
				await redirect.setState({ brand: machine_part[0].brand_id });
				await redirect.setState({
					machine_part_model_number:
						machine_part[0].machine_part_model_number
				});
				await redirect.setState({
					machine_part_name: machine_part[0].machine_part_name
				});
				await redirect.setState({
					machine_part_type: machine_part[0].machine_part_type
				});
				if (machine_part[0].machine_part_note !== null) {
					await redirect.setState({
						machine_part_note: machine_part[0].machine_part_note
					});
				}
				if (machine_part[0].machine_part_remarks !== null) {
					await redirect.setState({
						machine_part_remarks: machine_part[0].machine_part_remarks
					});
				}
				await redirect.setState({
					machine_part_priority: machine_part[0].machine_part_priority
				});
				if (machine_part[0].parent_model_number !== null) {
					await redirect.setState({
						parent_model_number: machine_part[0].parent_model_number
					});
				}

				if (machine_part[0].negative_parent_model_number !== null) {
					await redirect.setState({
						negative_parent_model_number:
							machine_part[0].negative_parent_model_number
					});
				}

				await redirect.setState({
					current_image: machine_part[0].machine_part_image
				});
				await redirect.setState({ is_visible: machine_part[0].is_visible });
				await redirect.setState({
					is_included: machine_part[0].is_included
				});
				await redirect.setState({
					year_price_count: machine_part[0].year_price_count
				});

			}
			this.setState({ loading: false });
		}
	}

	handleFileChange(event) {
		this.setState({ machine_part_image: event.target.files[0] });
	}

	onSubmit(e) {
		e.preventDefault();
		let { id } = this.props.match.params;
		let redirect = this;

		if (this.validator.allValid()) {
			let data = new FormData();
			data.append(
				"machine_part_model_number",
				redirect.state.machine_part_model_number
			);
			data.append("machine_part_name", redirect.state.machine_part_name);
			data.append("machine_part_type", redirect.state.machine_part_type);
			data.append("machine_part_note", redirect.state.machine_part_note);
			data.append(
				"machine_part_remarks",
				redirect.state.machine_part_remarks
			);
			data.append(
				"machine_part_priority",
				redirect.state.machine_part_priority
			);
			data.append("is_visible", redirect.state.is_visible);
			data.append("is_included", redirect.state.is_included);
			data.append("parent_model_number", redirect.state.parent_model_number);
			data.append(
				"negative_model_number",
				redirect.state.negative_parent_model_number
			);
			data.append("brand_id", redirect.state.brand);
			// data.append("country_id", redirect.state.country);

			if (this.state.category_image !== null) {
				data.append("file", this.state.machine_part_image);
			}
			this.props.edit_machine_parts_only(data, id);
		} else {
			this.validator.showMessages();
		}
	}

	onTextChange(name, value) {
		switch (name) {
			case "machine_part_model_number":
				this.setState({ machine_part_model_number: value });
				break;

			case "machine_part_name":
				this.setState({ machine_part_name: value });
				break;

			case "machine_part_type":
				this.setState({ machine_part_type: value });
				break;

			case "machine_part_note":
				this.setState({ machine_part_note: value });
				break;

			case "machine_part_remarks":
				this.setState({ machine_part_remarks: value });
				break;

			case "machine_part_priority":
				this.setState({ machine_part_priority: value });
				break;

			case "is_included":
				this.setState({ is_included: value });
				break;

			case "is_visible":
				this.setState({ is_visible: value });
				break;

			case "parent_model_number":
				this.setState({ parent_model_number: value });
				break;

			case "negative_parent_model_number":
				this.setState({ negative_parent_model_number: value });
				break;

			case "price":
				this.setState({ price: value });
				break;

			case "year":
				this.setState({ year: value });
				break;

			case "country":
				this.setState({ country: value });
				break;

			case "brand":
				this.setState({ brand: value });
				break;

			default:
				break;
		}
	}

	async componentDidMount() {
		let id = this.props.match.params.id;
		let redirect = this;

		let machine_part = await MachinePartService.showSingleMachinePartOnly(id);

		let brands = await BrandService.showBrands();
		await redirect.setState({ brands: brands });

		let countries = await CountryService.showCountries();

		await redirect.setState({ countries: countries });

		await redirect.setState({ country: machine_part[0].country_id });
		await redirect.setState({ brand: machine_part[0].brand_id });
		await redirect.setState({
			machine_part_model_number: machine_part[0].machine_part_model_number
		});
		await redirect.setState({
			machine_part_name: machine_part[0].machine_part_name
		});
		await redirect.setState({
			machine_part_type: machine_part[0].machine_part_type
		});
		if (machine_part[0].machine_part_note !== null) {
			await redirect.setState({
				machine_part_note: machine_part[0].machine_part_note
			});
		}
		if (machine_part[0].machine_part_remarks !== null) {
			await redirect.setState({
				machine_part_remarks: machine_part[0].machine_part_remarks
			});
		}
		await redirect.setState({
			machine_part_priority: machine_part[0].machine_part_priority
		});
		if (machine_part[0].parent_model_number !== null) {
			await redirect.setState({
				parent_model_number: machine_part[0].parent_model_number
			});
		}

		if (machine_part[0].negative_parent_model_number !== null) {
			await redirect.setState({
				negative_parent_model_number:
					machine_part[0].negative_parent_model_number
			});
		}

		await redirect.setState({
			current_image: machine_part[0].machine_part_image
		});
		await redirect.setState({ is_visible: machine_part[0].is_visible });
		await redirect.setState({ is_included: machine_part[0].is_included });
		await redirect.setState({
			year_price_count: machine_part[0].year_price_count
		});

		let compatible_machine_categories = await Api.get(
			"getCompatibleMachineCategories/" + id
		)
			.then(function(response) {
				return response.data;
			})
			.catch(function(error) {
				return false;
			});

		await redirect.setState({
			compatible_machine_categories: compatible_machine_categories
		});

		let machine_parts = await MachinePartService.getMachinePartsForParentSelectionWithId(
			id
		);
		await redirect.setState({ data: machine_parts });

		redirect.setState({ loading: false });
	}

	render() {
		if (this.props.message.includes("Editing machine parts only success")) {
			return <Redirect to="/machine-part" />;
		} else {
			if (this.state.loading || this.props.loading) {
				return (
					<div>
						<div
							className="content"
							style={{
								minHeight: 500
							}}
						>
							<div className="row">
								<div className="col-sm-12">
									<div className="card">
										<div className="card-header">
											<strong className="card-title">
												Update Machine Parts
											</strong>
										</div>
										<div className="card-body">
											<div className="card-content">
												<div className="todo-list">
													<div className="tdl-holder">
														<div className="tdl-content">
															<div className="row">
																<div className="col"></div>
																<div className="col">
																	<div className="text-center">
																		<Loader
																			type="Rings"
																			color="#00BFFF"
																			height={100}
																			width={100}
																		/>
																		<div className="text-center client-roboto-condensed">
																			LOADING...
																		</div>
																	</div>
																</div>
																<div className="col"></div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				);
			} else {
				return (
					<div>
						<div
							className="content"
							style={{
								minHeight: 500
							}}
						>
							<div className="row">
								<div className="col-sm-12">
									<div className="card">
										<div className="card-header">
											<strong className="card-title">
												Update Machine Parts
											</strong>
										</div>
										<div className="card-body">
											<form>
												<div className="row">
													<div className="col-sm-6">
														<div className="form-group">
															<label
																htmlFor="machine_part_model_number"
																className="text-center control-label mb-1"
															>
																Machine Part Model Number
															</label>
															<input
																id="machine_part_model_number"
																name="machine_part_model_number"
																type="text"
																className="form-control"
																onChange={e =>
																	this.onTextChange(
																		"machine_part_model_number",
																		e.target.value
																	)
																}
																value={
																	this.state
																		.machine_part_model_number
																}
															/>
															<div
																className="mt-4"
																style={{
																	color: "red"
																}}
															>
																{this.validator.message(
																	"machine_part_model_number",
																	this.state
																		.machine_part_model_number,
																	"required|string"
																)}
															</div>
														</div>
													</div>
													<div className="col-sm-6">
														<div className="form-group">
															<label
																htmlFor="machine_part_name"
																className="text-center control-label mb-1"
															>
																Machine Part Name
															</label>
															<input
																id="machine_part_name"
																name="machine_part_name"
																type="text"
																className="form-control"
																onChange={e =>
																	this.onTextChange(
																		"machine_part_name",
																		e.target.value
																	)
																}
																value={
																	this.state.machine_part_name
																}
															/>
															<div
																className="mt-4"
																style={{
																	color: "red"
																}}
															>
																{this.validator.message(
																	"machine_part_name",
																	this.state.machine_part_name,
																	"required|string"
																)}
															</div>
														</div>
													</div>
													<div className="col-sm-6">
														<div className="form-group">
															<label
																htmlFor="machine_part_type"
																className="text-center control-label mb-1"
															>
																Machine Part Type
															</label>
															<input
																id="machine_part_type"
																name="machine_part_type"
																type="text"
																className="form-control"
																onChange={e =>
																	this.onTextChange(
																		"machine_part_type",
																		e.target.value
																	)
																}
																value={
																	this.state.machine_part_type
																}
															/>
															<div
																className="mt-4"
																style={{
																	color: "red"
																}}
															>
																{this.validator.message(
																	"machine_part_type",
																	this.state.machine_part_type,
																	"required|string"
																)}
															</div>
														</div>
														<div className="form-group">
															{this.state.current_image !==
															null ? (
																<div className="image-card my-4">
																	<div className="imagewrap">
																		<div className="thumbnail">
																			<img
																				loading="lazy"
																				alt="brand"
																				src={
																					config.APP_URL +
																					"/api/storage/" +
																					this.state
																						.current_image
																				}
																			/>
																		</div>
																		<button
																			onClick={() =>
																				this.removeImage(
																					this.props.match
																						.params.id
																				)
																			}
																			type="button"
																			className="button1 ig-btn"
																		>
																			<i className="fa fa-times fa-2x"></i>
																		</button>
																	</div>
																</div>
															) : null}
														</div>
													</div>
													<div className="col-sm-6">
														<div className="form-group">
															<label
																htmlFor="machine_part_note"
																className="text-center control-label mb-1"
															>
																Machine Part Note
															</label>
															<input
																id="machine_part_note"
																name="machine_part_note"
																type="text"
																className="form-control"
																onChange={e =>
																	this.onTextChange(
																		"machine_part_note",
																		e.target.value
																	)
																}
																value={
																	this.state.machine_part_note
																}
															/>
															<div
																className="mt-4"
																style={{
																	color: "red"
																}}
															>
																{this.validator.message(
																	"machine_part_note",
																	this.state.machine_part_note,
																	"string"
																)}
															</div>
														</div>
														<div className="form-group">
															{/* <div className="file-input"> */}
															<input
																type="file"
																// id="file-input"
																// className="file-input__input"
																name="category_image"
																onChange={e =>
																	this.handleFileChange(e)
																}
																accept="image/jpg, image/jpeg, image/png"
															/>
															{/* <label
																	className="file-input__label"
																	for="file-input"
																>
																	<svg
																		aria-hidden="true"
																		focusable="false"
																		data-prefix="fas"
																		data-icon="upload"
																		className="svg-inline--fa fa-upload fa-w-16"
																		role="img"
																		xmlns="http://www.w3.org/2000/svg"
																		viewBox="0 0 512 512"
																	>
																		<path
																			fill="currentColor"
																			d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"
																		></path>
																	</svg>
																	<span>
																		Machine Part Image
																	</span>
																</label>
															</div> */}
														</div>
													</div>
													<div className="col-sm-6">
														<div className="form-group">
															<label
																htmlFor="machine_part_remarks"
																className="text-center control-label mb-1"
															>
																Machine Part Remarks
															</label>
															<input
																id="machine_part_remarks"
																name="machine_part_remarks"
																type="text"
																className="form-control"
																onChange={e =>
																	this.onTextChange(
																		"machine_part_remarks",
																		e.target.value
																	)
																}
																value={
																	this.state
																		.machine_part_remarks
																}
															/>
															<div
																className="mt-4"
																style={{
																	color: "red"
																}}
															>
																{this.validator.message(
																	"machine_part_remarks",
																	this.state
																		.machine_part_remarks,
																	"string"
																)}
															</div>
														</div>
													</div>
													<div className="col-sm-6">
														<div className="form-group">
															<label
																htmlFor="machine_part_priority"
																className="text-center control-label mb-1"
															>
																Machine Part Priority
															</label>
															<select
																value={
																	this.state
																		.machine_part_priority
																}
																className="custom-select"
																onChange={e =>
																	this.onTextChange(
																		"machine_part_priority",
																		e.target.value
																	)
																}
															>
																<option value={1}>Yes</option>
																<option value={0}>No</option>
															</select>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-sm-6">
														<div className="form-group">
															<label
																htmlFor="is_visible"
																className="text-center control-label mb-1"
															>
																Machine Part Visible
															</label>
															<select
																value={this.state.is_visible}
																className="custom-select"
																onChange={e =>
																	this.onTextChange(
																		"is_visible",
																		e.target.value
																	)
																}
															>
																<option value={1}>Yes</option>
																<option value={0}>No</option>
															</select>
														</div>
													</div>
													<div className="col-sm-6">
														<div className="form-group">
															<label
																htmlFor="is_included"
																className="text-center control-label mb-1"
															>
																Machine Part Included
															</label>
															<select
																value={this.state.is_included}
																className="custom-select"
																onChange={e =>
																	this.onTextChange(
																		"is_included",
																		e.target.value
																	)
																}
															>
																<option value={1}>Yes</option>
																<option value={0}>No</option>
															</select>
														</div>
													</div>
													{/* <div className="col-sm-6">
														<div className="form-group">
															<label
																htmlFor="parent_model_number"
																className="text-center control-label mb-1"
															>
																Parent Model Number
															</label>
															{this.state.data ? (
																<MultiSelect
																	value={
																		this.state
																			.parent_model_number
																	}
																	options={this.state.data}
																	onChange={e =>
																		this.setState({
																			parent_model_number:
																				e.value
																		})
																	}
																	className="multi-select"
																	filter={true}
																	placeholder="Choose parent parts"
																/>
															) : null}
														</div>
													</div>
													<div className="col-sm-6">
														<div className="form-group">
															<label
																htmlFor="negative_parent_model_number"
																className="text-center control-label mb-1"
															>
																Negative Parent Model Number
															</label>
															{this.state.data ? (
																<MultiSelect
																	value={
																		this.state
																			.negative_parent_model_number
																	}
																	options={this.state.data}
																	onChange={e =>
																		this.setState({
																			negative_parent_model_number:
																				e.value
																		})
																	}
																	className="multi-select"
																	filter={true}
																	placeholder="Choose negative parent parts"
																/>
															) : null}
														</div>
													</div> */}
													<div className="col-sm-6">
														<div className="form-group">
															<label
																htmlFor="brand"
																className="text-center control-label mb-1"
															>
																Brand
															</label>
															<select
																value={this.state.brand}
																className="custom-select"
																onChange={e =>
																	this.onTextChange(
																		"brand",
																		e.target.value
																	)
																}
															>
																<option>Select brand</option>
																{this.state.brands
																	? this.state.brands.map(
																			(brand, i) => (
																				<option
																					key={i}
																					value={brand.id}
																				>
																					{
																						brand.brand_name
																					}
																				</option>
																			)
																	  )
																	: null}
															</select>
															<div
																className="mt-4"
																style={{
																	color: "red"
																}}
															>
																{this.validator.message(
																	"brand",
																	this.state.brand,
																	"required|numeric"
																)}
															</div>
														</div>
													</div>
													{/* <div className="col-sm-6">
														<div className="form-group">
															<label
																htmlFor="country"
																className="text-center control-label mb-1"
															>
																Country
															</label>
															<select
																value={this.state.country}
																className="custom-select"
																onChange={e =>
																	this.onTextChange(
																		"country",
																		e.target.value
																	)
																}
															>
																<option>Select Country</option>
																{this.state.countries.map(
																	(country, i) => (
																		<option
																			key={i}
																			value={country.id}
																		>
																			{country.country_name}
																		</option>
																	)
																)}
															</select>
															<div
																className="mt-4"
																style={{
																	color: "red"
																}}
															>
																{this.validator.message(
																	"country",
																	this.state.country,
																	"required|numeric"
																)}
															</div>
														</div>
													</div> */}
												</div>
												<div>
													<button
														onClick={this.onSubmit}
														id="payment-button"
														type="submit"
														className="btn btn-secondary btn-block"
													>
														<i className="fa fa-truck"></i>&nbsp;
														<span id="payment-button-amount">
															Update Machine Part
														</span>
													</button>
												</div>
												<div className="row my-4">
													<div className="col-sm-12">
														<div className="form-group">
															<label
																htmlFor="country"
																className="text-center control-label mb-1"
															>
																Used in machine category
															</label>
															<div className="row">
																{this.state
																	.compatible_machine_categories &&
																this.state
																	.compatible_machine_categories !==
																	[]
																	? this.state.compatible_machine_categories.map(
																			(categories, i) => (
																				<div className="col-6 mt-2 mb-2">
																					[{" "}
																					{
																						categories.machine_category_model_number
																					}{" "}
																					] -{" "}
																					{
																						categories.machine_category
																					}
																				</div>
																			)
																	  )
																	: "Not used in any categories"}
															</div>
														</div>
													</div>
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				);
			}
		}
	}
}
const mapStateToProps = state => {
	return {
		message: state.showMachinePartReducer.message,
		loading: state.showMachinePartReducer.loading
	};
};

export default connect(mapStateToProps, { edit_machine_parts_only })(
	UpdateMachinePartOnly
);
