import { CountryService } from "./service";
import { take, call, put } from "redux-saga/effects";
import countryActionTypes from "./actionTypes";

function* showCountries() {
  while (true) {
    yield take(countryActionTypes.SHOW_COUNTRY_REQUEST);

    const countries = yield call(CountryService.showCountries);

    if (
      countries !== "" &&
      countries !== null &&
      countries !== [] &&
      countries
    ) {
      yield put({
        type: countryActionTypes.SHOW_COUNTRY_SUCCESS,
        message: "Getting Countries Data Success",
        countries: countries
      });
    } else {
      yield put({
        type: countryActionTypes.SHOW_COUNTRY_FALIURE,
        message: "Getting Country Data Faliure"
      });
    }
  }
}

function* addCountries(params) {
  while (true) {
    const { params } = yield take(countryActionTypes.ADD_COUNTRY_REQUEST);

    const data = yield call(CountryService.addCountries, params);

    if (data !== "" && data !== null && data !== [] && data) {
      yield put({
        type: countryActionTypes.ADD_COUNTRY_SUCCESS,
        message: "Adding country data success"
      });
    } else {
      yield put({
        type: countryActionTypes.ADD_COUNTRY_FALIURE,
        message: "Adding country data faliure"
      });
    }
  }
}

function* editCountries(params) {
  while (true) {
    const { params } = yield take(countryActionTypes.UPDATE_COUNTRY_REQUEST);

    const data = yield call(CountryService.editCountries, params);

    if (data !== "" && data !== null && data !== [] && data) {
      yield put({
        type: countryActionTypes.UPDATE_COUNTRY_SUCCESS,
        message: "Editing country data success"
      });
    } else {
      yield put({
        type: countryActionTypes.UPDATE_COUNTRY_FALIURE,
        message: "Editing country data faliure"
      });
    }
  }
}

function* deleteCountries(params) {
  while (true) {
    const { params } = yield take(countryActionTypes.DELETE_COUNTRY_REQUEST);

    const data = yield call(CountryService.deleteCountries, params);

    if (data !== "" && data !== null && data !== [] && data) {
      yield put({
        type: countryActionTypes.DELETE_COUNTRY_SUCCESS,
        message: "Deleting country data success"
      });
    } else {
      yield put({
        type: countryActionTypes.DELETE_COUNTRY_FALIURE,
        message: "Deleting country data faliure"
      });
    }
  }
}

function* cleanCountryMessage() {
  while (true) {
    yield take(countryActionTypes.CLEAN_COUNTRY_MESSAGE_REQUEST);
    yield put({ type: countryActionTypes.CLEAN_COUNTRY_MESSAGE_SUCCESS });
  }
}

export {
  showCountries,
  addCountries,
  editCountries,
  deleteCountries,
  cleanCountryMessage
};
