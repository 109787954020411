import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Api } from "./../../../config/api";
import { clean_message } from "./../action";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Tooltip, notification } from "antd";

class ListNoteParts extends Component {
	constructor(props) {
		super(props);
		this.state = {
			parts: false,
			search_parts: false,
			search: ""
		};
		this.onSearch = this.onSearch.bind(this);
	}

	onSearch(name, value) {
		let redirect = this;
		switch (name) {
			case "search":
				redirect.setState({ search: value });
				let parts = redirect.state.parts;
				parts = parts.filter(
					item =>
						item.machine_part_name.toLowerCase().includes(value) ||
						item.machine_part_model_number.toLowerCase().includes(value)
				);

				redirect.setState({ search_parts: parts });
				break;

			default:
				break;
		}
	}

	async componentDidMount() {
		await this.props.clean_message();
		let { machine_category_id } = this.props.match.params;
		let redirect = this;

		await Api.get("/compatibility/" + machine_category_id)
			.then(function(response) {
				redirect.setState({ parts: response.data.selected_parts });
				redirect.setState({ search_parts: response.data.selected_parts });
			})
			.catch(function(error) {});

		if (this.props.message !== "" && this.props.message !== undefined) {
			if (this.props.message.toLowerCase().includes("success")) {
				notification.success({
					message: "Success!",
					description: this.props.message
				});
			} else {
				notification.error({
					message: "Error!",
					description: this.props.message
				});
			}
		}
	}

	render() {
		if (
			this.state.parts !== null &&
			this.state.parts !== [] &&
			this.state.parts !== false
		) {
			return (
				<div>
					<div
						className="content"
						style={{
							minHeight: 500
						}}
					>
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-header">
										<strong className="card-title">
											Compatible Machine Parts
										</strong>
									</div>
									<div className="card-body">
										<div className="card-content">
											<div className="todo-list">
												<div className="tdl-holder">
													<div className="tdl-content">
														<div className="row mb-2">
															<div className="col"></div>
															<div className="col">
																<div className="from-group">
																	<input
																		placeholder="Filter Country"
																		className="form-control"
																		name="user"
																		id="user"
																		onChange={e =>
																			this.onSearch(
																				"search",
																				e.target.value
																			)
																		}
																		value={this.state.search}
																	/>
																</div>
															</div>
														</div>
														{/* <ol>
                                {
                                    this.state.search_parts.map((machine_part, i) => 
                                        <li key={i}>
                                            <label
                                                style={{
                                                color: 'black'
                                            }}>
                                                {machine_part.machine_part_name}
                                                <Link to={"/update-country/" + machine_part.id}>
                                                    <i className="fa text-dark fa-pencil"></i>
                                                </Link>
                                            </label>
                                        </li>
                                    )
                                }
                            </ol> */}
														{this.state.search_parts
															? this.state.search_parts.map(
																	(part, i) => (
																		<li key={i}>
																			<label
																				style={{
																					color: "black"
																				}}
																			>
																				{part.machine_part_model_number +
																					" - " +
																					part.machine_part_name}
																				<Link
																					to={
																						"/update-custom_note-or-remark/" +
																						this.props
																							.match
																							.params
																							.machine_category_id +
																						"/" +
																						part.machine_part_id
																					}
																				>
																					<i className="fa text-dark fa-pencil"></i>
																				</Link>
																				<Tooltip
																					title={
																						part.discount_applicable
																							? "Discount is applied in this part"
																							: "Discount is not applied in this part (Fixed Price)"
																					}
																				>
																					<Link
																						to="#"
																						style={{
																							color:
																								"black",
																							marginRight:
																								"1rem"
																						}}
																					>
																						{part.discount_applicable
																							? <i class="fa-solid fa-percent"></i>
																							: "Discount not Applied"}
																					</Link>
																				</Tooltip>
																			</label>
																		</li>
																	)
															  )
															: null}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div>
					<div
						className="content"
						style={{
							minHeight: 500
						}}
					>
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-header">
										<strong className="card-title">
											Compatible Machine Parts
										</strong>
									</div>
									<div className="card-body">
										<div className="card-content">
											<div className="todo-list">
												<div className="tdl-holder">
													<div className="tdl-content">
														<div className="row">
															<div className="col"></div>
															<div className="col">
																<div className="text-center">
																	<Loader
																		type="Rings"
																		color="#00BFFF"
																		height={100}
																		width={100}
																	/>
																	<div className="text-center client-roboto-condensed">
																		LOADING...
																	</div>
																</div>
															</div>
															<div className="col"></div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}
}

const mapStateToProps = state => {
	return {
		countries: state.countryReducer.countries,
		message: state.countryReducer.message
	};
};
export default connect(mapStateToProps, {
	clean_message
})(ListNoteParts);
