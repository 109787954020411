import machineCategoryActionTypes from './actionTypes';

function clean_message(){
    return{
        type: machineCategoryActionTypes.CLEAN_MESSAGE_REQUEST,
        loading:true
    }
}

function show_machine_categories() {
    return {type: machineCategoryActionTypes.SHOW_MACHINE_CATEGORY_REQUEST, loading: true}
}

function add_machine_categories(params){
    return{
        type: machineCategoryActionTypes.ADD_MACHINE_CATEGORY_REQUEST,
        loading: true,
        params: params
    }
}

function edit_machine_categories(params){
    return{
        type: machineCategoryActionTypes.EDIT_MACHINE_CATEGORY_REQUEST,
        loading: true,
        params: params
    }
}

export {show_machine_categories, clean_message, add_machine_categories, edit_machine_categories}