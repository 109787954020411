import React, { Component } from "react";
import { connect } from "react-redux";
import { CustomerFeedBackService } from "./../service";
import { Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { notification } from "antd";

class ListFrequency extends Component {
	constructor(props) {
		super(props);
		this.state = {
			frequencies: false,
			loading: true
		};
		this.onDelete = this.onDelete.bind(this);
	}

	async componentDidMount() {
		let frequencies = await CustomerFeedBackService.showAllFrequencies();

		this.setState({ frequencies: frequencies });
		this.setState({ loading: false });
	}

	//function to trigger delete of certain brand in the list
	async onDelete(id) {
		let confirmation = window.confirm("Are you sure?");

		if (confirmation) {
            let data = await CustomerFeedBackService.deleteFrequency(id);
            let frequencies = await CustomerFeedBackService.showAllFrequencies();
    
            this.setState({ frequencies: frequencies });

			// show all brands [redux]

			if (data.message.includes("successful")) {
				notification.success({
					message: "Success!",
					description: data.message
				});
			} else {
				notification.error({
					message: "Error!",
					description: data.message
				});
			}
		}
	}

	render() {
		if (!this.state.loading) {
			return (
				<div>
					<div
						className="content"
						style={{
							minHeight: 500
						}}
					>
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-header">
										<strong className="card-title">
											System Support Frequency
										</strong>
									</div>
									<div className="card-body">
										<div className="card-content">
											<div className="todo-list">
												<div className="tdl-holder">
													<div className="tdl-content">
														<ul>
															{this.state.frequencies
																? this.state.frequencies.map(
																		(frequency, i) => (
																			<li key={i}>
																				<label
																					style={{
																						color: "black"
																					}}
																				>
																					<div className="row">
																						<div className="col-sm-9">
																							{frequency.frequency}
																						</div>
																						<div className="col-sm-3">
																							{/* <Link
                                                                                            onClick={e =>
                                                                                                this.onDelete(brand.id)
                                                                                            }
                                                                                            >
                                                                                            <i className="fa text-dark fa-times"></i>
                                                                                            </Link> */}
																							<Link
																								to={
																									"/edit-customer-feedback-frequency/" +
																									frequency.id
																								}
																							>
																								<i className="fa text-dark fa-pencil"></i>
																							</Link>
																						</div>
																					</div>
																				</label>
																			</li>
																		)
																  )
																: null}
														</ul>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div>
					<div
						className="content"
						style={{
							minHeight: 500
						}}
					>
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-header">
										<strong className="card-title">
											System Support Severity
										</strong>
									</div>
									<div className="card-body">
										<div className="card-content">
											<div className="todo-list">
												<div className="tdl-holder">
													<div className="tdl-content">
														<div className="row">
															<div className="col"></div>
															<div className="col">
																<div className="text-center">
																	<Loader
																		type="Rings"
																		color="#00BFFF"
																		height={100}
																		width={100}
																	/>
																	<div className="text-center client-roboto-condensed">
																		LOADING...
																	</div>
																</div>
															</div>
															<div className="col"></div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}
}
const mapStateToProps = state => {
	return {
		message: state.brandReducer.message,
		loading: state.brandReducer.loading,
		brands: state.brandReducer.brands
	};
};

export default connect(mapStateToProps, {})(ListFrequency);
