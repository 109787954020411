import keyModelFeatureSetActionTypes from "./actionTypes";

function clean_message() {
	return {
		type: keyModelFeatureSetActionTypes.CLEAN_MESSAGE_REQUEST,
		loading: true
	};
}

function show_features() {
	return {
		type: keyModelFeatureSetActionTypes.SHOW_KEY_MODEL_FEATURES_REQUEST,
		loading: true
	};
}

function show_single_feature(params) {
	return {
		type: keyModelFeatureSetActionTypes.SHOW_SINGLE_KEY_MODEL_FEATURE_REQUEST,
		loading: true,
		params: params
	};
}

function add_features(params) {
	return {
		type: keyModelFeatureSetActionTypes.ADD_KEY_MODEL_FEATURE_REQUEST,
		loading: true,
		params: params
	};
}

function edit_feature(params) {
	return {
		type: keyModelFeatureSetActionTypes.UPDATE_KEY_MODEL_FEATURE_REQUEST,
		loading: true,
		params: params
	};
}

function delete_feature(params) {
	return {
		type: keyModelFeatureSetActionTypes.DELETE_KEY_MODEL_FEATURE_REQUEST,
		loading: true,
		params: params
	};
}

function update_features(parts_array, machine_category_id) {
	return {
		type: keyModelFeatureSetActionTypes.UPDATE_KEY_MODEL_FEATURES_REQUEST,
		loading: true,
		parts_array: parts_array,
		machine_category_id: machine_category_id
	};
}

export {
	show_features,
	clean_message,
	show_single_feature,
	add_features,
	edit_feature,
	delete_feature,
	update_features
};
