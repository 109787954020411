import { Api } from "./../../config/api";

function showCountries() {
  let data = Api.get("country")
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {});

  if (data) {
    return data;
  } else {
    return false;
  }
}

function showSingleCountry(params) {
  let data = Api.get("country/" + params)
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {});

  if (data) {
    return data;
  } else {
    return false;
  }
}

function addCountries(params) {
  let data = Api.post("country", params)
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {});

  if (data) {
    return data;
  } else {
    return false;
  }
}

function editCountries(params) {
  let data = Api.post("country/edit/" + params.get("id"), params)
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {});

  if (data) {
    return data;
  } else {
    return false;
  }
}

function deleteCountries(params) {
  let data = Api.get("country/delete/" + params)
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {});

  if (data) {
    return data;
  } else {
    return false;
  }
}

export const CountryService = {
  showCountries,
  showSingleCountry,
  addCountries,
  editCountries,
  deleteCountries
};
