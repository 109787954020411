import React, { Component } from "react";
import { connect } from "react-redux";
import { RoleManagementService } from "./../service";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

class UpdateTemplateForRole extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			templates: false,
			current_roles: false
		};
	}

	async componentDidMount() {
		let {id} = this.props.match.params
		let templates = await RoleManagementService.getTemplatesForRole(id);

		let current_roles = await RoleManagementService.getCurrentRoles(id);

		await this.setState({current_roles: current_roles[0]})

		await this.setState({ templates: templates });

		await this.setState({loading: false})
	}

	render() {
		if (!this.props.loading && !this.state.loading) {
			return (
				<div>
					<div
						className="content"
						style={{
							minHeight: 500
						}}
					>
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-header">
										<strong className="card-title">
											Show Templates For Role  
												{
													this.state.current_roles ?
														' ['+this.state.current_roles.role_name+']'
														:
														null
												}
										</strong>
									</div>
									<div className="card-body">
										<div className="card-content">
											<div className="todo-list">
												<div className="tdl-holder">
													<div className="tdl-content">
														<ul>
															{this.state.templates
																? this.state.templates.map((template, i) => (
																		<li key={i}>
																			<label
																				style={{
																					color: "black"
																				}}
																			>
																				<div className="row">
																					<div className="col-sm-9">
																						{template.template_name}
																					</div>
																				</div>
																			</label>
																		</li>
																  ))
																: null}
														</ul>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div>
					<div
						className="content"
						style={{
							minHeight: 500
						}}
					>
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-header">
										<strong className="card-title">
											Show Templates For Role
										</strong>
									</div>
									<div className="card-body">
										<div className="card-content">
											<div className="todo-list">
												<div className="tdl-holder">
													<div className="tdl-content">
														<div className="row">
															<div className="col"></div>
															<div className="col">
																<div className="text-center">
																	<Loader
																		type="Rings"
																		color="#00BFFF"
																		height={100}
																		width={100}
																	/>
																	<div className="text-center client-roboto-condensed">
																		LOADING...
																	</div>
																</div>
															</div>
															<div className="col"></div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}
}
const mapStateToProps = state => {
	return {};
};

export default connect(mapStateToProps, {})(UpdateTemplateForRole);
