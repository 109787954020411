import { KeyModelFeatureSetService } from "./service";
import { take, call, put } from "redux-saga/effects";
import keyModelFeatureSetActionTypes from "./actionTypes";

function* cleanKeyModelFeatureMessage() {
	while (true) {
		yield take(keyModelFeatureSetActionTypes.CLEAN_MESSAGE_REQUEST);

		yield put({
			type: keyModelFeatureSetActionTypes.CLEAN_MESSAGE_SUCCESS
		});
	}
}

function* showKeyModelFeatures() {
	while (true) {
		yield take(keyModelFeatureSetActionTypes.SHOW_KEY_MODEL_FEATURES_REQUEST);

		const features = yield call(
			KeyModelFeatureSetService.showKeyModelFeatures
		);

		if (features !== "" && features !== null && features !== [] && features) {
			yield put({
				type: keyModelFeatureSetActionTypes.SHOW_KEY_MODEL_FEATURES_SUCCESS,
				message: "Getting features data success",
				features: features
			});
		} else {
			yield put({
				type: keyModelFeatureSetActionTypes.SHOW_KEY_MODEL_FEATURES_FALIURE,
				message: "Getting features data faliure"
			});
		}
	}
}

function* showSingleKeyModelFeatures(params) {
	while (true) {
		const { params } = yield take(
			keyModelFeatureSetActionTypes.SHOW_SINGLE_KEY_MODEL_FEATURE_REQUEST
		);

		const feature = yield call(
			KeyModelFeatureSetService.showSingleKeyModelFeatures,
			params
		);

		if (feature !== "" && feature !== null && feature !== [] && feature) {
			yield put({
				type:
					keyModelFeatureSetActionTypes.SHOW_SINGLE_KEY_MODEL_FEATURE_SUCCESS,
				message: "Getting feature data success",
				feature: feature
			});
		} else {
			yield put({
				type:
					keyModelFeatureSetActionTypes.SHOW_SINGLE_KEY_MODEL_FEATURE_FALIURE,
				message: "Getting feature data faliure"
			});
		}
	}
}

function* addKeyModelFeature(params) {
	while (true) {
		const { params } = yield take(
			keyModelFeatureSetActionTypes.ADD_KEY_MODEL_FEATURE_REQUEST
		);

		const feature = yield call(
			KeyModelFeatureSetService.addKeyModelFeature,
			params
		);

		if (feature !== "" && feature !== null && feature !== [] && feature) {
			yield put({
				type: keyModelFeatureSetActionTypes.ADD_KEY_MODEL_FEATURE_SUCCESS,
				message: "Adding feature data success"
			});
		} else {
			yield put({
				type: keyModelFeatureSetActionTypes.ADD_KEY_MODEL_FEATURE_FALIURE,
				message: "Adding feature data faliure"
			});
		}
	}
}

function* editKeyModelFeature(params) {
	while (true) {
		const { params } = yield take(
			keyModelFeatureSetActionTypes.UPDATE_KEY_MODEL_FEATURE_REQUEST
		);

		const feature = yield call(
			KeyModelFeatureSetService.editKeyModelFeature,
			params
		);

		if (feature !== "" && feature !== null && feature !== [] && feature) {
			yield put({
				type:
					keyModelFeatureSetActionTypes.UPDATE_KEY_MODEL_FEATURE_SUCCESS,
				message: "Updating feature data success"
			});
		} else {
			yield put({
				type:
					keyModelFeatureSetActionTypes.UPDATE_KEY_MODEL_FEATURE_FALIURE,
				message: "Updating feature data faliure"
			});
		}
	}
}

function* deleteKeyModelFeature(params) {
	while (true) {
		const { params } = yield take(
			keyModelFeatureSetActionTypes.DELETE_KEY_MODEL_FEATURE_REQUEST
		);

		const feature = yield call(
			KeyModelFeatureSetService.deleteKeyModelFeature,
			params
		);

		if (feature !== "" && feature !== null && feature !== [] && feature) {
			yield put({
				type:
					keyModelFeatureSetActionTypes.DELETE_KEY_MODEL_FEATURE_SUCCESS,
				message: "Deleting feature data success",
				feature: feature
			});
		} else {
			yield put({
				type:
					keyModelFeatureSetActionTypes.DELETE_KEY_MODEL_FEATURE_FALIURE,
				message: "Deleting feature data faliure"
			});
		}
	}
}

function* addKeyModelFeaturesToCategory(params) {
	while (true) {
		const { parts_array, machine_category_id } = yield take(
			keyModelFeatureSetActionTypes.UPDATE_KEY_MODEL_FEATURES_REQUEST
		);

		const feature = yield call(
			KeyModelFeatureSetService.addKeyModelFeaturesToCategory,
			parts_array,
			machine_category_id
		);

		if (feature !== "" && feature !== null && feature !== [] && feature) {
			yield put({
				type:
					keyModelFeatureSetActionTypes.UPDATE_KEY_MODEL_FEATURES_SUCCESS,
				message: "Adding  features to category success"
			});
		} else {
			yield put({
				type:
					keyModelFeatureSetActionTypes.UPDATE_KEY_MODEL_FEATURES_FALIURE,
				message: "Adding features to category faliure"
			});
		}
	}
}

export {
	showKeyModelFeatures,
	showSingleKeyModelFeatures,
	addKeyModelFeature,
	editKeyModelFeature,
	deleteKeyModelFeature,
	addKeyModelFeaturesToCategory,
	cleanKeyModelFeatureMessage
};
