import React, { Component } from "react";
import { connect } from "react-redux";
import { CountryService } from "./../../admin/country/service";
import { registerAdmin } from "./../action";
import { Redirect } from "react-router-dom";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Swal from "sweetalert2";
import { clean_login_message } from "./../action";
import { PulseLoader } from "react-spinners";
import { css } from "@emotion/react";
import SimpleReactValidator from "simple-react-validator";

class RegisterAdmin extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			full_name: "",
			email: "",
			password: "",
			password_confirmation: "",
			selected_country: "",
			countries: []
		};
		this.validator = new SimpleReactValidator();
		this.onTextChange = this.onTextChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.onCountryChange = this.onCountryChange.bind(this);
	}

	async onCountryChange(value) {
		let redirect = this;
		redirect.setState({ selected_country: value });
	}

	onTextChange(name, value) {
		let redirect = this;
		switch (name) {
			case "full_name":
				redirect.setState({ full_name: value });
				break;

			case "email":
				redirect.setState({ email: value });
				break;

			case "password":
				redirect.setState({ password: value });
				break;

			case "password_confirmation":
				redirect.setState({ password_confirmation: value });
				break;

			default:
				break;
		}
	}

	async componentDidUpdate() {
		if (this.props.message.toLowerCase().includes("registration success")) {
			Swal.fire({
				title: "Success!",
				text: "Registration successful, please continue to login",
				icon: "success",
				confirmButtonText: "OK"
			});
		}

		if (this.props.message.toLowerCase().includes("registration faliure")) {
			Swal.fire({
				title: "Error!",
				text:
					"Registration error, please try again with the correct details",
				icon: "error",
				confirmButtonText: "OK"
			});
		}
	}

	async onSubmit(e) {
		e.preventDefault();

		this.setState({ loading: true });

		let result = await Swal.fire({
			title: "Are you sure?",
			text: "Do you want to create another admin?",
			icon: "info",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, Create!"
		}).then(result => {
			return result;
		});

		if (result.value) {
			if (this.validator.allValid()) {
				if (this.state.password === this.state.password_confirmation) {
					let params = {
						name: this.state.full_name,
						email: this.state.email,
						password: this.state.password,
						password_confirmation: this.state.password_confirmation,
						country_id: this.state.selected_country
					};

					await this.props.registerAdmin(params);

					await this.setState({ loading: false });
				} else {
					Swal.fire({
						title: "Password Information",
						text:
							"Your passwords do not match, please try again with matching passwords!!",
						icon: "info",
						confirmButtonText: "OK"
					});
				}
			} else {
				this.validator.showMessages();
				this.forceUpdate();
			}
		}

		this.setState({ loading: false });
	}

	async componentDidMount() {
		let redirect = this;

		let data = await CountryService.showCountries();
		redirect.setState({ countries: data });
		redirect.setState({ selected_country: data[0].id });

		await redirect.setState({ loading: false });
	}

	render() {
		const override = css`
			display: block;
			margin: 0 auto;
			border-color: red;
		`;
		if (this.props.message.toLowerCase().includes("registration success")) {
			return <Redirect to="/users" />;
		} else {
			if (this.state.loading) {
				return (
					<div className="sweet-loading">
						<div className="container loading-container">
							<div className="row m-5 p-5">
								<PulseLoader
									css={override}
									sizeUnit={"px"}
									size={24}
									color={"#B9373D"}
								/>
							</div>
						</div>
					</div>
				);
			} else {
				return (
					<div>
						<div
							className="content"
							style={{
								minHeight: 500
							}}
						>
							<div className="row">
								<div className="col-12">
									<div className="card">
										<div className="card-header">
											<strong className="card-title">
												Register Admin
											</strong>
										</div>
										<div className="card-body">
											<form className="">
												<div className="form-group">
													<label>Full Name</label>
													<input
														type="text"
														className="form-control"
														placeholder="Enter Full Name"
														value={this.state.full_name}
														onChange={e =>
															this.onTextChange(
																"full_name",
																e.target.value
															)
														}
													/>
													<div
														className="mt-4"
														style={{
															color: "red"
														}}
													>
														{this.validator.message(
															"Full name",
															this.state.full_name,
															"required|string"
														)}
													</div>
												</div>
												<div className="form-group">
													<label>E-mail Address</label>
													<input
														type="text"
														className="form-control"
														placeholder="Enter E-mail"
														value={this.state.email}
														onChange={e =>
															this.onTextChange(
																"email",
																e.target.value
															)
														}
													/>
													<div
														className="mt-4"
														style={{
															color: "red"
														}}
													>
														{this.validator.message(
															"email",
															this.state.email,
															"required|string|email"
														)}
													</div>
												</div>
												<div className="form-group">
													<label>Password</label>
													<input
														type="password"
														className="form-control"
														placeholder="Enter Password"
														value={this.state.password}
														onChange={e =>
															this.onTextChange(
																"password",
																e.target.value
															)
														}
													/>
													<div
														className="mt-4"
														style={{
															color: "red"
														}}
													>
														{this.validator.message(
															"password",
															this.state.password,
															"required|string|min:8|max:32"
														)}
													</div>
												</div>
												<div className="form-group">
													<label>Confirm Password</label>
													<input
														type="password"
														className="form-control"
														placeholder="Renter Password"
														value={
															this.state.password_confirmation
														}
														onChange={e =>
															this.onTextChange(
																"password_confirmation",
																e.target.value
															)
														}
													/>
													<div
														className="mt-4"
														style={{
															color: "red"
														}}
													>
														{this.validator.message(
															"password_confirmation",
															this.state.password_confirmation,
															"required|string|min:8|max:32"
														)}
													</div>
												</div>
												<div className="form-group">
													<label>Select Preferred Country</label>
													<select
														className="custom-select"
														onChange={e =>
															this.onCountryChange(
																e.target.value
															)
														}
														defaultValue={
															this.state.selected_country
														}
													>
														{this.state.countries.map(
															(country, id) => (
																<option
																	key={id}
																	value={country.id}
																>
																	{country.country_name}
																</option>
															)
														)}
													</select>
													<div
														className="mt-4"
														style={{
															color: "red"
														}}
													>
														{this.validator.message(
															"selected_country",
															this.state.selected_country,
															"required"
														)}
													</div>
												</div>
												<div>
													<button
														type="submit"
														onClick={this.onSubmit}
														className="client-roboto-condensed client-login m-2 px-5"
													>
														Register Admin
													</button>
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				);
			}
		}
	}
}
const mapStateToProps = state => {
	return {
		message: state.loginReducer.message
	};
};

export default connect(mapStateToProps, {
	registerAdmin,
	clean_login_message
})(RegisterAdmin);
