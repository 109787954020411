import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ClientConfigurationService } from "./../service";
import noimage from "./../../../CPQ/noimage.png";
import { css } from "@emotion/react";
import { PulseLoader } from "react-spinners";
import config from "./../../../config/config";
import { notification } from "antd";

class MyConfigurations extends Component {
	constructor(props) {
		super(props);
		this.state = {
			configurations: false,
			search_configurations: false,
			search: "",
			total_shown_configuration: 12,
			library_configurations_count: 0,
			children_configurations: false
		};
		this.onSearchChange = this.onSearchChange.bind(this);
	}

	onSearchChange(value) {
		let redirect = this;
		redirect.setState({ search: value });

		let parts = redirect.state.configurations;
		parts = parts.filter(item =>
			item.name.toLowerCase().includes(value.toLowerCase())
		);

		redirect.setState({ search_configurations: parts });
		if (parts.length === 0) {
		}
	}

	async componentDidMount() {
		let redirect = this;

		let children_configurations = await ClientConfigurationService.showChildrenConfigurations(
			this.props.user_id
		);
		this.setState({ children_configurations: children_configurations });

		let library_configurations = await ClientConfigurationService.getParentLibraryConfiguration(
			this.props.user_id
		);

		this.setState({
			library_configurations_count: library_configurations.length
		});

		let configurations = [];
		if (this.props.is_support) {
			configurations = await ClientConfigurationService.showUserConfigurationsSalesSupport(
				this.props.user_id
			);
		} else {
			configurations = await ClientConfigurationService.showUserConfigurations(
				this.props.user_id
			);
		}

		redirect.setState({ configurations: configurations });
		redirect.setState({ search_configurations: configurations });
		if (configurations === undefined) {
			notification.success({
				message: "Success!",
				description: "No configurations found."
			});
		} else {
		}
	}

	render() {
		const override = css`
			display: block;
			margin: 0 auto;
			border-color: red;
		`;
		if (!this.state.search_configurations || this.props.loginLoading) {
			return (
				<div className="sweet-loading">
					<div className="container loading-container">
						<div className="row m-5 p-5">
							<PulseLoader
								css={override}
								sizeUnit={"px"}
								size={24}
								color={"#B9373D"}
							/>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div>
					<div className="my-5 client-roboto-condensed container">
						<div className="row">
							<div className="col"></div>
							<div className="col-10">
								<div className="text-center">
									<h4
										className="mb-4"
										style={{
											fontWeight: 900
										}}
									>
										My Configurations
									</h4>
								</div>
							</div>
							<div className="col"></div>
						</div>
						<div className="row">
							<div className="col"></div>
							<div className="col-10 client-cards-list">
								<div className="my-3">
									{this.state.library_configurations_count !== 0 ? (
										<Link
											className="btn client-login"
											style={{
												width: 170
											}}
											to="/configuration-process"
										>
											Create Configuration
										</Link>
									) : (
										<Link
											className="btn client-login"
											style={{
												width: 200
											}}
											to="/configuration-process"
										>
											Create Configuration
										</Link>
									)}
								</div>
								<div className="my-3">
									{this.state.library_configurations_count !== 0 ? (
										<Link
											className="btn client-login"
											style={{
												width: 170
											}}
											to="/library-configurations"
										>
											My Library
										</Link>
									) : (
										<Link
											className="btn client-login"
											style={{
												width: 200
											}}
											to="/library-configurations"
										>
											My Library
										</Link>
									)}
								</div>
								{this.state.library_configurations_count !== 0 ? (
									<div className="my-3">
										<Link
											style={{
												width: 170
											}}
											className="btn client-login"
											to="/team-library-configurations"
										>
											Team Library
										</Link>
									</div>
								) : null}
								{this.state.children_configurations ? (
									this.state.children_configurations.length > 0 ? (
										this.state.library_configurations_count !== 0 ? (
											<div className="my-3">
												<Link
													className="btn client-login"
													style={{
														width: 170
													}}
													to="/team-configurations"
												>
													Team Configurations
												</Link>
											</div>
										) : (
											<div className="my-3">
												<Link
													className="btn client-login"
													style={{
														width: 200
													}}
													to="/team-configurations"
												>
													Team Configurations
												</Link>
											</div>
										)
									) : null
								) : null}
								<div className="form-group my-3">
									{this.state.library_configurations_count !== 0 ? (
										<input
											className="form-control"
											type="text"
											style={{
												width: 170
											}}
											onChange={e =>
												this.onSearchChange(e.target.value)
											}
											value={this.props.search}
											placeholder="Search Configurations"
										/>
									) : (
										<input
											className="form-control"
											type="text"
											style={{
												width: 200
											}}
											onChange={e =>
												this.onSearchChange(e.target.value)
											}
											value={this.props.search}
											placeholder="Search Configurations"
										/>
									)}
								</div>
							</div>
							<div className="col"></div>
						</div>
						<div className="row">
							<div className="col"></div>
							<div className="col-10">
								<div className="client-cards-list my-auto">
									{this.state.search_configurations
										? this.state.search_configurations.map(
												(configuration, i) =>
													i <
													this.state.total_shown_configuration ? (
														<Link
															key={i}
															to={
																"/configuration/" +
																configuration.id
															}
															style={{
																marginTop: 20,
																marginBottom: 20
															}}
														>
															<div
																id={"curve-" + i}
																className="card"
																style={
																	configuration.machine_category_image !==
																		"" &&
																	configuration.machine_category_image
																		? {
																				backgroundImage:
																					"url(" +
																					config.APP_URL +
																					"/api/storage/" +
																					configuration.machine_category_image +
																					")",
																				height: 200,
																				width: 200
																		  }
																		: {
																				backgroundImage:
																					"url(" +
																					noimage +
																					")",
																				height: 200,
																				width: 200
																		  }
																}
															>
																<div className="footer">
																	<svg id={"curve-" + i}>
																		<path
																			id={"p" + i}
																			d="M0,200 Q80,100 400,200 V150 H0 V50"
																			transform="translate(0 300)"
																		/>
																		<rect
																			id={"dummyRect" + i}
																			x="0"
																			y="0"
																			height="450"
																			width="400"
																			fill="transparent"
																		/>
																		<animate
																			xlinkHref={"#p" + i}
																			attributeName="d"
																			to="M0,50 Q80,100 400,50 V150 H0 V50"
																			fill="freeze"
																			begin={
																				"dummyRect" +
																				i +
																				".mouseover"
																			}
																			end={
																				"dummyRect" +
																				i +
																				".mouseout"
																			}
																			dur="0.1s"
																			id={"bounce1" + i}
																		/>
																		<animate
																			xlinkHref={"#p" + i}
																			attributeName="d"
																			to="M0,50 Q80,0 400,50 V150 H0 V50"
																			fill="freeze"
																			begin={
																				"bounce1" +
																				i +
																				".end"
																			}
																			end={
																				"dummyRect" +
																				i +
																				".mouseout"
																			}
																			dur="0.15s"
																			id={"bounce2" + i}
																		/>
																		<animate
																			xlinkHref={"#p" + i}
																			attributeName="d"
																			to="M0,50 Q80,80 400,50 V150 H0 V50"
																			fill="freeze"
																			begin={
																				"bounce2" +
																				i +
																				".end"
																			}
																			end={
																				"dummyRect" +
																				i +
																				".mouseout"
																			}
																			dur="0.15s"
																			id={"bounce3" + i}
																		/>
																		<animate
																			xlinkHref={"#p" + i}
																			attributeName="d"
																			to="M0,50 Q80,45 400,50 V150 H0 V50"
																			fill="freeze"
																			begin={
																				"bounce3" +
																				i +
																				".end"
																			}
																			end={
																				"dummyRect" +
																				i +
																				".mouseout"
																			}
																			dur="0.1s"
																			id={"bounce4" + i}
																		/>
																		<animate
																			xlinkHref={"#p" + i}
																			attributeName="d"
																			to="M0,50 Q80,50 400,50 V150 H0 V50"
																			fill="freeze"
																			begin={
																				"bounce4" +
																				i +
																				".end"
																			}
																			end={
																				"dummyRect" +
																				i +
																				".mouseout"
																			}
																			dur="0.05s"
																			id={"bounce5" + i}
																		/>

																		<animate
																			xlinkHref={"#p" + i}
																			attributeName="d"
																			to="M0,200 Q80,100 400,200 V150 H0 V50"
																			fill="freeze"
																			begin={
																				"dummyRect" +
																				i +
																				".mouseout"
																			}
																			dur="0.15s"
																			id={"bounceOut" + i}
																		/>
																	</svg>
																	<div className="info">
																		<div className="job">
																			{configuration.name}
																		</div>
																	</div>
																</div>
																<div className="card-blur"></div>
															</div>
														</Link>
													) : null
										  )
										: null}
								</div>
							</div>
							<div className="col"></div>
						</div>
						{this.state.search_configurations.length >
						this.state.total_shown_configuration ? (
							<div className="text-center">
								<button
									onClick={e =>
										this.setState({
											total_shown_configuration: 1000000
										})
									}
									type="submit"
									className="client-roboto-condensed client-login mr-2 ml-2"
								>
									Load More
								</button>
							</div>
						) : null}
					</div>
				</div>
			);
		}
	}
}
const mapStateToProps = state => {
	return {
		user_id: state.loginReducer.user.user.id,
		role: state.loginReducer.user.role,
		loginLoading: state.loginReducer.loading,
		is_support: state.loginReducer.user.is_support,
		sales_margin_visible: state.loginReducer.user.sales_margin_visible,
		customer_discount_visible:
			state.loginReducer.user.customer_discount_visible,
		extra_discount_visible: state.loginReducer.user.extra_discount_visible,
		extra_discount_visible_and_editable:
			state.loginReducer.user.extra_discount_visible_and_editable
	};
};

export default connect(mapStateToProps, {})(MyConfigurations);
