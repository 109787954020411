const orderActionTypes = {
  SHOW_ORDERS_REQUEST: "show.orders.request",
  SHOW_ORDERS_SUCCESS: "show.orders.success",
  SHOW_ORDERS_FALIURE: "show.orders.faliure",

  SHOW_DETAILED_ORDER_REQUEST: "show.detailed.order.request",
  SHOW_DETAILED_ORDER_SUCCESS: "show.detailed.order.success",
  SHOW_DETAILED_ORDER_FALIURE: "show.detailed.order.faliure"
};
export default orderActionTypes;
