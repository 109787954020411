import React, { Component } from "react";
import { connect } from "react-redux";
import { ConfiguredMachineService } from "./../service";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";

class ListConfigurations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      configurations: false,
      search_configurations: false,
      search: ""
    };
    this.onConfigurationChange = this.onConfigurationChange.bind(this);
  }

  async componentDidMount() {
    let redirect = this;
    let data = await ConfiguredMachineService.showConfiguredMachines();
    await redirect.setState({ configurations: data });
    await redirect.setState({ search_configurations: data });
  }

  onConfigurationChange(name, value) {
    let redirect = this;
    switch (name) {
      case "configuration":
        redirect.setState({ search: value });
        let parts = redirect.state.configurations;
        parts = parts.filter(item => item.name.toLowerCase().includes(value));

        redirect.setState({ search_configurations: parts });
        break;

      default:
        break;
    }
  }

  render() {
    if (this.state.search_configurations) {
      return (
        <div>
          <div
            className="content"
            style={{
              minHeight: 500
            }}
          >
            <div className="row">
              <div className="col-sm-12">
                <div className="card col-sm-6">
                  <div className="card-header">
                    <strong className="card-title">Configuration List</strong>
                  </div>
                  <div className="card-body">
                    <div className="card-content">
                      <div className="todo-list">
                        <div className="tdl-holder">
                          <div className="tdl-content">
                            <div className="row mb-2">
                              <div className="col"></div>
                              <div className="col">
                                <div className="from-group">
                                  <input
                                    placeholder="Filter Configuration"
                                    className="form-control"
                                    name="configuration"
                                    id="configuration"
                                    onChange={e =>
                                      this.onConfigurationChange(
                                        "configuration",
                                        e.target.value
                                      )
                                    }
                                    value={this.state.search}
                                  />
                                </div>
                              </div>
                            </div>
                            <ol>
                              {this.state.search_configurations.map(
                                (configuration, i) => (
                                  <li key={i}>
                                    <label
                                      style={{
                                        color: "black"
                                      }}
                                    >
                                      {configuration.name}
                                      <Link href="#">
                                        <i className="fa text-dark fa-times"></i>
                                      </Link>
                                      <a
                                        href={
                                          "/update-country/" + configuration.id
                                        }
                                      >
                                        <i className="fa text-dark fa-pencil"></i>
                                      </a>
                                    </label>
                                  </li>
                                )
                              )}
                            </ol>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div
            className="content"
            style={{
              minHeight: 500
            }}
          >
            <div className="row">
              <div className="col-sm-12">
                <div className="card col-sm-6">
                  <div className="card-header">
                    <strong className="card-title">Cfigurations List</strong>
                  </div>
                  <div className="card-body">
                    <div className="card-content">
                      <div className="todo-list">
                        <div className="tdl-holder">
                          <div className="tdl-content">
                            <div className="row mb-2">
                              <div className="col"></div>
                              <div className="col">
                                <div className="from-group">
                                  <input
                                    placeholder="Filter Configuration"
                                    className="form-control"
                                    name="configuration"
                                    id="configuration"
                                    onChange={e =>
                                      this.onConfigurationChange(
                                        "configuration",
                                        e.target.value
                                      )
                                    }
                                    value={this.state.search}
                                  />
                                </div>
                              </div>
                            </div>
                            <Skeleton count={5} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = state => {
  return {};
};
export default connect(mapStateToProps, {})(ListConfigurations);
