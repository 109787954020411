import { Api } from "./../../config/api";

// api call to show all brands
function getAllLeaseForConfiguration(configuration_id) {
  let data = Api.get("lease/getAllLeaseForConfiguration/"+configuration_id).then(function(response) {
    return response.data;
  });

  if (data) {
    return data;
  } else {
    return false;
  }
}

//api call to show a single brand
function addOrUpdateLeaseforConfiguration(param, configuration_id) {
  let data = Api.post("lease/addOrUpdateLeaseforConfiguration/" + configuration_id, param).then(function(response) {
    return response.data;
  });

  if (data) {
    return data;
  } else {
    return false;
  }
}

export const LeaseCalculationService = {
    getAllLeaseForConfiguration,
    addOrUpdateLeaseforConfiguration
};
