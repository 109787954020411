import React, { Component } from "react";
import { add_countries } from "./../action";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { CurrencyService } from "./../../currency/service";

class AddCountry extends Component {
	constructor(props) {
		super(props);
		this.state = {
			country_name: "",
			country_code: "",
			country_number_code: "",
			country_language: "",
			country_VAT: "",
			country_currency: "",
			country_flag_image: null,
			currency_id: "",
			currencies: false
		};
		this.validator = new SimpleReactValidator({ autoForceUpdate: this });
		this.onSubmit = this.onSubmit.bind(this);
		this.handleFileChange = this.handleFileChange.bind(this);
	}

	handleFileChange(event) {
		this.setState({ country_flag_image: event.target.files[0] });
	}

	async componentDidMount() {
		let that = this;
		let currencies = await CurrencyService.showCurrencies();
		await that.setState({ currencies: currencies });
	}

	onSubmit(e) {
		e.preventDefault();
		const fd = new FormData();

		if (this.validator.allValid()) {
			fd.append("country_name", this.state.country_name);
			fd.append("country_code", this.state.country_code);
			fd.append("country_number_code", this.state.country_number_code);
			fd.append("country_language", this.state.country_language);
			fd.append("country_VAT", this.state.country_VAT);
			fd.append("country_currency", this.state.country_currency);
			fd.append("currency_id", this.state.currency_id);

			if (this.state.country_flag_image !== null) {
				fd.append("file", this.state.country_flag_image);
			}

			this.props.add_countries(fd);
		} else {
			this.validator.showMessages();
		}
	}

	onTextChange(name, value) {
		let redirect = this;

		switch (name) {
			case "country_name":
				redirect.setState({ country_name: value });
				break;

			case "country_code":
				redirect.setState({ country_code: value });
				break;

			case "country_currency":
				redirect.setState({ country_currency: value });
				break;

			case "country_number_code":
				redirect.setState({ country_number_code: value });
				break;

			case "country_language":
				redirect.setState({ country_language: value });
				break;

			case "country_VAT":
				redirect.setState({ country_VAT: value });
				break;

			case "currency_id":
				redirect.setState({ currency_id: value });
				break;

			default:
				break;
		}
	}

	render() {
		if (this.props.message.includes("Adding country data success")) {
			return <Redirect to="/country" />;
		} else {
			if (this.props.loading) {
				return (
					<div>
						<div
							className="content"
							style={{
								minHeight: 500
							}}
						>
							<div className="row">
								<div className="col-sm-12">
									<div className="card">
										<div className="card-header">
											<strong className="card-title">
												Country Data
											</strong>
										</div>
										<div className="card-body">
											<div className="card-content">
												<div className="todo-list">
													<div className="tdl-holder">
														<div className="tdl-content">
															<div className="row">
																<div className="col"></div>
																<div className="col">
																	<div className="text-center">
																		<Loader
																			type="Rings"
																			color="#00BFFF"
																			height={100}
																			width={100}
																		/>
																		<div className="text-center client-roboto-condensed">
																			LOADING...
																		</div>
																	</div>
																</div>
																<div className="col"></div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				);
			} else {
				return (
					<div>
						<div
							className="content"
							style={{
								minHeight: 500
							}}
						>
							<div className="row">
								<div className="col-sm-12">
									<div className="card">
										<div className="card-header">
											<strong className="card-title">
												Add Country Data
											</strong>
										</div>
										<div className="card-body">
											<form>
												<div className="row">
													<div className="col-sm-6">
														<div className="form-group">
															<label
																htmlFor="country_name"
																className="control-label mb-1"
															>
																Country Name
															</label>
															<input
																id="country_name"
																name="country_name"
																type="text"
																className="form-control"
																aria-required="true"
																onChange={e =>
																	this.onTextChange(
																		"country_name",
																		e.target.value
																	)
																}
																value={this.state.country_name}
															/>
															<div
																className="mt-4"
																style={{
																	color: "red"
																}}
															>
																{this.validator.message(
																	"country_name",
																	this.state.country_name,
																	"required|string"
																)}
															</div>
														</div>

														<div className="form-group">
															<label
																htmlFor="country_code"
																className="control-label mb-1"
															>
																Country 3-letter Code
															</label>
															<input
																id="country_code"
																name="country_code"
																type="text"
																className="form-control"
																aria-required="true"
																onChange={e =>
																	this.onTextChange(
																		"country_code",
																		e.target.value
																	)
																}
																value={this.state.country_code}
															/>
															<div
																className="mt-4"
																style={{
																	color: "red"
																}}
															>
																{this.validator.message(
																	"country_code",
																	this.state.country_code,
																	"required|string"
																)}
															</div>
														</div>

														<div className="form-group">
															<label
																htmlFor="country_number_code"
																className="control-label mb-1"
															>
																Country Number Code
															</label>
															<input
																id="country_number_code"
																name="country_number_code"
																type="text"
																className="form-control"
																aria-required="true"
																onChange={e =>
																	this.onTextChange(
																		"country_number_code",
																		e.target.value
																	)
																}
																value={
																	this.state
																		.country_number_code
																}
															/>
															<div
																className="mt-4"
																style={{
																	color: "red"
																}}
															>
																{this.validator.message(
																	"country_number_code",
																	this.state
																		.country_number_code,
																	"required|numeric"
																)}
															</div>
														</div>
														<div className="form-group">
															<label className="control-label mb-1">
																Currency
															</label>
															<br />
															<select onChange={(e)=>this.onTextChange('currency_id', e.target.value)} value={this.state.currency_id} className="custom-select">
																<option>
																	Please Select Currecy
																</option>
																{this.state.currencies
																	? this.state.currencies.map(
																			(currency, i) => (
																				<option
																					value={
																						currency.currency_id
																					}
																				>
																					{
																						currency.currency_name
																					}
																				</option>
																			)
																	  )
																	: null}
															</select>
														</div>
													</div>
													<div className="col-sm-6">
														<div className="form-group">
															<label
																htmlFor="country_language"
																className="control-label mb-1"
															>
																Country Language
															</label>
															<input
																id="country_language"
																name="country_language"
																type="text"
																className="form-control"
																aria-required="true"
																onChange={e =>
																	this.onTextChange(
																		"country_language",
																		e.target.value
																	)
																}
																value={
																	this.state.country_language
																}
															/>
															<div
																className="mt-4"
																style={{
																	color: "red"
																}}
															>
																{this.validator.message(
																	"country_language",
																	this.state.country_language,
																	"required|string"
																)}
															</div>
														</div>

														<div className="form-group">
															<label
																htmlFor="country_VAT"
																className="control-label mb-1"
															>
																Country VAT
															</label>
															<input
																id="country_VAT"
																name="country_VAT"
																type="text"
																className="form-control"
																aria-required="true"
																onChange={e =>
																	this.onTextChange(
																		"country_VAT",
																		e.target.value
																	)
																}
																value={this.state.country_VAT}
															/>
															<div
																className="mt-4"
																style={{
																	color: "red"
																}}
															>
																{this.validator.message(
																	"country_VAT",
																	this.state.country_VAT,
																	"required|numeric"
																)}
															</div>
														</div>

														<div className="form-group">
															<label
																htmlFor="country_currency"
																className="control-label mb-1"
															>
																Country Currency
															</label>
															<input
																id="country_currency"
																name="country_currency"
																type="text"
																className="form-control"
																aria-required="true"
																onChange={e =>
																	this.onTextChange(
																		"country_currency",
																		e.target.value
																	)
																}
																value={
																	this.state.country_currency
																}
															/>
															<div
																className="mt-4"
																style={{
																	color: "red"
																}}
															>
																{this.validator.message(
																	"country_currency",
																	this.state.country_currency,
																	"required|string"
																)}
															</div>
														</div>
														<div className="form-group">
															<label
																htmlFor="file"
																className="control-label mb-1"
															>
																Country Flag Image
															</label>
															<br />
															<input
																className="form-group"
																type="file"
																name="country_flag_image"
																onChange={e =>
																	this.handleFileChange(e)
																}
															/>
														</div>
													</div>
												</div>
												<div>
													<button
														onClick={this.onSubmit}
														id="payment-button"
														type="submit"
														className="btn btn-secondary btn-block"
													>
														<i className="fa fa-truck"></i>&nbsp;
														<span id="payment-button-amount">
															Add Country Data
														</span>
													</button>
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				);
			}
		}
	}
}

const mapStateToProps = state => {
	return {
		message: state.countryReducer.message,
		loading: state.countryReducer.loading
	};
};

export default connect(mapStateToProps, {
	add_countries
})(AddCountry);
