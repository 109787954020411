import React, { Component } from "react";
import { Api } from "../../config/api";
import { css } from "@emotion/react";
import { PulseLoader } from "react-spinners";
import { Link } from "react-router-dom";

export default class Unsubscribe extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			message: false
		};
	}

	async componentDidMount() {
		let { user_id } = this.props.match.params;
		let that = this;
		await Api.get("auth/unsubscribe/" + user_id)
			.then(function(response) {
				that.setState({ message: true });
			})
			.catch(function(error) {
				that.setState({ message: false });
			});

		that.setState({ loading: false });
	}

	render() {
		const override = css`
			display: block;
			margin: 0 auto;
			border-color: red;
		`;
		if (this.props.loginLoading) {
			return (
				<div className="sweet-loading">
					<div className="container loading-container">
						<div className="row m-5 p-5">
							<PulseLoader
								css={override}
								sizeUnit={"px"}
								size={24}
								color={"#B9373D"}
							/>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div className="my-4 client-roboto-condensed container">
					<div className="row">
						<div className="col"></div>
						<div className="col-12">
							<div className="text-center">
								<div className="content-section introduction">
									<div className="feature-intro">
										<p
											style={{
												fontSize: "small"
											}}
										>
											{this.state.message ? (
												<h5>
													Successfully unsubscribed from Next Level
													Value Email
												</h5>
											) : null}
										</p>
										<h5 className="text-center">
											<Link to="/">Home</Link>
										</h5>
									</div>
								</div>
							</div>
						</div>
						<div className="col"></div>
					</div>
				</div>
			);
		}
	}
}
