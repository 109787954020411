const companyRatesActionTypes = {
  SHOW_COMPANY_RATES_REQUEST: "show.company.rates.request",
  SHOW_COMPANY_RATES_SUCCESS: "show.company.rates.success",
  SHOW_COMPANY_RATES_FALIURE: "show.company.rates.faliure",

  ADD_COMPANY_RATES_REQUEST: "add.company.rates.request",
  ADD_COMPANY_RATES_SUCCESS: "add.company.rates.success",
  ADD_COMPANY_RATES_FALIURE: "add.company.rates.faliure",

  EDIT_COMPANY_RATES_REQUEST: "edit.company.rates.request",
  EDIT_COMPANY_RATES_SUCCESS: "edit.company.rates.success",
  EDIT_COMPANY_RATES_FALIURE: "edit.company.rates.faliure",

  DELETE_COMPANY_RATES_REQUEST: "delete.company.rates.request",
  DELETE_COMPANY_RATES_SUCCESS: "delete.company.rates.success",
  DELETE_COMPANY_RATES_FALIURE: "delete.company.rates.faliure"
};
export default companyRatesActionTypes;
