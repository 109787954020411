import { Api } from "./../../config/api";

async function showYear() {
  let data = Api.get("year").then(function(response) {
    return response.data;
  });

  if (data) {
    return data;
  } else {
    return false;
  }
}

function showSingleYear(params) {
  let data = Api.get("year/" + params)
    .then(function(response) {
      return response.data[0];
    })
    .catch(function(error) {
      return false;
    });

  if (data) {
    return data;
  } else {
    return false;
  }
}

async function addYear(params) {
  let data = Api.post("year", params).then(function(response) {
    return response.data;
  });

  if (data) {
    return data;
  } else {
    return false;
  }
}

async function editYear(params) {
  let data = Api.post("year/edit/" + params.id, params).then(function(
    response
  ) {
    return response.data;
  });

  if (data) {
    return data;
  } else {
    return false;
  }
}

async function deleteYear(params) {
  let data = Api.get("year/delete/" + params, params).then(function(response) {
    return response.data;
  });

  if (data) {
    return data;
  } else {
    return false;
  }
}

export const YearService = {
  showYear,
  addYear,
  editYear,
  deleteYear,
  showSingleYear
};
