import { SpreadsheetService } from "./service";
import { take, call, put } from "redux-saga/effects";
import spreadsheetActionTypes from "./actiontypes";

function* downloadXlsxFiles(params) {
  while (true) {
    let { params } = yield take(spreadsheetActionTypes.DOWNLOAD_XLSX_REQUEST);

    let { data } = yield call(SpreadsheetService.downloadXlsxFiles, params);

    if (data !== "" && data !== null && data !== [] && data) {
      yield put({
        type: spreadsheetActionTypes.DOWNLOAD_XLSX_SUCCESS,
        message: "File download successful",
        file: data
      });
    } else {
      yield put({
        type: spreadsheetActionTypes.DOWNLOAD_XLSX_FALIURE,
        message: "File download unsuccessful"
      });
    }
  }
}

function* uploadXlsxFile(params) {
  while (true) {
    let { params } = yield take(spreadsheetActionTypes.UPLOAD_XLSX_REQUEST);

    let { data } = yield call(SpreadsheetService.uploadXlsxFile, params);

    if (data !== "" && data !== null && data !== [] && data) {
      yield put({
        type: spreadsheetActionTypes.UPLOAD_XLSX_SUCCESS,
        message: "File upload successful"
      });
    } else {
      yield put({
        type: spreadsheetActionTypes.UPLOAD_XLSX_FALIURE,
        message: "File upload unsuccessful"
      });
    }
  }
}

export { downloadXlsxFiles, uploadXlsxFile };
