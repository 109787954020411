import { Api } from "./../../config/api";

function showCompatiblePartsForCategory(param) {
  let data = Api.get("compatibility")
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {
      return false;
    });

  if (data) {
    return data;
  } else {
    return false;
  }
}

function showCompatibilityForId(id) {
  let data = Api.get("compatibility/" + id)
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {
      return false;
    });

  if (data) {
    return data;
  } else {
    return false;
  }
}

function showAllCategories() {
  return Api.get("machine-category").then(function(response) {
    return response.data;
  });
}

function showSingleMachineCategory(id) {
  let data = Api.get("machine-category/" + id)
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {
      return false;
    });

  if (data) {
    return data;
  } else {
    return false;
  }
}

function showAllMachineParts() {
  let data = Api.get("machine-parts")
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {
      return false;
    });

  if (data) {
    return data;
  } else {
    return false;
  }
}

function getTreeCategory() {
  let data = Api.get("machine-category/showTreeSelectData")
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {
      return false;
    });

  if (data) {
    return data;
  } else {
    return false;
  }
}

function updateCompatibility(parts_array, id) {
  let data = Api.post("compatibility/" + id, parts_array)
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {
      return false;
    });

  if (data) {
    return data;
  } else {
    return false;
  }
}

export const CompatibilityService = {
  showCompatiblePartsForCategory,
  showAllCategories,
  showCompatibilityForId,
  getTreeCategory,
  showSingleMachineCategory,
  updateCompatibility,
  showAllMachineParts
};
