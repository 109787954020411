import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Api } from "./../../../config/api";
import { PickList } from "primereact/picklist";
import { notification } from "antd";

class UpdateUserHierarchy extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			source: [],
			target: [],
			redirect: false
		};
		this.onChange = this.onChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}
	Pfor;

	async onSubmit(e) {
		e.preventDefault();
		let that = this;
		let { user_id } = that.props.match.params;
		let params = {
			child_users: that.state.target
		};
		Api.post("/user-hierarchy/addChildrenUsers/" + user_id, params)
			.then(function(response) {
				that.setState({ redirect: true });
			})
			.catch(function(error) {
				notification.info({
					message: "Hierarchy not updated!"
				});
			});
		that.setState({ loading: false });
	}

	async componentDidMount() {
		let { user_id } = this.props.match.params;
		let that = this;

		await Api.get("user-hierarchy/getPossibleChildrenUsers/" + user_id).then(
			function(response) {
				that.setState({ source: response.data.possible_users });
				that.setState({ target: response.data.selected_users });
			}
		);

		that.setState({ loading: false });
	}

	pickListTemplate(users) {
		return (
			<div className="p-clearfix">
				<div
					style={{
						fontSize: "14px",
						float: "right",
						margin: "15px 5px 0 0"
					}}
				>
					{users.name + "  -  " + users.email + "  -  " + users.role_name}
				</div>
			</div>
		);
	}

	async onChange(event) {
		await this.setState({ source: event.source, target: event.target });
	}

	render() {
		if (this.state.redirect) {
			return <Redirect to="/users" />;
		} else {
			if (this.state.loading) {
				return (
					<div>
						<div
							className="content"
							style={{
								minHeight: 500
							}}
						>
							<div className="row">
								<div className="col-sm-12">
									<div className="card">
										<div className="card-header">
											<strong className="card-title">
												Update User Hierarchy
											</strong>
										</div>
										<div className="card-body">
											<div className="card-content">
												<div className="todo-list">
													<div className="tdl-holder">
														<div className="tdl-content">
															<div className="row">
																<div className="col"></div>
																<div className="col">
																	<div className="text-center">
																		<Loader
																			type="Rings"
																			color="#00BFFF"
																			height={100}
																			width={100}
																		/>
																		<div className="text-center client-roboto-condensed">
																			LOADING...
																		</div>
																	</div>
																</div>
																<div className="col"></div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				);
			} else {
				return (
					<div>
						<div
							className="content"
							style={{
								minHeight: 500
							}}
						>
							<div className="row">
								<div className="col-sm-12">
									<div className="card">
										<div className="card-header">
											<strong className="card-title">
												Update User Hierarchy
											</strong>
										</div>
										<div className="card-body">
											<form>
												<div className="mb-4">
													<PickList
														source={this.state.source}
														target={this.state.target}
														itemTemplate={this.pickListTemplate}
														sourceHeader="Available Users"
														targetHeader="Selected Users"
														responsive={true}
														showSourceControls={false}
														showTargetControls={true}
														sourceStyle={{
															height: "400px"
														}}
														targetStyle={{
															height: "400px"
														}}
														onChange={this.onChange}
													></PickList>
												</div>
												<div>
													<button
														id="payment-button"
														type="submit"
														onClick={this.onSubmit}
														className="btn btn-secondary btn-block"
													>
														<i className="fa fa-user"></i>&nbsp;
														<span id="payment-button-amount">
															Update Hierarchy
														</span>
													</button>
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				);
			}
		}
	}
}

const mapStateToProps = state => {
	return {
		isAuthenticated: state.loginReducer.isAuthenticated,
		token: state.loginReducer.token,
		user: state.loginReducer.user.user
	};
};

export default connect(mapStateToProps, {})(UpdateUserHierarchy);
