const countryActionTypes = {
  SHOW_COUNTRY_REQUEST: "show.country.request",
  SHOW_COUNTRY_SUCCESS: "show.country.success",
  SHOW_COUNTRY_FALIURE: "show.country.faliure",

  ADD_COUNTRY_REQUEST: "add.country.request",
  ADD_COUNTRY_SUCCESS: "add.country.success",
  ADD_COUNTRY_FALIURE: "add.country.faliure",

  UPDATE_COUNTRY_REQUEST: "update.country.request",
  UPDATE_COUNTRY_SUCCESS: "update.country.success",
  UPDATE_COUNTRY_FALIURE: "update.country.faliure",

  DELETE_COUNTRY_REQUEST: "DELETE.country.request",
  DELETE_COUNTRY_SUCCESS: "DELETE.country.success",
  DELETE_COUNTRY_FALIURE: "DELETE.country.faliure",

  CLEAN_COUNTRY_MESSAGE_REQUEST: "clean.country.message.request",
  CLEAN_COUNTRY_MESSAGE_SUCCESS: "clean.country.message.success",
  CLEAN_COUNTRY_MESSAGE_FALIURE: "clean.country.message.faliure"
};
export default countryActionTypes;
