import React, { Component } from "react";
import { Api } from "./../../../config/api";
import { Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import config from "./../../../config/config";
import {connect} from 'react-redux';
import { setToken } from "./../../../config/api";

class Dashboard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			numerical_data: []
		};
	}

	async componentDidMount() {
		let redirect = this;

		await setToken(this.props.token);
		let search = await window.location.search;
		let params = await new URLSearchParams(search);
		let foo = await params.get('token');


		if (foo !== null && foo !== undefined && foo !== "") {
			window.location.href =  window.location.href.split("?")[0]
		}

		await Api.get(config.APP_URL + "/api/dashboard/data").then(function(
			response
		) {
			redirect.setState({ numerical_data: response.data });
		});

		redirect.setState({ loading: false });
	}

	render() {
		if (this.state.loading) {
			return (
				<div>
					<div
						className="content"
						style={{
							minHeight: 500
						}}
					>
						<div className="row">
							<div className="col-sm-12">
								<div className="card m-3 col-sm-12">
									<div className="row">
										<div className="col"></div>
										<div className="col">
											<div className="text-center">
												<Loader
													type="Rings"
													color="#00BFFF"
													height={100}
													width={100}
												/>
												<div className="text-center client-roboto-condensed">
													LOADING...
												</div>
											</div>
										</div>
										<div className="col"></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div>
					<div
						className="content"
						style={{
							minHeight: 500
						}}
					>
						<div className="row">
							<div className="col"></div>
							<div className="col">
								<div className="text-center">
									<strong
										className="text-uppercase"
										style={{
											fontSize: 20
										}}
									>
										CPQ total data
									</strong>
								</div>
							</div>
							<div className="col"></div>
						</div>
						<div className="row">
							<div className="col-lg-3 col-md-6">
								<div className="card m-3">
									<div className="card-body">
										<div className="stat-widget-five">
											<div className="stat-icon dib flat-color-1">
												<i className="fa fa-wrench"></i>
											</div>
											<div className="stat-content">
												<div className="text-left dib">
													<div className="stat-text">
														<span className="">
															{
																this.state.numerical_data
																	.total_machine_parts
															}
														</span>
													</div>
													<div className="stat-heading">
														<Link
															to="/machine-part"
															style={{
																color: "#99abb4"
															}}
														>
															Machine Parts
														</Link>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-lg-3 col-md-6">
								<div className="card m-3">
									<div className="card-body">
										<div className="stat-widget-five">
											<div className="stat-icon dib flat-color-2">
												<i className="fa fa-object-group"></i>
											</div>
											<div className="stat-content">
												<div className="text-left dib">
													<div className="stat-text">
														<span className="">
															{
																this.state.numerical_data
																	.total_machine_categories
															}
														</span>
													</div>
													<div className="stat-heading">
														<Link
															to="/machine-category"
															style={{
																color: "#99abb4"
															}}
														>
															Machine Categories
														</Link>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-lg-3 col-md-6">
								<div className="card m-3">
									<div className="card-body">
										<div className="stat-widget-five">
											<div className="stat-icon dib flat-color-5">
												<i className="fa fa-user"></i>
											</div>
											<div className="stat-content">
												<div className="text-left dib">
													<div className="stat-text">
														<span className="">
															{
																this.state.numerical_data
																	.total_users
															}
														</span>
													</div>
													<div className="stat-heading">
														<Link
															to="/users"
															style={{
																color: "#99abb4"
															}}
														>
															Users
														</Link>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-lg-3 col-md-6">
								<div className="card m-3">
									<div className="card-body">
										<div className="stat-widget-five">
											<div className="stat-icon dib flat-color-6">
												<i className="fa fa-users"></i>
											</div>
											<div className="stat-content">
												<div className="text-left dib">
													<div className="stat-text">
														<span className="">
															{
																this.state.numerical_data
																	.total_users_types
															}
														</span>
													</div>
													<div className="stat-heading">
														<Link
															to="/user-role"
															style={{
																color: "#99abb4"
															}}
														>
															User Roles
														</Link>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-lg-3 col-md-6">
								<div className="card m-3">
									<div className="card-body">
										<div className="stat-widget-five">
											<div className="stat-icon dib flat-color-7">
												<i className="fa fa-globe"></i>
											</div>
											<div className="stat-content">
												<div className="text-left dib">
													<div className="stat-text">
														<span className="">
															{
																this.state.numerical_data
																	.total_country_data
															}
														</span>
													</div>
													<div className="stat-heading">
														<Link
															to="/country"
															style={{
																color: "#99abb4"
															}}
														>
															Country Data
														</Link>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}
}
const mapStateToProps = state => {
  return {
    isAuthenticated: state.loginReducer.isAuthenticated,
    userRole: state.loginReducer.user.role,
    name: state.loginReducer.user.user.name,
    loginLoading: state.loginReducer.loading,
    user_id: state.loginReducer.user.user.id,
    role_id: state.loginReducer.user.role_id,
    token: state.loginReducer.token,
    language: state.languageReducer.selectedLanguage
  };
};

export default connect(mapStateToProps, { })(Dashboard);
