import React, { Component } from "react";
import { connect } from "react-redux";
import { edit_countries } from "../action";
import { CurrencyService } from "../service";
import { Redirect } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

class UpdateCurrency extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			redirect: false,
			currency_name: "",
			currency_symbol_php: "",
			currency_symbol_html: ""
		};
		this.validator = new SimpleReactValidator({ autoForceUpdate: this });
		this.onTextChange = this.onTextChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	onTextChange(name, value) {
		let redirect = this;

		switch (name) {
			case "currency_name":
				redirect.setState({ currency_name: value });
				break;

			case "currency_symbol_php":
				redirect.setState({ currency_symbol_php: value });
				break;

			case "currency_symbol_html":
				redirect.setState({ currency_symbol_html: value });
				break;

			default:
				break;
		}
	}

	onSubmit(e) {
		e.preventDefault();
		let that = this;
		let { id } = this.props.match.params;
		const fd = new FormData();

		if (this.validator.allValid()) {
			fd.append("id", id);
			fd.append("currency_name", this.state.currency_name);
			fd.append("currency_symbol_php", this.state.currency_symbol_php);
			fd.append("currency_symbol_html", this.state.currency_symbol_html);

			let result = CurrencyService.editCurrency(fd);


			if (result) {
				that.setState({ redirect: true });
			} else {
			}
		} else {
			this.validator.showMessages();
		}
	}

	async componentDidMount() {
		let { id } = this.props.match.params;
		let redirect = this;

		let data = await CurrencyService.showSingleCurrency(id);

		redirect.setState({ currency_name: data[0].currency_name });
		redirect.setState({ currency_symbol_php: data[0].currency_symbol_php });
		redirect.setState({ currency_symbol_html: data[0].currency_symbol_html });

		redirect.setState({ loading: false });
	}

	render() {
		if (this.state.redirect) {
			return <Redirect to="/currency" />;
		} else {
			if (this.props.loading || this.state.loading) {
				return (
					<div>
						<div
							className="content"
							style={{
								minHeight: 500
							}}
						>
							<div className="row">
								<div className="col-sm-12">
									<div className="card">
										<div className="card-header">
											<strong className="card-title">
												Update Country Data
											</strong>
										</div>
										<div className="card-body">
											<div className="card-content">
												<div className="todo-list">
													<div className="tdl-holder">
														<div className="tdl-content">
															<div className="row">
																<div className="col"></div>
																<div className="col">
																	<div className="text-center">
																		<Loader
																			type="Rings"
																			color="#00BFFF"
																			height={100}
																			width={100}
																		/>
																		<div className="text-center client-roboto-condensed">
																			LOADING...
																		</div>
																	</div>
																</div>
																<div className="col"></div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				);
			} else {
				return (
					<div>
						<div
							className="content"
							style={{
								minHeight: 500
							}}
						>
							<div className="row">
								<div className="col-sm-12">
									<div className="card">
										<div className="card-header">
											<strong className="card-title">
												Update Country Data
											</strong>
										</div>
										<div className="card-body">
											<form>
												<div className="row">
													<div className="col-6">
														<div className="form-group">
															<label
																htmlFor="currency_name"
																className="control-label mb-1"
															>
																Currency Name
															</label>
															<input
																id="currency_name"
																name="currency_name"
																type="text"
																className="form-control"
																aria-required="true"
																onChange={e =>
																	this.onTextChange(
																		"currency_name",
																		e.target.value
																	)
																}
																value={this.state.currency_name}
															/>
															<div
																className="mt-4"
																style={{
																	color: "red"
																}}
															>
																{this.validator.message(
																	"currency_name",
																	this.state.currency_name,
																	"required|string"
																)}
															</div>
														</div>
													</div>
													<div className="col-6">
														<div className="form-group">
															<label
																htmlFor="currency_symbol_php"
																className="control-label mb-1"
															>
																Currency Symbol PHP
															</label>
															<input
																id="currency_symbol_php"
																name="currency_symbol_php"
																type="text"
																className="form-control"
																aria-required="true"
																onChange={e =>
																	this.onTextChange(
																		"currency_symbol_php",
																		e.target.value
																	)
																}
																value={
																	this.state
																		.currency_symbol_php
																}
															/>
															<div
																className="mt-4"
																style={{
																	color: "red"
																}}
															>
																{this.validator.message(
																	"currency_symbol_php",
																	this.state
																		.currency_symbol_php,
																	"required|string"
																)}
															</div>
														</div>
													</div>
													<div className="col-6">
														<div className="form-group">
															<label
																htmlFor="currency_symbol_html"
																className="control-label mb-1"
															>
																Currency Symbol Html
															</label>
															<input
																id="currency_symbol_html"
																name="currency_symbol_html"
																type="text"
																className="form-control"
																aria-required="true"
																onChange={e =>
																	this.onTextChange(
																		"currency_symbol_html",
																		e.target.value
																	)
																}
																value={
																	this.state
																		.currency_symbol_html
																}
															/>
															<div
																className="mt-4"
																style={{
																	color: "red"
																}}
															>
																{this.validator.message(
																	"currency_symbol_html",
																	this.state
																		.currency_symbol_html,
																	"required|string"
																)}
															</div>
														</div>
													</div>
												</div>

												<div>
													<button
														onClick={this.onSubmit}
														id="payment-button"
														type="submit"
														className="btn btn-secondary btn-block"
													>
														<i className="fa fa-truck"></i>&nbsp;
														<span id="payment-button-amount">
															Update Currency
														</span>
													</button>
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				);
			}
		}
	}
}

const mapStateToProps = state => {
	return {
		message: state.countryReducer.message,
		loading: state.countryReducer.loading
	};
};

export default connect(mapStateToProps, {
	edit_countries
})(UpdateCurrency);
