import React, { Component } from "react";
import { connect } from "react-redux";
import {TemplateService} from './../service'
import SimpleReactValidator from "simple-react-validator";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Redirect } from "react-router";

class EditTemplate extends Component {
	constructor(props) {
		super(props);
		this.state = {
            loading: true,
            template_name: "",
            old_template_file: null,
            template_file: null,
            redirect: false
		};
		this.validator = new SimpleReactValidator({ autoForceUpdate: this });
		this.onSubmit = this.onSubmit.bind(this);
		this.handleFileChange = this.handleFileChange.bind(this);
	}

	handleFileChange(event) {
		this.setState({ template_file: event.target.files[0] });
	}

	async componentDidMount() {
        let {id } = this.props.match.params;

        let template = await TemplateService.getTemplate(id)

        this.setState({template_name: template.template_name})
        this.setState({old_template_file: template.template_file})

        this.setState({loading: false
        })
    }

	async onSubmit(e) {
        e.preventDefault();
        let {id} = this.props.match.params;
        const fd = new FormData();

		if (this.validator.allValid()) {

			fd.append("id", id);
			fd.append("template_name", this.state.template_name);

			if (this.state.template_file !== null) {
				fd.append("file", this.state.template_file);
            }
        
            let data = await TemplateService.editTemplate(fd)

            if(data.message.includes('Template updated successfully')){
                this.setState({redirect: true})
            }

		} else {
			this.validator.showMessages();
		}
	}

	onTextChange(name, value) {
		let redirect = this;

		switch (name) {
			case "template_name":
				redirect.setState({ template_name: value });
				break;

			default:
				break;
		}
	}

	render() {
        if(this.state.redirect){
            return(
                <Redirect to="/template" />
            )
        }else{
            if (this.state.loading) {
                return (
                    <div>
                        <div
                            className="content"
                            style={{
                                minHeight: 500
                            }}
                        >
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <strong className="card-title">Edit Template</strong>
                                        </div>
                                        <div className="card-body">
                                            <div className="card-content">
                                                <div className="todo-list">
                                                    <div className="tdl-holder">
                                                        <div className="tdl-content">
                                                            <div className="row">
                                                                <div className="col"></div>
                                                                <div className="col">
                                                                    <div className="text-center">
                                                                        <Loader
                                                                            type="Rings"
                                                                            color="#00BFFF"
                                                                            height={100}
                                                                            width={100}
                                                                        />
                                                                        <div className="text-center client-roboto-condensed">
                                                                            LOADING...
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            } else {
                return (
                    <div>
                        <div
                            className="content"
                            style={{
                                minHeight: 500
                            }}
                        >
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <strong className="card-title">Edit Template</strong>
                                        </div>
                                        <div className="card-body">
                                            <form>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="form-group">
                                                            <label
                                                                htmlFor="country_name"
                                                                className="control-label mb-1"
                                                            >
                                                                Template Name
                                                            </label>
                                                            <input
                                                                id="template_name"
                                                                name="template_name"
                                                                type="text"
                                                                className="form-control"
                                                                aria-required="true"
                                                                onChange={e =>
                                                                    this.onTextChange(
                                                                        "template_name",
                                                                        e.target.value
                                                                    )
                                                                }
                                                                value={this.state.template_name}
                                                            />
                                                            <div
                                                                className="mt-4"
                                                                style={{
                                                                    color: "red"
                                                                }}
                                                            >
                                                                {this.validator.message(
                                                                    "country_name",
                                                                    this.state.template_name,
                                                                    "required|string"
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <label
                                                                htmlFor="file"
                                                                className="control-label mb-1"
                                                            >
                                                                Old Template File
                                                            </label>
                                                            <br />
                                                            {this.state.old_template_file}
                                                        </div>
                                                        <div className="form-group">
                                                            <label
                                                                htmlFor="file"
                                                                className="control-label mb-1"
                                                            >
                                                                Template File
                                                            </label>
                                                            <br />
                                                            <input
                                                                className="form-group"
                                                                type="file"
                                                                name="template_file"
                                                                onChange={e => this.handleFileChange(e)}
																accept=".docx"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <button
                                                        onClick={this.onSubmit}
                                                        id="payment-button"
                                                        type="submit"
                                                        className="btn btn-secondary btn-block"
                                                    >
                                                        <i className="fa fa-truck"></i>&nbsp;
                                                        <span id="payment-button-amount">
                                                            Edit Template
                                                        </span>
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }

        }
	}
}

const mapStateToProps = state => {
	return {
		message: state.countryReducer.message,
		loading: state.countryReducer.loading
	};
};

export default connect(mapStateToProps, {})(EditTemplate);
