import { Api } from "./../../config/api";

function showQuotes() {
	let data = Api.get("quote")
		.then(function(response) {
			return response.data;
		})
		.catch(function(error) {
			return false;
		});

	if (data) {
		return data;
	} else {
		return false;
	}
}

function showSingleQuote(param) {
	let data = Api.get("quote/" + param)
		.then(function(response) {
			return response.data;
		})
		.catch(function(error) {
			return false;
		});

	if (data) {
		return data;
	} else {
		return false;
	}
}

function showQuoteData(param) {
	let data = Api.get("quote/quote-tracking/" + param)
		.then(function(response) {
			return response.data;
		})
		.catch(function(error) {
			return false;
		});

	if (data) {
		return data;
	} else {
		return false;
	}
}

function showQuoteWithFileStatus() {
	let data = Api.get("quote/quoteFileStatusCheck")
		.then(function(response) {
			return response.data;
		})
		.catch(function(error) {
			return false;
		});

	if (data) {
		return data;
	} else {
		return false;
	}
}

export const QuoteService = {
	showQuotes,
	showSingleQuote,
	showQuoteData,
	showQuoteWithFileStatus
};
