import { YearService } from "./service";
import { take, call, put } from "redux-saga/effects";
import yearActionTypes from "./actionTypes";

function* cleanYearMessage() {
  while (true) {
    yield take(yearActionTypes.CLEAN_MESSAGE_REQUEST);

    yield put({ type: yearActionTypes.CLEAN_MESSAGE_SUCCESS });
  }
}

function* showYear() {
  while (true) {
    yield take(yearActionTypes.SHOW_YEAR_REQUEST);

    const year = yield call(YearService.showYear);

    if (year !== "" && year !== null && year !== [] && year) {
      yield put({
        type: yearActionTypes.SHOW_YEAR_SUCCESS,
        message: "Getting Year Success",
        year: year
      });
    } else {
      yield put({
        type: yearActionTypes.SHOW_YEAR_FALIURE,
        message: "Getting Year Faliure"
      });
    }
  }
}

function* showSingleYear(param) {
  while (true) {
    const { param } = yield take(yearActionTypes.SHOW_SINGLE_YEAR_REQUEST);
    const year = yield call(YearService.showSingleYear, param);

    if (year !== "" && year !== null && year) {
      yield put({
        type: yearActionTypes.SHOW_SINGLE_YEAR_SUCCESS,
        message: "Getting Single Year Success",
        single_year: year
      });
    } else {
      yield put({
        type: yearActionTypes.SHOW_SINGLE_YEAR_FALIURE,
        message: "Getting SingleYear Faliure"
      });
    }
  }
}

function* addYear(params) {
  while (true) {
    const { params } = yield take(yearActionTypes.ADD_YEAR_REQUEST);

    const data = yield call(YearService.addYear, params);

    if (data !== "" && data !== null && data) {
      yield put({
        type: yearActionTypes.ADD_YEAR_SUCCESS,
        message: "Adding Year Success"
      });
    } else {
      yield put({
        type: yearActionTypes.ADD_YEAR_FALIURE,
        message: "Adding Year Faliure"
      });
    }
  }
}

function* editYear(params) {
  while (true) {
    const { params } = yield take(yearActionTypes.EDIT_YEAR_REQUEST);

    const data = yield call(YearService.editYear, params);

    if (data !== "" && data !== null && data) {
      yield put({
        type: yearActionTypes.EDIT_YEAR_SUCCESS,
        message: "Editing Year Success"
      });
    } else {
      yield put({
        type: yearActionTypes.EDIT_YEAR_FALIURE,
        message: "Editing Year Faliure"
      });
    }
  }
}

function* deleteYear(params) {
  while (true) {
    const { params } = yield take(yearActionTypes.DELETE_YEAR_REQUEST);

    const data = yield call(YearService.deleteYear, params);

    if (data !== "" && data !== null && data) {
      yield put({
        type: yearActionTypes.DELETE_YEAR_SUCCESS,
        message: "Deleting Year Success"
      });
    } else {
      yield put({
        type: yearActionTypes.DELETE_YEAR_FALIURE,
        message: "Deleting Year Faliure"
      });
    }
  }
}

export {
  showYear,
  showSingleYear,
  cleanYearMessage,
  addYear,
  editYear,
  deleteYear
};
