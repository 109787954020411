import brandActionTypes from "./actionTypes";
const INITIAL_STATE = {
  message: "",
  loading: false,
  brands: false,
  brand: false
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case brandActionTypes.SHOW_BRAND_REQUEST:
      return {
        ...state,
        loading: true
      };
    case brandActionTypes.SHOW_BRAND_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
        brands: action.brands
      };
    case brandActionTypes.SHOW_BRAND_FALIURE:
      return {
        ...state,
        loading: false,
        message: action.message
      };

    case brandActionTypes.ADD_BRAND_REQUEST:
      return {
        ...state,
        loading: true
      };
    case brandActionTypes.ADD_BRAND_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message
      };
    case brandActionTypes.ADD_BRAND_FALIURE:
      return {
        ...state,
        loading: false,
        message: action.message
      };

    case brandActionTypes.EDIT_BRAND_REQUEST:
      return {
        ...state,
        loading: true
      };
    case brandActionTypes.EDIT_BRAND_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message
      };
    case brandActionTypes.EDIT_BRAND_FALIURE:
      return {
        ...state,
        loading: false,
        message: action.message
      };

    case brandActionTypes.DELETE_BRAND_REQUEST:
      return {
        ...state,
        loading: true
      };
    case brandActionTypes.DELETE_BRAND_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message
      };
    case brandActionTypes.DELETE_BRAND_FALIURE:
      return {
        ...state,
        loading: false,
        message: action.message
      };

    case brandActionTypes.CLEAN_MESSAGE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case brandActionTypes.CLEAN_MESSAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        message: ""
      };
    case brandActionTypes.CLEAN_MESSAGE_FALIURE:
      return {
        ...state,
        loading: false
      };

    default:
      return state;
  }
}
