import React, { Component } from "react";
import { connect } from "react-redux";

import SimpleReactValidator from "simple-react-validator";
import { css } from "@emotion/react";
import { PulseLoader } from "react-spinners";
import { ClientConfigurationService } from "./../../configuration/service";
import { CompanyRatesService } from "./../../../admin/company_rates/service";
import { ResidualPercentageService } from "./../../../admin/residual_percentage/service";
import { LeaseCalculationService } from "./../service";
import Swal from "sweetalert2";
import noimage from "./../../../CPQ/noimage.png";
import config from "./../../../config/config";
import strings from "./../../../config/language";
import CurrencyFormat from "react-currency-format";
import getSymbolFromCurrency from "currency-symbol-map";

class LeaseCalculation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			id: false,
			configuration: false,
			lease: false,
			residual_percentages: false
		};

		this.validator = new SimpleReactValidator({ autoForceUpdate: this });
		this.handleSelectChange = this.handleSelectChange.bind(this);
		this.handleTextChange = this.handleTextChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	async onSubmit(e) {
		e.preventDefault();
		let lease_data = this.state.lease;

		lease_data.forEach(element => {
			element.configuration_id = this.state.id;
		});

		let params = {
			lease_data: lease_data
		};

		let checker = await LeaseCalculationService.addOrUpdateLeaseforConfiguration(
			params,
			this.state.id
		);

		if (checker) {
			Swal.fire({
				title: "Success!",
				text: "Lease data for configuration for updated successfully!",
				icon: "success",
				confirmButtonText: "OK"
			});
		} else {
			Swal.fire({
				title: "Error!",
				text: "Lease data for configuration for updated error!",
				icon: "error",
				confirmButtonText: "OK"
			});
		}
	}

	async handleTextChange(value, name, i) {
		let lease = this.state.lease;

		switch (name) {
			case "residual_value":
				if (!isNaN(value)) {
					lease[i].residual_value = value;
				}
				break;

			case "service_cost":
				if (!isNaN(value)) {
					lease[i].service_cost = value;
				}
				break;

			case "interest_rate":
				if (!isNaN(value) && value < 100) {
					lease[i].interest_rate = value;
				}
				break;

			default:
				break;
		}

		lease[i].price_per_month = await this.leaseCalcualtionFormula(
			parseInt(lease[i].net_total),
			parseInt(lease[i].residual_value),
			parseFloat(lease[i].interest_rate),
			parseInt(lease[i].lease_duration)
		);

		this.setState({ lease: lease });
	}

	handleSelectChange(value, i) {
		let lease = this.state.lease;

		lease[i].lease_duration = value;

		lease[i].residual_value =
			(lease[i].net_total *
				this.state.residual_percentages.filter(
					lease1 => lease1.year === parseInt(value)
				)[0].percentage) /
			100;
		lease[i].price_per_month = this.leaseCalcualtionFormula(
			parseInt(lease[i].net_total),
			parseInt(lease[i].residual_value),
			parseFloat(lease[i].interest_rate),
			parseInt(lease[i].lease_duration)
		);

		this.setState({ lease: lease });
	}

	leaseCalcualtionFormula(net_total, residual_value, rate, time) {
		let monthly_payment =
			((-net_total * Math.pow(1 + rate / 1200, time) + residual_value) *
				rate) /
			1200 /
			(1 - Math.pow(1 + rate / 1200, time));
		return monthly_payment.toFixed(2);
	}

	async componentDidMount() {
		let that = this;
		let { id } = that.props.match.params;
		let rates = await CompanyRatesService.showCompanyRates();
		let residual_percentages = await ResidualPercentageService.showAllResudualPercentage();

		await residual_percentages.forEach(element => {
			element.year = element.year * 12;
		});
		that.setState({ residual_percentages: residual_percentages });

		let existing_lease = await LeaseCalculationService.getAllLeaseForConfiguration(
			id
		);
		let interest_rate = 0;
		let service_cost = 0;

		rates.forEach(element => {
			if (element.rate_name.toLowerCase().includes("interest")) {
				interest_rate = element.rate_value;
			}
			if (element.rate_name.toLowerCase().includes("service")) {
				service_cost = element.rate_value;
			}
		});

		that.setState({ id: id });

		let configuration = false;
		if (this.props.is_support) {
			configuration = await ClientConfigurationService.showSingleUserConfigurationSalesSupport(
				this.props.user_id,
				id
			);
		} else {
			configuration = await ClientConfigurationService.showSingleUserConfiguration(
				this.props.user_id,
				id
			);
		}
		that.setState({ configuration: configuration.configuration[0] });

		let lease = false;
		if (existing_lease.length > 0) {
			lease = existing_lease;

			await lease.forEach(element => {
				element.residual_value = parseFloat(element.residual_value).toFixed(
					2
				);
			});
		} else {
			lease = [];
			residual_percentages.forEach((element, i) => {
				if (i < 3) {
					lease.push({
						lease_duration: element.year,
						configuration_quantity:
							configuration.configuration[0].quantity,
						net_total: parseFloat(
							configuration.configuration[0].machine_total_price -
								(configuration.configuration[0].discount_percentage *
									configuration.configuration[0].machine_total_price) /
									100 -
								configuration.configuration[0].discount_amount
						).toFixed(2),
						interest_rate: interest_rate,
						residual_value: 0,
						service_cost: service_cost,
						price_per_month: 0
					});
				}
			});
			// lease = [
			// 	{
			// 		lease_duration: residual_percentages[0]
			// 			? residual_percentages[0].year
			// 			: 0,
			// 		configuration_quantity: configuration.configuration[0].quantity,
			// 		net_total: parseFloat(
			// 			configuration.configuration[0].machine_total_price -
			// 				(configuration.configuration[0].discount_percentage *
			// 					configuration.configuration[0].machine_total_price) /
			// 					100 -
			// 				configuration.configuration[0].discount_amount
			// 		).toFixed(2),
			// 		interest_rate: interest_rate,
			// 		residual_value: 0,
			// 		service_cost: service_cost,
			// 		price_per_month: 0
			// 	},
			// 	{
			// 		lease_duration: residual_percentages[1]
			// 			? residual_percentages[1].year
			// 			: 0,
			// 		configuration_quantity: configuration.configuration[0].quantity,
			// 		net_total: parseFloat(
			// 			configuration.configuration[0].machine_total_price -
			// 				(configuration.configuration[0].discount_percentage *
			// 					configuration.configuration[0].machine_total_price) /
			// 					100 -
			// 				configuration.configuration[0].discount_amount
			// 		).toFixed(2),
			// 		interest_rate: interest_rate,
			// 		residual_value: 0,
			// 		service_cost: service_cost,
			// 		price_per_month: 0
			// 	},
			// 	{
			// 		lease_duration: residual_percentages[2]
			// 			? residual_percentages[2].year
			// 			: 0,
			// 		configuration_quantity: configuration.configuration[0].quantity,
			// 		net_total: parseFloat(
			// 			configuration.configuration[0].machine_total_price -
			// 				(configuration.configuration[0].discount_percentage *
			// 					configuration.configuration[0].machine_total_price) /
			// 					100 -
			// 				configuration.configuration[0].discount_amount
			// 		).toFixed(2),
			// 		interest_rate: interest_rate,
			// 		residual_value: 0,
			// 		service_cost: service_cost,
			// 		price_per_month: 0
			// 	}
			// ];

			await lease.forEach(element => {
				element.net_total =
					element.net_total / element.configuration_quantity;
				element.residual_value = parseFloat(
					(element.net_total *
						residual_percentages.filter(
							element1 => element1.year === element.lease_duration
						)[0].percentage) /
						100
				).toFixed(2);

				element.price_per_month = this.leaseCalcualtionFormula(
					parseInt(element.net_total),
					parseInt(element.residual_value),
					parseFloat(element.interest_rate),
					parseInt(element.lease_duration)
				);
			});
		}

		that.setState({ lease: lease });

		await this.setState({ loading: false });
	}

	render() {
		strings.setLanguage(this.props.language);
		const override = css`
			display: block;
			margin: 0 auto;
			border-color: red;
		`;
		if (this.state.loading) {
			return (
				<div className="sweet-loading">
					<div className="container loading-container">
						<div className="row m-5 p-5">
							<PulseLoader
								css={override}
								sizeUnit={"px"}
								size={24}
								color={"#B9373D"}
							/>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div className="m-5 row">
					<div className="container">
						<div className="text-center">
							<h3>Configuration Details</h3>
						</div>
						<div className="row my-5">
							<div className="col-sm-5">
								<div className="">
									{this.state.configuration.machine_category_image !==
										[] &&
									this.state.configuration.machine_category_image !==
										null ? (
										<img
											loading="lazy"
											width="70%"
											height="70%"
											className="img-responsive"
											alt=""
											src={
												config.APP_URL +
												"/api/storage/" +
												this.state.configuration
													.machine_category_image
											}
										/>
									) : (
										<img
											loading="lazy"
											width="70%"
											height="70%"
											className="img-responsive"
											alt=""
											src={noimage}
										/>
									)}
								</div>
							</div>
							<div className="col-sm-6">
								<div className="client-config-details ml-5 mr-5 mb-5">
									<h5 className="client-config">
										<span>Configuration Name:</span>
										<span>
											{this.state.configuration !== []
												? this.state.configuration.name
												: ""}
										</span>
									</h5>
									<h5 className="client-config">
										<span>Configuration Number:</span>
										<span>
											{this.props.configuration !== []
												? this.state.configuration.configuration_id
												: ""}
										</span>
									</h5>
									<h5 className="client-config">
										<span>Category:</span>
										<span>
											{this.props.configuration !== []
												? this.state.configuration.machine_category
												: ""}
										</span>
									</h5>
									{this.props.configuration !== [] &&
									this.state.configuration.discount_percentage !==
										0 ? (
										<h5 className="client-config">
											<span>Discount Percentage:</span>
											<span>
												{
													this.state.configuration
														.discount_percentage
												}{" "}
												%
											</span>
										</h5>
									) : null}
									{this.props.configuration !== [] &&
									this.state.configuration.discount_amount !== 0 ? (
										<h5 className="client-config">
											<span>Discount Amount:</span>
											<span>
												{this.state.configuration.discount_amount}{" "}
												{getSymbolFromCurrency(
													this.state.configuration
														.currency_symbol_html
												)}
											</span>
										</h5>
									) : null}
									<h5 className="client-config">
										<span>Net Price:</span>
										<span>
											{this.props.configuration !== [] ? (
												<CurrencyFormat
													value={parseFloat(
														this.state.configuration
															.machine_total_price /
															this.state.configuration.quantity -
															(this.state.configuration
																.discount_percentage *
																(this.state.configuration
																	.machine_total_price /
																	this.state.configuration
																		.quantity)) /
																100 -
															this.state.configuration
																.discount_amount
													)}
													decimalSeparator={"."}
													decimalScale={2}
													fixedDecimalScale={true}
													displayType={"text"}
													thousandSeparator={true}
													suffix={
														" " +
														getSymbolFromCurrency(
															this.state.configuration
																.currency_symbol_html
														) +
														" "
													}
												/>
											) : null}
										</span>
									</h5>
									{this.props.configuration !== [] &&
									this.state.configuration.quantity !== 0 ? (
										<h5 className="client-config">
											<span>Configuration Quantity:</span>
											<span>
												{this.state.configuration.quantity}
											</span>
										</h5>
									) : null}
									<h5 className="client-config">
										<span>Net Total:</span>
										<span>
											{this.state.configuration !== [] ? (
												<CurrencyFormat
													value={
														parseFloat(
															this.state.configuration
																.machine_total_price /
																this.state.configuration
																	.quantity -
																(this.state.configuration
																	.discount_percentage *
																	(this.state.configuration
																		.machine_total_price /
																		this.state.configuration
																			.quantity)) /
																	100 -
																this.state.configuration
																	.discount_amount
														) * this.state.configuration.quantity
													}
													decimalSeparator={"."}
													decimalScale={2}
													fixedDecimalScale={true}
													displayType={"text"}
													thousandSeparator={true}
													suffix={
														" " +
														getSymbolFromCurrency(
															this.state.configuration
																.currency_symbol_html
														) +
														" "
													}
												/>
											) : null}
										</span>
									</h5>
									<h5>
										<span>Parts exchange (trade-in):</span>
										<span>
											{this.props.configuration[0].trade_in_value}
										</span>
									</h5>
									<h5>
										<span>Final Net total:</span>
										<span>
											{this.props.configuration !== [] ? (
												<CurrencyFormat
													className="py-1 pr-2 text-right"
													displayType={"text"}
													decimalSeparator={"."}
													decimalScale={2}
													fixedDecimalScale={true}
													value={
														parseFloat(
															this.props.configuration[0]
																.machine_total_price /
																this.props.configuration[0]
																	.quantity -
																(this.props.configuration[0]
																	.discount_percentage *
																	(this.props.configuration[0]
																		.machine_total_price /
																		this.props
																			.configuration[0]
																			.quantity)) /
																	100 -
																this.props.configuration[0]
																	.discount_amount -
																this.props.configuration[0]
																	.dealer_discount_amount -
																this.props.configuration[0]
																	.oem_discount -
																parseFloat(
																	(this.props.configuration[0]
																		.extra_discount *
																		parseFloat(
																			this.props
																				.configuration[0]
																				.machine_total_price /
																				this.props
																					.configuration[0]
																					.quantity -
																				(this.props
																					.configuration[0]
																					.discount_percentage *
																					(this.props
																						.configuration[0]
																						.machine_total_price /
																						this.props
																							.configuration[0]
																							.quantity)) /
																					100 -
																				this.props
																					.configuration[0]
																					.discount_amount -
																				this.props
																					.configuration[0]
																					.dealer_discount_amount -
																				this.props
																					.configuration[0]
																					.oem_discount
																		).toFixed(2)) /
																		100
																).toFixed(2)
														) *
															this.props.configuration[0]
																.quantity -
														this.props.configuration[0]
															.trade_in_value
													}
													thousandSeparator={true}
													suffix={
														" " +
														getSymbolFromCurrency(
															this.props.configuration[0]
																.currency_symbol_html
														)
													}
												/>
											) : null}
										</span>
									</h5>
								</div>
							</div>
						</div>
						<div className="text-center">
							<h3>Lease Calculation</h3>
						</div>
						<div className="row">
							<div className="col">
								<table className="table table-striped">
									<thead
										style={{
											backgroundColor: "#B9373D",
											color: "white"
										}}
									>
										<tr>
											<th>
												<h6 className="text-white">Lease Data</h6>
											</th>
											{this.state.lease.map((lease, i) => (
												<td key={i} className="text-white">
													{i + 1}
												</td>
											))}
										</tr>
									</thead>
									<tbody>
										<tr>
											<th>
												<h6>Lease Duration</h6>
											</th>
											{this.state.lease.map((lease, i) =>
												this.props.lease_editable ? (
													<td key={i}>
														<select
															onChange={e =>
																this.handleSelectChange(
																	e.target.value,
																	i
																)
															}
															className="custom-select"
															value={lease.lease_duration}
														>
															{this.state.residual_percentages
																? this.state.residual_percentages.map(
																		(
																			residual_percentage,
																			i
																		) => (
																			<option
																				key={i}
																				value={
																					residual_percentage.year
																				}
																			>
																				{
																					residual_percentage.year
																				}
																			</option>
																		)
																  )
																: null}
														</select>
													</td>
												) : (
													<td key={i}>{lease.lease_duration}</td>
												)
											)}
										</tr>
										<tr>
											<th>
												<h6>Configuration Quantity</h6>
											</th>
											{this.state.lease.map((lease, i) => (
												<td key={i}>
													{lease.configuration_quantity}
												</td>
											))}
										</tr>
										<tr>
											<th>
												<h6>
													Configuration Net Total [Per Machine]
												</h6>
											</th>
											{this.state.lease.map((lease, i) => (
												<td key={i}>
													<CurrencyFormat
														value={lease.net_total}
														decimalSeparator={"."}
														decimalScale={2}
														fixedDecimalScale={true}
														displayType={"text"}
														thousandSeparator={true}
														suffix={
															" " +
															getSymbolFromCurrency(
																this.state.configuration
																	.currency_symbol_html
															) +
															" "
														}
													/>
												</td>
											))}
										</tr>
										<tr>
											<th>
												<h6>Interest Rate</h6>
											</th>
											{this.state.lease.map((lease, i) =>
												this.props.lease_editable ? (
													<td key={i}>
														<input
															className="py-1 pr-2 text-right"
															onChange={e =>
																this.handleTextChange(
																	e.target.value,
																	"interest_rate",
																	i
																)
															}
															value={lease.interest_rate}
														/>{" "}
														%
													</td>
												) : (
													<td key={i}>
														{lease.interest_rate + " %"}
													</td>
												)
											)}
										</tr>
										<tr>
											<th>
												<h6>Residual Value [Per Machine]</h6>
											</th>
											{this.state.lease.map((lease, i) =>
												this.props.lease_editable ? (
													<td key={i}>
														<CurrencyFormat
															className="py-1 pr-2 text-right"
															displayType={"input"}
															decimalSeparator={"."}
															decimalScale={2}
															fixedDecimalScale={true}
															onValueChange={e =>
																this.handleTextChange(
																	e.floatValue,
																	"residual_value",
																	i
																)
															}
															value={lease.residual_value}
															thousandSeparator={true}
															suffix={
																" " +
																getSymbolFromCurrency(
																	this.state.configuration
																		.currency_symbol_html
																) +
																" "
															}
														/>
													</td>
												) : (
													<td key={i}>
														<CurrencyFormat
															className="py-1 pr-2 text-right"
															displayType={"text"}
															decimalSeparator={"."}
															decimalScale={2}
															fixedDecimalScale={true}
															value={lease.residual_value}
															thousandSeparator={true}
															suffix={
																" " +
																getSymbolFromCurrency(
																	this.state.configuration
																		.currency_symbol_html
																) +
																" "
															}
														/>
														{/* {parseFloat(
															lease.residual_value
														).toFixed(2) +
															" " +
															getSymbolFromCurrency(
																		this.props.country[0]
																			.currency_symbol_html
																	)} */}
													</td>
												)
											)}
										</tr>
										<tr>
											<th>
												<h6>Service Cost [Per Machine]</h6>
											</th>
											{this.state.lease.map((lease, i) =>
												this.props.lease_editable ? (
													<td>
														<CurrencyFormat
															className="py-1 pr-2 text-right"
															displayType={"input"}
															decimalSeparator={"."}
															decimalScale={2}
															fixedDecimalScale={true}
															onValueChange={e =>
																this.handleTextChange(
																	e.floatValue,
																	"service_cost",
																	i
																)
															}
															value={lease.service_cost}
															thousandSeparator={true}
															suffix={
																" " +
																getSymbolFromCurrency(
																	this.state.configuration
																		.currency_symbol_html
																) +
																" "
															}
														/>
													</td>
												) : (
													<td key={i}>
														<CurrencyFormat
															value={lease.service_cost}
															displayType={"text"}
															thousandSeparator={true}
															suffix={
																" " +
																getSymbolFromCurrency(
																	this.state.configuration
																		.currency_symbol_html
																) +
																" "
															}
														/>
													</td>
												)
											)}
										</tr>
										<tr>
											<th>
												<h6>Price per month [Per Machine]</h6>
											</th>
											{this.state.lease.map((lease, i) => (
												<td key={i}>
													<CurrencyFormat
														decimalSeparator="."
														decimalScale={2}
														value={lease.price_per_month}
														displayType={"text"}
														thousandSeparator={true}
														suffix={
															" " +
															getSymbolFromCurrency(
																this.state.configuration
																	.currency_symbol_html
															) +
															" "
														}
													/>
												</td>
											))}
										</tr>
										<tr>
											<th>
												<h6>Price per month [All Machine]</h6>
											</th>
											{this.state.lease.map((lease, i) => (
												<td key={i}>
													<CurrencyFormat
														decimalSeparator="."
														decimalScale={2}
														value={
															lease.price_per_month *
															lease.configuration_quantity
														}
														displayType={"text"}
														thousandSeparator={true}
														suffix={
															" " +
															getSymbolFromCurrency(
																this.state.configuration
																	.currency_symbol_html
															) +
															" "
														}
													/>
												</td>
											))}
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<div className="text-center mt-3 mb-3">
							<button
								onClick={this.onSubmit}
								type="submit"
								className="client-roboto-condensed client-login"
							>
								Add/Update Lease
							</button>
						</div>
					</div>
				</div>
			);
		}
	}
}
const mapStateToProps = state => {
	return {
		user_id: state.loginReducer.user.user.id,
		role: state.loginReducer.user.role,
		lease_editable: state.loginReducer.user.lease_editable,
		language: state.languageReducer.selectedLanguage,
		country: state.loginReducer.user.country,
		is_support: state.loginReducer.user.is_support
	};
};

export default connect(mapStateToProps, {})(LeaseCalculation);
