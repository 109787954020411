import brandActionTypes from "./actionTypes";

function clean_message() {
  return {
    type: brandActionTypes.CLEAN_MESSAGE_REQUEST,
    loading: true
  };
}

function show_brands() {
  return {
    type: brandActionTypes.SHOW_BRAND_REQUEST,
    loading: true
  };
}

function add_brand(params) {
  return {
    type: brandActionTypes.ADD_BRAND_REQUEST,
    loading: true,
    params: params
  };
}

function edit_brand(params) {
  return {
    type: brandActionTypes.EDIT_BRAND_REQUEST,
    loading: true,
    params: params
  };
}

export { clean_message, show_brands, add_brand, edit_brand };
