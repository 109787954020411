import React, { Component } from "react";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { connect } from "react-redux";
import { TemplateService } from "./../service";
import { Link } from "react-router-dom";
import { notification } from "antd";
import config from "./../../../config/config";

class ListTemplates extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			templates: false
		};
		this.onDelete = this.onDelete.bind(this);
	}

	async componentDidMount() {
		let templates = await TemplateService.showAllTemplates();

		await this.setState({ templates: templates });

		await this.setState({ loading: false });
	}

	async onDelete(id) {
		let confirmation = window.confirm("Are you sure?");

		if (confirmation) {
			let data = await TemplateService.deleteTemplate(id);

			if (data.message.includes("success")) {
				notification.success({
					message: "Success!",
					description: data.message
				});
			} else {
				notification.error({
					message: "Error!",
					description: data.message
				});
			}
		}
	}

	render() {
		if (!this.state.loading) {
			return (
				<div>
					<div
						className="content"
						style={{
							minHeight: 500
						}}
					>
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-header">
										<strong className="card-title">
											List Templates
										</strong>
									</div>
									<div className="card-body">
										<div className="card-content">
											<div className="todo-list">
												<div className="tdl-holder">
													<div className="tdl-content">
														<ol>
															{this.state.templates
																? this.state.templates.map(
																		(template, i) => (
																			<li key={i}>
																				<label
																					style={{
																						color: "black"
																					}}
																				>
																					{
																						template.template_name
																					}
																					<Link
																						href="#"
																						onClick={() =>
																							this.onDelete(
																								template.template_id
																							)
																						}
																					>
																						<i className="fa text-dark fa-times"></i>
																					</Link>
																					<a
																						href={
																							config.APP_URL +
																							"/api/storage/" +
																							template.template_file
																						}
																						rel="noopener noreferrer"
																						target="_blank"
																						download
																					>
																						<i className="fas fa-file-download text-dark"></i>
																					</a>
																					<Link
																						to={
																							"update-template/" +
																							template.template_id
																						}
																					>
																						<i className="fa text-dark fa-pencil"></i>
																					</Link>
																				</label>
																			</li>
																		)
																  )
																: null}
														</ol>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div>
					<div
						className="content"
						style={{
							minHeight: 500
						}}
					>
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-header">
										<strong className="card-title">
											List Templates
										</strong>
									</div>
									<div className="card-body">
										<div className="card-content">
											<div className="todo-list">
												<div className="tdl-holder">
													<div className="tdl-content">
														<div className="row">
															<div className="col"></div>
															<div className="col">
																<div className="text-center">
																	<Loader
																		type="Rings"
																		color="#00BFFF"
																		height={100}
																		width={100}
																	/>
																	<div className="text-center client-roboto-condensed">
																		LOADING...
																	</div>
																</div>
															</div>
															<div className="col"></div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}
}

const mapStateToProps = state => {
	return {};
};

export default connect(mapStateToProps, {})(ListTemplates);
