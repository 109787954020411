import React, { Component } from "react";
import { connect } from "react-redux";
import { CountryService } from "./../../admin/country/service";
import { BrandService } from "./../../admin/brand/service";
import { register } from "./../action";
import { Redirect } from "react-router-dom";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Swal from "sweetalert2";
import { clean_login_message } from "./../action";
import { PulseLoader } from "react-spinners";
import { css } from "@emotion/react";
import SimpleReactValidator from "simple-react-validator";
import config from "../../config/config";

class Register extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			full_name: "",
			first_name: "",
			last_name: "",
			phone_number: "",
			mobile_number: "",
			company: "",
			user_company_role: "",
			signature_file: null,
			email: "",
			password: "",
			password_confirmation: "",
			selected_country: "",
			countries: [],
			brands: []
		};
		this.validator = new SimpleReactValidator();
		this.onTextChange = this.onTextChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.onCountryChange = this.onCountryChange.bind(this);
		this.onCompanyChange = this.onCompanyChange.bind(this);
		this.handleFileChange = this.handleFileChange.bind(this);
	}

	async onCountryChange(value) {
		let redirect = this;
		redirect.setState({ selected_country: value });
	}

	async onCompanyChange(value) {
		let redirect = this;
		redirect.setState({ company: value });
	}

	async handleFileChange(key, event) {
		switch (key) {
			case "signature_file":
				this.setState({ signature_file: event.target.files[0] });
				break;

			default:
				break;
		}
	}

	onTextChange(name, value) {
		let redirect = this;
		switch (name) {
			case "first_name":
				redirect.setState({ first_name: value });
				break;

			case "last_name":
				redirect.setState({ last_name: value });
				break;

			case "phone_number":
				redirect.setState({ phone_number: value });
				break;

			case "mobile_number":
				redirect.setState({ mobile_number: value });
				break;

			case "user_company_role":
				redirect.setState({ user_company_role: value });
				break;

			case "email":
				redirect.setState({ email: value });
				break;

			case "password":
				redirect.setState({ password: value });
				break;

			case "password_confirmation":
				redirect.setState({ password_confirmation: value });
				break;

			default:
				break;
		}
	}

	async componentDidUpdate() {
		if (this.props.message.toLowerCase().includes("registration success")) {
			Swal.fire({
				title: "Success!",
				text: "Registration successful, please continue to login",
				icon: "success",
				confirmButtonText: "OK"
			});
		}

		if (this.props.message.toLowerCase().includes("registration failure")) {
			Swal.fire({
				title: "Error!",
				text:
					"Registration error, please try again with the correct details",
				icon: "error",
				confirmButtonText: "OK"
			});
		}
	}

	async onSubmit(e) {
		e.preventDefault();

		this.setState({ loading: true });

		if (this.validator.allValid()) {
			if (this.state.password === this.state.password_confirmation) {
				const fd = new FormData();
				fd.append(
					"name",
					`${this.state.first_name} ${this.state.last_name}`
				);
				fd.append("email", this.state.email);
				fd.append("password", this.state.password);
				fd.append(
					"password_confirmation",
					this.state.password_confirmation
				);
				fd.append("country_id", this.state.selected_country);
				fd.append("first_name", this.state.first_name);
				fd.append("family_name", this.state.last_name);
				fd.append("phone_number", this.state.phone_number);
				fd.append("mobile_number", this.state.mobile_number);
				fd.append("user_responsibility", this.state.user_company_role);
				fd.append("brand_id", this.state.company);
				fd.append("signature_file", this.state.signature_file);

				await this.props.register(fd);

				await this.setState({ loading: false });
			} else {
				Swal.fire({
					title: "Password Information",
					text:
						"Your passwords do not match, please try again with matching passwords!!",
					icon: "info",
					confirmButtonText: "OK"
				});
			}
		} else {
			this.validator.showMessages();
			this.forceUpdate();
		}

		this.setState({ loading: false });
	}

	async componentDidMount() {
		let redirect = this;
		this.setState({ loading: true });
		let brands = await BrandService.showBrands();
		let data = await CountryService.showCountries();
		redirect.setState({ countries: data });
		redirect.setState({ brands: brands });
		redirect.setState({ company: "" });
		redirect.setState({ selected_country: "" });
		await redirect.setState({ loading: false });
	}

	render() {
		const override = css`
			display: block;
			margin: 0 auto;
			border-color: red;
		`;
		if (this.props.message.toLowerCase().includes("registration success")) {
			return <Redirect to="/users" />;
		} else {
			if (this.state.loading) {
				return (
					<div className="sweet-loading">
						<div className="container loading-container">
							<div className="row m-5 p-5">
								<PulseLoader
									css={override}
									sizeUnit={"px"}
									size={24}
									color={"#B9373D"}
								/>
							</div>
						</div>
					</div>
				);
			} else {
				return (
					<div>
						<div
							className="content"
							style={{
								minHeight: 500
							}}
						>
							<div className="row">
								<div className="col-12">
									<div className="card">
										<div className="card-header">
											<strong className="card-title">
												Register
											</strong>
										</div>
										<div className="card-body">
											<form className="">
												<div className="form-group">
													<label>First Name</label>
													<input
														type="text"
														className="form-control"
														placeholder="Enter First Name"
														value={this.state.first_name}
														onChange={e =>
															this.onTextChange(
																"first_name",
																e.target.value
															)
														}
													/>
													<div
														className="mt-4"
														style={{
															color: "red"
														}}
													>
														{this.validator.message(
															"First name",
															this.state.first_name,
															"required|string"
														)}
													</div>
												</div>
												<div className="form-group">
													<label>Last Name</label>
													<input
														type="text"
														className="form-control"
														placeholder="Enter Last Name"
														value={this.state.last_name}
														onChange={e =>
															this.onTextChange(
																"last_name",
																e.target.value
															)
														}
													/>
													<div
														className="mt-4"
														style={{
															color: "red"
														}}
													>
														{this.validator.message(
															"Last name",
															this.state.last_name,
															"required|string"
														)}
													</div>
												</div>

												<div className="form-group">
													<label>E-mail Address</label>
													<input
														type="text"
														className="form-control"
														placeholder="Enter E-mail"
														value={this.state.email}
														onChange={e =>
															this.onTextChange(
																"email",
																e.target.value
															)
														}
													/>
													<div
														className="mt-4"
														style={{
															color: "red"
														}}
													>
														{this.validator.message(
															"email",
															this.state.email,
															"required|string|email"
														)}
													</div>
												</div>

												<div className="form-group">
													<label>Phone Number</label>
													<input
														type="text"
														className="form-control"
														placeholder="Enter Phone Number"
														value={this.state.phone_number}
														onChange={e =>
															this.onTextChange(
																"phone_number",
																e.target.value
															)
														}
													/>
													<div
														className="mt-4"
														style={{
															color: "red"
														}}
													>
														{this.validator.message(
															"Phone Number",
															this.state.phone_number,
															"required|string"
														)}
													</div>
												</div>

												<div className="form-group">
													<label>Mobile Number</label>
													<input
														type="text"
														className="form-control"
														placeholder="Enter Mobile Number"
														value={this.state.mobile_number}
														onChange={e =>
															this.onTextChange(
																"mobile_number",
																e.target.value
															)
														}
													/>
													<div
														className="mt-4"
														style={{
															color: "red"
														}}
													>
														{this.validator.message(
															"Mobile Number",
															this.state.mobile_number,
															"required|string"
														)}
													</div>
												</div>

												<div className="form-group">
													<label>Password</label>
													<input
														type="password"
														className="form-control"
														placeholder="Enter Password"
														value={this.state.password}
														onChange={e =>
															this.onTextChange(
																"password",
																e.target.value
															)
														}
													/>
													<div
														className="mt-4"
														style={{
															color: "red"
														}}
													>
														{this.validator.message(
															"password",
															this.state.password,
															"required|string|min:8|max:32"
														)}
													</div>
												</div>
												<div className="form-group">
													<label>Confirm Password</label>
													<input
														type="password"
														className="form-control"
														placeholder="Renter Password"
														value={
															this.state.password_confirmation
														}
														onChange={e =>
															this.onTextChange(
																"password_confirmation",
																e.target.value
															)
														}
													/>
													<div
														className="mt-4"
														style={{
															color: "red"
														}}
													>
														{this.validator.message(
															"password_confirmation",
															this.state.password_confirmation,
															"required|string|min:8|max:32"
														)}
													</div>
												</div>

												{/* company */}
												{console.log("new value is", {
													con: this.state.company,
													cond: this.state.company === ""
												})}
												<div className="form-group">
													<label>Company</label>
													<select
														className="custom-select"
														onChange={e =>
															this.onCompanyChange(
																String(e.target.value)
															)
														}
														value={
															this.state.company === ""
																? {
																		value: "",
																		label: "Select Company"
																  }
																: this.state.company
														}
													>
														<option value="" select>
															Select Company
														</option>
														{this.state.brands.map(
															(brand, id) => (
																<option
																	key={id}
																	value={brand.id}
																>
																	{brand.brand_name}
																</option>
															)
														)}
													</select>
													<div
														className="mt-4"
														style={{
															color: "red"
														}}
													>
														{this.validator.message(
															"company",
															this.state.company,
															"required"
														)}
													</div>
												</div>

												{this.state?.company !== "" &&
													this.state.brands.find(
														brand =>
															brand.id ===
															parseInt(this.state?.company)
													).brand_image !== null && (
														<div className="image-card my-4">
															<div className="imagewrap">
																<div className="thumbnail">
																	<img
																		loading="lazy"
																		alt="brand"
																		src={
																			config.APP_URL +
																			"/api/storage/" +
																			this.state.brands.find(
																				brand =>
																					brand.id ===
																					parseInt(
																						this.state
																							?.company
																					)
																			).brand_image
																		}
																	/>
																</div>
															</div>
														</div>
													)}

												<div className="form-group">
													<label>User Role</label>
													<input
														type="text"
														className="form-control"
														placeholder="Enter User Role"
														value={this.state.user_company_role}
														onChange={e =>
															this.onTextChange(
																"user_company_role",
																e.target.value
															)
														}
													/>
													<div
														className="mt-4"
														style={{
															color: "red"
														}}
													>
														{this.validator.message(
															"User Role",
															this.state.user_company_role,
															"required|string"
														)}
													</div>
												</div>

												<div className="form-group">
													<label>Select Preferred Country</label>
													<select
														className="custom-select"
														onChange={e =>
															this.onCountryChange(
																e.target.value
															)
														}
														defaultValue={
															this.state.selected_country
														}
													>
														<option key="select" value="">
															Select Country
														</option>
														{this.state.countries.map(
															(country, id) => (
																<option
																	key={id}
																	value={country.id}
																>
																	{country.country_name}
																</option>
															)
														)}
													</select>
													<div
														className="mt-4"
														style={{
															color: "red"
														}}
													>
														{this.validator.message(
															"selected_country",
															this.state.selected_country,
															"required"
														)}
													</div>
												</div>

												<div className="form-group">
													<label>User Signature</label>
													<input
														type="file"
														className="form-control"
														placeholder="Enter User Signature"
														onChange={e =>
															this.handleFileChange(
																"signature_file",
																e
															)
														}
														accept="image/jpg, image/jpeg, image/png"
													/>
													{/* <div
														className="mt-4"
														style={{
															color: "red"
														}}
													>
														{this.validator.message(
															"User Signature",
															this.state.signature_file,
															"required"
														)}
													</div> */}
												</div>
												<div>
													<button
														type="submit"
														onClick={this.onSubmit}
														className="client-roboto-condensed client-login m-2"
													>
														Register
													</button>
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				);
			}
		}
	}
}
const mapStateToProps = state => {
	return {
		message: state.loginReducer.message
	};
};

export default connect(mapStateToProps, {
	register,
	clean_login_message
})(Register);
