import React, { Component } from "react";
import { connect } from "react-redux";
import { Accordion, AccordionTab } from "primereact/accordion";
import { ClientConfigurationService } from "./../service";
import { MachineCategoryService } from "./../../../admin/machine_category/service";
import { save_first_form, clear_second_froms } from "./../action";
import SimpleReactValidator from "simple-react-validator";
import { css } from "@emotion/react";
import { PulseLoader } from "react-spinners";
import { UserService } from "./../../../admin/user/service";
import { Api } from "./../../../config/api";
import config from "./../../../config/config";
import { notification, TreeSelect } from "antd";
import "antd/es/tree-select/style/css";

class FirstForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			data: false,
			configuration_name: "",
			category_name: "",
			configuration_category: "",
			category_details: false,
			specifications: false,
			features: false,
			max_discount_category: 100,
			category_image: false,
			users: false,
			only_parts: false,
			user: this.props.first_form.user_id
				? this.props.first_form.user_id
				: null
		};

		this.validator = new SimpleReactValidator({ autoForceUpdate: this });
		this.onTextChange = this.onTextChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.onCategoryChange = this.onCategoryChange.bind(this);
		this.onSelectChange = this.onSelectChange.bind(this);
	}

	onSelectChange(value) {
		this.setState({ user: value });
	}

	async onCategoryChange(value) {
		await this.setState({ loading: true });

		let that = this;

		that.setState({ configuration_category: value });

		let data = await ClientConfigurationService.getCategoryDetails(value);
		that.setState({ specifications: data.specifications });
		that.setState({ features: data.features });
		that.setState({ category_details: data.machine_category_details });
		that.setState({
			category_name: data.machine_category_details[0].machine_category
		});
		that.setState({
			configuration_category: data.machine_category_details[0].id
		});
		that.setState({
			category_image: data.machine_category_details[0].machine_category_image
		});

		await Api.get(
			"machine-category/getMachineCategoryMaxDiscountByRole/" +
			this.props.role_id +
			"/" +
			value
		)
			.then(function (response) {
				that.setState({ machine_category_data: response.data });
				that.setState({
					max_discount_category: response.data[0].max_discount_category
				});
			})
			.catch(function (error) { });

		let categories = await MachineCategoryService.getTreeSelectedData(
			value,
			this.props.role_id,
			this.props.country_id
		);

		that.setState({ data: categories });
		if (categories !== undefined && categories !== false) {
			await this.props.clear_second_froms();
		}

		await Api.get("configuration/get/lastConfiguration").then(function (
			response
		) {
			that.setState({
				configuration_name:
					data.machine_category_details[0].machine_category +
					"-" +
					(parseInt(response.data[0].id) + 1)
			});
		});
		await this.setState({ loading: false });
	}

	async onSubmit(e) {
		e.preventDefault();

		if (this.validator.allValid()) {
			let that = this;

			if (this.props.is_support) {
				if (
					that.state.configuration_name &&
					that.state.configuration_category
				) {
					let params = {
						configuration_name: that.state.configuration_name,
						configuration_category: that.state.configuration_category,
						machine_category_name: that.state.category_name,
						max_discount_category: that.state.max_discount_category,
						only_parts: that.state.only_parts,
						user_id: that.state.user
					};


					Api.post('configuration/checkIfConfigurationNameExists', { name: this.state.configuration_name }).then(async function (response) {
						if (response.data.configuration_name_exists) {
							notification.info({
								message: "Information!",
								description: 'The configuration name is already in use, please choose a different name.'
							});
						} else {
							await that.props.save_first_form(params);
							that.props.onComponentChange("seconForm", "");
						}
					}).catch(function (error) {

					})
				}
			} else {
				if (
					that.state.configuration_name &&
					that.state.configuration_category
				) {
					let params = {
						configuration_name: that.state.configuration_name,
						configuration_category: that.state.configuration_category,
						machine_category_name: that.state.category_name,
						only_parts: that.state.only_parts,
						max_discount_category: that.state.max_discount_category
					};

					Api.post('configuration/checkIfConfigurationNameExists', { name: this.state.configuration_name }).then(async function (response) {
						if (response.data.configuration_name_exists) {
							notification.info({
								message: "Information!",
								description: 'The configuration name is already in use, please choose a different name.'
							});
						} else {
							await that.props.save_first_form(params);
							that.props.onComponentChange("seconForm", "");
						}
					}).catch(function (error) {

					})
				}
			}
		} else {
			this.validator.showMessages();
		}
	}

	onTextChange(name, value) {
		let redirect = this;
		switch (name) {
			case "configuration_name":
				redirect.setState({ configuration_name: value });
				break;

			case "configuration_category":
				redirect.setState({ configuration_category: value });
				break;

			case "only_parts":
				redirect.setState({ only_parts: !this.state.only_parts });
				break;

			default:
				break;
		}
	}

	async componentDidMount() {
		let that = this;

		let data = await UserService.showUsers(this.props.token);

		that.setState({ users: data });

		if (this.props.first_form) {
			that.setState({
				configuration_category: this.props.first_form.configuration_category
			});

			that.setState({
				configuration_name: this.props.first_form.configuration_name
			});

			that.setState({ only_parts: this.props.first_form.only_parts })

			let data = await ClientConfigurationService.getCategoryDetails(
				this.props.first_form.configuration_category
			);
			that.setState({ specifications: data.specifications });
			that.setState({ features: data.features });
			that.setState({ category_details: data.machine_category_details });
			that.setState({
				category_name: data.machine_category_details[0] ? data.machine_category_details[0].machine_category : null
			});
			that.setState({
				configuration_category: data.machine_category_details[0].id
			});
			that.setState({
				category_image:
					data.machine_category_details[0].machine_category_image
			});

			await Api.get(
				"machine-category/getMachineCategoryMaxDiscountByRole/" +
				this.props.role_id +
				"/" +
				this.props.first_form.configuration_category
			)
				.then(function (response) {
					that.setState({ machine_category_data: response.data });
					that.setState({
						max_discount_category: response.data[0].max_discount_category
					});
				})
				.catch(function (error) { });

			let categories = await MachineCategoryService.getTreeSelectedData(
				this.props.first_form.configuration_category,
				this.props.role_id,
				this.props.country_id
			);

			that.setState({ data: categories });
		} else {
			let tree_category = await ClientConfigurationService.getTreeCategory(
				this.props.role_id,
				this.props.country_id
			);

			that.setState({ data: tree_category });
		}

		if (this.props.message && this.props) {
			notification.info({
				duration: 0,
				message: "No parts found!",
				description: this.props.message
			});
		}

		await this.setState({ loading: false });
	}

	render() {
		const override = css`
			display: block;
			margin: 0 auto;
			border-color: red;
		`;
		if (!this.state.data || this.state.loading) {
			return (
				<div className="sweet-loading">
					<div className="container loading-container">
						<div className="row m-5 p-5">
							<PulseLoader
								css={override}
								sizeUnit={"px"}
								size={24}
								color={"#B9373D"}
							/>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div className="row">
					<div className="col"></div>
					<div className="col-sm-12 text-center">
						<h4 className="mb-5 client-roboto-condensed font-weight-bold text-center">
							Configuration Information
						</h4>
						<form className="m-1">
							<div className="row">
								<div className="col">
									<div className="form-group text-center my-2">
										<label className="client-roboto-condensed font-weight-bold">
											Select Product
										</label>
										<br />
										<TreeSelect
											showSearch
											// className="col-6"
											style={{ width: "52%" }}
											value={this.state.configuration_category}
											dropdownStyle={{
												maxHeight: 300,
												overflow: "auto"
											}}
											treeData={this.state.data}
											placeholder="Please select a product"
											treeDefaultExpandAll
											treeNodeFilterProp="title"
											searchPlaceholder="Filter Products"
											onChange={e => this.onCategoryChange(e)}
										/>
										<div
											className="mt-4"
											style={{
												color: "red"
											}}
										>
											{this.validator.message(
												"configuration_category",
												this.state.configuration_category,
												"required"
											)}
										</div>
									</div>
									<div className="row form-group text-center my-2">
										<div className="col"></div>
										<div
											className="col-6"
											style={{
												paddingLeft: 0,
												paddingRight: 0
											}}
										>
											<label className="client-roboto-condensed font-weight-bold">
												Configuration Name
											</label>
											<input
												className="form-control"
												style={{
													textAlign: "center"
												}}
												type="text"
												value={
													this.state.configuration_name
														? this.state.configuration_name
														: ""
												}
												onChange={e =>
													this.onTextChange(
														"configuration_name",
														e.target.value
													)
												}
												placeholder="Enter configuration Name"
											/>
											<div
												className="mt-4"
												style={{
													color: "red"
												}}
											>
												{this.validator.message(
													"configuration_name",
													this.state.configuration_name,
													"required|string"
												)}
											</div>
										</div>

										<div className="col"></div>
									</div>

									{this.props.is_support ? (
										<div className="form-group">
											<label className="client-roboto-condensed">
												Select User
											</label>
											<select
												value={this.state.user}
												onChange={e =>
													this.onSelectChange(e.target.value)
												}
												className="custom-select"
											>
												<option value="">Select User</option>
												{this.state.users.map((user, i) =>
													user.role_name
														.toLowerCase()
														.includes("admin") ||
														user.role_name
															.toLowerCase()
															.includes("support") ? null : (
														<option key={i} value={user.id}>
															{user.name +
																"\t-\t[" +
																user.email +
																"]"}
														</option>
													)
												)}
											</select>
											<div
												className="mt-4"
												style={{
													color: "red"
												}}
											>
												{this.validator.message(
													"user",
													this.state.user,
													"required"
												)}
											</div>
										</div>
									) : null}
								</div>
							</div>
							{this.state.category_image &&
								this.state.category_image !== null ? (
								<>
									<div className="row mb-5">
										<div className="col"></div>
										<div className="col-sm-5">
											<div className="text-center">
												<img loading="lazy"
													height="70%"
													width="70%"
													alt={"category"}
													src={
														config.APP_URL +
														"/api/storage/" +
														this.state.category_image
													}
												/>
											</div>
										</div>
										<div className="col"></div>
									</div>
								</>
							) : null}
							{this.state.specifications && this.state.features ? (
								<Accordion multiple={true}>
									<AccordionTab header="Specifications">
										<div className="row">
											{this.state.specifications.map(
												(specification, i) => (
													<div
														key={i}
														className="col-sm-6 mt-2 mb-2"
													>
														<strong>
															{specification.specification_name}:{" "}
														</strong>
														<br />
														{specification.specification_value}
													</div>
												)
											)}
										</div>
									</AccordionTab>
									<AccordionTab header="Features">
										<div className="row">
											{this.state.features.map((feature, i) => (
												<div key={i} className="col-sm-6">
													<strong>{feature.feature_title}</strong>
													<p>{feature.feature_description}</p>
												</div>
											))}
										</div>
									</AccordionTab>
								</Accordion>
							) : null}
							<div style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'center',
								alignItems: 'center'
							}} className="text-center mt-3 mb-3">
								<div className="mx-1">
									Only Parts?
								</div>
								<div className="mx-1">
									<select
										onChange={e =>
											this.onTextChange(
												"only_parts",
												e.target.value
											)}
										value={this.state.only_parts}
										className="custom-select"
									>
										<option value={false}>No</option>
										<option value={true}>Yes</option>
									</select>
									{/* <input
										onChange={e =>
											this.onTextChange(
												"only_parts",
												e.target.value
											)} value={this.state.only_parts} type="checkbox" /> */}
								</div>
							</div>


							<div className="text-center mt-3 mb-3">
								<button
									onClick={this.onSubmit}
									type="submit"
									className="client-roboto-condensed text-uppercase client-login px-5"
								>
									Next
								</button>
							</div>
						</form>
					</div>
					<div className="col"></div>
				</div>
			);
		}
	}
}
const mapStateToProps = state => {
	return {
		loading: state.clientConfigurationReducer.loading,
		first_form: state.clientConfigurationReducer.first_form,
		role: state.loginReducer.user.role,
		role_id: state.loginReducer.user.role_id,
		token: state.loginReducer.token,
		country_id: state.loginReducer.user.user.country_id,
		is_support: state.loginReducer.user.is_support
	};
};

export default connect(mapStateToProps, {
	save_first_form,
	clear_second_froms
})(FirstForm);
