import compatibilityActionTypes from "./actionTypes";
const INITIAL_STATE = {
  message: "",
  loading: false,
  compatible_categories: false,
  all_categories: false,
  all_machine_parts: false,
  tree: false
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case compatibilityActionTypes.SHOW_COMPATIBILITY_FOR_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case compatibilityActionTypes.SHOW_COMPATIBILITY_FOR_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
        compatible_categories: action.compatible_categories
      };
    case compatibilityActionTypes.SHOW_COMPATIBILITY_FOR_CATEGORY_FALIURE:
      return {
        ...state,
        loading: false,
        message: action.message
      };

    case compatibilityActionTypes.SHOW_ALL_CATEGORIES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case compatibilityActionTypes.SHOW_ALL_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
        all_categories: action.all_categories
      };
    case compatibilityActionTypes.SHOW_ALL_CATEGORIES_FALIURE:
      return {
        ...state,
        loading: false,
        message: action.message
      };

    case compatibilityActionTypes.UPDATE_COMPATIBILITY_FOR_CATEGORY_REQUEST:
      return {
        ...state,
        loading: false
      };
    case compatibilityActionTypes.UPDATE_COMPATIBILITY_FOR_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message
      };
    case compatibilityActionTypes.UPDATE_COMPATIBILITY_FOR_CATEGORY_FALIURE:
      return {
        ...state,
        loading: false,
        message: action.message
      };

    case compatibilityActionTypes.SHOW_ALL_MACHINE_PARTS_REQUEST:
      return {
        ...state,
        loading: false,
        message: action.message
      };
    case compatibilityActionTypes.SHOW_ALL_MACHINE_PARTS_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
        all_machine_parts: action.all_machine_parts
      };
    case compatibilityActionTypes.SHOW_ALL_MACHINE_PARTS_FALIURE:
      return {
        ...state,
        loading: false,
        message: action.message
      };

    case compatibilityActionTypes.GET_TREE_CATEGORY_REQUEST:
      return {
        ...state,
        loading: false
      };
    case compatibilityActionTypes.GET_TREE_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
        tree: action.tree
      };
    case compatibilityActionTypes.GET_TREE_CATEGORY_FALIURE:
      return {
        ...state,
        loading: false,
        message: action.message
      };

    default:
      return state;
  }
}
