import React, { Component } from "react";
import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Bar } from "recharts";

export default class Barchart extends Component {
  componentDidMount() {}

  render() {
    return (
      <div>
        <BarChart
          width={900}
          height={300}
          data={this.props.data}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          {/* <Legend /> */}
          {Object.keys(this.props.data[0]).map((value, i) =>
            value !== "name" ? (
              <Bar
                key={i}
                dataKey={value}
                fill={"#000000".replace(/0/g, function() {
                  return (~~(Math.random() * 16)).toString(16);
                })}
              />
            ) : null
          )}
        </BarChart>
      </div>
    );
  }
}
