import { ClientConfigurationService } from "./service";
import { take, call, put } from "redux-saga/effects";
import clientConfigurationActionTypes from "./actionTypes";

function* saveFirstForm() {
	while (true) {
		const { params } = yield take(
			clientConfigurationActionTypes.SAVE_FIRST_FORM_REQUEST
		);

		const first_form = params;

		if (
			first_form !== "" &&
			first_form !== null &&
			first_form !== [] &&
			first_form
		) {
			yield put({
				type: clientConfigurationActionTypes.SAVE_FIRST_FORM_SUCCESS,
				message: "Saving first form success",
				first_form: first_form
			});
		} else {
			yield put({
				type: clientConfigurationActionTypes.SAVE_FIRST_FORM_FALIURE,
				message: "Saving first form faliure"
			});
		}
	}
}

function* saveSecondForm() {
	while (true) {
		const { params } = yield take(
			clientConfigurationActionTypes.SAVE_SECOND_FORM_REQUEST
		);

		const second_form = params;

		if (
			second_form !== "" &&
			second_form !== null &&
			second_form !== [] &&
			second_form
		) {
			yield put({
				type: clientConfigurationActionTypes.SAVE_SECOND_FORM_SUCCESS,
				message: "Saving second form success",
				second_form: second_form
			});
		} else {
			yield put({
				type: clientConfigurationActionTypes.SAVE_SECOND_FORM_FALIURE,
				message: "Saving second form faliure"
			});
		}
	}
}

function* saveThirdForm() {
	while (true) {
		const { params } = yield take(
			clientConfigurationActionTypes.SAVE_THIRD_FORM_REQUEST
		);

		const third_form = params;

		if (
			third_form !== "" &&
			third_form !== null &&
			third_form !== [] &&
			third_form
		) {
			yield put({
				type: clientConfigurationActionTypes.SAVE_THIRD_FORM_SUCCESS,
				message: "Saving third form success",
				third_form: third_form
			});
		} else {
			yield put({
				type: clientConfigurationActionTypes.SAVE_THIRD_FORM_FALIURE,
				message: "Saving third form faliure"
			});
		}
	}
}

function* saveConfiguration() {
	while (true) {
		const { user, params } = yield take(
			clientConfigurationActionTypes.SAVE_CONFIGURATION_REQUEST
		);

		const configurations = yield call(
			ClientConfigurationService.saveFirstForm,
			user,
			params
		);

		if (
			configurations !== "" &&
			configurations !== null &&
			configurations !== [] &&
			configurations
		) {
			yield put({
				type: clientConfigurationActionTypes.SAVE_CONFIGURATION_SUCCESS,
				message: "Saving configuration success"
			});
		} else {
			yield put({
				type: clientConfigurationActionTypes.SAVE_CONFIGURATION_FALIURE,
				message: "Saving configuration faliure"
			});
		}
	}
}

function* createConfiguration() {
	while (true) {
		const { params } = yield take(
			clientConfigurationActionTypes.CREATE_CONFIGURATION_REQUEST
		);
		const configuration = yield call(
			ClientConfigurationService.createConfiguration,
			params
		);

		if (
			configuration !== "" &&
			configuration !== null &&
			configuration !== [] &&
			configuration
		) {
			yield put({
				type: clientConfigurationActionTypes.CREATE_CONFIGURATION_SUCCESS,
				message: "Creating configuration success",
				configuration_id: configuration.configuration_id,
				configuration_type: configuration.configuration_type
			});
		} else {
			yield put({
				type: clientConfigurationActionTypes.CREATE_CONFIGURATION_FALIURE,
				message: "Creating configuration faliure"
			});
		}
	}
}

function* editCofiguration() {
	while (true) {
		const { id, params, role, from } = yield take(
			clientConfigurationActionTypes.EDIT_CONFIGURATION_REQUEST
		);
		let configuration = [];
		if (role) {
			configuration = yield call(
				ClientConfigurationService.editCofigurationSalessupport,
				params.user_id,
				id,
				params
			);
		} else {
			if (from === 'approval') {
				configuration = yield call(
					ClientConfigurationService.editedApprovedConfiguration,
					id,
					params
				);
			} else {
				configuration = yield call(
					ClientConfigurationService.editCofiguration,
					id,
					params
				);
			}
		}

		if (
			configuration !== "" &&
			configuration !== null &&
			configuration !== [] &&
			configuration
		) {
			yield put({
				type: clientConfigurationActionTypes.EDIT_CONFIGURATION_SUCCESS,
				message: "Editing configuration success"
			});
		} else {
			yield put({
				type: clientConfigurationActionTypes.EDIT_CONFIGURATION_FALIURE,
				message: "Editing configuration faliure"
			});
		}
	}
}

function* editLibraryCofiguration() {
	while (true) {
		const { id, params } = yield take(
			clientConfigurationActionTypes.EDIT_LIBRARY_CONFIGURATION_REQUEST
		);
		let configuration = [];

		configuration = yield call(
			ClientConfigurationService.editLibraryCofiguration,
			id,
			params
		);

		if (
			configuration !== "" &&
			configuration !== null &&
			configuration !== [] &&
			configuration
		) {
			yield put({
				type: clientConfigurationActionTypes.EDIT_LIBRARY_CONFIGURATION_SUCCESS,
				message: "Editing configuration success"
			});
		} else {
			yield put({
				type: clientConfigurationActionTypes.EDIT_LIBRARY_CONFIGURATION_FALIURE,
				message: "Editing configuration faliure"
			});
		}
	}
}

function* showUserConfigurations(user) {
	while (true) {
		const { user } = yield take(
			clientConfigurationActionTypes.SHOW_USER_CONFIGURATIONS_REQUEST
		);

		const configurations = yield call(
			ClientConfigurationService.showUserConfigurations,
			user
		);

		if (
			configurations !== "" &&
			configurations !== null &&
			configurations !== [] &&
			configurations
		) {
			yield put({
				type: clientConfigurationActionTypes.SHOW_USER_CONFIGURATIONS_SUCCESS,
				message: "Getting user configurations success",
				configurations: configurations
			});
		} else {
			yield put({
				type: clientConfigurationActionTypes.SHOW_USER_CONFIGURATIONS_FALIURE,
				message: "Getting user configurations faliure"
			});
		}
	}
}

function* showSingleLibraryConfiguration() {
	while (true) {
		const { params } = yield take(
			clientConfigurationActionTypes.SHOW_SINGLE_LIBRARY_CONFIGURATION_REQUEST
		);

		let configuration = [];
		configuration = yield call(
			ClientConfigurationService.showSingleLibraryConfiguration,
			params
		);

		let firstForm = false;
		let secondForm = false;

		if (
			configuration !== "" &&
			configuration !== null &&
			configuration !== [] &&
			configuration
		) {
			firstForm = {
				configuration_name: configuration.configuration[0].name,
				only_parts: configuration.configuration[0].only_parts === 1 ? true : false,
				configuration_category:
					configuration.configuration[0].machine_category_id,
				machine_category_name: configuration.configuration[0].machine_category
			};

			secondForm = {
				compatible_machine_parts: configuration.parts,
				manual_adjustments: configuration.manual_adjustments
			};
		}

		if (
			configuration !== "" &&
			configuration !== null &&
			configuration !== [] &&
			configuration
		) {
			yield put({
				type:
					clientConfigurationActionTypes.SHOW_SINGLE_LIBRARY_CONFIGURATION_SUCCESS,
				message: "Getting single user configuration success",
				configuration: configuration,
				firstForm: firstForm,
				secondForm: secondForm,
				category_details: configuration.configuration
			});
		} else {
			yield put({
				type:
					clientConfigurationActionTypes.SHOW_SINGLE_LIBRARY_CONFIGURATION_FALIURE,
				message: "Getting single user configuration faliure"
			});
		}
	}
}

function* showSingleUserConfiguration() {
	while (true) {
		const { user, params, role } = yield take(
			clientConfigurationActionTypes.SHOW_SINGLE_USER_CONFIGURATION_REQUEST
		);

		let configuration = false;

		if (role) {
			configuration = yield call(
				ClientConfigurationService.showSingleUserConfigurationSalesSupport,
				user,
				params
			);
		} else {
			configuration = yield call(
				ClientConfigurationService.showSingleUserConfiguration,
				user,
				params
			);
		}


		let firstForm = false;
		let secondForm = false;
		if (
			configuration !== "" &&
			configuration !== null &&
			configuration !== [] &&
			configuration
		) {
			firstForm = {
				configuration_name: configuration.configuration[0].name,
				only_parts: configuration.configuration[0].only_parts === 1 ? true : false,
				configuration_category:
					configuration.configuration[0].machine_category_id,
				machine_category_name: configuration.configuration[0].machine_category
			};

			secondForm = {
				compatible_machine_parts: configuration.parts,
				manual_adjustments: configuration.manual_adjustments
			};
		}

		if (
			configuration !== "" &&
			configuration !== null &&
			configuration !== [] &&
			configuration &&
			configuration !== undefined
		) {
			yield put({
				type:
					clientConfigurationActionTypes.SHOW_SINGLE_USER_CONFIGURATION_SUCCESS,
				message: "Getting single user configuration success",
				configuration: configuration,
				firstForm: firstForm,
				secondForm: secondForm,
				category_details: configuration.configuration
			});
		} else {
			yield put({
				type:
					clientConfigurationActionTypes.SHOW_SINGLE_USER_CONFIGURATION_FALIURE,
				message: "Getting single user configuration faliure"
			});
		}
	}
}

function* showSingleLibraryConfigurationForEdit() {
	while (true) {
		const { params, role } = yield take(
			clientConfigurationActionTypes.SHOW_SINGLE_LIBRARY_CONFIGURATION_FOR_EDIT_REQUEST
		);

		let configuration = [];

		if (role) {
			configuration = yield call(
				ClientConfigurationService.showSingleLibraryConfigurationForEdit,
				params
			);
		} else {
			configuration = yield call(
				ClientConfigurationService.showSingleLibraryConfigurationForEdit,
				params
			);
		}

		let firstForm = false;
		let secondForm = false;
		if (
			configuration !== "" &&
			configuration !== null &&
			configuration !== [] &&
			configuration
		) {
			firstForm = {
				configuration_name: configuration.configuration[0].name,
				only_parts: configuration.configuration[0].only_parts === 1 ? true : false,
				configuration_category:
					configuration.configuration[0].machine_category_id,
				machine_category_name: configuration.configuration[0].machine_category,
				user_id: configuration.configuration[0].user_id
			};

			secondForm = {
				compatible_machine_parts: configuration.parts,
				manual_adjustments: configuration.manual_adjustments
			};
		}

		if (
			configuration !== "" &&
			configuration !== null &&
			configuration !== [] &&
			configuration
		) {
			yield put({
				type:
					clientConfigurationActionTypes.SHOW_SINGLE_LIBRARY_CONFIGURATION_FOR_EDIT_SUCCESS,
				message: "Getting single user configuration success",
				configuration: configuration,
				firstForm: firstForm,
				secondForm: secondForm,
				category_details: configuration.configuration
			});
		} else {
			yield put({
				type:
					clientConfigurationActionTypes.SHOW_SINGLE_LIBRARY_CONFIGURATION_FOR_EDIT_FALIURE,
				message: "Getting single user configuration faliure"
			});
		}
	}
}

function* showSingleUserConfigurationForEdit() {
	while (true) {
		const { user, params, role } = yield take(
			clientConfigurationActionTypes.SHOW_SINGLE_USER_CONFIGURATION_FOR_EDIT_REQUEST
		);

		let configuration = [];
		if (role) {
			configuration = yield call(
				ClientConfigurationService.showSingleUserConfigurationSalesSupportForEdit,
				user,
				params
			);
		} else {
			configuration = yield call(
				ClientConfigurationService.showSingleUserConfigurationForEdit,
				user,
				params
			);
		}

		let firstForm = false;
		let secondForm = false;
		if (
			configuration !== "" &&
			configuration !== null &&
			configuration !== [] &&
			configuration
		) {
			firstForm = {
				configuration_name: configuration.configuration[0].name,
				only_parts: configuration.configuration[0].only_parts === 1 ? true : false,
				configuration_category:
					configuration.configuration[0].machine_category_id,
				machine_category_name: configuration.configuration[0].machine_category,
				user_id: configuration.configuration[0].user_id
			};

			secondForm = {
				compatible_machine_parts: configuration.parts,
				manual_adjustments: configuration.manual_adjustments
			};
		}


		if (
			configuration !== "" &&
			configuration !== null &&
			configuration !== [] &&
			configuration
		) {
			yield put({
				type:
					clientConfigurationActionTypes.SHOW_SINGLE_USER_CONFIGURATION_FOR_EDIT_SUCCESS,
				message: "Getting single user configuration success",
				configuration: configuration,
				firstForm: firstForm,
				secondForm: secondForm,
				category_details: configuration.configuration
			});
		} else {
			yield put({
				type:
					clientConfigurationActionTypes.SHOW_SINGLE_USER_CONFIGURATION_FOR_EDIT_FALIURE,
				message: "Getting single user configuration faliure"
			});
		}
	}
}

function* clearForms() {
	while (true) {
		yield take(clientConfigurationActionTypes.CLEAR_FORMS_REQUEST);

		yield put({
			type: clientConfigurationActionTypes.CLEAR_FORMS_SUCCESS,
			message: "Forms Cleared"
		});
	}
}

function* clearSecondForms() {
	while (true) {
		yield take(clientConfigurationActionTypes.CLEAR_SECOND_FORM_REQUEST);

		yield put({
			type: clientConfigurationActionTypes.CLEAR_SECOND_FORM_SUCCESS,
			message: "Second Form Cleared"
		});
	}
}

function* clearConfigurationMessage() {
	while (true) {
		yield take(clientConfigurationActionTypes.CLEAR_MESSAGE_REQUEST);

		yield put({
			type: clientConfigurationActionTypes.CLEAR_MESSAGE_SUCCESS
		});
	}
}

export {
	editLibraryCofiguration,
	showSingleLibraryConfigurationForEdit,
	showSingleLibraryConfiguration,
	clearConfigurationMessage,
	saveFirstForm,
	clearForms,
	clearSecondForms,
	saveSecondForm,
	saveThirdForm,
	saveConfiguration,
	createConfiguration,
	editCofiguration,
	showUserConfigurations,
	showSingleUserConfiguration,
	showSingleUserConfigurationForEdit
};
