import languageActionTypes from "./actionTypes";

function language_change(param) {
  return {
    type: languageActionTypes.LANGUAGE_CHANGE_REQUEST,
    loading: true,
    param: param
  };
}

export { language_change };
