import clientOrderActionTypes from "./actionTypes";

function show_orders(user) {
  return {
    type: clientOrderActionTypes.SHOW_ORDERS_REQUEST,
    loading: true,
    user: user
  };
}

function create_new_order(users, params) {
  return {
    type: clientOrderActionTypes.CREATE_ORDER_REQUEST,
    loading: true,
    users: users,
    params: params
  };
}

function show_single_order(user, params, role) {
  return {
    type: clientOrderActionTypes.SHOW_SINGLE_ORDER_REQUEST,
    loading: true,
    params: params,
    user: user,
    role: role
  };
}

export { show_orders, create_new_order, show_single_order };
