import React, { Component } from "react";
import { connect } from "react-redux";
import { QuoteService } from "./../service";
import { Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { notification } from "antd";
import { Api } from "../../../config/api";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

class ShowQuotes extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			search: "",
			quotes: false,
			search_quotes: false
		};
		this.onSearch = this.onSearch.bind(this);
		this.fixQuoteFiles = this.fixQuoteFiles.bind(this);
	}

	async fixQuoteFiles(value) {
		let redirect = this;
		await Api.get("quote/generateFilesForQuote/" + value)
			.then(function(response) {
				if (response.data.message.includes("unsuccessul")) {
					notification.error({
						message: "Error!",
						description:
							"There is something worng with the quotation or the configurations in it."
					});
				} else {
					notification.success({
						message: "Success!",
						description: response.data.message
					});
				}
			})
			.catch(function(error) {
				notification.error({
					message: "Error!",
					description: error.data.message
				});
			});

		let data = await QuoteService.showQuoteWithFileStatus();
		if (data) {
			if (data !== []) {
				redirect.setState({ quotes: data });
				redirect.setState({ search_quotes: data });
			} else {
				notification.info({
					message: "Quotes not found!"
				});
			}
		}
	}

	onSearch(name, value) {
		let redirect = this;
		switch (name) {
			case "search":
				redirect.setState({ search: value });
				let parts = redirect.state.quotes;
				parts = parts.filter(
					item =>
						item.quotation_name.toLowerCase().includes(value) ||
						item.quotation_for.toLowerCase().includes(value)
				);

				redirect.setState({ search_quotes: parts });
				break;

			default:
				break;
		}
	}

	async componentDidMount() {
		let redirect = this;

		let data = await QuoteService.showQuoteWithFileStatus();
		if (data) {
			if (data !== []) {
				redirect.setState({ quotes: data });
				redirect.setState({ search_quotes: data });
			} else {
				notification.info({
					message: "Quotes not found!"
				});
			}
		}
		redirect.setState({ loading: false });
	}

	render() {
		if (this.state.search_quotes) {
			return (
				<div>
					<div
						className="content"
						style={{
							minHeight: 500
						}}
					>
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-header">
										<strong className="card-title">Quotes</strong>
									</div>
									<div className="card-body">
										<div className="card-content">
											<div className="todo-list">
												<div className="tdl-holder">
													<div className="tdl-content">
														<div className="row">
															<div className="col-sm-6"></div>
															<div className="col-sm-2"></div>
															<div className="col-sm-4">
																<div className="form-group">
																	<input
																		name="search"
																		className="form-control"
																		id="search"
																		placeholder="Filter Parts"
																		value={this.state.search}
																		onChange={e =>
																			this.onSearch(
																				"search",
																				e.target.value
																			)
																		}
																	/>
																</div>
															</div>
														</div>
														<ul>
															{this.state.search_quotes.map(
																(quote, i) => (
																	<li key={i}>
																		<label
																			style={{
																				color: "black"
																			}}
																		>
																			<div className="row">
																				<div className="col-sm-8">
																					{/* <Link to={"/quote-detail/" + quote.id}> */}
																					{quote.quotation_name +
																						" - [" +
																						quote.name +
																						"]"}
																					{/* </Link> */}
																				</div>
																				<div className="col-sm-3">
																					{quote.file_status ? (
																						<CheckCircleOutlined
																							style={{
																								fontSize: 30,
																								color:
																									"green",
																								pointerEvents:
																									"none"
																							}}
																						/>
																					) : (
																						<CloseCircleOutlined
																							style={{
																								fontSize: 30,
																								color:
																									"red",
																								pointerEvents:
																									"none"
																							}}
																						/>
																					)}
																					<i
																						onClick={() =>
																							this.fixQuoteFiles(
																								quote.quote_id
																							)
																						}
																						style={{
																							fontSize: 30
																						}}
																						className="ml-3 fa fa-wrench"
																						aria-hidden="true"
																					></i>
																					<Link
																						to={
																							"/quote-detail/" +
																							quote.quote_id
																						}
																					>
																						<i className="fa text-dark fa-arrow-right"></i>
																					</Link>
																				</div>
																			</div>
																		</label>
																	</li>
																)
															)}
														</ul>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div>
					<div
						className="content"
						style={{
							minHeight: 500
						}}
					>
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-header">
										<strong className="card-title">Quotes</strong>
									</div>
									<div className="card-body">
										<div className="card-content">
											<div className="todo-list">
												<div className="tdl-holder">
													<div className="tdl-content">
														<div className="row">
															<div className="col"></div>
															<div className="col">
																<div className="text-center">
																	<Loader
																		type="Rings"
																		color="#00BFFF"
																		height={100}
																		width={100}
																	/>
																	<div className="text-center client-roboto-condensed">
																		LOADING...
																	</div>
																</div>
															</div>
															<div className="col"></div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}
}
const mapStateToProps = state => {
	return {
		machine_parts: state.showMachinePartReducer.machine_parts,
		message: state.showMachinePartReducer.message
	};
};

export default connect(mapStateToProps, {})(ShowQuotes);
