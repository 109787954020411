import { Api } from "./../../config/api";

function showUsers(params) {
  // Api.defaults.headers.common["Authorization"] = params;
  let data = Api.get("auth/users")
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {
      return false;
    });

  if (data) {
    return data;
  } else {
    return false;
  }
}

function validateUser(params) {
  let data = Api.post("auth/validate/" + params.id, params)
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {
      return false;
    });

  if (data) {
    return data;
  } else {
    return false;
  }
}

function createRole(params) {
  let data = Api.post("user-role", params)
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {
      return false;
    });

  if (data) {
    return data;
  } else {
    return false;
  }
}

export const UserService = {
  showUsers,
  validateUser,
  createRole
};
