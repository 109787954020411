import keyModelFeatureSetActionTypes from "./actionTypes";

const INITIAL_STATE = {
	message: "",
	loading: false,
	features: [],
	feature: []
};

export default function(state = INITIAL_STATE, action) {
	switch (action.type) {
		case keyModelFeatureSetActionTypes.CLEAN_MESSAGE_REQUEST:
			return {
				...state,
				loading: true
			};
		case keyModelFeatureSetActionTypes.CLEAN_MESSAGE_SUCCESS:
			return {
				...state,
				loading: false,
				message: ""
			};
		case keyModelFeatureSetActionTypes.CLEAN_MESSAGE_FALIURE:
			return {
				...state,
				loading: false
			};

		case keyModelFeatureSetActionTypes.SHOW_KEY_MODEL_FEATURES_REQUEST:
			return {
				...state,
				loading: true
			};
		case keyModelFeatureSetActionTypes.SHOW_KEY_MODEL_FEATURES_SUCCESS:
			return {
				...state,
				loading: false,
				message: action.message,
				features: action.features
			};
		case keyModelFeatureSetActionTypes.SHOW_KEY_MODEL_FEATURES_FALIURE:
			return {
				...state,
				loading: false,
				message: action.message
			};

		case keyModelFeatureSetActionTypes.SHOW_SINGLE_KEY_MODEL_FEATURE_REQUEST:
			return {
				...state,
				loading: true
			};
		case keyModelFeatureSetActionTypes.SHOW_SINGLE_KEY_MODEL_FEATURE_SUCCESS:
			return {
				...state,
				loading: false,
				message: action.message,
				feature: action.feature
			};
		case keyModelFeatureSetActionTypes.SHOW_SINGLE_KEY_MODEL_FEATURE_FALIURE:
			return {
				...state,
				loading: false,
				message: action.message
			};

		case keyModelFeatureSetActionTypes.ADD_KEY_MODEL_FEATURE_REQUEST:
			return {
				...state,
				loading: true
			};
		case keyModelFeatureSetActionTypes.ADD_KEY_MODEL_FEATURE_SUCCESS:
			return {
				...state,
				loading: false,
				message: action.message
			};
		case keyModelFeatureSetActionTypes.ADD_KEY_MODEL_FEATURE_FALIURE:
			return {
				...state,
				loading: false,
				message: action.message
			};

		case keyModelFeatureSetActionTypes.UPDATE_KEY_MODEL_FEATURE_REQUEST:
			return {
				...state,
				loading: true
			};
		case keyModelFeatureSetActionTypes.UPDATE_KEY_MODEL_FEATURE_SUCCESS:
			return {
				...state,
				loading: false,
				message: action.message
			};
		case keyModelFeatureSetActionTypes.UPDATE_KEY_MODEL_FEATURE_FALIURE:
			return {
				...state,
				loading: false,
				message: action.message
			};

		case keyModelFeatureSetActionTypes.DELETE_KEY_MODEL_FEATURE_REQUEST:
			return {
				...state,
				loading: true
			};
		case keyModelFeatureSetActionTypes.DELETE_KEY_MODEL_FEATURE_SUCCESS:
			return {
				...state,
				loading: false,
				message: action.message
			};
		case keyModelFeatureSetActionTypes.DELETE_KEY_MODEL_FEATURE_FALIURE:
			return {
				...state,
				loading: false,
				message: action.message
			};

		case keyModelFeatureSetActionTypes.UPDATE_KEY_MODEL_FEATURES_REQUEST:
			return {
				...state,
				loading: true
			};
		case keyModelFeatureSetActionTypes.UPDATE_KEY_MODEL_FEATURES_SUCCESS:
			return {
				...state,
				loading: false,
				message: action.message
			};
		case keyModelFeatureSetActionTypes.UPDATE_KEY_MODEL_FEATURES_FALIURE:
			return {
				...state,
				loading: false,
				message: action.message
			};

		default:
			return state;
	}
}
