import { Api } from "./../../config/api";

// api call to show all brands
function showAllArticles() {
	let data = Api.get("article").then(function(response) {
		return response.data;
	});

	if (data) {
		return data;
	} else {
		return false;
	}
}

//api call to show a single brand
function showSingleArticle(param) {
	let data = Api.get("article/" + param).then(function(response) {
		return response.data;
	});

	if (data) {
		return data;
	} else {
		return false;
	}
}

//api call to add a brand into the system
function addArticle(params) {
	let data = Api.post("article", params).then(function(response) {
		return response.data;
	});

	if (data) {
		return data;
	} else {
		return false;
	}
}

//api call to edit brand exisitng in the system
function updateArticle(params) {
	let data = Api.post("article/edit/" + params.get("id"), params).then(
		function(response) {
			return response.data;
		}
	);

	if (data) {
		return data;
	} else {
		return false;
	}
}

//api call to delete exisitng brand from the system
function deleteArticle(params) {
	let data = Api.get("article/delete/" + params).then(function(response) {
		return response.data;
	});

	if (data) {
		return data;
	} else {
		return false;
	}
}

export const ArticleService = {
	showAllArticles,
	showSingleArticle,
	addArticle,
	updateArticle,
	deleteArticle
};
