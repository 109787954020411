import React, { Component } from "react";
import { MachinePartService } from "./../service";
import { add_machine_parts } from "./../action";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { CountryService } from "./../../country/service";
import SimpleReactValidator from "simple-react-validator";
import { Api } from "./../../../config/api";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { notification } from "antd";
import { BrandService } from "./../../brand/service";

class AddMachinePart extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: false,
			loading: true,
			loaded: false,
			machine_part_model_number: "",
			machine_part_name: "",
			machine_part_type: "",
			machine_part_note: "",
			machine_part_image: null,
			machine_part_remarks: "",
			machine_part_priority: 1,
			is_visible: 1,
			is_included: 0,
			parent_model_number: [],
			negative_parent_model_number: [],
			countries: [],
			country: "",
			years: [],
			year: "",
			price: "",
			cost_price: "",
			keys: [],
			tabs: [],
			brands: false,
			brand: "",
			discount_applicable: "true"
		};
		this.validator = new SimpleReactValidator({ autoForceUpdate: this });
		this.onTextChange = this.onTextChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.handleFileChange = this.handleFileChange.bind(this);
	}

	handleFileChange(event) {
		this.setState({ machine_part_image: event.target.files[0] });
	}

	async componentDidMount() {
		let redirect = this;

		let data = await MachinePartService.showYear();
		await redirect.setState({ years: data });

		let countries = await CountryService.showCountries();
		await redirect.setState({ countries: countries });

		let brands = await BrandService.showBrands();
		await redirect.setState({ brands: brands });

		let machine_parts = await MachinePartService.getMachinePartsForParentSelection();
		await redirect.setState({ data: machine_parts });

		redirect.setState({ loading: false });
	}

	onTextChange(name, value) {
		switch (name) {
			case "machine_part_model_number":
				this.setState({ machine_part_model_number: value });
				break;

			case "machine_part_name":
				this.setState({ machine_part_name: value });
				break;

			case "machine_part_type":
				this.setState({ machine_part_type: value });
				break;

			case "machine_part_note":
				this.setState({ machine_part_note: value });
				break;

			case "machine_part_remarks":
				this.setState({ machine_part_remarks: value });
				break;

			case "machine_part_priority":
				this.setState({ machine_part_priority: value });
				break;

			case "is_included":
				this.setState({ is_included: value });
				break;

			case "is_visible":
				this.setState({ is_visible: value });
				break;

			case "parent_model_number":
				this.setState({ parent_model_number: value });
				break;

			case "negative_parent_model_number":
				this.setState({ negative_parent_model_number: value });
				break;

			case "cost_price":
				this.setState({ cost_price: value });
				break;

			case "price":
				this.setState({ price: value });
				break;

			case "year":
				this.setState({ year: value });
				break;

			case "country":
				this.setState({ country: value });
				break;

			case "brand":
				this.setState({ brand: value });
				break;
			case "discount_applicable":
				this.setState({ discount_applicable: value });
				break;

			default:
				break;
		}
	}

	// Form Data component used to make request
	async onSubmit(e) {
		let redirect = this;
		e.preventDefault();

		if (this.validator.allValid()) {
			let alert = await Api.get(
				"machine-parts/checkIfMachinePartsExist/" +
					this.state.machine_part_model_number
			).then(function(response) {
				return response.data.message;
			});

			if (alert.toLowerCase().includes("not")) {
				let data = new FormData();
				data.append(
					"machine_part_model_number",
					redirect.state.machine_part_model_number
				);
				data.append("machine_part_name", redirect.state.machine_part_name);
				data.append("machine_part_type", redirect.state.machine_part_type);
				data.append("machine_part_note", redirect.state.machine_part_note);
				data.append(
					"machine_part_remarks",
					redirect.state.machine_part_remarks
				);
				data.append(
					"machine_part_priority",
					redirect.state.machine_part_priority
				);
				data.append("is_visible", redirect.state.is_visible);
				data.append("is_included", redirect.state.is_included);
				data.append(
					"parent_model_number",
					redirect.state.parent_model_number
				);
				data.append(
					"negative_model_number",
					redirect.state.negative_parent_model_number
				);
				data.append("country_id", redirect.state.country);
				data.append("brand_id", redirect.state.brand);
				data.append("year", redirect.state.year);
				data.append("price", redirect.state.price);
				data.append("cost_price", redirect.state.cost_price);
				data.append(
					"discount_applicable",
					redirect.state.discount_applicable
				);

				if (this.state.category_image !== null) {
					data.append("file", this.state.machine_part_image);
				}

				this.props.add_machine_parts(data);
			} else {
				notification.info({
					message: "Information!",
					description: "Machine part model number already exists."
				});
			}
		} else {
			this.validator.showMessages();
		}
	}

	render() {
		if (this.props.message.includes("Adding machine parts success")) {
			return <Redirect to="/machine-part" />;
		} else {
			if (this.state.loading || this.props.loading) {
				return (
					<div>
						<div
							className="content"
							style={{
								minHeight: 500
							}}
						>
							<div className="row">
								<div className="col-sm-12">
									<div className="card">
										<div className="card-header">
											<strong className="card-title">
												Add Machine Parts
											</strong>
										</div>
										<div className="card-body">
											<div className="card-content">
												<div className="todo-list">
													<div className="tdl-holder">
														<div className="tdl-content">
															<div className="row">
																<div className="col"></div>
																<div className="col">
																	<div className="text-center">
																		<Loader
																			type="Rings"
																			color="#00BFFF"
																			height={100}
																			width={100}
																		/>
																		<div className="text-center client-roboto-condensed">
																			LOADING...
																		</div>
																	</div>
																</div>
																<div className="col"></div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				);
			} else {
				return (
					<div>
						<div
							className="content"
							style={{
								minHeight: 500
							}}
						>
							<div className="row">
								<div className="col-sm-12">
									<div className="card">
										<div className="card-header">
											<strong className="card-title">
												Add Machine Parts
											</strong>
										</div>
										<div className="card-body">
											<form>
												<div className="row">
													<div className="col-sm-6">
														<div className="form-group">
															<label
																htmlFor="machine_part_model_number"
																className="text-center control-label mb-1"
															>
																Machine Part Model Number
															</label>
															<input
																id="machine_part_model_number"
																name="machine_part_model_number"
																type="text"
																className="form-control"
																onChange={e =>
																	this.onTextChange(
																		"machine_part_model_number",
																		e.target.value
																	)
																}
																value={
																	this.state
																		.machine_part_model_number
																}
															/>
															<div
																className="mt-4"
																style={{
																	color: "red"
																}}
															>
																{this.validator.message(
																	"machine_part_model_number",
																	this.state
																		.machine_part_model_number,
																	"required|string"
																)}
															</div>
														</div>
													</div>
													<div className="col-sm-6">
														<div className="form-group">
															<label
																htmlFor="machine_part_name"
																className="text-center control-label mb-1"
															>
																Machine Part Name
															</label>
															<input
																id="machine_part_name"
																name="machine_part_name"
																type="text"
																className="form-control"
																onChange={e =>
																	this.onTextChange(
																		"machine_part_name",
																		e.target.value
																	)
																}
																value={
																	this.state.machine_part_name
																}
															/>
															<div
																className="mt-4"
																style={{
																	color: "red"
																}}
															>
																{this.validator.message(
																	"machine_part_name",
																	this.state.machine_part_name,
																	"required|string"
																)}
															</div>
														</div>
														<div className="form-group">
															<div className="file-input">
																<input
																	type="file"
																	id="file-input"
																	className="file-input__input"
																	name="category_image"
																	onChange={e =>
																		this.handleFileChange(e)
																	}
																	accept="image/jpg, image/jpeg, image/png"
																/>
																{/* <label
																	className="file-input__label"
																	for="file-input"
																>
																	<svg
																		aria-hidden="true"
																		focusable="false"
																		data-prefix="fas"
																		data-icon="upload"
																		className="svg-inline--fa fa-upload fa-w-16"
																		role="img"
																		xmlns="http://www.w3.org/2000/svg"
																		viewBox="0 0 512 512"
																	>
																		<path
																			fill="currentColor"
																			d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"
																		></path>
																	</svg>
																	<span>
																		Machine Part Image
																	</span>
																</label> */}
															</div>
															{/* <label
																htmlFor="parent_category"
																className="control-label mb-1"
															>
																Machine Part Image
															</label>
															<br />
															<input
																className="form-group inputfile"
																type="file"
																name="category_image"
																onChange={e => this.handleFileChange(e)}
															/> */}
														</div>
													</div>
													<div className="col-sm-6">
														<div className="form-group">
															<label
																htmlFor="machine_part_type"
																className="text-center control-label mb-1"
															>
																Machine Part Type
															</label>
															<input
																id="machine_part_type"
																name="machine_part_type"
																type="text"
																className="form-control"
																onChange={e =>
																	this.onTextChange(
																		"machine_part_type",
																		e.target.value
																	)
																}
																value={
																	this.state.machine_part_type
																}
															/>
															<div
																className="mt-4"
																style={{
																	color: "red"
																}}
															>
																{this.validator.message(
																	"machine_part_type",
																	this.state.machine_part_type,
																	"required|string"
																)}
															</div>
														</div>
													</div>
													<div className="col-sm-6">
														<div className="form-group">
															<label
																htmlFor="machine_part_note"
																className="text-center control-label mb-1"
															>
																Machine Part Note
															</label>
															<input
																id="machine_part_note"
																name="machine_part_note"
																type="text"
																className="form-control"
																onChange={e =>
																	this.onTextChange(
																		"machine_part_note",
																		e.target.value
																	)
																}
																value={
																	this.state.machine_part_note
																}
															/>
															<div
																className="mt-4"
																style={{
																	color: "red"
																}}
															>
																{this.validator.message(
																	"machine_part_note",
																	this.state.machine_part_note,
																	"string"
																)}
															</div>
														</div>
													</div>
													<div className="col-sm-6">
														<div className="form-group">
															<label
																htmlFor="machine_part_remarks"
																className="text-center control-label mb-1"
															>
																Machine Part Remarks
															</label>
															<input
																id="machine_part_remarks"
																name="machine_part_remarks"
																type="text"
																className="form-control"
																onChange={e =>
																	this.onTextChange(
																		"machine_part_remarks",
																		e.target.value
																	)
																}
																value={
																	this.state
																		.machine_part_remarks
																}
															/>
															<div
																className="mt-4"
																style={{
																	color: "red"
																}}
															>
																{this.validator.message(
																	"machine_part_remarks",
																	this.state
																		.machine_part_remarks,
																	"string"
																)}
															</div>
														</div>
													</div>
													<div className="col-sm-6">
														<div className="form-group">
															<label
																htmlFor="machine_part_priority"
																className="text-center control-label mb-1"
															>
																Machine Part Priority
															</label>
															<select
																value={
																	this.state
																		.machine_part_priority
																}
																className="custom-select"
																onChange={e =>
																	this.onTextChange(
																		"machine_part_priority",
																		e.target.value
																	)
																}
															>
																<option value={1}>Yes</option>
																<option value={0}>No</option>
															</select>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-sm-6">
														<div className="form-group">
															<label
																htmlFor="price"
																className="text-center control-label mb-1"
															>
																Machine Part Price
															</label>
															<input
																id="price"
																name="price"
																type="text"
																className="form-control"
																onChange={e =>
																	this.onTextChange(
																		"price",
																		e.target.value
																	)
																}
																value={this.state.price}
															/>
															<div
																className="mt-4"
																style={{
																	color: "red"
																}}
															>
																{this.validator.message(
																	"price",
																	this.state.price,
																	"required|numeric"
																)}
															</div>
														</div>
													</div>
													<div className="col-sm-6">
														<div className="form-group">
															<label
																htmlFor="cost price"
																className="text-center control-label mb-1"
															>
																Machine Part Cost Price
															</label>
															<input
																id="cost_price"
																name="cost_price"
																type="text"
																className="form-control"
																onChange={e =>
																	this.onTextChange(
																		"cost_price",
																		e.target.value
																	)
																}
																value={this.state.cost_price}
															/>
															<div
																className="mt-4"
																style={{
																	color: "red"
																}}
															>
																{this.validator.message(
																	"cost price",
																	this.state.cost_price,
																	"required|numeric"
																)}
															</div>
														</div>
													</div>
													<div className="col-sm-6">
														<div className="form-group">
															<label
																htmlFor="year"
																className="control-label mb-1"
															>
																Year
															</label>
															<select
																value={this.state.year}
																className="custom-select"
																onChange={e =>
																	this.onTextChange(
																		"year",
																		e.target.value
																	)
																}
															>
																<option>Select Year</option>
																{this.state.years.map(
																	(year, i) => (
																		<option
																			key={i}
																			value={year.id}
																		>
																			{year.year}
																		</option>
																	)
																)}
															</select>
															<div
																className="mt-4"
																style={{
																	color: "red"
																}}
															>
																{this.validator.message(
																	"year",
																	this.state.year,
																	"required"
																)}
															</div>
														</div>
													</div>
													<div className="col-sm-6">
														<div className="form-group">
															<label
																htmlFor="is_visible"
																className="text-center control-label mb-1"
															>
																Machine Part Visible
															</label>
															<select
																value={this.state.is_visible}
																className="custom-select"
																onChange={e =>
																	this.onTextChange(
																		"is_visible",
																		e.target.value
																	)
																}
															>
																<option value={1}>Yes</option>
																<option value={0}>No</option>
															</select>
														</div>
													</div>
													<div className="col-sm-6">
														<div className="form-group">
															<label
																htmlFor="is_included"
																className="text-center control-label mb-1"
															>
																Machine Part Included
															</label>
															<select
																value={this.state.is_included}
																className="custom-select"
																onChange={e =>
																	this.onTextChange(
																		"is_included",
																		e.target.value
																	)
																}
															>
																<option value={1}>Yes</option>
																<option value={0}>No</option>
															</select>
														</div>
													</div>
													{/* <div className="col-sm-6">
														<div className="form-group">
															<label
																htmlFor="parent_model_number"
																className="text-center control-label mb-1"
															>
																Parent Model Number
																{this.state.parent_model_number}
															</label>
															<MultiSelect
																value={
																	this.state
																		.parent_model_number
																}
																options={this.state.data}
																onChange={e =>
																	this.onTextChange(
																		"parent_model_number",
																		e.value
																	)
																}
																className="multi-select"
																filter={true}
																placeholder="Choose parent parts"
															/>
														</div>
													</div>
													<div className="col-sm-6">
														<div className="form-group">
															<label
																htmlFor="negative_parent_model_number"
																className="text-center control-label mb-1"
															>
																Negative Parent Model Number
																{
																	this.state
																		.negative_parent_model_number
																}
															</label>
															<MultiSelect
																value={
																	this.state
																		.negative_parent_model_number
																}
																options={this.state.data}
																onChange={e =>
																	this.onTextChange(
																		"negative_parent_model_number",
																		e.value
																	)
																}
																className="multi-select"
																filter={true}
																placeholder="Choose negative parent parts"
															/>
														</div>
													</div> */}
													<div className="col-sm-6">
														<div className="form-group">
															<label
																htmlFor="country"
																className="text-center control-label mb-1"
															>
																Country
															</label>
															<select
																value={this.state.country}
																className="custom-select"
																onChange={e =>
																	this.onTextChange(
																		"country",
																		e.target.value
																	)
																}
															>
																<option>Select Country</option>
																{this.state.countries.map(
																	(country, i) => (
																		<option
																			key={i}
																			value={country.id}
																		>
																			{country.country_name}
																		</option>
																	)
																)}
															</select>
															<div
																className="mt-4"
																style={{
																	color: "red"
																}}
															>
																{this.validator.message(
																	"country",
																	this.state.country,
																	"required|numeric"
																)}
															</div>
														</div>
													</div>
													<div className="col-sm-6">
														<div className="form-group">
															<label
																htmlFor="brand"
																className="text-center control-label mb-1"
															>
																Brand
															</label>
															<select
																value={this.state.brand}
																className="custom-select"
																onChange={e =>
																	this.onTextChange(
																		"brand",
																		e.target.value
																	)
																}
															>
																<option>Select brand</option>
																{this.state.brands
																	? this.state.brands.map(
																			(brand, i) => (
																				<option
																					key={i}
																					value={brand.id}
																				>
																					{
																						brand.brand_name
																					}
																				</option>
																			)
																	  )
																	: null}
															</select>

															<div
																className="mt-4"
																style={{
																	color: "red"
																}}
															>
																{this.validator.message(
																	"brand",
																	this.state.brand,
																	"required|numeric"
																)}
															</div>
														</div>
													</div>

													{/* <div className="col-sm-6">

														<div className="form-group">
															<label
																htmlFor="discount_applicable"
																className="text-center control-label mb-1"
															>
																Discount Applicable
															</label>
															<select
																value={
																	this.state
																		.discount_applicable
																}
																className="custom-select"
																onChange={e =>
																	this.onTextChange(
																		"discount_applicable",
																		e.target.value
																	)
																}
															>
																<option value={0}>No</option>
																<option value={1}>Yes</option>
															</select>
														</div>
													</div> */}

												</div>
												<div>
													<button
														onClick={this.onSubmit}
														id="payment-button"
														type="submit"
														className="btn btn-secondary btn-block"
													>
														<i className="fa fa-truck"></i>&nbsp;
														<span id="payment-button-amount">
															Create Machine Part
														</span>
													</button>
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				);
			}
		}
	}
}
const mapStateToProps = state => {
	return {
		machine_parts: state.showMachinePartReducer.machine_parts,
		message: state.showMachinePartReducer.message,
		loading: state.showMachinePartReducer.loading
	};
};

export default connect(mapStateToProps, { add_machine_parts })(AddMachinePart);
