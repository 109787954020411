import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Api } from "./../../../config/api";
import { update_note_and_remark, clean_message } from "./../action";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Select, notification } from "antd";

const { Option } = Select;
class AddOrUpdateNoteAndRemark extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			custom_note: "",
			custom_remark: "",
			custom_include: "",
			custom_quantity: "",
			custom_priority: "",
			machine_category_id: false,
			machine_category: false,
			machine_part: false,
			parent_model_numbers: false,
			negative_parent_model_numbers: false,
			selected_parent_model_numbers: [],
			selected_negative_parent_model_numbers: []
		};
		this.onSubmit = this.onSubmit.bind(this);
	}

	async componentDidMount() {
		await this.props.clean_message();
		let { machine_category_id } = this.props.match.params;
		let { machine_part_id } = this.props.match.params;

		let that = this;
		that.setState({ machine_category_id: machine_category_id });

		Api.get(
			"/compatibility/getCustomNoteAndRemark/" +
				machine_part_id +
				"/" +
				machine_category_id
		)
			.then(async function(response) {
				await that.setState({ custom_note: response.data[0].custom_note });
				await that.setState({
					custom_remark: response.data[0].custom_remark
				});
				await that.setState({
					custom_include: response.data[0].custom_include
				});
				await that.setState({
					custom_quantity: response.data[0].custom_quantity
				});
				await that.setState({
					custom_priority: response.data[0].custom_priority
				});
				await that.setState({
					machine_category: response.data[0].machine_category
				});
				await that.setState({
					machine_part: response.data[0].machine_part_name
				});

				if (
					response.data[0].custom_parent_model_number !== "" &&
					response.data[0].custom_parent_model_number !== null
				) {
					await that.setState({
						selected_parent_model_numbers: response.data[0].custom_parent_model_number.split(
							","
						)
					});
				}
				if (
					response.data[0].custom_negative_parent_model_number !== "" &&
					response.data[0].custom_negative_parent_model_number !== null
				) {
					await that.setState({
						selected_negative_parent_model_numbers: response.data[0].custom_negative_parent_model_number.split(
							","
						)
					});
				}
			})
			.catch(function(error) {});

		Api.get(
			"/compatibility/getCustomModelNumbers/" +
				machine_part_id +
				"/" +
				machine_category_id
		)
			.then(function(response) {
				that.setState({ parent_model_numbers: response.data });
				that.setState({ negative_parent_model_numbers: response.data });
			})
			.catch(function(error) {});

		this.setState({ loading: false });
	}

	async onSubmit(e) {
		e.preventDefault();
		let { machine_category_id } = this.props.match.params;
		let { machine_part_id } = this.props.match.params;

		let x = false;
		let negative = this.state.selected_negative_parent_model_numbers;
		let positive = this.state.selected_parent_model_numbers;

		let flag = 0;
		for (x of negative) {
			if (positive.includes(x)) {
				flag = flag + 1;
			}
		}
		if (flag > 0) {
			notification.info({
				message: "Warning!",
				description:
					"Machine parts selected in parent model number cannot be in negative parent model number and vice-versa."
			});
		} else {
			let params = {
				machine_category_id: machine_category_id,
				machine_part_id: machine_part_id,
				custom_note: this.state.custom_note,
				custom_remark: this.state.custom_remark,
				custom_include: this.state.custom_include,
				custom_quantity: this.state.custom_quantity,
				custom_priority: this.state.custom_priority,
				custom_parent_model_number: this.state
					.selected_parent_model_numbers,
				custom_negative_parent_model_number: this.state
					.selected_negative_parent_model_numbers
			};

			this.props.update_note_and_remark(params);
		}
	}

	async onTextChange(name, value) {
		let redirect = this;
		switch (name) {
			case "custom_note":
				redirect.setState({ custom_note: value });
				break;

			case "custom_remark":
				redirect.setState({ custom_remark: value });
				break;

			case "custom_include":
				redirect.setState({ custom_include: value });
				break;

			case "custom_quantity":
				redirect.setState({ custom_quantity: value });
				break;

			case "custom_priority":
				redirect.setState({ custom_priority: value });
				break;

			case "selected_parent_model_numbers":
				redirect.setState({ selected_parent_model_numbers: value });
				break;

			case "selected_negative_parent_model_numbers":
				redirect.setState({
					selected_negative_parent_model_numbers: value
				});
				break;

			default:
				break;
		}
	}

	render() {
		if (!this.props.loading && !this.state.loading) {
			if (this.props.message.includes("success")) {
				return (
					<Redirect
						to={
							"/show-compatible-parts/" +
							this.props.match.params.machine_category_id
						}
					/>
				);
			} else {
				return (
					<div>
						<div
							className="content"
							style={{
								minHeight: 500
							}}
						>
							<div className="row">
								<div className="col-sm-12">
									<div className="card">
										<div className="card-header">
											<strong className="card-title">
												Update note and remark{" "}
												{this.state.machine_part
													? "for " + this.state.machine_part
													: null}{" "}
												{this.state.machine_category
													? " [ " +
													  this.state.machine_category +
													  " ]"
													: null}
											</strong>
										</div>
										<div className="card-body">
											<form>
												<div className="row">
													<div className="col-sm-12">
														<div className="form-group">
															<label
																htmlFor="custom_note"
																className="control-label mb-1"
															>
																Custom Note
															</label>
															<input
																id="custom_note"
																name="custom_note"
																type="text"
																className="form-control"
																aria-required="true"
																onChange={e =>
																	this.onTextChange(
																		"custom_note",
																		e.target.value
																	)
																}
																value={this.state.custom_note}
															/>
														</div>

														<div className="form-group">
															<label
																htmlFor="custom_remark"
																className="control-label mb-1"
															>
																Custom Remark
															</label>
															<input
																id="custom_remark"
																name="custom_remark"
																type="text"
																className="form-control"
																aria-required="true"
																onChange={e =>
																	this.onTextChange(
																		"custom_remark",
																		e.target.value
																	)
																}
																value={this.state.custom_remark}
															/>
														</div>

														<div className="form-group">
															<label
																htmlFor="custom_include"
																className="control-label mb-1"
															>
																Custom Included Quantity
															</label>
															<input
																id="custom_include"
																name="custom_include"
																type="text"
																className="form-control"
																aria-required="true"
																onChange={e =>
																	this.onTextChange(
																		"custom_include",
																		e.target.value
																	)
																}
																value={
																	this.state.custom_include
																}
															/>
														</div>

														<div className="form-group">
															<label
																htmlFor="custom_quantity"
																className="control-label mb-1"
															>
																Custom Quantity
															</label>
															<input
																id="custom_quantity"
																name="custom_quantity"
																type="text"
																className="form-control"
																aria-required="true"
																onChange={e =>
																	this.onTextChange(
																		"custom_quantity",
																		e.target.value
																	)
																}
																value={
																	this.state.custom_quantity
																}
															/>
														</div>

														<div className="form-group">
															<label
																htmlFor="custom_priority"
																className="control-label mb-1"
															>
																Custom Priority
															</label>
															<select
																id="custom_priority"
																name="custom_quantity"
																type="text"
																className="custom-select"
																aria-required="true"
																onChange={e =>
																	this.onTextChange(
																		"custom_priority",
																		e.target.value
																	)
																}
																value={
																	this.state.custom_priority
																}
															>
																<option value={0}>No</option>
																<option value={1}>Yes</option>
															</select>
														</div>
														<div className="form-group">
															<label
																htmlFor="selected_parent_model_numbers"
																className="text-center control-label mb-1"
															>
																Parent Model Number
															</label>
															<Select
																optionFilterProp="children"
																filterOption={(input, option) =>
																	option.props.children
																		.toLowerCase()
																		.indexOf(
																			input.toLowerCase()
																		) >= 0
																}
																mode={"multiple"}
																value={
																	this.state
																		.selected_parent_model_numbers
																}
																onChange={e =>
																	this.onTextChange(
																		"selected_parent_model_numbers",
																		e
																	)
																}
																showSearch={true}
															>
																{this.state.parent_model_numbers
																	? this.state.parent_model_numbers.map(
																			option =>
																				option.disabled ? (
																					<Option
																						key={
																							option.value
																						}
																						disabled
																					>
																						{option.label}
																					</Option>
																				) : (
																					<Option
																						key={
																							option.value
																						}
																					>
																						{option.label}
																					</Option>
																				)
																	  )
																	: null}
															</Select>
														</div>
														<div className="form-group">
															<label
																htmlFor="selected_negative_parent_model_numbers"
																className="text-center control-label mb-1"
															>
																Negative Parent Model Number
															</label>
															<Select
																optionFilterProp="children"
																filterOption={(input, option) =>
																	option.props.children
																		.toLowerCase()
																		.indexOf(
																			input.toLowerCase()
																		) >= 0
																}
																mode={"multiple"}
																value={
																	this.state
																		.selected_negative_parent_model_numbers
																}
																options={
																	this.state
																		.negative_parent_model_numbers
																}
																onChange={e =>
																	this.onTextChange(
																		"selected_negative_parent_model_numbers",
																		e
																	)
																}
																showSearch={true}
															>
																{this.state
																	.negative_parent_model_numbers
																	? this.state.negative_parent_model_numbers.map(
																			option =>
																				option.disabled ? (
																					<Option
																						key={
																							option.value
																						}
																						disabled
																					>
																						{option.label}
																					</Option>
																				) : (
																					<Option
																						key={
																							option.value
																						}
																					>
																						{option.label}
																					</Option>
																				)
																	  )
																	: null}
															</Select>
														</div>
													</div>
												</div>
												<div>
													<button
														onClick={this.onSubmit}
														id="payment-button"
														type="submit"
														className="btn btn-secondary btn-block"
													>
														<i className="fa fa-truck"></i>&nbsp;
														<span id="payment-button-amount">
															Update Note and Remark
														</span>
													</button>
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				);
			}
		} else {
			return (
				<div>
					<div
						className="content"
						style={{
							minHeight: 500
						}}
					>
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-header">
										<strong className="card-title">
											Update note and remark
										</strong>
									</div>
									<div className="card-body">
										<div className="card-content">
											<div className="todo-list">
												<div className="tdl-holder">
													<div className="tdl-content">
														<div className="row">
															<div className="col"></div>
															<div className="col">
																<div className="text-center">
																	<Loader
																		type="Rings"
																		color="#00BFFF"
																		height={100}
																		width={100}
																	/>
																	<div className="text-center client-roboto-condensed">
																		LOADING...
																	</div>
																</div>
															</div>
															<div className="col"></div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}
}

const mapStateToProps = state => {
	return {
		message: state.noteAndRemarkReducer.message,
		loading: state.noteAndRemarkReducer.loading
	};
};

export default connect(mapStateToProps, {
	update_note_and_remark,
	clean_message
})(AddOrUpdateNoteAndRemark);
