import sequenceActionTypes from "./actionTypes";
import { SequenceService } from "./service";
import { take, call, put } from "redux-saga/effects";

function* showSequenceForMachineCategory() {
  while (true) {
    let { params } = yield take(
      sequenceActionTypes.SHOW_SEQUENCE_FOR_MACHINE_CATEGORY_REQUEST
    );

    const sequences = yield call(
      SequenceService.showSequenceForMachineCategory,
      params
    );

    if (sequences !== "" && sequences !== null && sequences) {
      yield put({
        type: sequenceActionTypes.SHOW_SEQUENCE_FOR_MACHINE_CATEGORY_SUCCESS,
        message: "Getting Sequences Success",
        sequences: sequences
      });
    } else {
      yield put({
        type: sequenceActionTypes.SHOW_SEQUENCE_FOR_MACHINE_CATEGORY_FALIURE,
        message: "Getting Sequences Faliure"
      });
    }
  }
}

function* addOrEditSequenceForMachineCategory(params) {
  while (true) {
    const { params } = yield take(
      sequenceActionTypes.ADD_OR_EDIT_SEQUENCE_FOR_MACHINE_CATEGORY_REQUEST
    );

    const data = yield call(
      SequenceService.addOrEditSequenceForMachineCategory,
      params
    );

    if (data !== "" && data !== null && data) {
      yield put({
        type:
          sequenceActionTypes.ADD_OR_EDIT_SEQUENCE_FOR_MACHINE_CATEGORY_SUCCESS,
        message: "Adding or Editing Sequence Success"
      });
    } else {
      yield put({
        type:
          sequenceActionTypes.ADD_OR_EDIT_SEQUENCE_FOR_MACHINE_CATEGORY_FALIURE,
        message: "Adding or Editing Sequence Faliure"
      });
    }
  }
}

function* sequenceCleanMessage(params) {
  while (true) {
    const { params } = yield take(
      sequenceActionTypes.EDIT_SEQUENCE_FOR_MACHINE_CATEGORY_REQUEST
    );

    const data = yield call(
      SequenceService.editSequenceForMachineCategory,
      params
    );

    if (data !== "" && data !== null && data) {
      yield put({
        type: sequenceActionTypes.EDIT_SEQUENCE_FOR_MACHINE_CATEGORY_SUCCESS,
        message: "Editing Sequence Success"
      });
    } else {
      yield put({
        type: sequenceActionTypes.EDIT_SEQUENCE_FOR_MACHINE_CATEGORY_FALIURE,
        message: "Editing Sequence Faliure"
      });
    }
  }
}

function* editSequenceForMachineCategory() {
  while (true) {
    yield take(sequenceActionTypes.CLEAN_MESSAGE_REQUEST);

    yield put({ type: sequenceActionTypes.CLEAN_MESSAGE_SUCCESS, message: "" });
  }
}

export {
  showSequenceForMachineCategory,
  addOrEditSequenceForMachineCategory,
  editSequenceForMachineCategory,
  sequenceCleanMessage
};
