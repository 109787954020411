import React, { Component } from "react";
import { connect } from "react-redux";
import { css } from "@emotion/react";
import { PulseLoader } from "react-spinners";
import { Api } from "../../config/api";
import Swal from "sweetalert2";
import { List, notification, Button } from "antd";
import { Link } from "react-router-dom";

class Notificattions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      notifications: false,
      reply_comment: ""
    };
    this.approveRequest = this.approveRequest.bind(this);
    this.rejectRequest = this.rejectRequest.bind(this);
  }
  handleTextChange(value) {
    this.setState({ reply_comment: value });
  }

  async fetch(){
    let that = this;

    if (this.props.is_manager === 1) {
      await Api.get(
        "discount-notification/getAllDiscountForManager/" + this.props.user_id
      )
        .then(function(response) {
          that.setState({ notifications: response.data.all_unreplied_requested_notification });
        })
        .catch(function(error) {
        });

    } else {
      await Api.get(
        "discount-notification/getAllDiscount/" + this.props.user_id
      )
        .then(function(response) {
          that.setState({ notifications: response.data });
        })
        .catch(function(error) {
        });
    }
  }

  async approveRequest(notification_id) {
    let notification = this.state.notifications.filter(
      item => item.notification_id === notification_id
    );
    (async () => {
      const { value: formValues } = await Swal.fire({
        title: "Approval Message",
        html:
          "<label>Discount</label><input id=\"discount\" class=\"swal2-input\" placeholder=\"0-100\" value=\"" +
          notification[0].requested_discount_percentage +
          "\">" +
          "<label>Comment</label><textarea rows=\"6\" id=\"comment\" class=\"swal2-textarea\" />",
        focusConfirm: false,
        showCancelButton: true,
        preConfirm: () => {
          if (
            document.getElementById("discount").value === "" ||
            document.getElementById("comment").value === ""
          ) {
            return false;
          } else {
            return {
              discount: document.getElementById("discount").value,
              comment: document.getElementById("comment").value
            };
          }
        }
      });

      if (formValues) {
        if (formValues.discount <= this.props.max_discount) {
          return Api.post(
            "discount-notification/approveDiscountRequest/" +
            this.props.user_id +
            "/" +
            notification_id,
            formValues
          )
            .then(async response => {
              await this.fetch();
            })
            .catch(error => {
              Swal.showValidationMessage(`Request failed: ${error}`);
            });
        } else {
          await Swal.fire({
            icon: "error",
            title: "Oops...",
            text:
              "You can only approve discount up to " +
              this.props.max_discount +
              "!"
          });
        }
      } else {
      }
    })();
  }

  async rejectRequest(notification_id) {
    Swal.fire({
      title: "Reject Reason",
      input: "textarea",
      inputAttributes: {
        autocapitalize: "off"
      },
      showCancelButton: true,
      confirmButtonText: "Reject",
      showLoaderOnConfirm: true,
      preConfirm: comment => {
        return Api.post(
          "discount-notification/rejectDiscountRequest/" +
          this.props.user_id +
          "/" +
          notification_id,
          { comment: comment }
        )
          .then(async response => {
            await this.fetch();
          })
          .catch(error => {
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then(result => {
      if (result.value) {
        notification.success({
          message: "Information!",
          description: "Discount Rejected."
        });
      }
    });
  }

  async componentDidMount() {
    await this.fetch();
  }

  render() {
    const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
    `;
    if (this.props.loginLoading) {
      return (
        <div className="sweet-loading">
          <div className="container loading-container">
            <div className="row m-5 p-5">
              <PulseLoader
                css={override}
                sizeUnit={"px"}
                size={24}
                color={"#B9373D"}
              />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="my-4 client-roboto-condensed container">
          <div className="row my-5">
            <div className="col-2"></div>
            <div className="col-8 text-center">
              <h4>Discount Requests</h4>
            </div>
            <div className="col-2 text-align-right">
              <Link className="red-link" to="/notification-logs">
                Discount History
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col">
              {this.state.notifications ? (
                <List
                  locale={{
                    emptyText:
                      "Currently no open/new discount request. ( See history for old requests )"
                  }}
                  header={<div>Discount Request</div>}
                  bordered
                  dataSource={this.state.notifications}
                  renderItem={item => (
                    <List.Item
                      actions={[
                        <Button
                          onClick={() =>

                            this.approveRequest(
                              item.notification_id
                            )
                          }
                          className="btn client-login mx-2"
                        >
                          Approve
                        </Button>,
                        <Button
                          onClick={() =>
                            this.rejectRequest(
                              item.notification_id
                            )
                          }
                          className="btn client-login mx-2"
                        >
                          Reject
                        </Button>
                      ]}
                    >
                      <List.Item.Meta
                        title={item.name}
                        description={
                          item.name +
                          " has requested a discount percentage of " +
                          item.requested_discount_percentage +
                          " for configuration " +
                          item.configuration_name
                        }
                      />
                      <Link
                        className="red-link"
                        to={
                          "/configuration/" +
                          item.requested_configuration_id
                        }
                      >
                        See Configuration
                      </Link>
                    </List.Item>
                  )}
                />
              ) : null}
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.loginReducer.isAuthenticated,
    userRole: state.loginReducer.user.role,
    name: state.loginReducer.user.user.name,
    loginLoading: state.loginReducer.loading,
    user_id: state.loginReducer.user.user.id,
    role_id: state.loginReducer.user.role_id,
    token: state.loginReducer.token,
    language: state.languageReducer.selectedLanguage,
    max_discount: state.loginReducer.user.max_discount,
    is_manager: state.loginReducer.user.is_manager
  };
};

export default connect(mapStateToProps, {})(Notificattions);
