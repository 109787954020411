const userActionTypes = {
  SHOW_USER_REQUEST: "show.user.request",
  SHOW_USER_SUCCESS: "show.user.success",
  SHOW_USER_FALIURE: "show.user.faliure",

  VALIDATE_USER_REQUEST: "validate.user.request",
  VALIDATE_USER_SUCCESS: "validate.user.success",
  VALIDATE_USER_FALIURE: "validate.user.faliure",

  CLEAN_MESSAGE_REQUEST: "clean.message.request",
  CLEAN_MESSAGE_SUCCESS: "clean.message.success",
  CLEAN_MESSAGE_FALIURE: "clean.message.faliure"
};
export default userActionTypes;
