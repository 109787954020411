import React, { Component } from "react";
import { connect } from "react-redux";
import { CustomerFeedBackService } from "./../service";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { notification, Descriptions } from "antd";
import config from "../../../config/config";
import { Redirect } from "react-router";

class ListSingleFeedback extends Component {
	constructor(props) {
		super(props);
		this.state = {
			feedback: false,
			loading: true,
			redirect: false,
			status: [
				{
					id: 0,
					name: "PENDING"
				},
				{
					id: 1,
					name: "COMPLETE"
				},
				{
					id: 2,
					name: "SOLVED"
				},
				{
					id: 3,
					name: "OTHER"
				}
			]

		};
		this.onDelete = this.onDelete.bind(this);
		this.onNotifyDeveloper = this.onNotifyDeveloper.bind(this);
		this.commentEditable = this.commentEditable.bind(this);
		this.submitComment = this.submitComment.bind(this);
		this.onStatusChange = this.onStatusChange.bind(this);
	}

	commentEditable() {
		let feedback = this.state.feedback;

		feedback[0].comment_to_developer_editable = true;
		this.setState({ feedback: feedback });
	}

	handleTextChange(name, value) {
		switch (name) {
			case "comment":
				let feedback = this.state.feedback;
				feedback[0].comment_to_developer = value;
				this.setState({ feedback: feedback });
				break;

			default:
				break;
		}
	}

	onStatusChange(value){
		let _feedback = this.state.feedback;
		_feedback[0].status = value;
		this.setState({
			feedback: _feedback
		}, async () => {
			await this.submitComment();
		})
	}

	async submitComment() {
		let { id } = this.props.match.params;
		let params = {
			status: this.state.feedback[0].status,
			comment_to_developer: this.state.feedback[0].comment_to_developer
		};

		let data = await CustomerFeedBackService.editFeedback(params, id);

		if (data) {
			notification.success({
				message: "Success!",
				description: data.message
			});

			let feedback = this.state.feedback;

			feedback[0].comment_to_developer_editable = false;
			this.setState({ feedback: feedback });
		}
	}

	async onDelete() {
		let confirmation = window.confirm("Are you sure?");

		if (confirmation) {
			let { id } = this.props.match.params;
			let data = await CustomerFeedBackService.deleteFeedback(id);

			// show all brands [redux]

			if (data) {
				this.setState({ redirect: true });
			}
		}
	}

	async onNotifyDeveloper() {
		let confirmation = window.confirm("Are you sure?");

		if (confirmation) {
			let { id } = this.props.match.params;
			let data = await CustomerFeedBackService.notify(id);

			// show all brands [redux]

			if (data) {
				notification.success({
					message: "Success!",
					description: "Developer has been notified."
				});
			}
		}
	}

	async componentDidMount() {
		let { id } = this.props.match.params;
		let feedback = await CustomerFeedBackService.showSingleFeedback(id);

		this.setState({ feedback: feedback });
		this.setState({ loading: false });
	}

	render() {
		if (this.state.redirect) {
			return <Redirect to="/show-customer-feedbacks" />;
		} else {
			if (!this.state.loading) {
				return (
					<div>
						<div
							className="content"
							style={{
								minHeight: 500
							}}
						>
							<div className="row">
								<div className="col-sm-12">
									<div className="card">
										<div className="card-header">
											<strong className="card-title">
												System Support Detail
											</strong>
										</div>
										<div className="card-body">
											<div className="card-content">
												<div className="todo-list">
													<div className="tdl-holder">
														<div className="tdl-content">
															<div className="row my-3">
																<div className="col-12">
																	<Descriptions
																		title=""
																		layout="hotizontal"
																		bordered
																		// size="small"
																		column={1}
																	>
																		<Descriptions.Item label="Requested By">
																			{this.state.feedback
																				? this.state
																						.feedback[0]
																						.name
																				: ""}
																		</Descriptions.Item>
																		<Descriptions.Item label="Requested Email">
																			{this.state.feedback
																				? this.state
																						.feedback[0]
																						.email
																				: ""}
																		</Descriptions.Item>
																		<Descriptions.Item label="Feedback Type">
																			{this.state.feedback
																				? this.state
																						.feedback[0]
																						.type
																				: ""}
																		</Descriptions.Item>
																		<Descriptions.Item label="Problem Description">
																			{this.state.feedback
																				? this.state
																						.feedback[0]
																						.problem_description
																				: ""}
																		</Descriptions.Item>
																		<Descriptions.Item label="Status">
																			<select
																				className="custom-select"
																				value={this.state.feedback && this.state
																					.feedback[0]
																					.status}
																				onChange={e =>
																					this.onStatusChange(
																						e.target.value
																					)
																				}
																			>
																				{this.state.status && this.state.status.map((item,i)=>(
																					<option value={item.id} key={i}>{item.name}</option>
																				))}
																			</select>
																		</Descriptions.Item>
																		<Descriptions.Item label="Comment to Developer">
																			{this.state
																				.feedback ? (
																				this.state
																					.feedback[0]
																					.comment_to_developer_editable ? (
																					<>
																						<textarea
																							cols={50}
																							rows={5}
																							style={{padding: '5px'}}
																							value={
																								this
																									.state
																									.feedback[0]
																									.comment_to_developer
																							}
																							onChange={e =>
																								this.handleTextChange(
																									"comment",
																									e
																										.target
																										.value
																								)
																							}
																						/>
																						<i
																							onClick={() =>
																								this.submitComment()
																							}
																							className="ml-2 fas fa-check"
																						></i>
																					</>
																				) : (
																					<>
																						{
																							this.state
																								.feedback[0]
																								.comment_to_developer
																						}
																						<i
																							onClick={() =>
																								this.commentEditable()
																							}
																							className="ml-2 fas fa-edit"
																						></i>
																					</>
																				)
																			) : (
																				""
																			)}
																		</Descriptions.Item>
																		<Descriptions.Item label="Frequency">
																			{this.state.feedback
																				? this.state
																						.feedback[0]
																						.frequency
																				: ""}
																		</Descriptions.Item>
																		<Descriptions.Item label="Severity">
																			{this.state.feedback
																				? this.state
																						.feedback[0]
																						.serverity
																				: ""}
																		</Descriptions.Item>
																		{this.state.feedback ? (
																			this.state.feedback[0]
																				.file_attachments !==
																			null ? (
																				<Descriptions.Item label="File Attachements">
																					<a
																						href={
																							config.APP_URL +
																							"/api/storage/" +
																							this.state
																								.feedback[0]
																								.file_attachments
																						}
																						rel="noopener noreferrer"
																						target="_blank"
																						download
																					>
																						Download
																					</a>
																				</Descriptions.Item>
																			) : null
																		) : null}
																	</Descriptions>
																</div>
															</div>
															<div className="row">
																<div className="col text-center">
																	<button
																		onClick={() =>
																			this.onDelete()
																		}
																		className="btn client-login px-5"
																	>
																		Delete
																	</button>
																</div>
																<div className="col text-center">
																	<button
																		onClick={() =>
																			this.onNotifyDeveloper()
																		}
																		className="btn client-login px-5"
																	>
																		Notify Developer
																	</button>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				);
			} else {
				return (
					<div>
						<div
							className="content"
							style={{
								minHeight: 500
							}}
						>
							<div className="row">
								<div className="col-sm-12">
									<div className="card">
										<div className="card-header">
											<strong className="card-title">
												System Support Detail
											</strong>
										</div>
										<div className="card-body">
											<div className="card-content">
												<div className="todo-list">
													<div className="tdl-holder">
														<div className="tdl-content">
															<div className="row">
																<div className="col"></div>
																<div className="col">
																	<div className="text-center">
																		<Loader
																			type="Rings"
																			color="#00BFFF"
																			height={100}
																			width={100}
																		/>
																		<div className="text-center client-roboto-condensed">
																			LOADING...
																		</div>
																	</div>
																</div>
																<div className="col"></div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				);
			}
		}
	}
}
const mapStateToProps = state => {
	return {
		message: state.brandReducer.message,
		loading: state.brandReducer.loading,
		brands: state.brandReducer.brands
	};
};

export default connect(mapStateToProps, {})(ListSingleFeedback);
