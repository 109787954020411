import React, { Component } from "react";
import { connect } from "react-redux";
import SortableComponent from "./sortable";
import { ClientConfigurationService } from "./../../../client/configuration/service";
import { Api } from "./../../../config/api";
import { add_or_edit_sequence_for_machine_category } from "./../action";
import { Redirect } from "react-router-dom";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

class AddSequenceForMachineCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      types: false,
      machine_category: false
    };
    this.handleTypesChange = this.handleTypesChange.bind(this);
    this.handleButtonPress = this.handleButtonPress.bind(this);
  }

  async handleButtonPress() {
    let { id } = this.props.match.params;
    let params = {
      machine_category_id: parseInt(id),
      sequence: this.state.types
    };

    await this.props.add_or_edit_sequence_for_machine_category(params);
  }

  handleTypesChange(types) {
    this.setState({ types: types });
  }

  async componentDidMount() {
    let that = this;
    let { id } = that.props.match.params;
    let total_types = [];

    let compatible_machine_parts = await ClientConfigurationService.showCompatibilityForCategory(
      id
    );

    compatible_machine_parts.forEach(element => {
      total_types.push(element.machine_part_type.trim().toLowerCase());
    });

    total_types = [...new Set(total_types)];

    // await this.setState({types: total_types})

    await Api.get("machine-category/" + id)
      .then(function(response) {
        this.setState({ machine_category: response.data });
      })
      .catch(function(error) {});

    let existing_sequence = await Api.get("sequence/" + id)
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {});

    if (
      existing_sequence.length === 0 ||
      existing_sequence.length < total_types.length
    ) {
		total_types.sort(function(a, b) {
			if (existing_sequence.indexOf(a) === -1) {
				return total_types.length;
			}
			return existing_sequence.indexOf(a) - existing_sequence.indexOf(b);
		});
      await this.setState({ types: total_types });
    } else {
      total_types.sort(function(a, b) {
        return existing_sequence.indexOf(a) - existing_sequence.indexOf(b);
      });
      await this.setState({ types: total_types });
    }
    this.setState({ loading: false });
  }

  render() {
    if (
      this.props.message
        .toLowerCase()
        .includes("adding or editing sequence success")
    ) {
      return <Redirect to="/sequence" />;
    } else {
      if (this.state.loading || this.props.loading) {
        return (
          <div>
            <div
              className="content"
              style={{
                minHeight: 500
              }}
            >
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <strong className="card-title">
                        {!this.state.machine_category ? null : (
                          <strong className="card-title">
                            Add of Update sequence of{" "}
                            {this.state.machine_category[0].machine_category}
                          </strong>
                        )}
                      </strong>
                    </div>
                    <div className="card-body">
                      <div className="card-content">
                        <div className="todo-list">
                          <div className="tdl-holder">
                            <div className="tdl-content">
                              <div className="row">
                                <div className="col"></div>
                                <div className="col">
                                  <div className="text-center">
                                    <Loader
                                      type="Rings"
                                      color="#00BFFF"
                                      height={100}
                                      width={100}
                                    />
                                    <div className="text-center client-roboto-condensed">
                                      LOADING...
                                    </div>
                                  </div>
                                </div>
                                <div className="col"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div>
            <div
              className="content"
              style={{
                minHeight: 500
              }}
            >
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      {!this.state.machine_category ? null : (
                        <strong className="card-title">
                          Add of Update sequence of{" "}
                          {this.state.machine_category[0].machine_category}
                        </strong>
                      )}
                    </div>
                    <div className="card-body">
                      {!this.state.types ? null : (
                        <SortableComponent
                          types={this.state.types}
                          onComponentChange={this.handleTypesChange}
                        />
                      )}
                      <div className="text-center">
                        <button
                          onClick={this.handleButtonPress}
                          className="btn btn-secondary"
                        >
                          Add / Update
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
  }
}

const mapStateToProps = state => {
  return {
    message: state.sequenceReducer.message,
    loading: state.sequenceReducer.loading
  };
};

export default connect(mapStateToProps, {
  add_or_edit_sequence_for_machine_category
})(AddSequenceForMachineCategory);
