import { Api } from "./../../config/api";

// api call to show all updates
function showAllUpdates() {
    let data = Api.get("update/show").then(function (response) {
        return response.data;
    });

    if (data) {
        return data;
    } else {
        return false;
    }
}

//api call to show a single update
function showSingleUpdate(param) {
    let data = Api.get("update/showSingleUpdate/" + param).then(function (response) {
        return response.data;
    });

    if (data) {
        return data;
    } else {
        return false;
    }
}

//api call to add a update into the system
function createUpdate(params) {
    let data = Api.post("update/create", params).then(function (response) {
        return response.data;
    });

    if (data) {
        return data;
    } else {
        return false;
    }
}

//api call to edit update exisitng in the system
function editUpdate(params) {
    let data = Api.post("update/edit/" + params.get("id"), params).then(function (
        response
    ) {
        return response.data;
    });

    if (data) {
        return data;
    } else {
        return false;
    }
}

//api call to delete exisitng update from the system
function deleteUpdate(params) {
    let data = Api.get("update/delete/" + params).then(function (response) {
        return response.data;
    });

    if (data) {
        return data;
    } else {
        return false;
    }
}

//api call to delete exisitng update from the system
function makeVisible(params) {
    let data = Api.get("update/makeVisible/" + params).then(function (response) {
        return response.data;
    });

    if (data) {
        return data;
    } else {
        return false;
    }
}

//api call to delete exisitng update from the system
function notVisible(params) {
    let data = Api.get("update/notVisible/" + params).then(function (response) {
        return response.data;
    });

    if (data) {
        return data;
    } else {
        return false;
    }
}

//api call to delete exisitng update from the system
function toggleUpdateForAllUsers() {
    let data = Api.get("update/toggleUpdateForAllUsers").then(function (response) {
        return response.data;
    });

    if (data) {
        return data;
    } else {
        return false;
    }
}

//api call to delete exisitng update from the system
function toggleUpdateOffForAllUsers() {
    let data = Api.get("update/toggleUpdateOffForAllUsers").then(function (response) {
        return response.data;
    });

    if (data) {
        return data;
    } else {
        return false;
    }
}

//api call to delete exisitng update from the system
function toggleUpdateForSingleUser(params) {
    let data = Api.get("update/toggleUpdateForSingleUser/" + params).then(function (response) {
        return response.data;
    });

    if (data) {
        return data;
    } else {
        return false;
    }
}

export const UpdateService = {
    showAllUpdates,
    showSingleUpdate,
    createUpdate,
    editUpdate,
    deleteUpdate,
    notVisible,
    makeVisible,
    toggleUpdateForAllUsers,
    toggleUpdateForSingleUser,
    toggleUpdateOffForAllUsers
};
