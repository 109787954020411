import React, { Component } from "react";
import "react-dropdown-tree-select/dist/styles.css";
import { connect } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { CustomerFeedBackService } from "./../service";
import { notification, Progress } from "antd";
import { Api } from "../../../config/api";
import {Redirect} from 'react-router-dom'

class AddCustomerFeedback extends Component {
	constructor(props) {
		super(props);
		this.state = {
			redirect: false,
			types: false,
			type: "",
			frequencies: false,
			frequency: "",
			severities: false,
			severity: "",
			problem_description: "",
			file_attachment: null,
			loading: true,
			uploadPercentage: 0
		};
		this.validator = new SimpleReactValidator({ autoForceUpdate: this });
		this.onTextChange = this.onTextChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.handleFileChange = this.handleFileChange.bind(this);
	}

	handleFileChange(event) {
		this.setState({ file_attachment: event.target.files[0] });
	}

	// function to handle text changes in multiple fields [two-way binding]
	async onTextChange(name, value) {
		let redirect = this;
		switch (name) {
			case "type":
				redirect.setState({ type: value });
				break;

			case "severity":
				redirect.setState({ severity: value });
				break;

			case "frequency":
				redirect.setState({ frequency: value });
				break;

			case "problem_description":
				redirect.setState({ problem_description: value });
				break;

			default:
				break;
		}
	}

	// submit button when add brand is pressed
	async onSubmit(e) {
		e.preventDefault();
		const fd = new FormData();

		if (this.validator.allValid()) {
			fd.append("type_id", this.state.type);
			fd.append("serverity", this.state.serverity);
			fd.append("frequency", this.state.frequency);
			fd.append("problem_description", this.state.problem_description);

			if (this.state.brand_image !== null) {
				fd.append("file_attachments", this.state.file_attachment);
			}

			const options = {
				onUploadProgress: progressEvent => {
					const { loaded, total } = progressEvent;
					let percent = Math.floor((loaded * 100) / total);

					if (percent < 100) {
						this.setState({ uploadPercentage: percent });
					}
				}
			};

			Api.defaults.timeout = 120000;
			let data = await Api.post("feedback/create", fd, options).then(
				async res => {
					await this.setState({ uploadPercentage: 100 }, () => {
						setTimeout(() => {
							this.setState({ uploadPercentage: 0 });
							this.setState({ redirect: true });
						}, 1000);
					});
					return res.data;
				}
			);

			if (data) {
				notification.success({
					message: "Success!",
					description: data.message
				});
				this.setState({
					type: "",
					frequency: "",
					severity: "",
					problem_description: "",
					file_attachment: null,
					uploadPercentage: 0
				});
			}
		} else {
			this.validator.showMessages();
		}
	}

	async componentDidMount() {
		let types = await CustomerFeedBackService.showAllTypes();
		this.setState({ types: types });

		let frequencies = await CustomerFeedBackService.showAllFrequencies();
		this.setState({ frequencies: frequencies });

		let severities = await CustomerFeedBackService.showAllSeverities();
		this.setState({ severities: severities });

		this.setState({ loading: false });
	}

	render() {
		if (this.state.redirect) {
			return <Redirect to="/" />
		} else {
			if (this.state.loading) {
				return (
					<div className="container my-1">
						<div
							className="content"
							style={{
								minHeight: 500
							}}
						>
							<div className="row">
								<div className="col"></div>
								<div className="col-sm-6">
									<h5 className="font-weight-bold text-center my-5">
										Question/Remark System Support
									</h5>
									<div className="row">
										<div className="col"></div>
										<div className="col">
											<div className="text-center">
												<Loader
													type="Rings"
													color="#00BFFF"
													height={100}
													width={100}
												/>
												<div className="text-center client-roboto-condensed">
													LOADING...
												</div>
											</div>
										</div>
										<div className="col"></div>
									</div>
								</div>
								<div className="col"></div>
							</div>
						</div>
					</div>
				);
			} else {
				return (
					<div className="container my-1">
						<div
							className="content"
							style={{
								minHeight: 500
							}}
						>
							<div className="row">
								<div className="col"></div>
								<div className="col-sm-6">
									<h5 className="font-weight-bold text-center my-5">
										Question/Remark System Support
									</h5>
									<form encType="multipart/form-data">
										<div className="form-group text-center">
											<label
												htmlFor="brand_name"
												className="control-label mb-1"
											>
												Name
											</label>
											<input
												name="type"
												type="text"
												className="form-control"
												aria-required="true"
												value={this.props.name}
												disabled
											/>
										</div>
										<div className="form-group text-center">
											<label
												htmlFor="brand_name"
												className="control-label mb-1"
											>
												Email
											</label>
											<input
												name="type"
												type="text"
												className="form-control"
												disabled
												value={this.props.email}
											/>
										</div>
										<div className="form-group text-center">
											<label
												htmlFor="brand_name"
												className="control-label mb-1"
											>
												Feedback Type
											</label>
											<select
												value={this.state.type}
												className="custom-select"
												onChange={e =>
													this.onTextChange("type", e.target.value)
												}
											>
												<option value="">Select Type</option>
												{this.state.types
													? this.state.types.map((type, i) => (
															<option key={i} value={type.id}>
																{type.type}
															</option>
													  ))
													: null}
											</select>
											<div
												className="mt-4"
												style={{
													color: "red"
												}}
											>
												{this.validator.message(
													"type",
													this.state.type,
													"required|numeric"
												)}
											</div>
										</div>
										<div className="form-group text-center">
											{this.state.uploadPercentage > 0 &&
											this.state.uploadPercentage < 100 ? (
												<Progress
													percent={this.state.uploadPercentage}
												/>
											) : null}
										</div>
										<div className="form-group text-center">
											<label
												htmlFor="brand_name"
												className="control-label mb-1"
											>
												File Attachments
											</label>
											<input
												type="file"
												className="form-control"
												onChange={e => this.handleFileChange(e)}
												aria-required="true"
												accept=".mp4, .png, .jpg, .jpeg, .docx, .xls, .xlsx, .docx"
											/>
										</div>
										{/* <div className="form-group text-center">
										<label
											htmlFor="brand_name"
											className="control-label mb-1"
										>
											Feedback Frequency
										</label>
										<select
											value={this.state.frequency}
											className="custom-select"
											onChange={e =>
												this.onTextChange(
													"frequency",
													e.target.value
												)
											}
										>
											<option value="">Select Frequency</option>
											{this.state.frequencies
												? this.state.frequencies.map(
														(frequency, i) => (
															<option value={frequency.id}>
																{frequency.frequency}
															</option>
														)
												  )
												: null}
										</select>
										<div
											className="mt-4"
											style={{
												color: "red"
											}}
										>
											{this.validator.message(
												"frequency",
												this.state.frequency,
												"numeric"
											)}
										</div>
									</div>
									<div className="form-group text-center">
										<label
											htmlFor="brand_name"
											className="control-label mb-1"
										>
											Feedback Severity
										</label>
										<select
											value={this.state.severity}
											className="custom-select"
											onChange={e =>
												this.onTextChange(
													"severity",
													e.target.value
												)
											}
										>
											<option value="">Select Severity</option>
											{this.state.severities
												? this.state.severities.map(
														(severity, i) => (
															<option value={severity.id}>
																{severity.serverity}
															</option>
														)
												  )
												: null}
										</select>
										<div
											className="mt-4"
											style={{
												color: "red"
											}}
										>
											{this.validator.message(
												"severity",
												this.state.severity,
												"numeric"
											)}
										</div>
									</div> */}
										<div className="form-group text-center">
											<label
												htmlFor="brand_name"
												className="control-label mb-1"
											>
												Problem Description
											</label>
											<textarea
												rows={6}
												name="problem_description"
												type="text"
												className="form-control"
												onChange={e =>
													this.onTextChange(
														"problem_description",
														e.target.value
													)
												}
												aria-required="true"
											/>
											<div
												className="mt-4"
												style={{
													color: "red"
												}}
											>
												{this.validator.message(
													"problem_description",
													this.state.problem_description,
													"required|string"
												)}
											</div>
										</div>

										<div className="text-center">
											<button
												onClick={this.onSubmit}
												className="btn client-login px-5"
											>
												Submit Feedback
											</button>
										</div>
									</form>
								</div>
								<div className="col"></div>
							</div>
						</div>
					</div>
				);
			}
		}
	}
}

const mapStateToProps = state => {
	return {
		message: state.brandReducer.message,
		loading: state.brandReducer.loading,
		name: state.loginReducer.user.user.name,
		email: state.loginReducer.user.user.email
	};
};

export default connect(mapStateToProps, {})(AddCustomerFeedback);
