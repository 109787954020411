import { Api } from "./../../config/api";

// api call to show all feedbacks
function showAllFeedback() {
	let data = Api.get("feedback/show").then(function(response) {
		return response.data;
	});

	if (data) {
		return data;
	} else {
		return false;
	}
}

//api call to show a single feedback
function showSingleFeedback(param) {
	let data = Api.get("feedback/show/" + param).then(function(
		response
	) {
		return response.data;
	});

	if (data) {
		return data;
	} else {
		return false;
	}
}

//api call to add a feedback into the system
function createfeedback(params) {
	let data = Api.post("feedback/create", params).then(function(
		response
	) {
		return response.data;
	});

	if (data) {
		return data;
	} else {
		return false;
	}
}

function editFeedback(params, id) {
	let data = Api.post("feedback/edit/" + id, params).then(
		function(response) {
			return response.data;
		}
	);

	if (data) {
		return data;
	} else {
		return false;
	}
}

//api call to edit feedback exisitng in the system
function deleteFeedback(params) {
	let data = Api.get("feedback/delete/" + params, params).then(
		function(response) {
			return response.data;
		}
	);

	if (data) {
		return data;
	} else {
		return false;
	}
}

// api call to show all feedbacks
function showAllTypes() {
	let data = Api.get("feedback/showAllTypes").then(function(
		response
	) {
		return response.data;
	});

	if (data) {
		return data;
	} else {
		return false;
	}
}

//api call to show a single feedback
function showSingleType(param) {
	let data = Api.get("feedback/showSingleType/" + param).then(
		function(response) {
			return response.data;
		}
	);

	if (data) {
		return data;
	} else {
		return false;
	}
}

//api call to add a feedback into the system
function createType(params) {
	let data = Api.post("feedback/type", params).then(function(
		response
	) {
		return response.data;
	});

	if (data) {
		return data;
	} else {
		return false;
	}
}

//api call to edit feedback exisitng in the system
function editType(params, id) {
	let data = Api.post("feedback/type/edit/" + id, params).then(
		function(response) {
			return response.data;
		}
	);

	if (data) {
		return data;
	} else {
		return false;
	}
}

//api call to delete exisitng feedback from the system
function deleteType(params) {
	let data = Api.get("feedback/type/delete/" + params).then(function(
		response
	) {
		return response.data;
	});

	if (data) {
		return data;
	} else {
		return false;
	}
}
// api call to show all feedbacks
function showAllFrequencies() {
	let data = Api.get("feedback/showAllFrequencies").then(function(
		response
	) {
		return response.data;
	});

	if (data) {
		return data;
	} else {
		return false;
	}
}

//api call to show a single feedback
function showSingleFrequency(param) {
	let data = Api.get("feedback/showSingleFrequency/" + param).then(
		function(response) {
			return response.data;
		}
	);

	if (data) {
		return data;
	} else {
		return false;
	}
}

//api call to add a feedback into the system
function createFrequency(params) {
	let data = Api.post("feedback/frequency", params).then(function(
		response
	) {
		return response.data;
	});

	if (data) {
		return data;
	} else {
		return false;
	}
}

//api call to edit feedback exisitng in the system
function editFrequency(params, id) {
	let data = Api.post("feedback/frequency/edit/" + id, params).then(
		function(response) {
			return response.data;
		}
	);

	if (data) {
		return data;
	} else {
		return false;
	}
}

//api call to delete exisitng feedback from the system
function deleteFrequency(params) {
	let data = Api.get("feedback/frequency/delete/" + params).then(
		function(response) {
			return response.data;
		}
	);

	if (data) {
		return data;
	} else {
		return false;
	}
}
// api call to show all feedbacks
function showAllSeverities() {
	let data = Api.get("feedback/showAllSeverities").then(function(
		response
	) {
		return response.data;
	});

	if (data) {
		return data;
	} else {
		return false;
	}
}

//api call to show a single feedback
function showSingleSeverity(param) {
	let data = Api.get("feedback/showSingleSeverity/" + param).then(
		function(response) {
			return response.data;
		}
	);

	if (data) {
		return data;
	} else {
		return false;
	}
}

//api call to add a feedback into the system
function createServerity(params) {
	let data = Api.post("feedback/serverity", params).then(function(
		response
	) {
		return response.data;
	});

	if (data) {
		return data;
	} else {
		return false;
	}
}

//api call to edit feedback exisitng in the system
function editServerity(params, id) {
	let data = Api.post("feedback/serverity/edit/" + id, params).then(
		function(response) {
			return response.data;
		}
	);

	if (data) {
		return data;
	} else {
		return false;
	}
}

//api call to delete exisitng feedback from the system
function deleteServerity(params) {
	let data = Api.get("feedback/serverity/delete/" + params).then(
		function(response) {
			return response.data;
		}
	);

	if (data) {
		return data;
	} else {
		return false;
	}
}

function notify(params) {
	let data = Api.get("feedback/notify/" + params).then(function(
		response
	) {
		return response.data;
	});

	if (data) {
		return data;
	} else {
		return false;
	}
}

export const CustomerFeedBackService = {
	showAllFeedback,
	showSingleFeedback,
	createfeedback,
	editFeedback,
	deleteFeedback,
	showAllTypes,
	showSingleType,
	createType,
	editType,
	deleteType,
	showAllFrequencies,
	showSingleFrequency,
	createFrequency,
	editFrequency,
	deleteFrequency,
	showAllSeverities,
	showSingleSeverity,
	createServerity,
	editServerity,
    deleteServerity,
    notify
};
