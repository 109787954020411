import { Api } from "./../config/api";

function register(params) {
	let data = Api.post("auth/signup", params)
		.then(function(response) {
			return response.data;
		})
		.catch(function(error) {
			return false;
		});

	if (data) {
		return data;
	} else {
		return false;
	}
}


function registerAdmin(params) {
	let data = Api.post("auth/admin_signup", params)
		.then(function(response) {
			return response.data;
		})
		.catch(function(error) {
			return false;
		});

	if (data) {
		return data;
	} else {
		return false;
	}
}

function login(params) {
	let data = Api.post("auth/login", params)
		.then(function(response) {
			return response.data;
		})
		.catch(function(error) {
			return false;
		});

	if (data) {
		return data;
	} else {
		return false;
	}
}

function getLoginInfoWithToken(params) {
	let data = Api.post("auth/getLoginData", params)
		.then(function(response) {
			return response.data;
		})
		.catch(function(error) {
			return false;
		});

	if (data) {
		return data;
	} else {
		return false;
	}
}

function logout(params) {
	let data = Api.get("auth/logout")
		.then(function(response) {
			return response.data;
		})
		.catch(function(error) {
			return false;
		});

	if (data) {
		return data;
	} else {
		return false;
	}
}

async function getUser(params) {
	Api.defaults.headers.common["Authorization"] = params;
	let data = await Api.get("auth/user")
		.then(function(response) {
			return response.data;
		})
		.catch(function(error) {
			return false;
		});

	if (data) {
		return data;
	} else {
		return false;
	}
}

export const LoginService = {
	register,
	registerAdmin,
	login,
	logout,
	getUser,
	getLoginInfoWithToken
};
