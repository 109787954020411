import Axios from "axios";
import config from "./config";

export function setToken(token) {
  Axios.defaults.headers.common['Authorization'] =
      `${token}`;
}

export const Api = Axios.create({
  baseURL: config.APP_URL + "/api/",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});
