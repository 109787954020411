import { SpecificationService } from "./service";
import { take, call, put } from "redux-saga/effects";
import specificationActionTypes from "./actionTypes";

function* cleanSpecificationMessage() {
  while (true) {
    yield take(specificationActionTypes.CLEAN_MESSAGE_REQUEST);

    yield put({
      type: specificationActionTypes.CLEAN_MESSAGE_SUCCESS
    });
  }
}

function* showAllSpecificationForMachineCateogry() {
  while (true) {
    yield take(
      specificationActionTypes.SHOW_SPECIFICATION_FOR_CATEGORY_REQUEST
    );

    const specifications = yield call(
      SpecificationService.showAllSpecificationForMachineCateogry
    );

    if (
      specifications !== "" &&
      specifications !== null &&
      specifications !== [] &&
      specifications
    ) {
      yield put({
        type: specificationActionTypes.SHOW_SPECIFICATION_FOR_CATEGORY_SUCCESS,
        message: "Getting Specification Data Success",
        specifications: specifications
      });
    } else {
      yield put({
        type: specificationActionTypes.SHOW_SPECIFICATION_FOR_CATEGORY_FALIURE,
        message: "Getting Specification Data Faliure"
      });
    }
  }
}

function* showSingleSpecification() {
  while (true) {
    const { params } = yield take(
      specificationActionTypes.SHOW_SINGLE_SPECIFICATION_REQUEST
    );

    const specification = yield call(
      SpecificationService.showSingleSpecification,
      params
    );

    if (
      specification !== "" &&
      specification !== null &&
      specification !== [] &&
      specification
    ) {
      yield put({
        type: specificationActionTypes.SHOW_SINGLE_SPECIFICATION_SUCCESS,
        message: "Getting Specification Data Success",
        specification: specification
      });
    } else {
      yield put({
        type: specificationActionTypes.SHOW_SINGLE_SPECIFICATION_FALIURE,
        message: "Getting Specification Data Faliure"
      });
    }
  }
}

function* addSpecificationForMachineCategory() {
  while (true) {
    const { params } = yield take(
      specificationActionTypes.ADD_SPECIFICATION_FOR_CATEGORY_REQUEST
    );

    const specification = yield call(
      SpecificationService.addSpecificationForMachineCategory,
      params
    );

    if (
      specification !== "" &&
      specification !== null &&
      specification !== [] &&
      specification
    ) {
      yield put({
        type: specificationActionTypes.ADD_SPECIFICATION_FOR_CATEGORY_SUCCESS,
        message: "Adding Specification Data Success"
      });
    } else {
      yield put({
        type: specificationActionTypes.ADD_SPECIFICATION_FOR_CATEGORY_FALIURE,
        message: "Adding Specification Data Faliure"
      });
    }
  }
}

function* editSpecificationForMachineCategory() {
  while (true) {
    const { params } = yield take(
      specificationActionTypes.UPDATE_SPECIFICATION_FOR_CATEGORY_REQUEST
    );

    const specification = yield call(
      SpecificationService.editSpecificationForMachineCategory,
      params
    );

    if (
      specification !== "" &&
      specification !== null &&
      specification !== [] &&
      specification
    ) {
      yield put({
        type:
          specificationActionTypes.UPDATE_SPECIFICATION_FOR_CATEGORY_SUCCESS,
        message: "Updating Specification Data Success"
      });
    } else {
      yield put({
        type:
          specificationActionTypes.UPDATE_SPECIFICATION_FOR_CATEGORY_FALIURE,
        message: "Updating Specification Data Faliure"
      });
    }
  }
}

function* deleteSpecificationForMachineCategory() {
  while (true) {
    const { params } = yield take(
      specificationActionTypes.DELETE_SPECIFICATION_FOR_CATEGORY_REQUEST
    );

    const specification = yield call(
      SpecificationService.deleteSpecificationForMachineCategory,
      params
    );

    if (
      specification !== "" &&
      specification !== null &&
      specification !== [] &&
      specification
    ) {
      yield put({
        type:
          specificationActionTypes.DELETE_SPECIFICATION_FOR_CATEGORY_SUCCESS,
        message: "Deleting Specification Data Success"
      });
    } else {
      yield put({
        type:
          specificationActionTypes.DELETE_SPECIFICATION_FOR_CATEGORY_FALIURE,
        message: "Deleting Specification Data Faliure"
      });
    }
  }
}

export {
  showSingleSpecification,
  cleanSpecificationMessage,
  showAllSpecificationForMachineCateogry,
  addSpecificationForMachineCategory,
  editSpecificationForMachineCategory,
  deleteSpecificationForMachineCategory
};
