const sequenceActionTypes = {
  SHOW_SEQUENCE_FOR_MACHINE_CATEGORY_REQUEST:
    "show.sequence.for.machine.category.request",
  SHOW_SEQUENCE_FOR_MACHINE_CATEGORY_SUCCESS:
    "show.sequence.for.machine.category.success",
  SHOW_SEQUENCE_FOR_MACHINE_CATEGORY_FALIURE:
    "show.sequence.for.machine.category.faliure",

  ADD_OR_EDIT_SEQUENCE_FOR_MACHINE_CATEGORY_REQUEST:
    "add.or.edit.sequence.for.machine.category.request",
  ADD_OR_EDIT_SEQUENCE_FOR_MACHINE_CATEGORY_SUCCESS:
    "add.or.edit.sequence.for.machine.category.success",
  ADD_OR_EDIT_SEQUENCE_FOR_MACHINE_CATEGORY_FALIURE:
    "add.or.edit.sequence.for.machine.category.faliure",

  EDIT_SEQUENCE_FOR_MACHINE_CATEGORY_REQUEST:
    "edit.sequence.for.machine.category.request",
  EDIT_SEQUENCE_FOR_MACHINE_CATEGORY_SUCCESS:
    "edit.sequence.for.machine.category.success",
  EDIT_SEQUENCE_FOR_MACHINE_CATEGORY_FALIURE:
    "edit.sequence.for.machine.category.faliure",

  CLEAN_MESSAGE_REQUEST: "clean.message.request",
  CLEAN_MESSAGE_SUCCESS: "clean.message.success",
  CLEAN_MESSAGEG_FALIURE: "clean.message.faliure"
};
export default sequenceActionTypes;
