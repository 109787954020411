import React, { Component } from "react";
import "./../css/errorStyle.css";
import carcrash from "./../CPQ/carcrash.png";
import { Link } from "react-router-dom";

export default class ErrorPage extends Component {
  render() {
    return (
      <div className="container">
        <div className="site" height="100%" width="100%">
          <div className="sketch">
            <img loading="lazy"
              alt="404 Not Found"
              width="100%"
              height="100%"
              src={carcrash}
            />
          </div>
          <h1 id="error">
            Oops!!!
            <small>Looks like the page you are looking doesn't exist.</small>
            <Link id="error-message" className="red-link" to="/">
              Back to Homepage
            </Link>
          </h1>
        </div>
      </div>
    );
  }
}
