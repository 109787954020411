import React, { Component } from "react";
import { connect } from "react-redux";
import { FeatureSetService } from "./../service";
import { Link } from "react-router-dom";
import { clean_message } from "./../action";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { notification } from "antd";

class ListFeatures extends Component {
	constructor(props) {
		super(props);
		this.state = {
			features: false,
			search_features: false,
			search: ""
		};
		this.onFeatureChange = this.onFeatureChange.bind(this);
		this.onDelete = this.onDelete.bind(this);
	}

	onFeatureChange(name, value) {
		let redirect = this;
		switch (name) {
			case "feature":
				redirect.setState({ search: value });
				let parts = redirect.state.features;
				parts = parts.filter(item =>
					item.feature_title.toLowerCase().includes(value)
				);

				redirect.setState({ search_features: parts });
				break;

			default:
				break;
		}
	}

	async onDelete(id) {
		let confirmation = window.confirm("Are you sure?");

		if (confirmation) {
			let data = await FeatureSetService.deleteFeature(id);

			if (data.message.includes("success")) {
				notification.success({
					message: "Success!",
					description: data.message
				});
			} else {
				notification.error({
					message: "Error!",
					description: data.message
				});
			}

			let features = await FeatureSetService.showFeatures();
			this.setState({ search_features: features });
			this.setState({ features: features });
		}
	}

	async componentDidMount() {
		let redirect = this;

		let features = await FeatureSetService.showFeatures();
		redirect.setState({ search_features: features });
		redirect.setState({ features: features });

		if (this.props.message !== "" && this.props.message !== undefined) {
			if (this.props.message.toLowerCase().includes("success")) {
				notification.success({
					message: "Success!",
					description: this.props.message
				});
			} else {
				notification.error({
					message: "Error!",
					description: this.props.message
				});
			}
		}
		this.props.clean_message();
	}

	render() {
		if (this.state.search_features) {
			return (
				<div>
					<div
						className="content"
						style={{
							minHeight: 500
						}}
					>
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-header">
										<strong className="card-title">Features</strong>
									</div>
									<div className="card-body">
										<div className="card-content">
											<div className="todo-list">
												<div className="tdl-holder">
													<div className="tdl-content">
														<div className="row mb-2">
															<div className="col"></div>
															<div className="col">
																<div className="from-group">
																	<input
																		placeholder="Filter features"
																		className="form-control"
																		name="user"
																		id="user"
																		onChange={e =>
																			this.onFeatureChange(
																				"feature",
																				e.target.value
																			)
																		}
																		value={this.state.search}
																	/>
																</div>
															</div>
														</div>
														<ol>
															{this.state.search_features.map(
																(feature, i) => (
																	<li key={i}>
																		<label
																			style={{
																				color: "black"
																			}}
																		>
																			{feature.feature_title}
																			<Link
																				onClick={e =>
																					this.onDelete(
																						feature.id
																					)
																				}
																			>
																				<i className="fa text-dark fa-times"></i>
																			</Link>
																			<Link
																				to={
																					"/edit-feature/" +
																					feature.id
																				}
																			>
																				<i className="fa text-dark fa-pencil"></i>
																			</Link>
																		</label>
																	</li>
																)
															)}
														</ol>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div>
					<div
						className="content"
						style={{
							minHeight: 500
						}}
					>
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-header">
										<strong className="card-title">Features</strong>
									</div>
									<div className="card-body">
										<div className="card-content">
											<div className="todo-list">
												<div className="tdl-holder">
													<div className="tdl-content">
														<div className="row">
															<div className="col"></div>
															<div className="col">
																<div className="text-center">
																	<Loader
																		type="Rings"
																		color="#00BFFF"
																		height={100}
																		width={100}
																	/>
																	<div className="text-center client-roboto-condensed">
																		LOADING...
																	</div>
																</div>
															</div>
															<div className="col"></div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}
}

const mapStateToProps = state => {
	return {
		message: state.featureReducer.message
	};
};
export default connect(mapStateToProps, {
	clean_message
})(ListFeatures);
