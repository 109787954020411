import clientConfigurationActionTypes from "./actionTypes";

const INITIAL_STATE = {
	message: "",
	loading: false,
	first_form: false,
	second_form: false,
	third_form: false,
	configurations: false,
	configuration: false,
	category_details: false,
	configuration_id: false,
	configuration_type: false
};

export default function(state = INITIAL_STATE, action) {
	switch (action.type) {
		case clientConfigurationActionTypes.SAVE_FIRST_FORM_REQUEST:
			return {
				...state,
				loading: true
			};
		case clientConfigurationActionTypes.SAVE_FIRST_FORM_SUCCESS:
			return {
				...state,
				loading: false,
				message: action.message,
				first_form: action.first_form
			};
		case clientConfigurationActionTypes.SAVE_FIRST_FORM_FALIURE:
			return {
				...state,
				loading: false,
				message: action.message
			};

		case clientConfigurationActionTypes.SAVE_SECOND_FORM_REQUEST:
			return {
				...state,
				loading: true
			};
		case clientConfigurationActionTypes.SAVE_SECOND_FORM_SUCCESS:
			return {
				...state,
				loading: false,
				message: action.message,
				second_form: action.second_form
			};
		case clientConfigurationActionTypes.SAVE_SECOND_FORM_FALIURE:
			return {
				...state,
				loading: false,
				message: action.message
			};

		case clientConfigurationActionTypes.SAVE_THIRD_FORM_REQUEST:
			return {
				...state,
				loading: true
			};
		case clientConfigurationActionTypes.SAVE_THIRD_FORM_SUCCESS:
			return {
				...state,
				loading: false,
				message: action.message,
				third_form: action.third_form
			};
		case clientConfigurationActionTypes.SAVE_THIRD_FORM_FALIURE:
			return {
				...state,
				loading: false,
				message: action.message
			};

		case clientConfigurationActionTypes.SAVE_CONFIGURATION_REQUEST:
			return {
				...state,
				loading: true
			};
		case clientConfigurationActionTypes.SAVE_CONFIGURATION_SUCCESS:
			return {
				...state,
				loading: false,
				message: action.message
			};
		case clientConfigurationActionTypes.SAVE_CONFIGURATION_FALIURE:
			return {
				...state,
				loading: false,
				message: action.message
			};

		case clientConfigurationActionTypes.CREATE_CONFIGURATION_REQUEST:
			return {
				...state,
				loading: true
			};
		case clientConfigurationActionTypes.CREATE_CONFIGURATION_SUCCESS:
			return {
				...state,
				loading: false,
				message: action.message,
				configuration_id: action.configuration_id,
				configuration_type: action.configuration_type,
				first_form: false,
				second_form: false,
				third_form: false
			};
		case clientConfigurationActionTypes.CREATE_CONFIGURATION_FALIURE:
			return {
				...state,
				loading: false,
				message: action.message
			};

		case clientConfigurationActionTypes.EDIT_LIBRARY_CONFIGURATION_REQUEST:
			return {
				...state,
				loading: true
			};
		case clientConfigurationActionTypes.EDIT_LIBRARY_CONFIGURATION_SUCCESS:
			return {
				...state,
				loading: false,
				message: action.message
			};
		case clientConfigurationActionTypes.EDIT_LIBRARY_CONFIGURATION_FALIURE:
			return {
				...state,
				loading: false,
				message: action.message
			};

		case clientConfigurationActionTypes.EDIT_CONFIGURATION_REQUEST:
			return {
				...state,
				loading: true
			};
		case clientConfigurationActionTypes.EDIT_CONFIGURATION_SUCCESS:
			return {
				...state,
				loading: false,
				message: action.message
			};
		case clientConfigurationActionTypes.EDIT_CONFIGURATION_FALIURE:
			return {
				...state,
				loading: false,
				message: action.message
			};

		case clientConfigurationActionTypes.SHOW_USER_CONFIGURATIONS_REQUEST:
			return {
				...state,
				loading: true
			};
		case clientConfigurationActionTypes.SHOW_USER_CONFIGURATIONS_SUCCESS:
			return {
				...state,
				loading: false,
				message: action.message,
				configurations: action.configurations
			};
		case clientConfigurationActionTypes.SHOW_USER_CONFIGURATIONS_FALIURE:
			return {
				...state,
				loading: false,
				message: action.message,
				configurations: false
			};

		case clientConfigurationActionTypes.SHOW_SINGLE_USER_CONFIGURATION_REQUEST:
			return {
				...state,
				loading: true
			};
		case clientConfigurationActionTypes.SHOW_SINGLE_USER_CONFIGURATION_SUCCESS:
			return {
				...state,
				loading: false,
				message: action.message,
				configuration: action.configuration,
				first_form: action.firstForm,
				second_form: action.secondForm,
				category_details: action.category_details
			};
		case clientConfigurationActionTypes.SHOW_SINGLE_USER_CONFIGURATION_FALIURE:
			return {
				...state,
				loading: false,
				message: action.message,
				configuration: false,
				first_form: false,
				second_form: false,
				category_details: false
			};

		case clientConfigurationActionTypes.SHOW_SINGLE_LIBRARY_CONFIGURATION_REQUEST:
			return {
				...state,
				loading: true
			};
		case clientConfigurationActionTypes.SHOW_SINGLE_LIBRARY_CONFIGURATION_SUCCESS:
			return {
				...state,
				loading: false,
				message: action.message,
				configuration: action.configuration,
				first_form: action.firstForm,
				second_form: action.secondForm,
				category_details: action.category_details
			};
		case clientConfigurationActionTypes.SHOW_SINGLE_LIBRARY_CONFIGURATION_FALIURE:
			return {
				...state,
				loading: false,
				message: action.message,
				configuration: false,
				first_form: false,
				second_form: false,
				category_details: false
			};

		case clientConfigurationActionTypes.SHOW_SINGLE_USER_CONFIGURATION_FOR_EDIT_REQUEST:
			return {
				...state,
				loading: true
			};
		case clientConfigurationActionTypes.SHOW_SINGLE_USER_CONFIGURATION_FOR_EDIT_SUCCESS:
			return {
				...state,
				loading: false,
				message: action.message,
				configuration: action.configuration,
				first_form: action.firstForm,
				second_form: action.secondForm,
				category_details: action.category_details
			};
		case clientConfigurationActionTypes.SHOW_SINGLE_USER_CONFIGURATION_FOR_EDIT_FALIURE:
			return {
				...state,
				loading: false,
				message: action.message,
				configuration: false,
				first_form: false,
				second_form: false,
				category_details: false
			};

		case clientConfigurationActionTypes.SHOW_SINGLE_LIBRARY_CONFIGURATION_FOR_EDIT_REQUEST:
			return {
				...state,
				loading: true
			};
		case clientConfigurationActionTypes.SHOW_SINGLE_LIBRARY_CONFIGURATION_FOR_EDIT_SUCCESS:
			return {
				...state,
				loading: false,
				message: action.message,
				configuration: action.configuration,
				first_form: action.firstForm,
				second_form: action.secondForm,
				category_details: action.category_details
			};
		case clientConfigurationActionTypes.SHOW_SINGLE_LIBRARY_CONFIGURATION_FOR_EDIT_FALIURE:
			return {
				...state,
				loading: false,
				message: action.message,
				configuration: false,
				first_form: false,
				second_form: false,
				category_details: false
			};

		case clientConfigurationActionTypes.CLEAR_FORMS_REQUEST:
			return {
				...state,
				loading: true
			};
		case clientConfigurationActionTypes.CLEAR_FORMS_SUCCESS:
			return {
				...state,
				loading: false,
				message: action.message,
				first_form: false,
				second_form: false,
				configuration: false
			};
		case clientConfigurationActionTypes.CLEAR_FORMS_FALIURE:
			return {
				...state,
				loading: false
			};

		case clientConfigurationActionTypes.CLEAR_MESSAGE_REQUEST:
			return {
				...state,
				loading: true
			};
		case clientConfigurationActionTypes.CLEAR_MESSAGE_SUCCESS:
			return {
				...state,
				loading: false,
				message: ""
			};
		case clientConfigurationActionTypes.CLEAR_MESSAGE_FALIURE:
			return {
				...state,
				loading: false
			};

		case clientConfigurationActionTypes.CLEAR_SECOND_FORM_REQUEST:
			return {
				...state,
				loading: true
			};
		case clientConfigurationActionTypes.CLEAR_SECOND_FORM_SUCCESS:
			return {
				...state,
				loading: false,
				message: action.message,
				second_form: false
			};
		case clientConfigurationActionTypes.CLEAR_SECOND_FORM_FALIURE:
			return {
				...state,
				loading: false
			};

		default:
			return state;
	}
}
