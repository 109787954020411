import { Api } from "./../../config/api";

function showMachineCategories() {
	let data = Api.get("machine-category")
		.then(function(response) {
			return response.data;
		})
		.catch(function(error) {
			return false;
		});

	if (data) {
		return data;
	} else {
		return false;
	}
}

function showMachineCategoriesWithPrice(
	search = "",
	country_id = "",
	year_id = "",
	is_selectable = "",
	is_visible = ""
) {
	let data = false;
	if (search === "") {
		data = Api.get(
			"machine-category/category-with-price/" +
				null +
				"/" +
				country_id +
				"/" +
				year_id +
				`/${is_selectable !== "" ? is_selectable : null}`
			// `/${is_visible !== "" ? is_visible : null}`
		)
			.then(function(response) {
				return response.data;
			})
			.catch(function(error) {
				return false;
			});
	} else {
		data = Api.get(
			"machine-category/category-with-price/" +
				search +
				"/" +
				country_id +
				"/" +
				year_id +
				`/${is_selectable !== "" ? is_selectable : null}`
			// `/${is_visible !== "" ? is_visible : null}`
		)
			.then(function(response) {
				return response.data;
			})
			.catch(function(error) {
				return false;
			});
	}

	if (data) {
		return data;
	} else {
		return false;
	}
}

async function showSingleMachineCategory(params) {
	let data = await Api.get("machine-category/" + params + "/show")
		.then(function(response) {
			return response.data;
		})
		.catch(function(error) {
			return false;
		});

	if (data) {
		return data?.message;
	} else {
		return false;
	}
}

function showSingleMachineCategoryWithSpecificPrice(
	params,
	year_count,
	cost_year_count,
	year_id,
	country_id
) {
	let data = Api.get(
		"machine-category/with-price/" +
			params +
			"/" +
			year_count +
			"/" +
			cost_year_count +
			"/" +
			year_id +
			"/" +
			country_id
	)
		.then(function(response) {
			return response.data;
		})
		.catch(function(error) {
			return false;
		});

	if (data) {
		return data;
	} else {
		return false;
	}
}

function addMachineCategories(params) {
	let data = Api.post("machine-category", params)
		.then(function(response) {
			return response.data;
		})
		.catch(function(error) {
			return false;
		});

	if (data) {
		return data;
	} else {
		return false;
	}
}

function editMachineCategories(params) {
	let data = Api.post(
		"machine-category/edit/" + params.get("id"),
		params
	).then(function(response) {
		return response.data;
	});

	if (data) {
		return data;
	} else {
		return false;
	}
}

function deleteMachineCategoriesSpecific(id, year_id, year_count) {
	let data = Api.get(
		"machine-category/delete-price/" + id + "/" + year_id + "/" + year_count
	).then(function(response) {
		return response.data;
	});

	if (data) {
		return data;
	} else {
		return false;
	}
}

function deleteMachineCategories(params) {
	let data = Api.get("machine-category/delete/" + params).then(function(
		response
	) {
		return response.data;
	});

	if (data) {
		return data;
	} else {
		return false;
	}
}

function getTreeSelectData(id = 0) {
	let data = Api.get("machine-category/showTreeSelectData/" + id)
		.then(function(response) {
			return response.data;
		})
		.catch(function(error) {
			return false;
		});

	if (data) {
		return data;
	} else {
		return false;
	}
}

function getTreeSelectedData(params, role_id, country_id = null) {
	let data = false;
	if (country_id === null) {
		data = Api.get(
			"machine-category/showTreeSelectData/" + params + "/" + role_id
		)
			.then(function(response) {
				return response.data;
			})
			.catch(function(error) {
				return false;
			});
	} else {
		data = Api.get(
			"machine-category/showTreeSelectData/" +
				params +
				"/" +
				role_id +
				"/" +
				country_id
		)
			.then(function(response) {
				return response.data;
			})
			.catch(function(error) {
				return false;
			});
	}

	if (data) {
		return data;
	} else {
		return false;
	}
}

function getTreeCategory(role_id) {
	let data = Api.get("machine-category/tree-category/" + role_id)
		.then(function(response) {
			return response.data;
		})
		.catch(function(error) {
			return false;
		});

	if (data) {
		return data;
	} else {
		return false;
	}
}

function getBrands() {
	let data = Api.get("brand")
		.then(function(response) {
			return response.data;
		})
		.catch(function(error) {
			return false;
		});

	if (data) {
		return data;
	} else {
		return false;
	}
}

export const MachineCategoryService = {
	getBrands,
	showMachineCategoriesWithPrice,
	showMachineCategories,
	showSingleMachineCategory,
	showSingleMachineCategoryWithSpecificPrice,
	addMachineCategories,
	editMachineCategories,
	deleteMachineCategories,
	deleteMachineCategoriesSpecific,
	getTreeSelectData,
	getTreeSelectedData,
	getTreeCategory
};
