import quotesActionTypes from "./actionTypes";
const INITIAL_STATE = {
  message: "",
  loading: false,
  quotes: [],
  quote: []
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case quotesActionTypes.SHOW_QUOTES_REQUEST:
      return {
        ...state,
        loading: true
      };

    case quotesActionTypes.SHOW_QUOTES_SUCCESS:
      return {
        ...state,
        loading: false,
        quotes: action.quotes,
        message: action.message
      };

    case quotesActionTypes.SHOW_QUOTES_FALIURE:
      return {
        ...state,
        loading: false,
        message: action.message
      };

    case quotesActionTypes.SHOW_SINGLE_QUOTES_REQUEST:
      return {
        ...state,
        loading: true
      };

    case quotesActionTypes.SHOW_SINGLE_QUOTES_SUCCESS:
      return {
        ...state,
        loading: false,
        quote: action.quote,
        message: action.message
      };

    case quotesActionTypes.SHOW_SINGLE_QUOTES_FALIURE:
      return {
        ...state,
        loading: false,
        message: action.message
      };

    default:
      return state;
  }
}
