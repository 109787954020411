import React, { Component } from "react";
import { connect } from "react-redux";
import { show_user_configurations } from "./../../configuration/action";
import { show_quotes } from "./../../quotes/action";
import { show_orders } from "./../../order/action";
import { css } from "@emotion/react";
import { PulseLoader } from "react-spinners";
import { Api } from "./../../../config/api";
import VerticalBarchart from "./../../../charts/verticalBarchart";
import Barchart from "../../../charts/barchart";
import strings from "./../../../config/language";

class ClientDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: this.props.user !== [] ? this.props.user : false,
      configurations: [],
      quotes: [],
      orders: [],
      dashboard_data: [],
      loading: false,
      pendingConfigurationsFitlerByUser: false,
      pendingConfigurationsFilterByMachineCategory: false,
      totalConfigurationsFitlerByUser: false,
      totalConfigurationsFilterByMachineCategory: false,
      users: false,
      selected_pending_user: false,
      selected_pending_category: false,
      selected_total_user: false,
      selected_total_category: false,
      machineCategories: false
    };
    this.handlePendingUserChange = this.handlePendingUserChange.bind(this);
    this.handlePendingMachineCategoryChange = this.handlePendingMachineCategoryChange.bind(
      this
    );
    this.handleTotalUserChange = this.handleTotalUserChange.bind(this);
    this.handleTotalMachineCategoryChange = this.handleTotalMachineCategoryChange.bind(
      this
    );
  }

  async handleTotalUserChange(value) {
    let that = this;
    await that.setState({ selected_total_user: value });

    await that.setState({ totalConfigurationsFitlerByUser: false });

    await Api.get(
      "client-dashboard/configurations-filter-by-user/" +
        that.props.user_id +
        "?user_id=" +
        that.state.selected_total_user
    )
      .then(function(response) {
        if (response.data.length === 0) {
          that.setState({ totalConfigurationsFitlerByUser: false });
        } else {
          that.setState({ totalConfigurationsFitlerByUser: response.data });
        }
      })
      .catch(function(error) {});
  }

  async handleTotalMachineCategoryChange(value) {
    let that = this;
    await that.setState({ selected_total_category: value });

    await that.setState({ totalConfigurationsFilterByMachineCategory: false });

    await Api.get(
      "client-dashboard/configurations-filter-by-machine-category/" +
        that.props.user_id +
        "?machine_category_id=" +
        that.state.selected_total_category
    )
      .then(function(response) {
        if (response.data.length === 0) {
          that.setState({ totalConfigurationsFilterByMachineCategory: false });
        } else {
          that.setState({
            totalConfigurationsFilterByMachineCategory: response.data
          });
        }
      })
      .catch(function(error) {});
  }

  async handlePendingUserChange(value) {
    let that = this;
    await that.setState({ selected_pending_user: value });

    await that.setState({ pendingConfigurationsFitlerByUser: false });

    await Api.get(
      "client-dashboard/pending-configurations-filter-by-user/" +
        that.props.user_id +
        "?user_id=" +
        that.state.selected_pending_user
    )
      .then(function(response) {
        if (response.data.length === 0) {
          that.setState({ pendingConfigurationsFitlerByUser: false });
        } else {
          that.setState({ pendingConfigurationsFitlerByUser: response.data });
        }
      })
      .catch(function(error) {});
  }

  async handlePendingMachineCategoryChange(value) {
    let that = this;
    await that.setState({ selected_pending_category: value });

    await that.setState({
      pendingConfigurationsFilterByMachineCategory: false
    });

    await Api.get(
      "client-dashboard/pending-configurations-filter-by-machine-category/" +
        that.props.user_id +
        "?machine_category_id=" +
        that.state.selected_pending_category
    )
      .then(function(response) {
        if (response.data.length === 0) {
          that.setState({
            pendingConfigurationsFilterByMachineCategory: false
          });
        } else {
          that.setState({
            pendingConfigurationsFilterByMachineCategory: response.data
          });
        }
      })
      .catch(function(error) {});
  }

  async componentDidMount() {
    let that = this;
    await Api.get("getDashboardDataAccordingToUserTypes/" + that.props.user_id)
      .then(function(response) {
        that.setState({ dashboard_data: response.data });
      })
      .catch(function(error) {
        that.setState({ dashboard_data: false });
      });

    await Api.get(
      "client-dashboard/configurations-filter-by-user/" + this.props.user_id
    )
      .then(function(response) {
        that.setState({ totalConfigurationsFitlerByUser: response.data });
      })
      .catch(function(error) {});

    await Api.get(
      "client-dashboard/configurations-filter-by-machine-category/" +
        this.props.user_id
    )
      .then(function(response) {
        that.setState({
          totalConfigurationsFilterByMachineCategory: response.data
        });
      })
      .catch(function(error) {});

    await Api.get(
      "client-dashboard/pending-configurations-filter-by-user/" +
        this.props.user_id
    )
      .then(function(response) {
        that.setState({ pendingConfigurationsFitlerByUser: response.data });
      })
      .catch(function(error) {});

    await Api.get(
      "client-dashboard/pending-configurations-filter-by-machine-category/" +
        this.props.user_id
    )
      .then(function(response) {
        that.setState({
          pendingConfigurationsFilterByMachineCategory: response.data
        });
      })
      .catch(function(error) {});

    await Api.get("machine-category")
      .then(function(response) {
        that.setState({ machineCategories: response.data });
      })
      .catch(function(error) {});

    Api.defaults.headers.common["Authorization"] = this.props.token;
    await Api.get("auth/users")
      .then(function(response) {
        that.setState({ users: response.data });
      })
      .catch(function(error) {});

    await that.setState({ loading: true });
  }

  render() {
    strings.setLanguage(this.props.language);
    const override = css`
      display: block;
      margin: 0 auto;
      border-color: red;
    `;
    if (
      !this.state.user ||
      !this.state.loading ||
      this.props.configurationLoading ||
      this.props.quoteLoading ||
      this.props.orderLoading ||
      !this.state.dashboard_data
    ) {
      return (
        <div className="sweet-loading">
          <div className="container loading-container">
            <div className="row m-5 p-5">
              <PulseLoader
                css={override}
                sizeUnit={"px"}
                size={24}
                color={"#B9373D"}
              />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div className="mt-5 mb-5 client-roboto-condensed container">
            <div className="text-center">
              <h3>Dashboard</h3>
            </div>
            <div className="row m-5">
              {this.props.is_support &&
              this.state.dashboard_data &&
              this.state.dashboard_data !== [] ? (
                <>
                  <div className="row">
                    <div className="col text-center">
                      <h3>System Data</h3>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-3 col-md-6 my-3">
                      <div className="card">
                        <div className="card-body">
                          <div className="stat-widget-five">
                            <div className="text-center">
                              {this.state.dashboard_data.total_user}
                              <br />
                              Total Users
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 my-3">
                      <div className="card">
                        <div className="card-body">
                          <div className="stat-widget-five">
                            <div className="text-center">
                              {this.state.dashboard_data.total_configurations}
                              <br />
                              Total Configurations
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 my-3">
                      <div className="card">
                        <div className="card-body">
                          <div className="stat-widget-five">
                            <div className="text-center">
                              {this.state.dashboard_data.total_quotes}
                              <br />
                              Total Quotes
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 my-3">
                      <div className="card">
                        <div className="card-body">
                          <div className="stat-widget-five">
                            <div className="text-center">
                              {this.state.dashboard_data.total_orders}
                              <br />
                              Total Orders
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 my-3">
                      <div className="card">
                        <div className="card-body">
                          <div className="stat-widget-five">
                            <div className="text-center">
                              {this.state.dashboard_data.pending_quotes}
                              <br />
                              Pending Quotes
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 my-3">
                      <div className="card">
                        <div className="card-body">
                          <div className="stat-widget-five">
                            <div className="text-center">
                              {this.state.dashboard_data.pending_configurations}
                              <br />
                              Pending Configurations
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
              {this.props.is_support &&
              this.state.dashboard_data &&
              this.state.dashboard_data !== [] ? (
                <>
                  <div className="row mt-5 mb-5">
                    <div className="col-sm-6 mt-5 mb-5">
                      <h5>Customer Data</h5>
                      <table
                        style={{
                          tableLayout: "auto"
                        }}
                        className="table table-striped"
                      >
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">User Type</th>
                            <th scope="col">Count</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.dashboard_data.customer_data.map(
                            (data, i) => (
                              <tr>
                                <th scope="row">{i + 1}</th>
                                <td>{data.name}</td>
                                <td>{data.value}</td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="col-sm-6 mt-5 mb-5">
                      <h5>Sales Personnel Data</h5>
                      <VerticalBarchart
                        data={this.state.dashboard_data.sales_details}
                      />
                    </div>
                    <div className="col-sm-12 mt-5 mb-5">
                      <h5>Machine Category Used for Configuration</h5>
                      <Barchart
                        data={this.state.dashboard_data.machine_category_values}
                      />
                    </div>
                    {
                      <div className="col-sm-12 mt-5 mb-5">
                        <h5>Machine Category Data</h5>
                        <table
                          style={{
                            tableLayout: "auto"
                          }}
                          className="table table-striped"
                        >
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Count</th>
                              <th scope="col">Machine Cateogry</th>
                              <th scope="col">Average Price</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.dashboard_data.machie_category_with_average_price.map(
                              (data, i) => (
                                <tr>
                                  <th scope="row">{i + 1}</th>
                                  <td>{data.value}</td>
                                  <td>{data.name}</td>
                                  <td>
                                    {String.fromCharCode(strings.currency)}{" "}
                                    {parseFloat(data.average_price).toFixed(2)}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    }
                  </div>
                </>
              ) : null}
            </div>
            <div className="row mt-5 mb-5">
              <div className="col-sm-6">
                <h3 className="m-3">Pending Configuration (User)</h3>
                <select
                  className="custom-select"
                  onChange={e => this.handlePendingUserChange(e.target.value)}
                  value={this.state.selected_pending_user}
                >
                  <option value="">Filter by User</option>
                  {!this.state.users
                    ? null
                    : this.state.users.map((user, i) =>
                        user.role_name.toLowerCase().includes("admin") ||
                        user.role_name
                          .toLowerCase()
                          .includes("support") ? null : (
                          <option key={i} value={user.id}>{user.name}</option>
                        )
                      )}
                </select>
                {!this.state.pendingConfigurationsFitlerByUser ? (
                  <strong>
                    No pending configuration for the selected user.
                  </strong>
                ) : (
                  <>
                    {!this.state.pendingConfigurationsFitlerByUser ||
                    this.state.pendingConfigurationsFitlerByUser === [] ? (
                      <strong>No data available</strong>
                    ) : (
                      <>
                        <VerticalBarchart
                          data={this.state.pendingConfigurationsFitlerByUser}
                        />
                      </>
                    )}
                  </>
                )}
              </div>
              <div className="col-sm-6">
                <h3 className="m-3">
                  Pending Configuration (Machine Category)
                </h3>
                <select
                  className="custom-select"
                  onChange={e =>
                    this.handlePendingMachineCategoryChange(e.target.value)
                  }
                  value={this.state.selected_pending_category}
                >
                  <option value="">Filter by Machine Category</option>
                  {!this.state.machineCategories
                    ? null
                    : this.state.machineCategories.map((category, i) =>
                        category.category_level === 3 ? (
                          <option key={i} value={category.id}>
                            {category.machine_category}
                          </option>
                        ) : null
                      )}
                </select>
                {!this.state.pendingConfigurationsFilterByMachineCategory ? (
                  <strong>
                    No pending configuration for the selected category.
                  </strong>
                ) : (
                  <>
                    {!this.state.pendingConfigurationsFilterByMachineCategory ||
                    this.state.pendingConfigurationsFilterByMachineCategory ===
                      [] ? (
                      <strong>No data available</strong>
                    ) : (
                      <>
                        <VerticalBarchart
                          data={
                            this.state
                              .pendingConfigurationsFilterByMachineCategory
                          }
                        />
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="row mt-5 mb-5">
              <div className="col-sm-6">
                <h3 className="m-3">Total Configuration (User)</h3>
                <select
                  className="custom-select"
                  onChange={e => this.handleTotalUserChange(e.target.value)}
                  value={this.state.selected_total_user}
                >
                  <option value="">Filter by User</option>
                  {!this.state.users
                    ? null
                    : this.state.users.map((user, i) =>
                        user.role_name.toLowerCase().includes("admin") ||
                        user.role_name
                          .toLowerCase()
                          .includes("support") ? null : (
                          <option key={i} value={user.id}>{user.name}</option>
                        )
                      )}
                </select>
                {!this.state.totalConfigurationsFitlerByUser ? (
                  <strong>No configuration for the selected user.</strong>
                ) : (
                  <>
                    {!this.state.totalConfigurationsFitlerByUser ||
                    this.state.totalConfigurationsFitlerByUser === [] ? (
                      <strong>No data available</strong>
                    ) : (
                      <>
                        <VerticalBarchart
                          data={this.state.totalConfigurationsFitlerByUser}
                        />
                      </>
                    )}
                  </>
                )}
              </div>
              <div className="col-sm-6">
                <h3 className="m-3">Total Configuration (Machine Category)</h3>
                <select
                  className="custom-select"
                  onChange={e =>
                    this.handleTotalMachineCategoryChange(e.target.value)
                  }
                  value={this.state.selected_total_category}
                >
                  <option value="">Filter by Machine Category</option>
                  {!this.state.machineCategories
                    ? null
                    : this.state.machineCategories.map((category, i) =>
                        category.category_level === 3 ? (
                          <option key={i} value={category.id}>
                            {category.machine_category}
                          </option>
                        ) : null
                      )}
                </select>
                {!this.state.totalConfigurationsFilterByMachineCategory ? (
                  <strong>No configuration for the selected category.</strong>
                ) : (
                  <>
                    {!this.state.totalConfigurationsFilterByMachineCategory ||
                    this.state.totalConfigurationsFilterByMachineCategory ===
                      [] ? (
                      <strong>No data available</strong>
                    ) : (
                      <>
                        <VerticalBarchart
                          data={
                            this.state
                              .totalConfigurationsFilterByMachineCategory
                          }
                        />
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
const mapStateToProps = state => {
  return {
    configurationLoading: state.clientConfigurationReducer.loading,
    quoteLoading: state.clientQuoteReducer.loading,
    orderLoading: state.clientOrderReducer.loading,
    user: state.loginReducer.user.user,
    userRole: state.loginReducer.user.role,
    user_id: state.loginReducer.user.user.id,
    configurations: state.clientConfigurationReducer.configurations,
    orders: state.clientOrderReducer.orders,
    quotes: state.clientQuoteReducer.quotes,
    language: state.languageReducer.selectedLanguage,
    token: state.loginReducer.token,
		is_support: state.loginReducer.user.is_support
  };
};

export default connect(mapStateToProps, {
  show_user_configurations,
  show_quotes,
  show_orders
})(ClientDashboard);
