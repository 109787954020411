import loginActionTypes from "./actionTypes";

function register(params) {
	return {
		type: loginActionTypes.REGISTER_REQUEST,
		loading: true,
		params: params
	};
}

function registerAdmin(params) {
	return {
		type: loginActionTypes.REGISTER_ADMIN_REQUEST,
		loading: true,
		params: params
	};
}

function login(params) {
	return {
		type: loginActionTypes.LOGIN_REQUEST,
		loading: true,
		params: params
	};
}

function getLoginInfoWithToken(params) {
	return {
		type: loginActionTypes.GET_LOGIN_INFO_WITH_TOKEN_REQUEST,
		loading: true,
		params: params
	};
}

function getUser(params) {
	return {
		type: loginActionTypes.GET_USER_REQUEST,
		loading: true,
		params: params
	};
}

function logout(params) {
	return {
		type: loginActionTypes.LOGOUT_REQUEST,
		loading: true,
		params: params
	};
}

function clean_login_message(params) {
	return {
		type: loginActionTypes.CLEAN_LOGIN_MESSAGE_REQUEST,
		loading: true,
		params: params
	};
}

export {
	register,
	registerAdmin,
	login,
	logout,
	getUser,
	clean_login_message,
	getLoginInfoWithToken
};
