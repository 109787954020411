import { Api } from "./../../config/api";

function saveConfiguration(params) {
	let data = Api.post("configuration/saveUnfinishedConfiguration", params)
		.then(function(response) {
			return response.data;
		})
		.catch(function(error) {
			return false;
		});
	if (data) {
		return data;
	} else {
		return false;
	}
}

function createConfiguration(params) {
	let data = false;

	if (params.is_library) {
		data = Api.post("configuration/libraryConfiguration", params)
			.then(function(response) {
				return response.data;
			})
			.catch(function(error) {
				return false;
			});
	} else {
		data = Api.post("configuration", params)
			.then(function(response) {
				return response.data;
			})
			.catch(function(error) {
				return false;
			});
	}

	if (data) {
		return data;
	} else {
		return false;
	}
}

function editLibraryCofiguration(id, params) {
	let data = Api.post("configuration/edit/libraryConfiguration/" + id, params)
		.then(function(response) {
			return response.data;
		})
		.catch(function(error) {
			return false;
		});
	if (data) {
		return data;
	} else {
		return false;
	}
}

function editCofiguration(id, params) {
	let data = Api.post("configuration/edit/" + id, params)
		.then(function(response) {
			return response.data;
		})
		.catch(function(error) {
			return false;
		});
	if (data) {
		return data;
	} else {
		return false;
	}
}


function editedApprovedConfiguration(id, params) {
	let data = Api.post("configuration/editedApprovedConfiguration/" + id, params)
		.then(function(response) {
			return response.data;
		})
		.catch(function(error) {
			return false;
		});
	if (data) {
		return data;
	} else {
		return false;
	}
}

function editCofigurationSalessupport(user, id, params) {
	let data = Api.post(
		"sales-support/editConfigurationSalesSupport/" + user + "/" + id,
		params
	)
		.then(function(response) {
			return response.data;
		})
		.catch(function(error) {
			return false;
		});
	if (data) {
		return data;
	} else {
		return false;
	}
}

function showCompatibilityForCategory(params, country_id = null) {
	let data = false;
	if (country_id === null) {
		data = Api.get(
			"compatibility/getCompatibleMachinePartsWithQuantity/" + params
		)
			.then(function(response) {
				return response.data;
			})
			.catch(function(error) {
				return false;
			});
	} else {
		data = Api.get(
			"compatibility/getCompatibleMachinePartsWithQuantity/" +
				params +
				"/" +
				country_id
		)
			.then(function(response) {
				return response.data;
			})
			.catch(function(error) {
				return false;
			});
	}
	if (data) {
		return data;
	} else {
		return false;
	}
}

function showLibraryConfigurations(user) {
	let data = Api.get("configuration/library/user/" + user)
		.then(function(response) {
			return response.data;
		})
		.catch(function(error) {
			return false;
		});
	if (data) {
		return data;
	} else {
		return false;
	}
}

function showUserConfigurations(user) {
	let data = Api.get("configuration/user/" + user)
		.then(function(response) {
			return response.data;
		})
		.catch(function(error) {
			return false;
		});
	if (data) {
		return data;
	} else {
		return false;
	}
}

function showUserConfigurationsSalesSupport(user) {
	let data = Api.get("sales-support/getAllConfigurationsSalesSupport/" + user)
		.then(function(response) {
			return response.data;
		})
		.catch(function(error) {
			return false;
		});
	if (data) {
		return data;
	} else {
		return false;
	}
}

function showUserConfigurationsForMultiselect(user) {
	let data = Api.get(
		"configuration/getUserConfigurationsForMultiselect/" + user
	)
		.then(function(response) {
			return response.data;
		})
		.catch(function(error) {
			return false;
		});

	if (data) {
		return data;
	} else {
		return false;
	}
}

function showSingleLibraryConfiguration(params) {
	let data = Api.get("configuration/library/user/single/" + params)
		.then(function(response) {
			return response.data;
		})
		.catch(function(error) {
			return false;
		});
	if (data) {
		return data;
	} else {
		return false;
	}
}

function showSingleLibraryConfigurationForEdit(params) {
	let data = Api.get("configuration/library/user/edit/" + params)
		.then(function(response) {
			return response.data;
		})
		.catch(function(error) {
			return false;
		});
	if (data) {
		return data;
	} else {
		return false;
	}
}

function showSingleUserConfiguration(user, params) {
	let data = Api.get("configuration/user/" + user + "/" + params)
		.then(function(response) {
			return response.data;
		})
		.catch(function(error) {
			return false;
		});
	if (data) {
		return data;
	} else {
		return false;
	}
}

function showSingleUserConfigurationForEdit(user, params) {
	let data = Api.get("configuration/user/edit/" + user + "/" + params)
		.then(function(response) {
			return response.data;
		})
		.catch(function(error) {
			return false;
		});
	if (data) {
		return data;
	} else {
		return false;
	}
}

function showSingleUserConfigurationSalesSupport(user, params) {
	let data = Api.get(
		"sales-support/getSingleConfigurationSalesSupport/" + user + "/" + params
	)
		.then(function(response) {
			return response.data;
		})
		.catch(function(error) {
			return false;
		});
	if (data) {
		return data;
	} else {
		return false;
	}
}

function showSingleUserConfigurationSalesSupportForEdit(user, params) {
	let data = Api.get(
		"sales-support/getSingleConfigurationSalesSupport/edit/" +
			user +
			"/" +
			params
	)
		.then(function(response) {
			return response.data;
		})
		.catch(function(error) {
			return false;
		});

	if (data) {
		return data;
	} else {
		return false;
	}
}

function getTreeCategory(role_id, country_id) {
	let data = Api.get(
		"machine-category/tree-category/" + role_id + "/" + country_id
	)
		.then(function(response) {
			return response.data;
		})
		.catch(function(error) {});
	if (data) {
		return data;
	} else {
		return false;
	}
}

function getCategoryDetails(params) {
	let data = Api.get(
		"machine-category/detailsWithSpecificationAndFeatures/" + params
	)
		.then(function(response) {
			return response.data;
		})
		.catch(function(error) {
			return false;
		});

	if (data) {
		return data;
	} else {
		return false;
	}
}

function showChildrenConfigurations(user) {
	let data = Api.get("user-hierarchy/showChildrenConfigurations/" + user)
		.then(function(response) {
			return response.data;
		})
		.catch(function(error) {
			return false;
		});

	if (data) {
		return data;
	} else {
		return false;
	}
}

function showSingleChildrenConfiguration(configuration_id) {
	let data = Api.get(
		"user-hierarchy/detailsWithSpecificationAndFeatures/" + configuration_id
	)
		.then(function(response) {
			return response.data;
		})
		.catch(function(error) {
			return false;
		});

	if (data) {
		return data;
	} else {
		return false;
	}
}

function getParentLibraryConfiguration(user) {
	let data = Api.get("configuration/getParentLibraryConfiguration/" + user)
		.then(function(response) {
			return response.data;
		})
		.catch(function(error) {
			return false;
		});
	if (data) {
		return data;
	} else {
		return false;
	}
}

export const ClientConfigurationService = {
	showLibraryConfigurations,
	showSingleLibraryConfiguration,
	showSingleLibraryConfigurationForEdit,
	showCompatibilityForCategory,
	saveConfiguration,
	showSingleUserConfigurationSalesSupport,
	createConfiguration,
	showUserConfigurationsSalesSupport,
	editCofiguration,
	editCofigurationSalessupport,
	showUserConfigurations,
	showUserConfigurationsForMultiselect,
	showSingleUserConfiguration,
	getTreeCategory,
	getCategoryDetails,
	showSingleUserConfigurationSalesSupportForEdit,
	showSingleUserConfigurationForEdit,
	editLibraryCofiguration,
	showChildrenConfigurations,
	showSingleChildrenConfiguration,
	getParentLibraryConfiguration,
	editedApprovedConfiguration
};
