import { Api } from "./../../config/api";

// api call to show all brands
function showAllPolicyDocuments() {
	let data = Api.get("policy-documents").then(function(response) {
		return response.data;
	});

	if (data) {
		return data;
	} else {
		return false;
	}
}

//api call to show a single brand
function showSinglePolicyDocument(param) {
	let data = Api.get("policy-documents/" + param).then(function(response) {
		return response.data;
	});

	if (data) {
		return data;
	} else {
		return false;
	}
}

//api call to add a brand into the system
function createPolicyDocument(params) {
	let data = Api.post("policy-documents", params).then(function(response) {
		return response.data;
	});

	if (data) {
		return data;
	} else {
		return false;
	}
}

//api call to edit brand exisitng in the system
function updatePolicyDocument(params) {
	let data = Api.post(
		"policy-documents/edit/" + params.get("id"),
		params
	).then(function(response) {
		return response.data;
	});

	if (data) {
		return data;
	} else {
		return false;
	}
}

//api call to delete exisitng brand from the system
function deletePolicyDocument(params) {
	let data = Api.get("policy-documents/delete/" + params).then(function(
		response
	) {
		return response.data;
	});

	if (data) {
		return data;
	} else {
		return false;
	}
}

function getCurrentPolicyDocumentsForRole(params) {
	let data = Api.get(
		"policy-documents/getCurrentPolicyDocumentsForRole/" + params
	).then(function(response) {
		return response.data;
	});

	if (data) {
		return data;
	} else {
		return false;
	}
}

function addOrUpdatePolicyDocumentWithRole(id, params) {
	let data = Api.post(
		"policy-documents/addOrUpdatePPolicyDocumentWithRole/" + id,
		params
	).then(function(response) {
		return response.data;
	});

	if (data) {
		return data;
	} else {
		return false;
	}
}

function getAllPoliciesAccoringtoRole(id) {
	let data = Api.get(
		"policy-documents/getAllPoliciesAccoringtoRole/" + id
	).then(function(response) {
		return response.data;
	});

	if (data) {
		return data;
	} else {
		return false;
	}
}

export const PolicyDocumentService = {
	showAllPolicyDocuments,
	showSinglePolicyDocument,
	createPolicyDocument,
	updatePolicyDocument,
	deletePolicyDocument,
	getCurrentPolicyDocumentsForRole,
	addOrUpdatePolicyDocumentWithRole,
	getAllPoliciesAccoringtoRole
};
