import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { css } from "@emotion/react";
import { PulseLoader } from "react-spinners";
import strings from "./../../../config/language";
import { getCostPriceVisibilityForRole } from "./../../../admin/role/action";
import { Api, setToken } from "./../../../config/api";
import { Divider, Modal } from 'antd';

class Home extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activeIndex: 0,
			firstFormOn: true,
			secondFormOn: false,
			thirdFormOn: false,
			dashboard_data: false,
			visible: false,
			updates: false,
		};
	}

	showModal = () => {
		this.setState({
			visible: true
		});
	};

	handleOk = (e, id) => {
		Api.get('update/toggleUpdateForSingleUser/' + this.props.user_id).then(function(response){
			// alert('hello')
		})
		this.setState({
			visible: false
		});
	};

	handleCancel = e => {
		this.setState({
			visible: false
		});
	};

	async componentDidMount() {
		let that = this;
		if (this.props.is_updated) {
			that.setState({ visible: true })
			await Api.get('update/showvisible/' + this.props.user_id).then(function (response) {
				that.setState({ updates: response.data })
			})
		}

		await setToken(this.props.token);
		// await this.props.getCostPriceVisibilityForRole(this.props.role_id);
	}

	render() {
		strings.setLanguage(this.props.language);
		const override = css`
			display: block;
			margin: 0 auto;
			border-color: red;
		`;
		if (this.props.loginLoading) {
			return (
				<div className="sweet-loading">
					<div className="container loading-container">
						<div className="row m-5 p-5">
							<PulseLoader
								css={override}
								sizeUnit={"px"}
								size={24}
								color={"#B9373D"}
							/>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div className="my-4 client-roboto-condensed container">

					<Modal
						title="Updates in the CPQ"
						visible={this.state.visible}
						onOk={this.handleOk}
						onCancel={this.handleCancel}
					>
						<div className="container">
							<div className="row">
								<div className="col">

									<div className="card-body">
										<div className="card-content">
											<ul>
												{this.state.updates ?
													this.state.updates.map((update, i) =>
														<div className="my-4">
															<div className="update-title">
																{update.update_title}
															</div>
															<div 
																className="update-description"
																dangerouslySetInnerHTML={{
																	__html: update.update_description
																		? update.update_description
																		: ""
																}}
															>
															</div>
															<div className="update-date">
																{update.updated_date}
															</div>
															<Divider />
														</div>
													) : null}
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</Modal>
					<div className="row">
						<div className="col"></div>
						<div className="col-12">
							<div className="text-center">
								<div className="content-section introduction">
									<div className="feature-intro">
										<h5>Hello {this.props.name},</h5>
										<p
											style={{
												fontSize: 13
											}}
										>
											Welcome to the Reesink UK CPQ. With this
											application you can create configurations and
											quotes for new machines. These machines can
											also be ordered. Under the button policy
											documents you find important documents.
										</p>
									</div>
								</div>
							</div>
						</div>
						<div className="col"></div>
					</div>
					<div className="row m-5 align-items-center justify-content-center">
						<div className="col m-2">
							<Link
								to="/bulletin-documents"
								className="btn btn-block client-login"
							>
								Bulletin
							</Link>
						</div>
						<div className="col m-2">
							<Link
								to="/user-policy-documents"
								className="btn btn-block client-login"
							>
								Policy Documents
							</Link>
						</div>
						<div className="col m-2">
							{this.props.is_manager ?
								<Link
									to="/team-configurations"
									className="btn btn-block client-login"
								>
									Team Configurations
							</Link> :
								<Link
									to="/configurations"
									className="btn btn-block client-login"
								>
									All Configurations
							</Link>
							}
						</div>
						<div className="col m-2">
							{
								this.props.is_manager ?
									<Link to="/team-quotes" className="btn btn-block client-login">
										Team Quotes
								</Link>
									:

									<Link to="/quotes" className="btn btn-block client-login">
										All Quotes
								</Link>

							}
						</div>
						<div className="col m-2">
							{
								this.props.is_manager ?
									<Link to="/team-orders" className="btn btn-block client-login">
										Team Orders
									</Link>
									:
									<Link to="/orders" className="btn btn-block client-login">
										All Orders
									</Link>
							}
						</div>
					</div>
				</div>
			);
		}
	}
}
const mapStateToProps = state => {
	return {
		isAuthenticated: state.loginReducer.isAuthenticated,
		userRole: state.loginReducer.user.role,
		name: state.loginReducer.user.user.name,
		loginLoading: state.loginReducer.loading,
		is_updated: state.loginReducer.user.user.is_updated,
		user_id: state.loginReducer.user.user.id,
		role_id: state.loginReducer.user.role_id,
		token: state.loginReducer.token,
		language: state.languageReducer.selectedLanguage,
		is_manager: state.loginReducer.user.is_manager
	};
};

export default connect(mapStateToProps, { getCostPriceVisibilityForRole })(
	Home
);
