import React, { Component } from "react";
import { connect } from "react-redux";
import { Api } from "./../../config/api";
import { css } from "@emotion/react";
import { logout } from "./../action";
import { PulseLoader } from "react-spinners";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Swal from "sweetalert2";

class VerificationPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  async onSubmit(e) {
    e.preventDefault();
    this.setState({ loading: true });
    Api.defaults.headers.common["Authorization"] = this.props.token;
    await Api.get("/email/resend")
      .then(function(response) {
        Swal.fire({
          title: "Success!",
          text: "Verification e-mail has been sent! Please check!!",
          icon: "success",
          confirmButtonText: "OK"
        });
      })
      .catch(function(error) {
        Swal.fire({
          title: "Email Send Error!",
          text:
            "Verification e-mail has not beend sent! Something is wrong with the provided email!!",
          icon: "error",
          confirmButtonText: "OK"
        });
      });

    await this.props.logout(this.props.token);
  }

  async componentDidMount() {
    let { token } = this.props.match.params;
    this.setState({ token: token });

    await this.setState({ loading: false });
  }

  render() {
    const override = css`
      display: block;
      margin: 0 auto;
      border-color: red;
    `;
    if (this.props.loading) {
      return (
        <div className="sweet-loading">
          <div className="container loading-container">
            <div className="row m-5 p-5">
              <PulseLoader
                css={override}
                sizeUnit={"px"}
                size={24}
                color={"#B9373D"}
              />
            </div>
          </div>
        </div>
      );
    } else {
      if (this.state.loading) {
        return (
          <div className="sweet-loading">
            <div className="container loading-container">
              <div className="row m-5 p-5">
                <PulseLoader
                  css={override}
                  sizeUnit={"px"}
                  size={24}
                  color={"#B9373D"}
                />
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div>
            <div className="mt-4 mb-4 roboto-condensed container">
              <div className="row">
                <div className="col"></div>
                <div className="col-8">
                  <div className="text-center">
                    <div className="content-section introduction">
                      <div className="feature-intro">
                        <h3>Verification | Reesink UK CPQ</h3>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col"></div>
                      <div className="col-6">
                        <form>
                          <div className="form-group">
                            <h5>Your email has not been verified</h5>
                          </div>
                          {/* <div>
                            <button
                              type="submit"
                              className="roboto-condensed client-login mr-2 ml-2"
                            >
                              Send verification Email
                            </button>
                          </div> */}
                        </form>
                      </div>
                      <div className="col"></div>
                    </div>
                  </div>
                </div>
                <div className="col"></div>
              </div>
            </div>
          </div>
        );
      }
    }
  }
}
const mapStateToProps = state => {
  return {
    token: state.loginReducer.token
  };
};

export default connect(mapStateToProps, {
  logout
})(VerificationPage);
