import React, { Component } from "react";
import { connect } from "react-redux";
import { show_single_order } from "./../action";
import { show_single_user_configuration } from "./../../configuration/action";
import { Api } from "./../../../config/api";
import { css } from "@emotion/react";
import noimage from "./../../../CPQ/noimage.png";
import { PulseLoader } from "react-spinners";
import strings from "./../../../config/language";
import config from "./../../../config/config";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Link } from "react-router-dom";
import getSymbolFromCurrency from "currency-symbol-map";
import { Tabs, Modal, Descriptions, Table, notification } from "antd";
import CurrencyFormat from "react-currency-format";
import { Redirect } from "react-router-dom";
import { ClientOrderService } from "./../service";

const { TabPane } = Tabs;

class MyDetailedOrder extends Component {
	constructor(props) {
		super(props);
		this.state = {
			delete: false,
			smart_log_configuration_data: false,
			log_configuration_modal_visible: false,
			log_configuration_data: false,
			projectOverviewModal: false,
		};
		this.showModal = this.showModal.bind(this);
		this.handleCancelOrder = this.handleCancelOrder.bind(this);
		this.handleReOrder = this.handleReOrder.bind(this);
		this.loadLogsForConfiguration = this.loadLogsForConfiguration.bind(this);
		this.deleteOrder = this.deleteOrder.bind(this);
		this.downloadXlsx = this.downloadXlsx.bind(this);
		this.downloadDocx = this.downloadDocx.bind(this);
	}

	async downloadXlsx(value) {
		if(value === null){
			return
		}
        if(value.split("/")[0].toLowerCase() === "Xlsx".toLowerCase()){
			window.open(await ClientOrderService.fetchDocumentS3("documents/" + value));
        }else{
			window.open(config.APP_URL + '/api/storage/' + value + '?date=' + new Date().getMilliseconds());
        }
    }
	async downloadDocx(value) {
		if(value === null){
			return
		}
        if(value.split("/")[0].toLowerCase() === "Docx".toLowerCase()){
			window.open(await ClientOrderService.fetchDocumentS3("documents/" + value));
        }else{
			window.open(config.APP_URL + '/api/storage/' + value + '?date=' + new Date().getMilliseconds());
        }
    }


	logModalOk = (e, id) => {
		this.setState({
			log_configuration_modal_visible: false
		});
	};

	logModalCancel = e => {
		this.setState({
			log_configuration_modal_visible: false
		});
	};


	async loadLogsForConfiguration(configuration_id) {
		await this.showModal(configuration_id)
		let that = this;

		await Api.get(
			"configuration/getLogsForConfiguration/" + configuration_id
		).then(function (response) {
			that.setState({ log_configuration_data: response.data });
		});

		await Api.get('configuration/getChangedLogForConfiguration/' + configuration_id).then(function (response) {
			that.setState({ smart_log_configuration_data: response.data })
		})

		await that.setState({ log_configuration_modal_visible: true });
	}

	async deleteOrder(id) {
		let that = this;
		let confirmation = window.confirm("Are you sure you want to delete this order?");
		if (confirmation) {
			await Api.get("/order/deleteOrderFormData/" + id)
				.then(function (response) {
					if (
						response.data.message
							.toLowerCase()
							.includes("cannot be deleted")
					) {
						notification.success({
							message: "Success!",
							description: response.data.message
						});
					} else {
						that.setState({ delete: true });
					}
				})
				.catch(function (error) { });
		}
	}

	columns = [
		{
			title: "Configuration Name",
			dataIndex: "name",
			key: "name"
		},
		{
			title: "Quantity",
			dataIndex: "quantity",
			key: "quantity"
		},
		{
			title: "Actions",
			dataIndex: "id",
			key: "id",
			render: id => (
				<>
					<button
						href="#"
						style={{
							background: "none",
							color: "inherit",
							border: "none",
							padding: 0,
							font: "inherit",
							borderBottom: 1,
							cursor: "pointer"
						}}
						onClick={() => this.showModal(id)}
						className="red-link mr-2"
						data-toggle="modal"
						data-target="#exampleModal"
					>
						See Configuration
					</button>
					<br />
					{!this.props.order.order[0].status
						.toLowerCase()
						.includes("submitted order") ? (
						<Link
							className="red-link"
							to={"/edit-configuration-process/" + id}
						>
							Edit Configuration
						</Link>
					) : null}
					<br />
					{this.props.log_visibility ? (
						<button
							href="#"
							style={{
								background: "none",
								color: "inherit",
								border: "none",
								padding: 0,
								font: "inherit",
								borderBottom: 1,
								cursor: "pointer"
							}}
							className="red-link"
							onClick={() => this.loadLogsForConfiguration(id)}
						>
							Configuration History
						</button>
					) : null}
				</>
			)
		}
	];

	async handleCancelOrder() {
		let that = this;
		let user_id = that.props.user_id;
		let order_id = that.props.match.params.id;

		if (this.props.is_support) {
			await Api.get(
				config.APP_URL +
				"/api/sales-support/cancelOrderSalesSupport/" +
				user_id +
				"/" +
				order_id
			).then(async function (response) {
				await that.props.show_single_order(
					user_id,
					order_id,
					that.props.role
				);
			});
		} else {
			await Api.get(
				config.APP_URL + "/api/order/cancel/" + order_id + "/" + user_id
			).then(async function (response) {
				await that.props.show_single_order(
					user_id,
					order_id,
					that.props.role
				);
			});
		}
	}

	async handleReOrder() {
		let that = this;
		let user_id = that.props.user_id;
		let order_id = that.props.match.params.id;

		if (this.props.is_support) {
			await Api.get(
				config.APP_URL +
				"/api/sales-support/reorderOrderSalesSupport/" +
				user_id +
				"/" +
				order_id
			).then(async function (response) {
				await that.props.show_single_order(
					user_id,
					order_id,
					that.props.role
				);
			});
		} else {
			await Api.get(
				config.APP_URL + "/api/order/reorder/" + order_id + "/" + user_id
			).then(async function (response) {
				await that.props.show_single_order(
					user_id,
					order_id,
					that.props.role
				);
			});
		}
	}

	async componentDidMount() {
		let { id } = this.props.match.params;

		await this.props.show_single_order(
			this.props.user_id,
			id,
			this.props.role
		);
	}

	async showModal(id) {
		let that = this;
		await that.props.show_single_user_configuration(
			that.props.user_id,
			id,
			this.props.role
		);
	}

	render() {
		strings.setLanguage(this.props.language);
		const override = css`
			display: block;
			margin: 0 auto;
			border-color: red;
		`;
		if (this.props.loginLoading || this.props.orderLoading) {
			return (
				<div className="sweet-loading">
					<div className="container loading-container">
						<div className="row m-5 p-5">
							<PulseLoader
								css={override}
								sizeUnit={"px"}
								size={24}
								color={"#B9373D"}
							/>
						</div>
					</div>
				</div>
			);
		} else {
			if (this.state.delete) {
				return <Redirect to="/orders" />;
			} else if (this.props.order) {
				return (
					<div>
						<div className="my-5 client-roboto-condensed container">
							<div className="text-center my-4">
								<h4
									className="mb-4"
									style={{
										fontWeight: 900
									}}
								>
									Order Details
								</h4>
							</div>
							<div className="row">
								<div className="col-sm-6">
									<div className="client-config-details mr-5 mb-5">
										<Descriptions
											title=""
											layout="hotizontal"
											bordered
											size="small"
											column={1}
										>
											<Descriptions.Item label="Order Name">
												{this.props.order.order[0].order_name}
											</Descriptions.Item>
											<Descriptions.Item label="Order Number">
												{this.props.order.order[0].id}
											</Descriptions.Item>
											<Descriptions.Item label="Quote Name">
												{this.props.order.order[0].quotation_name}
											</Descriptions.Item>
											<Descriptions.Item label="Status">
												{this.props.order.order[0].status}
											</Descriptions.Item>
											{this.props.is_support ? (
												<Descriptions.Item label="Created By">
													{this.props.order.order[0].name}
												</Descriptions.Item>
											) : null}
										</Descriptions>
									</div>
								</div>
								<div className="col-sm-4">
									<div className="float-left text-center mt-2 mb-2 col-sm-12">
											<button
											className="btn-block client-login"
											onClick={()=>this.downloadDocx(this.props.order.order[0].quotation_file_doc)}
										>
											Download Document
										</button>
									</div>
									<div className="float-left text-center mt-2 mb-2 col-sm-12">
										<button
											className="btn-block client-login"
											onClick={()=>this.downloadXlsx(this.props.order.order[0].quotation_file_xls)}
										>
											Download Excel Sheet
										</button>
									</div>

									{this.props.order_form_visibility ? (
										<div
											className="float-left text-center mt-2 mb-2 col-sm-12"
											width="100%"
										>
											{this.props.order.order[0].customer_id ===
												null ? (
												<Link
													className="btn-block client-login"
													to={
														"/order-form/" +
														this.props.match.params.id
													}
												>
													Order Form
												</Link>
											) : (
												<Link
													className="btn-block client-login"
													to={
														"/order-form/" +
														this.props.match.params.id
													}
												>
													Update Order Form
												</Link>
											)}
										</div>
									) : null}
									<>
										{this.props.order.order[0].status
											.toLowerCase()
											.includes("order") ? (
											this.props.cancel_order ? (
												<div
													className="float-left text-center mt-2 mb-2 col-sm-12"
													width="100%"
												>
													<button
														onClick={this.handleCancelOrder}
														className="btn-block client-login"
													>
														Cancel Order
													</button>
												</div>
											) : null
										) : null}
										{!this.props.order.order[0].status
											.toLowerCase()
											.includes("order") ? (
											<div
												className="float-left text-center mt-2 mb-2 col-sm-12"
												width="100%"
											>
												<button
													onClick={this.handleReOrder}
													className="btn-block client-login"
												>
													Re-Order
												</button>
											</div>
										) : null}
									</>
									<div
										className="float-left text-center mt-2 mb-2 col-sm-12"
										width="100%"
									>
										<button
											onClick={() =>
												this.deleteOrder(
													this.props.order.order[0].order_id
												)
											}
											className="btn-block client-login"
										>
											Delete Order
										</button>
									</div>
								</div>
							</div>

							<div className="row">
								<div className="col-sm-10 my-5">
									<h6
										className="text-center my-3 client-roboto-condensed"
										style={{
											fontWeight: 900
										}}
									>
										Configurations in this Order
									</h6>
									{
										this.props.order ?
											<Table
												bordered={true}
												pagination={false}
												columns={this.columns}
												dataSource={this.props.order.configurations}
											/> : null
									}
								</div>
							</div>
						</div>
						{
							<div className="container">
								<div
									className="modal fade"
									id="exampleModal"
									tabIndex="-1"
									role="dialog"
									aria-labelledby="exampleModalLabel"
									aria-hidden="true"
								>
									<div className="modal-dialog" role="document">
										<div className="modal-content">
											<div className="modal-header">
												<h6
													className="modal-title"
													id="exampleModalLabel"
												>
													Configuration Details
												</h6>
												<button
													type="button"
													className="close"
													data-dismiss="modal"
													aria-label="Close"
												>
													<span aria-hidden="true">&times;</span>
												</button>
											</div>
											{this.props.configuration && !this.props.configurationLoading ? (
												<div className="modal-body">
													<div className="row m-5">
														<div className="col-sm-5">
															<div className="">
																{this.props.configuration[0]
																	.machine_category_image !==
																	[] &&
																	this.props.configuration[0]
																		.machine_category_image !==
																	null ? (
																	<img loading="lazy"
																		width="70%"
																		height="70%"
																		className="img-responsive"
																		alt=""
																		src={
																			config.APP_URL +
																			"/api/storage/" +
																			this.props
																				.configuration[0]
																				.machine_category_image
																		}
																	/>
																) : (
																	<img loading="lazy"
																		width="70%"
																		height="70%"
																		className="img-responsive"
																		alt=""
																		src={noimage}
																	/>
																)}
															</div>
														</div>
														<div className="col-sm-6">
															<div className="client-config-details mr-5 mb-5">
																<Descriptions
																	title=""
																	layout="hotizontal"
																	bordered
																	size="small"
																	column={1}
																>
																	<Descriptions.Item label="Configuration Name">
																		{this.props
																			.configuration !== []
																			? this.props
																				.configuration[0]
																				.name
																			: ""}
																	</Descriptions.Item>
																	<Descriptions.Item label="Product Category">
																		{this.props
																			.configuration !== []
																			? this.props
																				.configuration[0]
																				.machine_category
																			: ""}
																	</Descriptions.Item>
																	<Descriptions.Item label="Retail Price">
																		{this.props
																			.configuration !== []
																			? parseFloat(
																				this.props
																					.configuration[0]
																					.machine_total_price /
																				this.props
																					.configuration[0]
																					.quantity
																			).toFixed(2)
																			: ""}{" "}
																		{getSymbolFromCurrency(
																			this.props.country[0]
																				.currency_symbol_html
																		)}
																	</Descriptions.Item>
																	{this.props.configuration !==
																		[] &&
																		this.props.configuration[0]
																			.discount_percentage !==
																		0 ? (
																		this.props.is_dealer ? (
																			<Descriptions.Item label="Standard Dealer Terms %">
																				{
																					this.props
																						.configuration[0]
																						.discount_percentage
																				}{" "}
																				%
																			</Descriptions.Item>
																		) : (
																			<>
																				<Descriptions.Item label="Discount Percentage">
																					{
																						this.props
																							.configuration[0]
																							.discount_percentage
																					}{" "}
																					%
																				</Descriptions.Item>
																				<Descriptions.Item label="Discount Percentage Amount">
																					<CurrencyFormat
																						className="py-1 pr-2 text-right"
																						displayType={
																							"text"
																						}
																						decimalSeparator={
																							"."
																						}
																						decimalScale={
																							2
																						}
																						fixedDecimalScale={
																							true
																						}
																						value={parseFloat(
																							((this
																								.props
																								.configuration[0]
																								.machine_total_price /
																								this
																									.props
																									.configuration[0]
																									.quantity) *
																								this
																									.props
																									.configuration[0]
																									.discount_percentage) /
																							100
																						).toFixed(2)}
																						thousandSeparator={
																							true
																						}
																						suffix={
																							" " +
																							getSymbolFromCurrency(
																								this
																									.props
																									.configuration[0]
																									.currency_symbol_html
																							)
																						}
																					/>
																				</Descriptions.Item>
																			</>
																		)
																	) : null}
																	{this.props.configuration !==
																		[] &&
																		this.props.configuration[0]
																			.discount_amount !== 0 ? (
																		this.props.is_dealer ? (
																			<Descriptions.Item label="Dealer Discount Amount">
																				{
																					this.props
																						.configuration[0]
																						.discount_amount
																				}{" "}
																				{getSymbolFromCurrency(
																					this.props
																						.configuration[0]
																						.currency_symbol_html
																				)}
																			</Descriptions.Item>
																		) : (
																			<Descriptions.Item label="Discount Amount">
																				{
																					this.props
																						.configuration[0]
																						.discount_amount
																				}{" "}
																				{getSymbolFromCurrency(
																					this.props
																						.configuration[0]
																						.currency_symbol_html
																				)}
																			</Descriptions.Item>
																		)
																	) : null}

																	{this.props.configuration !==
																		[] &&
																		this.props.configuration[0]
																			.dealer_discount_amount !==
																		0 ? (
																		this.props
																			.extra_discount_visible ? (
																			<Descriptions.Item label="Extra Discount">
																				{
																					this.props
																						.configuration[0]
																						.dealer_discount_amount
																				}{" "}
																				{getSymbolFromCurrency(
																					this.props
																						.configuration[0]
																						.currency_symbol_html
																				)}
																			</Descriptions.Item>
																		) : null
																	) : null}
																	{this.props.is_dealer ? (
																		<Descriptions.Item label="Dealer Net Total">
																			{this.props
																				.configuration !==
																				[] ? (
																				<CurrencyFormat
																					className="py-1 pr-2 text-right"
																					displayType={
																						"text"
																					}
																					decimalSeparator={
																						"."
																					}
																					decimalScale={2}
																					fixedDecimalScale={
																						true
																					}
																					value={parseFloat(
																						this.props
																							.configuration[0]
																							.machine_total_price /
																						this.props
																							.configuration[0]
																							.quantity -
																						(this.props
																							.configuration[0]
																							.discount_percentage *
																							(this
																								.props
																								.configuration[0]
																								.machine_total_price /
																								this
																									.props
																									.configuration[0]
																									.quantity)) /
																						100 -
																						this.props
																							.configuration[0]
																							.discount_amount -
																						this.props
																							.configuration[0]
																							.dealer_discount_amount
																					)}
																					thousandSeparator={
																						true
																					}
																					suffix={
																						" " +
																						getSymbolFromCurrency(
																							this.props
																								.configuration[0]
																								.currency_symbol_html
																						)
																					}
																				/>
																			) : null}
																		</Descriptions.Item>
																	) : (
																		<Descriptions.Item label="Net Total">
																			{this.props
																				.configuration !==
																				[] ? (
																				<CurrencyFormat
																					className="py-1 pr-2 text-right"
																					displayType={
																						"text"
																					}
																					decimalSeparator={
																						"."
																					}
																					decimalScale={2}
																					fixedDecimalScale={
																						true
																					}
																					value={parseFloat(
																						this.props
																							.configuration[0]
																							.machine_total_price /
																						this.props
																							.configuration[0]
																							.quantity -
																						(this.props
																							.configuration[0]
																							.discount_percentage *
																							(this
																								.props
																								.configuration[0]
																								.machine_total_price /
																								this
																									.props
																									.configuration[0]
																									.quantity)) /
																						100 -
																						this.props
																							.configuration[0]
																							.discount_amount -
																						this.props
																							.configuration[0]
																							.dealer_discount_amount
																					)}
																					thousandSeparator={
																						true
																					}
																					suffix={
																						" " +
																						getSymbolFromCurrency(
																							this.props
																								.configuration[0]
																								.currency_symbol_html
																						)
																					}
																				/>
																			) : null}
																		</Descriptions.Item>
																	)}

																	{this.props.is_dealer ? (
																		<>
																			<Descriptions.Item label="OEM Programme">
																				{
																					this.props
																						.configuration[0]
																						.oem_discount
																				}
																				{" " +
																					getSymbolFromCurrency(
																						this.props
																							.configuration[0]
																							.currency_symbol_html
																					)}
																			</Descriptions.Item>
																			<Descriptions.Item label="Net Net Purchase Price">
																				<CurrencyFormat
																					className="py-1 pr-2 text-right"
																					displayType={
																						"text"
																					}
																					decimalSeparator={
																						"."
																					}
																					decimalScale={2}
																					fixedDecimalScale={
																						true
																					}
																					value={parseFloat(
																						this.props
																							.configuration[0]
																							.machine_total_price /
																						this.props
																							.configuration[0]
																							.quantity -
																						(this.props
																							.configuration[0]
																							.discount_percentage *
																							(this
																								.props
																								.configuration[0]
																								.machine_total_price /
																								this
																									.props
																									.configuration[0]
																									.quantity)) /
																						100 -
																						this.props
																							.configuration[0]
																							.discount_amount -
																						this.props
																							.configuration[0]
																							.dealer_discount_amount -
																						this.props
																							.configuration[0]
																							.oem_discount
																					).toFixed(2)}
																					thousandSeparator={
																						true
																					}
																					suffix={
																						" " +
																						getSymbolFromCurrency(
																							this.props
																								.configuration[0]
																								.currency_symbol_html
																						)
																					}
																				/>
																			</Descriptions.Item>
																		</>
																	) : null}
																	{this.props.is_dealer ? (
																		<>
																			<Descriptions.Item label="Extra Discount">
																				{
																					this.props
																						.configuration[0]
																						.extra_discount
																				}
																				{" %"}
																			</Descriptions.Item>
																			<Descriptions.Item label="Reesink Extra Extra Support">
																				<CurrencyFormat
																					className="py-1 pr-2 text-right"
																					displayType={
																						"text"
																					}
																					decimalSeparator={
																						"."
																					}
																					decimalScale={2}
																					fixedDecimalScale={
																						true
																					}
																					value={parseFloat(
																						(this.props
																							.configuration[0]
																							.extra_discount *
																							parseFloat(
																								this
																									.props
																									.configuration[0]
																									.machine_total_price /
																								this
																									.props
																									.configuration[0]
																									.quantity -
																								(this
																									.props
																									.configuration[0]
																									.discount_percentage *
																									(this
																										.props
																										.configuration[0]
																										.machine_total_price /
																										this
																											.props
																											.configuration[0]
																											.quantity)) /
																								100 -
																								this
																									.props
																									.configuration[0]
																									.discount_amount -
																								this
																									.props
																									.configuration[0]
																									.dealer_discount_amount -
																								this
																									.props
																									.configuration[0]
																									.oem_discount
																							).toFixed(
																								2
																							)) /
																						100
																					).toFixed(2)}
																					thousandSeparator={
																						true
																					}
																					suffix={
																						" " +
																						getSymbolFromCurrency(
																							this.props
																								.configuration[0]
																								.currency_symbol_html
																						)
																					}
																				/>
																			</Descriptions.Item>
																		</>
																	) : null}
																	{this.props.is_dealer ? (
																		<Descriptions.Item label="Net Net Net Price">
																			<CurrencyFormat
																				className="py-1 pr-2 text-right"
																				displayType={"text"}
																				decimalSeparator={
																					"."
																				}
																				decimalScale={2}
																				fixedDecimalScale={
																					true
																				}
																				value={parseFloat(
																					parseFloat(
																						this.props
																							.configuration[0]
																							.machine_total_price /
																						this.props
																							.configuration[0]
																							.quantity -
																						(this.props
																							.configuration[0]
																							.discount_percentage *
																							(this
																								.props
																								.configuration[0]
																								.machine_total_price /
																								this
																									.props
																									.configuration[0]
																									.quantity)) /
																						100 -
																						this.props
																							.configuration[0]
																							.discount_amount -
																						this.props
																							.configuration[0]
																							.dealer_discount_amount -
																						this.props
																							.configuration[0]
																							.oem_discount
																					).toFixed(2) -
																					parseFloat(
																						(this.props
																							.configuration[0]
																							.extra_discount *
																							parseFloat(
																								this
																									.props
																									.configuration[0]
																									.machine_total_price /
																								this
																									.props
																									.configuration[0]
																									.quantity -
																								(this
																									.props
																									.configuration[0]
																									.discount_percentage *
																									(this
																										.props
																										.configuration[0]
																										.machine_total_price /
																										this
																											.props
																											.configuration[0]
																											.quantity)) /
																								100 -
																								this
																									.props
																									.configuration[0]
																									.discount_amount -
																								this
																									.props
																									.configuration[0]
																									.dealer_discount_amount -
																								this
																									.props
																									.configuration[0]
																									.oem_discount
																							).toFixed(
																								2
																							)) /
																						100
																					).toFixed(2)
																				).toFixed(2)}
																				thousandSeparator={
																					true
																				}
																				suffix={
																					" " +
																					getSymbolFromCurrency(
																						this.props
																							.configuration[0]
																							.currency_symbol_html
																					)
																				}
																			/>
																		</Descriptions.Item>
																	) : null
																	}
																	{this.props.configuration !==
																		[] &&
																		this.props.configuration[0]
																			.sales_margin !== 0 ? (
																		this.props
																			.sales_margin_visible ? (
																			this.props
																				.is_dealer ? (
																				<>
																					<Descriptions.Item label="End Customer Purchase Price">
																						{parseFloat(
																							this.props
																								.configuration[0]
																								.end_user_sales_price
																						).toFixed(
																							2
																						)}{" "}
																						{getSymbolFromCurrency(
																							this.props
																								.country[0]
																								.currency_symbol_html
																						)}
																					</Descriptions.Item>
																					<Descriptions.Item label="Dealer Margin Amount">
																						<CurrencyFormat
																							className="py-1 pr-2 text-right"
																							displayType={
																								"text"
																							}
																							decimalSeparator={
																								"."
																							}
																							decimalScale={
																								2
																							}
																							fixedDecimalScale={
																								true
																							}
																							value={
																								this
																									.props
																									.configuration[0]
																									.sales_margin_amount
																							}
																							thousandSeparator={
																								true
																							}
																							suffix={
																								" " +
																								getSymbolFromCurrency(
																									this
																										.props
																										.configuration[0]
																										.currency_symbol_html
																								)
																							}
																						/>
																					</Descriptions.Item>
																					<Descriptions.Item label="Dealer Margin">
																						{
																							this.props
																								.configuration[0]
																								.sales_margin
																						}
																						{" %"}
																					</Descriptions.Item>
																				</>
																			) : (
																				<>
																					<Descriptions.Item label="Sales Margin">
																						{
																							this.props
																								.configuration[0]
																								.sales_margin
																						}
																						{" %"}
																					</Descriptions.Item>
																					<Descriptions.Item label="Total Invoice for customer">
																						{parseFloat(
																							this.props
																								.configuration[0]
																								.end_user_sales_price
																						).toFixed(
																							2
																						)}{" "}
																						{getSymbolFromCurrency(
																							this.props
																								.country[0]
																								.currency_symbol_html
																						)}
																					</Descriptions.Item>
																				</>
																			)
																		) : null
																	) : null}
																	{this.props
																		.customer_discount_visible &&
																		!this.props.is_dealer ? (
																		<Descriptions.Item label="Customer Discount">
																			{parseFloat(
																				(Math.abs(
																					this.props
																						.configuration[0]
																						.machine_total_price -
																					parseFloat(
																						this.props
																							.configuration[0]
																							.machine_total_price -
																						(this
																							.props
																							.configuration[0]
																							.machine_total_price *
																							this
																								.props
																								.configuration[0]
																								.discount_percentage) /
																						100 -
																						this
																							.props
																							.configuration[0]
																							.discount_amount -
																						this
																							.props
																							.configuration[0]
																							.dealer_discount_amount -
																						this
																							.props
																							.configuration[0]
																							.oem_discount -
																						parseFloat(
																							(this
																								.props
																								.configuration[0]
																								.extra_discount *
																								parseFloat(
																									this
																										.props
																										.configuration[0]
																										.machine_total_price /
																									this
																										.props
																										.configuration[0]
																										.quantity -
																									(this
																										.props
																										.configuration[0]
																										.discount_percentage *
																										(this
																											.props
																											.configuration[0]
																											.machine_total_price /
																											this
																												.props
																												.configuration[0]
																												.quantity)) /
																									100 -
																									this
																										.props
																										.configuration[0]
																										.discount_amount -
																									this
																										.props
																										.configuration[0]
																										.dealer_discount_amount -
																									this
																										.props
																										.configuration[0]
																										.oem_discount
																								).toFixed(
																									2
																								)) /
																							100
																						).toFixed(
																							2
																						) +
																						(this
																							.props
																							.configuration[0]
																							.sales_margin *
																							(this
																								.props
																								.configuration[0]
																								.machine_total_price -
																								(this
																									.props
																									.configuration[0]
																									.machine_total_price *
																									this
																										.props
																										.configuration[0]
																										.discount_percentage) /
																								100 -
																								this
																									.props
																									.configuration[0]
																									.discount_amount -
																								this
																									.props
																									.configuration[0]
																									.dealer_discount_amount -
																								this
																									.props
																									.configuration[0]
																									.oem_discount -
																								parseFloat(
																									(this
																										.props
																										.configuration[0]
																										.extra_discount *
																										parseFloat(
																											this
																												.props
																												.configuration[0]
																												.machine_total_price /
																											this
																												.props
																												.configuration[0]
																												.quantity -
																											(this
																												.props
																												.configuration[0]
																												.discount_percentage *
																												(this
																													.props
																													.configuration[0]
																													.machine_total_price /
																													this
																														.props
																														.configuration[0]
																														.quantity)) /
																											100 -
																											this
																												.props
																												.configuration[0]
																												.discount_amount -
																											this
																												.props
																												.configuration[0]
																												.dealer_discount_amount -
																											this
																												.props
																												.configuration[0]
																												.oem_discount
																										).toFixed(
																											2
																										)) /
																									100
																								).toFixed(
																									2
																								))) /
																						100
																					).toFixed(2)
																				) /
																					this.props
																						.configuration[0]
																						.machine_total_price) *
																				100
																			).toFixed(2)}
																			{" %"}
																		</Descriptions.Item>
																	) : null}
																	{this.props.configuration !==
																		[] &&
																		this.props.configuration[0]
																			.quantity !== 0 ? (
																		<Descriptions.Item label="Configuration Quantity">
																			{
																				this.props
																					.configuration[0]
																					.quantity
																			}
																		</Descriptions.Item>
																	) : null}
																	<Descriptions.Item label="Final Net total">
																		{this.props
																			.configuration !==
																			[] ? (
																			<CurrencyFormat
																				className="py-1 pr-2 text-right"
																				displayType={"text"}
																				decimalSeparator={
																					"."
																				}
																				decimalScale={2}
																				fixedDecimalScale={
																					true
																				}
																				value={
																					parseFloat(
																						this.props
																							.configuration[0]
																							.machine_total_price /
																						this.props
																							.configuration[0]
																							.quantity -
																						(this.props
																							.configuration[0]
																							.discount_percentage *
																							(this
																								.props
																								.configuration[0]
																								.machine_total_price /
																								this
																									.props
																									.configuration[0]
																									.quantity)) /
																						100 -
																						this.props
																							.configuration[0]
																							.discount_amount -
																						this.props
																							.configuration[0]
																							.dealer_discount_amount -
																						this.props
																							.configuration[0]
																							.oem_discount -
																						parseFloat(
																							(this
																								.props
																								.configuration[0]
																								.extra_discount *
																								parseFloat(
																									this
																										.props
																										.configuration[0]
																										.machine_total_price /
																									this
																										.props
																										.configuration[0]
																										.quantity -
																									(this
																										.props
																										.configuration[0]
																										.discount_percentage *
																										(this
																											.props
																											.configuration[0]
																											.machine_total_price /
																											this
																												.props
																												.configuration[0]
																												.quantity)) /
																									100 -
																									this
																										.props
																										.configuration[0]
																										.discount_amount -
																									this
																										.props
																										.configuration[0]
																										.dealer_discount_amount -
																									this
																										.props
																										.configuration[0]
																										.oem_discount
																								).toFixed(
																									2
																								)) /
																							100
																						).toFixed(
																							2
																						)
																					) *
																					this.props
																						.configuration[0]
																						.quantity
																				}
																				thousandSeparator={
																					true
																				}
																				suffix={
																					" " +
																					getSymbolFromCurrency(
																						this.props
																							.configuration[0]
																							.currency_symbol_html
																					)
																				}
																			/>
																		) : null}
																	</Descriptions.Item>
																	<Descriptions.Item label="Parts exchange (trade-in)">
																		{this.props.configuration[0].trade_in_value}
																	</Descriptions.Item>
																	<Descriptions.Item label="Final Net total">
																		{this.props
																			.configuration !==
																		[] ? (
																			<CurrencyFormat
																				className="py-1 pr-2 text-right"
																				displayType={"text"}
																				decimalSeparator={
																					"."
																				}
																				decimalScale={2}
																				fixedDecimalScale={
																					true
																				}
																				value={
																					parseFloat(
																						this.props
																							.configuration[0]
																							.machine_total_price /
																						this.props
																							.configuration[0]
																							.quantity -
																						(this.props
																								.configuration[0]
																								.discount_percentage *
																							(this
																									.props
																									.configuration[0]
																									.machine_total_price /
																								this
																									.props
																									.configuration[0]
																									.quantity)) /
																						100 -
																						this.props
																							.configuration[0]
																							.discount_amount -
																						this.props
																							.configuration[0]
																							.dealer_discount_amount -
																						this.props
																							.configuration[0]
																							.oem_discount -
																						parseFloat(
																							(this
																									.props
																									.configuration[0]
																									.extra_discount *
																								parseFloat(
																									this
																										.props
																										.configuration[0]
																										.machine_total_price /
																									this
																										.props
																										.configuration[0]
																										.quantity -
																									(this
																											.props
																											.configuration[0]
																											.discount_percentage *
																										(this
																												.props
																												.configuration[0]
																												.machine_total_price /
																											this
																												.props
																												.configuration[0]
																												.quantity)) /
																									100 -
																									this
																										.props
																										.configuration[0]
																										.discount_amount -
																									this
																										.props
																										.configuration[0]
																										.dealer_discount_amount -
																									this
																										.props
																										.configuration[0]
																										.oem_discount
																								).toFixed(
																									2
																								)) /
																							100
																						).toFixed(
																							2
																						)
																					) *
																					this.props
																						.configuration[0]
																						.quantity - this.props.configuration[0].trade_in_value
																				}
																				thousandSeparator={
																					true
																				}
																				suffix={
																					" " +
																					getSymbolFromCurrency(
																						this.props
																							.configuration[0]
																							.currency_symbol_html
																					)
																				}
																			/>
																		) : null}
																	</Descriptions.Item>
																</Descriptions>
															</div>
														</div>
													</div>
													<div className="row m-5">
														<div className="client-selected-parts">
															<table className="table table-striped table-responsive-md">
																<thead className="thead-dark">
																	<tr>
																		<th
																			scope="col"
																			className="min-width-table"
																		>
																			Model Number
																		</th>
																		<th
																			scope="col"
																			className="min-width-table"
																		>
																			Description
																		</th>
																		<th
																			scope="col"
																			className="min-width-table"
																		>
																			Price
																		</th>
																		<th
																			scope="col"
																			className="min-width-table"
																		>
																			Quantity
																		</th>
																		<th
																			scope="col"
																			className="min-width-table"
																		>
																			Total Price
																		</th>
																	</tr>
																	<tr
																		style={{
																			backgroundColor:
																				"#B9373D",
																			color: "#ffffff"
																		}}
																	>
																		<td
																			style={{
																				color: "white",
																				fontWeight: 900,
																				fontSize: 15
																			}}
																		>
																			Components
																		</td>
																		<td></td>
																		<td></td>
																		<td></td>
																		<td></td>
																	</tr>
																</thead>
																<tbody>
																	<tr>
																		<td className="min-width-table">
																			{this.props
																				.category_details
																				? this.props
																					.category_details[0]
																					.machine_category
																				: null}
																		</td>
																		<td className="min-width-table"></td>
																		<td className="money min-width-table">
																			{getSymbolFromCurrency(
																				this.props
																					.category_details[0]
																					.currency_symbol_html
																			)}{" "}
																			{this.props
																				.category_details
																				? parseFloat(
																					this.props
																						.category_details[0]
																						.price
																				).toFixed(2)
																				: null}
																		</td>
																		<td>{this.props.configuration ?
																			this.props.configuration[0].only_parts ? 0 : 1 : 1}</td>
																		<td className="money">
																			{getSymbolFromCurrency(
																				this.props
																					.category_details[0]
																					.currency_symbol_html
																			)}{" "}
																			{
																				this.props.category_details ?
																					this.props.configuration ?
																						this.props.configuration[0].only_parts ? 0 : parseFloat(this.props.category_details[0].price).toFixed(2) : parseFloat(this.props.category_details[0].price).toFixed(2)
																					: null
																			}
																			{/* {this.props
																				.category_details
																				? parseFloat(
																					this.props
																						.category_details[0]
																						.price
																				)
																				: null} */}
																		</td>
																	</tr>
																	<tr
																		style={{
																			backgroundColor:
																				"#B9373D",
																			color: "#ffffff"
																		}}
																	>
																		<td
																			style={{
																				color: "white",
																				fontWeight: 900,
																				fontSize: 15
																			}}
																		>
																			Parts
																		</td>
																		<td></td>
																		<td></td>
																		<td></td>
																		<td></td>
																	</tr>
																	{this.props.parts !== []
																		? this.props.parts.map(
																			(part, i) => (
																				<tr rowKey={i}>
																					<td className="min-width-table">
																						{
																							part.machine_part_model_number
																						}
																					</td>
																					<td className="min-width-table">
																						{
																							part.machine_part_name
																						}
																					</td>
																					{part.is_included ? (
																						<>
																							<td className="min-width-table">
																								Included
																								</td>
																							<td className="min-width-table">
																								{
																									part.quantity
																								}
																							</td>
																							<td className="min-width-table">
																								Included
																								</td>
																						</>
																					) : (
																						<>
																							<td className="money min-width-table">
																								{getSymbolFromCurrency(
																									part.currency_symbol_html
																								)}{" "}
																								{
																									part.price
																								}
																							</td>
																							<td className="min-width-table">
																								{" "}
																								{
																									part.quantity
																								}
																							</td>
																							<td className="money min-width-table">
																								{getSymbolFromCurrency(
																									part.currency_symbol_html
																								)}{" "}
																								{part.quantity *
																									part.price}
																							</td>
																						</>
																					)}
																				</tr>
																			)
																		)
																		: null}
																</tbody>
															</table>

															<table className="table table-striped table-responsive-md mt-5">
																<tbody>
																	{this.props
																		.manual_adjustments
																		.length > 0 ? (
																		<>
																			<tr
																				style={{
																					backgroundColor:
																						"#B9373D",
																					color: "#ffffff"
																				}}
																			>
																				<td
																					style={{
																						color:
																							"white",
																						fontWeight: 900,
																						fontSize: 15
																					}}
																				>
																					Manual
																					Adjustments
																				</td>
																				<td></td>
																				<td></td>
																				<td></td>
																				<td></td>
																				<td></td>
																			</tr>
																			<tr className="thead-dark">
																				<th scope="col">
																					Model Number
																				</th>
																				<th scope="col">
																					Description
																				</th>
																				<th scope="col">
																					Note
																				</th>
																				<th scope="col">
																					Price
																				</th>
																				<th scope="col">
																					Quantity
																				</th>
																				<th scope="col">
																					Total Price
																				</th>
																			</tr>
																		</>
																	) : null}
																	{this.props
																		.manual_adjustments !==
																		null &&
																		this.props
																			.manual_adjustments !== []
																		? this.props.manual_adjustments.map(
																			(adjustment, i) => (
																				<tr rowKey={i}>
																					<td className="min-width-table">
																						{" "}
																						{
																							adjustment.manual_adjustment_model_number
																						}
																					</td>
																					<td className="min-width-table">
																						{
																							adjustment.manual_adjustment_name
																						}
																					</td>
																					<td className="min-width-table">
																						{
																							adjustment.manual_adjustment_note
																						}
																					</td>
																					<td className="money min-width-table">
																						{getSymbolFromCurrency(
																							adjustment.currency_symbol_html
																						)}{" "}
																						{parseFloat(
																							adjustment.manual_adjustment_price
																						)}
																					</td>
																					<td>
																						<div className="client-counter row">
																							{" "}
																							{
																								adjustment.manual_adjustment_quantity
																							}
																						</div>
																					</td>
																					<td className="money">
																						{getSymbolFromCurrency(
																							adjustment.currency_symbol_html
																						)}
																						{(
																							parseFloat(
																								adjustment.manual_adjustment_quantity
																							) *
																							parseFloat(
																								adjustment.manual_adjustment_price
																							)
																						).toFixed(
																							2
																						)}
																					</td>
																				</tr>
																			)
																		)
																		: null}
																</tbody>
															</table>
														</div>
													</div>
												</div>
											) : (
												<div
													className="modal-body"
													style={{
														justifyContent: "center"
													}}
												>
													<div
														className="row m-5"
														style={{
															justifyContent: "center",
															flexDirection: "row"
														}}
													>
														{this.props.configuration_message.includes(
															"Getting single user configuration faliure"
														) ? (
															<strong>
																Either there is something wrong with the configuration (can be deleted by the user) or you don't have proper authority to access this configuration, please contact the administrator. Then we will try to fix it.
															</strong>
														) : (
															<Loader
																type="Rings"
																color="#00BFFF"
																height={100}
																width={100}
															/>
														)}
													</div>
												</div>
											)}
										</div>
									</div>
								</div>
							</div>
						}

						<Modal
							onOk={this.logModalOk}
							onCancel={this.logModalCancel}
							footer={null}
							title="Configuration Logs"
							visible={this.state.log_configuration_modal_visible}
						>
							{this.state.log_configuration_data ? (
								<div className="card-container">
									<Tabs type="card">
										<TabPane tab="Changes Summary" key={'summary'}>
											{
												<div className="container">
													{
														this.state.smart_log_configuration_data ?
															this.state.smart_log_configuration_data.map((configuration, i) =>
																<div className="row">
																	{
																		i === 0 ?
																			'Current Change' :
																			(this.state.smart_log_configuration_data.length - i) + ' changes'
																	}

																	<table className="table table-striped table-responsive-md">
																		<thead className="thead-dark">
																			<tr>
																				<th
																					scope="col"
																					className="min-width-table"
																				>
																					Changes Field
														</th>
																				<th
																					scope="col"
																					className="min-width-table"
																				>
																					Version
														</th>
																				<th
																					scope="col"
																					className="min-width-table"
																				>
																					Machine Part Number
														</th>
																				<th
																					scope="col"
																					className="min-width-table"
																				>
																					Machine Part Description
														</th>
																				<th
																					scope="col"
																					className="min-width-table"
																				>
																					Modifications
														</th>
																				<th
																					scope="col"
																					className="min-width-table"
																				>
																					Modified By
														</th>
																				<th
																					scope="col"
																					className="min-width-table"
																				>
																					Date Modified
														</th>
																			</tr>
																		</thead>
																		<tbody>
																			{
																				configuration.current_approve_request !== configuration.old_approve_request ?
																					<tr>
																						<td>Approve Request</td>
																						<td>{i === 0 ?
																							'Latest' :
																							(this.state.smart_log_configuration_data.length - i)}
																						</td>
																						<td></td>
																						<td></td>
																						<td>{configuration.current_approve_request ? 'Approved' : 'Not Approved'}</td>
																						<td>{configuration.changed_by}</td>
																						<td>{configuration.modified_date}</td>
																					</tr>
																					: null
																			}
																			{
																				configuration.current_approve_with_change !== configuration.old_approve_with_change ?
																					<tr>
																						<td>Approve Request with change</td>
																						<td>{i === 0 ?
																							'Latest' :
																							(this.state.smart_log_configuration_data.length - i)}
																						</td>
																						<td></td>
																						<td></td>
																						<td>{configuration.current_approve_with_change ? 'Approved with change' : 'Not Approved'}</td>
																						<td>{configuration.changed_by}</td>
																						<td>{configuration.modified_date}</td>
																					</tr>
																					: null
																			}
																			{
																				configuration.quantity_difference !== 0 ?
																					<tr>
																						<td>Quantity</td>
																						<td>{i === 0 ?
																							'Latest' :
																							(this.state.smart_log_configuration_data.length - i)}
																						</td>
																						<td></td>
																						<td></td>
																						<td>{'From [' + (configuration.current_quantity - configuration.quantity_difference) + '] to [' + configuration.current_quantity + ']'}</td>
																						<td>{configuration.changed_by}</td>
																						<td>{configuration.modified_date}</td>
																					</tr>
																					: null
																			}
																			{
																				configuration.discount_percentage_difference !== 0 ?
																					<tr>
																						<td>Discount Percentage</td>
																						<td>{i === 0 ?
																							'Latest' :
																							(this.state.smart_log_configuration_data.length - i)}
																						</td>
																						<td></td>
																						<td></td>
																						<td>{'From [' + (configuration.current_discount_percentage - configuration.discount_percentage_difference) + '%] to [' + configuration.current_discount_percentage + '%]'}</td>
																						<td>{configuration.changed_by}</td>
																						<td>{configuration.modified_date}</td>
																					</tr>
																					: null
																			}
																			{
																				configuration.disount_amount_difference !== 0 ?
																					<tr>
																						<td>Discount Amount</td>
																						<td>{i === 0 ?
																							'Latest' :
																							(this.state.smart_log_configuration_data.length - i)}
																						</td>
																						<td></td>
																						<td></td>
																						<td>{'From ' + (configuration.current_discount_amount - configuration.disount_amount_difference) + ' to ' + configuration.current_discount_amount}</td>
																						<td>{configuration.changed_by}</td>
																						<td>{configuration.modified_date}</td>
																					</tr>
																					: null
																			}
																			{
																				configuration.dealer_discount_amount_difference !== 0 ?
																					<tr>
																						<td>Dealer Discount Percentage</td>
																						<td>{i === 0 ?
																							'Latest' :
																							(this.state.smart_log_configuration_data.length - i)}
																						</td>
																						<td></td>
																						<td></td>
																						<td>{'From ' + (configuration.current_dealer_discount_amount - configuration.dealer_discount_amount_difference) + ' to ' + configuration.current_dealer_discount_amount}</td>
																						<td>{configuration.changed_by}</td>
																						<td>{configuration.modified_date}</td>
																					</tr>
																					: null
																			}
																			{
																				configuration.oem_discount_difference !== 0 ?
																					<tr>
																						<td>OEM Discount Percentage</td>
																						<td>{i === 0 ?
																							'Latest' :
																							(this.state.smart_log_configuration_data.length - i)}
																						</td>
																						<td></td>
																						<td></td>
																						<td>{'From ' + (configuration.current_oem_discount - configuration.oem_discount_difference) + ' to ' + configuration.current_oem_discount}</td>
																						<td>{configuration.changed_by}</td>
																						<td>{configuration.modified_date}</td>
																					</tr>
																					: null
																			}
																			{
																				configuration.extra_discount_difference !== 0 ?
																					<tr>
																						<td>Extra Discount Percentage</td>
																						<td>{i === 0 ?
																							'Latest' :
																							(this.state.smart_log_configuration_data.length - i)}
																						</td>
																						<td></td>
																						<td></td>
																						<td>{'From ' + (configuration.current_extra_discount - configuration.extra_discount_difference) + ' to ' + configuration.current_extra_discount}</td>
																						<td>{configuration.changed_by}</td>
																						<td>{configuration.modified_date}</td>
																					</tr>
																					: null
																			}
																			{
																				configuration.sales_margin_difference !== 0 ?
																					<tr>
																						<td>Sales Margin</td>
																						<td>{i === 0 ?
																							'Latest' :
																							(this.state.smart_log_configuration_data.length - i)}
																						</td>
																						<td></td>
																						<td></td>
																						<td>{'From [' + (configuration.current_sales_margin - configuration.sales_margin_difference) + '%] to [' + configuration.current_sales_margin + '%]'}</td>
																						<td>{configuration.changed_by}</td>
																						<td>{configuration.modified_date}</td>
																					</tr>
																					: null
																			}
																			{
																				configuration.parts_changed.length !== 0 ?
																					configuration.parts_changed.map((part, j) =>
																						part.added ?
																							<tr>
																								<td>Add Machine Part</td>
																								<td>{i === 0 ?
																									'Latest' :
																									(this.state.smart_log_configuration_data.length - i)}
																								</td>
																								<td>{part.machine_part_model_number}</td>
																								<td>{part.machine_part_name}</td>
																								<td>Add</td>
																								<td>{configuration.changed_by}</td>
																								<td>{configuration.modified_date}</td>
																							</tr> :
																							part.removed ?
																								<tr>
																									<td>Remove Machine Part</td>
																									<td>{i === 0 ?
																										'Latest' :
																										(this.state.smart_log_configuration_data.length - i)}
																									</td>
																									<td>{part.machine_part_model_number}</td>
																									<td>{part.machine_part_name}</td>
																									<td>Delete</td>
																									<td>{configuration.changed_by}</td>
																									<td>{configuration.modified_date}</td>
																								</tr>
																								:
																								part.quantity_change ?
																									<tr>
																										<td>Machine Quantity Change</td>
																										<td>{i === 0 ?
																											'Latest' :
																											(this.state.smart_log_configuration_data.length - i)}
																										</td>
																										<td>{part.machine_part_model_number}</td>
																										<td>{part.machine_part_name}</td>
																										<td>{'From ' + (part.current_quantity - part.quantity) + ' to ' + part.current_quantity}</td>
																										<td>{configuration.changed_by}</td>
																										<td>{configuration.modified_date}</td>
																									</tr>
																									: null

																					)
																					: null
																			}
																			{
																				configuration.manual_adjustment_changed.length !== 0 ?
																					configuration.manual_adjustment_changed.map((part, j) =>
																						part.added ?
																							<tr>
																								<td>Add Machine Part</td>
																								<td>{i === 0 ?
																									'Latest' :
																									(this.state.smart_log_configuration_data.length - i)}
																								</td>
																								<td>{part.manual_adjustment_model_number}</td>
																								<td>{part.manual_adjustment_name}</td>
																								<td>Add</td>
																								<td>{configuration.changed_by}</td>
																								<td>{configuration.modified_date}</td>
																							</tr> :
																							part.removed ?
																								<tr>
																									<td>Remove Machine Part</td>
																									<td>{i === 0 ?
																										'Latest' :
																										(this.state.smart_log_configuration_data.length - i)}
																									</td>
																									<td>{part.manual_adjustment_model_number}</td>
																									<td>{part.manual_adjustment_name}</td>
																									<td>Delete</td>
																									<td>{configuration.changed_by}</td>
																									<td>{configuration.modified_date}</td>
																								</tr>
																								:
																								part.quantity_change ?
																									<tr>
																										<td>Machine Quantity Change</td>
																										<td>{i === 0 ?
																											'Latest' :
																											(this.state.smart_log_configuration_data.length - i)}
																										</td>
																										<td>{part.manual_adjustment_model_number}</td>
																										<td>{part.manual_adjustment_name}</td>
																										<td>{'From ' + (part.current_quantity - part.quantity) + ' to ' + part.current_quantity}</td>
																										<td>{configuration.changed_by}</td>
																										<td>{configuration.modified_date}</td>
																									</tr>
																									: null

																					)
																					: null
																			}
																			{
																				configuration.machine_total_price_difference !== 0 ?
																					<tr>
																						<td>Total Machine Price</td>
																						<td>{i === 0 ?
																							'Latest' :
																							(this.state.smart_log_configuration_data.length - i)}
																						</td>
																						<td></td>
																						<td></td>
																						<td>{'From ' + (configuration.current_machine_total_price - configuration.machine_total_price_difference) + ' to ' + configuration.current_machine_total_price}</td>
																						<td>{configuration.changed_by}</td>
																						<td>{configuration.modified_date}</td>
																					</tr>
																					: null
																			}
																			{
																				configuration.machine_total_price_difference !== 0 ?
																					<tr>
																						<td>Current
																			Total Machine Price</td>
																						<td>{i === 0 ?
																							'Latest' :
																							(this.state.smart_log_configuration_data.length - i)}
																						</td>
																						<td></td>
																						<td></td>
																						<td>{configuration.current_machine_total_price}</td>
																						<td>{configuration.changed_by}</td>
																						<td>{configuration.modified_date}</td>
																					</tr>
																					: null
																			}
																		</tbody>
																	</table>


																</div>
															)
															: null
													}
												</div>
											}
										</TabPane>
										<TabPane tab="Current Machine" key={0}>

											{this.props.configuration &&
												!this.props.configuration_loading ? (
												<div className="modal-body">
													<div className="row m-5">
														<div className="col-sm-4">
															{this.props.configuration[0]
																.machine_category_image !==
																[] &&
																this.props.configuration[0]
																	.machine_category_image !==
																null ? (
																<img loading="lazy"
																	width="100%"
																	className="img-responsive"
																	alt=""
																	src={
																		config.APP_URL +
																		"/api/storage/" +
																		this.props
																			.configuration[0]
																			.machine_category_image
																	}
																/>
															) : (
																<img loading="lazy"
																	width="100%"
																	className="img-responsive"
																	alt=""
																	src={noimage}
																/>
															)}
														</div>
														<div className="col-sm-8">
															<div className="client-config-details ml-5 mr-5 mb-5">
																<Descriptions
																	title=""
																	layout="hotizontal"
																	bordered
																	size="small"
																	column={1}
																>
																	<Descriptions.Item label="Configuration Name">
																		{this.props
																			.configuration !==
																			[]
																			? this.props
																				.configuration[0]
																				.name
																			: ""}
																	</Descriptions.Item>
																	<Descriptions.Item label="Product Category">
																		{this.props
																			.configuration !==
																			[]
																			? this.props
																				.configuration[0]
																				.machine_category
																			: ""}
																	</Descriptions.Item>
																	<Descriptions.Item label="Retail Price">
																		{this.props
																			.configuration !==
																			[]
																			? parseFloat(
																				this.props
																					.configuration[0]
																					.machine_total_price /
																				this.props
																					.configuration[0]
																					.quantity
																			).toFixed(2)
																			: ""}{" "}
																		{getSymbolFromCurrency(
																			this.props
																				.country[0]
																				.currency_symbol_html
																		)}
																	</Descriptions.Item>
																	{this.props
																		.configuration !==
																		[] &&
																		this.props
																			.configuration[0]
																			.discount_percentage !==
																		0 ? (
																		this.props
																			.is_dealer ? (
																			<Descriptions.Item label="Standard Dealer Terms %">
																				{
																					this.props
																						.configuration[0]
																						.discount_percentage
																				}{" "}
																%
																			</Descriptions.Item>
																		) : (
																			<>
																				<Descriptions.Item label="Discount Percentage">
																					{
																						this.props
																							.configuration[0]
																							.discount_percentage
																					}{" "}
																	%
																</Descriptions.Item>
																				<Descriptions.Item label="Discount Percentage Amount">
																					<CurrencyFormat
																						className="py-1 pr-2 text-right"
																						displayType={
																							"text"
																						}
																						decimalSeparator={
																							"."
																						}
																						decimalScale={
																							2
																						}
																						fixedDecimalScale={
																							true
																						}
																						value={parseFloat(
																							((this
																								.props
																								.configuration[0]
																								.machine_total_price /
																								this
																									.props
																									.configuration[0]
																									.quantity) *
																								this
																									.props
																									.configuration[0]
																									.discount_percentage) /
																							100
																						).toFixed(
																							2
																						)}
																						thousandSeparator={
																							true
																						}
																						suffix={
																							" " +
																							getSymbolFromCurrency(
																								this
																									.props
																									.configuration[0]
																									.currency_symbol_html
																							)
																						}
																					/>
																				</Descriptions.Item>
																			</>
																		)
																	) : null}
																	{this.props
																		.configuration !==
																		[] &&
																		this.props
																			.configuration[0]
																			.discount_amount !==
																		0 ? (
																		this.props
																			.is_dealer ? (
																			<Descriptions.Item label="Dealer Discount Amount">
																				{
																					this.props
																						.configuration[0]
																						.discount_amount
																				}{" "}
																				{getSymbolFromCurrency(
																					this.props
																						.configuration[0]
																						.currency_symbol_html
																				)}
																			</Descriptions.Item>
																		) : (
																			<Descriptions.Item label="Discount Amount">
																				{
																					this.props
																						.configuration[0]
																						.discount_amount
																				}{" "}
																				{getSymbolFromCurrency(
																					this.props
																						.configuration[0]
																						.currency_symbol_html
																				)}
																			</Descriptions.Item>
																		)
																	) : null}

																	{this.props
																		.configuration !==
																		[] &&
																		this.props
																			.configuration[0]
																			.dealer_discount_amount !==
																		0 ? (
																		this.props
																			.extra_discount_visible ? (
																			<Descriptions.Item label="Extra Discount">
																				{
																					this.props
																						.configuration[0]
																						.dealer_discount_amount
																				}{" "}
																				{getSymbolFromCurrency(
																					this.props
																						.configuration[0]
																						.currency_symbol_html
																				)}
																			</Descriptions.Item>
																		) : null
																	) : null}
																	{this.props.is_dealer ? (
																		<Descriptions.Item label="Dealer Net Total">
																			{this.props
																				.configuration !==
																				[] ? (
																				<CurrencyFormat
																					className="py-1 pr-2 text-right"
																					displayType={
																						"text"
																					}
																					decimalSeparator={
																						"."
																					}
																					decimalScale={
																						2
																					}
																					fixedDecimalScale={
																						true
																					}
																					value={parseFloat(
																						this.props
																							.configuration[0]
																							.machine_total_price /
																						this
																							.props
																							.configuration[0]
																							.quantity -
																						(this
																							.props
																							.configuration[0]
																							.discount_percentage *
																							(this
																								.props
																								.configuration[0]
																								.machine_total_price /
																								this
																									.props
																									.configuration[0]
																									.quantity)) /
																						100 -
																						this
																							.props
																							.configuration[0]
																							.discount_amount -
																						this
																							.props
																							.configuration[0]
																							.dealer_discount_amount
																					)}
																					thousandSeparator={
																						true
																					}
																					suffix={
																						" " +
																						getSymbolFromCurrency(
																							this
																								.props
																								.configuration[0]
																								.currency_symbol_html
																						)
																					}
																				/>
																			) : null}
																		</Descriptions.Item>
																	) : (
																		<Descriptions.Item label="Net Total">
																			{this.props
																				.configuration !==
																				[] ? (
																				<CurrencyFormat
																					className="py-1 pr-2 text-right"
																					displayType={
																						"text"
																					}
																					decimalSeparator={
																						"."
																					}
																					decimalScale={
																						2
																					}
																					fixedDecimalScale={
																						true
																					}
																					value={parseFloat(
																						this.props
																							.configuration[0]
																							.machine_total_price /
																						this
																							.props
																							.configuration[0]
																							.quantity -
																						(this
																							.props
																							.configuration[0]
																							.discount_percentage *
																							(this
																								.props
																								.configuration[0]
																								.machine_total_price /
																								this
																									.props
																									.configuration[0]
																									.quantity)) /
																						100 -
																						this
																							.props
																							.configuration[0]
																							.discount_amount -
																						this
																							.props
																							.configuration[0]
																							.dealer_discount_amount
																					)}
																					thousandSeparator={
																						true
																					}
																					suffix={
																						" " +
																						getSymbolFromCurrency(
																							this
																								.props
																								.configuration[0]
																								.currency_symbol_html
																						)
																					}
																				/>
																			) : null}
																		</Descriptions.Item>
																	)}

																	{this.props.is_dealer ? (
																		<>
																			<Descriptions.Item label="OEM Programme">
																				{
																					this.props
																						.configuration[0]
																						.oem_discount
																				}
																				{" " +
																					getSymbolFromCurrency(
																						this.props
																							.configuration[0]
																							.currency_symbol_html
																					)}
																			</Descriptions.Item>
																			<Descriptions.Item label="Net Net Purchase Price">
																				<CurrencyFormat
																					className="py-1 pr-2 text-right"
																					displayType={
																						"text"
																					}
																					decimalSeparator={
																						"."
																					}
																					decimalScale={
																						2
																					}
																					fixedDecimalScale={
																						true
																					}
																					value={parseFloat(
																						this.props
																							.configuration[0]
																							.machine_total_price /
																						this
																							.props
																							.configuration[0]
																							.quantity -
																						(this
																							.props
																							.configuration[0]
																							.discount_percentage *
																							(this
																								.props
																								.configuration[0]
																								.machine_total_price /
																								this
																									.props
																									.configuration[0]
																									.quantity)) /
																						100 -
																						this
																							.props
																							.configuration[0]
																							.discount_amount -
																						this
																							.props
																							.configuration[0]
																							.dealer_discount_amount -
																						this
																							.props
																							.configuration[0]
																							.oem_discount
																					).toFixed(2)}
																					thousandSeparator={
																						true
																					}
																					suffix={
																						" " +
																						getSymbolFromCurrency(
																							this
																								.props
																								.configuration[0]
																								.currency_symbol_html
																						)
																					}
																				/>
																			</Descriptions.Item>
																		</>
																	) : null}
																	{this.props.is_dealer ? (
																		<>
																			<Descriptions.Item label="Extra Discount">
																				{
																					this.props
																						.configuration[0]
																						.extra_discount
																				}
																				{" %"}
																			</Descriptions.Item>
																			<Descriptions.Item label="Reesink Extra Extra Support">
																				<CurrencyFormat
																					className="py-1 pr-2 text-right"
																					displayType={
																						"text"
																					}
																					decimalSeparator={
																						"."
																					}
																					decimalScale={
																						2
																					}
																					fixedDecimalScale={
																						true
																					}
																					value={parseFloat(
																						(this.props
																							.configuration[0]
																							.extra_discount *
																							parseFloat(
																								this
																									.props
																									.configuration[0]
																									.machine_total_price /
																								this
																									.props
																									.configuration[0]
																									.quantity -
																								(this
																									.props
																									.configuration[0]
																									.discount_percentage *
																									(this
																										.props
																										.configuration[0]
																										.machine_total_price /
																										this
																											.props
																											.configuration[0]
																											.quantity)) /
																								100 -
																								this
																									.props
																									.configuration[0]
																									.discount_amount -
																								this
																									.props
																									.configuration[0]
																									.dealer_discount_amount -
																								this
																									.props
																									.configuration[0]
																									.oem_discount
																							).toFixed(
																								2
																							)) /
																						100
																					).toFixed(2)}
																					thousandSeparator={
																						true
																					}
																					suffix={
																						" " +
																						getSymbolFromCurrency(
																							this
																								.props
																								.configuration[0]
																								.currency_symbol_html
																						)
																					}
																				/>
																			</Descriptions.Item>
																		</>
																	) : null}
																	{this.props.is_dealer ? (
																		<Descriptions.Item label="Net Net Net Price">
																			<CurrencyFormat
																				className="py-1 pr-2 text-right"
																				displayType={
																					"text"
																				}
																				decimalSeparator={
																					"."
																				}
																				decimalScale={2}
																				fixedDecimalScale={
																					true
																				}
																				value={parseFloat(
																					parseFloat(
																						this.props
																							.configuration[0]
																							.machine_total_price /
																						this
																							.props
																							.configuration[0]
																							.quantity -
																						(this
																							.props
																							.configuration[0]
																							.discount_percentage *
																							(this
																								.props
																								.configuration[0]
																								.machine_total_price /
																								this
																									.props
																									.configuration[0]
																									.quantity)) /
																						100 -
																						this
																							.props
																							.configuration[0]
																							.discount_amount -
																						this
																							.props
																							.configuration[0]
																							.dealer_discount_amount -
																						this
																							.props
																							.configuration[0]
																							.oem_discount
																					).toFixed(2) -
																					parseFloat(
																						(this
																							.props
																							.configuration[0]
																							.extra_discount *
																							parseFloat(
																								this
																									.props
																									.configuration[0]
																									.machine_total_price /
																								this
																									.props
																									.configuration[0]
																									.quantity -
																								(this
																									.props
																									.configuration[0]
																									.discount_percentage *
																									(this
																										.props
																										.configuration[0]
																										.machine_total_price /
																										this
																											.props
																											.configuration[0]
																											.quantity)) /
																								100 -
																								this
																									.props
																									.configuration[0]
																									.discount_amount -
																								this
																									.props
																									.configuration[0]
																									.dealer_discount_amount -
																								this
																									.props
																									.configuration[0]
																									.oem_discount
																							).toFixed(
																								2
																							)) /
																						100
																					).toFixed(
																						2
																					)
																				).toFixed(2)}
																				thousandSeparator={
																					true
																				}
																				suffix={
																					" " +
																					getSymbolFromCurrency(
																						this.props
																							.configuration[0]
																							.currency_symbol_html
																					)
																				}
																			/>
																		</Descriptions.Item>
																	) : null
																	}
																	{this.props
																		.configuration !==
																		[] &&
																		this.props
																			.configuration[0]
																			.sales_margin !== 0 ? (
																		this.props
																			.sales_margin_visible ? (
																			this.props
																				.is_dealer ? (
																				<>
																					<Descriptions.Item label="End Customer Purchase Price">
																						{parseFloat(
																							this
																								.props
																								.configuration[0]
																								.end_user_sales_price
																						).toFixed(
																							2
																						)}{" "}
																						{getSymbolFromCurrency(
																							this
																								.props
																								.country[0]
																								.currency_symbol_html
																						)}
																					</Descriptions.Item>
																					<Descriptions.Item label="Dealer Margin Amount">
																						<CurrencyFormat
																							className="py-1 pr-2 text-right"
																							displayType={
																								"text"
																							}
																							decimalSeparator={
																								"."
																							}
																							decimalScale={
																								2
																							}
																							fixedDecimalScale={
																								true
																							}
																							value={
																								this
																									.props
																									.configuration[0]
																									.sales_margin_amount
																							}
																							thousandSeparator={
																								true
																							}
																							suffix={
																								" " +
																								getSymbolFromCurrency(
																									this
																										.props
																										.configuration[0]
																										.currency_symbol_html
																								)
																							}
																						/>
																					</Descriptions.Item>
																					<Descriptions.Item label="Dealer Margin">
																						{
																							this
																								.props
																								.configuration[0]
																								.sales_margin
																						}
																						{" %"}
																					</Descriptions.Item>
																				</>
																			) : (
																				<>
																					<Descriptions.Item label="Sales Margin">
																						{
																							this
																								.props
																								.configuration[0]
																								.sales_margin
																						}
																						{" %"}
																					</Descriptions.Item>
																					<Descriptions.Item label="Total Invoice for customer">
																						{parseFloat(
																							this
																								.props
																								.configuration[0]
																								.end_user_sales_price
																						).toFixed(
																							2
																						)}{" "}
																						{getSymbolFromCurrency(
																							this
																								.props
																								.country[0]
																								.currency_symbol_html
																						)}
																					</Descriptions.Item>
																				</>
																			)
																		) : null
																	) : null}
																	{this.props
																		.customer_discount_visible &&
																		!this.props.is_dealer ? (
																		<Descriptions.Item label="Customer Discount">
																			{parseFloat(
																				(Math.abs(
																					this.props
																						.configuration[0]
																						.machine_total_price -
																					parseFloat(
																						this
																							.props
																							.configuration[0]
																							.machine_total_price -
																						(this
																							.props
																							.configuration[0]
																							.machine_total_price *
																							this
																								.props
																								.configuration[0]
																								.discount_percentage) /
																						100 -
																						this
																							.props
																							.configuration[0]
																							.discount_amount -
																						this
																							.props
																							.configuration[0]
																							.dealer_discount_amount -
																						this
																							.props
																							.configuration[0]
																							.oem_discount -
																						parseFloat(
																							(this
																								.props
																								.configuration[0]
																								.extra_discount *
																								parseFloat(
																									this
																										.props
																										.configuration[0]
																										.machine_total_price /
																									this
																										.props
																										.configuration[0]
																										.quantity -
																									(this
																										.props
																										.configuration[0]
																										.discount_percentage *
																										(this
																											.props
																											.configuration[0]
																											.machine_total_price /
																											this
																												.props
																												.configuration[0]
																												.quantity)) /
																									100 -
																									this
																										.props
																										.configuration[0]
																										.discount_amount -
																									this
																										.props
																										.configuration[0]
																										.dealer_discount_amount -
																									this
																										.props
																										.configuration[0]
																										.oem_discount
																								).toFixed(
																									2
																								)) /
																							100
																						).toFixed(
																							2
																						) +
																						(this
																							.props
																							.configuration[0]
																							.sales_margin *
																							(this
																								.props
																								.configuration[0]
																								.machine_total_price -
																								(this
																									.props
																									.configuration[0]
																									.machine_total_price *
																									this
																										.props
																										.configuration[0]
																										.discount_percentage) /
																								100 -
																								this
																									.props
																									.configuration[0]
																									.discount_amount -
																								this
																									.props
																									.configuration[0]
																									.dealer_discount_amount -
																								this
																									.props
																									.configuration[0]
																									.oem_discount -
																								parseFloat(
																									(this
																										.props
																										.configuration[0]
																										.extra_discount *
																										parseFloat(
																											this
																												.props
																												.configuration[0]
																												.machine_total_price /
																											this
																												.props
																												.configuration[0]
																												.quantity -
																											(this
																												.props
																												.configuration[0]
																												.discount_percentage *
																												(this
																													.props
																													.configuration[0]
																													.machine_total_price /
																													this
																														.props
																														.configuration[0]
																														.quantity)) /
																											100 -
																											this
																												.props
																												.configuration[0]
																												.discount_amount -
																											this
																												.props
																												.configuration[0]
																												.dealer_discount_amount -
																											this
																												.props
																												.configuration[0]
																												.oem_discount
																										).toFixed(
																											2
																										)) /
																									100
																								).toFixed(
																									2
																								))) /
																						100
																					).toFixed(
																						2
																					)
																				) /
																					this.props
																						.configuration[0]
																						.machine_total_price) *
																				100
																			).toFixed(2)}
																			{" %"}
																		</Descriptions.Item>
																	) : null}
																	{this.props
																		.configuration !==
																		[] &&
																		this.props
																			.configuration[0]
																			.quantity !== 0 ? (
																		<Descriptions.Item label="Configuration Quantity">
																			{
																				this.props
																					.configuration[0]
																					.quantity
																			}
																		</Descriptions.Item>
																	) : null}
																	<Descriptions.Item label="Final Net total">
																		{this.props
																			.configuration !==
																			[] ? (
																			<CurrencyFormat
																				className="py-1 pr-2 text-right"
																				displayType={
																					"text"
																				}
																				decimalSeparator={
																					"."
																				}
																				decimalScale={2}
																				fixedDecimalScale={
																					true
																				}
																				value={
																					parseFloat(
																						this.props
																							.configuration[0]
																							.machine_total_price /
																						this
																							.props
																							.configuration[0]
																							.quantity -
																						(this
																							.props
																							.configuration[0]
																							.discount_percentage *
																							(this
																								.props
																								.configuration[0]
																								.machine_total_price /
																								this
																									.props
																									.configuration[0]
																									.quantity)) /
																						100 -
																						this
																							.props
																							.configuration[0]
																							.discount_amount -
																						this
																							.props
																							.configuration[0]
																							.dealer_discount_amount -
																						this
																							.props
																							.configuration[0]
																							.oem_discount -
																						parseFloat(
																							(this
																								.props
																								.configuration[0]
																								.extra_discount *
																								parseFloat(
																									this
																										.props
																										.configuration[0]
																										.machine_total_price /
																									this
																										.props
																										.configuration[0]
																										.quantity -
																									(this
																										.props
																										.configuration[0]
																										.discount_percentage *
																										(this
																											.props
																											.configuration[0]
																											.machine_total_price /
																											this
																												.props
																												.configuration[0]
																												.quantity)) /
																									100 -
																									this
																										.props
																										.configuration[0]
																										.discount_amount -
																									this
																										.props
																										.configuration[0]
																										.dealer_discount_amount -
																									this
																										.props
																										.configuration[0]
																										.oem_discount
																								).toFixed(
																									2
																								)) /
																							100
																						).toFixed(
																							2
																						)
																					) *
																					this.props
																						.configuration[0]
																						.quantity
																				}
																				thousandSeparator={
																					true
																				}
																				suffix={
																					" " +
																					getSymbolFromCurrency(
																						this.props
																							.configuration[0]
																							.currency_symbol_html
																					)
																				}
																			/>
																		) : null}
																	</Descriptions.Item>
																</Descriptions>
															</div>
														</div>
													</div>
													<div className="row m-5">
														<div className="client-selected-parts">
															<table className="table table-striped table-responsive-md">
																<thead className="thead-dark">
																	<tr>
																		<th
																			scope="col"
																			className="min-width-table"
																		>
																			Model Number
														</th>
																		<th
																			scope="col"
																			className="min-width-table"
																		>
																			Description
														</th>
																		<th
																			scope="col"
																			className="min-width-table"
																		>
																			Price
														</th>
																		<th
																			scope="col"
																			className="min-width-table"
																		>
																			Quantity
														</th>
																		<th
																			scope="col"
																			className="min-width-table"
																		>
																			Total Price
														</th>
																	</tr>
																	<tr
																		style={{
																			backgroundColor:
																				"#B9373D",
																			color: "#ffffff"
																		}}
																	>
																		<td
																			style={{
																				color: "white",
																				fontWeight: 900,
																				fontSize: 15
																			}}
																		>
																			Components
														</td>
																		<td></td>
																		<td></td>
																		<td></td>
																		<td></td>
																	</tr>
																</thead>
																<tbody>
																	<tr>
																		<td className="min-width-table">
																			{this.props
																				.category_details
																				? this.props
																					.category_details[0]
																					.machine_category_model_number
																				: null}
																		</td>
																		<td className="min-width-table">
																			{this.props
																				.category_details
																				? this.props
																					.category_details[0]
																					.machine_category
																				: null}
																		</td>
																		<td className="money min-width-table">
																			{getSymbolFromCurrency(
																				this.props
																					.category_details[0]
																					.currency_symbol_html
																			)}{" "}
																			{this.props
																				.category_details
																				? parseFloat(
																					this.props
																						.category_details[0]
																						.price
																				).toFixed(2)
																				: null}
																		</td>
																		<td>{this.props.configuration ?
																			this.props.configuration[0].only_parts ? 0 : 1 : 1}</td>
																		<td className="money">
																			{getSymbolFromCurrency(
																				this.props
																					.category_details[0]
																					.currency_symbol_html
																			)}{" "}
																			{
																				this.props.category_details ?
																					this.props.configuration ?
																						this.props.configuration[0].only_parts ? 0 : parseFloat(this.props.category_details[0].price).toFixed(2) : parseFloat(this.props.category_details[0].price).toFixed(2)
																					: null
																			}
																			{/* {this.props
																				.category_details
																				? parseFloat(
																					this.props
																						.category_details[0]
																						.price
																				)
																				: null} */}
																		</td>
																	</tr>
																	<tr
																		style={{
																			backgroundColor:
																				"#B9373D",
																			color: "#ffffff"
																		}}
																	>
																		<td
																			style={{
																				color: "white",
																				fontWeight: 900,
																				fontSize: 15
																			}}
																		>
																			Parts
														</td>
																		<td></td>
																		<td></td>
																		<td></td>
																		<td></td>
																	</tr>
																	{this.props.parts !== []
																		? this.props.parts.map(
																			(part, i) => (
																				<tr key={i}>
																					<td className="min-width-table">
																						{
																							part.machine_part_model_number
																						}
																					</td>
																					<td className="min-width-table">
																						{
																							part.machine_part_name
																						}
																					</td>
																					{part.is_included ? (
																						<>
																							<td className="min-width-table">
																								Included
																				</td>
																							<td className="min-width-table">
																								{
																									part.quantity
																								}
																							</td>
																							<td className="min-width-table">
																								Included
																				</td>
																						</>
																					) : (
																						<>
																							<td className="money min-width-table">
																								{getSymbolFromCurrency(
																									part.currency_symbol_html
																								)}{" "}
																								{part.price.toFixed(
																									2
																								)}
																							</td>
																							<td className="min-width-table">
																								{
																									part.quantity
																								}
																							</td>
																							<td className="money min-width-table">
																								{getSymbolFromCurrency(
																									part.currency_symbol_html
																								)}{" "}
																								{parseFloat(
																									part.quantity *
																									part.price
																								).toFixed(
																									2
																								)}
																							</td>
																						</>
																					)}
																				</tr>
																			)
																		)
																		: null}
																</tbody>
															</table>

															<table className="table table-striped table-responsive-md mt-5">
																<tbody>
																	{this.props
																		.manual_adjustments
																		.length > 0 ? (
																		<>
																			<tr
																				style={{
																					backgroundColor:
																						"#B9373D",
																					color:
																						"#ffffff"
																				}}
																			>
																				<td
																					style={{
																						color:
																							"white",
																						fontWeight: 900,
																						fontSize: 15
																					}}
																				>
																					Manual
																					Adjustments
																</td>
																				<td></td>
																				<td></td>
																				<td></td>
																				<td></td>
																				<td></td>
																			</tr>
																			<tr className="thead-dark">
																				<th scope="col">
																					Model Number
																</th>
																				<th scope="col">
																					Description
																</th>
																				<th scope="col">
																					Note
																</th>
																				<th scope="col">
																					Price
																</th>
																				<th scope="col">
																					Quantity
																</th>
																				<th scope="col">
																					Total Price
																</th>
																			</tr>
																		</>
																	) : null}
																	{this.props
																		.manual_adjustments !==
																		null &&
																		this.props
																			.manual_adjustments !==
																		[]
																		? this.props.manual_adjustments.map(
																			(
																				adjustment,
																				i
																			) => (
																				<tr key={i}>
																					<td className="min-width-table">
																						{" "}
																						{
																							adjustment.manual_adjustment_model_number
																						}
																					</td>
																					<td className="min-width-table">
																						{
																							adjustment.manual_adjustment_name
																						}
																					</td>
																					<td className="min-width-table">
																						{
																							adjustment.manual_adjustment_note
																						}
																					</td>
																					<td className="money min-width-table">
																						{getSymbolFromCurrency(
																							adjustment.currency_symbol_html
																						)}{" "}
																						{parseFloat(
																							adjustment.manual_adjustment_price
																						)}
																					</td>
																					<td>
																						<div className="client-counter row">
																							{" "}
																							{
																								adjustment.manual_adjustment_quantity
																							}
																						</div>
																					</td>
																					<td className="money">
																						{getSymbolFromCurrency(
																							adjustment.currency_symbol_html
																						)}
																						{(
																							parseFloat(
																								adjustment.manual_adjustment_quantity
																							) *
																							parseFloat(
																								adjustment.manual_adjustment_price
																							)
																						).toFixed(
																							2
																						)}
																					</td>
																				</tr>
																			)
																		)
																		: null}
																</tbody>
															</table>
														</div>
													</div>
												</div>
											) : (
												<div
													className="modal-body"
													style={{
														justifyContent: "center"
													}}
												>
													<div
														className="row my-5"
														style={{
															justifyContent: "center",
															flexDirection: "row"
														}}
													>
														{this.props.configuration_message.includes(
															"Getting single user configuration faliure"
														) ? (
															<strong>
																There is something wrong with
																the configuration please
																contact the administrator.
																Then we will try to fix it.
															</strong>
														) : (
															<Loader
																type="Rings"
																color="#00BFFF"
																height={100}
																width={100}
															/>
														)}
													</div>
												</div>
											)
											}
										</TabPane>
										{this.state.log_configuration_data.map(
											(configuration, i) => (
												<TabPane
													tab={
														configuration.log_configuration
															.name +
														" - " +
														configuration.log_configuration
															.log_configuration_id
													}
													key={i + 1}
												>
													<div className="container">
														<div className="row m-5">
															<div className="col-sm-4">
																{configuration
																	.log_configuration
																	.machine_category_image !==
																	[] &&
																	configuration
																		.log_configuration
																		.machine_category_image !==
																	null ? (
																	<img loading="lazy"
																		width="100%"
																		className="img-responsive"
																		alt=""
																		src={
																			config.APP_URL +
																			"/api/storage/" +
																			configuration
																				.log_configuration
																				.machine_category_image
																		}
																	/>
																) : (
																	<img loading="lazy"
																		width="100%"
																		className="img-responsive"
																		alt=""
																		src={noimage}
																	/>
																)}
															</div>
															<div className="col-sm-8">
																<div className="client-config-details ml-5 mr-5 mb-5">
																	{configuration.log_configuration !==
																		[] ? (
																		<Descriptions
																			title=""
																			layout="hotizontal"
																			bordered
																			size="small"
																			column={1}
																		>
																			<Descriptions.Item label="Configuration Name">
																				{configuration.log_configuration !==
																					[]
																					? configuration
																						.log_configuration
																						.name
																					: ""}
																			</Descriptions.Item>
																			<Descriptions.Item label="Product Category">
																				{configuration.log_configuration !==
																					[]
																					? configuration
																						.log_configuration
																						.machine_category
																					: ""}
																			</Descriptions.Item>
																			<Descriptions.Item label="Retail Price">
																				{configuration.log_configuration !==
																					[]
																					? parseFloat(
																						configuration
																							.log_configuration
																							.machine_total_price /
																						configuration
																							.log_configuration
																							.quantity
																					).toFixed(2)
																					: ""}{" "}
																				{getSymbolFromCurrency(
																					configuration
																						.log_configuration
																						.currency_symbol_html
																				)}
																			</Descriptions.Item>
																			{configuration.log_configuration !==
																				[] &&
																				configuration
																					.log_configuration
																					.discount_percentage !==
																				0 ? (
																				this.props
																					.is_dealer ? (
																					<Descriptions.Item label="Standard Dealer Terms %">
																						{
																							configuration
																								.log_configuration
																								.discount_percentage
																						}{" "}
																		%
																					</Descriptions.Item>
																				) : (
																					<>
																						<Descriptions.Item label="Discount Percentage">
																							{
																								configuration
																									.log_configuration
																									.discount_percentage
																							}{" "}
																			%
																		</Descriptions.Item>

																						<Descriptions.Item label="Discount Percentage Amount">
																							<CurrencyFormat
																								className="py-1 pr-2 text-right"
																								displayType={
																									"text"
																								}
																								decimalSeparator={
																									"."
																								}
																								decimalScale={
																									2
																								}
																								fixedDecimalScale={
																									true
																								}
																								value={parseFloat(
																									((configuration
																										.log_configuration
																										.machine_total_price /
																										configuration
																											.log_configuration
																											.quantity) *
																										configuration
																											.log_configuration
																											.discount_percentage) /
																									100
																								).toFixed(
																									2
																								)}
																								thousandSeparator={
																									true
																								}
																								suffix={
																									" " +
																									getSymbolFromCurrency(
																										configuration.log_configuration.currency_symbol_html
																									)
																								}
																							/>
																						</Descriptions.Item>
																					</>
																				)
																			) : null}
																			{configuration.log_configuration !==
																				[] &&
																				configuration
																					.log_configuration
																					.discount_amount !==
																				0 ? (
																				this.props
																					.is_dealer ? (
																					<Descriptions.Item label="Dealer Discount Amount">
																						{
																							configuration
																								.log_configuration
																								.discount_amount
																						}{" "}
																						{getSymbolFromCurrency(
																							configuration
																								.log_configuration
																								.currency_symbol_html
																						)}
																					</Descriptions.Item>
																				) : (
																					<Descriptions.Item label="Discount Amount">
																						{
																							configuration
																								.log_configuration
																								.discount_amount
																						}{" "}
																						{getSymbolFromCurrency(
																							configuration
																								.log_configuration
																								.currency_symbol_html
																						)}
																					</Descriptions.Item>
																				)
																			) : null}

																			{configuration.log_configuration !==
																				[] &&
																				configuration
																					.log_configuration
																					.dealer_discount_amount !==
																				0 ? (
																				this.props
																					.extra_discount_visible ? (
																					<Descriptions.Item label="Extra Discount">
																						{
																							configuration
																								.log_configuration
																								.dealer_discount_amount
																						}{" "}
																						{getSymbolFromCurrency(
																							configuration
																								.log_configuration
																								.currency_symbol_html
																						)}
																					</Descriptions.Item>
																				) : null
																			) : null}
																			{this.props
																				.is_dealer ? (
																				<Descriptions.Item label="Dealer Net Total">
																					{configuration.log_configuration !==
																						[] ? (
																						<CurrencyFormat
																							className="py-1 pr-2 text-right"
																							displayType={
																								"text"
																							}
																							decimalSeparator={
																								"."
																							}
																							decimalScale={
																								2
																							}
																							fixedDecimalScale={
																								true
																							}
																							value={parseFloat(
																								configuration
																									.log_configuration
																									.machine_total_price /
																								configuration
																									.log_configuration
																									.quantity -
																								(configuration
																									.log_configuration
																									.discount_percentage *
																									(configuration
																										.log_configuration
																										.machine_total_price /
																										configuration
																											.log_configuration
																											.quantity)) /
																								100 -
																								configuration
																									.log_configuration
																									.discount_amount -
																								configuration
																									.log_configuration
																									.dealer_discount_amount
																							)}
																							thousandSeparator={
																								true
																							}
																							suffix={
																								" " +
																								getSymbolFromCurrency(
																									configuration
																										.log_configuration
																										.currency_symbol_html
																								)
																							}
																						/>
																					) : null}
																				</Descriptions.Item>
																			) : (
																				<Descriptions.Item label="Net Total">
																					{configuration.log_configuration !==
																						[] ? (
																						<CurrencyFormat
																							className="py-1 pr-2 text-right"
																							displayType={
																								"text"
																							}
																							decimalSeparator={
																								"."
																							}
																							decimalScale={
																								2
																							}
																							fixedDecimalScale={
																								true
																							}
																							value={parseFloat(
																								configuration
																									.log_configuration
																									.machine_total_price /
																								configuration
																									.log_configuration
																									.quantity -
																								(configuration
																									.log_configuration
																									.discount_percentage *
																									(configuration
																										.log_configuration
																										.machine_total_price /
																										configuration
																											.log_configuration
																											.quantity)) /
																								100 -
																								configuration
																									.log_configuration
																									.discount_amount -
																								configuration
																									.log_configuration
																									.dealer_discount_amount
																							)}
																							thousandSeparator={
																								true
																							}
																							suffix={
																								" " +
																								getSymbolFromCurrency(
																									configuration
																										.log_configuration
																										.currency_symbol_html
																								)
																							}
																						/>
																					) : null}
																				</Descriptions.Item>
																			)}

																			{this.props
																				.is_dealer ? (
																				<>
																					<Descriptions.Item label="OEM Programme">
																						{
																							configuration
																								.log_configuration
																								.oem_discount
																						}
																						{" " +
																							getSymbolFromCurrency(
																								configuration
																									.log_configuration
																									.currency_symbol_html
																							)}
																					</Descriptions.Item>
																					<Descriptions.Item label="Net Net Purchase Price">
																						<CurrencyFormat
																							className="py-1 pr-2 text-right"
																							displayType={
																								"text"
																							}
																							decimalSeparator={
																								"."
																							}
																							decimalScale={
																								2
																							}
																							fixedDecimalScale={
																								true
																							}
																							value={parseFloat(
																								configuration
																									.log_configuration
																									.machine_total_price /
																								configuration
																									.log_configuration
																									.quantity -
																								(configuration
																									.log_configuration
																									.discount_percentage *
																									(configuration
																										.log_configuration
																										.machine_total_price /
																										configuration
																											.log_configuration
																											.quantity)) /
																								100 -
																								configuration
																									.log_configuration
																									.discount_amount -
																								configuration
																									.log_configuration
																									.dealer_discount_amount -
																								configuration
																									.log_configuration
																									.oem_discount
																							).toFixed(
																								2
																							)}
																							thousandSeparator={
																								true
																							}
																							suffix={
																								" " +
																								getSymbolFromCurrency(
																									configuration
																										.log_configuration
																										.currency_symbol_html
																								)
																							}
																						/>
																					</Descriptions.Item>
																				</>
																			) : null}
																			{this.props
																				.is_dealer ? (
																				<>
																					<Descriptions.Item label="Extra Discount">
																						{
																							configuration
																								.log_configuration
																								.extra_discount
																						}
																						{" %"}
																					</Descriptions.Item>
																					<Descriptions.Item label="Reesink Extra Extra Support">
																						<CurrencyFormat
																							className="py-1 pr-2 text-right"
																							displayType={
																								"text"
																							}
																							decimalSeparator={
																								"."
																							}
																							decimalScale={
																								2
																							}
																							fixedDecimalScale={
																								true
																							}
																							value={parseFloat(
																								(configuration
																									.log_configuration
																									.extra_discount *
																									parseFloat(
																										configuration
																											.log_configuration
																											.machine_total_price /
																										configuration
																											.log_configuration
																											.quantity -
																										(configuration
																											.log_configuration
																											.discount_percentage *
																											(configuration
																												.log_configuration
																												.machine_total_price /
																												configuration
																													.log_configuration
																													.quantity)) /
																										100 -
																										configuration
																											.log_configuration
																											.discount_amount -
																										configuration
																											.log_configuration
																											.dealer_discount_amount -
																										configuration
																											.log_configuration
																											.oem_discount
																									).toFixed(
																										2
																									)) /
																								100
																							).toFixed(
																								2
																							)}
																							thousandSeparator={
																								true
																							}
																							suffix={
																								" " +
																								getSymbolFromCurrency(
																									configuration
																										.log_configuration
																										.currency_symbol_html
																								)
																							}
																						/>
																					</Descriptions.Item>
																				</>
																			) : null}
																			{this.props
																				.is_dealer ? (
																				<Descriptions.Item label="Net Net Net Price">
																					<CurrencyFormat
																						className="py-1 pr-2 text-right"
																						displayType={
																							"text"
																						}
																						decimalSeparator={
																							"."
																						}
																						decimalScale={
																							2
																						}
																						fixedDecimalScale={
																							true
																						}
																						value={parseFloat(
																							parseFloat(
																								configuration
																									.log_configuration
																									.machine_total_price /
																								configuration
																									.log_configuration
																									.quantity -
																								(configuration
																									.log_configuration
																									.discount_percentage *
																									(configuration
																										.log_configuration
																										.machine_total_price /
																										configuration
																											.log_configuration
																											.quantity)) /
																								100 -
																								configuration
																									.log_configuration
																									.discount_amount -
																								configuration
																									.log_configuration
																									.dealer_discount_amount -
																								configuration
																									.log_configuration
																									.oem_discount
																							).toFixed(
																								2
																							) -
																							parseFloat(
																								(configuration
																									.log_configuration
																									.extra_discount *
																									parseFloat(
																										configuration
																											.log_configuration
																											.machine_total_price /
																										configuration
																											.log_configuration
																											.quantity -
																										(configuration
																											.log_configuration
																											.discount_percentage *
																											(configuration
																												.log_configuration
																												.machine_total_price /
																												configuration
																													.log_configuration
																													.quantity)) /
																										100 -
																										configuration
																											.log_configuration
																											.discount_amount -
																										configuration
																											.log_configuration
																											.dealer_discount_amount -
																										configuration
																											.log_configuration
																											.oem_discount
																									).toFixed(
																										2
																									)) /
																								100
																							).toFixed(
																								2
																							)
																						).toFixed(
																							2
																						)}
																						thousandSeparator={
																							true
																						}
																						suffix={
																							" " +
																							getSymbolFromCurrency(
																								configuration
																									.log_configuration
																									.currency_symbol_html
																							)
																						}
																					/>
																				</Descriptions.Item>
																			) : null
																			}
																			{configuration.log_configuration !==
																				[] &&
																				configuration
																					.log_configuration
																					.sales_margin !==
																				0 ? (
																				this.props
																					.sales_margin_visible ? (
																					this.props
																						.is_dealer ? (
																						<>
																							<Descriptions.Item label="End Customer Purchase Price">
																								{parseFloat(
																									configuration
																										.log_configuration
																										.end_user_sales_price
																								).toFixed(
																									2
																								)}{" "}
																								{getSymbolFromCurrency(
																									configuration
																										.log_configuration
																										.currency_symbol_html
																								)}
																							</Descriptions.Item>
																							<Descriptions.Item label="Dealer Margin Amount">
																								<CurrencyFormat
																									className="py-1 pr-2 text-right"
																									displayType={
																										"text"
																									}
																									decimalSeparator={
																										"."
																									}
																									decimalScale={
																										2
																									}
																									fixedDecimalScale={
																										true
																									}
																									value={
																										configuration
																											.log_configuration
																											.sales_margin_amount
																									}
																									thousandSeparator={
																										true
																									}
																									suffix={
																										" " +
																										getSymbolFromCurrency(
																											configuration
																												.log_configuration
																												.currency_symbol_html
																										)
																									}
																								/>
																							</Descriptions.Item>
																							<Descriptions.Item label="Dealer Margin">
																								{
																									configuration
																										.log_configuration
																										.sales_margin
																								}
																								{
																									" %"
																								}
																							</Descriptions.Item>
																						</>
																					) : (
																						<>
																							<Descriptions.Item label="Sales Margin">
																								{
																									configuration
																										.log_configuration
																										.sales_margin
																								}
																								{
																									" %"
																								}
																							</Descriptions.Item>
																							<Descriptions.Item label="Total Invoice for customer">
																								{parseFloat(
																									configuration
																										.log_configuration
																										.end_user_sales_price
																								).toFixed(
																									2
																								)}{" "}
																								{getSymbolFromCurrency(
																									configuration
																										.log_configuration
																										.currency_symbol_html
																								)}
																							</Descriptions.Item>
																						</>
																					)
																				) : null
																			) : null}
																			{this.props
																				.customer_discount_visible &&
																				!this.props
																					.is_dealer ? (
																				<Descriptions.Item label="Customer Discount">
																					{parseFloat(
																						(Math.abs(
																							configuration
																								.log_configuration
																								.machine_total_price -
																							parseFloat(
																								configuration
																									.log_configuration
																									.machine_total_price -
																								(configuration
																									.log_configuration
																									.machine_total_price *
																									configuration
																										.log_configuration
																										.discount_percentage) /
																								100 -
																								configuration
																									.log_configuration
																									.discount_amount -
																								configuration
																									.log_configuration
																									.dealer_discount_amount -
																								configuration
																									.log_configuration
																									.oem_discount -
																								parseFloat(
																									(configuration
																										.log_configuration
																										.extra_discount *
																										parseFloat(
																											configuration
																												.log_configuration
																												.machine_total_price /
																											configuration
																												.log_configuration
																												.quantity -
																											(configuration
																												.log_configuration
																												.discount_percentage *
																												(configuration
																													.log_configuration
																													.machine_total_price /
																													configuration
																														.log_configuration
																														.quantity)) /
																											100 -
																											configuration
																												.log_configuration
																												.discount_amount -
																											configuration
																												.log_configuration
																												.dealer_discount_amount -
																											configuration
																												.log_configuration
																												.oem_discount
																										).toFixed(
																											2
																										)) /
																									100
																								).toFixed(
																									2
																								) +
																								(configuration
																									.log_configuration
																									.sales_margin *
																									(configuration
																										.log_configuration
																										.machine_total_price -
																										(configuration
																											.log_configuration
																											.machine_total_price *
																											configuration
																												.log_configuration
																												.discount_percentage) /
																										100 -
																										configuration
																											.log_configuration
																											.discount_amount -
																										configuration
																											.log_configuration
																											.dealer_discount_amount -
																										configuration
																											.log_configuration
																											.oem_discount -
																										parseFloat(
																											(configuration
																												.log_configuration
																												.extra_discount *
																												parseFloat(
																													configuration
																														.log_configuration
																														.machine_total_price /
																													configuration
																														.log_configuration
																														.quantity -
																													(configuration
																														.log_configuration
																														.discount_percentage *
																														(configuration
																															.log_configuration
																															.machine_total_price /
																															configuration
																																.log_configuration
																																.quantity)) /
																													100 -
																													configuration
																														.log_configuration
																														.discount_amount -
																													configuration
																														.log_configuration
																														.dealer_discount_amount -
																													configuration
																														.log_configuration
																														.oem_discount
																												).toFixed(
																													2
																												)) /
																											100
																										).toFixed(
																											2
																										))) /
																								100
																							).toFixed(
																								2
																							)
																						) /
																							configuration
																								.log_configuration
																								.machine_total_price) *
																						100
																					).toFixed(2)}
																					{" %"}
																				</Descriptions.Item>
																			) : null}
																			{configuration.log_configuration !==
																				[] &&
																				configuration
																					.log_configuration
																					.quantity !==
																				0 ? (
																				<Descriptions.Item label="Configuration Quantity">
																					{
																						configuration
																							.log_configuration
																							.quantity
																					}
																				</Descriptions.Item>
																			) : null}
																			<Descriptions.Item label="Final Net total">
																				{configuration.log_configuration !==
																					[] ? (
																					<CurrencyFormat
																						className="py-1 pr-2 text-right"
																						displayType={
																							"text"
																						}
																						decimalSeparator={
																							"."
																						}
																						decimalScale={
																							2
																						}
																						fixedDecimalScale={
																							true
																						}
																						value={
																							parseFloat(
																								configuration
																									.log_configuration
																									.machine_total_price /
																								configuration
																									.log_configuration
																									.quantity -
																								(configuration
																									.log_configuration
																									.discount_percentage *
																									(configuration
																										.log_configuration
																										.machine_total_price /
																										configuration
																											.log_configuration
																											.quantity)) /
																								100 -
																								configuration
																									.log_configuration
																									.discount_amount -
																								configuration
																									.log_configuration
																									.dealer_discount_amount -
																								configuration
																									.log_configuration
																									.oem_discount -
																								parseFloat(
																									(configuration
																										.log_configuration
																										.extra_discount *
																										parseFloat(
																											configuration
																												.log_configuration
																												.machine_total_price /
																											configuration
																												.log_configuration
																												.quantity -
																											(configuration
																												.log_configuration
																												.discount_percentage *
																												(configuration
																													.log_configuration
																													.machine_total_price /
																													configuration
																														.log_configuration
																														.quantity)) /
																											100 -
																											configuration
																												.log_configuration
																												.discount_amount -
																											configuration
																												.log_configuration
																												.dealer_discount_amount -
																											configuration
																												.log_configuration
																												.oem_discount
																										).toFixed(
																											2
																										)) /
																									100
																								).toFixed(
																									2
																								)
																							) *
																							configuration
																								.log_configuration
																								.quantity
																						}
																						thousandSeparator={
																							true
																						}
																						suffix={
																							" " +
																							getSymbolFromCurrency(
																								configuration
																									.log_configuration
																									.currency_symbol_html
																							)
																						}
																					/>
																				) : null}
																			</Descriptions.Item>
																		</Descriptions>
																	) : null}
																</div>
															</div>
														</div>
														<div className="row m-5">
															<div className="client-selected-parts">
																<table className="table table-striped table-responsive-md">
																	<thead className="thead-dark">
																		<tr>
																			<th
																				scope="col"
																				className="min-width-table"
																			>
																				Model Number
															</th>
																			<th
																				scope="col"
																				className="min-width-table"
																			>
																				Description
															</th>
																			<th
																				scope="col"
																				className="min-width-table"
																			>
																				Price
															</th>
																			<th
																				scope="col"
																				className="min-width-table"
																			>
																				Quantity
															</th>
																			<th
																				scope="col"
																				className="min-width-table"
																			>
																				Total Price
															</th>
																		</tr>
																		<tr
																			style={{
																				backgroundColor:
																					"#B9373D",
																				color: "#ffffff"
																			}}
																		>
																			<td
																				style={{
																					color:
																						"white",
																					fontWeight: 900,
																					fontSize: 15
																				}}
																			>
																				Components
															</td>
																			<td></td>
																			<td></td>
																			<td></td>
																			<td></td>
																		</tr>
																	</thead>
																	<tbody>
																		<tr>
																			<td className="min-width-table">
																				{configuration.log_configuration
																					? configuration
																						.log_configuration
																						.machine_category
																					: null}
																			</td>
																			<td className="min-width-table"></td>
																			<td className="money min-width-table">
																				{getSymbolFromCurrency(
																					configuration
																						.log_configuration
																						.currency_symbol_html
																				)}{" "}
																				{configuration.log_configuration
																					? parseFloat(
																						configuration
																							.log_configuration
																							.price
																					).toFixed(2)
																					: null}
																			</td>
																			<td>{configuration.log_configuration ?
																				configuration.log_configuration.only_parts ? 0 : 1 : 1}</td>
																			<td className="money">
																				{getSymbolFromCurrency(
																					configuration
																						.log_configuration
																						.currency_symbol_html
																				)}{" "}
																				{
																					configuration.log_configuration ?
																						configuration.log_configuration.only_parts ? 0 : parseFloat(configuration.log_configuration.price).toFixed(2) : parseFloat(configuration.log_configuration.price).toFixed(2)
																				}
																				{/* {configuration.log_configuration
																					? parseFloat(
																						configuration
																							.log_configuration
																							.price
																					)
																					: null} */}
																			</td>
																		</tr>
																		<tr
																			style={{
																				backgroundColor:
																					"#B9373D",
																				color: "#ffffff"
																			}}
																		>
																			<td
																				style={{
																					color:
																						"white",
																					fontWeight: 900,
																					fontSize: 15
																				}}
																			>
																				Parts
															</td>
																			<td></td>
																			<td></td>
																			<td></td>
																			<td></td>
																		</tr>
																		{configuration.parts !==
																			[]
																			? configuration.parts.map(
																				(part, i) => (
																					<tr
																						key={i}
																					>
																						<td className="min-width-table">
																							{
																								part.machine_part_model_number
																							}
																						</td>
																						<td className="min-width-table">
																							{
																								part.machine_part_name
																							}
																						</td>
																						{part.is_included ? (
																							<>
																								<td className="min-width-table">
																									Included
																					</td>
																								<td className="min-width-table">
																									{
																										part.quantity
																									}
																								</td>
																								<td className="min-width-table">
																									Included
																					</td>
																							</>
																						) : (
																							<>
																								<td className="money min-width-table">
																									{getSymbolFromCurrency(
																										part.currency_symbol_html
																									)}{" "}
																									{part.price.toFixed(
																										2
																									)}
																								</td>
																								<td className="min-width-table">
																									{" "}
																									{
																										part.quantity
																									}
																								</td>
																								<td className="money min-width-table">
																									{getSymbolFromCurrency(
																										part.currency_symbol_html
																									)}{" "}
																									{parseFloat(
																										part.quantity *
																										part.price
																									).toFixed(
																										2
																									)}
																								</td>
																							</>
																						)}
																					</tr>
																				)
																			)
																			: null}
																	</tbody>
																</table>

																<table className="table table-striped table-responsive-md mt-5">
																	<tbody>
																		{configuration
																			.manual_adjustments
																			.length > 0 ? (
																			<>
																				<tr
																					style={{
																						backgroundColor:
																							"#B9373D",
																						color:
																							"#ffffff"
																					}}
																				>
																					<td
																						style={{
																							color:
																								"white",
																							fontWeight: 900,
																							fontSize: 15
																						}}
																					>
																						Manual
																						Adjustments
																	</td>
																					<td></td>
																					<td></td>
																					<td></td>
																					<td></td>
																					<td></td>
																				</tr>
																				<tr className="thead-dark">
																					<th scope="col">
																						Model
																						Number
																	</th>
																					<th scope="col">
																						Description
																	</th>
																					<th scope="col">
																						Note
																	</th>
																					<th scope="col">
																						Price
																	</th>
																					<th scope="col">
																						Quantity
																	</th>
																					<th scope="col">
																						Total Price
																	</th>
																				</tr>
																			</>
																		) : null}
																		{configuration.manual_adjustments !==
																			null &&
																			configuration.manual_adjustments !==
																			[]
																			? configuration.manual_adjustments.map(
																				(
																					adjustment,
																					i
																				) => (
																					<tr
																						key={i}
																					>
																						<td className="min-width-table">
																							{" "}
																							{
																								adjustment.manual_adjustment_model_number
																							}
																						</td>
																						<td className="min-width-table">
																							{
																								adjustment.manual_adjustment_name
																							}
																						</td>
																						<td className="min-width-table">
																							{
																								adjustment.manual_adjustment_note
																							}
																						</td>
																						<td className="money min-width-table">
																							{getSymbolFromCurrency(
																								adjustment.currency_symbol_html
																							)}{" "}
																							{parseFloat(
																								adjustment.manual_adjustment_price
																							)}
																						</td>
																						<td>
																							<div className="client-counter row">
																								{" "}
																								{
																									adjustment.manual_adjustment_quantity
																								}
																							</div>
																						</td>
																						<td className="money">
																							{getSymbolFromCurrency(
																								adjustment.currency_symbol_html
																							)}
																							{(
																								parseFloat(
																									adjustment.manual_adjustment_quantity
																								) *
																								parseFloat(
																									adjustment.manual_adjustment_price
																								)
																							).toFixed(
																								2
																							)}
																						</td>
																					</tr>
																				)
																			)
																			: null}
																	</tbody>
																</table>
															</div>
														</div>
													</div>
												</TabPane>
											)
										)}
									</Tabs>
								</div>
							) : (
								<div
									className="modal-body"
									style={{
										justifyContent: "center"
									}}
								>
									<div
										className="row my-5"
										style={{
											justifyContent: "center",
											flexDirection: "row"
										}}
									>
										{this.props.configuration_message.includes(
											"Getting single user configuration faliure"
										) ? (
											<strong>
												Either there is something wrong with the configuration (can be deleted by the user) or you don't have proper authority to access this configuration, please contact the administrator. Then we will try to fix it.
											</strong>
										) : (
											<Loader
												type="Rings"
												color="#00BFFF"
												height={100}
												width={100}
											/>
										)}
									</div>
								</div>
							)}
						</Modal>
					</div>
				);
			} else {
				return (
					<div className="container my-5 text-center">
						<strong>
							Either there is something wrong with the order (can be deleted by the user) or you don't have proper authority to access this order, please contact the administrator. Then we will try to fix it.
						</strong>
					</div>
				)
			}
		}
	}
}
const mapStateToProps = state => {
	return {
		user_id: state.loginReducer.user.user.id,
		role: state.loginReducer.user.role,
		orderLoading: state.clientOrderReducer.loading,
		order: state.clientOrderReducer.order,
		configurationLoading:
			state.clientConfigurationReducer.loading,
		configuration:
			state.clientConfigurationReducer.configuration.configuration,
		category_details: state.clientConfigurationReducer.category_details,
		parts: state.clientConfigurationReducer.configuration.parts,
		manual_adjustments:
			state.clientConfigurationReducer.configuration.manual_adjustments,
		loginLoading: state.loginReducer.loading,
		language: state.languageReducer.selectedLanguage,
		configuration_loading: state.clientConfigurationReducer.loading,
		configuration_message: state.clientConfigurationReducer.message,
		country: state.loginReducer.user.country,
		is_support: state.loginReducer.user.is_support,
		order_form_visibility: state.loginReducer.user.order_form_visibility,
		is_dealer: state.loginReducer.user.is_dealer,
		sales_margin_visible: state.loginReducer.user.sales_margin_visible,
		customer_discount_visible:
			state.loginReducer.user.customer_discount_visible,
		extra_discount_visible: state.loginReducer.user.extra_discount_visible,
		extra_discount_visible_and_editable:
			state.loginReducer.user.extra_discount_visible_and_editable,
		oem_editable: state.loginReducer.user.oem_editable,
		extra_extra_discount_editable:
			state.loginReducer.user.extra_extra_discount_editable,
		cancel_order: state.loginReducer.user.cancel_order,

		log_visibility: state.loginReducer.user.log_visibility
	};
};

export default connect(mapStateToProps, {
	show_single_order,
	show_single_user_configuration
})(MyDetailedOrder);
