import React, { Component } from "react";
import { SpreadsheetService } from "./../../spreadsheet/service";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { notification } from "antd";

export default class UploadMachineCategoryWithParts extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			file: null
		};
		this.fileHandler = this.fileHandler.bind(this);
		this.onSelectValue = this.onSelectValue.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	onSelectValue(event) {
		this.setState({ type: event });
	}

	async componentDidMount() {
		let redirect = this;
		let data = await SpreadsheetService.getTypes();
		redirect.setState({ type: data[2] });
		redirect.setState({ types: data });
		redirect.setState({ loading: false });
	}

	async onSubmit(e) {
		e.preventDefault();
		let redirect = this;
		redirect.setState({ loading: true });
		let fd = new FormData();
		fd.append("file", redirect.state.file);
		fd.append("type", redirect.state.type);

		let data = await SpreadsheetService.uploadPartsWithCategory(fd);

		if (data.message !== undefined) {
			if (
				data.message
					.toLowerCase()
					.includes("configuration excel sheet uploaded successfully")
			) {
				notification.success({
					message: "Success!",
					description: data.message
				});
			} else {
				notification.error({
					message: "Error!",
					description: data.message
				});
			}
		} else {
			notification.error({
				message: "Error!",
				description: "File not uploaded."
			});
		}
		redirect.setState({ loading: false });
	}

	fileHandler(name, event) {
		switch (name) {
			case "file":
				this.setState({ file: event.target.files[0] });
				break;

			default:
				break;
		}
	}

	render() {
		if (this.state.loading) {
			return (
				<div>
					<div
						className="content"
						style={{
							minHeight: 500
						}}
					>
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-header">
										<strong className="card-title">
											Upload Machine Category with compatible parts
										</strong>
									</div>
									<div className="card-body">
										<div className="card-content">
											<div className="todo-list">
												<div className="tdl-holder">
													<div className="tdl-content">
														<div className="row">
															<div className="col"></div>
															<div className="col">
																<div className="text-center">
																	<Loader
																		type="Rings"
																		color="#00BFFF"
																		height={100}
																		width={100}
																	/>
																	<div className="text-center client-roboto-condensed">
																		LOADING...
																	</div>
																</div>
															</div>
															<div className="col"></div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div>
					<div
						className="content"
						style={{
							minHeight: 500
						}}
					>
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-header">
										<strong className="card-title">
											Upload Machine Category with compatible parts
										</strong>
									</div>
									<div className="card-body">
										<div className="card-content">
											<div className="todo-list">
												<div className="tdl-holder">
													<div className="tdl-content">
														<div className="row">
															<div className="col"></div>
															<div className="col"></div>
															<div className="col">
																<div className="form-group">
																	<input
																		className="from-control"
																		type="file"
																		onChange={e =>
																			this.fileHandler(
																				"file",
																				e
																			)
																		}
																		accept=".xlsx"
																	/>
																</div>
															</div>
															<div className="col"></div>
															<div className="col"></div>
														</div>
														<div className="row">
															<div className="col"></div>
															<div className="col"></div>
															<div className="col">
																<button
																	onClick={this.onSubmit}
																	className="btn btn-rounded btn-secondary text-center"
																>
																	Upload Category with Parts
																</button>
															</div>
															<div className="col"></div>
															<div className="col"></div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}
}
