import React, { Component } from "react";
import { connect } from "react-redux";
import { FeatureSetService } from "./../service";
import { edit_feature } from "./../action";
import { Redirect } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Api } from "./../../../config/api";

class UpdateFeature extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      feature_title: "",
      feature_description: "",
      compatible_machine_categories: false
    };
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.onTextChange = this.onTextChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onTextChange(name, value) {
    let redirect = this;
    switch (name) {
      case "feature_title":
        redirect.setState({ feature_title: value });
        break;

      case "feature_description":
        redirect.setState({ feature_description: value });
        break;

      default:
        break;
    }
  }

  onSubmit(e) {
    let { id } = this.props.match.params;
    let redirect = this;
    e.preventDefault();

    if (this.validator.allValid()) {
      let params = {
        feature_id: id,
        feature_title: redirect.state.feature_title,
        feature_description: redirect.state.feature_description
      };

      this.props.edit_feature(params);
    } else {
      this.validator.showMessages();
    }
  }

  async componentDidMount() {
    let redirect = this;
    let { id } = this.props.match.params;

    let data = await FeatureSetService.showSingleFeatures(id);
    redirect.setState({ feature_title: data[0].feature_title });
    redirect.setState({ feature_description: data[0].feature_description });

    let compatible_machine_categories = await Api.get(
      "getCompatibleMachineCategoriesForFeatures/" + id
    )
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        return false;
      });

    await redirect.setState({
      compatible_machine_categories: compatible_machine_categories
    });

    redirect.setState({ loading: false });
  }

  render() {
    if (this.props.message.includes("Updating feature data success")) {
      return <Redirect to="/features" />;
    } else {
      if (this.props.loading || this.state.loading) {
        return (
          <div>
            <div
              className="content"
              style={{
                minHeight: 500
              }}
            >
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <strong className="card-title">Update Feature</strong>
                    </div>
                    <div className="card-body">
                      <div className="card-content">
                        <div className="todo-list">
                          <div className="tdl-holder">
                            <div className="tdl-content">
                              <div className="row">
                                <div className="col"></div>
                                <div className="col">
                                  <div className="text-center">
                                    <Loader
                                      type="Rings"
                                      color="#00BFFF"
                                      height={100}
                                      width={100}
                                    />
                                    <div className="text-center client-roboto-condensed">
                                      LOADING...
                                    </div>
                                  </div>
                                </div>
                                <div className="col"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div>
            <div
              className="content"
              style={{
                minHeight: 500
              }}
            >
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <strong className="card-title">Update Feature</strong>
                    </div>
                    <div className="card-body">
                      <div className="card-content">
                        <form>
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="form-group">
                                <label
                                  htmlFor="feature_title"
                                  className="control-label mb-1"
                                >
                                  Feature Title
                                </label>
                                <input
                                  id="feature_title"
                                  name="feature_title"
                                  type="text"
                                  className="form-control"
                                  aria-required="true"
                                  onChange={e =>
                                    this.onTextChange(
                                      "feature_title",
                                      e.target.value
                                    )
                                  }
                                  value={this.state.feature_title}
                                />
                                <div
                                  className="mt-4"
                                  style={{
                                    color: "red"
                                  }}
                                >
                                  {this.validator.message(
                                    "feature_title",
                                    this.state.feature_title,
                                    "required|string"
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-12">
                              <div className="form-group">
                                <label
                                  htmlFor="feature_description"
                                  className="control-label mb-1"
                                >
                                  Feature Description
                                </label>
                                <textarea
                                  rows={5}
                                  id="feature_description"
                                  name="feature_description"
                                  type="text"
                                  className="form-control"
                                  aria-required="true"
                                  onChange={e =>
                                    this.onTextChange(
                                      "feature_description",
                                      e.target.value
                                    )
                                  }
                                  value={this.state.feature_description}
                                />
                                <div
                                  className="mt-4"
                                  style={{
                                    color: "red"
                                  }}
                                >
                                  {this.validator.message(
                                    "feature_description",
                                    this.state.feature_description,
                                    "required|string"
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <button
                              onClick={this.onSubmit}
                              id="payment-button"
                              type="submit"
                              className="btn btn-secondary btn-block"
                            >
                              <i className="fa fa-truck"></i>&nbsp;
                              <span id="payment-button-amount">
                                Update Feature
                              </span>
                            </button>
                          </div>
                          <div className="row my-4">
                            <div className="col-sm-12">
                              <div className="form-group">
                                <label
                                  htmlFor="country"
                                  className="text-center control-label mb-1"
                                >
                                  Used in machine category
                                </label>
                                <div className="row">
                                  {this.state.compatible_machine_categories &&
                                  this.state.compatible_machine_categories !==
                                    []
                                    ? this.state.compatible_machine_categories.map(
                                        (categories, i) => (
                                          <div className="col-6 mt-2 mb-2">
                                            [{" "}
                                            {
                                              categories.machine_category_model_number
                                            }{" "}
                                            ] - {categories.machine_category}
                                          </div>
                                        )
                                      )
                                    : "Not used in any categories"}
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
  }
}

const mapStateToProps = state => {
  return {
    message: state.featureReducer.message,
    loading: state.featureReducer.loading
  };
};
export default connect(mapStateToProps, {
  edit_feature
})(UpdateFeature);
