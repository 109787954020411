import React, { Component } from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";

const SortableItem = SortableElement(({ value, count }) => (
  <div
    style={{
      padding: 10,
      marginTop: 15,
      marginBottom: 15
    }}
    className="card"
    tabIndex={0}
  >
    {count}. {value.toUpperCase()}
  </div>
));

const SortableList = SortableContainer(({ items }) => {
  return (
    <div>
      {items.map((value, index) => (
        <SortableItem
          key={`item-${value}`}
          index={index}
          count={index + 1}
          value={value}
        />
      ))}
    </div>
  );
});

export default class SortableComponent extends Component {
  state = {
    items: this.props.types
  };
  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ items }) => ({
      items: arrayMove(items, oldIndex, newIndex)
    }));
    this.props.onComponentChange(this.state.items);
  };
  render() {
    return <SortableList items={this.state.items} onSortEnd={this.onSortEnd} />;
  }
}
