import React, { Component } from "react";
import { add_company_rates } from "./../action";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

class AddCompanyRates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rate_name: "",
      rate_value: ""
    };
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.onTextChange = this.onTextChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  //function to handle text change in multiple fields
  onTextChange(name, value) {
    let redirect = this;
    switch (name) {
      case "rate_name":
        redirect.setState({ rate_name: value });
        break;

      case "rate_value":
        redirect.setState({ rate_value: value });
        break;

      default:
        break;
    }
  }

  //function to trigger submit once the add company rates button is pressed
  async onSubmit(e) {
    e.preventDefault();

    if (this.validator.allValid()) {
      let params = {
        rate_name: this.state.rate_name,
        rate_value: this.state.rate_value
      };
      await this.props.add_company_rates(params);
    } else {
      this.validator.showMessages();
    }
  }

  componentDidMount() {}

  render() {
    if (this.props.message.includes("Adding company rates successful")) {
      return <Redirect to="/company-rates" />;
    } else {
      if (this.props.loading) {
        return (
          <div>
            <div
              className="content"
              style={{
                minHeight: 500
              }}
            >
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <strong className="card-title">Add Company Rates</strong>
                    </div>
                    <div className="card-body">
                      <div className="card-content">
                        <div className="todo-list">
                          <div className="tdl-holder">
                            <div className="tdl-content">
                              <div className="row">
                                <div className="col"></div>
                                <div className="col">
                                  <div className="text-center">
                                    <Loader
                                      type="Rings"
                                      color="#00BFFF"
                                      height={100}
                                      width={100}
                                    />
                                    <div className="text-center client-roboto-condensed">
                                      LOADING...
                                    </div>
                                  </div>
                                </div>
                                <div className="col"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div>
            <div
              className="content"
              style={{
                minHeight: 500
              }}
            >
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <strong className="card-title">Add Company Rates</strong>
                    </div>
                    <div className="card-body">
                      <form>
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="form-group col-sm-12">
                              <label
                                htmlFor="rate_name"
                                className="control-label mb-1"
                              >
                                Comapny Rate Name
                              </label>
                              <input
                                id="rate_name"
                                name="rate_name"
                                type="text"
                                className="form-control"
                                aria-required="true"
                                value={this.state.rate_name}
                                onChange={e =>
                                  this.onTextChange("rate_name", e.target.value)
                                }
                              />
                              <div
                                className="mt-4"
                                style={{
                                  color: "red"
                                }}
                              >
                                {this.validator.message(
                                  "rate_name",
                                  this.state.rate_name,
                                  "required|string"
                                )}
                              </div>
                            </div>
                            <div className="form-group col-sm-12">
                              <label
                                htmlFor="rate_name"
                                className="control-label mb-1"
                              >
                                Company Rate Value
                              </label>
                              <textarea
                                rows={8}
                                id="rate_value"
                                name="rate_value"
                                type="text"
                                className="form-control"
                                aria-required="true"
                                value={this.state.rate_value}
                                onChange={e =>
                                  this.onTextChange(
                                    "rate_value",
                                    e.target.value
                                  )
                                }
                              />
                              <div
                                className="mt-4"
                                style={{
                                  color: "red"
                                }}
                              >
                                {this.validator.message(
                                  "rate_value",
                                  this.state.rate_value,
                                  "required|string"
                                )}
                              </div>
                            </div>
                            <div>
                              <button
                                id="payment-button"
                                onClick={this.onSubmit}
                                type="submit"
                                className="btn btn-secondary btn-block"
                              >
                                <i className="fa fa-truck"></i>&nbsp;
                                <span id="payment-button-amount">
                                  Add Company Rates
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
  }
}

const mapStateToProps = state => {
  return {
    message: state.companyRateReducer.message,
    loading: state.companyRateReducer.loading
  };
};

export default connect(mapStateToProps, {
  add_company_rates
})(AddCompanyRates);
