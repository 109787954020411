import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import { css } from "@emotion/react";
import { PulseLoader } from "react-spinners";

import passwordReset from "./../login/views/passwordReset";

import ErrorPage from "./../layout/errorpage";
import Dashboard from "./../admin/dashboard/views/dashboard";

import GraphicalData from "./../admin/dashboard/views/graphical";

import ListMachineCategoriesForSequence from "./../admin/sequence/views/list";
import AddSequenceForMachineCategory from "./../admin/sequence/views/add";

import ListBrands from "./../admin/brand/views/listBrands";
import AddBrand from "./../admin/brand/views/addBrand";
import UpdateBrand from "./../admin/brand/views/updateBrands";

import ListRole from "./../admin/user/views/listRole";
import CreateRole from "../admin/user/views/createRole";
import UpdateRole from "../admin/user/views/updateRole";

import ListMachinePart from "../admin/machine_parts/views/listMachineParts";
import AddMachinePart from "../admin/machine_parts/views/addMachinePart";
import UpdateMachinePart from "../admin/machine_parts/views/updateMachinePart";

import ListMachineCategories from "../admin/machine_category/views/listMachineCategories";
import AddMachineCategory from "../admin/machine_category/views/addMachineCategory";
import UpdateMachineCategory from "../admin/machine_category/views/updateMachineCategory";
import AddSpecificationCategory from "../admin/machine_category/views/addSpecification";

import ListCompanyRates from "../admin/company_rates/views/list";
import AddCompanyRate from "../admin/company_rates/views/add";
import EditCompanyRate from "../admin/company_rates/views/update";

import ListYear from "../admin/year/views/listYears";
import AddYear from "../admin/year/views/addYear";
import EditYear from "../admin/year/views/editYear";

import listCountries from "../admin/country/views/listCountries";
import AddCountry from "../admin/country/views/addCountry";
import UpdateCountry from "../admin/country/views/UpdateCountry";

import showQuotes from "../admin/quote/views/showQuotes";
import showDetailedQuote from "../admin/quote/views/showDetailedQuote";

import ListUser from "../admin/user/views/listUsers";

import Login from "../login/views/login";
import Register from "../login/views/register";

import ListMachineCategoriesForCompatibility from "../admin/compatibility/views/listMachineCategoriesForCompatibility";
import AddCompatibility from "../admin/compatibility/views/addCompatibility";

import SpreadSheet from "../admin/spreadsheet/views/spreadsheet";
import AddMachinePartData from "../admin/machine_parts/views/addMachinePartData";
import listConfigurations from "../admin/configured_machines/views/listConfigurations";
import UploadSpreadsheet from "../admin/spreadsheet/views/uploadspreadSheet";
import UploadMachineCategoryWithParts from "../admin/machine_category/views/uploadMachineCategoryWithParts";
import UploadMachineCategoryOnly from "../admin/machine_category/views/uploadMachineCategoryOnly";

import ListFeatures from "../admin/featureset/views/list";
import AddFeature from "../admin/featureset/views/add";
import EditFeature from "../admin/featureset/views/update";
import AddFeatureToCategory from "../admin/featureset/views/addFeaturetoCategory";

import ListSpecification from "../admin/specifications/views/list";
import AddSpecification from "../admin/specifications/views/add";
import EditSpecification from "../admin/specifications/views/update";

import ListCategory from "../admin/note_and_remark/views/listCategory";
import ListNoteParts from "../admin/note_and_remark/views/listParts";
import AddOrUpdateNoteAndRemark from "../admin/note_and_remark/views/addOrUpdate";

import Home from "./../client/home/views/home";

import ConfigurationSuccess from "./../client/configuration/views/configurationSuccess";
import configurationProcess from "../client/configuration/views/configurationProcess";
import myConfigurations from "../client/configuration/views/myConfigurations";
import myDetailedConfiguration from "../client/configuration/views/myDetailedConfiguration";

import MyQuotes from "./../client/quotes/views/myQuotes";
import myDetailedQuote from "../client/quotes/views/myDetailedQuote";
import CreateQuote from "../client/quotes/views/createQuote";

import myOrders from "../client/order/views/myOrders";
import myDetailedOrder from "../client/order/views/myDetailedOrder";

import profile from "../client/profile/views/profile";
import ClientDashboard from "../client/profile/views/dashboard";
import addFeaturesToCategory from "../admin/machine_category/views/addFeaturesToCategory";
import AddKeyModelFeatureToCategoryMachine from "../admin/machine_category/views/addKeyModelFeaturesToCategory";

import { getUser } from "./../login/action";
import editConfigurationProcess from "../client/configuration/views/editConfigurationProcess";
import editQuote from "../client/quotes/views/editQuote";
import Disclaimer from "../common/views/disclaimer";
import PrivacyStatement from "../common/views/privacyStatement";
import UpdateUserRole from "../admin/user/views/updateUserRole";
import addConfigurations from "../client/quotes/views/addConfigurations";
import libraryConfigurations from "../client/configuration/views/libraryConfigurations";
import detailedLibraryConfiguration from "../client/configuration/views/detailedLibraryConfiguration";
import listMachinePartsOnly from "../admin/machine_parts/views/listMachinePartsOnly";
import listMachineCategoryWithPrice from "../admin/machine_category/views/listMachineCategoryWithPrice";
import updateMachinePartOnly from "../admin/machine_parts/views/updateMachinePartOnly";
import updateMachineCategoryWithPrice from "../admin/machine_category/views/updateMachineCategoryWithPrice";
import verificationPage from "../login/views/verificationPage";
import forgotPassword from "../login/views/forgotPassword";

import ListBackupFiles from "../admin/backup/views/list";

import OrderForm from "../client/order/views/orderForm";

import ListTemplates from "../admin/template/views/list";
import AddTemplate from "../admin/template/views/add";
import EditTemplate from "../admin/template/views/edit";

import ListCategoriesForRole from "../admin/role/views/listCategoriesForRole";
import ListCategoriesUpdateForRole from "../admin/role/views/listCategoriesUpdateForRole";
import ListCostPriceManagementForRole from "../admin/role/views/listCostPriceManagementForRole";
import ListTemplateForRole from "../admin/role/views/listTemplatesForRole";
import ListTemplateUpdateForRole from "../admin/role/views/listTemplateUpdateForRole";

import UpdateCategoriesForRole from "../admin/role/views/updateCategoriesForRole";
import UpdateCategoriesUpdateForRole from "../admin/role/views/updateCategoriesUpdateForRole";
import UpdateCostPriceManagementForRole from "../admin/role/views/updateCostPriceManagementForRole";
import UpdateTemplateForRole from "../admin/role/views/updateTemplatesForRole";
import UpdateTemplateUpdateForRole from "../admin/role/views/updateTemplateUpdateForRole";

import LeaseCalculation from "../client/lease/views/leaseCalculation";
import listAllResidualPercentage from "../admin/residual_percentage/views/listAllResidualPercentage";
import addResidualPercentage from "../admin/residual_percentage/views/addResidualPercentage";
import updateResidualPercentage from "../admin/residual_percentage/views/updateResidualPercentage";
import listCurrencies from "../admin/currency/views/listCurrencies";
import addCurrency from "../admin/currency/views/addCurrency";
import updateCurrency from "../admin/currency/views/updateCurrency";
import listGenericQuestions from "../admin/generic_questions/views/listGenericQuestions";
import addGenericQuestion from "../admin/generic_questions/views/addGenericQuestion";
import updateGenericQuestion from "../admin/generic_questions/views/updateGenericQuestion";
import listPolicyDocuments from "../admin/policy_documents/views/listPolicyDocuments";
import addPolicyDocument from "../admin/policy_documents/views/addPolicyDocument";
import updatePolicyDocuement from "../admin/policy_documents/views/updatePolicyDocuement";
import updatePolicyDocumentUpdateForRole from "../admin/role/views/updatePolicyDocumentUpdateForRole";
import listPolicyDocumentUpdateForRole from "../admin/role/views/listPolicyDocumentUpdateForRole";
import policyDownloads from "../client/home/views/policyDownloads";
import updateUserHierarchy from "../admin/user/views/updateUserHierarchy";
import childrenConfigurations from "../client/configuration/views/childrenConfigurations";
import ChildrenQuotes from "../client/quotes/views/childrenQuotes";
import childrenOrders from "../client/order/views/childrenOrders";
import Unsubscribe from "../common/views/unsubscribe";
import updateDiscountForCategoryByRole from "../admin/role/views/updateDiscountForCategoryByRole";
import Notification from "./../login/views/notification";
import notificationLogs from "../login/views/notificationLogs";
import listSalesDesk from "../admin/sales_desk/views/listSalesDesk";
import addSalesDesk from "../admin/sales_desk/views/addSalesDesk";
import updateSalesDesk from "../admin/sales_desk/views/updateSalesDesk";
import listBulletin from "../admin/bulletin/views/listBulletin";
import addBulletin from "../admin/bulletin/views/addBulletin";
import updateBulletin from "../admin/bulletin/views/updateBulletin";
import updateBulletinUpdateForRole from "../admin/role/views/updateBulletinUpdateForRole";
import listBulletinUpdateForRole from "../admin/role/views/listBulletinUpdateForRole";
import bulletinDownloads from "../client/home/views/bulletinDownloads";
import listType from "../admin/customer_feedback/views/listType";
import addType from "../admin/customer_feedback/views/addType";
import updateType from "../admin/customer_feedback/views/updateType";
import listSeverity from "../admin/customer_feedback/views/listSeverity";
import addSeverity from "../admin/customer_feedback/views/addSeverity";
import updateSeverity from "../admin/customer_feedback/views/updateSeverity";
import listFrequency from "../admin/customer_feedback/views/listFrequency";
import addFrequency from "../admin/customer_feedback/views/addFrequency";
import updateFrequency from "../admin/customer_feedback/views/updateFrequency";
import listAllFeedBacks from "../admin/customer_feedback/views/listAllFeedBacks";
import listSinglefeedBack from "../admin/customer_feedback/views/listSinglefeedBack";
import addCustomerFeedback from "../admin/customer_feedback/views/addCustomerFeedback";
import listArticles from "../admin/article/views/listArticles";
import addArticle from "../admin/article/views/addArticle";
import updateArticle from "../admin/article/views/updateArticle";
import parentLibraryConfigurations from "../client/configuration/views/parentLibraryConfigurations";
import registerAdmin from "../login/views/registerAdmin";
import listUpdates from "../admin/update/views/listUpdates";
import addUpdate from "../admin/update/views/addUpdate";
import editUpdate from "../admin/update/views/editUpdate";

// key-model-feature
import ListKeyModelFeature from "../admin/keymodelfeature/views/list";
import AddKeyModelFeature from "../admin/keymodelfeature/views/add";
import EditKeyModelFeature from "../admin/keymodelfeature/views/update";
import AddKeyModelFeatureToCategory from "../admin/keymodelfeature/views/addFeaturetoCategory";

class Router extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true
		};
	}

	async componentDidMount() {
		let search = await window.location.search;
		let params = await new URLSearchParams(search);
		let foo = await params.get('token');

		if (foo !== null && foo !== undefined && foo !== "") {
			localStorage.setItem("ADMIN_ONLY", 'true')
			await this.props.getUser('Bearer '+ foo);
		} else {

			let qw = localStorage.getItem("AppTree");

			if (qw && qw !== null && qw !== undefined && qw !== "") {
				let token = JSON.parse(qw).loginReducer.token;
				if (token && token !== "" && token !== null && token !== undefined) {
					await this.props.getUser(token);
				} else {
					this.setState({ loading: false });
				}
			}

		}
		await this.setState({ loading: false });
	}

	render() {
		const override = css`
			display: block;
			margin: 0 auto;
			border-color: red;
		`;
		if (this.props.loginLoading || this.state.loading) {
			return (
				<div className="sweet-loading">
					<div className="container loading-container">
						<div className="row m-5 p-5">
							<PulseLoader
								css={override}
								sizeUnit={"px"}
								size={24}
								color={"#B9373D"}
							/>
						</div>
					</div>
				</div>
			);
		} else {
			if (
				this.props.isAuthenticated &&
				this.props.token !== "" &&
				this.props.token !== null &&
				this.props.token !== undefined &&
				this.props.user !== [] &&
				this.props.user !== "" &&
				this.props.user !== false &&
				this.props.userRole
			) {
				if (this.props.userRole.toLowerCase() === "admin") {
					return (
						<Switch>
							{/* Admin related routes start */}
							<Route path="/" exact component={Dashboard} />
							<Route path="/register" component={Register} />
							<Route path="/register-admin" component={registerAdmin} />
							<Route path="/graphical-data" component={GraphicalData} />
							<Route
								path="/unsubscribe/:user_id"
								component={Unsubscribe}
							/>
							<Route
								path="/password-reset/:token"
								component={passwordReset}
							/>

							<Route path="/backup-files" component={ListBackupFiles} />

							<Route
								path="/user-role"
								component={withRouter(ListRole)}
							/>
							<Route path="/create-user-role" component={CreateRole} />
							<Route
								path="/update-user-role/:id"
								component={UpdateRole}
							/>
							<Route path="/users" component={ListUser} />
							<Route
								path="/update-user/:user_id"
								component={UpdateUserRole}
							/>
							<Route
								path="/update-user-hierarchy/:user_id"
								component={updateUserHierarchy}
							/>

							<Route
								path="/sequence"
								component={ListMachineCategoriesForSequence}
							/>
							<Route
								path="/add-squence/:id"
								component={AddSequenceForMachineCategory}
							/>

							<Route
								path="/show-compatible-categories"
								component={ListCategory}
							/>
							<Route
								path="/show-compatible-parts/:machine_category_id"
								component={ListNoteParts}
							/>
							<Route
								path="/update-custom_note-or-remark/:machine_category_id/:machine_part_id"
								component={AddOrUpdateNoteAndRemark}
							/>

							<Route path="/bulletin" component={listBulletin} />
							<Route path="/add-bulletin" component={addBulletin} />
							<Route
								path="/edit-bulletin/:id"
								component={updateBulletin}
							/>

							<Route
								path="/show-customer-feedbacks"
								component={listAllFeedBacks}
							/>
							<Route
								path="/show-customer-feedback/:id"
								component={listSinglefeedBack}
							/>

							<Route
								path="/list-customer-feedback-types"
								component={listType}
							/>
							<Route
								path="/add-customer-feedback-type"
								component={addType}
							/>
							<Route
								path="/edit-customer-feedback-type/:id"
								component={updateType}
							/>

							<Route
								path="/list-customer-feedback-severity"
								component={listSeverity}
							/>
							<Route
								path="/add-customer-feedback-severity"
								component={addSeverity}
							/>
							<Route
								path="/edit-customer-feedback-severity/:id"
								component={updateSeverity}
							/>

							<Route
								path="/list-customer-feedback-frequency"
								component={listFrequency}
							/>
							<Route
								path="/add-customer-feedback-frequency"
								component={addFrequency}
							/>
							<Route
								path="/edit-customer-feedback-frequency/:id"
								component={updateFrequency}
							/>

							<Route path="/sales-desk" component={listSalesDesk} />
							<Route path="/add-sales-desk" component={addSalesDesk} />
							<Route
								path="/edit-sales-desk/:id"
								component={updateSalesDesk}
							/>

							<Route path="/article" component={listArticles} />
							<Route path="/add-article" component={addArticle} />
							<Route
								path="/edit-article/:id"
								component={updateArticle}
							/>

							<Route path="/updates" component={listUpdates} />
							<Route path="/add-update" component={addUpdate} />
							<Route path="/edit-update/:id" component={editUpdate} />

							<Route path="/brand" component={ListBrands} />
							<Route path="/add-brand" component={AddBrand} />
							<Route path="/edit-brand/:id" component={UpdateBrand} />

							<Route
								path="/generic-question"
								component={listGenericQuestions}
							/>
							<Route
								path="/add-generic-question"
								component={addGenericQuestion}
							/>
							<Route
								path="/edit-generic-question/:id"
								component={updateGenericQuestion}
							/>

							<Route
								path="/policy-documents"
								component={listPolicyDocuments}
							/>
							<Route
								path="/add-policy-document"
								component={addPolicyDocument}
							/>
							<Route
								path="/edit-policy-document/:id"
								component={updatePolicyDocuement}
							/>

							<Route path="/machine-part" component={ListMachinePart} />
							<Route
								path="/machine-part-only"
								component={listMachinePartsOnly}
							/>
							<Route
								path="/create-machine-part"
								component={AddMachinePart}
							/>
							<Route
								path="/update-machine-part/:id/:year_count/:cost_year_count/:year_id/:country_id"
								component={UpdateMachinePart}
							/>
							<Route
								path="/update-machine-part-only/:id"
								component={updateMachinePartOnly}
							/>

							<Route
								path="/machine-category"
								component={ListMachineCategories}
							/>
							<Route
								path="/machine-category-price"
								component={listMachineCategoryWithPrice}
							/>
							<Route
								path="/create-machine-category"
								component={AddMachineCategory}
							/>
							<Route
								path="/update-machine-category/:id"
								component={UpdateMachineCategory}
							/>
							<Route
								path="/update-machine-category-with-price/:id/:year_count/:cost_year_count/:year_id/:country_id"
								component={updateMachineCategoryWithPrice}
							/>
							<Route
								path="/add-specification-category"
								component={AddSpecificationCategory}
							/>
							<Route
								path="/add-feature-category"
								component={addFeaturesToCategory}
							/>

							<Route path="/features" component={ListFeatures} />
							<Route path="/add-feature" component={AddFeature} />
							<Route path="/edit-feature/:id" component={EditFeature} />
							<Route
								path="/add-feature-to-category/:machine_category_id"
								component={AddFeatureToCategory}
							/>

							{/* key-model-features */}
							<Route
								path="/key-model-feature"
								component={ListKeyModelFeature}
							/>
							<Route
								path="/add-key-model-feature"
								component={AddKeyModelFeature}
							/>
							<Route
								path="/edit-key-model-feature/:id"
								component={EditKeyModelFeature}
							/>
							<Route
								path="/add-key-model-feature-to-category/:machine_category_id"
								component={AddKeyModelFeatureToCategory}
							/>
							<Route
								path="/add-key-model-feature-category"
								component={AddKeyModelFeatureToCategoryMachine}
							/>

							<Route
								path="/list-specifications/:machine_category_id"
								component={ListSpecification}
							/>
							<Route
								path="/add-specification"
								component={AddSpecification}
							/>
							<Route
								path="/edit-specification/:id"
								component={EditSpecification}
							/>

							<Route path="/template" component={ListTemplates} />
							<Route path="/add-template" component={AddTemplate} />
							<Route
								path="/update-template/:id"
								component={EditTemplate}
							/>

							<Route
								path="/show-categories-for-role"
								component={ListCategoriesForRole}
							/>
							<Route
								path="/show-categories-update-for-role"
								component={ListCategoriesUpdateForRole}
							/>
							<Route
								path="/show-cost-price-management-for-role"
								component={ListCostPriceManagementForRole}
							/>
							<Route
								path="/show-template-for-role"
								component={ListTemplateForRole}
							/>
							<Route
								path="/show-template-update-for-role"
								component={ListTemplateUpdateForRole}
							/>
							<Route
								path="/show-policy-document-update-for-role"
								component={listPolicyDocumentUpdateForRole}
							/>
							<Route
								path="/show-bulletin-update-for-role"
								component={listBulletinUpdateForRole}
							/>

							<Route
								path="/update-discount-for-category-by-role/:role_id/:machine_category_id"
								component={updateDiscountForCategoryByRole}
							/>

							<Route
								path="/update-categories-for-role/:id"
								component={UpdateCategoriesForRole}
							/>
							<Route
								path="/update-categories-update-for-role/:id"
								component={UpdateCategoriesUpdateForRole}
							/>
							<Route
								path="/update-cost-price-management-for-role/:id"
								component={UpdateCostPriceManagementForRole}
							/>
							<Route
								path="/update-template-for-role/:id"
								component={UpdateTemplateForRole}
							/>
							<Route
								path="/update-template-update-for-role/:id"
								component={UpdateTemplateUpdateForRole}
							/>
							<Route
								path="/update-policy-document-update-for-role/:id"
								component={updatePolicyDocumentUpdateForRole}
							/>
							<Route
								path="/update-bulletin-update-for-role/:id"
								component={updateBulletinUpdateForRole}
							/>

							<Route path="/year" component={ListYear} />
							<Route path="/add-year" component={AddYear} />
							<Route path="/update-year/:id" component={EditYear} />

							<Route path="/country" component={listCountries} />
							<Route path="/add-country" component={AddCountry} />
							<Route
								path="/update-country/:id"
								component={UpdateCountry}
							/>

							<Route path="/currency" component={listCurrencies} />
							<Route path="/add-currency" component={addCurrency} />
							<Route
								path="/update-currency/:id"
								component={updateCurrency}
							/>

							<Route
								path="/company-rates"
								component={ListCompanyRates}
							/>
							<Route
								path="/add-company-rate"
								component={AddCompanyRate}
							/>
							<Route
								path="/edit-company-rate/:id"
								component={EditCompanyRate}
							/>

							<Route path="/quote" component={showQuotes} />
							<Route
								path="/quote-detail/:id"
								component={showDetailedQuote}
							/>

							<Route
								path="/show-category-for-compatibility"
								component={ListMachineCategoriesForCompatibility}
							/>
							<Route
								path="/change-compatibility/:id"
								component={AddCompatibility}
							/>

							<Route
								path="/add-machine-part-data"
								component={AddMachinePartData}
							/>

							<Route
								path="/configurations"
								component={listConfigurations}
							/>

							<Route path="/spreadsheet" component={SpreadSheet} />
							<Route
								path="/upload-spreadsheet"
								component={UploadSpreadsheet}
							/>

							<Route
								path="/upload-category-with-parts"
								component={UploadMachineCategoryWithParts}
							/>
							<Route
								path="/upload-category-only"
								component={UploadMachineCategoryOnly}
							/>
							<Route path="/disclaimer" component={Disclaimer} />
							<Route
								path="/privacy-statement"
								component={PrivacyStatement}
							/>

							<Route
								path="/list-all-residual-percnentage"
								component={listAllResidualPercentage}
							/>
							<Route
								path="/add-residual-percentage"
								component={addResidualPercentage}
							/>
							<Route
								path="/edit-residual-percentage/:id"
								component={updateResidualPercentage}
							/>

							<Route path="*" exact={true} component={ErrorPage} />
							{/* Admin related routes end */}
						</Switch>
					);
				} else if (!this.props.userRole.toLowerCase().includes("admin")) {
					if (this.props.is_verified) {
						return (
							<Switch>
								{/* Client realated routes start */}
								<Route
									exact
									path="/"
									render={() => <Redirect to="/home" />}
								/>
								<Route
									path="/password-reset/:token"
									component={passwordReset}
								/>
								<Route path="/home" initial exact component={Home} />
								<Route
									path="/unsubscribe/:user_id"
									component={Unsubscribe}
								/>

								<Route
									path="/add-customer-feedback"
									component={addCustomerFeedback}
								/>

								<Route path="/notifications" component={Notification} />
								<Route
									path="/notification-logs"
									component={notificationLogs}
								/>

								<Route
									path="/bulletin-documents"
									exact
									component={bulletinDownloads}
								/>

								<Route
									path="/user-policy-documents"
									exact
									component={policyDownloads}
								/>
								<Route
									path="/lease-calculation/:id"
									component={LeaseCalculation}
								/>

								<Route
									path="/configuration-process/:id?/:from?"
									component={configurationProcess}
								/>
								<Route
									path="/configuration-success"
									component={ConfigurationSuccess}
								/>

								<Route
									path="/edit-configuration-process/:id/:from?"
									component={editConfigurationProcess}
								/>

								<Route path="/quotes" component={MyQuotes} />
								<Route path="/team-quotes" component={ChildrenQuotes} />
								<Route path="/quote/:id" component={myDetailedQuote} />
								<Route path="/create-quote" component={CreateQuote} />
								<Route path="/edit-quote/:id" component={editQuote} />

								<Route
									path="/add-configuration-quote/:id"
									component={addConfigurations}
								/>

								<Route
									path="/configurations"
									component={myConfigurations}
								/>
								<Route
									path="/team-configurations"
									component={childrenConfigurations}
								/>
								<Route
									path="/configuration/:id"
									component={myDetailedConfiguration}
								/>

								<Route
									path="/library-configurations"
									component={libraryConfigurations}
								/>
								<Route
									path="/team-library-configurations"
									component={parentLibraryConfigurations}
								/>
								<Route
									path="/library-configuration/:id"
									component={detailedLibraryConfiguration}
								/>

								<Route path="/orders" component={myOrders} />
								<Route path="/team-orders" component={childrenOrders} />

								<Route path="/order/:id" component={myDetailedOrder} />
								<Route path="/order-form/:id" component={OrderForm} />

								<Route path="/profile" component={profile} />
								{this.props.is_support ? (
									<Route
										path="/dashboard"
										component={ClientDashboard}
									/>
								) : null}

								<Route path="/disclaimer" component={Disclaimer} />
								<Route
									path="/privacy-statement"
									component={PrivacyStatement}
								/>

								<Route path="*" exact={true} component={ErrorPage} />
								{/* Client related routes end */}
							</Switch>
						);
					} else {
						return (
							<Switch>
								<Route path="/" component={verificationPage} />
							</Switch>
						);
					}
				} else {
					return this.props.message === "Logout Success" ? (
						<Switch>
							<Route
								path="/password-reset/:token"
								component={passwordReset}
							/>
							<Route
								path="/forgot-password"
								component={forgotPassword}
							/>
							<Route
								path="/unsubscribe/:user_id"
								component={Unsubscribe}
							/>
							<Route path="/" exact component={Login} />
							<Route
								path="*"
								exact={true}
								render={() => <Redirect to="/" />}
							/>
						</Switch>
					) : (
							<Switch>
								<Route
									path="/password-reset/:token"
									component={passwordReset}
								/>
								<Route
									path="/forgot-password"
									component={forgotPassword}
								/>
								<Route
									path="/unsubscribe/:user_id"
									component={Unsubscribe}
								/>
								<Route path="/" exact component={Login} />
								<Route path="*" exact={true} component={ErrorPage} />
							</Switch>
						);
				}
			} else {
				return this.props.message === "Logout Success" ||
					this.props.message === "Getting user data faliure" ? (
						<Switch>
							<Route
								path="/password-reset/:token"
								component={passwordReset}
							/>
							<Route path="/forgot-password" component={forgotPassword} />
							{/* <Route path="/register" component={Register} /> */}
							<Route path="/" exact component={Login} />
							<Route path="/disclaimer" component={Disclaimer} />
							<Route
								path="/privacy-statement"
								component={PrivacyStatement}
							/>
							<Route path="/unsubscribe/:user_id" component={Unsubscribe} />
							<Route
								path="*"
								exact={true}
								render={() => <Redirect to="/" />}
							/>
						</Switch>
					) : (
						<Switch>
							<Route
								path="/password-reset/:token"
								component={passwordReset}
							/>
							<Route path="/forgot-password" component={forgotPassword} />
							{/* <Route path="/register" component={Register} /> */}
							<Route path="/" exact component={Login} />
							<Route path="/disclaimer" component={Disclaimer} />
							<Route
								path="/privacy-statement"
								component={PrivacyStatement}
							/>
							<Route path="/unsubscribe/:user_id" component={Unsubscribe} />
							{/* <Route
							path="*"
							exact={true}
							render={() => <Redirect to="/" />}
						/> */}
							<Route path="*" exact={true} component={ErrorPage} />
						</Switch>
					);
			}
		}
	}
}

const mapStateToProps = state => {
	return {
		isAuthenticated: state.loginReducer.isAuthenticated,
		user: state.loginReducer.user,
		token: state.loginReducer.token,
		userRole: state.loginReducer.user.role
			? state.loginReducer.user.role
			: "",
		message: state.loginReducer.message,
		is_verified: state.loginReducer.user.is_verified,
		is_support: state.loginReducer.user.is_support,
		loginLoading: state.loginReducer.loading
	};
};

export default connect(mapStateToProps, {
	getUser
})(Router);
