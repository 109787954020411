import React, { Component } from "react";
import { connect } from "react-redux";
import { MachineCategoryService } from "./../service";
import { Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { YearService } from "./../../year/service";
import { CountryService } from "./../../country/service";
import { notification } from "antd";

class ListMachineCategoryWithPrice extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			search: "",
			machine_part_list: false,
			parts: false,
			countries: false,
			years: false,
			country_id: this.props.user.user.country_id,
			year_id: "",
			is_selectable: "",
			is_visible: ""
		};
		this.onSearchChange = this.onSearchChange.bind(this);
		this.onDelete = this.onDelete.bind(this);
		this.onSearch = this.onSearch.bind(this);
		this.handleSelectChange = this.handleSelectChange.bind(this);
	}

	async handleSelectChange(name, value) {
		let that = this;
		that.setState({ loading: true });
		let data = [];
		switch (name) {
			case "is_selectable":
				that.setState({ is_selectable: value });
				data = await MachineCategoryService.showMachineCategoriesWithPrice(
					this.state.search,
					this.state.country_id,
					this.state.year_id,
					value
					// this.state.is_visible
				);
				this.setState({ machine_part_list: data });
				this.setState({ parts: data });
				break;

			// case "is_visible":
			// 	that.setState({ is_visible: value });
			// 	data = await MachineCategoryService.showMachineCategoriesWithPrice(
			// 		this.state.search,
			// 		this.state.country_id,
			// 		this.state.year_id,
			// 		this.state.is_selectable,
			// 		value
			// 	);
			// 	this.setState({ machine_part_list: data });
			// 	this.setState({ parts: data });
			// 	break;

			case "year_id":
				that.setState({ year_id: value });
				data = await MachineCategoryService.showMachineCategoriesWithPrice(
					this.state.search,
					this.state.country_id,
					value,
					this.state.is_selectable
					// this.state.is_visible
				);
				this.setState({ machine_part_list: data });
				this.setState({ parts: data });
				break;

			case "country_id":
				that.setState({ country_id: value });

				data = await MachineCategoryService.showMachineCategoriesWithPrice(
					this.state.search,
					value,
					this.state.year_id,
					this.state.is_selectable
					// this.state.is_visible
				);
				this.setState({ machine_part_list: data });
				this.setState({ parts: data });
				break;

			default:
				break;
		}

		that.setState({ loading: false });
	}

	async onDelete(id, year_id, year_price_count) {
		let confirmation = window.confirm("Are you sure?");

		if (confirmation) {
			let data = await MachineCategoryService.deleteMachineCategoriesSpecific(
				id,
				year_id,
				year_price_count
			);

			if (data.message.includes("success")) {
				notification.success({
					message: "Success!",
					description: data.message
				});
			} else {
				notification.error({
					message: "Error!",
					description: data.message
				});
			}

			let data1 = await MachineCategoryService.showMachineCategoriesWithPrice(
				this.state.search,
				this.state.country_name,
				this.state.year_id
			);
			this.setState({ machine_part_list: data1 });
			this.setState({ parts: data1 });
		}
	}

	async onSearch() {
		let data = await MachineCategoryService.showMachineCategoriesWithPrice(
			this.state.search,
			this.state.country_id,
			this.state.year_id
		);
		this.setState({ machine_part_list: data });
		this.setState({ parts: data });
	}

	onSearchChange(name, value) {
		let redirect = this;
		switch (name) {
			case "search":
				redirect.setState({ search: value });
				break;

			default:
				break;
		}
	}

	async componentDidMount() {
		let redirect = this;

		let countries = await CountryService.showCountries();
		let years = await YearService.showYear();

		await redirect.setState({ countries: countries });
		await redirect.setState({ years: years });
		let year_id = await years.filter(
			element => element.year === new Date().getFullYear()
		);

		await redirect.setState({ year_id: year_id[0].id });

		let data = await MachineCategoryService.showMachineCategoriesWithPrice(
			"",
			this.props.user.user.country_id,
			this.state.year_id,
			this.state.is_selectable,
			this.state.is_visible
		);
		redirect.setState({ machine_part_list: data });
		redirect.setState({ parts: data });

		if (
			redirect.props.message !== "" &&
			redirect.props.message !== undefined
		) {
			if (redirect.props.message.toLowerCase().includes("success")) {
				notification.success({
					message: "Success!",
					description: redirect.props.message
				});
			} else {
				notification.error({
					message: "Error!",
					description: redirect.props.message
				});
			}
		}

		redirect.setState({ loading: false });
	}

	render() {
		if (this.state.parts) {
			return (
				<div>
					<div
						className="content"
						style={{
							minHeight: 500
						}}
					>
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-header">
										<strong className="card-title">
											Machine Category [Price]
										</strong>
									</div>
									<div className="card-body">
										<div className="card-content">
											<div className="todo-list">
												<div className="tdl-holder">
													<div className="tdl-content">
														<div className="row my-3">
															<div className="col-4">
																<select
																	onChange={e =>
																		this.handleSelectChange(
																			"country_id",
																			e.target.value
																		)
																	}
																	className="custom-select"
																	value={this.state.country_id}
																>
																	{this.state.countries
																		? this.state.countries.map(
																				(country, i) => (
																					<option
																						value={
																							country.id
																						}
																					>
																						{
																							country.country_name
																						}
																					</option>
																				)
																		  )
																		: null}
																</select>
															</div>
															<div className="col-4">
																<select
																	onChange={e =>
																		this.handleSelectChange(
																			"year_id",
																			e.target.value
																		)
																	}
																	className="custom-select"
																	value={this.state.year_id}
																>
																	{this.state.years
																		? this.state.years.map(
																				(year, i) => (
																					<option
																						value={
																							year.id
																						}
																					>
																						{year.year}
																					</option>
																				)
																		  )
																		: null}
																</select>
															</div>

															{/* is_selectable */}
															<div className="col-2">
																<select
																	onChange={e =>
																		this.handleSelectChange(
																			"is_selectable",
																			e.target.value
																		)
																	}
																	className="custom-select"
																	value={
																		this.state.is_selectable
																	}
																>
																	{[
																		{
																			value: "",
																			label:
																				"All (Selectable)"
																		},
																		{
																			value: 1,
																			label:
																				"Yes (Selectable)"
																		},
																		{
																			value: 0,
																			label:
																				"No (Selectable)"
																		}
																	].map((selectable, i) => (
																		<option
																			key={`selectable-${i}-${selectable.value}`}
																			value={
																				selectable.value
																			}
																		>
																			{selectable.label}
																		</option>
																	))}
																</select>
															</div>

															{/* is_visible */}
															{/* <div className="col-2">
																<select
																	onChange={e =>
																		this.handleSelectChange(
																			"is_visible",
																			e.target.value
																		)
																	}
																	className="custom-select"
																	value={this.state.is_visible}
																>
																	{[
																		{
																			value: "",
																			label: "All (Visible)"
																		},
																		{
																			value: 1,
																			label: "Yes (Visible)"
																		},
																		{
																			value: 0,
																			label: "No (Visible)"
																		}
																	].map((visible, i) => (
																		<option
																			key={`visible-${i}-${visible.value}`}
																			value={visible.value}
																		>
																			{visible.label}
																		</option>
																	))}
																</select>
															</div> */}
														</div>
														<div className="row">
															<div className="col-8">
																<div className="form-group">
																	<input
																		name="search"
																		className="form-control"
																		id="search"
																		placeholder="Filter Parts"
																		value={this.state.search}
																		onChange={e =>
																			this.onSearchChange(
																				"search",
																				e.target.value
																			)
																		}
																	/>
																</div>
															</div>

															<div className="col-4">
																<button
																	className="btn btn-block btn-secondary"
																	onClick={this.onSearch}
																>
																	Search
																</button>
															</div>
														</div>
														<ul>
															{this.state.parts.map(
																(machine_part, i) => (
																	<li key={i}>
																		<label
																			style={{
																				color: "black"
																			}}
																		>
																			<div className="row">
																				<div className="col-sm-8">
																					{"[" +
																						machine_part.machine_category_model_number +
																						"]\t-\t" +
																						machine_part.machine_category
																					// "\t-\t[" +
																					// machine_part.year_price_count +
																					// "]" +
																					// "\t-\t[" +
																					// machine_part.cost_year_price_count +
																					// "]" +
																					// "\t-\t[" +
																					// machine_part.year +
																					// "]"
																					}
																				</div>
																				<div className="col-sm-4">
																					<Link
																						to={
																							"/update-machine-category-with-price/" +
																							machine_part.machine_category_id +
																							"/" +
																							machine_part.year_price_count +
																							"/" +
																							machine_part.cost_year_price_count +
																							"/" +
																							machine_part.yearly_price_id +
																							"/" +
																							machine_part.country_id
																						}
																					>
																						<i className="fa text-dark fa-pencil"></i>
																					</Link>
																				</div>
																			</div>
																		</label>
																	</li>
																)
															)}
														</ul>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div>
					<div
						className="content"
						style={{
							minHeight: 500
						}}
					>
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-header">
										<strong className="card-title">
											Machine Category [Price]
										</strong>
									</div>
									<div className="card-body">
										<div className="card-content">
											<div className="todo-list">
												<div className="tdl-holder">
													<div className="tdl-content">
														<div className="row">
															<div className="col"></div>
															<div className="col">
																<div className="text-center">
																	<Loader
																		type="Rings"
																		color="#00BFFF"
																		height={100}
																		width={100}
																	/>
																	<div className="text-center client-roboto-condensed">
																		LOADING...
																	</div>
																</div>
															</div>
															<div className="col"></div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}
}
const mapStateToProps = state => {
	return {
		machine_parts: state.showMachinePartReducer.machine_parts,
		message: state.showMachinePartReducer.message,
		user: state.loginReducer.user
	};
};

export default connect(mapStateToProps, {})(ListMachineCategoryWithPrice);
