import React, { Component } from "react";
import { Tree } from "primereact/tree";
import { show_machine_categories } from "./../action";
import { connect } from "react-redux";
import { MachineCategoryService } from "./../service";
import { Link } from "react-router-dom";
import { clean_message } from "./../../specifications/action";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { notification } from "antd";

class AddSpecificationCategory extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			machine_categories: [],
			tree: false,
			selectedNodeKey: "",
			selectedNodeValue: ""
		};
	}

	async componentDidMount() {
		let redirect = this;

		let data = await MachineCategoryService.getTreeSelectData();
		redirect.setState({ tree: data });

		if (
			redirect.props.specificationMessage !== "" &&
			redirect.props.specificationMessage
		) {
			if (
				redirect.props.specificationMessage
					.toLowerCase()
					.includes("success")
			) {
				notification.success({
					message: "Success!",
					description: redirect.props.specificationMessage
				});
			} else {
				notification.error({
					message: "Error!",
					description: redirect.props.specificationMessage
				});
			}
		}

		await redirect.props.clean_message();

		redirect.setState({ loading: false });
	}

	render() {
		if (this.state.tree || !this.state.loading) {
			return (
				<div>
					<div
						className="content"
						style={{
							minHeight: 500
						}}
					>
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-header">
										<strong className="card-title">
											Machine Categories
										</strong>
									</div>
									<div className="card-body">
										<div className="card-content">
											<div className="todo-list">
												<div className="tdl-content">
													<div className="card">
														<div className="card-body">
															<div className="row">
																<div className="col text-left">
																	Add Specification for
																	Selected
																</div>
																<div className="col text-right">
																	{this.state
																		.selectedNodeKey ? (
																		<div>
																			{/* <Link
                                        to={
                                          "/add-specification/" +
                                          this.state.selectedNodeKey
                                        }
                                      >
                                        <i className="fa ml-1 mr-1 text-dark fa-plus"></i>
                                      </Link> */}
																			<Link
																				to={
																					"/list-specifications/" +
																					this.state
																						.selectedNodeKey
																				}
																			>
																				<i className="fa ml-1 mr-1 text-dark fa-list"></i>
																			</Link>
																		</div>
																	) : null}
																</div>
															</div>
														</div>
													</div>
													<Tree
														value={this.state.tree}
														selectionMode="single"
														selectionKeys={
															this.state.selectedNodeKey
														}
														onSelectionChange={e =>
															this.setState({
																selectedNodeKey: e.value
															})
														}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div>
					<div
						className="content"
						style={{
							minHeight: 500
						}}
					>
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-header">
										<strong className="card-title">
											Machine Categories
										</strong>
									</div>
									<div className="card-body">
										<div className="card-content">
											<div className="todo-list">
												<div className="tdl-holder">
													<div className="tdl-content">
														<div className="row">
															<div className="col"></div>
															<div className="col">
																<div className="text-center">
																	<Loader
																		type="Rings"
																		color="#00BFFF"
																		height={100}
																		width={100}
																	/>
																	<div className="text-center client-roboto-condensed">
																		LOADING...
																	</div>
																</div>
															</div>
															<div className="col"></div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}
}
const mapStateToProps = state => {
	return {
		machine_categories: state.showMachineCategoryReducer.machine_categories,
		message: state.showMachineCategoryReducer.message,
		specificationMessage: state.specificationReducer.message
	};
};

export default connect(mapStateToProps, {
	show_machine_categories,
	clean_message
})(AddSpecificationCategory);
