import React, { Component } from "react";
import { connect } from "react-redux";
import { Api } from "./../../../config/api";
import SimpleReactValidator from "simple-react-validator";
import { Calendar } from "primereact/calendar";
import Swal from "sweetalert2";
import { css } from "@emotion/react";
import { PulseLoader } from "react-spinners";
import getSymbolFromCurrency from "currency-symbol-map";
import { Redirect } from "react-router-dom";

class OrderForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			is_update: 0,
			redirect: false,
			cannotErrorMessage: false,
			loading: true,
			order_data: false,
			configurations: false,
			dealer_name: "",
			v55_email_address: "",
			order_number: "",
			order_date: new Date(),
			purchase_order_number: "",
			order_name: "",
			end_user_name: "",
			countries: false,
			country: 1,
			state: "",
			city: "",
			postal_code: "",
			address1: "",
			address2: "",
			delivery_option: 0,
			delivery_for_retail: 0,
			salesman_name: "",
			salesman_contact: "",
			finance_account_number: "",
			financeCompanyExists: true,
			finance_company_name: "",
			exisiting_finance_companies: false,
			exisiting_finance_company: null,
			dealer_request_date: new Date(),
			expected_delivery_date: new Date(),
			payment_terms: false,
			type: false,
			errorMessage: ""
		};
		this.validator = new SimpleReactValidator({ autoForceUpdate: this });
		this.handleSelectChange = this.handleSelectChange.bind(this);
		this.handleTextChange = this.handleTextChange.bind(this);
		this.handleDateChange = this.handleDateChange.bind(this);
		this.onSumbit = this.onSumbit.bind(this);
		this.onSaveOrderFormData = this.onSaveOrderFormData.bind(this);
	}

	formatDate(date) {
		let formatted_date = date;
		let newMonth = parseInt(formatted_date.getMonth()) + 1;

		return (
			formatted_date.getFullYear() +
			"-" +
			newMonth +
			"-" +
			formatted_date.getDate()
		);
	}

	handleConfigurationTextChange(name, i, value) {
		let configurations = this.state.configurations;
		switch (name) {
			case "special_instruction":
				configurations.forEach(element => {
					if (element.configuration[0].configuration_id === i) {
						element.configuration[0].special_instruction = value;
					}
				});

				this.setState({ configurations: configurations });
				break;

			case "trade_in_information":
				configurations.forEach(element => {
					if (element.configuration[0].configuration_id === i) {
						element.configuration[0].trade_in_information = value;
					}
				});

				this.setState({ configurations: configurations });
				break;

			case "serial_number":
				configurations.forEach(element => {
					if (element.configuration[0].configuration_id === i) {
						element.configuration[0].serial_number = value;
					}
				});

				this.setState({ configurations: configurations });
				break;

			default:
				break;
		}
	}

	handleDateChange(name, value) {
		let date = value;

		switch (name) {
			case "expected_delivery_date":
				this.setState({ expected_delivery_date: date });
				break;

			case "dealer_request_date":
				this.setState({ dealer_request_date: date });
				break;

			default:
				break;
		}
	}

	handleSelectChange(name, value) {
		switch (name) {
			case "delivery_option":
				this.setState({ delivery_option: value });
				break;

			case "delivery_for_retail":
				this.setState({ delivery_for_retail: value });
				break;

			case "country":
				this.setState({ country: value });
				break;

			case "exisiting_finance_company":
				this.setState({ exisiting_finance_company: value });
				break;

			case "expected_date":
				this.setState({ type: value });
				break;

			default:
				break;
		}
	}

	handleTextChange(name, value) {
		switch (name) {
			case "dealer_name":
				this.setState({ dealer_name: value });
				break;

			case "v55_email_address":
				this.setState({ v55_email_address: value });
				break;

			case "state":
				this.setState({ state: value });
				break;

			case "city":
				this.setState({ city: value });
				break;

			case "postal_code":
				this.setState({ postal_code: value });
				break;

			case "address1":
				this.setState({ address1: value });
				break;

			case "address2":
				this.setState({ address2: value });
				break;

			case "salesman_name":
				this.setState({ salesman_name: value });
				break;

			case "salesman_contact":
				this.setState({ salesman_contact: value });
				break;

			case "finance_account_number":
				this.setState({ finance_account_number: value });
				break;

			case "finance_company_name":
				this.setState({ finance_company_name: value });
				break;

			case "purchase_order_number":
				this.setState({ purchase_order_number: value });
				break;

			default:
				break;
		}
	}

	async onSaveOrderFormData(e) {
		e.preventDefault();
		this.setState({loading:true});

		if(this.state && this.state.order_data && this.state.order_data.order && this.state.order_data.order[0].status.toUpperCase() === 'submitted order'.toUpperCase()){
			Swal.fire({
				title: "Warning!",
				text: "Cannot update submitted order.",
				icon: "error",
				confirmButtonText: "OK"
			});
			await this.setState({ loading: false });

			return
		}
		let { id } = this.props.match.params;
		let that = this;
		this.setState({ errorMessage: "" });
		if (this.validator.allValid()) {
			this.setState({ errorMessage: "" });

			let configurations = [];

			this.state.configurations.forEach(element => {
				configurations.push({
					configuration_id: element.configuration[0].configuration_id,
					serial_number: element.configuration[0].serial_number,
					trade_in_information:
						element.configuration[0].trade_in_information,
					special_instruction: element.configuration[0].special_instruction
				});
			});

			let params = {
				is_update: this.state.is_update,
				dealer_name: this.state.dealer_name,
				v55_email_address: this.state.v55_email_address,
				country: this.state.country,
				state: this.state.state,
				city: this.state.city,
				postal_code: this.state.postal_code,
				address1: this.state.address1,
				address2: this.state.address2,
				delivery_option: this.state.delivery_option,
				delivery_for_retail: this.state.delivery_for_retail,
				salesman_name: this.state.salesman_name,
				salesman_contact: this.state.salesman_contact,
				finance_account_number: this.state.finance_account_number,
				finance_company_name: this.state.finance_company_name,
				exisiting_finance_company:
					this.state.exisiting_finance_company === undefined
						? null
						: this.state.exisiting_finance_company,
				dealer_request_date: this.formatDate(
					this.state.dealer_request_date
				),
				expected_delivery_date: this.formatDate(
					this.state.expected_delivery_date
				),
				type: this.state.type,
				finance_company_exists: this.state.financeCompanyExists,
				payment_terms: this.state.payment_terms,
				configurations: configurations,
				user_id: this.props.user_id,
				purchase_order_number: this.state.purchase_order_number
			};

			await Api.post("/order/saveOrderFormData/" + id, params)
				.then(function (response) {
					Swal.fire({
						title: "Success!",
						text: response.data.message,
						icon: "success",
						confirmButtonText: "Ok"
					});
					that.setState({ redirect: true });
				})
				.catch(function (error) {
					Swal.fire({
						title: "Info!",
						text: "Order form data not saved!",
						icon: "info",
						confirmButtonText: "Ok"
					});
				});
		} else {
			this.validator.showMessages();
		}

		await this.setState({ loading: false });
	}

	async onSumbit(e) {
		await this.setState({ loading: true });
		e.preventDefault();
		let { id } = this.props.match.params;
		let that = this;

		this.setState({ errorMessage: "" });
		if (this.validator.allValid()) {
			this.setState({ errorMessage: "" });

			let configurations = [];

			this.state.configurations.forEach(element => {
				configurations.push({
					configuration_id: element.configuration[0].configuration_id,
					serial_number: element.configuration[0].serial_number,
					trade_in_information:
						element.configuration[0].trade_in_information,
					special_instruction: element.configuration[0].special_instruction
				});
			});

			let params = {
				is_update: this.state.is_update,
				dealer_name: this.state.dealer_name,
				v55_email_address: this.state.v55_email_address,
				country: this.state.country,
				state: this.state.state,
				city: this.state.city,
				postal_code: this.state.postal_code,
				address1: this.state.address1,
				address2: this.state.address2,
				delivery_option: this.state.delivery_option,
				delivery_for_retail: this.state.delivery_for_retail,
				salesman_name: this.state.salesman_name,
				salesman_contact: this.state.salesman_contact,
				finance_account_number: this.state.finance_account_number,
				finance_company_name: this.state.finance_company_name,
				exisiting_finance_company:
					this.state.exisiting_finance_company === undefined
						? null
						: this.state.exisiting_finance_company,
				dealer_request_date: this.formatDate(
					this.state.dealer_request_date
				),
				expected_delivery_date: this.formatDate(
					this.state.expected_delivery_date
				),
				type: this.state.type,
				finance_company_exists: this.state.financeCompanyExists,
				payment_terms: this.state.payment_terms,
				configurations: configurations,
				user_id: this.props.user_id,
				purchase_order_number: this.state.purchase_order_number,
			};

			Api.defaults.timeout = 100000
			await Api.post("/order/updateOrderFormData/" + id, params)
				.then(function (response) {
					Swal.fire({
						title: "Success!",
						text: response.data.message,
						icon: "success",
						confirmButtonText: "Ok"
					});
					that.setState({ redirect: true });
				})
				.catch(function (error) {
					Swal.fire({
						title: "Info!",
						text: "Order form data not saved!",
						icon: "info",
						confirmButtonText: "Ok"
					});
				});
		} else {
			this.validator.showMessages();
		}

		await this.setState({ loading: false });
	}

	async componentDidMount() {
		let { id } = this.props.match.params;
		let that = this;

		await Api.get("/order/getOrderFormData/" + id)
			.then(function (response) {
				that.setState({ order_data: response.data });
				that.setState({ order_name: response.data.order[0].order_name });
				that.setState({ order_number: response.data.order[0].id });
				that.setState({
					end_user_name: response.data.order[0].quotation_for
				});
				that.setState({
					order_date:
						response.data.order[0].order_date !== null &&
							response.data.order[0].order_date !== "" &&
							response.data.order[0].order_date !== undefined &&
							response.data.order[0].order_date
							? new Date(
								response.data.order[0].order_date.replace(/-/g, "/")
							)
							: new Date()
				});
				if (response.data.order[0].customer_id !== null) {
					that.setState({ is_update: 1 });
				}
				that.setState({
					dealer_name: response.data.order[0].customer_name
				});
				that.setState({
					v55_email_address:
						response.data.order[0].customer_v55_email_address
				});
				if (
					response.data.order[0].country !== "" &&
					response.data.order[0].country !== undefined &&
					response.data.order[0].country &&
					response.data.order[0].country !== null
				) {
					that.setState({ country: response.data.order[0].country });
				} else {
					that.setState({ country: 1 });
				}
				that.setState({ state: response.data.order[0].customer_state });
				that.setState({ city: response.data.order[0].customer_city });
				that.setState({
					postal_code: response.data.order[0].customer_postal_code
				});
				that.setState({
					address1: response.data.order[0].customer_address1
				});
				that.setState({
					address2: response.data.order[0].customer_address2
				});

				if (
					response.data.order[0].delivery_option !== undefined &&
					response.data.order[0].delivery_option !== null &&
					response.data.order[0].delivery_option !== ""
				) {
					that.setState({
						delivery_option: response.data.order[0].delivery_option
					});
				}

				if (
					response.data.order[0].delivery_type !== undefined &&
					response.data.order[0].delivery_type !== null &&
					response.data.order[0].delivery_type !== ""
				) {
					that.setState({
						delivery_for_retail: response.data.order[0].delivery_type
					});
				}

				that.setState({
					finance_account_number:
						response.data.order[0].customer_account_number
				});
				that.setState({
					exisiting_finance_company: response.data.order[0].finance_id
				});
				that.setState({
					finance_company_name: response.data.order[0].finance_name
				});

				that.setState({
					purchase_order_number: response.data.order[0].purchase_order_number
				});

				that.setState({
					type:
						parseInt(response.data.order[0].customer_type) === 0 ||
							response.data.order[0].customer_type === null ||
							response.data.order[0].customer_type === undefined
							? false
							: true
				});

				if (
					parseInt(response.data.order[0].customer_type) === 0 ||
					response.data.order[0].customer_type === null ||
					response.data.order[0].customer_type === undefined
				) {
					if (
						response.data.order[0].expected_date !== undefined &&
						response.data.order[0].expected_date !== null &&
						response.data.order[0].expected_date !== ""
					) {
						that.setState({
							expected_delivery_date: new Date(
								response.data.order[0].expected_date.replace(/-/g, "/")
							)
						});
					} else {
						that.setState({
							expected_delivery_date: new Date()
						});
					}
				} else {
					if (
						response.data.order[0].expected_date !== undefined &&
						response.data.order[0].expected_date !== null &&
						response.data.order[0].expected_date !== "" &&
						response.data.order[0].expected_date
					) {
						that.setState({
							dealer_request_date: new Date(
								response.data.order[0].expected_date.replace(/-/g, "/")
							)
						});
					} else {
						that.setState({
							dealer_request_date: new Date()
						});
					}
				}

				if (
					response.data.order[0].payment_terms !== 0 &&
					response.data.order[0].payment_terms !== null &&
					response.data.order[0].payment_terms !== undefined &&
					response.data.order[0].payment_terms
				) {
					that.setState({ payment_terms: true });
				} else {
					that.setState({ payment_terms: false });
				}

				that.setState({ configurations: response.data.configurations });
			})
			.catch(function (error) {
				that.setState({
					cannotErrorMessage:
						"You cannot update this order form. There is something wrong!! Please contact the administrator!"
				});
			});

		await Api.get("/country").then(function (response) {
			that.setState({ countries: response.data });
		});

		await Api.get("/getAllFinanceCompanies").then(function (response) {
			that.setState({ exisiting_finance_companies: response.data });
		});

		await this.setState({ loading: false });
	}

	render() {
		const override = css`
			display: block;
			margin: 0 auto;
			border-color: red;
		`;
		if (this.state.redirect) {
			return <Redirect to="/orders" />;
		} else {
			if (this.state.loading) {
				return (
					<div className="sweet-loading">
						<div className="container loading-container">
							<div className="row m-5 p-5">
								<PulseLoader
									css={override}
									sizeUnit={"px"}
									size={24}
									color={"#B9373D"}
								/>
							</div>
						</div>
					</div>
				);
			} else {
				if (!this.state.cannotErrorMessage) {
					return (
						<div>
							<div className="mt-4 mb-4 client-roboto-condensed container">
								<div className="text-center">
									<h4
										className="my-5"
										style={{
											fontWeight: 900
										}}
									>
										Order Form
									</h4>
								</div>
								<div className="row">
									<div className="col-sm-12">
										<form>
											<div className="row">
												<div className="form-group col-sm-6">
													<label>Dealer SC Name</label>
													<input
														type="text"
														className="form-control"
														value={this.state.dealer_name}
														onChange={e =>
															this.handleTextChange(
																"dealer_name",
																e.target.value
															)
														}
													/>
													<div
														className="mt-4"
														style={{
															color: "red"
														}}
													>
														{this.validator.message(
															"dealer name",
															this.state.dealer_name,
															"required|string"
														)}
													</div>
												</div>
												<div className="form-group col-sm-6">
													<label>V55 Required?</label>
													<input
														type="text"
														className="form-control"
												// 		value={
												// 			this.state.v55_email_address !==
												// 				null
												// 				? this.state.v55_email_address
												// 				: ""
												// 		}
        												value={
        												    parseInt(this.state.v55_email_address, 10) === 1 ? "Yes" : "No"
        												}
														onChange={e =>
															this.handleTextChange(
																"v55_email_address",
																e.target.value
															)
														}
													/>
													<div
														className="mt-4"
														style={{
															color: "red"
														}}
													>
														{this.validator.message(
															"v55 email address",
															this.state.v55_email_address,
															"email"
														)}
													</div>
												</div>
											</div>
											<div className="row">
												<div className="form-group col-sm-6">
													<label>Order Number</label>
													<input
														type="text"
														className="form-control"
														disabled
														value={this.state.order_number}
													/>
												</div>
												<div className="form-group col-sm-6 row pr-0">
													<div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
														<label>Order Date</label>
														{/*
															<input
																type="text"
																className="form-control"
																disabled
																value={this.state.order_date}
															/>
														*/}
														<br/>
														<Calendar
															dateFormat="dd-mm-yy"
															showIcon={true}
															value={this.state.order_date}
															className="ml-1"
															disabled
														></Calendar>
													</div>
													<div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 purchase-order">
														<label>Purchase Order Number</label>
														<br/>
															<input
																type="text"
																className="form-control"
																value={this.state.purchase_order_number}
																onChange={e =>
																	this.handleTextChange(
																		"purchase_order_number",
																		e.target.value
																	)
																}
															/>
													</div>

												</div>
											</div>
											<div className="row">
												<div className="form-group col-sm-6">
													<label>Order Name</label>
													<input
														type="text"
														className="form-control"
														disabled
														value={this.state.order_name}
													/>
												</div>
												<div className="form-group col-sm-6">
													<label>End User Name</label>
													<input
														type="text"
														className="form-control"
														disabled
														value={this.state.end_user_name}
													/>
												</div>
											</div>
											<div className="row">
												<div className="form-group col-sm-6">
													<label>Country</label>
													<select
														className="custom-select"
														value={this.state.country}
														onChange={e =>
															this.handleSelectChange(
																"country",
																e.target.value
															)
														}
													>
														<option>Select Country</option>
														{this.state.countries
															? this.state.countries.map(
																(country, i) => (
																	<option
																		key={i}
																		value={country.id}
																	>
																		{country.country_name}
																	</option>
																)
															)
															: null}
													</select>
													<div
														className="mt-4"
														style={{
															color: "red"
														}}
													>
														{this.validator.message(
															"country",
															this.state.country,
															"required|numeric"
														)}
													</div>
												</div>
												<div className="form-group col-sm-6">
													<label>State / Province</label>
													<input
														type="text"
														className="form-control"
														value={this.state.state}
														onChange={e =>
															this.handleTextChange(
																"state",
																e.target.value
															)
														}
													/>
													<div
														className="mt-4"
														style={{
															color: "red"
														}}
													>
														{this.validator.message(
															"state",
															this.state.state,
															"required|string"
														)}
													</div>
												</div>
											</div>
											<div className="row">
												<div className="form-group col-sm-6">
													<label>City</label>
													<input
														type="text"
														className="form-control"
														value={this.state.city}
														onChange={e =>
															this.handleTextChange(
																"city",
																e.target.value
															)
														}
													/>
													<div
														className="mt-4"
														style={{
															color: "red"
														}}
													>
														{this.validator.message(
															"city",
															this.state.city,
															"required|string"
														)}
													</div>
												</div>
												<div className="form-group col-sm-6">
													<label>Postal Code</label>
													<input
														type="text"
														className="form-control"
														value={this.state.postal_code}
														onChange={e =>
															this.handleTextChange(
																"postal_code",
																e.target.value
															)
														}
													/>
													<div
														className="mt-4"
														style={{
															color: "red"
														}}
													>
														{this.validator.message(
															"postal code",
															this.state.postal_code,
															"required|string"
														)}
													</div>
												</div>
											</div>
											<div className="row">
												<div className="form-group col-sm-6">
													<label>Address 1</label>
													<input
														type="text"
														className="form-control"
														value={this.state.address1}
														onChange={e =>
															this.handleTextChange(
																"address1",
																e.target.value
															)
														}
													/>
													<div
														className="mt-4"
														style={{
															color: "red"
														}}
													>
														{this.validator.message(
															"user address",
															this.state.address1,
															"required|string"
														)}
													</div>
												</div>
												<div className="form-group col-sm-6">
													<label>Address 2</label>
													<input
														type="text"
														className="form-control"
														value={
															this.state.address2 === null
																? ""
																: this.state.address2
														}
														placeholder="[Optional]"
														onChange={e =>
															this.handleTextChange(
																"address2",
																e.target.value
															)
														}
													/>
													<div
														className="mt-4"
														style={{
															color: "red"
														}}
													>
														{this.validator.message(
															"address",
															this.state.address2,
															"string"
														)}
													</div>
												</div>
											</div>
											<div className="row">
												<div className="form-group col-sm-6">
													<label>Delivery Option</label>
													<select
														className="custom-select"
														value={this.state.delivery_option}
														onChange={e =>
															this.handleSelectChange(
																"delivery_option",
																e.target.value
															)
														}
													>
														<option value={0}>
															Delivery individually
														</option>
														<option value={1}>
															Delivery all together
														</option>
													</select>
													<div
														className="mt-4"
														style={{
															color: "red"
														}}
													>
														{this.validator.message(
															"delivery option",
															this.state.delivery_option,
															"required|numeric"
														)}
													</div>
												</div>
												<div className="form-group col-sm-6">
													<label>Delivery for Retail</label>
													<select
														className="custom-select"
														value={this.state.delivery_for_retail}
														onChange={e =>
															this.handleSelectChange(
																"delivery_for_retail",
																e.target.value
															)
														}
													>
														<option value={0}>Transport</option>
														<option value={1}>SalesMan</option>
													</select>
													<div
														className="mt-4"
														style={{
															color: "red"
														}}
													>
														{this.validator.message(
															"delivery for retail",
															this.state.delivery_for_retail,
															"required|numeric"
														)}
													</div>
												</div>
											</div>
											<div className="row">
												<div className="form-group col-sm-6">
													<label>Quote Made By</label>
													<input
														type="text"
														className="form-control"
														value={
															this.state.order_data.order[0].name
														}
														disabled
													/>
												</div>
												<div className="form-group col-sm-6">
													<label>Contact Email</label>
													<input
														type="text"
														className="form-control"
														value={
															this.state.order_data.order[0]
																.email
														}
														disabled
													/>
												</div>
											</div>
											<div className="row">
												<div className="form-group col-sm-6">
													<label>Order By</label>
													<input
														type="text"
														className="form-control"
														value={this.props.user.name}
														disabled
													/>
												</div>
												<div className="form-group col-sm-6">
													<label>Contact Email</label>
													<input
														type="text"
														className="form-control"
														value={this.props.user.email}
														disabled
													/>
												</div>
											</div>
											<div className="row">
												<div className="form-group col-sm-6">
													<label>Finance Account Number</label>
													<input
														type="text"
														className="form-control"
														value={
															this.state
																.finance_account_number !== null
																? this.state
																	.finance_account_number
																: ""
														}
														onChange={e =>
															this.handleTextChange(
																"finance_account_number",
																e.target.value
															)
														}
													/>
													<div
														className="mt-4"
														style={{
															color: "red"
														}}
													>
														{this.validator.message(
															"finance account number",
															this.state.finance_account_number,
															"string"
														)}
													</div>
												</div>
												<div className="form-group col-sm-6">
													<label>Finance Company</label>
													<br />
													Doesn't exist?
													<input
														className="form-control mb-4"
														type="checkbox"
														value={
															this.state.financeCompanyExists
														}
														onChange={e =>
															this.setState({
																financeCompanyExists: !this
																	.state.financeCompanyExists
															})
														}
													/>
													{this.state.financeCompanyExists ? (
														<>
															<select
																className="custom-select"
																value={
																	this.state
																		.exisiting_finance_company
																}
																onChange={e =>
																	this.handleSelectChange(
																		"exisiting_finance_company",
																		e.target.value
																	)
																}
															>
																<option value={null}>
																	Select one
																</option>
																{this.state
																	.exisiting_finance_companies &&
																	this.state
																		.exisiting_finance_companies !==
																	[]
																	? this.state.exisiting_finance_companies.map(
																		(company, i) => (
																			<option
																				key={
																					company.finance_id +
																					i
																				}
																				value={
																					company.finance_id
																				}
																			>
																				{
																					company.finance_name
																				}
																			</option>
																		)
																	)
																	: null}
															</select>
														</>
													) : (
														<>
															<input
																type="text"
																className="form-control"
																value={
																	this.state
																		.finance_company_name
																}
																placeholder="Enter finance company name"
																onChange={e =>
																	this.handleTextChange(
																		"finance_company_name",
																		e.target.value
																	)
																}
															/>
														</>
													)}
													{this.state.errorMessage !== "" ? (
														<div
															className="mt-4"
															style={{
																color: "red"
															}}
														>
															{this.state.errorMessage}
														</div>
													) : null}
												</div>
											</div>

											<div className="row">
												<div className="form-group col-sm-6">
													Dealer?
													<br />
													<input
														type="checkbox"
														defaultChecked={this.state.type}
														onClick={e =>
															this.setState({
																type: this.state.type
																	? false
																	: true
															})
														}
													/>
													<br />
													{this.state.type ? (
														<>
															<label>Dealer Request Date</label>
															<Calendar
																dateFormat="dd-mm-yy"
																showIcon={true}
																value={
																	this.state
																		.dealer_request_date
																}
																className="ml-2"
																onChange={e =>
																	this.handleDateChange(
																		"dealer_request_date",
																		e.value
																	)
																}
															></Calendar>
														</>
													) : (
														<>
															<label>
																Customer Request Date
															</label>
															<Calendar
																// minDate={new Date()}
																dateFormat="dd-mm-yy"
																showIcon={true}
																value={
																	this.state
																		.expected_delivery_date
																}
																className="ml-2"
																onChange={e =>
																	this.handleDateChange(
																		"expected_delivery_date",
																		e.value
																	)
																}
															></Calendar>
														</>
													)}
												</div>
												<div className="form-group col-sm-6">
													<label>Payment Terms?</label>
													<input
														type="checkbox"
														className="form-control"
														value={this.state.payment_terms}
														defaultChecked={
															this.state.payment_terms
														}
														onClick={e =>
															this.setState({
																payment_terms: !this.state
																	.payment_terms
															})
														}
													/>
												</div>
											</div>
										</form>
									</div>
								</div>
								{this.state.configurations &&
									this.state.configurations !== null &&
									this.state.configurations !== [] &&
									this.state.configurations !== "" ? (
									<div className="row my-5">
										<div className="col-sm-12">
											{this.state.configurations.map((config, i) => (
												<>
													<hr
														key={config.configuration[0].name + i}
														className="my-5"
													/>
													<h6 key={i}>
														{config.configuration[0].name}
													</h6>
													<div className="row">
														<div className="row col-sm-8">
															<div className="form-group col-sm-6">
																<label>
																	Configuration Name
																</label>
																<input
																	type="text"
																	className="form-control"
																	disabled
																	value={
																		config.configuration[0]
																			.name
																	}
																/>
															</div>
															<div className="form-group col-sm-6">
																<label>Serial Number</label>
																<input
																	type="text"
																	className="form-control"
																	value={
																		config.configuration[0]
																			.serial_number !== null
																			? config
																				.configuration[0]
																				.serial_number
																			: ""
																	}
																	onChange={e =>
																		this.handleConfigurationTextChange(
																			"serial_number",
																			config.configuration[0]
																				.configuration_id,
																			e.target.value
																		)
																	}
																/>
																<div
																	className="mt-4"
																	style={{
																		color: "red"
																	}}
																>
																	{this.validator.message(
																		"serial number",
																		config.configuration[0]
																			.serial_number,
																		"string"
																	)}
																</div>
															</div>
															<div className="form-group col-sm-6">
																<label>
																	Special Instruction
																</label>
																<textarea
																	className="form-control"
																	value={
																		config.configuration[0]
																			.special_instruction !==
																			null
																			? config
																				.configuration[0]
																				.special_instruction
																			: ""
																	}
																	onChange={e =>
																		this.handleConfigurationTextChange(
																			"special_instruction",
																			config.configuration[0]
																				.configuration_id,
																			e.target.value
																		)
																	}
																/>
																<div
																	className="mt-4"
																	style={{
																		color: "red"
																	}}
																>
																	{this.validator.message(
																		"special instruction",
																		config.configuration[0]
																			.special_instruction,
																		"string"
																	)}
																</div>
															</div>
															<div className="form-group col-sm-6">
																<label>
																	Trade-In Information
																</label>
																<textarea
																	className="form-control"
																	value={
																		config.configuration[0]
																			.trade_in_information !==
																			null
																			? config
																				.configuration[0]
																				.trade_in_information
																			: ""
																	}
																	onChange={e =>
																		this.handleConfigurationTextChange(
																			"trade_in_information",
																			config.configuration[0]
																				.configuration_id,
																			e.target.value
																		)
																	}
																/>
																<div
																	className="mt-4"
																	style={{
																		color: "red"
																	}}
																>
																	{this.validator.message(
																		"trade in information",
																		config.configuration[0]
																			.trade_in_information,
																		"string"
																	)}
																</div>
															</div>
														</div>
														<div className="row col-sm-4">
															<div className="form-group col-sm-12">
																<label>Discount Given</label>
																<input
																	type="text"
																	className="form-control"
																	disabled
																	value={
																		config.configuration[0]
																			.discount_percentage
																	}
																/>
															</div>
															<div className="form-group col-sm-12">
																<label>Discount Amount</label>
																<input
																	type="text"
																	className="form-control"
																	disabled
																	value={
																		config.configuration[0]
																			.discount_amount
																	}
																/>
															</div>
															<div className="form-group col-sm-12">
																<label>
																	Total Retail Price
																</label>
																<input
																	type="text"
																	className="form-control"
																	disabled
																	value={config.configuration[0].machine_total_price.toFixed(
																		2
																	)}
																/>
															</div>
															<div className="form-group col-sm-12">
																<label>Net Net Net Price</label>
																<input
																	type="text"
																	className="form-control"
																	disabled
																	value={(
																		config.configuration[0]
																			.machine_total_price -
																		(config.configuration[0]
																			.discount_amount * config.configuration[0]
																				.quantity) -
																		(config.configuration[0]
																			.machine_total_price *
																			config.configuration[0]
																				.discount_percentage) /
																		100
																	).toFixed(2)}
																/>
															</div>
															<div className="form-group col-sm-12">
																<label>Trade In value</label>
																<input
																	type="text"
																	className="form-control"
																	disabled
																	value={config.configuration[0]
																		.trade_in_value}
																/>
															</div>
															<div className="form-group col-sm-12">
																<label>Quantity</label>
																<input
																	type="text"
																	className="form-control"
																	disabled
																	value={config.configuration[0]
																		.quantity}
																/>
															</div>
														</div>
													</div>
													<div className="row">
														<div className="col-sm-12">
															<div className="row">
																<div className="client-selected-parts">
																	<table className="table table-striped table-responsive-md">
																		<thead className="thead-dark">
																			<tr>
																				{/* <th scope="col" className="min-width-table">#</th> */}
																				<th
																					scope="col"
																					className="min-width-table"
																				>
																					Model Number
																				</th>
																				<th
																					scope="col"
																					className="min-width-table"
																				>
																					Description
																				</th>
																				<th
																					scope="col"
																					className="min-width-table"
																				>
																					Remarks
																				</th>
																				<th
																					scope="col"
																					className="min-width-table"
																				>
																					Note
																				</th>
																				<th
																					scope="col"
																					className="min-width-table"
																				>
																					Price
																				</th>
																				<th
																					scope="col"
																					className="min-width-table"
																				>
																					Quantity
																				</th>
																				<th
																					scope="col"
																					className="min-width-table"
																				>
																					Total Price
																				</th>
																			</tr>
																		</thead>
																		<tbody>
																			<tr
																				style={{
																					backgroundColor:
																						"#B9373D",
																					color: "#ffffff"
																				}}
																			>
																				<td
																					style={{
																						color:
																							"white",
																						fontWeight: 900,
																						fontSize: 15
																					}}
																				>
																					Components
																				</td>
																				<td></td>
																				<td></td>
																				<td></td>
																				<td></td>
																				<td></td>
																				<td></td>
																				{/* <td></td> */}
																			</tr>
																			<tr>
																				{/* <th scope="col" className="min-width-table">#</th> */}
																				<td>
																					{
																						config
																							.configuration[0]
																							.machine_category_model_number
																					}
																				</td>
																				<td>
																					{
																						config
																							.configuration[0]
																							.machine_category
																					}
																				</td>
																				<td></td>
																				<td></td>
																				<td>
																					{getSymbolFromCurrency(
																						config
																							.configuration[0]
																							.currency_symbol_html
																					)}{" "}
																					{
																						config
																							.configuration[0]
																							.price
																					}
																				</td>
																				<td>{config.configuration ?
																					config.configuration[0].only_parts ? 0 : 1 : 1}</td>
																				<td>
																					{getSymbolFromCurrency(
																						config
																							.configuration[0]
																							.currency_symbol_html
																					)}{" "}
																					{
																						config.configuration ?
																							config.configuration[0].only_parts ? 0 : parseFloat(config.configuration[0].price).toFixed(2) : parseFloat(config.configuration[0].price).toFixed(2)
																					}
																					{/* {
																						config
																							.configuration[0]
																							.price
																					} */}
																				</td>
																			</tr>
																			<tr
																				style={{
																					backgroundColor:
																						"#B9373D",
																					color: "#ffffff"
																				}}
																			>
																				<td
																					style={{
																						color:
																							"white",
																						fontWeight: 900,
																						fontSize: 15
																					}}
																				>
																					Parts
																				</td>
																				<td></td>
																				<td></td>
																				<td></td>
																				<td></td>
																				<td></td>
																				<td></td>
																				{/* <td></td> */}
																			</tr>
																			{config.parts.map(
																				(part, i) => (
																					<tr key={i}>
																						{/* <th scope="row" className="min-width-table">{i+1}</th> */}
																						<td className="min-width-table">
																							{
																								part.machine_part_model_number
																							}
																						</td>
																						<td className="min-width-table">
																							{
																								part.machine_part_name
																							}
																						</td>
																						<td className="min-width-table">
																							{part.custom_remark ===
																								"" ||
																								part.custom_remark ===
																								null
																								? part.machine_part_remarks !==
																									null
																									? part.machine_part_remarks
																									: null
																								: part.custom_remark}
																						</td>
																						<td className="min-width-table">
																							{part.custom_note ===
																								"" ||
																								part.custom_note ===
																								null
																								? part.machine_part_note !==
																									null
																									? part.machine_part_note
																									: null
																								: part.custom_note}
																						</td>
																						{part.is_included ? (
																							<>
																								<td className="min-width-table">
																									Included
																								</td>
																								<td className="min-width-table">
																									{
																										part.quantity
																									}
																								</td>
																								<td className="min-width-table">
																									Included
																								</td>
																							</>
																						) : (
																							<>
																								<td className="min-width-table">
																									{getSymbolFromCurrency(
																										part.currency_symbol_html
																									)}{" "}
																									{part.price.toFixed(
																										2
																									)}
																								</td>
																								<td className="min-width-table">
																									{
																										part.quantity
																									}
																								</td>
																								<td className="min-width-table">
																									{getSymbolFromCurrency(
																										part.currency_symbol_html
																									)}{" "}
																									{(
																										part.quantity *
																										part.price
																									).toFixed(
																										2
																									)}
																								</td>
																							</>
																						)}
																					</tr>
																				)
																			)}
																		</tbody>
																	</table>

																	<table className="table table-striped table-responsive-md mt-5">
																		<tbody>
																			{config
																				.manual_adjustments
																				.length > 0 ? (
																				<>
																					<tr
																						style={{
																							backgroundColor:
																								"#B9373D",
																							color:
																								"#ffffff"
																						}}
																					>
																						<td
																							style={{
																								color:
																									"white",
																								fontWeight: 900,
																								fontSize: 15
																							}}
																						>
																							Manual
																							Adjustments
																						</td>
																						<td></td>
																						<td></td>
																						<td></td>
																						{/* <td></td> */}
																						{/* <td></td> */}
																						<td></td>
																						<td></td>
																						{/* <td>
																	</td> */}
																					</tr>
																					<tr className="thead-dark">
																						<th scope="col">
																							Model
																							Number
																						</th>
																						<th scope="col">
																							Description
																						</th>
																						{/* <th scope="col">Priority</th> */}
																						<th scope="col">
																							Note
																						</th>
																						{/* <th scope="col"></th> */}
																						<th scope="col">
																							Price
																						</th>
																						<th scope="col">
																							Quantity
																						</th>
																						<th scope="col">
																							Total Price
																						</th>
																						{/* <th scope="col">Actions</th> */}
																					</tr>
																				</>
																			) : null}
																			{config.manual_adjustments !==
																				null &&
																				config.manual_adjustments !==
																				[]
																				? config.manual_adjustments.map(
																					(
																						adjustment,
																						i
																					) => (
																						<tr
																							key={i}
																						>
																							<td className="min-width-table">
																								{" "}
																								{
																									adjustment.manual_adjustment_model_number
																								}
																							</td>
																							<td className="min-width-table">
																								{
																									adjustment.manual_adjustment_name
																								}
																							</td>
																							<td className="min-width-table">
																								{
																									adjustment.manual_adjustment_note
																								}
																							</td>
																							{/* <td scope="col" className="min-width-table"></td> */}
																							<td className="money min-width-table">
																								{getSymbolFromCurrency(
																									adjustment.currency_symbol_html
																								)}{" "}
																								{parseFloat(
																									adjustment.manual_adjustment_price
																								)}
																							</td>
																							<td>
																								<div className="client-counter row">
																									{" "}
																									{
																										adjustment.manual_adjustment_quantity
																									}
																								</div>
																							</td>
																							<td className="money">
																								{getSymbolFromCurrency(
																									adjustment.currency_symbol_html
																								)}{" "}
																								{(
																									parseFloat(
																										adjustment.manual_adjustment_quantity
																									) *
																									parseFloat(
																										adjustment.manual_adjustment_price
																									)
																								).toFixed(
																									2
																								)}
																							</td>
																						</tr>
																					)
																				)
																				: null}
																		</tbody>
																	</table>
																</div>
															</div>
														</div>
													</div>
												</>
											))}
										</div>
									</div>
								) : null}

								<div className="row">
									<div className="text-center form-group col-sm-12">
										{this.props.submit_order_form ? (
											<button
												disabled={this.state.loading}
												onClick={this.onSumbit}
												className="btn client-login px-5"
											>
												{this.state.is_update
													? "Update Order Form"
													: "Submit Order Form"}
											</button>
										) : (
											<button
												disabled={this.state.loading}
												onClick={this.onSaveOrderFormData}
												className="btn client-login px-5"
											>
												Save Order Form
											</button>
										)}
									</div>
								</div>
							</div>
						</div>
					);
				} else {
					return (
						<div className="mt-4 mb-4 client-roboto-condensed container">
							<div className="text-center">
								<h3>Order Form</h3>
							</div>
							<div className="row">
								<div className="col"></div>
								<div className="col-10">
									<h5 className="text-center">
										{this.state.cannotErrorMessage}
									</h5>
								</div>
								<div className="col"></div>
							</div>
						</div>
					);
				}
			}
		}
	}
}
const mapStateToProps = state => {
	return {
		user_id: state.loginReducer.user.user.id,
		role: state.loginReducer.user.role,
		user: state.loginReducer.user.user,
		loginLoading: state.loginReducer.loading,
		submit_order_form: state.loginReducer.user.submit_order_form
	};
};

export default connect(mapStateToProps, {})(OrderForm);
