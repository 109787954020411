import spreadsheetActionTypes from "./actiontypes";
const INITIAL_STATE = {
  message: "",
  loading: false,
  files: [],
  file: ""
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case spreadsheetActionTypes.DOWNLOAD_XLSX_REQUEST:
      return {
        ...state,
        loading: true
      };
    case spreadsheetActionTypes.DOWNLOAD_XLSX_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
        countries: action.countries
      };
    case spreadsheetActionTypes.DOWNLOAD_XLSX_FALIURE:
      return {
        ...state,
        loading: false,
        message: action.message
      };

    case spreadsheetActionTypes.UPLOAD_XLSX_REQUEST:
      return {
        ...state,
        loading: true
      };
    case spreadsheetActionTypes.UPLOAD_XLSX_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message
      };
    case spreadsheetActionTypes.UPLOAD_XLSX_FALIURE:
      return {
        ...state,
        loading: false,
        message: action.message
      };

    default:
      return state;
  }
}
