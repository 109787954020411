import compatibilityActionTypes from "./actionTypes";

function show_compatibility_for_category(param) {
  return {
    type: compatibilityActionTypes.SHOW_COMPATIBILITY_FOR_CATEGORY_REQUEST,
    loading: true,
    param: param
  };
}

function show_all_categories() {
  return {
    type: compatibilityActionTypes.SHOW_ALL_CATEGORIES_REQUEST,
    loading: true
  };
}

function show_all_machine_parts() {
  return {
    type: compatibilityActionTypes.SHOW_ALL_MACHINE_PARTS_REQUEST,
    loading: true
  };
}

function get_tree_category() {
  return {
    type: compatibilityActionTypes.GET_TREE_CATEGORY_REQUEST,
    loading: true
  };
}

function update_cpmpatibility(parts_array, id) {
  return {
    type: compatibilityActionTypes.UPDATE_COMPATIBILITY_FOR_CATEGORY_REQUEST,
    loading: true,
    parts_array: parts_array,
    id: id
  };
}

export {
  show_compatibility_for_category,
  get_tree_category,
  show_all_categories,
  update_cpmpatibility,
  show_all_machine_parts
};
