import React, { Component } from "react";
import "react-dropdown-tree-select/dist/styles.css";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { SalesDeskService } from "../service";
import { notification } from "antd";

class AddSalesDesk extends Component {
	constructor(props) {
		super(props);
		this.state = {
			sales_desk_name: "",
			sales_desk_email: "",
			redirect: false
		};
		this.validator = new SimpleReactValidator({ autoForceUpdate: this });
		this.onTextChange = this.onTextChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	// function to handle text changes in multiple fields [two-way binding]
	async onTextChange(name, value) {
		let redirect = this;
		switch (name) {
			case "sales_desk_name":
				redirect.setState({ sales_desk_name: value });
				break;

			case "sales_desk_email":
				redirect.setState({ sales_desk_email: value });
				break;

			default:
				break;
		}
	}

	// submit button when add brand is pressed
	async onSubmit(e) {
		e.preventDefault();
		const fd = new FormData();

		if (this.validator.allValid()) {
			fd.append("sales_desk_name", this.state.sales_desk_name);
			fd.append("sales_desk_email", this.state.sales_desk_email);

			let data = await SalesDeskService.createSalesDesk(fd);
			if (data) {
				notification.success({
					message: "Success!",
					description: data.message
				});
				this.setState({ redirect: true });
			}
		} else {
			this.validator.showMessages();
		}
	}

	async componentDidMount() {}

	render() {
		if (this.state.redirect) {
			return <Redirect to="/sales-desk" />;
		} else {
			if (this.props.loading) {
				return (
					<div>
						<div
							className="content"
							style={{
								minHeight: 500
							}}
						>
							<div className="row">
								<div className="col-sm-12">
									<div className="card">
										<div className="card-header">
											<strong className="card-title">
												Add Sales Desk
											</strong>
										</div>
										<div className="card-body">
											<div className="card-content">
												<div className="todo-list">
													<div className="tdl-holder">
														<div className="tdl-content">
															<div className="row">
																<div className="col"></div>
																<div className="col">
																	<div className="text-center">
																		<Loader
																			type="Rings"
																			color="#00BFFF"
																			height={100}
																			width={100}
																		/>
																		<div className="text-center client-roboto-condensed">
																			LOADING...
																		</div>
																	</div>
																</div>
																<div className="col"></div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				);
			} else {
				return (
					<div>
						<div
							className="content"
							style={{
								minHeight: 500
							}}
						>
							<div className="row">
								<div className="col-sm-12">
									<div className="card">
										<div className="card-header">
											<strong className="card-title">
												Sales Desk
											</strong>
										</div>
										<div className="card-body">
											<form>
												<div className="form-group">
													<label
														htmlFor="sales_desk_name"
														className="control-label mb-1"
													>
														Sales Desk Name
													</label>
													<input
														id="sales_desk_name"
														name="sales_desk_name"
														type="text"
														className="form-control"
														onChange={e =>
															this.onTextChange(
																"sales_desk_name",
																e.target.value
															)
														}
														aria-required="true"
													/>
													<div
														className="mt-4"
														style={{
															color: "red"
														}}
													>
														{this.validator.message(
															"sales_desk_name",
															this.state.sales_desk_name,
															"required|string"
														)}
													</div>
												</div>
												<div className="form-group">
													<label
														htmlFor="sales_desk_email"
														className="control-label mb-1"
													>
														Sales Desk Email
													</label>
													<input
														id="sales_desk_email"
														name="sales_desk_email"
														type="text"
														className="form-control"
														aria-required="true"
														onChange={e =>
															this.onTextChange(
																"sales_desk_email",
																e.target.value
															)
														}
														value={this.state.sales_desk_email}
													/>
													<div
														className="mt-4"
														style={{
															color: "red"
														}}
													>
														{this.validator.message(
															"sales_desk_email",
															this.state.sales_desk_email,
															"required|email"
														)}
													</div>
												</div>

												<div>
													<button
														onClick={this.onSubmit}
														id="payment-button"
														type="submit"
														className="btn btn-secondary btn-block"
													>
														<i className="fa fa-truck"></i>&nbsp;
														<span id="payment-button-amount">
															Add Sales Desk
														</span>
													</button>
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				);
			}
		}
	}
}

const mapStateToProps = state => {
	return {
		message: state.brandReducer.message,
		loading: state.brandReducer.loading
	};
};

export default connect(mapStateToProps, {})(AddSalesDesk);
