import { Api } from "./../../config/api";

function showOrders() {
  let data = Api.get("order")
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {});

  if (data) {
    return data;
  } else {
    return false;
  }
}

function showDetailedOrder(param) {
  let data = Api.get("order/" + param.id)
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {});

  if (data) {
    return data;
  } else {
    return false;
  }
}

export const OrderService = {
  showOrders,
  showDetailedOrder
};
