import clientQuoteActionTypes from "./actionTypes";

const INITIAL_STATE = {
  message: "",
  loading: false,
  firstForm: false,
  secondForm: false,
  thirdForm: false,
  quotes: false,
  quote: false
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case clientQuoteActionTypes.SAVE_QUOTE_FIRST_FORM_REQUEST:
      return {
        ...state,
        loading: true
      };

    case clientQuoteActionTypes.SAVE_QUOTE_FIRST_FORM_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
        firstForm: action.firstForm
      };

    case clientQuoteActionTypes.SAVE_QUOTE_FIRST_FORM_FALIURE:
      return {
        ...state,
        loading: false,
        message: action.message
      };

    case clientQuoteActionTypes.SAVE_QUOTE_SECOND_FORM_REQUEST:
      return {
        ...state,
        loading: true
      };
    case clientQuoteActionTypes.SAVE_QUOTE_SECOND_FORM_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
        secondForm: action.secondForm
      };
    case clientQuoteActionTypes.SAVE_QUOTE_SECOND_FORM_FALIURE:
      return {
        ...state,
        loading: false,
        message: action.message
      };

    case clientQuoteActionTypes.SAVE_QUOTE_THIRD_FORM_REQUEST:
      return {
        ...state,
        loading: true
      };
    case clientQuoteActionTypes.SAVE_QUOTE_THIRD_FORM_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
        thirdForm: action.thirdForm
      };
    case clientQuoteActionTypes.SAVE_QUOTE_THIRD_FORM_FALIURE:
      return {
        ...state,
        loading: false,
        message: action.message
      };

    case clientQuoteActionTypes.SHOW_QUOTES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case clientQuoteActionTypes.SHOW_QUOTES_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
        quotes: action.quotes
      };
    case clientQuoteActionTypes.SHOW_QUOTES_FALIURE:
      return {
        ...state,
        loading: false,
        message: action.message
      };

    case clientQuoteActionTypes.SHOW_SINGLE_QUOTE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case clientQuoteActionTypes.SHOW_SINGLE_QUOTE_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
        quote: action.quote
      };
    case clientQuoteActionTypes.SHOW_SINGLE_QUOTE_FALIURE:
      return {
        ...state,
        loading: false,
        message: action.message,
        quote: false
      };

    case clientQuoteActionTypes.CREATE_QUOTE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case clientQuoteActionTypes.CREATE_QUOTE_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
        quote: action.quote,
        firstForm: false,
        secondForm: false,
        thirdForm: false
      };
    case clientQuoteActionTypes.CREATE_QUOTE_FALIURE:
      return {
        ...state,
        loading: false,
        message: action.message
      };

    case clientQuoteActionTypes.EDIT_QUOTE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case clientQuoteActionTypes.EDIT_QUOTE_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
        firstForm: false,
        secondForm: false,
        thirdForm: false
      };
    case clientQuoteActionTypes.EDIT_QUOTE_FALIURE:
      return {
        ...state,
        loading: false,
        message: action.message
      };

    case clientQuoteActionTypes.GET_DATA_FOR_EDITING_REQUEST:
      return {
        ...state,
        loading: true
      };
    case clientQuoteActionTypes.GET_DATA_FOR_EDITING_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
        firstForm: action.firstForm,
        secondForm: action.secondForm
      };
    case clientQuoteActionTypes.GET_DATA_FOR_EDITING_FALIURE:
      return {
        ...state,
        loading: false
      };

    case clientQuoteActionTypes.ORDER_QUOTE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case clientQuoteActionTypes.ORDER_QUOTE_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message
      };
    case clientQuoteActionTypes.ORDER_QUOTE_FALIURE:
      return {
        ...state,
        loading: false
      };

    case clientQuoteActionTypes.DOWNLOAD_DOCX_REQUEST:
      return {
        ...state,
        loading: true
      };
    case clientQuoteActionTypes.DOWNLOAD_DOCX_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
        quote: action.quote
      };
    case clientQuoteActionTypes.DOWNLOAD_DOCX_FALIURE:
      return {
        ...state,
        loading: false,
        message: action.message
      };

    case clientQuoteActionTypes.DOWNLOAD_XLSX_REQUEST:
      return {
        ...state,
        loading: true
      };
    case clientQuoteActionTypes.DOWNLOAD_XLSX_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
        quote: action.quote
      };
    case clientQuoteActionTypes.DOWNLOAD_XLSX_FALIURE:
      return {
        ...state,
        loading: false,
        message: action.message
      };

    case clientQuoteActionTypes.CLEAN_MESSAGE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case clientQuoteActionTypes.CLEAN_MESSAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        message: ""
      };
    case clientQuoteActionTypes.CLEAN_MESSAGE_FALIURE:
      return {
        ...state,
        loading: false,
        message: ""
      };

    case clientQuoteActionTypes.CLEAN_FORM_REQUEST:
      return {
        ...state,
        loading: true
      };
    case clientQuoteActionTypes.CLEAN_FORM_SUCCESS:
      return {
        ...state,
        loading: false,
        firstForm: false,
        secondForm: false,
        thirdForm: false
      };
    case clientQuoteActionTypes.CLEAN_FORM_FALIURE:
      return {
        ...state,
        loading: false
      };

    default:
      return state;
  }
}
