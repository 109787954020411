import React, { Component } from "react";
import { connect } from "react-redux";
import { ClientOrderService } from "./../service";
import { Link } from "react-router-dom";
import { notification } from "antd";
import { clean_quote_message } from "./../../quotes/action";
import { css } from "@emotion/react";
import { PulseLoader } from "react-spinners";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

class ChildrenOrders extends Component {
	constructor(props) {
		super(props);
		this.state = {
			orders: false,
			search_orders: false,
			search: "",
			total_shown_configuration: 9,
			status: "",
			unique_status: [],
			user: "",
			unique_user: []
		};
		this.handleSelectChange = this.handleSelectChange.bind(this);
	}

	handleSelectChange(value, search, user) {
		let redirect = this;
		redirect.setState({ status: value });

		let parts = redirect.state.orders;

		if (this.props.is_support) {
			parts = parts.filter(
				item =>
					(item.order_name.toLowerCase().includes(search) ||
						item.quotation_for.toLowerCase().includes(search) ||
						item.name.toLowerCase().includes(search)) &&
					item.status.toLowerCase().includes(value) &&
					item.user_name.toLowerCase().includes(user)
			);
		} else {
			parts = parts.filter(
				item =>
					(item.order_name.toLowerCase().includes(search) ||
						item.quotation_for.toLowerCase().includes(search) ||
						item.name.toLowerCase().includes(search)) &&
					item.status.toLowerCase().includes(value) &&
					item.user_name.toLowerCase().includes(user)
			);
		}

		redirect.setState({ search_orders: parts });
		if (parts.length === 0) {
			notification.info({
				message: "Orders not found!",
				description: "Orders with the given status doesn't exsist"
			});
		}
	}

	async handleSelectUserChange(value, search, status) {
		let redirect = this;
		redirect.setState({ user: value });

		let parts = redirect.state.orders;

		let parts1 = parts.filter(
			item =>
				item.user_name.toLowerCase().includes(value) &&
				item.order_name.toLowerCase().includes(search.toLowerCase())
		);

		parts = parts.filter(
			item =>
				item.user_name.toLowerCase().includes(value) &&
				item.order_name.toLowerCase().includes(search.toLowerCase()) &&
				item.status.toLowerCase().includes(status)
		);

		let x = false;
		let search_status = [];
		for (x of parts1) {
			await search_status.push(x.status);
		}
		let unique_status = await [...new Set(search_status)];

		await redirect.setState({ unique_status: unique_status });

		redirect.setState({ search_orders: parts });
	}

	async componentDidMount() {
		let redirect = this;
		let orders = [];

		orders = await ClientOrderService.showChildrenOrders(this.props.user_id);

		redirect.setState({ orders: orders });
		redirect.setState({ search_orders: orders });

		let x = false;
		let status = [];
		for (x of orders) {
			await status.push(x.status);
		}
		let unique_status = await [...new Set(status)];

		await redirect.setState({ unique_status: unique_status });

		let y = false;
		let users = [];
		for (y of orders) {
			await users.push(y.user_name);
		}
		let unique_user = await [...new Set(users)];

		await redirect.setState({ unique_user: unique_user });
		if (orders === undefined) {
			notification.success({
				message: "Success!",
				description: "No orders found."
			});
		} else {
		}

		await this.handleSelectChange(this.state.status, this.state.search, this.state.user);

		await this.props.clean_quote_message();
	}

	onSearchChange(value) {
		let redirect = this;
		redirect.setState({ search: value });

		let parts = redirect.state.orders;

		if (this.props.is_support) {
			parts = parts.filter(
				item =>
					item.order_name.toLowerCase().includes(value.toLowerCase()) ||
					item.quotation_for.toLowerCase().includes(value.toLowerCase()) ||
					item.name.toLowerCase().includes(value.toLowerCase()) ||
					item.user_name.toLowerCase().includes(value.toLowerCase())
			);
		} else {
			parts = parts.filter(
				item =>
					item.order_name.toLowerCase().includes(value.toLowerCase()) ||
					item.quotation_for.toLowerCase().includes(value.toLowerCase()) ||
					item.user_name.toLowerCase().includes(value.toLowerCase())
			);
		}

		redirect.setState({ search_orders: parts });
		if (parts.length === 0) {
		}
	}

	render() {
		const override = css`
			display: block;
			margin: 0 auto;
			border-color: red;
		`;
		if (!this.state.search_orders || this.props.loginLoading) {
			return (
				<div className="sweet-loading">
					<div className="container loading-container">
						<div className="row m-5 p-5">
							<PulseLoader
								css={override}
								sizeUnit={"px"}
								size={24}
								color={"#B9373D"}
							/>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div>
					<div className="mt-4 mb-4 client-roboto-condensed container">
						<div className="text-center">
							<h3>Team Orders</h3>
						</div>
						<div className="row">
							<div className="col-12 client-cards-list">
								<div
									style={{
										width: 200
									}}
									className="my-2"
								>
									<Link
										to={"/orders"}
										className="btn client-login"
										style={{
											width: 200
										}}
									>
										My Orders
								</Link></div>
								<div
									style={{
										width: 200
									}}
								></div>
								<div
									className="my-2"
									style={{
										width: 200
									}}
								>
									<select
										onChange={e =>
											this.handleSelectChange(
												e.target.value,
												this.state.search,
												this.state.user
											)
										}
										className="custom-select"
										value={this.state.status}
									>
										<option value="">Select Status</option>
										{this.state.unique_status.map((status, i) => (
											<option key={i} value={status.toLowerCase()}>
												{status}
											</option>
										))}
									</select>
								</div>
								<div
									className="my-2"
									style={{
										width: 200
									}}
								>
									<select
										value={this.state.user}
										onChange={e =>
											this.handleSelectUserChange(
												e.target.value,
												this.state.search,
												this.state.status
											)
										}
										className="custom-select"
									>
										<option value="">Select User</option>
										{this.state.unique_user.map((status, i) => (
											<option key={i} value={status.toLowerCase()}>
												{status}
											</option>
										))}
									</select>
								</div>
								<div
									className="my-2"
									style={{
										width: 200
									}}
								>
									<div className="form-group">
										<input
											className="form-control"
											type="text"
											onChange={e =>
												this.onSearchChange(e.target.value)
											}
											value={this.state.search}
											placeholder="Search Orders"
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-12">
								<Table className="table table-striped">
									<Thead>
										<Tr>
											<Th className="five-percent">#</Th>
											{/* <Th >Order Number</Th> */}
											<Th>Order Name</Th>
											<Th>Customer Name</Th>
											<Th>Created By</Th>
											<Th>Order Status</Th>
											<Th>Order Date</Th>
										</Tr>
									</Thead>
									<Tbody>
										{this.state.search_orders.map((order, i) =>
											i < this.state.total_shown_configuration ? (
												<Tr key={i} className="my-2">
													<Td>{i + 1}</Td>
													{/* <Td>{order.id}</Td> */}
													<Td>
														<Link to={"/order/" + order.id}>
															{order.order_name}
														</Link>
													</Td>
													<Td>{order.quotation_for}</Td>
													<Td>{order.user_name}</Td>
													<Td>{order.status}</Td>
													<Td>{order.order_date}</Td>
												</Tr>
											) : null
										)}
									</Tbody>
								</Table>
							</div>
						</div>
						{this.state.search_orders.length >
							this.state.total_shown_configuration ? (
								<div className="text-center">
									<button
										onClick={e =>
											this.setState({
												total_shown_configuration: 1000000
											})
										}
										type="submit"
										className="client-roboto-condensed client-login mr-2 ml-2"
									>
										Load More
								</button>
								</div>
							) : null}
					</div>
				</div>
			);
		}
	}
}
const mapStateToProps = state => {
	return {
		user_id: state.loginReducer.user.user.id,
		role: state.loginReducer.user.role,
		loginLoading: state.loginReducer.loading,
		is_support: state.loginReducer.user.is_support
	};
};

export default connect(mapStateToProps, { clean_quote_message })(
	ChildrenOrders
);
