import { Api } from "./../../config/api";

function getMachinePartsForParentSelection() {
	let data = Api.get("machine-parts/getMachinePartsForParentCategorySelection")
		.then(function(response) {
			return response.data;
		})
		.catch(function(error) {
			return false;
		});

	if (data) {
		return data;
	} else {
		return false;
	}
}

function getMachinePartsForParentSelectionWithId(id) {
	let data = Api.get(
		"machine-parts/getMachinePartsForParentCategorySelection/" + id
	)
		.then(function(response) {
			return response.data;
		})
		.catch(function(error) {
			return false;
		});

	if (data) {
		return data;
	} else {
		return false;
	}
}

function showMachineParts(params = "", year_id = null, country_id = null) {
	let data = false;
	if (params === "") {
		data = Api.get("machine-parts/" + country_id + "/" + year_id)
			.then(function(response) {
				return response.data;
			})
			.catch(function(error) {
				return false;
			});
	} else {
		data = Api.get(
			"machine-parts/search/" + params + "/" + country_id + "/" + year_id
		)
			.then(function(response) {
				return response.data;
			})
			.catch(function(error) {
				return false;
			});
	}

	if (data) {
		return data;
	} else {
		return false;
	}
}

function showMachinePartsOnly() {
	let data = Api.get("machine-parts/only")
		.then(function(response) {
			return response.data;
		})
		.catch(function(error) {
			return false;
		});

	if (data) {
		return data;
	} else {
		return false;
	}
}

function showSingleMachinePart(id, year_count, cost_year_count, year_id, country_id) {
	let data = Api.get(
		"machine-parts/admin/" +
			id +
			"/" +
			year_count +
			"/" +
			cost_year_count +
			"/" +
			year_id +
			"/" +
			country_id
	)
		.then(function(response) {
			return response.data;
		})
		.catch(function(error) {
			return false;
		});

	if (data) {
		return data;
	} else {
		return false;
	}
}

function showSingleMachinePartOnly(id) {
	let data = Api.get("machine-parts/admin/only/" + id)
		.then(function(response) {
			return response.data;
		})
		.catch(function(error) {
			return false;
		});

	if (data) {
		return data;
	} else {
		return false;
	}
}

function addMachineParts(params) {
	let data = Api.post("machine-parts", params)
		.then(function(response) {
			return response.data;
		})
		.catch(function(error) {
			return false;
		});

	if (data || data !== "") {
		return data;
	} else {
		return false;
	}
}

function editMachineParts(params, id) {
	let data = Api.post("machine-parts/edit/" + id, params)
		.then(function(response) {
			return response.data;
		})
		.catch(function(error) {
			return false;
		});

	if (data) {
		return data;
	} else {
		return false;
	}
}

function editMachinePartsOnly(params, id) {
	let data = Api.post("machine-parts/edit/only/" + id, params)
		.then(function(response) {
			return response.data;
		})
		.catch(function(error) {
			return false;
		});

	if (data) {
		return data;
	} else {
		return false;
	}
}

function deleteMachineParts(id, year, year_count) {
	let data = Api.get(
		"machine-parts/delete-price/" + id + "/" + year + "/" + year_count
	)
		.then(function(response) {
			return response.data;
		})
		.catch(function(error) {
			return false;
		});

	if (data) {
		return data;
	} else {
		return false;
	}
}

function deleteMachinePartsOnly(params) {
	let data = Api.get("machine-parts/delete/" + params)
		.then(function(response) {
			return response.data;
		})
		.catch(function(error) {
			return false;
		});

	if (data) {
		return data;
	} else {
		return false;
	}
}

function showMachinePartsColumns() {
	let data = Api.get("machine-parts/columns")
		.then(function(response) {
			return response.data;
		})
		.catch(function(error) {
			return false;
		});

	if (data) {
		return data;
	} else {
		return false;
	}
}

function showYear() {
	let data = Api.get("year")
		.then(function(response) {
			return response.data;
		})
		.catch(function(error) {
			return false;
		});

	if (data) {
		return data;
	} else {
		return false;
	}
}

function addMachinePartData(params) {
	let data = Api.post("machine-parts/add-machine-part-data", params)
		.then(function(response) {
			return response.data;
		})
		.catch(function(error) {
			return false;
		});

	if (data) {
		return data;
	} else {
		return false;
	}
}

export const MachinePartService = {
	getMachinePartsForParentSelection,
	getMachinePartsForParentSelectionWithId,
	showMachinePartsOnly,
	showMachineParts,
	showSingleMachinePart,
	showSingleMachinePartOnly,
	addMachineParts,
	editMachineParts,
	editMachinePartsOnly,
	deleteMachineParts,
	deleteMachinePartsOnly,
	showMachinePartsColumns,
	showYear,
	addMachinePartData
};
