const quotesActionTypes = {
  SHOW_QUOTES_REQUEST: "show.quotes.request",
  SHOW_QUOTES_SUCCESS: "show.quotes.success",
  SHOW_QUOTES_FALIURE: "show.quotes.faliure",

  SHOW_SINGLE_QUOTES_REQUEST: "show.single.quotes.request",
  SHOW_SINGLE_QUOTES_SUCCESS: "show.single.quotes.success",
  SHOW_SINGLE_QUOTES_FALIURE: "show.single.quotes.faliure",

  ADD_QUOTES_REQUEST: "add.quotes.request",
  ADD_QUOTES_SUCCESS: "add.quotes.success",
  ADD_QUOTES_FALIURE: "add.quotes.faliure",

  EDIT_QUOTES_REQUEST: "edit.quotes.request",
  EDIT_QUOTES_SUCCESS: "edit.quotes.success",
  EDIT_QUOTES_FALIURE: "edit.quotes.faliure",

  DELETE_QUOTES_REQUEST: "delete.quotes.request",
  DELETE_QUOTES_SUCCESS: "delete.quotes.success",
  DELETE_QUOTES_FALIURE: "delete.quotes.faliure"
};
export default quotesActionTypes;
