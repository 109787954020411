const machineCategoryActionTypes = {

    SHOW_MACHINE_CATEGORY_REQUEST: 'show.machine.category.request',
    SHOW_MACHINE_CATEGORY_SUCCESS: 'show.machine.category.success',
    SHOW_MACHINE_CATEGORY_FALIURE: 'show.machine.category.faliure',

    ADD_MACHINE_CATEGORY_REQUEST: 'add.machine.category.request',
    ADD_MACHINE_CATEGORY_SUCCESS: 'add.machine.category.success',
    ADD_MACHINE_CATEGORY_FALIURE: 'add.machine.category.faliure',

    EDIT_MACHINE_CATEGORY_REQUEST: 'edit.machine.category.request',
    EDIT_MACHINE_CATEGORY_SUCCESS: 'edit.machine.category.success',
    EDIT_MACHINE_CATEGORY_FALIURE: 'edit.machine.category.faliure',

    DELETE_MACHINE_CATEGORY_REQUEST: 'delete.machine.category.request',
    DELETE_MACHINE_CATEGORY_SUCCESS: 'delete.machine.category.success',
    DELETE_MACHINE_CATEGORY_FALIURE: 'delete.machine.category.faliure',

    CLEAN_MESSAGE_REQUEST: 'clean.message.request',
    CLEAN_MESSAGE_SUCCESS: 'clean.message.success',
    CLEAN_MESSAGE_FALIURE: 'clean.message.faliure',

}
export default machineCategoryActionTypes;