import React, { Component } from "react";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { connect } from "react-redux";
import { show_year, clean_message } from "./../action";
import { YearService } from "./../service";
import { Link } from "react-router-dom";
import { notification } from "antd";

class ListYear extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.onDelete = this.onDelete.bind(this);
	}

	componentDidMount() {
		this.props.show_year();

		if (this.props.message !== "" && this.props.message !== undefined) {
			if (this.props.message.toLowerCase().includes("success")) {
				notification.success({
					message: "Success!",
					description: this.props.message
				});
			} else {
				notification.error({
					message: "Error!",
					description: this.props.message
				});
			}
		}
		this.props.clean_message();
	}

	async onDelete(id) {
		let confirmation = window.confirm("Are you sure?");

		if (confirmation) {
			let data = await YearService.deleteYear(id);

			if (data.message.includes("success")) {
				notification.success({
					message: "Success!",
					description: data.message
				});
			} else {
				notification.error({
					message: "Error!",
					description: data.message
				});
			}
		}
	}

	render() {
		if (this.props.year) {
			return (
				<div>
					<div
						className="content"
						style={{
							minHeight: 500
						}}
					>
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-header">
										<strong className="card-title">
											Year Data for Pricing
										</strong>
									</div>
									<div className="card-body">
										<div className="card-content">
											<div className="todo-list">
												<div className="tdl-holder">
													<div className="tdl-content">
														<ol>
															{this.props.year.map((year, i) => (
																<li key={i}>
																	<label
																		style={{
																			color: "black"
																		}}
																	>
																		{year.year}
																		{/* <Link href="#" onClick={(e)=> this.onDelete(year.id)}>
                                                                            <i className="fa text-dark fa-times"></i>
                                                                        </Link> */}
																		<Link
																			to={
																				"update-year/" +
																				year.id
																			}
																		>
																			<i className="fa text-dark fa-pencil"></i>
																		</Link>
																	</label>
																</li>
															))}
														</ol>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div>
					<div
						className="content"
						style={{
							minHeight: 500
						}}
					>
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-header">
										<strong className="card-title">
											Year Data for Pricing
										</strong>
									</div>
									<div className="card-body">
										<div className="card-content">
											<div className="todo-list">
												<div className="tdl-holder">
													<div className="tdl-content">
														<div className="row">
															<div className="col"></div>
															<div className="col">
																<div className="text-center">
																	<Loader
																		type="Rings"
																		color="#00BFFF"
																		height={100}
																		width={100}
																	/>
																	<div className="text-center client-roboto-condensed">
																		LOADING...
																	</div>
																</div>
															</div>
															<div className="col"></div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}
}

const mapStateToProps = state => {
	return {
		year: state.yearReducer.year,
		message: state.yearReducer.message
	};
};

export default connect(mapStateToProps, { show_year, clean_message })(ListYear);
