import React, { Component } from "react";
import { connect } from "react-redux";
import "primereact/resources/themes/nova-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { PickList } from "primereact/picklist";
import { CompatibilityService } from "./../service";
import { update_cpmpatibility } from "./../action";
import { Redirect } from "react-router-dom";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { notification } from "antd";

class AddCompatibility extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			category: false,
			source: [],
			target: [],
			search: "",
			search_selected: ""
		};
		this.pickListTemplate = this.pickListTemplate.bind(this);
		this.onChange = this.onChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.onSearchChange = this.onSearchChange.bind(this);
	}

	async onSearchChange(value) {
		this.setState({ search: value });
		let data = this.state.source;
		let target_data = this.state.target;

		data.sort(function(x, y) {
			return x.machine_part_name
				.toLowerCase()
				.includes(value.toLowerCase()) ||
				x.machine_part_model_number
					.toLowerCase()
					.includes(value.toLowerCase())
				? -1
				: y.machine_part_name.toLowerCase().includes(value.toLowerCase()) ||
				  x.machine_part_model_number
						.toLowerCase()
						.includes(value.toLowerCase())
				? 1
				: 0;
		});

		target_data.sort(function(x, y) {
			return x.machine_part_name
				.toLowerCase()
				.includes(value.toLowerCase()) ||
				x.machine_part_model_number
					.toLowerCase()
					.includes(value.toLowerCase())
				? -1
				: y.machine_part_name.toLowerCase().includes(value.toLowerCase()) ||
				  x.machine_part_model_number
						.toLowerCase()
						.includes(value.toLowerCase())
				? 1
				: 0;
		});
		this.setState({ source: data });
	}

	async componentDidMount() {
		let id = this.props.match.params.id;
		let redirect = this;

		let machine_category_data = await CompatibilityService.showSingleMachineCategory(
			id
		);
		redirect.setState({ category: machine_category_data });

		let compatibility_data = await CompatibilityService.showCompatibilityForId(
			id
		);
		redirect.setState({ target: compatibility_data.selected_parts });
		redirect.setState({ source: compatibility_data.unselected_parts });

		this.setState({ loading: false });
	}

	async onChange(event) {
		await this.setState({ source: event.source, target: event.target });
	}

	async onSubmit(e) {
		let { id } = this.props.match.params;
		e.preventDefault();
		let redirect = this;

		await redirect.setState({ loading: true });
		//check start

		let checker = 0;
		//condition to check if child is selected select parent as well. START
		let message = "Please move one of these to the target";
		let element = null;
		let element1 = null;
		let element2 = null;
		let final_machine_parts = this.state.target;

		for (element of final_machine_parts) {
			if (
				element.parent_model_number !== "" &&
				element.parent_model_number !== null
			) {
				let splitted_model_number = element.parent_model_number.split(",");
				splitted_model_number.forEach(element => {
					let part_quantity = this.state.target.filter(item =>
						item.machine_part_model_number.includes(element)
					);

					if (part_quantity.length === 0) {
						splitted_model_number = splitted_model_number.filter(
							f => f !== element
						);
					}
				});
				let is_selected = 0;

				for (element1 of splitted_model_number) {
					// eslint-disable-next-line no-loop-func
					let part_quantity = await this.state.target.filter(item =>
						item.machine_part_model_number.includes(element1)
					);

					if (part_quantity.length > 0) {
						is_selected = is_selected + 1;
					}
				}

				if (splitted_model_number.length === 0) {
					is_selected = 1;
				}

				if (is_selected === 0) {
					await checker++;
					for (element2 of splitted_model_number) {
						if (element2 !== "" || element2 !== null) {
							// eslint-disable-next-line no-loop-func
							let part_quantity = await this.state.target.filter(item =>
								item.machine_part_model_number.includes(element2)
							);
							if (part_quantity.length === 0) {
								message =
									"The machine part with model number " +
									element2 +
									" is not selected or does not exist in the system";
							} else {
								message =
									message +
									"," +
									part_quantity[0].machine_part_model_number;
							}
						}
					}
					notification.info({
						duration: 0,
						message: "Information!",
						description: message
					});
				}
			}
		}
		if (checker === 0) {
			let target = redirect.state.target;
			let parts_array = new FormData();
			parts_array.append("target", JSON.stringify(target));
			parts_array.append(
				"machine_category_id",
				redirect.state.category[0].machine_category_id
			);

			this.props.update_cpmpatibility(parts_array, id);
		}

		await redirect.setState({ loading: false });
		//check end
	}

	pickListTemplate(machine_parts) {
		return (
			<div className="p-clearfix">
				<div
					style={{
						fontSize: "14px",
						float: "right",
						margin: "15px 5px 0 0"
					}}
				>
					{`(${machine_parts.machine_part_model_number}) ${
						machine_parts.discount_applicable
							? "[Discount Applicable]"
							: "[Discount Not Applicable]"
					} ${machine_parts.machine_part_name}`}
				</div>
			</div>
		);
	}

	render() {
		if (this.props.message.includes("Updating Compatibility Success")) {
			return <Redirect to="/show-category-for-compatibility" />;
		} else {
			if (this.state.loading || this.props.loading) {
				return (
					<div>
						<div
							className="content"
							style={{
								minHeight: 500
							}}
						>
							<div className="row">
								<div className="col-sm-12">
									<div className="card">
										<div className="card-header">
											<strong className="card-title">
												Change Compatibility
											</strong>
										</div>
										<div className="card-body">
											<div className="card-content">
												<div className="todo-list">
													<div className="tdl-holder">
														<div className="tdl-content">
															<div className="row">
																<div className="col"></div>
																<div className="col">
																	<div className="text-center">
																		<Loader
																			type="Rings"
																			color="#00BFFF"
																			height={100}
																			width={100}
																		/>
																		<div className="text-center client-roboto-condensed">
																			LOADING...
																		</div>
																	</div>
																</div>
																<div className="col"></div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				);
			} else {
				return (
					<div>
						<div
							className="content"
							style={{
								minHeight: 500
							}}
						>
							<div className="row">
								<div className="col-sm-12">
									<div className="card">
										<div className="card-header">
											{this.state.category !== [] &&
											this.state.category !== null ? (
												<strong className="card-title">
													Change Compatibility for{" "}
													{this.state.category
														? this.state.category[0]
																.machine_category
														: null}
												</strong>
											) : null}
										</div>
										<div className="card-body">
											<div className="row">
												<div className="col-sm-4">
													<div className="form-group">
														<input
															className="form-control"
															type="text"
															onChange={e =>
																this.onSearchChange(
																	e.target.value
																)
															}
															value={this.state.search}
															placeholder="Search parts"
														/>
													</div>
												</div>
												<div className="col-sm-4"></div>
												<div className="col-sm-4"></div>
											</div>
											<div className="row">
												<div className="col-sm-12">
													<div className="card-content">
														<div className="content-section implementation">
															<PickList
																source={this.state.source}
																target={this.state.target}
																itemTemplate={
																	this.pickListTemplate
																}
																sourceHeader="Available Parts"
																targetHeader="Selected Parts"
																responsive={true}
																showSourceControls={false}
																showTargetControls={true}
																sourceStyle={{
																	height: "400px"
																}}
																targetStyle={{
																	height: "400px"
																}}
																onChange={this.onChange}
															></PickList>
														</div>
														<div className="mt-3 text-center">
															<button
																onClick={this.onSubmit}
																className="btn btn-secondary"
																style={{
																	borderRadius: "10em"
																}}
															>
																Confirm Compatible Parts
															</button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				);
			}
		}
	}
}

const mapStateToProps = state => {
	return {
		message: state.compatibilityReducer.message,
		loading: state.compatibilityReducer.loading
	};
};

export default connect(mapStateToProps, { update_cpmpatibility })(
	AddCompatibility
);
