import { ClientProfileService } from "./service";
import { take, call, put } from "redux-saga/effects";
import clientProfileActionTypes from "./actionTypes";

function* showProfileData() {
  while (true) {
    yield take(clientProfileActionTypes.SHOW_USER_PROFILE_REQUEST);

    const user_profile = yield call(ClientProfileService.showProfileData);

    if (
      user_profile !== "" &&
      user_profile !== null &&
      user_profile !== [] &&
      user_profile
    ) {
      yield put({
        type: clientProfileActionTypes.SHOW_USER_PROFILE_SUCCESS,
        message: "Getting user profile data success",
        user_profile: user_profile
      });
    } else {
      yield put({
        type: clientProfileActionTypes.SHOW_USER_PROFILE_FALIURE,
        message: "Getting user profile data faliure"
      });
    }
  }
}

export { showProfileData };
