import { Api } from "./../../config/api";

function showCompanyRates() {
  let data = Api.get("company-rates")
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {
      return false;
    });

  if (data && data !== "") {
    return data;
  } else {
    return false;
  }
}

function showSingleCompayRate(params) {
  let data = Api.get("company-rates/" + params)
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {
      return false;
    });

  if (data && data !== "") {
    return data;
  } else {
    return false;
  }
}

function addCompanyRates(params) {
  let data = Api.post("company-rates", params)
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {
      return false;
    });

  if (data && data !== "") {
    return data;
  } else {
    return false;
  }
}

function editCompanyRates(params) {
  let data = Api.post("company-rates/edit/" + params.id, params)
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {
      return false;
    });

  if (data && data !== "") {
    return data;
  } else {
    return false;
  }
}

function deleteCompanyRates(params) {
  let data = Api.get("company-rates/delete/" + params)
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {
      return false;
    });

  if (data && data !== "") {
    return data;
  } else {
    return false;
  }
}

export const CompanyRatesService = {
  showCompanyRates,
  showSingleCompayRate,
  addCompanyRates,
  editCompanyRates,
  deleteCompanyRates
};
