import React, { Component } from "react";
import { connect } from "react-redux";
import { edit_quote } from "./../action";
import { Descriptions } from "antd";
import { css } from "@emotion/react";
import { PulseLoader } from "react-spinners";

class EditThirdForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			quotation_name: this.props.firstForm
				? this.props.firstForm.quotation_name
				: null,
			quotation_for: this.props.firstForm
				? this.props.firstForm.quotation_for
				: null,
			selected_configurations: this.props
				? this.props.secondForm.selected_configurations
				: null,
			configurations: false
		};
		this.onSubmit = this.onSubmit.bind(this);
		this.onBack = this.onBack.bind(this);
	}

	onBack() {
		if (this.props.process.toLowerCase().includes("add")) {
			this.props.onComponentChange("seconForm");
		} else {
			this.props.onComponentChange("firstFrom");
		}
	}

	onSubmit(e) {
		e.preventDefault();
		let params = {
			quotation_name: this.state.quotation_name,
			quotation_for: this.state.quotation_for,
			user_id: this.props.quote.quote[0].user_id,
			configurations: this.state.selected_configurations,
			status: this.props.quote.quote[0].status,
			template: this.props.quote.quote[0].template_id
		};

		this.props.edit_quote(this.props.id, params);
	}

	async componentDidMount() {}

	render() {
		const override = css`
			display: block;
			margin: 0 auto;
			border-color: red;
		`;
		if (this.props.loading) {
			return (
				<div className="sweet-loading">
					<div className="container loading-container">
						<div className="row m-5 p-5">
							<PulseLoader
								css={override}
								sizeUnit={"px"}
								size={24}
								color={"#B9373D"}
							/>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div>
					<div className="m-2 row">
						<div className="col-sm-2"></div>
						<div className="col-sm-8">
							<h5 className="client-roboto-condensed font-weight-bold text-center">
								Confirm Quote
							</h5>
							{this.props.firstForm && this.props.secondForm ? (
								<div>
									<Descriptions
										title=""
										layout="hotizontal"
										bordered
										size="small"
										column={1}
									>
										<Descriptions.Item label="Quote Name">
											{this.state.quotation_name}
										</Descriptions.Item>
										<Descriptions.Item label="Quote For">
											{this.state.quotation_for}
										</Descriptions.Item>
										<Descriptions.Item label="Total Configurations">
											{
												this.props.secondForm
													.selected_configurations.length
											}
										</Descriptions.Item>

										{this.props.process
											.toLowerCase()
											.includes("add") ? (
											<>
												<Descriptions.Item label="Add these configuration(s) to this Quote">
													<ul type="none">
														{this.props.secondForm.selected_configurations.map(
															(configuration, i) => (
																<li key={i}>
																	{configuration.label}
																</li>
															)
														)}
													</ul>
												</Descriptions.Item>
											</>
										) : (
											<>
												<Descriptions.Item label="Configuration(s) in this Quote">
													<ul type="none">
														{this.props.secondForm.selected_configurations.map(
															(configuration, i) => (
																<li key={i}>
																	{configuration.label}
																</li>
															)
														)}
													</ul>
												</Descriptions.Item>
											</>
										)}
									</Descriptions>
								</div>
							) : null}
						</div>
						<div className="col-sm-2"></div>
						{/* <div className="col"></div> */}
					</div>
					<div className="text-center row my-2">
						<div className="col"></div>

						<button
							onClick={this.onBack}
							type="submit"
							className="client-roboto-condensed text-uppercase client-login m-2 btn-block col"
						>
							Back
						</button>
						<button
							onClick={this.onSubmit}
							type="submit"
							className="client-roboto-condensed text-uppercase client-login m-2 btn-block col"
						>
							Confirm
						</button>
						<div className="col"></div>
					</div>
				</div>
			);
		}
	}
}
const mapStateToProps = state => {
	return {
		loading: state.clientQuoteReducer.loading,
		user_id: state.loginReducer.user.user.id,
		firstForm: state.clientQuoteReducer.firstForm,
		secondForm: state.clientQuoteReducer.secondForm,
		message: state.clientQuoteReducer.message,
		quote: state.clientQuoteReducer.quote
	};
};

export default connect(mapStateToProps, {
	edit_quote
})(EditThirdForm);
