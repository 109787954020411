import userActionTypes from "./actionTypes";
import { UserService } from "./service";
import { take, call, put } from "redux-saga/effects";

function* cleanUserMessage() {
  while (true) {
    yield take(userActionTypes.CLEAN_MESSAGE_REQUEST);
    yield put({ type: userActionTypes.CLEAN_MESSAGE_SUCCESS });
  }
}

function* showUsers() {
  while (true) {
    yield take(userActionTypes.SHOW_USER_REQUEST);

    const users = yield call(UserService.showUsers);

    if (users !== "" && users !== null && users) {
      yield put({
        type: userActionTypes.SHOW_USER_SUCCESS,
        message: "Getting users success",
        users: users
      });
    } else {
      yield put({
        type: userActionTypes.SHOW_USER_FALIURE,
        message: "Getting users faliure"
      });
    }
  }
}

function* validateUser(params) {
  while (true) {
    const { params } = yield take(userActionTypes.VALIDATE_USER_REQUEST);

    const { data } = yield call(UserService.validateUser, params);

    if (data !== "" && data !== null && data) {
      yield put({
        type: userActionTypes.VALIDATE_USER_SUCCESS,
        message: "Validating User success"
      });
    } else {
      yield put({
        type: userActionTypes.VALIDATE_USER_FALIURE,
        message: "Validating User faliure"
      });
    }
  }
}

export { showUsers, validateUser, cleanUserMessage };
