import machineCategoryActionTypes from './actionTypes';
const INITIAL_STATE = {
    message: '',
    loading: false,
    machine_categories: [],
    machine_category: []
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case machineCategoryActionTypes.SHOW_MACHINE_CATEGORY_REQUEST:
            return {
                ...state,
                loading: true
            }
        case machineCategoryActionTypes.SHOW_MACHINE_CATEGORY_SUCCESS:
            return {
                ...state,
                loading: false,
                message: action.message,
                machine_categories: action.machine_categories
            }
        case machineCategoryActionTypes.SHOW_MACHINE_CATEGORY_FALIURE:
            return {
                ...state,
                loading: false,
                message: action.message,
                machine_category: action.machine_category
            }

        case machineCategoryActionTypes.ADD_MACHINE_CATEGORY_REQUEST:
            return {
                ...state,
                loading: true
            }
        case machineCategoryActionTypes.ADD_MACHINE_CATEGORY_SUCCESS:
            return {
                ...state,
                loading: false,
                message: action.message
            }
        case machineCategoryActionTypes.ADD_MACHINE_CATEGORY_FALIURE:
            return {
                ...state,
                loading: false,
                message: action.message
            }

        case machineCategoryActionTypes.EDIT_MACHINE_CATEGORY_REQUEST:
            return {
                ...state,
                loading: true
            }
        case machineCategoryActionTypes.EDIT_MACHINE_CATEGORY_SUCCESS:
            return {
                ...state,
                loading: false,
                message: action.message
            }
        case machineCategoryActionTypes.EDIT_MACHINE_CATEGORY_FALIURE:
            return {
                ...state,
                loading: false,
                message: action.message
            }

        case machineCategoryActionTypes.DELETE_MACHINE_CATEGORY_REQUEST:
            return {
                ...state,
                loading: true
            }
        case machineCategoryActionTypes.DELETE_MACHINE_CATEGORY_SUCCESS:
            return {
                ...state,
                loading: false,
                message: action.message
            }
        case machineCategoryActionTypes.DELETE_MACHINE_CATEGORY_FALIURE:
            return {
                ...state,
                loading: false,
                message: action.message
            }



        case machineCategoryActionTypes.CLEAN_MESSAGE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case machineCategoryActionTypes.CLEAN_MESSAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                message: ''
            }
        case machineCategoryActionTypes.CLEAN_MESSAGE_FALIURE:
            return {
                ...state,
                loading: false,
            }

        default:
            return state;
    }
}