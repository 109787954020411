import React, { Component } from "react";
import { connect } from "react-redux";
import { SpecificationService } from "./../service";
import { edit_specification_for_machine_category } from "./../action";
import { Redirect } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

class UpdateSpecification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      specification_name: "",
      specification_value: ""
    };
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.onTextChange = this.onTextChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onTextChange(name, value) {
    let redirect = this;
    switch (name) {
      case "specification_name":
        redirect.setState({ specification_name: value });
        break;

      case "specification_value":
        redirect.setState({ specification_value: value });
        break;

      default:
        break;
    }
  }

  onSubmit(e) {
    let { id } = this.props.match.params;
    let redirect = this;
    e.preventDefault();

    if (this.validator.allValid()) {
      let params = {
        id: id,
        specification_name: redirect.state.specification_name,
        specification_value: redirect.state.specification_value
      };

      this.props.edit_specification_for_machine_category(params);
    } else {
      this.validator.showMessages();
    }
  }

  async componentDidMount() {
    let redirect = this;
    let { id } = this.props.match.params;

    let specification = await SpecificationService.showSingleSpecification(id);

    redirect.setState({
      specification_name: specification[0].specification_name
    });
    redirect.setState({
      specification_value: specification[0].specification_value
    });

    redirect.setState({ loading: false });
  }

  render() {
    if (this.props.message.includes("Updating Specification Data Success")) {
      return <Redirect to="/add-specification-category" />;
    } else {
      if (this.props.loading || this.state.loading) {
        return (
          <div>
            <div
              className="content"
              style={{
                minHeight: 500
              }}
            >
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <strong className="card-title">Update Feature</strong>
                    </div>
                    <div className="card-body">
                      <div className="card-content">
                        <div className="todo-list">
                          <div className="tdl-holder">
                            <div className="tdl-content">
                              <div className="row">
                                <div className="col"></div>
                                <div className="col">
                                  <div className="text-center">
                                    <Loader
                                      type="Rings"
                                      color="#00BFFF"
                                      height={100}
                                      width={100}
                                    />
                                    <div className="text-center client-roboto-condensed">
                                      LOADING...
                                    </div>
                                  </div>
                                </div>
                                <div className="col"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div>
            <div
              className="content"
              style={{
                minHeight: 500
              }}
            >
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <strong className="card-title">Update Feature</strong>
                    </div>
                    <div className="card-body">
                      <div className="card-content">
                        <form>
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="form-group">
                                <label
                                  htmlFor="specification_name"
                                  className="control-label mb-1"
                                >
                                  Specification Name
                                </label>
                                <input
                                  id="specification_name"
                                  name="specification_name"
                                  type="text"
                                  className="form-control"
                                  aria-required="true"
                                  onChange={e =>
                                    this.onTextChange(
                                      "specification_name",
                                      e.target.value
                                    )
                                  }
                                  value={this.state.specification_name}
                                />
                                <div
                                  className="mt-4"
                                  style={{
                                    color: "red"
                                  }}
                                >
                                  {this.validator.message(
                                    "specification_name",
                                    this.state.specification_name,
                                    "required|string"
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-12">
                              <div className="form-group">
                                <label
                                  htmlFor="specification_value"
                                  className="control-label mb-1"
                                >
                                  Specification Value
                                </label>
                                <input
                                  id="specification_value"
                                  name="specification_value"
                                  type="text"
                                  className="form-control"
                                  aria-required="true"
                                  onChange={e =>
                                    this.onTextChange(
                                      "specification_value",
                                      e.target.value
                                    )
                                  }
                                  value={this.state.specification_value}
                                />
                                <div
                                  className="mt-4"
                                  style={{
                                    color: "red"
                                  }}
                                >
                                  {this.validator.message(
                                    "specification_value",
                                    this.state.specification_value,
                                    "required|string"
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <button
                              onClick={this.onSubmit}
                              id="payment-button"
                              type="submit"
                              className="btn btn-secondary btn-block"
                            >
                              <i className="fa fa-truck"></i>&nbsp;
                              <span id="payment-button-amount">
                                Update Specification
                              </span>
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
  }
}

const mapStateToProps = state => {
  return {
    message: state.specificationReducer.message,
    loading: state.specificationReducer.loading
  };
};
export default connect(mapStateToProps, {
  edit_specification_for_machine_category
})(UpdateSpecification);
