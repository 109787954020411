import loginActionTypes from "./actionTypes";
const INITIAL_STATE = {
	message: "",
	loading: false,
	isAuthenticated: false,
	token: "",
	user: []
};

export default function(state = INITIAL_STATE, action) {
	switch (action.type) {
		case loginActionTypes.REGISTER_REQUEST:
			return { ...state, loading: true };

		case loginActionTypes.REGISTER_SUCCESS:
			return { ...state, loading: false, message: action.message };

		case loginActionTypes.REGISTER_FALIURE:
			return { ...state, loading: false, message: action.message };

		case loginActionTypes.REGISTER_ADMIN_REQUEST:
			return { ...state, loading: true };

		case loginActionTypes.REGISTER_ADMIN_SUCCESS:
			return { ...state, loading: false, message: action.message };

		case loginActionTypes.REGISTER_ADMIN_FALIURE:
			return { ...state, loading: false, message: action.message };

		case loginActionTypes.LOGIN_REQUEST:
			return { ...state, loading: true };

		case loginActionTypes.LOGIN_SUCCESS:
			return {
				...state,
				loading: false,
				message: action.message,
				user: action.user,
				token: action.token,
				isAuthenticated: true
			};

		case loginActionTypes.LOGIN_FALIURE:
			return { ...state, loading: false, message: action.message };

		case loginActionTypes.GET_LOGIN_INFO_WITH_TOKEN_REQUEST:
			return { ...state, loading: true };

		case loginActionTypes.GET_LOGIN_INFO_WITH_TOKEN_SUCCESS:
			return {
				...state,
				loading: false,
				message: action.message,
				user: action.user,
				token: action.token,
				isAuthenticated: true
			};

		case loginActionTypes.GET_LOGIN_INFO_WITH_TOKEN_FALIURE:
			return { ...state, loading: false, message: action.message };

		case loginActionTypes.LOGOUT_REQUEST:
			return { ...state, loading: true };

		case loginActionTypes.LOGOUT_SUCCESS:
			return {
				...state,
				loading: false,
				message: action.message,
				isAuthenticated: false,
				token: "",
				user: []
			};

		case loginActionTypes.LOGOUT_FALIURE:
			return { ...state, loading: false, message: action.message };

		case loginActionTypes.GET_USER_REQUEST:
			return { ...state, loading: true };

		case loginActionTypes.GET_USER_SUCCESS:
			return {
				...state,
				loading: false,
				message: action.message,
				user: action.user,
				token: action.token,
				isAuthenticated: true
			};

		case loginActionTypes.GET_USER_FALIURE:
			return {
				...state,
				loading: false,
				message: action.message,
				isAuthenticated: false,
				token: "",
				user: []
			};

		case loginActionTypes.CLEAN_LOGIN_MESSAGE_REQUEST:
			return { ...state, loading: true };

		case loginActionTypes.CLEAN_LOGIN_MESSAGE_SUCCESS:
			return { ...state, loading: false, message: "" };

		case loginActionTypes.CLEAN_LOGIN_MESSAGE_FALIURE:
			return { ...state, loading: false, message: "" };

		default:
			return state;
	}
}
