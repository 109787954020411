import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ClientConfigurationService } from "./../service";
import { css } from "@emotion/react";
import { PulseLoader } from "react-spinners";
import { notification } from "antd";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";

class ChildrenConfigurations extends Component {
	constructor(props) {
		super(props);
		this.state = {
			configurations: false,
			search_configurations: false,
			search: "",
			total_shown_configuration: 12,
			user: "",
			unique_user: []
		};
		this.onSearchChange = this.onSearchChange.bind(this);
	}

	onSearchChange(value) {
		let redirect = this;
		redirect.setState({ search: value });

		let parts = redirect.state.configurations;
		parts = parts.filter(
			item =>
				item.name.toLowerCase().includes(value.toLowerCase()) ||
				item.user_name.toLowerCase().includes(value.toLowerCase())
		);

		redirect.setState({ search_configurations: parts });
		if (parts.length === 0) {
		}
	}

	handleSelectChange(value, search) {
		let redirect = this;
		redirect.setState({ user: value });


		let parts = redirect.state.configurations;

		parts = parts.filter(
			item =>
				item.user_name.toLowerCase().includes(value) && item.name.toLowerCase().includes(search.toLowerCase())
		);

		redirect.setState({ search_configurations: parts })
	}

	async componentDidMount() {
		let redirect = this;
		let configurations = [];
		configurations = await ClientConfigurationService.showChildrenConfigurations(
			this.props.user_id
		);

		redirect.setState({ configurations: configurations });
		redirect.setState({ search_configurations: configurations });

		let x = false;
		let status = [];
		for (x of configurations) {
			await status.push(x.user_name);
		}
		let unique_user = await [...new Set(status)];

		await redirect.setState({ unique_user: unique_user });

		if (configurations === undefined) {
			notification.success({
				message: "Success!",
				description: "No configurations found."
			});
		} else {
		}
	}

	render() {
		const override = css`
			display: block;
			margin: 0 auto;
			border-color: red;
		`;
		if (!this.state.search_configurations || this.props.loginLoading) {
			return (
				<div className="sweet-loading">
					<div className="container loading-container">
						<div className="row m-5 p-5">
							<PulseLoader
								css={override}
								sizeUnit={"px"}
								size={24}
								color={"#B9373D"}
							/>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div>
					<div className="my-5 client-roboto-condensed container">
						<div className="row">
							<div className="col-12">
								<div className="text-center">
									<h3>Team Configurations</h3>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-12 client-cards-list">
								<div
									style={{
										width: 200
									}}
									className="my-3"
								>
									<Link
										className="btn client-login"
										style={{
											width: 170
										}}
										to="/configurations"
									>
										My Configurations
								</Link></div>
								<div className="form-group my-3">
									<input
										className="form-control"
										type="text"
										style={{
											width: 200
										}}
										onChange={e =>
											this.onSearchChange(e.target.value)
										}
										value={this.props.search}
										placeholder="Search Configurations"
									/>
								</div>
								<div
									style={{
										width: 200
									}}
								>
									<select
										value={this.state.status}
										onChange={e =>
											this.handleSelectChange(
												e.target.value,
												this.state.search
											)
										}
										className="custom-select my-3"
									>
										<option value="">Select User</option>
										{this.state.unique_user.map((status, i) => (
											<option key={i} value={status.toLowerCase()}>
												{status}
											</option>
										))}
									</select>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-12">
								<Table className="table table-striped">
									<Thead
										style={{
											backgroundColor: "#B9373D",
											color: "#ffffff"
										}}
									>
										<Tr>
											<Th className="five-percent">#</Th>
											<Th>Configuration Name</Th>
											<Th>Created By</Th>
											<Th>Created At</Th>
										</Tr>
									</Thead>
									<Tbody>
										{this.state.search_configurations
											? this.state.search_configurations.map(
												(configuration, i) =>
													i <
														this.state
															.total_shown_configuration ? (
															<Tr key={i} className="my-2">
																<Td>{i + 1}</Td>
																<Td>
																	<Link
																		to={
																			"/configuration/" +
																			configuration.id
																		}
																	>
																		{configuration.name}
																	</Link>
																</Td>
																<Td>
																	{configuration.user_name}
																</Td>
																<Td>
																	{configuration.created_at}
																</Td>
															</Tr>
														) : null
											)
											: null}
									</Tbody>
								</Table>
							</div>
						</div>
						{this.state.search_configurations.length >
							this.state.total_shown_configuration ? (
								<div className="text-center">
									<button
										onClick={e =>
											this.setState({
												total_shown_configuration: 1000000
											})
										}
										type="submit"
										className="client-roboto-condensed client-login mr-2 ml-2"
									>
										Load More
								</button>
								</div>
							) : null}
					</div>
				</div>
			);
		}
	}
}
const mapStateToProps = state => {
	return {
		user_id: state.loginReducer.user.user.id,
		role: state.loginReducer.user.role,
		loginLoading: state.loginReducer.loading,
		is_support: state.loginReducer.user.is_support
	};
};

export default connect(mapStateToProps, {})(ChildrenConfigurations);
