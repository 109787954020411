import React, { Component } from "react";
import {
  ComposedChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar
} from "recharts";

export default class VerticalBarchart extends Component {
  componentDidMount() {}

  render() {
    return (
      <div>
        <ComposedChart
          layout="vertical"
          width={400}
          height={400}
          data={this.props.data}
          margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
        >
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis type="number" />
          <YAxis dataKey="name" type="category" />
          <Tooltip />
          {Object.keys(this.props.data[0]).map((value, i) =>
            value !== "name" ? (
              <Bar
                dataKey={value}
                fill={"#000000".replace(/0/g, function() {
                  return (~~(Math.random() * 16)).toString(16);
                })}
              />
            ) : null
          )}
        </ComposedChart>
      </div>
    );
  }
}
