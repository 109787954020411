import React, { Component } from "react";
import "./../css/styles.css";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
import logo from "./../CPQ/logo.png";
import banner from "./../CPQ/banner.png";

class NoLoginHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleLanguageChange = this.handleLanguageChange.bind(this);
  }

  handleLanguageChange(value) {
  }

  componentDidMount() {}

  render() {
    return (
      <div>
          <div className="container">
            <nav className="navbar navbar-expand-lg ml-auto navbar-light">
              <Link className="navbar-brand" to="/">
                <img loading="lazy"
                  alt="nextlevelvalue-logo"
                  height="120px"
                  className="logo"
                  src={logo}
                />
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>

              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                {/* <ul className="navbar-nav ml-auto">
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle"
                      to="#"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <img loading="lazy"
                        alt="language-flag"
                        height="24px"
                        width="24px"
                        className="mr-2"
                        src={gb}
                      />
                      English
                    </Link>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                    >
                      <Link
                        onClick={e => this.handleLanguageChange("nl")}
                        className="dropdown-item"
                        to="#"
                      >
                        <img loading="lazy"
                          alt="language-flag"
                          height="24px"
                          width="24px"
                          className="mr-2"
                          src={nl}
                        />
                        Dutch
                      </Link>
                      <Link
                        onClick={e => this.handleLanguageChange("gr")}
                        className="dropdown-item"
                        to="#"
                      >
                        <img loading="lazy"
                          alt="language-flag"
                          height="24px"
                          width="24px"
                          className="mr-2"
                          src={de}
                        />
                        German
                      </Link>
                    </div>
                  </li>
                </ul> */}
              </div>
            </nav>
          </div>
        <div className="header-seperator"></div>
        <div className="cotainer-fluid" id="banner">
          <img loading="lazy"
            alt="nextlevelvalue-banner"
            width="100%"
            height="100%"
            src={banner}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, {})(NoLoginHeader);
