import React, { Component } from "react";
import "react-dropdown-tree-select/dist/styles.css";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { ResidualPercentageService } from "./../service";

class AddResidualPercentage extends Component {
	constructor(props) {
		super(props);
		this.state = {
            loading: true,
			year: "",
            percentage: "",
            redirect: false,
		};
		this.validator = new SimpleReactValidator({ autoForceUpdate: this });
		this.onTextChange = this.onTextChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	// function to handle text changes in multiple fields [two-way binding]
	async onTextChange(name, value) {
		let redirect = this;
		switch (name) {
			case "year":
				redirect.setState({ year: value });
				break;

			case "percentage":
				redirect.setState({ percentage: value });
				break;

			default:
				break;
		}
	}

	// submit button when add brand is pressed
	async onSubmit(e) {
        e.preventDefault();
        let that = this
		const fd = new FormData();

		if (this.validator.allValid()) {
			fd.append("year", this.state.year);
            fd.append("percentage", this.state.percentage);
            await ResidualPercentageService.createResidualPercentage(fd);
            that.setState({redirect: true})
		} else {
			this.validator.showMessages();
		}
	}

	async componentDidMount() {
        let that = this
        that.setState({loading: false})
    }

	render() {
		if (this.state.redirect) {
			return <Redirect to="/list-all-residual-percnentage" />;
		} else {
			if (this.state.loading) {
				return (
					<div>
						<div
							className="content"
							style={{
								minHeight: 500
							}}
						>
							<div className="row">
								<div className="col-sm-12">
									<div className="card">
										<div className="card-header">
											<strong className="card-title">
												Add Residual Percentage
											</strong>
										</div>
										<div className="card-body">
											<div className="card-content">
												<div className="todo-list">
													<div className="tdl-holder">
														<div className="tdl-content">
															<div className="row">
																<div className="col"></div>
																<div className="col">
																	<div className="text-center">
																		<Loader
																			type="Rings"
																			color="#00BFFF"
																			height={100}
																			width={100}
																		/>
																		<div className="text-center client-roboto-condensed">
																			LOADING...
																		</div>
																	</div>
																</div>
																<div className="col"></div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				);
			} else {
				return (
					<div>
						<div
							className="content"
							style={{
								minHeight: 500
							}}
						>
							<div className="row">
								<div className="col-sm-12">
									<div className="card">
										<div className="card-header">
											<strong className="card-title">
												Add Residual Percentage
											</strong>
										</div>
										<div className="card-body">
											<form>
												<div className="form-group">
													<label
														htmlFor="year"
														className="control-label mb-1"
													>
														Year Length
													</label>
													<input
														id="year"
														name="year"
														type="text"
														className="form-control"
														onChange={e =>
															this.onTextChange(
																"year",
																e.target.value
															)
														}
														aria-required="true"
													/>
													<div
														className="mt-4"
														style={{
															color: "red"
														}}
													>
														{this.validator.message(
															"year",
															this.state.year,
															"required|string"
														)}
													</div>
												</div>
												<div className="form-group">
													<label
														htmlFor="percentage"
														className="control-label mb-1"
													>
														Residual Percentage
													</label>
													<input
														id="percentage"
														name="percentage"
														type="text"
														className="form-control"
														aria-required="true"
														onChange={e =>
															this.onTextChange(
																"percentage",
																e.target.value
															)
														}
														value={
															this.state.percentage
														}
													/>
													<div
														className="mt-4"
														style={{
															color: "red"
														}}
													>
														{this.validator.message(
															"percentage",
															this.state.percentage,
															"required|numeric"
														)}
													</div>
												</div>

												<div>
													<button
														onClick={this.onSubmit}
														id="payment-button"
														type="submit"
														className="btn btn-secondary btn-block"
													>
														<i className="fa fa-truck"></i>&nbsp;
														<span id="payment-button-amount">
															Add Residual Percentage
														</span>
													</button>
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				);
			}
		}
	}
}

const mapStateToProps = state => {
	return {
	};
};

export default connect(mapStateToProps, {
})(AddResidualPercentage);
