import brandActionTypes from "./actionTypes";
import { BrandService } from "./service";
import { take, call, put } from "redux-saga/effects";

function* showBrands() {
  while (true) {
    yield take(brandActionTypes.SHOW_BRAND_REQUEST);

    const brands = yield call(BrandService.showBrands);

    if (brands !== "" && brands !== null && brands) {
      yield put({
        type: brandActionTypes.SHOW_BRAND_SUCCESS,
        message: "Getting Brands Success",
        brands: brands
      });
    } else {
      yield put({
        type: brandActionTypes.SHOW_BRAND_FALIURE,
        message: "Getting Brands Faliure"
      });
    }
  }
}

function* addBrand(params) {
  while (true) {
    const { params } = yield take(brandActionTypes.ADD_BRAND_REQUEST);

    const data = yield call(BrandService.addBrand, params);

    if (data !== "" && data !== null && data) {
      yield put({
        type: brandActionTypes.ADD_BRAND_SUCCESS,
        message: "Adding Brand Success"
      });
    } else {
      yield put({
        type: brandActionTypes.ADD_BRAND_FALIURE,
        message: "Adding Brand Faliure"
      });
    }
  }
}

function* editBrand(params) {
  while (true) {
    const { params } = yield take(brandActionTypes.EDIT_BRAND_REQUEST);

    const data = yield call(BrandService.editBrand, params);

    if (data !== "" && data !== null && data) {
      yield put({
        type: brandActionTypes.EDIT_BRAND_SUCCESS,
        message: "Editing Brand Success"
      });
    } else {
      yield put({
        type: brandActionTypes.EDIT_BRAND_FALIURE,
        message: "Editing Braand Faliure"
      });
    }
  }
}

function* deleteBrand(params) {
  while (true) {
    const { params } = yield take(brandActionTypes.DELETE_BRAND_REQUEST);

    const data = yield call(BrandService.deleteBrand, params);

    if (data !== "" && data !== null && data) {
      yield put({
        type: brandActionTypes.DELETE_BRAND_SUCCESS,
        message: "Deleting Brand Success"
      });
    } else {
      yield put({
        type: brandActionTypes.DELETE_BRAND_FALIURE,
        message: "Deleting Brand Faliure"
      });
    }
  }
}

function* clearBrandMessage() {
  while (true) {
    yield take(brandActionTypes.CLEAN_MESSAGE_REQUEST);

    yield put({
      type: brandActionTypes.CLEAN_MESSAGE_SUCCESS
    });
  }
}

export { showBrands, addBrand, editBrand, deleteBrand, clearBrandMessage };
