import React, { Component } from "react";
import { connect } from "react-redux";
import { RoleManagementService } from "./../service";
import { Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

class ListPolicyDocuementUpdateForRole extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			roles: false,
		};
	}

	async componentDidMount() {
		let roles = await RoleManagementService.getAllRoles();

		await this.setState({ roles: roles });

		this.setState({loading: false})
	}

	render() {
		if (!this.props.loading && !this.state.loading) {
			return (
				<div>
					<div
						className="content"
						style={{
							minHeight: 500
						}}
					>
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-header">
										<strong className="card-title">
											Roles [For Policy Document Update]
										</strong>
									</div>
									<div className="card-body">
										<div className="card-content">
											<div className="todo-list">
												<div className="tdl-holder">
													<div className="tdl-content">
														<ul>
															{this.state.roles
																? this.state.roles.map((role, i) => (
																		!role.role_name.toLowerCase().includes('admin')?
																		<li key={i}>
																			<label
																				style={{
																					color: "black"
																				}}
																			>
																				<div className="row">
																					<div className="col-sm-9">
																						{role.role_name}
																					</div>
																					<div className="col-sm-3">
																						<Link
																							to={
																								"/update-policy-document-update-for-role/" +
																								role.id
																							}
																						>
																							<i className="fa text-dark fa-pencil"></i>
																						</Link>
																					</div>
																				</div>
																			</label>
																		</li>
																		:null
																  ))
																: null}
														</ul>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div>
					<div
						className="content"
						style={{
							minHeight: 500
						}}
					>
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-header">
										<strong className="card-title">
											Roles [For Policy Document Update]
										</strong>
									</div>
									<div className="card-body">
										<div className="card-content">
											<div className="todo-list">
												<div className="tdl-holder">
													<div className="tdl-content">
														<div className="row">
															<div className="col"></div>
															<div className="col">
																<div className="text-center">
																	<Loader
																		type="Rings"
																		color="#00BFFF"
																		height={100}
																		width={100}
																	/>
																	<div className="text-center client-roboto-condensed">
																		LOADING...
																	</div>
																</div>
															</div>
															<div className="col"></div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}
}
const mapStateToProps = state => {
	return {};
};

export default connect(mapStateToProps, {})(ListPolicyDocuementUpdateForRole);
