const spreadsheetActionTypes = {
  DOWNLOAD_XLSX_REQUEST: "download.xlsx.request",
  DOWNLOAD_XLSX_SUCCESS: "download.xlsx.success",
  DOWNLOAD_XLSX_FALIURE: "download.xlsx.faliure",

  UPLOAD_XLSX_REQUEST: "upload.xlsx.request",
  UPLOAD_XLSX_SUCCESS: "upload.xlsx.success",
  UPLOAD_XLSX_FALIURE: "upload.xlsx.faliure"
};
export default spreadsheetActionTypes;
