import React, { Component } from "react";
import { connect } from "react-redux";
import { FeatureSetService } from "./../service";
import { PickList } from "primereact/picklist";
import { update_features } from "./../action";
import { Redirect } from "react-router-dom";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

class AddFeatureToCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      source: [],
      target: []
    };
    this.pickListTemplate = this.pickListTemplate.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  async componentDidMount() {
    let { machine_category_id } = this.props.match.params;
    let redirect = this;

    let compatibility_data = await FeatureSetService.getFeaturesForId(
      machine_category_id
    );

    redirect.setState({ target: compatibility_data.selected_features });
    redirect.setState({ source: compatibility_data.unselected_features });
    redirect.setState({ loading: false });
  }

  async onChange(event) {
    await this.setState({ source: event.source, target: event.target });
  }

  onSubmit(e) {
    let { machine_category_id } = this.props.match.params;

    e.preventDefault();
    let redirect = this;
    let target = redirect.state.target;
    let parts_array = new FormData();
    parts_array.append("target", JSON.stringify(target));
    parts_array.append("machine_category_id", machine_category_id);

    this.props.update_features(parts_array, machine_category_id);
  }

  pickListTemplate(machine_parts) {
    return (
      <div className="p-clearfix">
        <div
          style={{
            fontSize: "14px",
            float: "right",
            margin: "15px 5px 0 0"
          }}
        >
          {machine_parts.feature_title}
        </div>
      </div>
    );
  }

  render() {
    if (this.props.message.includes("Adding  features to category success")) {
      return <Redirect to="/add-feature-category" />;
    } else {
      if (this.state.loading || this.props.loading) {
        return (
          <div>
            <div
              className="content"
              style={{
                minHeight: 500
              }}
            >
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <strong className="card-title">Add Feature</strong>
                    </div>
                    <div className="card-body">
                      <div className="card-content">
                        <div className="todo-list">
                          <div className="tdl-holder">
                            <div className="tdl-content">
                              <div className="row">
                                <div className="col"></div>
                                <div className="col">
                                  <div className="text-center">
                                    <Loader
                                      type="Rings"
                                      color="#00BFFF"
                                      height={100}
                                      width={100}
                                    />
                                    <div className="text-center client-roboto-condensed">
                                      LOADING...
                                    </div>
                                  </div>
                                </div>
                                <div className="col"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div>
            <div
              className="content"
              style={{
                minHeight: 500
              }}
            >
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <strong className="card-title">Add Feature</strong>
                    </div>
                    <div className="card-body">
                      <div className="card-content">
                        <div className="content-section implementation">
                          <PickList
                            source={this.state.source}
                            target={this.state.target}
                            itemTemplate={this.pickListTemplate}
                            sourceHeader="Available Features"
                            targetHeader="Selected Features"
                            responsive={true}
                            sourceStyle={{
                              height: "400px"
                            }}
                            targetStyle={{
                              height: "400px"
                            }}
                            onChange={this.onChange}
                          ></PickList>
                        </div>
                        <div className="mt-3 text-center">
                          <button
                            onClick={this.onSubmit}
                            className="btn btn-secondary"
                            style={{
                              borderRadius: "10em"
                            }}
                          >
                            Update Feature Set
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
  }
}

const mapStateToProps = state => {
  return {
    message: state.featureReducer.message,
    loading: state.featureReducer.loading
  };
};
export default connect(mapStateToProps, {
  update_features
})(AddFeatureToCategory);
