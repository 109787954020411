import React, { Component } from "react";
import { connect } from "react-redux";
import { show_countries, clean_country_message } from "../action";
import { CurrencyService } from "../service";
import { Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { notification } from "antd";

class ListCurrencies extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			countries: [],
			search_countries: [],
			search: ""
		};
		this.onCountryChange = this.onCountryChange.bind(this);
		this.onDelete = this.onDelete.bind(this);
	}

	async onDelete(id) {
		let confirmation = window.confirm("Are you sure?");

		if (confirmation) {
			this.setState({ loading: true });

			let data = await CurrencyService.deleteCurrency(id);

			if (data.message.toLowerCase().includes("success")) {
				notification.success({
					message: "Success!",
					description: data.message
				});
			} else {
				notification.error({
					message: "Error!",
					description: data.message
				});
			}
		}
		this.setState({ loading: false });
	}

	onCountryChange(name, value) {
		let redirect = this;
		switch (name) {
			case "country":
				redirect.setState({ search: value });
				let parts = redirect.state.countries;
				parts = parts.filter(item =>
					item.country_name.toLowerCase().includes(value)
				);

				redirect.setState({ search_countries: parts });
				break;

			default:
				break;
		}
	}

	async componentDidMount() {
		let countries = await CurrencyService.showCurrencies();
		await this.setState({ countries: countries });
		await this.setState({ search_countries: countries });
		await this.props.clean_country_message();

		await this.setState({ loading: false });
	}

	render() {
		if (this.state.loading) {
			return (
				<div>
					<div
						className="content"
						style={{
							minHeight: 500
						}}
					>
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-header">
										<strong className="card-title">
											Currency Data
										</strong>
									</div>
									<div className="card-body">
										<div className="card-content">
											<div className="todo-list">
												<div className="tdl-holder">
													<div className="tdl-content">
														<div className="row">
															<div className="col"></div>
															<div className="col">
																<div className="text-center">
																	<Loader
																		type="Rings"
																		color="#00BFFF"
																		height={100}
																		width={100}
																	/>
																	<div className="text-center client-roboto-condensed">
																		LOADING...
																	</div>
																</div>
															</div>
															<div className="col"></div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div>
					<div
						className="content"
						style={{
							minHeight: 500
						}}
					>
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-header">
										<strong className="card-title">
											Currency Data
										</strong>
									</div>
									<div className="card-body">
										<div className="card-content">
											<div className="todo-list">
												<div className="tdl-holder">
													<div className="tdl-content">
														<div className="row mb-2">
															<div className="col"></div>
															<div className="col">
																<div className="from-group">
																	<input
																		placeholder="Filter Currency"
																		className="form-control"
																		name="user"
																		id="user"
																		onChange={e =>
																			this.onCountryChange(
																				"country",
																				e.target.value
																			)
																		}
																		value={this.state.search}
																	/>
																</div>
															</div>
														</div>
														<ol>
															{this.state.search_countries.map(
																(country, i) => (
																	<li key={i}>
																		<label
																			style={{
																				color: "black"
																			}}
																		>
																			{country.currency_name}
																			{/* <Link
                                      onClick={e => this.onDelete(country.id)}
                                    >
                                      <i className="fa text-dark fa-times"></i>
                                    </Link> */}
																			<Link
																				to={
																					"/update-currency/" +
																					country.currency_id
																				}
																			>
																				<i className="fa text-dark fa-pencil"></i>
																			</Link>
																		</label>
																	</li>
																)
															)}
														</ol>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}
}

const mapStateToProps = state => {
	return {
		countries: state.countryReducer.countries,
		loading: state.countryReducer.loading,
		message: state.countryReducer.message
	};
};
export default connect(mapStateToProps, {
	show_countries,
	clean_country_message
})(ListCurrencies);
