import React, { Component } from "react";
import { connect } from "react-redux";
// import DropdownTreeSelect from "react-dropdown-tree-select";
import { Accordion, AccordionTab } from "primereact/accordion";
import { ClientConfigurationService } from "./../service";
import { MachineCategoryService } from "./../../../admin/machine_category/service";
import { save_first_form } from "./../action";
import { PulseLoader } from "react-spinners";
import { css } from "@emotion/react";
import config from "./../../../config/config";
import { TreeSelect } from "antd";
import "antd/es/tree-select/style/css";
import { Api } from "../../../config/api";

class editFirstForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			configuration_exist: true,
			loading: true,
			data: [],
			configuration_name: "",
			category_name: "",
			configuration_category: false,
			category_details: false,
			specifications: false,
			features: false,
			max_discount_category: 100,
			only_parts: false,
			user_id: this.props.configuration
				? parseInt(this.props.configuration[0].user_id)
				: false,
			category_image: false
		};
		this.onTextChange = this.onTextChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.onCategoryChange = this.onCategoryChange.bind(this);
	}

	async onCategoryChange(value) {
		await this.setState({ loading: true });
		let that = this;
		that.setState({ configuration_category: value });

		let data = await ClientConfigurationService.getCategoryDetails(value);
		that.setState({ specifications: data.specifications });
		that.setState({ features: data.features });
		that.setState({ category_details: data.machine_category_details });
		that.setState({
			category_name: data.machine_category_details[0].machine_category
		});
		that.setState({
			configuration_category: data.machine_category_details[0].id
		});
		that.setState({
			category_image: data.machine_category_details[0].machine_category_image
		});

		await Api.get(
			"machine-category/getMachineCategoryMaxDiscountByRole/" +
			this.props.role_id +
			"/" +
			value
		)
			.then(function (response) {
				that.setState({ machine_category_data: response.data });
				that.setState({
					max_discount_category: response.data[0].max_discount_category
				});
			})
			.catch(function (error) { });

		let categories = await MachineCategoryService.getTreeSelectedData(
			value,
			this.props.role_id,
			this.props.country_id
		);
		that.setState({ data: categories });

		await this.setState({ loading: false });
	}

	async onSubmit(e) {
		e.preventDefault();
		let that = this;

		if (that.state.configuration_name && that.state.configuration_category) {
			let params = {
				configuration_name: that.state.configuration_name,
				configuration_category: that.state.configuration_category,
				machine_category_name: that.state.category_name,
				max_discount_category: that.state.max_discount_category,
				only_parts: that.state.only_parts,
				user_id: that.state.user_id
			};

			await that.props.save_first_form(params);
			that.props.onComponentChange("seconForm", "");

		}
	}

	onTextChange(name, value) {
		let redirect = this;
		switch (name) {
			case "configuration_name":
				redirect.setState({ configuration_name: value });
				break;

			case "configuration_category":
				redirect.setState({ configuration_category: value });
				break;

			case "only_parts":
				redirect.setState({ only_parts: !this.state.only_parts });
				break;

			default:
				break;
		}
	}

	async componentDidMount() {
		let that = this;
		if (this.props.first_form) {
			that.setState({
				configuration_category: this.props.first_form.configuration_category
			});

			let data = await ClientConfigurationService.getCategoryDetails(
				this.props.first_form.configuration_category
			);
			that.setState({ specifications: data.specifications });
			that.setState({ features: data.features });
			that.setState({ category_details: data.machine_category_details });
			that.setState({
				category_name: data.machine_category_details[0].machine_category
			});

			that.setState({ only_parts: this.props.first_form.only_parts })
			that.setState({
				configuration_category: data.machine_category_details[0].id
			});
			that.setState({
				category_image:
					data.machine_category_details[0].machine_category_image
			});

			that.setState({
				configuration_name: this.props.first_form.configuration_name
			});

			await Api.get(
				"machine-category/getMachineCategoryMaxDiscountByRole/" +
				this.props.role_id +
				"/" +
				data.machine_category_details[0].id
			)
				.then(function (response) {
					that.setState({ machine_category_data: response.data });
					that.setState({
						max_discount_category: response.data[0].max_discount_category
					});
				})
				.catch(function (error) { });

			let categories = await MachineCategoryService.getTreeSelectedData(
				this.props.first_form.configuration_category,
				this.props.role_id,
				this.props.country_id
			);
			that.setState({ data: categories });
		} else {
			await this.setState({ configuration_exist: false });
		}

		await this.setState({ loading: false });
	}

	render() {
		const override = css`
			display: block;
			margin: 0 auto;
			border-color: red;
		`;
		if (!this.state.data || this.state.loading) {
			return (
				<div className="sweet-loading">
					<div className="container loading-container">
						<div className="row m-5 p-5">
							<PulseLoader
								css={override}
								sizeUnit={"px"}
								size={24}
								color={"#B9373D"}
							/>
						</div>
					</div>
				</div>
			);
		} else {
			if (this.state.configuration_exist) {
				return (
					<div className="m-1 row">
						<div className="col"></div>
						<div className="col-sm-12 text-center">
							<h4 className="mb-5 client-roboto-condensed font-weight-bold text-center">
								Edit Configuration Information
							</h4>
							<form className="m-1">
								<div className="row">
									<div className="col">
										<div className="form-group text-center my-2">
											<label className="client-roboto-condensed font-weight-bold">
												Select Product
											</label>
											<br />
											<TreeSelect
												showSearch
												searchPlaceholder="Filter Products"
												placeholder="Select product"
												style={{ width: "52%" }}
												value={this.state.configuration_category}
												dropdownStyle={{
													maxHeight: 300,
													overflow: "auto"
												}}
												treeData={this.state.data}
												treeDefaultExpandAll
												treeNodeFilterProp="title"
												onChange={e => this.onCategoryChange(e)}
											/>
										</div>
										<div className="row my-2 form-group">
											<div className="col"></div>
											<div
												className="col-6"
												style={{
													paddingLeft: 0,
													paddingRight: 0
												}}
											>
												<label className="client-roboto-condensed font-weight-bold">
													Configuration Name
												</label>
												<input
													className="form-control"
													type="text"
													value={
														this.state.configuration_name
															? this.state.configuration_name
															: ""
													}
													onChange={e =>
														this.onTextChange(
															"configuration_name",
															e.target.value
														)
													}
													placeholder="Enter configuration Name"
												/>
											</div>
											<div className="col"></div>
										</div>
									</div>
								</div>
								{this.state.category_image &&
									this.state.category_image !== null ? (
									<>
										<div className="row my-3">
											<div className="col"></div>
											<div className="col-4 text-center">
												<div className="text-center">
													<img loading="lazy"
														alt={"category"}
														height="70%"
														width="70%"
														src={
															config.APP_URL +
															"/api/storage/" +
															this.state.category_image
														}
													/>
												</div>
											</div>
											<div className="col"></div>
										</div>
									</>
								) : null}
								{this.state.specifications && this.state.features ? (
									<Accordion multiple={true}>
										<AccordionTab header="Specifications">
											<div className="row">
												{this.state.specifications.map(
													(specification, i) => (
														<div key={i} className="col-sm-6 mt-2 mb-2">
															<strong>
																{
																	specification.specification_name
																}
																:{" "}
															</strong>
															<br />
															{specification.specification_value}
														</div>
													)
												)}
											</div>
										</AccordionTab>
										<AccordionTab header="Features">
											<div className="row">
												{this.state.features.map((feature, i) => (
													<div key={i} className="col-sm-6 mt-2 mb-2">
														<strong>
															{feature.feature_title}
														</strong>
														<p>{feature.feature_description}</p>
													</div>
												))}
											</div>
										</AccordionTab>
									</Accordion>
								) : null}
								<div style={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'center',
									alignItems: 'center'
								}} className="text-center mt-3 mb-3">
									<div className="mx-1">
										Only Parts?
									</div>
									<div className="mx-1">
										<select
											onChange={e =>
												this.onTextChange(
													"only_parts",
													e.target.value
												)}
											value={this.state.only_parts}
											className="custom-select"
										>
											<option value={false}>No</option>
											<option value={true}>Yes</option>
										</select>
										{/* <input
											onChange={e =>
												this.onTextChange(
													"only_parts",
													e.target.value
												)} value={this.state.only_parts} type="checkbox" /> */}
									</div>
								</div>

								<div className="text-center mt-3 mb-3">
									<button
										onClick={this.onSubmit}
										type="submit"
										className="client-roboto-condensed text-uppercase client-login px-5"
									>
										Next
									</button>
								</div>
							</form>
						</div>
						<div className="col"></div>
					</div>
				);
			} else {
				return (
					<div className="m-4 row">
						<div className="col-sm-12 text-center">
							<h4 className="client-roboto-condensed text-uppercase text-center">
								This configuration is corrupted you cannot edit it
							</h4>
						</div>
					</div>
				);
			}
		}
	}
}
const mapStateToProps = state => {
	return {
		first_form: state.clientConfigurationReducer.first_form,
		configuration:
			state.clientConfigurationReducer.configuration.configuration,
		role_id: state.loginReducer.user.role_id,
		country_id: state.loginReducer.user.user.country_id
	};
};

export default connect(mapStateToProps, {
	save_first_form
})(editFirstForm);
