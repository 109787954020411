import React, { Component } from "react";
import { connect } from "react-redux";
import { css } from "@emotion/react";
import { PulseLoader } from "react-spinners";
import strings from "./../../../config/language";
import { PolicyDocumentService } from "./../../../admin/policy_documents/service";
import config from "./../../../config/config";

class PolicyDownloads extends Component {
	constructor(props) {
		super(props);
		this.state = {
			policy_documents: false,
			search_policy_documents: false,
			search: "",
		};
		this.onSearchChange = this.onSearchChange.bind(this);
	}

	onSearchChange(value) {
		let redirect = this;
		redirect.setState({ search: value });

		let parts = redirect.state.policy_documents;
		parts = parts.filter(item =>
			item.policy_name.toLowerCase().includes(value.toLowerCase())
		);

		redirect.setState({ search_policy_documents: parts });
	}

	async componentDidMount() {
		let policy_documents = await PolicyDocumentService.getAllPoliciesAccoringtoRole(
			this.props.role_id
		);

		this.setState({ policy_documents: policy_documents });

		this.setState({ search_policy_documents: policy_documents });
	}

	render() {
		strings.setLanguage(this.props.language);
		const override = css`
			display: block;
			margin: 0 auto;
			border-color: red;
		`;
		if (this.props.loginLoading) {
			return (
				<div className="sweet-loading">
					<div className="container loading-container">
						<div className="row m-5 p-5">
							<PulseLoader
								css={override}
								sizeUnit={"px"}
								size={24}
								color={"#B9373D"}
							/>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div className="my-4 client-roboto-condensed container">
					<h5 className="text-center font-weight-bold">Policy Documents Download</h5>
						<div className="row">
							<div className="col"></div>
							<div className="col-12 client-cards-list">
								<div className="my-3">
								</div>
								<div className="my-3">
								</div>
								<div className="my-3">
								</div>
								<div className="form-group my-3">
									<input
										className="form-control"
										type="text"
										style={{
											width: 200
										}}
										onChange={e =>
											this.onSearchChange(e.target.value)
										}
										value={this.props.search}
										placeholder="Search Policies"
									/>
								</div>
							</div>
							<div className="col"></div>
						</div>
					<div className="row my-5">
						{this.state.search_policy_documents
							? this.state.search_policy_documents.map((policies, i) => (
									<div key={i} className="col-4">
										<h6 className="text-center my-2">{policies.policy_name}</h6>
										<a
											rel="noopener noreferrer"
											target="_blank"
											download
											href={
												config.APP_URL +
												"/api/storage/" +
												policies.policy_document
											}
											className="btn btn-block client-login"
										>
											Download
										</a>
									</div>
							  ))
							: null}
					</div>
				</div>
			);
		}
	}
}
const mapStateToProps = state => {
	return {
		isAuthenticated: state.loginReducer.isAuthenticated,
		userRole: state.loginReducer.user.role,
		name: state.loginReducer.user.user.name,
		loginLoading: state.loginReducer.loading,
		user_id: state.loginReducer.user.user.id,
		role_id: state.loginReducer.user.role_id,
		language: state.languageReducer.selectedLanguage
	};
};

export default connect(mapStateToProps, {})(PolicyDownloads);
