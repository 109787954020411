import { Api } from "./../../config/api";

function showConfiguredMachines() {
  return Api.get("configuration").then(function(response) {
    return response.data;
  });
}

export const ConfiguredMachineService = {
  showConfiguredMachines
};
