import Axios from "axios";
import config from "./../../config/config";

function showProfileData() {
  let data = Axios.get(config.APP_URL + "/api/auth/user")
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {});

  if (data) {
    return data;
  } else {
    return false;
  }
}

export const ClientProfileService = { showProfileData };
