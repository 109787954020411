import React, { Component } from "react";
import { connect } from "react-redux";
import { SalesDeskService } from "./../service";
import { Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { notification } from "antd";

class ListSalesDesk extends Component {
	constructor(props) {
		super(props);
		this.state = {
			sales_desk_emails: false
		};
		this.onDelete = this.onDelete.bind(this);
	}

	async componentDidMount() {
		let sales_desk_emails = await SalesDeskService.showAllSalesDesk();

		this.setState({ sales_desk_emails: sales_desk_emails });
	}

	//function to trigger delete of certain brand in the list
	async onDelete(id) {
		let confirmation = window.confirm("Are you sure?");

		if (confirmation) {
			let data = await SalesDeskService.deleteSalesDesk(id);

			// show all brands [redux]
			let sales_desk_emails = await SalesDeskService.showAllSalesDesk();

			this.setState({ sales_desk_emails: sales_desk_emails });

			if (data.message.includes("successful")) {
				notification.success({
					message: "Success!",
					description: data.message
				});
			} else {
				notification.error({
					message: "Error!",
					description: data.message
				});
			}
		}
	}

	render() {
		if (!this.props.loading) {
			return (
				<div>
					<div
						className="content"
						style={{
							minHeight: 500
						}}
					>
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-header">
										<strong className="card-title">Sales Desk Email</strong>
									</div>
									<div className="card-body">
										<div className="card-content">
											<div className="todo-list">
												<div className="tdl-holder">
													<div className="tdl-content">
														<ul>
															{this.state.sales_desk_emails
																? this.state.sales_desk_emails.map(
																	(sales_desk, i) => (
																		<li key={i}>
																			<label
																				style={{
																					color: "black"
																				}}
																			>
																				<div className="row">
																					<div className="col-sm-9">
																						{
																							sales_desk.sales_desk_name
																						}
																					</div>
																					<div className="col-sm-3">
																						<Link
																							onClick={e =>
																								this.onDelete(sales_desk.sales_desk_id)
																							}
																						>
																							<i className="fa text-dark fa-times"></i>
																						</Link>
																						<Link
																							to={
																								"/edit-sales-desk/" +
																								sales_desk.sales_desk_id
																							}
																						>
																							<i className="fa text-dark fa-pencil"></i>
																						</Link>
																					</div>
																				</div>
																			</label>
																		</li>
																	)
																)
																: null}
														</ul>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div>
					<div
						className="content"
						style={{
							minHeight: 500
						}}
					>
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-header">
										<strong className="card-title">Sales Desk Emails</strong>
									</div>
									<div className="card-body">
										<div className="card-content">
											<div className="todo-list">
												<div className="tdl-holder">
													<div className="tdl-content">
														<div className="row">
															<div className="col"></div>
															<div className="col">
																<div className="text-center">
																	<Loader
																		type="Rings"
																		color="#00BFFF"
																		height={100}
																		width={100}
																	/>
																	<div className="text-center client-roboto-condensed">
																		LOADING...
																	</div>
																</div>
															</div>
															<div className="col"></div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}
}
const mapStateToProps = state => {
	return {
		message: state.brandReducer.message,
		loading: state.brandReducer.loading,
		brands: state.brandReducer.brands
	};
};

export default connect(mapStateToProps, {})(ListSalesDesk);
