const noteAndRemarkActionTypes = {
  CLEAN_MESSAGE_REQUEST: "clean.message.request",
  CLEAN_MESSAGE_SUCCESS: "clean.message.success",
  CLEAN_MESSAGE_FALIURE: "clean.message.faliure",

  SHOW_NOTE_AND_REMARK_REQUEST: "show.note.and.remark.request",
  SHOW_NOTE_AND_REMARK_SUCCESS: "show.note.and.remark.success",
  SHOW_NOTE_AND_REMARK_FALIURE: "show.note.and.remark.faliure",

  UPDATE_NOTE_AND_REMARK_REQUEST: "update.note.and.remark.request",
  UPDATE_NOTE_AND_REMARK_SUCCESS: "update.note.and.remark.success",
  UPDATE_NOTE_AND_REMARK_FALIURE: "update.note.and.remark.faliure"
};
export default noteAndRemarkActionTypes;
