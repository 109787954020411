import React, { Component } from "react";
import "react-dropdown-tree-select/dist/styles.css";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { UpdateService } from './../service';
import CKEditor from "ckeditor4-react";
import { Switch } from 'antd';

class AddUpdate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            update_title: "",
            update_description: "",
            is_visible: 0,
            loading: true,
            redirect: false
        };
        this.validator = new SimpleReactValidator({ autoForceUpdate: this });
        this.onTextChange = this.onTextChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onEditorChange = this.onEditorChange.bind(this);
    }

    onEditorChange(evt) {
        let that = this
        that.setState({
            update_description: evt.editor.getData()
        });
    }

    // function to handle text changes in multiple fields [two-way binding]
    async onTextChange(name, value) {
        let redirect = this;
        switch (name) {
            case "update_title":
                redirect.setState({ update_title: value });
                break;

            case "is_visible":
                redirect.setState({ is_visible: !redirect.state.is_visible });
                break;

            default:
                break;
        }
    }

    // submit button when add brand is pressed
    async onSubmit(e) {
        e.preventDefault();
        const fd = new FormData();

        if (this.validator.allValid()) {
            fd.append("update_title", this.state.update_title);
            fd.append("update_description", this.state.update_description);
            fd.append("is_visible", this.state.is_visible);
            // let params ={
            //     'machine_category': this.state.category_name,
            //     'parent_category': this.state.parent_category,
            //     'category_level': this.state.category_level,
            //     'price': this.state.price,
            //     'category_image':  this.state.category_image
            // }
            let create = await UpdateService.createUpdate(fd);
            if (create) {
                this.setState({ redirect: true })
            }
        } else {
            this.validator.showMessages();
        }
    }

    async componentDidMount() {
        this.setState({ loading: false })
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to="/updates" />;
        } else {
            if (this.state.loading) {
                return (
                    <div>
                        <div
                            className="content"
                            style={{
                                minHeight: 500
                            }}
                        >
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <strong className="card-title">Add Update</strong>
                                        </div>
                                        <div className="card-body">
                                            <div className="card-content">
                                                <div className="todo-list">
                                                    <div className="tdl-holder">
                                                        <div className="tdl-content">
                                                            <div className="row">
                                                                <div className="col"></div>
                                                                <div className="col">
                                                                    <div className="text-center">
                                                                        <Loader
                                                                            type="Rings"
                                                                            color="#00BFFF"
                                                                            height={100}
                                                                            width={100}
                                                                        />
                                                                        <div className="text-center client-roboto-condensed">
                                                                            LOADING...
                                    </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            } else {
                return (
                    <div>
                        <div
                            className="content"
                            style={{
                                minHeight: 500
                            }}
                        >
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <strong className="card-title">Add Update</strong>
                                        </div>
                                        <div className="card-body">
                                            <form>
                                                <div className="form-group">
                                                    <label
                                                        htmlFor="update_title"
                                                        className="control-label mb-1"
                                                    >
                                                        Update Title
                          </label>
                                                    <input
                                                        id="update_title"
                                                        name="update_title"
                                                        type="text"
                                                        className="form-control"
                                                        value={this.state.update_title}
                                                        onChange={e =>
                                                            this.onTextChange("update_title", e.target.value)
                                                        }
                                                        aria-required="true"
                                                    />
                                                    <div
                                                        className="mt-4"
                                                        style={{
                                                            color: "red"
                                                        }}
                                                    >
                                                        {this.validator.message(
                                                            "update_title",
                                                            this.state.update_title,
                                                            "required|string"
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label
                                                        htmlFor="update_description"
                                                        className="control-label mb-1"
                                                    >
                                                        Update Description
                          </label>

                                                    <CKEditor
                                                        onChange={this.onEditorChange}
                                                        data={this.state.update_description}
                                                    />
                                                    <div
                                                        className="mt-4"
                                                        style={{
                                                            color: "red"
                                                        }}
                                                    >
                                                        {this.validator.message(
                                                            "update_description",
                                                            this.state.update_description,
                                                            "required"
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label
                                                        htmlFor="update_description"
                                                        className="control-label mb-1"
                                                    >
                                                        Update Visibility
                                                    </label>
                                                    <br />
                                                    <br />
                                                    <Switch checked={this.state.is_visible} onChange={(e) =>
                                                        this.onTextChange("is_visible", true)
                                                    }
                                                    />
                                                </div>

                                                <div>
                                                    <button
                                                        onClick={this.onSubmit}
                                                        id="payment-button"
                                                        type="submit"
                                                        className="btn btn-secondary btn-block"
                                                    >
                                                        <i className="fa fa-truck"></i>&nbsp;
                            <span id="payment-button-amount">Add Update</span>
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        }
    }
}

const mapStateToProps = state => {
    return {
        message: state.brandReducer.message,
        loading: state.brandReducer.loading
    };
};

export default connect(mapStateToProps, {
})(AddUpdate);
