import React, { Component } from "react";
import { connect } from "react-redux";
import { save_first_form_data } from "./../action";
import SimpleReactValidator from "simple-react-validator";
import { Api } from "../../../config/api";
import { UserService } from "./../../../admin/user/service";
import { PulseLoader } from "react-spinners";
import { css } from "@emotion/react";

class FirstForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			quotation_name: this.props.first_form
				? this.props.first_form.quotation_name
				: "",
			quotation_for: this.props.first_form
				? this.props.first_form.quotation_for
				: "",
			users: false,
			user: this.props.first_form ? this.props.first_form.user_id : null
		};
		this.validator = new SimpleReactValidator({ autoForceUpdate: this });
		this.onSubmit = this.onSubmit.bind(this);
		this.onTextChange = this.onTextChange.bind(this);
		this.onSelectChange = this.onSelectChange.bind(this);
	}

	onSelectChange(value) {
		this.setState({ user: value });
	}

	onSubmit(e) {
		e.preventDefault();

		if (this.validator.allValid()) {
			if (this.props.is_support) {
				let params = {
					user_id: this.state.user,
					quotation_name: this.state.quotation_name,
					quotation_for: this.state.quotation_for
				};

				this.props.save_first_form_data(params);
				this.props.onComponentChange("seconForm");
			} else {
				let params = {
					quotation_name: this.state.quotation_name,
					quotation_for: this.state.quotation_for
				};

				this.props.save_first_form_data(params);
				this.props.onComponentChange("seconForm");
			}
		} else {
			this.validator.showMessages();
		}
	}

	onTextChange(name, value) {
		let that = this;

		switch (name) {
			case "quotation_name":
				that.setState({ quotation_name: value });
				break;

			case "quotation_for":
				that.setState({ quotation_for: value });
				break;

			default:
				break;
		}
	}

	async componentDidMount() {
		let that = this;

		let data = await UserService.showUsers(this.props.token);

		this.setState({ users: data });

		await Api.get("quote/get/lastQuote").then(function(response) {
			that.setState({
				quotation_name: "NLV-QUO-" + (parseInt(response.data[0].id) + 1)
			});
		});

		await this.setState({ loading: false });
	}

	render() {
		const override = css`
			display: block;
			margin: 0 auto;
			border-color: red;
		`;
		if (!this.state.users || this.state.loading) {
			return (
				<div className="sweet-loading">
					<div className="container loading-container">
						<div className="row m-5 p-5">
							<PulseLoader
								css={override}
								sizeUnit={"px"}
								size={24}
								color={"#B9373D"}
							/>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div className="m-2 row">
					<div className="col"></div>
					<div className="col-sm-12 text-center">
						<h4 className="client-roboto-condensed font-weight-bold mb-4 text-center">
							Quote Information
						</h4>
						<form className="m-5">
							<div className="row text-center mt-3 mb-3">
								<div className="col"></div>
								<div className="col-6">
									<div className="form-group">
										<label className="client-roboto-condensed font-weight-bold">
											Quote Name
										</label>
										<input
											style={{
												textAlign: "center"
											}}
											className="form-control"
											type="text"
											value={
												this.state.quotation_name
													? this.state.quotation_name
													: ""
											}
											onChange={e =>
												this.onTextChange(
													"quotation_name",
													e.target.value
												)
											}
											placeholder="Enter quote Name"
										/>
										<div
											className="mt-4"
											style={{
												color: "red"
											}}
										>
											{this.validator.message(
												"quotation_name",
												this.state.quotation_name,
												"required|string|alpha_num_dash_space"
											)}
										</div>
									</div>
									{this.props.is_support ? (
										<div className="form-group">
											<label className="client-roboto-condensed font-weight-bold">
												Select User
											</label>
											<select
												defaultValue={this.state.user}
												onChange={e =>
													this.onSelectChange(e.target.value)
												}
												className="custom-select"
											>
												<option value="">Select User</option>
												{this.state.users.map((user, i) =>
													user.role_name
														.toLowerCase()
														.includes("admin") ||
													user.role_name
														.toLowerCase()
														.includes("support") ? null : (
														<option 
															key={i}
															value={user.id}>
															{user.name +
																"\t-\t[" +
																user.email +
																"]"}
														</option>
													)
												)}
											</select>
											<div
												className="mt-4"
												style={{
													color: "red"
												}}
											>
												{this.validator.message(
													"user",
													this.state.user,
													"required"
												)}
											</div>
										</div>
									) : null}
									<div className="form-group">
										<label className="client-roboto-condensed font-weight-bold">
											Customer Name
										</label>
										<input
											style={{
												textAlign: "center"
											}}
											className="form-control"
											type="text"
											value={
												this.state.quotation_for
													? this.state.quotation_for
													: ""
											}
											onChange={e =>
												this.onTextChange(
													"quotation_for",
													e.target.value
												)
											}
											placeholder="Enter Customer Name"
										/>
										<div
											className="mt-4"
											style={{
												color: "red"
											}}
										>
											{this.validator.message(
												"quotation_for",
												this.state.quotation_for,
												"required|string|alpha_num_dash_space"
											)}
										</div>
									</div>
									<button
										onClick={this.onSubmit}
										type="submit"
										className="client-roboto-condensed text-uppercase client-login px-5"
									>
										Next
									</button>
								</div>
								<div className="col"></div>
							</div>
						</form>
					</div>
					<div className="col"></div>
				</div>
			);
		}
	}
}
const mapStateToProps = state => {
	return {
		user_id: state.loginReducer.user.user.id,
		first_form: state.clientQuoteReducer.firstForm,
		quotes: state.clientQuoteReducer.quotes,
		role: state.loginReducer.user.role,
		token: state.loginReducer.token,
		is_support: state.loginReducer.user.is_support
	};
};

const mapDispatchToProps = {
	save_first_form_data
};

export default connect(mapStateToProps, mapDispatchToProps)(FirstForm);
