import { Api } from "./../../config/api";

function showFeatures() {
  let data = Api.get("feature/showAllFeatures")
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {});

  if (data) {
    return data;
  } else {
    return false;
  }
}

function showSingleFeatures(params) {
  let data = Api.get("feature/showSpecificFeature/" + params)
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {});

  if (data) {
    return data;
  } else {
    return false;
  }
}

function addFeature(params) {
  let data = Api.post("feature/addFeature", params)
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {});

  if (data) {
    return data;
  } else {
    return false;
  }
}

function editFeature(params) {
  let data = Api.post("feature/edit/" + params.feature_id, params)
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {});

  if (data) {
    return data;
  } else {
    return false;
  }
}

function deleteFeature(params) {
  let data = Api.get("feature/delete/" + params)
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {});

  if (data) {
    return data;
  } else {
    return false;
  }
}

function getFeaturesForId(params) {
  let data = Api.get("feature/getFeaturesForId/" + params)
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {});

  if (data) {
    return data;
  } else {
    return false;
  }
}

function addFeaturesToCategory(parts_array, machine_category_id) {
  let data = Api.post(
    "feature/addFeaturesToMachineCategory/" + machine_category_id,
    parts_array
  )
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {});

  if (data) {
    return data;
  } else {
    return false;
  }
}

export const FeatureSetService = {
  showFeatures,
  getFeaturesForId,
  showSingleFeatures,
  addFeaturesToCategory,
  addFeature,
  editFeature,
  deleteFeature
};
