import React, { Component } from "react";
import { Provider } from "react-redux";
import store from "./config/store";
import Main from "./layout/main";
import { BrowserRouter, Route } from "react-router-dom";

export default class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Provider store={store()}>
          <Route component={Main} />
        </Provider>
      </BrowserRouter>
    );
  }
}
