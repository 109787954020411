import noteAndRemarkActionTypes from "./actionTypes";

function show_note_and_remark() {
  return {
    type: noteAndRemarkActionTypes.SHOW_NOTE_AND_REMARK_REQUEST,
    loading: true
  };
}

function update_note_and_remark(params) {
  return {
    type: noteAndRemarkActionTypes.UPDATE_NOTE_AND_REMARK_REQUEST,
    loading: true,
    params: params
  };
}

function clean_message() {
  return {
    type: noteAndRemarkActionTypes.CLEAN_MESSAGE_REQUEST,
    loading: true
  };
}

export { show_note_and_remark, update_note_and_remark, clean_message };
