import { Api } from "./../../config/api";

// api call to show all brands
function showBrands() {
  let data = Api.get("brand").then(function(response) {
    return response.data;
  });

  if (data) {
    return data;
  } else {
    return false;
  }
}

//api call to show a single brand
function showSingleBrand(param) {
  let data = Api.get("brand/" + param).then(function(response) {
    return response.data;
  });

  if (data) {
    return data;
  } else {
    return false;
  }
}

//api call to add a brand into the system
function addBrand(params) {
  let data = Api.post("brand", params).then(function(response) {
    return response.data;
  });

  if (data) {
    return data;
  } else {
    return false;
  }
}

//api call to edit brand exisitng in the system
function editBrand(params) {
  let data = Api.post("brand/edit/" + params.get("id"), params).then(function(
    response
  ) {
    return response.data;
  });

  if (data) {
    return data;
  } else {
    return false;
  }
}

//api call to delete exisitng brand from the system
function deleteBrand(params) {
  let data = Api.get("brand/delete/" + params).then(function(response) {
    return response.data;
  });

  if (data) {
    return data;
  } else {
    return false;
  }
}

export const BrandService = {
  showBrands,
  showSingleBrand,
  addBrand,
  editBrand,
  deleteBrand
};
