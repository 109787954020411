import yearActionTYpes from "./actionTypes";

function clean_message() {
  return {
    type: yearActionTYpes.CLEAN_MESSAGE_REQUEST,
    loading: true
  };
}

function show_year() {
  return { type: yearActionTYpes.SHOW_YEAR_REQUEST, loading: true };
}

function show_single_year(params) {
  return {
    type: yearActionTYpes.SHOW_SINGLE_YEAR_REQUEST,
    loading: true,
    params: params
  };
}

function add_year(params) {
  return {
    type: yearActionTYpes.ADD_YEAR_REQUEST,
    loading: true,
    params: params
  };
}

function edit_year(params) {
  return {
    type: yearActionTYpes.EDIT_YEAR_REQUEST,
    loading: true,
    params: params
  };
}

function delete_year(params) {
  return {
    type: yearActionTYpes.DELETE_YEAR_REQUEST,
    loading: true,
    params: params
  };
}

export {
  show_year,
  clean_message,
  show_single_year,
  add_year,
  edit_year,
  delete_year
};
