import React, { Component } from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import config from "./../../../config/config";
import { Api } from "../../../config/api";
import Swal from "sweetalert2";

export default class UpdateRole extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			role: [],
			role_name: "",
			role_image: null,
			old_image: null,
			redirect: false
		};
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleFileChange = this.handleFileChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.removeImage = this.removeImage.bind(this);
	}

	async removeImage(value) {
		let result1 = await Swal.fire({
			title: "Are you sure?",
			text: "Do you want delete this image?",
			icon: "info",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes!"
		}).then(result => {
			return result;
		});

		if (result1.value) {
			this.setState({ loading: true });
			let result = await Api.get("user-role/removeRoleBannerImage/" + value)
				.then(function(response) {
					return true;
				})
				.catch(function(error) {
					return false;
				});

			if (result) {
				let { id } = this.props.match.params;
				let redirect = this;

				await axios
					.get(config.APP_URL + "/api/user-role/" + id)
					.then(function(response) {
						redirect.setState({ role: response.data[0] });
						redirect.setState({ role_name: response.data[0].role_name });
						redirect.setState({
							old_image: response.data[0].role_banner_image
						});
					});
			}
			this.setState({ loading: false });
		}
	}

	handleFileChange(event) {
		this.setState({ role_image: event.target.files[0] });
	}

	handleInputChange(value) {
		this.setState({ role_name: value });
	}

	async onSubmit(e) {
		this.setState({ loading: true });

		e.preventDefault();
		let { id } = this.props.match.params;
		let that = this;
		let data = new FormData();
		data.append("role_name", this.state.role_name);
		if (this.state.role_image !== null) {
			data.append("role_banner_image", this.state.role_image);
		}
		await axios
			.post(config.APP_URL + "/api/user-role/edit/" + id, data)
			.then(function(response) {
				that.setState({ redirect: true });
			})
			.catch(function(error) {});

		this.setState({ loading: false });
	}

	async componentDidMount() {
		let { id } = this.props.match.params;
		let redirect = this;

		await axios
			.get(config.APP_URL + "/api/user-role/" + id)
			.then(function(response) {
				redirect.setState({ role: response.data[0] });
				redirect.setState({ role_name: response.data[0].role_name });
				redirect.setState({
					old_image: response.data[0].role_banner_image
				});
			});
		redirect.setState({ loading: false });
	}

	render() {
		if (this.state.redirect) {
			return <Redirect to="/user-role" />;
		} else {
			if (this.state.loading) {
				return (
					<div>
						<div
							className="content"
							style={{
								minHeight: 500
							}}
						>
							<div className="row">
								<div className="col-sm-12">
									<div className="card">
										<div className="card-header">
											<strong className="card-title">
												Update User Role
											</strong>
										</div>
										<div className="card-body">
											<div className="card-content">
												<div className="todo-list">
													<div className="tdl-holder">
														<div className="tdl-content">
															<div className="row">
																<div className="col"></div>
																<div className="col">
																	<div className="text-center">
																		<Loader
																			type="Rings"
																			color="#00BFFF"
																			height={100}
																			width={100}
																		/>
																		<div className="text-center client-roboto-condensed">
																			LOADING...
																		</div>
																	</div>
																</div>
																<div className="col"></div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				);
			} else {
				return (
					<div>
						<div
							className="content"
							style={{
								minHeight: 500
							}}
						>
							<div className="row">
								<div className="col-sm-12">
									<div className="card">
										<div className="card-header">
											<strong className="card-title">
												Update User Role
											</strong>
										</div>
										<div className="card-body">
											<form>
												<div className="form-group">
													<label
														htmlFor="roleName"
														className="control-label mb-1"
													>
														Role Name
													</label>
													<input
														id="roleName"
														name="roleName"
														type="text"
														className="form-control"
														aria-required="true"
														onChange={e =>
															this.handleInputChange(
																e.target.value
															)
														}
														value={this.state.role_name}
													/>
												</div>
												{this.state.old_image !== null ? (
													<div className="image-card my-4">
														<div className="imagewrap">
															<div className="thumbnail">
																<img loading="lazy"
																	alt="brand"
																	src={
																		config.APP_URL +
																		"/api/storage/" +
																		this.state.old_image
																	}
																/>
															</div>
															<button
																onClick={() =>
																	this.removeImage(
																		this.props.match.params.id
																	)
																}
																type="button"
																className="button1 ig-btn"
															>
																<i className="fa fa-times fa-2x"></i>
															</button>
														</div>
													</div>
												) : // <div className="form-group">
												// 	<img
												// 		style={{
												// 			maxWidth: 400
												// 		}}
												// 		src={
												// 			config.APP_URL +
												// 			"/api/storage/" +
												// 			this.state.old_image
												// 		}
												// 		alt={this.state.role_name}
												// 	/>
												// </div>
												null}
												<div className="form-group">
													{/* <div className="file-input"> */}
													<input
														type="file"
														// id="file-input"
														// className="file-input__input"
														name="category_image"
														onChange={e =>
															this.handleFileChange(e)
														}
														accept="image/jpg, image/jpeg, image/png"
													/>
													{/* <label
															className="file-input__label"
															for="file-input"
														>
															<svg
																aria-hidden="true"
																focusable="false"
																data-prefix="fas"
																data-icon="upload"
																className="svg-inline--fa fa-upload fa-w-16"
																role="img"
																xmlns="http://www.w3.org/2000/svg"
																viewBox="0 0 512 512"
															>
																<path
																	fill="currentColor"
																	d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"
																></path>
															</svg>
															<span>Role Image</span>
														</label>
													</div> */}
												</div>
												<div>
													<button
														id="payment-button"
														type="submit"
														onClick={this.onSubmit}
														className="btn btn-secondary btn-block"
													>
														<i className="fa fa-user"></i>&nbsp;
														<span id="payment-button-amount">
															Update Role
														</span>
													</button>
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				);
			}
		}
	}
}
