import { Api } from "./../../config/api";
import config from "./../../config/config";


function showQuotes(user) {
	let data = Api.get("quote/user/" + user)
		.then(function(response) {
			return response.data;
		})
		.catch(function(error) {});

	if (data) {
		return data;
	} else {
		return false;
	}
}

function showQuotesSalesSupport(user) {
	let data = Api.get("sales-support/getAllQuotesSalesSupport/" + user)
		.then(function(response) {
			return response.data;
		})
		.catch(function(error) {});

	if (data) {
		return data;
	} else {
		return false;
	}
}

function showSingleQuote(user, params) {
	let data = Api.get("quote/user/" + user + "/" + params)
		.then(function(response) {
			return response.data;
		})
		.catch(function(error) {});

	if (data) {
		return data;
	} else {
		return false;
	}
}

function showSingleQuoteSalesSupport(user, params) {
	let data = Api.get(
		"sales-support/getSingleQuoteSalesSupport/" + user + "/" + params
	)
		.then(function(response) {
			return response.data;
		})
		.catch(function(error) {});

	if (data) {
		return data;
	} else {
		return false;
	}
}

function createQuote(params) {
	let data = Api.post("quote", params)
		.then(function(response) {
			return response.data;
		})
		.catch(function(error) {});

	if (data) {
		return data;
	} else {
		return false;
	}
}

function editClientQuote(id, params) {
	let data = Api.post("quote/edit/" + id, params)
		.then(function(response) {
			return response.data;
		})
		.catch(function(error) {
			return false;
		});

	if (data) {
		return data;
	} else {
		return false;
	}
}

function getDataForEditing(params) {
	let data = Api.get("quote/dataForEditing/" + params)
		.then(function(response) {
			return response.data;
		})
		.catch(function(error) {
			return false;
		});

	if (data) {
		return data;
	} else {
		return false;
	}
}

function orderQuote(user_id, params) {
	let data = Api.post("order/" + user_id, params)
		.then(function(response) {
			return response.data;
		})
		.catch(function(error) {
			return false;
		});

	if (data) {
		return data;
	} else {
		return false;
	}
}

async function downloadDocx(quote_id) {
	let data = await Api.get("quote/downloadDocx/" + quote_id)
		.then(function(response) {
			return response.data;
		})
		.catch(function(error) {
			return false;
		});
	
	if (data) {
		var link = document.createElement("a");
		link.setAttribute('download',data.quote[0].quotation_file_doc.split('/').pop());
		link.href = config.APP_URL + "/api/storage/" + data.quote[0].quotation_file_doc;
		link.target = '_blank';
		document.body.appendChild(link);
		link.click();
		link.remove();
	} else {
		return false;
	}
}

async function downloadXlsx(quote_id) {
	
	let data = await Api.get("quote/downloadXlsx/" + quote_id)
		.then(function(response) {
			return response.data;
		})
		.catch(function(error) {
			return false;
		});

	if (data) {
		var link = document.createElement("a");
		link.setAttribute('download',data.quote[0].quotation_file_xls.split('/').pop());
		link.href = config.APP_URL + "/api/storage/" + data.quote[0].quotation_file_xls;
		link.target = '_blank';
		document.body.appendChild(link);
		link.click();
		link.remove();
	} else {
		return false;
	}
}

function getAllCommentsForQuote(quote_id) {
	let data = Api.get("comments/" + quote_id)
		.then(function(response) {
			return response.data;
		})
		.catch(function(error) {
			return false;
		});

	if (data) {
		return data;
	} else {
		return false;
	}
}

function showChildrenQuotes(user_id) {
	let data = Api.get("user-hierarchy/showChildrenQuotes/" + user_id)
		.then(function(response) {
			return response.data;
		})
		.catch(function(error) {
			return false;
		});

	if (data) {
		return data;
	} else {
		return false;
	}
}
function fetchDocumentS3(document_name) {
	let data = Api.post("quote/download-doc",{url: document_name})
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			return false;
		});
  
	if (data) {
		return data;
	} else {
		return false;
	}
  }
  

export const ClientQuoteService = {
	getAllCommentsForQuote,
	showQuotes,
	showSingleQuoteSalesSupport,
	showQuotesSalesSupport,
	orderQuote,
	getDataForEditing,
	showSingleQuote,
	createQuote,
	editClientQuote,
	downloadDocx,
	downloadXlsx,
	showChildrenQuotes,
	fetchDocumentS3
};
