import React, { Component } from "react";
import { SpreadsheetService } from "./../service";
import { notification } from "antd";

export default class UploadSpreadsheet extends Component {
	constructor(props) {
		super(props);
		this.state = {
			file: null
		};
		this.fileHandler = this.fileHandler.bind(this);
		this.onSelectValue = this.onSelectValue.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	onSelectValue(event) {
		this.setState({ type: event });
	}

	async componentDidMount() {
		let redirect = this;
		let data = await SpreadsheetService.getTypes();
		redirect.setState({ type: data[2] });
		redirect.setState({ types: data });
	}

	async onSubmit(e) {
		e.preventDefault();
		let redirect = this;

		let fd = new FormData();
		fd.append("file", redirect.state.file);
		fd.append("type", redirect.state.type);

		let data = await SpreadsheetService.uploadXlsxFile(fd);

		if (data.message !== undefined) {
			if (
				data.message
					.toLowerCase()
					.includes("machine parts excel sheet uploaded successfully")
			) {
				notification.success({
					message: "Success!",
					description: data.message
				});
			} else {
				notification.error({
					message: "Error!",
					description: data.message
				});
			}
		} else {
			notification.error({
				message: "Error!",
				description: "File not uploaded."
			});
		}
	}

	fileHandler(name, event) {
		switch (name) {
			case "file":
				this.setState({ file: event.target.files[0] });
				break;

			default:
				break;
		}
	}

	render() {
		return (
			<div>
				<div
					className="content"
					style={{
						minHeight: 500
					}}
				>
					<div className="row">
						<div className="col-sm-12">
							<div className="card">
								<div className="card-header">
									<strong className="card-title">
										Upload Machine Parts
									</strong>
								</div>
								<div className="card-body">
									<div className="card-content">
										<div className="todo-list">
											<div className="tdl-holder">
												<div className="tdl-content">
													<div className="row">
														<div className="col"></div>
														<div className="col"></div>
														<div className="col">
															<div className="form-group">
																<input
																	className="from-control"
																	type="file"
																	onChange={e =>
																		this.fileHandler(
																			"file",
																			e
																		)
																	}
																	accept=".xlsx"
																/>
															</div>
														</div>
														<div className="col"></div>
														<div className="col"></div>
													</div>
													<div className="row">
														<div className="col"></div>
														<div className="col"></div>
														<div className="col">
															<button
																onClick={this.onSubmit}
																className="btn btn-rounded btn-secondary text-center"
															>
																Upload Parts
															</button>
														</div>
														<div className="col"></div>
														<div className="col"></div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
