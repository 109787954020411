import React, { Component } from "react";
import { connect } from "react-redux";
import FirstForm from "./firstForm";
import SecondForm from "./secondFrom";
import ThirdForm from "./thirdForm";
import { Steps } from "primereact/steps";
import { Redirect } from "react-router-dom";
import { clean_form } from "./../action";
import { css } from "@emotion/react";
import { PulseLoader } from "react-spinners";

class CreateQuote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      firstFormOn: true,
      secondFormOn: false,
      thirdFormOn: false
    };
    this.handleComponentChange = this.handleComponentChange.bind(this);
  }

  handleComponentChange(value) {
    let that = this;

    switch (value) {
      case "firstForm":
        that.setState({ activeIndex: 0 });
        that.setState({ firstFormOn: true });
        that.setState({ secondFormOn: false });
        that.setState({ thirdFormOn: false });
        break;

      case "seconForm":
        that.setState({ activeIndex: 1 });
        that.setState({ firstFormOn: false });
        that.setState({ secondFormOn: true });
        that.setState({ thirdFormOn: false });
        break;

      case "thirdForm":
        that.setState({ activeIndex: 2 });
        that.setState({ firstFormOn: false });
        that.setState({ secondFormOn: false });
        that.setState({ thirdFormOn: true });
        break;

      default:
        break;
    }
  }

  async componentDidMount() {
    await this.props.clean_form();
  }

  render() {
    if (
      this.props.message === "Creating user quote success" &&
      this.props.quote
    ) {
      return <Redirect to={"/quote/" + this.props.quote.quote[0].id} />;
    } else {
      const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
      `;
      if (this.props.loading || this.props.loginLoading) {
        return (
          <div className="sweet-loading">
            <div className="container loading-container">
              <div className="row m-5 p-5">
                <PulseLoader
                  css={override}
                  sizeUnit={"px"}
                  size={24}
                  color={"#B9373D"}
                />
              </div>
            </div>
          </div>
        );
      } else {
        const items = [
          {
            label: "Quote Information",
            command: event => {
              this.setState({ firstFormOn: true });
              this.setState({ secondFormOn: false });
              this.setState({ thirdFormOn: false });
            }
          },
          {
            label: "Configuration Selection",
            command: event => {
              this.setState({ firstFormOn: false });
              this.setState({ secondFormOn: true });
              this.setState({ thirdFormOn: false });
            }
          },
          {
            label: "Confirmation",
            command: event => {
              this.setState({ firstFormOn: false });
              this.setState({ secondFormOn: false });
              this.setState({ thirdFormOn: true });
            }
          }
        ];
        return (
          <div className="my-2 client-roboto-condensed container">
            <div className="row">
              <div className="col"></div>
              <div className="col-sm-12 text-center">
                <div className="text-center">
                  <div className="content-section implementation">
                    <Steps
                      className="mt-5 mb-5"
                      model={items}
                      activeIndex={this.state.activeIndex}
                      onSelect={e => this.setState({ activeIndex: e.index })}
                      readOnly={true}
                    />
                  </div>
                </div>
              </div>
              <div className="col"></div>
            </div>
            {this.state.firstFormOn ? (
              <div className="first-form">
                <FirstForm onComponentChange={this.handleComponentChange} />
              </div>
            ) : null}
            {this.state.secondFormOn ? (
              <div className="second-form">
                <SecondForm onComponentChange={this.handleComponentChange} />
              </div>
            ) : null}
            {this.state.thirdFormOn ? (
              <div className="third-form">
                <ThirdForm onComponentChange={this.handleComponentChange} />
              </div>
            ) : null}
          </div>
        );
      }
    }
  }
}
const mapStateToProps = state => {
  return {
    loading: state.clientQuoteReducer.loading,
    user_id: state.loginReducer.user.user.id,
    message: state.clientQuoteReducer.message,
    quote: state.clientQuoteReducer.quote,
    loginLoading: state.loginReducer.loading
  };
};

export default connect(mapStateToProps, { clean_form })(CreateQuote);
