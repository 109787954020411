import { take, put } from "redux-saga/effects";
import languageActionTypes from "./actionTypes";

function* changeLanguage() {
  while (true) {
    let { param } = yield take(languageActionTypes.LANGUAGE_CHANGE_REQUEST);

    yield put({
      type: languageActionTypes.LANGUAGE_CHANGE_SUCCESS,
      message: "Language change success",
      selectedLanguage: param.language,
      selectedCountry: param.country
    });
  }
}

export { changeLanguage };
