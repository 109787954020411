import machinePartsActionTypes from "./actionTypes";
import { MachinePartService } from "./service";
import { take, call, put } from "redux-saga/effects";

function* cleanMachinePartMessage() {
  while (true) {
    yield take(machinePartsActionTypes.CLEAN_MESSAGE_REQUEST);

    yield put({
      type: machinePartsActionTypes.CLEAN_MESSAGE_SUCCESS
    });
  }
}

function* showMachineParts() {
  while (true) {
    yield take(machinePartsActionTypes.SHOW_MACHINE_PARTS_REQUEST);

    const machine_parts = yield call(MachinePartService.showMachineParts);

    if (machine_parts !== "" && machine_parts !== null && machine_parts) {
      yield put({
        type: machinePartsActionTypes.SHOW_MACHINE_PARTS_SUCCESS,
        message: "Getting machine parts success",
        machine_parts: machine_parts
      });
    } else {
      yield put({
        type: machinePartsActionTypes.SHOW_MACHINE_PARTS_FALIURE,
        message: "Getting machine parts faliure"
      });
    }
  }
}

function* addMachineParts(params) {
  while (true) {
    const { params } = yield take(
      machinePartsActionTypes.ADD_MACHINE_PARTS_REQUEST
    );

    const data = yield call(MachinePartService.addMachineParts, params);
    if (data !== "" && data !== null && data) {
      yield put({
        type: machinePartsActionTypes.ADD_MACHINE_PARTS_SUCCESS,
        message: "Adding machine parts success"
      });
    } else {
      yield put({
        type: machinePartsActionTypes.ADD_MACHINE_PARTS_FALIURE,
        message: "Adding machine parts faliure"
      });
    }
  }
}

function* editMachineParts(params) {
  while (true) {
    const { params, id } = yield take(
      machinePartsActionTypes.EDIT_MACHINE_PARTS_REQUEST
    );

    const data = yield call(MachinePartService.editMachineParts, params, id);
    if (data !== "" && data !== null && data) {
      yield put({
        type: machinePartsActionTypes.EDIT_MACHINE_PARTS_SUCCESS,
        message: "Editing machine parts success"
      });
    } else {
      yield put({
        type: machinePartsActionTypes.EDIT_MACHINE_PARTS_FALIURE,
        message: "Editing machine parts faliure"
      });
    }
  }
}

function* editMachinePartsOnly(params) {
  while (true) {
    const { params, id } = yield take(
      machinePartsActionTypes.EDIT_MACHINE_PARTS_ONLY_REQUEST
    );

    const data = yield call(
      MachinePartService.editMachinePartsOnly,
      params,
      id
    );

    if (data !== "" && data !== null && data) {
      yield put({
        type: machinePartsActionTypes.EDIT_MACHINE_PARTS_ONLY_SUCCESS,
        message: "Editing machine parts only success"
      });
    } else {
      yield put({
        type: machinePartsActionTypes.EDIT_MACHINE_PARTS_ONLY_FALIURE,
        message: "Editing machine parts only faliure"
      });
    }
  }
}

function* deleteMachineParts(params) {
  while (true) {
    const { params } = yield take(
      machinePartsActionTypes.DELETE_MACHINE_PARTS_REQUEST
    );

    const data = yield call(MachinePartService.deleteMachineParts, params);

    if (data !== "" && data !== null && data) {
      yield put({
        type: machinePartsActionTypes.DELETE_MACHINE_PARTS_SUCCESS,
        message: "Deleting machine parts success"
      });
    } else {
      yield put({
        type: machinePartsActionTypes.DELETE_MACHINE_PARTS_FALIURE,
        message: "Deleting machine parts faliure"
      });
    }
  }
}

function* addMachinePartData(params) {
  while (true) {
    const { params } = yield take(
      machinePartsActionTypes.ADD_MACHINE_PART_DATA_REQUEST
    );

    const data = yield call(MachinePartService.addMachinePartData, params);

    if (data !== "" && data !== null && data) {
      yield put({
        type: machinePartsActionTypes.ADD_MACHINE_PART_DATA_SUCCESS,
        message: "Adding machine parts data success"
      });
    } else {
      yield put({
        type: machinePartsActionTypes.ADD_MACHINE_PART_DATA_FALIURE,
        message: "Adding machine parts data faliure"
      });
    }
  }
}

export {
  showMachineParts,
  cleanMachinePartMessage,
  addMachineParts,
  editMachineParts,
  editMachinePartsOnly,
  deleteMachineParts,
  addMachinePartData
};
