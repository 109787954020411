import React, { Component } from "react";
import { connect } from "react-redux";
import { css } from "@emotion/react";
import { Api } from "./../../config/api";
import { PulseLoader } from "react-spinners";
import Swal from "sweetalert2";
import SimpleReactValidator from "simple-react-validator";

class ForgotPassword extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			email: "",
			password: "",
			password_confirmation: "",
			langauge_id: "",
			token: "",
			countries: [],
			selected_country: "",
			message: ""
		};
		this.validator = new SimpleReactValidator();
		this.onTextChange = this.onTextChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	onTextChange(name, value) {
		let redirect = this;
		switch (name) {
			case "email":
				redirect.setState({ email: value });
				break;

			case "password":
				redirect.setState({ password: value });
				break;

			case "password_confirmation":
				redirect.setState({ password_confirmation: value });
				break;

			default:
				break;
		}
	}

	async onSubmit(e) {
		e.preventDefault();

		this.setState({ loading: true });

		let that = this;

		if (this.validator.allValid()) {
			let params = {
				email: this.state.email
			};

			await Api.post("/password/email", params)
				.then(function(response) {
					that.setState({ message: response.data });

					Swal.fire({
						title: "Password Resest!",
						text: "Password reset e-mail has been sent! Please check!!",
						icon: "success",
						confirmButtonText: "OK"
					});
				})
				.catch(function(error) {
					Swal.fire({
						title: "Password Reset!",
						text: "Password reset e-mail has not been sent!!",
						icon: "error",
						confirmButtonText: "OK"
					});
				});
		} else {
			this.validator.showMessages();
			this.forceUpdate();
		}
		await this.setState({ loading: false });
	}

	async componentDidMount() {
		await this.setState({ loading: false });
	}

	render() {
		const override = css`
			display: block;
			margin: 0 auto;
			border-color: red;
		`;
		if (this.props.loading) {
			return (
				<div className="sweet-loading">
					<div className="container loading-container">
						<div className="row m-5 p-5">
							<PulseLoader
								css={override}
								sizeUnit={"px"}
								size={24}
								color={"#B9373D"}
							/>
						</div>
					</div>
				</div>
			);
		} else {
			if (this.state.loading) {
				return (
					<div className="sweet-loading">
						<div className="container loading-container">
							<div className="row m-5 p-5">
								<PulseLoader
									css={override}
									sizeUnit={"px"}
									size={24}
									color={"#B9373D"}
								/>
							</div>
						</div>
					</div>
				);
			} else {
				return (
					<div>
						<div className="my-5 roboto-condensed container">
							<div className="row">
								<div className="col"></div>
								<div className="col-8">
									<div className="text-center">
										<div className="content-section introduction">
											<div className="feature-intro">
												<h4 className=" mb-4 roboto-condensed ">
													Forgot Password | Reesink UK CPQ
												</h4>
											</div>
										</div>
										<div className="row">
											<div className="col"></div>
											<div className="col-6">
												<form>
													<div className="form-group">
														<label className="roboto-condensed">
															E-mail Address
														</label>
														<input
															type="text"
															value={this.state.email}
															onChange={e =>
																this.onTextChange(
																	"email",
																	e.target.value
																)
															}
															className="form-control"
															placeholder="Enter E-mail"
														/>
														<div
															className="mt-4"
															style={{
																color: "red"
															}}
														>
															{this.validator.message(
																"Email",
																this.state.email,
																"required|string|email"
															)}
														</div>
													</div>
													{/* <div className="text-center mb-4">
														<Link
															className="red-link roboto-condensed "
															to="/"
														>
															Login
														</Link>
													</div> */}
													<div>
														<button
															type="submit"
															onClick={this.onSubmit}
															className="roboto-condensed client-login m-2 px-5"
														>
															Reset Password
														</button>
													</div>
												</form>
											</div>
											<div className="col"></div>
										</div>
									</div>
								</div>
								<div className="col"></div>
							</div>
						</div>
					</div>
				);
			}
		}
	}
}
const mapStateToProps = state => {
	return {
		token: state.loginReducer.token
	};
};

export default connect(mapStateToProps, {})(ForgotPassword);
