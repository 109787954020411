import React, { Component } from "react";
import { connect } from "react-redux";
import { QuoteService } from "./../service";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

class ShowDetailQuote extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      search: "",
      quote: false,
      quote_data: false
    };
  }

  formatDate(date) {
    let formatted_date = new Date(date.replace(/-/g, "/"));
    let newMonth = parseInt(formatted_date.getMonth()) + 1;

    return (
      formatted_date.getFullYear() +
      "-" +
      newMonth +
      "-" +
      formatted_date.getDate()
    );
  }

  async componentDidMount() {
    let redirect = this;
    let { id } = this.props.match.params;

    let quote = await QuoteService.showSingleQuote(id);

    redirect.setState({ quote: quote });

    let quote_data = await QuoteService.showQuoteData(id);

    redirect.setState({ quote_data: quote_data });


    redirect.setState({ loading: false });
  }

  render() {
    if (!this.state.loading) {
      return (
        <div>
          <div
            className="content"
            style={{
              minHeight: 500
            }}
          >
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <strong className="card-title">Quotes Details</strong>
                  </div>
                  <div className="card-body">
                    <div className="card-content">
                      <div className="todo-list">
                        <div className="tdl-holder">
                          <div className="tdl-content">
                            <div className="row">
                              <div className="mt-4 mb-4 client-roboto-condensed container">
                                <div className="text-center">
                                  <h3>Quotation Details</h3>
                                </div>
                                <div className="row m-5">
                                  <div className="col-sm-3"></div>
                                  <div className="col-sm-6">
                                    <div className="client-config-details ml-5 mr-5 mb-5">
                                      <h5 className="client-config">
                                        <span>Quote Name:</span>
                                        <span>
                                          {
                                            this.state.quote.quote[0]
                                              .quotation_name
                                          }
                                        </span>
                                      </h5>
                                      <h5 className="client-config">
                                        <span>Quote Number:</span>
                                        <span>
                                          {this.state.quote.quote[0].id}
                                        </span>
                                      </h5>
                                      <h5 className="client-config">
                                        <span>Total Configurations:</span>
                                        <span>
                                          {
                                            this.state.quote.configurations
                                              .length
                                          }
                                        </span>
                                      </h5>
                                      <h5 className="client-config">
                                        <span>Quotation For:</span>
                                        <span>
                                          {
                                            this.state.quote.quote[0]
                                              .quotation_for
                                          }
                                        </span>
                                      </h5>
                                      <h5 className="client-config">
                                        <span>Created At:</span>
                                        <span>
                                          {this.formatDate(
                                            this.state.quote.quote[0].created_at
                                          )}
                                        </span>
                                      </h5>
                                    </div>
                                  </div>
                                  <div className="col-sm-3"></div>
                                </div>
                                <div className="row m-5">
                                  <div className="col-sm-3"></div>
                                  <div className="col-sm-6">
                                    <div className="client-config-details ml-5 mr-5 mb-5">
                                      <table className="table table-striped">
                                        <thead>
                                          <tr>
                                            <th>Status</th>
                                            <th>Updated Date</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {this.state.quote_data &&
                                          this.state.quote_data !== []
                                            ? this.state.quote_data.map(
                                                (data, i) => (
                                                  <tr>
                                                    <td>{data.status}</td>
                                                    <td>{data.updated_date}</td>
                                                  </tr>
                                                )
                                              )
                                            : null}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                  <div className="col-sm-3"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div
            className="content"
            style={{
              minHeight: 500
            }}
          >
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <strong className="card-title">Quote Details</strong>
                  </div>
                  <div className="card-body">
                    <div className="card-content">
                      <div className="todo-list">
                        <div className="tdl-holder">
                          <div className="tdl-content">
                            <div className="row">
                              <div className="col"></div>
                              <div className="col">
                                <div className="text-center">
                                  <Loader
                                    type="Rings"
                                    color="#00BFFF"
                                    height={100}
                                    width={100}
                                  />
                                  <div className="text-center client-roboto-condensed">
                                    LOADING...
                                  </div>
                                </div>
                              </div>
                              <div className="col"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
const mapStateToProps = state => {
  return {
    machine_parts: state.showMachinePartReducer.machine_parts,
    message: state.showMachinePartReducer.message
  };
};

export default connect(mapStateToProps, {})(ShowDetailQuote);
