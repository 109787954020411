const specificationActionTypes = {
  SHOW_SPECIFICATION_FOR_CATEGORY_REQUEST:
    "show.specification.for.category.request",
  SHOW_SPECIFICATION_FOR_CATEGORY_SUCCESS:
    "show.specification.for.category.success",
  SHOW_SPECIFICATION_FOR_CATEGORY_FALIURE:
    "show.specification.for.category.faliure",

  SHOW_SINGLE_SPECIFICATION_REQUEST: "show.single.specification.request",
  SHOW_SINGLE_SPECIFICATION_SUCCESS: "show.single.specification.success",
  SHOW_SINGLE_SPECIFICATION_FALIURE: "show.single.specification.faliure",

  ADD_SPECIFICATION_FOR_CATEGORY_REQUEST:
    "add.specification.for.category.request",
  ADD_SPECIFICATION_FOR_CATEGORY_SUCCESS:
    "add.specification.for.category.success",
  ADD_SPECIFICATION_FOR_CATEGORY_FALIURE:
    "add.specification.for.category.faliure",

  UPDATE_SPECIFICATION_FOR_CATEGORY_REQUEST:
    "update.specification.for.category.request",
  UPDATE_SPECIFICATION_FOR_CATEGORY_SUCCESS:
    "update.specification.for.category.success",
  UPDATE_SPECIFICATION_FOR_CATEGORY_FALIURE:
    "update.specification.for.category.faliure",

  DELETE_SPECIFICATION_FOR_CATEGORY_REQUEST:
    "DELETE.specification.for.category.request",
  DELETE_SPECIFICATION_FOR_CATEGORY_SUCCESS:
    "DELETE.specification.for.category.success",
  DELETE_SPECIFICATION_FOR_CATEGORY_FALIURE:
    "DELETE.specification.for.category.faliure",

  CLEAN_MESSAGE_REQUEST: "clean.message.request",
  CLEAN_MESSAGE_SUCCESS: "clean.message.success",
  CLEAN_MESSAGE_FALIURE: "clean.message.faliure"
};
export default specificationActionTypes;
