import { ClientOrderService } from "./service";
import { take, call, put } from "redux-saga/effects";
import clientOrderActionTypes from "./actionTypes";

function* showClientOrders(user) {
  while (true) {
    const { user } = yield take(clientOrderActionTypes.SHOW_ORDERS_REQUEST);

    const orders = yield call(ClientOrderService.showOrders, user);

    if (orders !== "" && orders !== null && orders !== [] && orders) {
      yield put({
        type: clientOrderActionTypes.SHOW_ORDERS_SUCCESS,
        message: "Getting all orders of user success",
        orders: orders
      });
    } else {
      yield put({
        type: clientOrderActionTypes.SHOW_ORDERS_FALIURE,
        message: "Getting all orders of user faliure"
      });
    }
  }
}

function* showSingleClientOrder(user, params) {
  while (true) {
    const { user, params, role } = yield take(
      clientOrderActionTypes.SHOW_SINGLE_ORDER_REQUEST
    );

    let order = [];
    if (role.toLowerCase().includes("support")) {
      order = yield call(ClientOrderService.showSingleOrderSales, user, params);
    } else {
      order = yield call(ClientOrderService.showSingleOrder, user, params);
    }

    if (order !== "" && order !== null && order !== [] && order) {
      yield put({
        type: clientOrderActionTypes.SHOW_SINGLE_ORDER_SUCCESS,
        message: "Getting specific order of user success",
        order: order
      });
    } else {
      yield put({
        type: clientOrderActionTypes.SHOW_SINGLE_ORDER_FALIURE,
        message: "Getting specific order of user faliure"
      });
    }
  }
}

function* createClientOrder(user, params) {
  while (true) {
    const { user, params } = yield take(
      clientOrderActionTypes.CREATE_ORDER_REQUEST
    );

    const { order } = yield call(ClientOrderService.createOrder, user, params);

    if (order !== "" && order !== null && order !== [] && order) {
      yield put({
        type: clientOrderActionTypes.CREATE_ORDER_SUCCESS,
        message: "Creating order for user success"
      });
    } else {
      yield put({
        type: clientOrderActionTypes.CREATE_ORDER_FALIURE,
        message: "Creating order for user faliure"
      });
    }
  }
}

export { showClientOrders, showSingleClientOrder, createClientOrder };
