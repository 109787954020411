import sequenceActionTypes from "./actionTypes";

function show_sequence_for_machine_category(params) {
  return {
    type: sequenceActionTypes.SHOW_SEQUENCE_FOR_MACHINE_CATEGORY_REQUEST,
    loading: true,
    params: params
  };
}

function add_or_edit_sequence_for_machine_category(params) {
  return {
    type: sequenceActionTypes.ADD_OR_EDIT_SEQUENCE_FOR_MACHINE_CATEGORY_REQUEST,
    loading: true,
    params: params
  };
}

function clean_message() {
  return {
    type: sequenceActionTypes.CLEAN_MESSAGE_REQUEST,
    loading: true
  };
}

function edit_sequence_for_machine_category(params) {
  return {
    type: sequenceActionTypes.EDIT_SEQUENCE_FOR_MACHINE_CATEGORY_REQUEST,
    loading: true,
    params: params
  };
}

export {
  show_sequence_for_machine_category,
  add_or_edit_sequence_for_machine_category,
  edit_sequence_for_machine_category,
  clean_message
};
