import React, { Component } from "react";
import { connect } from "react-redux";
import { UpdateService } from "./../service";
import { Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Switch } from "antd";

class ListUpdates extends Component {
    constructor(props) {
        super(props);
        this.state = {
            updates: false,
            loading: true
        };
        this.handleVisibleToggle = this.handleVisibleToggle.bind(this);
        this.toggleUpdateForAllUsers = this.toggleUpdateForAllUsers.bind(this);
        this.toggleUpdateOffForAllUsers = this.toggleUpdateOffForAllUsers.bind(this);
    }

    async handleVisibleToggle(i) {
        let updates = this.state.updates;
        const fd = new FormData();
        updates[i].is_visible = !updates[i].is_visible;

        fd.append("id", updates[i].id);
        fd.append("update_title", updates[i].update_title);
        fd.append("update_description", updates[i].update_description);
        fd.append("is_visible", updates[i].is_visible ? 1: 0);

        await UpdateService.editUpdate(fd);



        this.setState({ updates: updates })
    }

    async componentDidMount() {
        let updates = await UpdateService.showAllUpdates();
        await this.setState({ updates: updates })
        await this.setState({ loading: false })
    }

    async toggleUpdateForAllUsers(){
        await UpdateService.toggleUpdateForAllUsers();
    }


    async toggleUpdateOffForAllUsers(){
        await UpdateService.toggleUpdateOffForAllUsers();
    }

    //function to trigger delete of certain brand in the list
    // async onDelete(id) {
    // }

    render() {
        if (!this.state.loading) {
            return (
                <div>
                    <div
                        className="content"
                        style={{
                            minHeight: 500
                        }}
                    >
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-header">
                                        <strong className="card-title">Updates</strong>
                                    </div>
                                    <div className="card-body">
                                        <div className="card-content">
                                            <button onClick={() => this.toggleUpdateForAllUsers()} className="client-login px-3 my-2 mx-1">Toggle Updates For All Users</button>
                                            <button onClick={() => this.toggleUpdateOffForAllUsers()} className="client-login px-3 my-2 mx-1">Toggle Updates Off For All Users</button>
                                            <div className="todo-list">
                                                <div className="tdl-holder">
                                                    <div className="tdl-content">
                                                        <ul>
                                                            {this.state.updates
                                                                ? this.state.updates.map(
                                                                    (update, i) => (
                                                                        <li key={i}>
                                                                            <label
                                                                                style={{
                                                                                    color: "black"
                                                                                }}
                                                                            >
                                                                                <div className="row">
                                                                                    <div className="col-sm-5">
                                                                                        {
                                                                                            update.update_title
                                                                                        }
                                                                                    </div>
                                                                                    <div className="col-sm-4">
                                                                                        <Switch checked={update.is_visible} onChange={(e) =>
                                                                                            this.handleVisibleToggle(i)
                                                                                        } />
                                                                                    </div>
                                                                                    <div className="col-sm-3">
                                                                                        <Link
                                                                                            to={
                                                                                                "/edit-update/" +
                                                                                                update.id
                                                                                            }
                                                                                        >
                                                                                            <i className="fa text-dark fa-pencil"></i>
                                                                                        </Link>
                                                                                    </div>
                                                                                </div>
                                                                            </label>
                                                                        </li>
                                                                    )
                                                                )
                                                                : null}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    <div
                        className="content"
                        style={{
                            minHeight: 500
                        }}
                    >
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-header">
                                        <strong className="card-title">Updates</strong>
                                    </div>
                                    <div className="card-body">
                                        <div className="card-content">
                                            <button onClick={() => this.toggleUpdateForAllUsers()} className="client-login px-3 my-2 mx-1">Toggle Updates For All Users</button>
                                            <button onClick={() => this.toggleUpdateOffForAllUsers()} className="client-login px-3 my-2 mx-1">Toggle Updates Off For All Users</button>
                                            <div className="todo-list">
                                                <div className="tdl-holder">
                                                    <div className="tdl-content">
                                                        <div className="row">
                                                            <div className="col"></div>
                                                            <div className="col">
                                                                <div className="text-center">
                                                                    <Loader
                                                                        type="Rings"
                                                                        color="#00BFFF"
                                                                        height={100}
                                                                        width={100}
                                                                    />
                                                                    <div className="text-center client-roboto-condensed">
                                                                        LOADING...
																	</div>
                                                                </div>
                                                            </div>
                                                            <div className="col"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}
const mapStateToProps = state => {
    return {
        message: state.brandReducer.message,
        loading: state.brandReducer.loading,
        brands: state.brandReducer.brands
    };
};

export default connect(mapStateToProps, {})(ListUpdates);
