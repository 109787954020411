import React, { Component } from "react";
import "./../css/styles.css";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

class NoLoginFooter extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {}
	render() {
		return (
			<footer className="mt-5">
				<hr />
				<div className="container-fluid">
					<div className="container">
						<div className="row my-3">
							<div className="col">
								<footer className="page-footer font-small blue">
									<div className="text-black client-roboto-condensed client-footer-copyright text-center">
										© {new Date().getFullYear()} Copyright &nbsp;
										<a
											href="https://nextlevelvalue.com"
											className="footer-link roboto-condensed copyright-link"
										>
											Next Level Value
										</a>
										&nbsp;|&nbsp;
										<Link
											style={{
												fontSize: 13
											}}
											className="footer-link roboto-condensed"
											to="/disclaimer"
										>
											Disclaimer
										</Link>
										&nbsp;|&nbsp;
										<Link
											style={{
												fontSize: 13
											}}
											className="footer-link roboto-condensed"
											to="/privacy-statement"
										>
											Privacy Statement
										</Link>
									</div>
								</footer>
							</div>
						</div>
					</div>
				</div>
			</footer>
		);
	}
}

const mapStateToProps = state => {
	return {};
};

export default connect(mapStateToProps, {})(NoLoginFooter);
