import { LoginService } from "./service";
import { take, call, put } from "redux-saga/effects";
import loginActionTypes from "./actionTypes";

function* register() {
	while (true) {
		let { params } = yield take(loginActionTypes.REGISTER_REQUEST);

		let user = yield call(LoginService.register, params);

		if (user !== "" && user !== null && user !== [] && user) {
			yield put({
				type: loginActionTypes.REGISTER_SUCCESS,
				message: "Registration Success",
				user: user
			});
		} else {
			yield put({
				type: loginActionTypes.REGISTER_FALIURE,
				message: "Registration Faliure"
			});
		}
	}
}

function* registerAdmin() {
	while (true) {
		let { params } = yield take(loginActionTypes.REGISTER_ADMIN_REQUEST);

		let user = yield call(LoginService.registerAdmin, params);

		if (user !== "" && user !== null && user !== [] && user) {
			yield put({
				type: loginActionTypes.REGISTER_ADMIN_SUCCESS,
				message: "Registration Admin Success",
				user: user
			});
		} else {
			yield put({
				type: loginActionTypes.REGISTER_ADMIN_FALIURE,
				message: "Registration Admin Faliure"
			});
		}
	}
}

function* login(param) {
	while (true) {
		let { params } = yield take(loginActionTypes.LOGIN_REQUEST);

		let user = yield call(LoginService.login, params);

		if (user !== "" && user !== null && user !== [] && user) {
			yield put({
				type: loginActionTypes.LOGIN_SUCCESS,
				message: "Login Success",
				user: user,
				token: user.token_type + " " + user.access_token
			});
		} else {
			yield put({
				type: loginActionTypes.LOGIN_FALIURE,
				message: "Login Faliure"
			});
		}
	}
}

function* getLoginInfoWithToken() {
	while (true) {
		let { params } = yield take(
			loginActionTypes.GET_LOGIN_INFO_WITH_TOKEN_REQUEST
		);
		let user = yield call(LoginService.getLoginInfoWithToken, params);

		if (user !== "" && user !== null && user !== [] && user) {
			yield put({
				type: loginActionTypes.GET_LOGIN_INFO_WITH_TOKEN_SUCCESS,
				message: "Login Success",
				user: user,
				token: user.token_type + " " + user.access_token
			});
		} else {
			yield put({
				type: loginActionTypes.GET_LOGIN_INFO_WITH_TOKEN_FALIURE,
				message: "Login Faliure"
			});
		}
	}
}

function* logout() {
	while (true) {
		let { params } = yield take(loginActionTypes.LOGOUT_REQUEST);

		let flag = yield call(LoginService.logout, params);

		if (flag !== "" && flag !== null && flag !== [] && flag) {
			const adminOnly = localStorage.getItem("ADMIN_ONLY")

			if(adminOnly === 'true'){
				localStorage.removeItem("ADMIN_ONLY")
				window.location.replace(
					`${process.env.REACT_APP_CLIENT_URL}`,
				)
			}
			yield put({
				type: loginActionTypes.LOGOUT_SUCCESS,
				message: "Logout Success"
			});
		} else {
			yield put({
				type: loginActionTypes.LOGOUT_FALIURE,
				message: "Logout Failure"
			});
		}
	}
}

function* getUser() {
	while (true) {
		let { params } = yield take(loginActionTypes.GET_USER_REQUEST);

		let user = yield call(LoginService.getUser, params);
		if (user !== "" && user !== null && user !== [] && user) {
			yield put({
				type: loginActionTypes.GET_USER_SUCCESS,
				message: "Getting user data success",
				user: user,
				token: user.token_type + " " + user.access_token
			});
		} else {
			yield put({
				type: loginActionTypes.GET_USER_FALIURE,
				message: "Getting user data faliure"
			});
		}
	}
}

function* cleanLoginMessage() {
	while (true) {
		yield take(loginActionTypes.CLEAN_LOGIN_MESSAGE_REQUEST);

		yield put({ type: loginActionTypes.CLEAN_LOGIN_MESSAGE_SUCCESS });
	}
}

export {
	login,
	logout,
	register,
	registerAdmin,
	getUser,
	cleanLoginMessage,
	getLoginInfoWithToken
};
