import languageActionTypes from "./actionTypes";
const INITIAL_STATE = {
  message: "",
  loading: false,
  selectedLanguage: "en",
  selectedCountry: 'united kingdom'
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case languageActionTypes.LANGUAGE_CHANGE_REQUEST:
      return { ...state, loading: true };

    case languageActionTypes.LANGUAGE_CHANGE_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
        selectedLanguage: action.selectedLanguage,
        selectedCountry: action.selectedCountry
      };

    case languageActionTypes.LANGUAGE_CHANGE_FALIURE:
      return { ...state, loading: false, message: action.message };

    default:
      return state;
  }
}
