import React, { Component } from "react";
import { connect } from "react-redux";
import { show_company_rates } from "./../action";
import { CompanyRatesService } from "./../service";
import { Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { notification } from "antd";

class ListCompanyRates extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.onDelete = this.onDelete.bind(this);
	}

	async componentDidMount() {
		//get all the company rates from the system [redux]
		await this.props.show_company_rates();
	}

	//function to trigger when the delete button of a certain company rate is pressed.
	async onDelete(id) {
		let confirmation = window.confirm("Are you sure?");
		this.setState({ loading: true });

		if (confirmation) {
			let data = await CompanyRatesService.deleteCompanyRates(id);
			await this.props.show_company_rates();

			if (data.message.includes("successful")) {
				notification.success({
					message: "Success!",
					description: data.message
				});
			} else {
				notification.error({
					message: "Error!",
					description: data.message
				});
			}
		}
		this.setState({ loading: false });
	}

	render() {
		if (!this.props.loading) {
			return (
				<div>
					<div
						className="content"
						style={{
							minHeight: 500
						}}
					>
						<div className="row">
							<div className="col">
								<div className="card">
									<div className="card-header">
										<strong className="card-title">
											Company Rates
										</strong>
									</div>
									<div className="card-body">
										<div className="card-content">
											<div className="todo-list">
												<div className="tdl-holder">
													<div className="tdl-content">
														<ul>
															{this.props.company_rates
																? this.props.company_rates.map(
																		(company_rate, i) => (
																			<li key={i}>
																				<label
																					style={{
																						color: "black"
																					}}
																				>
																					<div className="row">
																						<div className="col-sm-9">
																							{
																								company_rate.rate_name
																							}
																						</div>
																						<div className="col-sm-3">
																							{/* <Link
																								onClick={e =>
																									this.onDelete(company_rate.id)
																								}
																							>
																								<i className="fa text-dark fa-times"></i>
																							</Link> */}
																							<Link
																								to={
																									"/edit-company-rate/" +
																									company_rate.id
																								}
																							>
																								<i className="fa text-dark fa-pencil"></i>
																							</Link>
																						</div>
																					</div>
																				</label>
																			</li>
																		)
																  )
																: null}
														</ul>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div>
					<div
						className="content"
						style={{
							minHeight: 500
						}}
					>
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-header">
										<strong className="card-title">
											Company Rates
										</strong>
									</div>
									<div className="card-body">
										<div className="card-content">
											<div className="todo-list">
												<div className="tdl-holder">
													<div className="tdl-content">
														<div className="row">
															<div className="col"></div>
															<div className="col">
																<div className="text-center">
																	<Loader
																		type="Rings"
																		color="#00BFFF"
																		height={100}
																		width={100}
																	/>
																	<div className="text-center client-roboto-condensed">
																		LOADING...
																	</div>
																</div>
															</div>
															<div className="col"></div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}
}
const mapStateToProps = state => {
	return {
		message: state.companyRateReducer.message,
		loading: state.companyRateReducer.loading,
		company_rates: state.companyRateReducer.company_rates
			? state.companyRateReducer.company_rates
			: []
	};
};

export default connect(mapStateToProps, { show_company_rates })(
	ListCompanyRates
);
