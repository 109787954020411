const brandActionTypes = {
  SHOW_BRAND_REQUEST: "show.brand.request",
  SHOW_BRAND_SUCCESS: "show.brand.success",
  SHOW_BRAND_FALIURE: "show.brand.faliure",

  ADD_BRAND_REQUEST: "add.brand.request",
  ADD_BRAND_SUCCESS: "add.brand.success",
  ADD_BRAND_FALIURE: "add.brand.faliure",

  EDIT_BRAND_REQUEST: "edit.brand.request",
  EDIT_BRAND_SUCCESS: "edit.brand.success",
  EDIT_BRAND_FALIURE: "edit.brand.faliure",

  DELETE_BRAND_REQUEST: "delete.brand.request",
  DELETE_BRAND_SUCCESS: "delete.brand.success",
  DELETE_BRAND_FALIURE: "delete.brand.faliure",

  CLEAN_MESSAGE_REQUEST: "clean.message.request",
  CLEAN_MESSAGE_SUCCESS: "clean.message.success",
  CLEAN_MESSAGE_FALIURE: "clean.message.faliure"
};
export default brandActionTypes;
