import React, { Component } from "react";
import { connect } from "react-redux";
import { add_specification_for_machine_category } from "./../action";
import { Redirect } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import DropdownTreeSelect from "react-dropdown-tree-select";
import { MachineCategoryService } from "./../../machine_category/service";
import { SpecificationService } from "./../service";

class AddSpecification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      tree_category: "",
      machine_category_id: false,
      existing_specifications: false,
      specifications: [
        {
          specification_name: "",
          specification_value: ""
        }
      ]
    };
    this.onSpecificationDelete = this.onSpecificationDelete.bind(this);
    this.handleSpecificationChange = this.handleSpecificationChange.bind(this);
    this.handleAddSpecification = this.handleAddSpecification.bind(this);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.onTextChange = this.onTextChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSpecificationDelete(e, i) {
    e.preventDefault();
    let new_quantity_plus = this.state.specifications;
    new_quantity_plus.splice(i, 1);

    this.setState({ specifications: new_quantity_plus });
    this.forceUpdate();
  }

  handleSpecificationChange(event, name, i) {
    switch (name) {
      case "specification_name":
        let new_specification_name = this.state.specifications;
        new_specification_name[i].specification_name = event;
        this.setState({ specifications: new_specification_name });
        break;

      case "specification_value":
        let new_specification_value = this.state.specifications;
        new_specification_value[i].specification_value = event;
        this.setState({ specifications: new_specification_value });
        break;

      default:
        break;
    }
  }

  handleAddSpecification(e) {
    e.preventDefault();
    this.setState({
      specifications: [
        ...this.state.specifications,
        ...[
          {
            specification_name: "",
            specification_value: ""
          }
        ]
      ]
    });
  }

  onTextChange(name, value) {
    let redirect = this;
    switch (name) {
      case "specification_name":
        redirect.setState({ specification_name: value });
        break;

      case "specification_value":
        redirect.setState({ specification_value: value });
        break;

      default:
        break;
    }
  }

  async onCategoryChange(value) {
    await this.setState({ loading: true });

    let that = this;

    that.setState({ machine_category_id: value.key });

    let categories = await MachineCategoryService.getTreeSelectData(
      value.key
    );

    that.setState({ tree_category: categories });

    let existing_specifications = await SpecificationService.showAllSpecificationForMachineCateogry(
      this.state.machine_category_id
    );

    await this.setState({ existing_specifications: existing_specifications });

    await this.setState({ loading: false });
  }

  onSubmit(e) {
    let redirect = this;
    e.preventDefault();

    if (this.validator.allValid()) {
      let params = {
        machine_category_id: redirect.state.machine_category_id,
        specifications: redirect.state.specifications
      };


      this.props.add_specification_for_machine_category(params);
    } else {
      this.validator.showMessages();
    }
  }

  async componentDidMount() {
    let tree_category = await MachineCategoryService.getTreeSelectData();

    this.setState({ tree_category: tree_category });
  }

  render() {
    if (this.props.message.includes("Adding Specification Data Success")) {
      return <Redirect to="/add-specification-category" />;
    } else {
      if (this.props.loading || this.state.loading) {
        return (
          <div>
            <div
              className="content"
              style={{
                minHeight: 500
              }}
            >
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <strong className="card-title">Add Specification</strong>
                    </div>
                    <div className="card-body">
                      <div className="card-content">
                        <div className="todo-list">
                          <div className="tdl-holder">
                            <div className="tdl-content">
                              <div className="row">
                                <div className="col"></div>
                                <div className="col">
                                  <div className="text-center">
                                    <Loader
                                      type="Rings"
                                      color="#00BFFF"
                                      height={100}
                                      width={100}
                                    />
                                    <div className="text-center client-roboto-condensed">
                                      LOADING...
                                    </div>
                                  </div>
                                </div>
                                <div className="col"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div>
            <div
              className="content"
              style={{
                minHeight: 500
              }}
            >
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <strong className="card-title">Add Specification</strong>
                    </div>
                    <div className="card-body">
                      <div className="card-content">
                        <form>
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="form-group text-left">
                                <label className="client-roboto-condensed">
                                  Select Category
                                </label>

                                {this.state.tree_category ? (
                                  <DropdownTreeSelect
                                    data={this.state.tree_category}
                                    onChange={e => this.onCategoryChange(e)}
                                    radioSelect
                                    className="mdl-demo"
                                  />
                                ) : null}
                                <div
                                  className="mt-4"
                                  style={{
                                    color: "red"
                                  }}
                                >
                                  {
                                    this.validator.message(
                                      "configuration_category",
                                      this.state.machine_category_id,
                                      "required|numeric"
                                    )
                                  }
                                </div>
                              </div>
                            </div>
                          </div>

                          {this.state.existing_specifications &&
                            this.state.existing_specifications !== [] ? (
                              <div className="row my-4">
                                <div className="col-sm-12">
                                  <div className="form-group">
                                    <label
                                      htmlFor="country"
                                      className="text-center control-label mb-1"
                                    >
                                      <strong>Used in machine category</strong>
                                    </label>
                                    <div className="row">
                                      {this.state.existing_specifications.map(
                                        (specification, i) => (
                                          <div className="col-6 mt-2 mb-2">
                                            {specification.specification_name +
                                              ": " +
                                              specification.specification_value}
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          <div className="row my-3 mx-1">
                            {this.state.machine_category_id === false ||
                              this.state.machine_category_id === "" ||
                              this.state.machine_category_id === null ||
                              this.state.machine_category_id === undefined ? (
                                <button disabled className="btn btn-secondary">
                                  Add Specification
                                </button>
                              ) : (
                                <button
                                  className="btn btn-secondary"
                                  onClick={e => this.handleAddSpecification(e)}
                                >
                                  Add Specification
                                </button>
                              )}
                          </div>
                          {this.state.machine_category_id === false ||
                            this.state.machine_category_id === "" ||
                            this.state.machine_category_id === null ||
                            this.state.machine_category_id === undefined
                            ? null
                            : this.state.specifications.length > 0
                              ? this.state.specifications.map(
                                (specification, i) => (
                                  <div className="row">
                                    <div className="col-sm-5">
                                      <div className="form-group">
                                        <label
                                          htmlFor="specification_name"
                                          className="control-label mb-1"
                                        >
                                          Specification Name [{i + 1}]
                                        </label>
                                        <input
                                          id={"specification_name" + (i + 1)}
                                          name={"specification_name" + (i + 1)}
                                          type="text"
                                          className="form-control"
                                          aria-required="true"
                                          onChange={e =>
                                            this.handleSpecificationChange(
                                              e.target.value,
                                              "specification_name",
                                              i
                                            )
                                          }
                                          value={
                                            specification.specification_name
                                          }
                                        />
                                        <div
                                          className="mt-4"
                                          style={{
                                            color: "red"
                                          }}
                                        >
                                          {this.validator.message(
                                            "specification_name ",
                                            specification.specification_name,
                                            "required|string"
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-sm-5">
                                      <div className="form-group">
                                        <label
                                          htmlFor="specification_name"
                                          className="control-label mb-1"
                                        >
                                          Specification Value [{i + 1}]
                                        </label>
                                        <input
                                          id={"specification_value" + (i + 1)}
                                          name={"specification_value" + (i + 1)}
                                          type="text"
                                          className="form-control"
                                          aria-required="true"
                                          onChange={e =>
                                            this.handleSpecificationChange(
                                              e.target.value,
                                              "specification_value",
                                              i
                                            )
                                          }
                                          value={
                                            specification.specification_value
                                          }
                                        />
                                        <div
                                          className="mt-4"
                                          style={{
                                            color: "red"
                                          }}
                                        >
                                          {this.validator.message(
                                            "specification_value ",
                                            specification.specification_value,
                                            "required|string"
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-sm-2 p-3">
                                      <button
                                        style={{
                                          borderColor: "green"
                                        }}
                                        className="plus-minus"
                                        onClick={e =>
                                          this.onSpecificationDelete(e, i)
                                        }
                                      >
                                        X
                                      </button>
                                    </div>
                                  </div>
                                )
                              )
                              : null}
                          <div>
                            <button
                              onClick={this.onSubmit}
                              id="payment-button"
                              type="submit"
                              className="btn btn-secondary btn-block"
                            >
                              <i className="fa fa-truck"></i>&nbsp;
                              <span id="payment-button-amount">
                                Store Specifications
                              </span>
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
  }
}

const mapStateToProps = state => {
  return {
    message: state.specificationReducer.message,
    loading: state.specificationReducer.loading
  };
};
export default connect(mapStateToProps, {
  add_specification_for_machine_category
})(AddSpecification);
