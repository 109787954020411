import React, { Component } from "react";
import { connect } from "react-redux";
import { GenericQuestionService } from "../service";
import { Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

class ListGenericQuestions extends Component {
	constructor(props) {
		super(props);
		this.state = {
			generic_questions: false
		};
		this.onDelete = this.onDelete.bind(this);
	}

	async componentDidMount() {
		let generic_questions = await GenericQuestionService.showAllGenericQuestions();

		await this.setState({ generic_questions: generic_questions });
	}

	//function to trigger delete of certain brand in the list
	async onDelete(id) {
		let confirmation = window.confirm("Are you sure?");

		if (confirmation) {
			await GenericQuestionService.deleteGenericQuestion(id);
			// show all brands [redux]
			let generic_questions = await GenericQuestionService.showAllGenericQuestions();
			await this.setState({ generic_questions: generic_questions });
		}
	}

	render() {
		if (!this.props.loading) {
			return (
				<div>
					<div
						className="content"
						style={{
							minHeight: 500
						}}
					>
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-header">
										<strong className="card-title">Generic Reasons</strong>
									</div>
									<div className="card-body">
										<div className="card-content">
											<div className="todo-list">
												<div className="tdl-holder">
													<div className="tdl-content">
														<ul>
															{this.state.generic_questions
																? this.state.generic_questions.map(
																		(generic_question, i) => (
																			<li key={i}>
																				<label
																					style={{
																						color: "black"
																					}}
																				>
																					<div className="row">
																						<div className="col-sm-9">
																							{
																								generic_question.generic_question
																							}
																						</div>
																						<div className="col-sm-3">
																							<Link
																								onClick={e =>
																									this.onDelete(
																										generic_question.generic_id
																									)
																								}
																							>
																								<i className="fa text-dark fa-times"></i>
																							</Link>
																							<Link
																								to={
																									"/edit-generic-question/" +
																									generic_question.generic_id
																								}
																							>
																								<i className="fa text-dark fa-pencil"></i>
																							</Link>
																						</div>
																					</div>
																				</label>
																			</li>
																		)
																  )
																: null}
														</ul>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div>
					<div
						className="content"
						style={{
							minHeight: 500
						}}
					>
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-header">
										<strong className="card-title">Generic Reasons</strong>
									</div>
									<div className="card-body">
										<div className="card-content">
											<div className="todo-list">
												<div className="tdl-holder">
													<div className="tdl-content">
														<div className="row">
															<div className="col"></div>
															<div className="col">
																<div className="text-center">
																	<Loader
																		type="Rings"
																		color="#00BFFF"
																		height={100}
																		width={100}
																	/>
																	<div className="text-center client-roboto-condensed">
																		LOADING...
																	</div>
																</div>
															</div>
															<div className="col"></div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}
}
const mapStateToProps = state => {
	return {
	};
};

export default connect(mapStateToProps, {})(ListGenericQuestions);
