import React, { Component } from "react";
import DropdownTreeSelect from "react-dropdown-tree-select";
import "react-dropdown-tree-select/dist/styles.css";
import { MachineCategoryService } from "./../service";
import { edit_machine_categories } from "./../action";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { YearService } from "./../../year/service";
import SimpleReactValidator from "simple-react-validator";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import config from "./../../../config/config";
import { CountryService } from "./../../country/service";
import { Api } from "../../../config/api";
import Swal from "sweetalert2";

class UpdateMachineCategoryWithPrice extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			machine_category: [],
			machine_category_list: [],
			selected_machine: [],
			machine_category_model_number: "",
			category_name: "",
			category_level: "",
			category_id: "",
			parent_category: "",
			price: 0,
			cost_price: 0,
			brands: [],
			brand: false,
			category_image: null,
			description: "",
			title_name: "",
			machine_category_image: false,
			category_banner_image: null,
			machine_category_banner_image: false,
			years: [],
			year: "",
			countries: [],
			country: ""
		};
		this.validator = new SimpleReactValidator({ autoForceUpdate: this });
		this.onTextChange = this.onTextChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.UncheckAll = this.UncheckAll.bind(this);
		this.handleFileChange = this.handleFileChange.bind(this);
		this.onYearChange = this.onYearChange.bind(this);
		this.onCountryChange = this.onCountryChange.bind(this);
		this.removeImage = this.removeImage.bind(this);
		this.removeBannerImage = this.removeBannerImage.bind(this);
	}

	async removeImage(value) {
		let result1 = await Swal.fire({
			title: "Are you sure?",
			text: "Do you want delete this image?",
			icon: "info",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes!"
		}).then(result => {
			return result;
		});

		if (result1.value) {
			this.setState({ loading: true });
			let result = await Api.get(
				"machine-category/removeMachineCategoryImage/" + value + "/model"
			)
				.then(function(response) {
					return true;
				})
				.catch(function(error) {
					return false;
				});

			if (result) {
				let { id } = this.props.match.params;
				let { year_count } = this.props.match.params;
				let { cost_year_count } = this.props.match.params;
				let { year_id } = this.props.match.params;
				let { country_id } = this.props.match.params;

				let redirect = this;

				let countries = await CountryService.showCountries();

				await redirect.setState({ countries: countries });

				let years = await YearService.showYear();

				redirect.setState({ years: years });

				let brands = await MachineCategoryService.getBrands();

				redirect.setState({ brands: brands });

				let data = await MachineCategoryService.showSingleMachineCategoryWithSpecificPrice(
					id,
					year_count,
					cost_year_count,
					year_id,
					country_id
				);

				await redirect.setState({ machine_category: data[0] });
				redirect.setState({
					machine_category_model_number:
						data[0].machine_category_model_number
				});
				redirect.setState({ category_name: data[0].machine_category });
				redirect.setState({ parent_category: data[0].parent_category });
				redirect.setState({ category_level: data[0].category_level });
				redirect.setState({ is_selectable: data[0].is_selectable });
				redirect.setState({ brand: data[0].brand_id });
				redirect.setState({ country: data[0].country_id });
				redirect.setState({ year: data[0].yearly_price_id });
				redirect.setState({
					title_name: data[0].title_name
				});
				redirect.setState({
					description: data[0].description
				});

				if (data[0].price !== null && data[0].price) {
					redirect.setState({ price: data[0].price });
				}
				if (data[0].cost_price !== null && data[0].cost_price) {
					redirect.setState({ cost_price: data[0].cost_price });
				}
				redirect.setState({
					machine_category_image:
						data[0]?.machine_category_images.find(
							item => item?.type === "model"
						)?.path || null
				});
				redirect.setState({
					machine_category_banner_image:
						data[0]?.machine_category_images.find(
							item => item?.type === "banner"
						)?.path || null
				});

				let data2 = await MachineCategoryService.getTreeSelectData(
					redirect.state.machine_category.parent_category
				);
				redirect.setState({ machine_category_list: data2 });
			}
			this.setState({ loading: false });
		}
	}

	async removeBannerImage(value) {
		let result1 = await Swal.fire({
			title: "Are you sure?",
			text: "Do you want delete this image?",
			icon: "info",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes!"
		}).then(result => {
			return result;
		});

		if (result1.value) {
			this.setState({ loading: true });
			let result = await Api.get(
				"machine-category/removeMachineCategoryImage/" + value + "/banner"
			)
				.then(function(response) {
					return true;
				})
				.catch(function(error) {
					return false;
				});

			if (result) {
				let { id } = this.props.match.params;
				let { year_count } = this.props.match.params;
				let { cost_year_count } = this.props.match.params;
				let { year_id } = this.props.match.params;
				let { country_id } = this.props.match.params;

				let redirect = this;

				let countries = await CountryService.showCountries();

				await redirect.setState({ countries: countries });

				let years = await YearService.showYear();

				redirect.setState({ years: years });

				let brands = await MachineCategoryService.getBrands();

				redirect.setState({ brands: brands });

				let data = await MachineCategoryService.showSingleMachineCategoryWithSpecificPrice(
					id,
					year_count,
					cost_year_count,
					year_id,
					country_id
				);

				await redirect.setState({ machine_category: data[0] });
				redirect.setState({
					machine_category_model_number:
						data[0].machine_category_model_number
				});
				redirect.setState({ category_name: data[0].machine_category });
				redirect.setState({ parent_category: data[0].parent_category });
				redirect.setState({ category_level: data[0].category_level });
				redirect.setState({ is_selectable: data[0].is_selectable });
				redirect.setState({ brand: data[0].brand_id });
				redirect.setState({ country: data[0].country_id });
				redirect.setState({ year: data[0].yearly_price_id });
				redirect.setState({
					title_name: data[0].title_name
				});
				redirect.setState({
					description: data[0].description
				});

				if (data[0].price !== null && data[0].price) {
					redirect.setState({ price: data[0].price });
				}
				if (data[0].cost_price !== null && data[0].cost_price) {
					redirect.setState({ cost_price: data[0].cost_price });
				}
				redirect.setState({
					machine_category_image:
						data[0]?.machine_category_images.find(
							item => item?.type === "model"
						)?.path || null
				});
				redirect.setState({
					machine_category_banner_image:
						data[0]?.machine_category_images.find(
							item => item?.type === "banner"
						)?.path || null
				});

				let data2 = await MachineCategoryService.getTreeSelectData(
					redirect.state.machine_category.parent_category
				);
				redirect.setState({ machine_category_list: data2 });
			}
			this.setState({ loading: false });
		}
	}

	onSelectChange(name, value) {
		let that = this;
		switch (name) {
			case "brand":
				that.setState({ brand: value });
				break;

			case "is_selectable":
				that.setState({ is_selectable: value });
				break;

			default:
				break;
		}
	}

	onCountryChange(value) {
		this.setState({ country: value });
	}

	onYearChange(value) {
		this.setState({ year: value });
	}

	handleFileChange(event, key) {
		switch (key) {
			case "category_banner_image":
				this.setState({ category_banner_image: event.target.files[0] });
				break;

			case "category_image":
				this.setState({ category_image: event.target.files[0] });
				break;

			default:
				break;
		}
	}
	async UncheckAll(e) {
		e.preventDefault();
		let redirect = this;
		let data = await MachineCategoryService.getTreeSelectData();
		redirect.setState({ parent_category: 0 });
		redirect.setState({ category_level: 1 });
		redirect.setState({ machine_category_list: data });
	}

	async onTextChange(name, value, value1) {
		let redirect = this;
		switch (name) {
			case "machine_category_model_number":
				redirect.setState({ machine_category_model_number: value });
				break;

			case "category_name":
				redirect.setState({ category_name: value });
				break;

			case "title_name":
				redirect.setState({ title_name: value });
				break;

			case "description":
				redirect.setState({ description: value });
				break;

			case "parent_category":
				redirect.setState({ parent_category: value });
				redirect.setState({ category_level: value1 + 1 });
				let categories = await MachineCategoryService.getTreeSelectData(
					value
				);
				redirect.setState({ machine_category_list: categories });

				break;

			case "price":
				redirect.setState({ price: value });
				break;

			case "cost_price":
				redirect.setState({ cost_price: value });
				break;

			case "country":
				redirect.setState({ country: value });
				break;

			default:
				break;
		}
	}

	onSubmit(e) {
		e.preventDefault();

		if (this.validator.allValid()) {
			const fd = new FormData();

			fd.append("id", this.props.match.params.id);
			fd.append(
				"machine_category_model_number",
				this.state.machine_category_model_number
			);
			fd.append("machine_category", this.state.category_name);
			fd.append("parent_category", this.state.parent_category);
			fd.append("category_level", this.state.category_level);
			fd.append("brand_id", this.state.brand);
			fd.append("price", this.state.price);
			fd.append("cost_price", this.state.cost_price);
			fd.append("year_id", this.state.year);
			fd.append("is_selectable", parseInt(this.state.is_selectable));
			fd.append("country_id", parseInt(this.state.country));
			fd.append("description", this.state.description);
			fd.append("title_name", this.state.title_name);

			if (this.state.category_image !== null) {
				fd.append("category_image", this.state.category_image);
			}

			if (this.state.category_banner_image !== null) {
				fd.append(
					"category_banner_image",
					this.state.category_banner_image
				);
			}

			this.props.edit_machine_categories(fd);
		} else {
			this.validator.showMessages();
		}
	}

	async componentDidMount() {
		let { id } = this.props.match.params;
		let { year_count } = this.props.match.params;
		let { cost_year_count } = this.props.match.params;
		let { year_id } = this.props.match.params;
		let { country_id } = this.props.match.params;

		let redirect = this;

		let countries = await CountryService.showCountries();

		await redirect.setState({ countries: countries });

		let years = await YearService.showYear();

		redirect.setState({ years: years });

		let brands = await MachineCategoryService.getBrands();

		redirect.setState({ brands: brands });

		let data = await MachineCategoryService.showSingleMachineCategoryWithSpecificPrice(
			id,
			year_count,
			cost_year_count,
			year_id,
			country_id
		);

		await redirect.setState({ machine_category: data[0] });
		redirect.setState({
			machine_category_model_number: data[0].machine_category_model_number
		});
		redirect.setState({ category_name: data[0].machine_category });
		redirect.setState({ parent_category: data[0].parent_category });
		redirect.setState({ category_level: data[0].category_level });
		redirect.setState({ is_selectable: data[0].is_selectable });
		redirect.setState({ brand: data[0].brand_id });
		redirect.setState({ country: data[0].country_id });
		redirect.setState({ year: data[0].yearly_price_id });

		redirect.setState({ title_name: data[0].title_name });
		redirect.setState({ description: data[0].description });

		if (data[0].price !== null && data[0].price) {
			redirect.setState({ price: data[0].price });
		}
		if (data[0].cost_price !== null && data[0].cost_price) {
			redirect.setState({ cost_price: data[0].cost_price });
		}
		redirect.setState({
			machine_category_image:
				data[0]?.machine_category_images?.find(
					item => item?.type === "model"
				)?.path || null
		});
		redirect.setState({
			machine_category_banner_image:
				data[0]?.machine_category_images?.find(
					item => item?.type === "banner"
				)?.path || null
		});

		let data2 = await MachineCategoryService.getTreeSelectData(
			redirect.state.machine_category.parent_category
		);
		redirect.setState({ machine_category_list: data2 });

		redirect.setState({ loading: false });
	}

	render() {
		if (this.props.message.includes("Editing Machine Category Success")) {
			return <Redirect to="/machine-category" />;
		} else {
			if (this.props.loading || this.state.loading) {
				return (
					<div>
						<div
							className="content"
							style={{
								minHeight: 500
							}}
						>
							<div className="row">
								<div className="col-sm-12">
									<div className="card">
										<div className="card-header">
											<strong className="card-title">
												Machine Categories
											</strong>
										</div>
										<div className="card-body">
											<div className="card-content">
												<div className="todo-list">
													<div className="tdl-holder">
														<div className="tdl-content">
															<div className="row">
																<div className="col"></div>
																<div className="col">
																	<div className="text-center">
																		<Loader
																			type="Rings"
																			color="#00BFFF"
																			height={100}
																			width={100}
																		/>
																		<div className="text-center client-roboto-condensed">
																			LOADING...
																		</div>
																	</div>
																</div>
																<div className="col"></div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				);
			} else {
				return (
					<div>
						<div
							className="content"
							style={{
								minHeight: 500
							}}
						>
							<div className="row">
								<div className="col-sm-12">
									<div className="card">
										<div className="card-header">
											<strong className="card-title">
												Machine Categories
											</strong>
										</div>
										<div className="card-body">
											<form>
												<div className="form-group">
													<label
														htmlFor="machine_category_model_number"
														className="control-label mb-1"
													>
														Machine Category Model Number
													</label>
													<input
														id="machine_category_model_number"
														name="machine_category_model_number"
														type="text"
														className="form-control"
														aria-required="true"
														onChange={e =>
															this.onTextChange(
																"machine_category_model_number",
																e.target.value
															)
														}
														value={
															this.state
																.machine_category_model_number
														}
													/>
													<div
														className="mt-4"
														style={{
															color: "red"
														}}
													>
														{this.validator.message(
															"machine_category_model_number",
															this.state
																.machine_category_model_number,
															"string"
														)}
													</div>
												</div>

												<div className="form-group">
													<label
														htmlFor="category_name"
														className="control-label mb-1"
													>
														Machine Category Name
													</label>
													<input
														id="category_name"
														name="category_name"
														type="text"
														className="form-control"
														aria-required="true"
														onChange={e =>
															this.onTextChange(
																"category_name",
																e.target.value
															)
														}
														value={this.state.category_name}
													/>
													<div
														className="mt-4"
														style={{
															color: "red"
														}}
													>
														{this.validator.message(
															"category_name",
															this.state.category_name,
															"required|string"
														)}
													</div>
												</div>

												<div className="form-group">
													<label
														htmlFor="description"
														className="control-label mb-1"
													>
														Machine Category Description
													</label>
													<input
														id="description"
														name="description"
														type="text"
														className="form-control"
														onChange={e =>
															this.onTextChange(
																"description",
																e.target.value
															)
														}
														value={
															this.state.description !== null
																? this.state.description
																: ""
														}
														aria-required="true"
													/>
												</div>

												<div className="form-group">
													<label
														htmlFor="parent_category"
														className="control-label mb-1"
													>
														Parent Category
													</label>
													<DropdownTreeSelect
														data={
															this.state.machine_category_list
														}
														radioSelect
														onChange={e =>
															this.onTextChange(
																"parent_category",
																e.key,
																e.category_level
															)
														}
													/>
												</div>

												<div className="form-group">
													<label
														htmlFor="category_name"
														className="control-label mb-1"
													>
														Machine Category Selectable
													</label>
													<select
														className="custom-select"
														value={this.state.is_selectable}
														onChange={e =>
															this.onSelectChange(
																"is_selectable",
																e.target.value
															)
														}
													>
														<option value={0}>No</option>
														<option value={1}>Yes</option>
													</select>
													<div
														className="mt-4"
														style={{
															color: "red"
														}}
													>
														{this.validator.message(
															"is_selectable",
															this.state.is_selectable,
															"required"
														)}
													</div>
												</div>

												<>
													{this.state.machine_category_image ? (
														<div className="form-group">
															<label
																htmlFor="parent_category"
																className="control-label mb-1"
															>
																Current Image
															</label>
															<br />

															<div className="image-card my-4">
																<div className="imagewrap">
																	<div className="thumbnail">
																		<img
																			loading="lazy"
																			alt="brand"
																			src={
																				config.APP_URL +
																				"/api/storage/" +
																				this.state
																					.machine_category_image
																			}
																		/>
																	</div>
																	<button
																		onClick={() =>
																			this.removeImage(
																				this.props.match
																					.params.id
																			)
																		}
																		type="button"
																		className="button1 ig-btn"
																	>
																		<i className="fa fa-times fa-2x"></i>
																	</button>
																</div>
															</div>
															{/* <img
															alt="machine_category"
															src={
																config.APP_URL +
																"/api/storage/" +
																this.state
																	.machine_category_image
															}
														/> */}
														</div>
													) : null}
													<div className="form-group">
														{/* <div className="file-input"> */}
														{!this.state
															.machine_category_image ? (
															<>
																<label
																	htmlFor="file-input"
																	className="control-label mb-1"
																>
																	Machine Category Image
																</label>
																<br />
															</>
														) : null}
														<input
															type="file"
															// id="file-input"
															// className="file-input__input"
															name="category_image"
															onChange={e =>
																this.handleFileChange(
																	e,
																	"category_image"
																)
															}
															accept="image/jpg, image/jpeg, image/png"
														/>
														{/* <label
															className="file-input__label"
															htmlFor="file-input"
														>
															<svg
																aria-hidden="true"
																focusable="false"
																data-prefix="fas"
																data-icon="upload"
																className="svg-inline--fa fa-upload fa-w-16"
																role="img"
																xmlns="http://www.w3.org/2000/svg"
																viewBox="0 0 512 512"
															>
																<path
																	fill="currentColor"
																	d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"
																></path>
															</svg>
															<span>Machine Category Image</span>
														</label>
													</div> */}
													</div>
												</>

												<>
													{this.state
														.machine_category_banner_image ? (
														<div className="form-group">
															<label
																htmlFor="parent_category"
																className="control-label mb-1"
															>
																Current Category Banner Image
															</label>
															<br />

															<div className="image-card my-4">
																<div className="imagewrap">
																	<div className="thumbnail">
																		<img
																			loading="lazy"
																			alt="brand"
																			src={
																				config.APP_URL +
																				"/api/storage/" +
																				this.state
																					.machine_category_banner_image
																			}
																		/>
																	</div>
																	<button
																		onClick={() =>
																			this.removeBannerImage(
																				this.props.match
																					.params.id
																			)
																		}
																		type="button"
																		className="button1 ig-btn"
																	>
																		<i className="fa fa-times fa-2x"></i>
																	</button>
																</div>
															</div>
															{/* <img
															alt="machine_category"
															src={
																config.APP_URL +
																"/api/storage/" +
																this.state
																	.machine_category_image
															}
														/> */}
														</div>
													) : null}

													<div className="form-group">
														{/* <div className="file-input"> */}

														{!this.state
															.machine_category_banner_image ? (
															<>
																<label
																	htmlFor="file-input"
																	className="control-label mb-1"
																>
																	Machine Category Banner Image
																</label>
																<br />
															</>
														) : null}

														<input
															type="file"
															// id="file-input"
															// className="file-input__input"
															name="category_banner_image"
															onChange={e =>
																this.handleFileChange(
																	e,
																	"category_banner_image"
																)
															}
															accept="image/jpg, image/jpeg, image/png"
														/>
														{/* <label
															className="file-input__label"
															htmlFor="file-input"
														>
															<svg
																aria-hidden="true"
																focusable="false"
																data-prefix="fas"
																data-icon="upload"
																className="svg-inline--fa fa-upload fa-w-16"
																role="img"
																xmlns="http://www.w3.org/2000/svg"
																viewBox="0 0 512 512"
															>
																<path
																	fill="currentColor"
																	d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"
																></path>
															</svg>
															<span>Machine Category Image</span>
														</label>
													</div> */}
													</div>
												</>

												<div className="form-group">
													<label
														htmlFor="title_name"
														className="control-label mb-1"
													>
														Machine Category Title Name
													</label>
													<input
														id="title_name"
														name="title_name"
														type="text"
														className="form-control"
														onChange={e =>
															this.onTextChange(
																"title_name",
																e.target.value
															)
														}
														value={
															this.state.title_name !== null
																? this.state.title_name
																: ""
														}
														aria-required="true"
													/>
												</div>

												<div className="form-group">
													<label
														htmlFor="price"
														className="control-label mb-1"
													>
														Machine Category Price
													</label>
													<input
														id="price"
														name="price"
														type="text"
														className="form-control"
														aria-required="true"
														onChange={e =>
															this.onTextChange(
																"price",
																e.target.value
															)
														}
														value={this.state.price}
													/>
													<div
														className="mt-4"
														style={{
															color: "red"
														}}
													>
														{this.validator.message(
															"price",
															this.state.price,
															"numeric"
														)}
													</div>
												</div>
												<div className="form-group">
													<label
														htmlFor="cost_price"
														className="control-label mb-1"
													>
														Machine Category Cost Price
													</label>
													<input
														id="cost_price"
														name="cost_price"
														type="text"
														className="form-control"
														aria-required="true"
														onChange={e =>
															this.onTextChange(
																"cost_price",
																e.target.value
															)
														}
														value={this.state.cost_price}
													/>
													<div
														className="mt-4"
														style={{
															color: "red"
														}}
													>
														{this.validator.message(
															"cost_price",
															this.state.cost_price,
															"numeric"
														)}
													</div>
												</div>

												<div className="form-group">
													<label
														htmlFor="year"
														className="control-label mb-1"
													>
														Country
													</label>
													<select
														onChange={e =>
															this.onCountryChange(
																e.target.value
															)
														}
														value={this.state.country}
														className="custom-select"
													>
														{this.state.countries
															? this.state.countries.map(
																	(country, i) => (
																		<option
																			key={i}
																			value={country.id}
																		>
																			{country.country_name}
																		</option>
																	)
															  )
															: null}
													</select>
													<div
														className="mt-4"
														style={{
															color: "red"
														}}
													>
														{this.validator.message(
															"year",
															this.state.country,
															"required|numeric"
														)}
													</div>
												</div>

												<div className="form-group">
													<label
														htmlFor="year"
														className="control-label mb-1"
													>
														Year
													</label>
													<select
														onChange={e =>
															this.onYearChange(e.target.value)
														}
														value={this.state.year}
														className="custom-select"
													>
														<option value={null}>
															Select Year
														</option>
														{this.state.years.map((year, i) => (
															<option key={i} value={year.id}>
																{year.year}
															</option>
														))}
													</select>
													<div
														className="mt-4"
														style={{
															color: "red"
														}}
													>
														{this.validator.message(
															"year",
															this.state.year,
															"required|numeric"
														)}
													</div>
												</div>

												<div className="form-group">
													<label
														htmlFor="price"
														className="control-label mb-1"
													>
														Machine Brand (optional)
													</label>
													<select
														onChange={e =>
															this.onSelectChange(
																"brand",
																e.target.value
															)
														}
														value={this.state.brand}
														className="custom-select"
													>
														<option>Select Brand</option>
														{this.state.brands.map((brand, i) => (
															<option key={i} value={brand.id}>
																{brand.brand_name}
															</option>
														))}
													</select>
													<div
														className="mt-4"
														style={{
															color: "red"
														}}
													>
														{this.validator.message(
															"brand",
															this.state.brand,
															"required|numeric"
														)}
													</div>
												</div>
												<div className="form-group">
													<button
														className="btn btn-secondary"
														onClick={this.UncheckAll}
													>
														Uncheck All
													</button>
												</div>

												<div>
													<button
														onClick={this.onSubmit}
														id="payment-button"
														type="submit"
														className="btn btn-secondary btn-block"
													>
														<i className="fa fa-truck"></i>&nbsp;
														<span id="payment-button-amount">
															Update Machine Category
														</span>
													</button>
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				);
			}
		}
	}
}

const mapStateToProps = state => {
	return {
		message: state.showMachineCategoryReducer.message,
		loading: state.showMachineCategoryReducer.loading,
		role_id: state.loginReducer.user.role_id
	};
};

export default connect(mapStateToProps, {
	edit_machine_categories
})(UpdateMachineCategoryWithPrice);
