import countryActionTypes from "./actionTypes";

function show_countries() {
  return { type: countryActionTypes.SHOW_COUNTRY_REQUEST, loading: true };
}

function add_countries(params) {
  return {
    type: countryActionTypes.ADD_COUNTRY_REQUEST,
    loading: true,
    params: params
  };
}

function edit_countries(params) {
  return {
    type: countryActionTypes.UPDATE_COUNTRY_REQUEST,
    loading: true,
    params: params
  };
}

function delete_country(params) {
  return {
    type: countryActionTypes.DELETE_COUNTRY_REQUEST,
    loading: true,
    params: params
  };
}

function clean_country_message() {
  return {
    type: countryActionTypes.CLEAN_COUNTRY_MESSAGE_REQUEST,
    loading: true
  };
}

export {
  show_countries,
  add_countries,
  edit_countries,
  delete_country,
  clean_country_message
};
