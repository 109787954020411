import { Api } from "./../../config/api";

// api call to show all brands
function showAllSalesDesk() {
	let data = Api.get("sales-desk").then(function(response) {
		return response.data;
	});

	if (data) {
		return data;
	} else {
		return false;
	}
}

//api call to show a single brand
function showSingleSalesDesk(param) {
	let data = Api.get("sales-desk/" + param).then(function(response) {
		return response.data;
	});

	if (data) {
		return data;
	} else {
		return false;
	}
}

//api call to add a brand into the system
function createSalesDesk(params) {
	let data = Api.post("sales-desk", params).then(function(response) {
		return response.data;
	});

	if (data) {
		return data;
	} else {
		return false;
	}
}

//api call to edit brand exisitng in the system
function updateSalesDesk(params, id) {
	let data = Api.post("sales-desk/edit/" + id, params).then(function(
		response
	) {
		return response.data;
	});

	if (data) {
		return data;
	} else {
		return false;
	}
}

//api call to delete exisitng brand from the system
function deleteSalesDesk(params) {
	let data = Api.get("sales-desk/delete/" + params).then(function(response) {
		return response.data;
	});

	if (data) {
		return data;
	} else {
		return false;
	}
}

export const SalesDeskService = {
	showAllSalesDesk,
	showSingleSalesDesk,
	createSalesDesk,
	updateSalesDesk,
	deleteSalesDesk
};
