import React, { Component } from "react";
import { connect } from "react-redux";
import { show_all_categories } from "./../../compatibility/action";
import { Tree } from "primereact/tree";
import { Link } from "react-router-dom";
import { get_tree_category } from "./../../compatibility/action";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { notification } from "antd";

class ListCategory extends Component {
	constructor(props) {
		super(props);
		this.state = {
			machine_categories: [],
			tree: this.props.tree,
			selectedNodeKey: ""
		};
	}

	async componentDidMount() {
		let that = this;
		await that.props.get_tree_category();

		if (that.props.message !== "" && that.props.message !== undefined) {
			if (that.props.message.toLowerCase().includes("success")) {
				notification.success({
					message: "Success!",
					description: this.props.message
				});
			} else {
				notification.error({
					message: "Error!",
					description: this.props.message
				});
			}
		}
	}

	render() {
		if (!this.props.loading) {
			return (
				<div>
					<div
						className="content"
						style={{
							minHeight: 500
						}}
					>
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-header">
										<strong className="card-title">
											List machine categories for compatibility
										</strong>
									</div>
									<div className="card-body">
										<div className="card-content">
											<div className="todo-list">
												<div className="tdl-content">
													<div className="card">
														<div className="card-body">
															<div className="row">
																<div className="col text-left">
																	Edit Selected
																</div>
																<div className="col text-right">
																	{this.state
																		.selectedNodeKey ? (
																		<div>
																			<Link
																				to={
																					"/show-compatible-parts/" +
																					this.state
																						.selectedNodeKey
																				}
																			>
																				<i className="fa ml-1 mr-1 text-dark fa-list"></i>
																			</Link>
																		</div>
																	) : null}
																</div>
															</div>
														</div>
													</div>
													<Tree
														value={this.props.tree}
														selectionMode="single"
														selectionKeys={
															this.state.selectedNodeKey
														}
														onSelectionChange={e =>
															this.setState({
																selectedNodeKey: e.value
															})
														}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div>
					<div
						className="content"
						style={{
							minHeight: 500
						}}
					>
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-header">
										<strong className="card-title">
											List machine categories for compatibility
										</strong>
									</div>
									<div className="card-body">
										<div className="card-content">
											<div className="todo-list">
												<div className="tdl-holder">
													<div className="tdl-content">
														<div className="row">
															<div className="col"></div>
															<div className="col">
																<div className="text-center">
																	<Loader
																		type="Rings"
																		color="#00BFFF"
																		height={100}
																		width={100}
																	/>
																	<div className="text-center client-roboto-condensed">
																		LOADING...
																	</div>
																</div>
															</div>
															<div className="col"></div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}
}

const mapStateToProps = state => {
	return {
		categories: state.compatibilityReducer.all_categories,
		message: state.compatibilityReducer.message,
		tree: state.compatibilityReducer.tree,
		loading: state.compatibilityReducer.loading
	};
};

export default connect(mapStateToProps, {
	show_all_categories,
	get_tree_category
})(ListCategory);
