import React, { Component } from "react";
import { connect } from "react-redux";
import { save_first_form_data } from "./../action";
import SimpleReactValidator from "simple-react-validator";

class EditFirstForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			quotation_name: this.props.first_form
				? this.props.first_form.quotation_name
				: false,
			quotation_for: this.props.first_form
				? this.props.first_form.quotation_for
				: false,
			user_id: this.props.user_id ? this.props.user_id : false
		};
		this.validator = new SimpleReactValidator({ autoForceUpdate: this });
		this.onSubmit = this.onSubmit.bind(this);
		this.onTextChange = this.onTextChange.bind(this);
	}

	async onSubmit(e) {
		e.preventDefault();

		if (this.validator.allValid()) {

			let params = {
				quotation_name: this.state.quotation_name,
				quotation_for: this.state.quotation_for
			};

			await this.props.save_first_form_data(params);
			await this.props.onComponentChange("thirdForm");
		} else {
			this.validator.showMessages();
		}
	}

	onTextChange(name, value) {
		let that = this;

		switch (name) {
			case "quotation_name":
				that.setState({ quotation_name: value });
				break;

			case "quotation_for":
				that.setState({ quotation_for: value });
				break;

			default:
				break;
		}
	}

	async componentDidMount() {
		await this.setState({ loading: false });
	}

	render() {
		return (
			<div className="m-2 row">
				<div className="col"></div>
				<div className="col-sm-12 text-center">
					<h4 className="client-roboto-condensed font-weight-bold mb-4 text-center">
						Quote Information
					</h4>
					<form className="m-5">
						<div className="row text-center mt-3 mb-3">
							<div className="col"></div>
							<div className="col-6">
								<div className="form-group">
									<label className="client-roboto-condensed font-weight-bold">
										Quote Name
									</label>
									<input
										style={{
											textAlign: "center"
										}}
										className="form-control"
										type="text"
										value={
											this.state.quotation_name
												? this.state.quotation_name
												: ""
										}
										onChange={e =>
											this.onTextChange(
												"quotation_name",
												e.target.value
											)
										}
										placeholder="Enter Quotation Name"
									/>
									<div
										className="mt-4"
										style={{
											color: "red"
										}}
									>
										{this.validator.message(
											"quotation_name",
											this.state.quotation_name,
											"required|string|alpha_num_dash_space"
										)}
									</div>
								</div>
								<div className="form-group">
									<label className="client-roboto-condensed font-weight-bold">
										Customer Name
									</label>
									<input
										style={{
											textAlign: "center"
										}}
										className="form-control"
										type="text"
										value={
											this.state.quotation_for
												? this.state.quotation_for
												: ""
										}
										onChange={e =>
											this.onTextChange(
												"quotation_for",
												e.target.value
											)
										}
										placeholder="Enter Customer Name"
									/>
								</div>
								<div
									className="mt-4"
									style={{
										color: "red"
									}}
								>
									{this.validator.message(
										"quotation_for",
										this.state.quotation_for,
										"required|string|alpha_num_dash_space"
									)}
								</div>
								<button
									onClick={this.onSubmit}
									type="submit"
									className="client-roboto-condensed text-uppercase client-login px-5 my-3"
								>
									Next
								</button>
							</div>
							<div className="col"></div>
						</div>
					</form>
				</div>
				<div className="col"></div>
			</div>
		);
	}
}
const mapStateToProps = state => {
	return {
		user_id: state.loginReducer.user.user.id,
		first_form: state.clientQuoteReducer.firstForm
	};
};

const mapDispatchToProps = {
	save_first_form_data
};

export default connect(mapStateToProps, mapDispatchToProps)(EditFirstForm);
