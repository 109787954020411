import countryActionTypes from "./actionTypes";
const INITIAL_STATE = {
  message: "",
  loading: false,
  countries: false,
  country: []
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case countryActionTypes.SHOW_COUNTRY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case countryActionTypes.SHOW_COUNTRY_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
        countries: action.countries
      };
    case countryActionTypes.SHOW_COUNTRY_FALIURE:
      return {
        ...state,
        loading: false,
        message: action.message
      };

    case countryActionTypes.ADD_COUNTRY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case countryActionTypes.ADD_COUNTRY_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message
      };
    case countryActionTypes.ADD_COUNTRY_FALIURE:
      return {
        ...state,
        loading: false,
        message: action.message
      };

    case countryActionTypes.UPDATE_COUNTRY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case countryActionTypes.UPDATE_COUNTRY_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message
      };
    case countryActionTypes.UPDATE_COUNTRY_FALIURE:
      return {
        ...state,
        loading: false,
        message: action.message
      };

    case countryActionTypes.DELETE_COUNTRY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case countryActionTypes.DELETE_COUNTRY_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message
      };
    case countryActionTypes.DELETE_COUNTRY_FALIURE:
      return {
        ...state,
        loading: false,
        message: action.message
      };

    case countryActionTypes.CLEAN_COUNTRY_MESSAGE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case countryActionTypes.CLEAN_COUNTRY_MESSAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        message: ""
      };
    case countryActionTypes.CLEAN_COUNTRY_MESSAGE_FALIURE:
      return {
        ...state,
        loading: false,
        message: ""
      };

    default:
      return state;
  }
}
