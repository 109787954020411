import quotesActionTypes from "./actionTypes";
import { QuoteService } from "./service";
import { take, call, put } from "redux-saga/effects";

function* showQuotes() {
  while (true) {
    yield take(quotesActionTypes.SHOW_QUOTES_REQUEST);
    let quotes = yield call(QuoteService.showQuotes);

    if (quotes !== "" && quotes !== null && quotes) {
      yield put({
        type: quotesActionTypes.SHOW_QUOTES_REQUEST,
        message: "Getting Quotes Success",
        quotes: quotes
      });
    } else {
      yield put({
        type: quotesActionTypes.SHOW_QUOTES_FALIURE,
        message: "Getting Quote Faliure"
      });
    }
  }
}

function* showSingleQuote(param) {
  while (true) {
    let { param } = yield take(quotesActionTypes.SHOW_SINGLE_QUOTES_REQUEST);

    let quote = yield call(QuoteService.showSingleQuote, param);

    if (quote !== "" && quote !== null && quote) {
      yield put({
        type: quotesActionTypes.SHOW_SINGLE_QUOTES_SUCCESS,
        message: "Getting Detail Quotes Success",
        quote: quote
      });
    } else {
      yield put({
        type: quotesActionTypes.SHOW_SINGLE_QUOTES_FALIURE,
        message: "Getting Detailed Quote Faliure"
      });
    }
  }
}

export { showQuotes, showSingleQuote };
