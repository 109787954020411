import configuredMachineActionTypes from "./actionTypes";
import { ConfiguredMachineService } from "./service";
import { take, call, put } from "redux-saga/effects";

function* showConfiguredMachines() {
  while (true) {
    yield take(configuredMachineActionTypes.SHOW_CONFIGURED_MACHINE_REQUEST);

    const configured_machines = yield call(
      ConfiguredMachineService.showConfiguredMachines
    );

    if (configured_machines !== "" && configured_machines !== null) {
      yield put({
        type: configuredMachineActionTypes.SHOW_CONFIGURED_MACHINE_SUCCESS,
        message: "Getting configured machines success",
        configured_machines: configured_machines
      });
    } else {
      yield put({
        type: configuredMachineActionTypes.SHOW_CONFIGURED_MACHINE_FALIURE,
        message: "Getting configured machines faliure"
      });
    }
  }
}

export { showConfiguredMachines };
