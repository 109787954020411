import { Api } from "./../../config/api";

// api call to show all brands
function showAllResudualPercentage() {
  let data = Api.get("residual-percentage/showAllResudualPercentage").then(function(response) {
    return response.data;
  });

  if (data) {
    return data;
  } else {
    return false;
  }
}

//api call to show a single brand
function showSingleResidualPercentage(param) {
  let data = Api.get("residual-percentage/showSingleResidualPercentage/" + param).then(function(response) {
    return response.data;
  });

  if (data) {
    return data;
  } else {
    return false;
  }
}

//api call to add a brand into the system
function createResidualPercentage(params) {
  let data = Api.post("residual-percentage/createResidualPercentage", params).then(function(response) {
    return response.data;
  });

  if (data) {
    return data;
  } else {
    return false;
  }
}

//api call to edit brand exisitng in the system
function editResidualPercentage(params) {
  let data = Api.post("residual-percentage/editResidualPercentage/" + params.get("id"), params).then(function(
    response
  ) {
    return response.data;
  });

  if (data) {
    return data;
  } else {
    return false;
  }
}

//api call to delete exisitng brand from the system
function deleteResidualPercentage(params) {
  let data = Api.get("residual-percentage/deleteResidualPercentage/" + params).then(function(response) {
    return response.data;
  });

  if (data) {
    return data;
  } else {
    return false;
  }
}

export const ResidualPercentageService = {
    showAllResudualPercentage,
    showSingleResidualPercentage,
    createResidualPercentage,
    editResidualPercentage,
    deleteResidualPercentage
};
