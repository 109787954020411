import { Api } from "./../../config/api";

function showAllSpecificationForMachineCateogry(params) {
  let data = Api.get(
    "specification/showAllSpecificationOfMachineCategory/" + params
  )
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {});

  if (data) {
    return data;
  } else {
    return false;
  }
}

function showSingleSpecification(params) {
  let data = Api.get(
    "specification/showSpecificSpecificationOfMachineCategory/" + params
  )
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {});

  if (data) {
    return data;
  } else {
    return false;
  }
}

function addSpecificationForMachineCategory(params) {
  let data = Api.post(
    "specification/addSpecificationForMachineCategory/" +
      params.machine_category_id,
    params
  )
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {});

  if (data) {
    return data;
  } else {
    return false;
  }
}

function editSpecificationForMachineCategory(params) {
  let data = Api.post("specification/edit/" + params.id, params)
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {});

  if (data) {
    return data;
  } else {
    return false;
  }
}

function deleteSpecificationForMachineCategory(params) {
  let data = Api.get("specification/delete/" + params)
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {});

  if (data) {
    return data;
  } else {
    return false;
  }
}

export const SpecificationService = {
  showSingleSpecification,
  showAllSpecificationForMachineCateogry,
  addSpecificationForMachineCategory,
  editSpecificationForMachineCategory,
  deleteSpecificationForMachineCategory
};
