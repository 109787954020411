import companyRatesActionTypes from "./actionTypes";
const INITIAL_STATE = {
  message: "",
  loading: false,
  company_rates: [],
  company_rate: []
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case companyRatesActionTypes.SHOW_COMPANY_RATES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case companyRatesActionTypes.SHOW_COMPANY_RATES_SUCCESS:
      return {
        ...state,
        loading: false,
        company_rates: action.company_rates,
        message: action.message
      };
    case companyRatesActionTypes.SHOW_COMPANY_RATES_FALIURE:
      return {
        ...state,
        loading: false,
        message: action.message
      };

    case companyRatesActionTypes.ADD_COMPANY_RATES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case companyRatesActionTypes.ADD_COMPANY_RATES_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message
      };
    case companyRatesActionTypes.ADD_COMPANY_RATES_FALIURE:
      return {
        ...state,
        loading: false,
        message: action.message
      };

    case companyRatesActionTypes.EDIT_COMPANY_RATES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case companyRatesActionTypes.EDIT_COMPANY_RATES_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message
      };
    case companyRatesActionTypes.EDIT_COMPANY_RATES_FALIURE:
      return {
        ...state,
        loading: false,
        message: action.message
      };

    case companyRatesActionTypes.DELETE_COMPANY_RATES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case companyRatesActionTypes.DELETE_COMPANY_RATES_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message
      };
    case companyRatesActionTypes.DELETE_COMPANY_RATES_FALIURE:
      return {
        ...state,
        loading: false,
        message: action.message
      };

    default:
      return state;
  }
}
