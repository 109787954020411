import noteAndRemarkActionTypes from "./actionTypes";
const INITIAL_STATE = {
  message: "",
  loading: false,
  notes_and_remarks: [],
  note_and_remark: []
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case noteAndRemarkActionTypes.CLEAN_MESSAGE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case noteAndRemarkActionTypes.CLEAN_MESSAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        message: ""
      };
    case noteAndRemarkActionTypes.CLEAN_MESSAGE_FALIURE:
      return {
        ...state,
        loading: false,
        message: action.message
      };

    case noteAndRemarkActionTypes.SHOW_NOTE_AND_REMARK_REQUEST:
      return {
        ...state,
        loading: true
      };
    case noteAndRemarkActionTypes.SHOW_NOTE_AND_REMARK_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
        notes_and_remarks: action.notes_and_remarks
      };
    case noteAndRemarkActionTypes.SHOW_NOTE_AND_REMARK_FALIURE:
      return {
        ...state,
        loading: false,
        message: action.message
      };

    case noteAndRemarkActionTypes.UPDATE_NOTE_AND_REMARK_REQUEST:
      return {
        ...state,
        loading: true
      };
    case noteAndRemarkActionTypes.UPDATE_NOTE_AND_REMARK_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message
      };
    case noteAndRemarkActionTypes.UPDATE_NOTE_AND_REMARK_FALIURE:
      return {
        ...state,
        loading: false,
        message: action.message
      };

    default:
      return state;
  }
}
