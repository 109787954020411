import React, { Component } from "react";
import { connect } from "react-redux";
import { Steps } from "primereact/steps";
import { clear_froms } from "./../action";
import { Redirect } from "react-router-dom";

import FirstForm from "./../../configuration/views/firstForm";
import SecondForm from "./../../configuration/views/secondForm";
import ThirdForm from "./../../configuration/views/thirdForm";
import { css } from "@emotion/react";
import { PulseLoader } from "react-spinners";
import {
	show_single_user_configuration_for_edit,
	show_single_library_configuration_for_edit
} from "./../action";

class Home extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activeIndex: 0,
			firstFormOn: true,
			secondFormOn: false,
			thirdFormOn: false,
			message1: false,
			is_library: false
		};
    this.handleComponentChange = this.handleComponentChange.bind(this);
    this.handleLibraryChange = this.handleLibraryChange.bind(this);
	}

	handleLibraryChange(value) {
		let that = this;
		that.setState({ is_library: value });
	}

	handleComponentChange(value, message) {
		let that = this;

		switch (value) {
			case "firstForm":
				that.setState({ activeIndex: 0 });
				that.setState({ firstFormOn: true });
				that.setState({ secondFormOn: false });
				that.setState({ thirdFormOn: false });
				that.setState({ message1: message });
				break;

			case "seconForm":
				that.setState({ activeIndex: 1 });
				that.setState({ firstFormOn: false });
				that.setState({ secondFormOn: true });
				that.setState({ thirdFormOn: false });
				that.setState({ message1: message });
				break;

			case "thirdForm":
				that.setState({ activeIndex: 2 });
				that.setState({ firstFormOn: false });
				that.setState({ secondFormOn: false });
				that.setState({ thirdFormOn: true });
				that.setState({ message1: message });
				break;

			default:
				break;
		}
	}

	async componentDidMount() {
		let that = this;
		const { id } = this.props.match.params;
		const { from } = this.props.match.params;

		if (id) {
			if (from === "library") {
				await that.props.show_single_library_configuration_for_edit(
					that.props.user_id,
					id,
					this.props.role
				);
			} else {
				await that.props.show_single_user_configuration_for_edit(
					that.props.user_id,
					id,
					this.props.role
				);
			}
		} else {
			await this.props.clear_froms();
		}
	}

	render() {
		const items = [
			{
				label: "Configuration Information",
				command: event => {
					this.setState({ firstFormOn: true });
					this.setState({ secondFormOn: false });
					this.setState({ thirdFormOn: false });
				}
			},
			{
				label: "Parts Selection",
				command: event => {
					this.setState({ firstFormOn: false });
					this.setState({ secondFormOn: true });
					this.setState({ thirdFormOn: false });
				}
			},
			{
				label: "Confirmation",
				command: event => {
					this.setState({ firstFormOn: false });
					this.setState({ secondFormOn: false });
					this.setState({ thirdFormOn: true });
				}
			}
		];
		const override = css`
			display: block;
			margin: 0 auto;
			border-color: red;
		`;
		if (this.props.loginLoading) {
			return (
				<div className="sweet-loading">
					<div className="container loading-container">
						<div className="row m-5 p-5">
							<PulseLoader
								css={override}
								sizeUnit={"px"}
								size={24}
								color={"#B9373D"}
							/>
						</div>
					</div>
				</div>
			);
		} else {
			if (this.props.message.includes("Creating configuration success")) {
				if (this.state.is_library) {
					return <Redirect to="/library-configurations" />;
				} else {
					return <Redirect to="/configuration-success" />;
				}
			} else {
				return (
					<div className="mt-4 mb-4 client-roboto-condensed container">
						<div className="row">
							<div className="col-lg-12 text-center">
								<div className="text-center">
									<div className="content-section implementation">
										<Steps
											className="mt-5 mb-5"
											model={items}
											activeIndex={this.state.activeIndex}
											onSelect={e =>
												this.setState({ activeIndex: e.index })
											}
											readOnly={true}
										/>
									</div>
								</div>
							</div>
						</div>
						{this.state.firstFormOn ? (
							<div className="first-form">
								<FirstForm
									onComponentChange={this.handleComponentChange}
									message={this.state.message1}
								/>
							</div>
						) : null}
						{this.state.secondFormOn ? (
							<div className="second-form">
								<SecondForm
									onComponentChange={this.handleComponentChange}
									message={this.state.message1}
								/>
							</div>
						) : null}
						{this.state.thirdFormOn ? (
							<div className="third-form">
								<ThirdForm
									onIsLibrary={this.handleLibraryChange}
									onComponentChange={this.handleComponentChange}
									message={this.state.message1}
								/>
							</div>
						) : null}
					</div>
				);
			}
		}
	}
}
const mapStateToProps = state => {
	return {
		first_form: state.clientConfigurationReducer.first_form,
		second_form: state.clientConfigurationReducer.second_form,
		message: state.clientConfigurationReducer.message,
		loginLoading: state.loginReducer.loading,
		user_id: state.loginReducer.user.user.id,
		role: state.loginReducer.user.role
	};
};

export default connect(mapStateToProps, {
	show_single_user_configuration_for_edit,
	clear_froms,
	show_single_library_configuration_for_edit
})(Home);
