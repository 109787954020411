import roleActionTypes from "./actionTypes";

function getCostPriceVisibilityForRole(params) {
  return {
    type: roleActionTypes.GET_COST_PRICE_VISIBILITY_FOR_ROLE_REQUEST,
    loading: true,
    params: params
  };
}

export { getCostPriceVisibilityForRole };
