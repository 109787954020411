import React, {Component} from "react";
import {connect} from "react-redux";
import {edit_configuration, edit_library_configuration} from "./../action";
import {Api} from "./../../../config/api";
import { css } from "@emotion/react";
import {PulseLoader} from "react-spinners";
import strings from "./../../../config/language";
import config from "./../../../config/config";
import getSymbolFromCurrency from "currency-symbol-map";
import {notification, Descriptions} from "antd";

class editThirdForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_dealer: this.props.is_dealer,
            loading: true,
            company_rate: false,
            configuration_name: this.props.first_form
                ? this.props.first_form.configuration_name
                : false,
            only_parts: this.props.first_form
                ? this.props.first_form.only_parts
                : false,
            discount_percentage: this.props.configuration
                ? this.props.configuration[0].discount_percentage
                : 0,
            country_id: this.props.configuration
                ? this.props.configuration[0].country_id
                : 0,
            discount_amount: this.props.configuration
                ? this.props.configuration[0].discount_amount
                : 0,
            dealer_discount_amount: this.props.configuration
                ? this.props.configuration[0].dealer_discount_amount
                : 0,
            oem_discount_amount: this.props.configuration
                ? this.props.configuration[0].oem_discount
                : 0,
            extra_discount_amount: this.props.configuration
                ? this.props.configuration[0].extra_discount
                : 0,
            sales_margin: this.props.configuration
                ? this.props.configuration[0].sales_margin
                : 0,
            sales_margin_amount: this.props.configuration
                ? this.props.configuration[0].sales_margin_amount
                : 0,
            end_user_sales_price: this.props.configuration
                ? this.props.configuration[0].end_user_sales_price
                : 0,
            landed_cost: this.props.configuration
                ? this.props.configuration[0].landed_cost
                : false,
            configuration_total_price: "",
            configuration_cost_total_price: "",
            configuration_gross_total_price: "",
            category_details: false,
            configuration_quantity: this.props.configuration
                ? this.props.configuration[0].quantity
                : 1,
            trade_in_value: this.props.configuration ? this.props.configuration[0].trade_in_value : 0,
            configuration_total_price_VAT: "",
            configuration_total_discount: "",
            user: this.props.first_form
                ? parseInt(this.props.first_form.user_id)
                : null,
            machine_category: this.props.first_form
                ? this.props.first_form.configuration_category
                : false,
            configuration_category: this.props.first_form
                ? this.props.first_form.machine_category_name
                : false,
            selected_parts: this.props.second_form
                ? this.props.second_form.compatible_machine_parts
                : false,
            manualAdjustments:
                this.props.second_form && this.props.second_form.manual_adjustments
                    ? this.props.second_form.manual_adjustments
                    : [],
            maximum_available_discount: 0
        };
        this.onQuantityChange = this.onQuantityChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onBack = this.onBack.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
    }

    onQuantityChange(value, operation) {
        switch (operation) {
            case "plus":
                if (value === 20) {
                    break;
                }
                value++;
                this.setState({configuration_quantity: value});
                break;

            case "minus":
                if (value === 1) {
                    break;
                }
                value--;
                this.setState({configuration_quantity: value--});
                break;

            default:
                break;
        }
    }

    async handleTextChange(value, type) {
        let that = this;

        switch (type) {
            case "discount_percentage":
                if (
                    value <= this.props.max_discount &&
                    value <= this.props.first_form.max_discount_category &&
                    !isNaN(value)
                ) {
                    if (
                        parseFloat(
                            (value * that.state.configuration_total_price) / 100 +
                            isNaN(parseFloat(this.state.discount_amount))
                                ? 0
                                : parseFloat(that.state.discount_amount)
                        ) <= parseFloat(this.state.maximum_available_discount)
                    ) {
                        await that.setState({discount_percentage: value});
                    } else {
                        notification.info({
                            message: "Info!",
                            description:
                                "Discount can not amount to more than " +
                                this.state.maximum_available_discount
                        });
                    }
                } else {
                    if (
                        this.props.max_discount <
                        this.props.first_form.max_discount_category
                    ) {
                        notification.info({
                            message: "Info!",
                            description:
                                "Discount can not be more than " +
                                this.props.max_discount
                        });
                    } else {
                        notification.info({
                            message: "Info!",
                            description:
                                "Discount can not be more than " +
                                this.props.first_form.max_discount_category
                        });
                    }
                }

                this.handleTextChange(
                    this.state.end_user_sales_price,
                    "end_user_sales_price"
                );
                break;

            case "discount_amount":
                if (!isNaN(value)) {
                    if (
                        parseFloat(
                            (this.state.discount_percentage *
                                this.state.configuration_total_price) /
                            100 +
                            parseFloat(value !== "" ? value : 0)
                        ) <= parseFloat(this.state.maximum_available_discount)
                    ) {
                        await that.setState({discount_amount: value});
                    } else {
                        notification.info({
                            message: "Info!",
                            description:
                                "Discount can not amount to more than " +
                                this.state.maximum_available_discount
                        });
                    }
                }

                this.handleTextChange(
                    this.state.end_user_sales_price,
                    "end_user_sales_price"
                );
                break;

            case "dealer_discount_amount":
                if (!isNaN(value)) {
                    await that.setState({dealer_discount_amount: value});
                }

                this.handleTextChange(
                    this.state.end_user_sales_price,
                    "end_user_sales_price"
                );
                break;

            case "oem_discount_amount":
                if (!isNaN(value)) {
                    await that.setState({oem_discount_amount: value});
                }

                this.handleTextChange(
                    this.state.end_user_sales_price,
                    "end_user_sales_price"
                );
                break;

            case "trade_in_value":
                if (!isNaN(value)) {
                    await that.setState({trade_in_value: value});
                }
                break;

            case "extra_discount_amount":
                if (!isNaN(value) && value <= 100) {
                    await that.setState({extra_discount_amount: value});
                }

                this.handleTextChange(
                    this.state.end_user_sales_price,
                    "end_user_sales_price"
                );
                break;

            case "sales_margin":
                that.setState({sales_margin: value});
                let sales_margin_amount = parseFloat(
                    (value *
                        parseFloat(
                            this.state.configuration_total_price -
                            (this.state.configuration_total_price *
                                this.state.discount_percentage) /
                            100 -
                            this.state.discount_amount -
                            this.state.dealer_discount_amount -
                            this.state.oem_discount_amount -
                            parseFloat(
                                (this.state.extra_discount_amount *
                                    parseFloat(
                                        this.state.configuration_total_price -
                                        (this.state.configuration_total_price *
                                            this.state.discount_percentage) /
                                        100 -
                                        this.state.discount_amount -
                                        this.state.dealer_discount_amount -
                                        this.state.oem_discount_amount
                                    ).toFixed(2)) /
                                100
                            ).toFixed(2)
                        )) /
                    100
                ).toFixed(2);

                let end_user_sales_price = parseFloat(
                    parseFloat(
                        (value *
                            parseFloat(
                                this.state.configuration_total_price -
                                (this.state.configuration_total_price *
                                    this.state.discount_percentage) /
                                100 -
                                this.state.discount_amount -
                                this.state.dealer_discount_amount -
                                this.state.oem_discount_amount -
                                parseFloat(
                                    (this.state.extra_discount_amount *
                                        parseFloat(
                                            this.state.configuration_total_price -
                                            (this.state.configuration_total_price *
                                                this.state.discount_percentage) /
                                            100 -
                                            this.state.discount_amount -
                                            this.state.dealer_discount_amount -
                                            this.state.oem_discount_amount
                                        ).toFixed(2)) /
                                    100
                                ).toFixed(2)
                            )) /
                        100
                    ) +
                    parseFloat(
                        this.state.configuration_total_price -
                        (this.state.configuration_total_price *
                            this.state.discount_percentage) /
                        100 -
                        this.state.discount_amount -
                        this.state.dealer_discount_amount -
                        this.state.oem_discount_amount -
                        parseFloat(
                            (this.state.extra_discount_amount *
                                parseFloat(
                                    this.state.configuration_total_price -
                                    (this.state.configuration_total_price *
                                        this.state.discount_percentage) /
                                    100 -
                                    this.state.discount_amount -
                                    this.state.dealer_discount_amount -
                                    this.state.oem_discount_amount
                                ).toFixed(2)) /
                            100
                        ).toFixed(2)
                    )
                ).toFixed(2);

                that.setState({sales_margin_amount: sales_margin_amount});
                that.setState({end_user_sales_price: end_user_sales_price});
                break;

            case "sales_margin_amount":
                if (!isNaN(value)) {
                    that.setState({sales_margin_amount: value});

                    let sales_margin = parseFloat(
                        (value * 100) /
                        parseFloat(
                            this.state.configuration_total_price -
                            (this.state.configuration_total_price *
                                this.state.discount_percentage) /
                            100 -
                            this.state.discount_amount -
                            this.state.dealer_discount_amount -
                            this.state.oem_discount_amount -
                            parseFloat(
                                (this.state.extra_discount_amount *
                                    parseFloat(
                                        this.state.configuration_total_price -
                                        (this.state.configuration_total_price *
                                            this.state.discount_percentage) /
                                        100 -
                                        this.state.discount_amount -
                                        this.state.dealer_discount_amount -
                                        this.state.oem_discount_amount
                                    ).toFixed(2)) /
                                100
                            ).toFixed(2)
                        )
                    ).toFixed(2);

                    let end_user_sales_price = parseFloat(
                        parseFloat(
                            this.state.configuration_total_price -
                            (this.state.configuration_total_price *
                                this.state.discount_percentage) /
                            100 -
                            this.state.discount_amount -
                            this.state.dealer_discount_amount -
                            this.state.oem_discount_amount -
                            parseFloat(
                                (this.state.extra_discount_amount *
                                    parseFloat(
                                        this.state.configuration_total_price -
                                        (this.state.configuration_total_price *
                                            this.state.discount_percentage) /
                                        100 -
                                        this.state.discount_amount -
                                        this.state.dealer_discount_amount -
                                        this.state.oem_discount_amount
                                    ).toFixed(2)) /
                                100
                            ).toFixed(2)
                        ) + value
                    ).toFixed(2);

                    that.setState({sales_margin: sales_margin});
                    that.setState({end_user_sales_price: end_user_sales_price});
                }
                break;

            case "end_user_sales_price":
                if (!isNaN(value)) {
                    that.setState({end_user_sales_price: value});

                    let sales_margin_amount = parseFloat(
                        value -
                        parseFloat(
                            this.state.configuration_total_price -
                            (this.state.configuration_total_price *
                                this.state.discount_percentage) /
                            100 -
                            this.state.discount_amount -
                            this.state.dealer_discount_amount -
                            this.state.oem_discount_amount -
                            parseFloat(
                                (this.state.extra_discount_amount *
                                    parseFloat(
                                        this.state.configuration_total_price -
                                        (this.state.configuration_total_price *
                                            this.state.discount_percentage) /
                                        100 -
                                        this.state.discount_amount -
                                        this.state.dealer_discount_amount -
                                        this.state.oem_discount_amount
                                    ).toFixed(2)) /
                                100
                            ).toFixed(2)
                        )
                    ).toFixed(2);


                    if (sales_margin_amount > 0) {
                        that.setState({sales_margin_amount: sales_margin_amount});
                    } else {
                        that.setState({sales_margin_amount: 0});
                    }

                    let sales_margin = parseFloat(
                        (parseFloat(
                                value -
                                parseFloat(
                                    this.state.configuration_total_price -
                                    (this.state.configuration_total_price *
                                        this.state.discount_percentage) /
                                    100 -
                                    this.state.discount_amount -
                                    this.state.dealer_discount_amount -
                                    this.state.oem_discount_amount -
                                    parseFloat(
                                        (this.state.extra_discount_amount *
                                            parseFloat(
                                                this.state.configuration_total_price -
                                                (this.state
                                                        .configuration_total_price *
                                                    this.state.discount_percentage) /
                                                100 -
                                                this.state.discount_amount -
                                                this.state.dealer_discount_amount -
                                                this.state.oem_discount_amount
                                            ).toFixed(2)) /
                                        100
                                    ).toFixed(2)
                                )
                            ).toFixed(2) /
                            parseFloat(
                                this.state.configuration_total_price -
                                (this.state.configuration_total_price *
                                    this.state.discount_percentage) /
                                100 -
                                this.state.discount_amount -
                                this.state.dealer_discount_amount -
                                this.state.oem_discount_amount -
                                parseFloat(
                                    (this.state.extra_discount_amount *
                                        parseFloat(
                                            this.state.configuration_total_price -
                                            (this.state.configuration_total_price *
                                                this.state.discount_percentage) /
                                            100 -
                                            this.state.discount_amount -
                                            this.state.dealer_discount_amount -
                                            this.state.oem_discount_amount
                                        ).toFixed(2)) /
                                    100
                                ).toFixed(2)
                            )) *
                        100
                    ).toFixed(2);

                    if (sales_margin > 0) {
                        that.setState({sales_margin: sales_margin});
                    } else {
                        that.setState({sales_margin: 0});
                    }
                }
                break;

            default:
                break;
        }
    }

    onBack() {
        this.props.onComponentChange("seconForm");
    }

    async componentDidMount() {
        let that = this;

        if (this.props.second_form && this.props.first_form) {
            let total_price = 0;
            let total_cost_price = 0;

            await this.props.second_form.manual_adjustments.forEach(element => {
                total_price =
                    total_price + parseFloat(element.manual_adjustment_price *
                        element.manual_adjustment_quantity);
                total_cost_price =
                    total_cost_price +
                    parseFloat(
                        element.manual_adjustment_price *
                        element.manual_adjustment_quantity
                    );
            });

            await this.props.second_form.compatible_machine_parts.forEach(
                element => {
                    // if (element.is_included === 0) {
                    total_price =
                        total_price + parseFloat(element.price * element.quantity);
                    total_cost_price =
                        total_cost_price +
                        parseFloat(element.cost_price * element.quantity);
                    // }
                }
            );

            await Api.get(
                config.APP_URL +
                "/api/machine-category/" +
                this.props.first_form.configuration_category
            )
                .then(async function (response) {
                    if (!that.state.only_parts) {
                        total_price = total_price + parseFloat(response.data[0].price);
                        total_cost_price =
                            total_cost_price + response.data[0].cost_price;
                    }
                    await that.setState({category_details: response.data[0]});
                })
                .catch(function (error) {
                    return false;
                });

            let company_rates = await Api.get("company-rates")
                .then(function (response) {
                    return response.data;
                })
                .catch(function (error) {
                    return false;
                });

            if (company_rates) {
                let company_rate = company_rates.filter(item =>
                    item.rate_name.toLowerCase().includes("handling")
                );
                if (company_rate.length > 0) {
                    that.setState({company_rate: company_rate});
                    total_cost_price =
                        total_cost_price +
                        (company_rate[0].rate_value * total_cost_price) / 100;
                }
            }

            company_rates = await Api.get("company-rates")
                .then(function (response) {
                    return response.data;
                })
                .catch(function (error) {
                    return false;
                });

            if (company_rates) {
                let company_rate = company_rates.filter(item =>
                    item.rate_name.toLowerCase().includes("landed cost")
                );
                if (company_rate.length > 0) {
                    that.setState({landed_cost: company_rate[0].rate_value});
                }
            }

            await that.setState({
                configuration_cost_total_price: total_cost_price
            });
            await that.setState({configuration_total_price: total_price});
            that.setState({
                configuration_gross_total_price: total_price * 0.13 + total_price
            });

            let maximum_available_discount = 0;
            if (
                this.props.max_discount <
                this.props.first_form.max_discount_category
            ) {
                maximum_available_discount =
                    (this.props.max_discount * total_price) / 100;
            } else {
                maximum_available_discount = parseFloat(
                    (this.props.first_form.max_discount_category * total_price) / 100
                ).toFixed(2);
            }

            that.setState({
                maximum_available_discount: maximum_available_discount
            });
        }
        await this.setState({loading: false});
    }

    async onSubmit(e) {
        e.preventDefault();
        let that = this;
        let params = false;
        if (this.props.is_support) {
            params = {
                user_id: this.props.user_id,
                configuration_name: that.state.configuration_name,
                only_parts: that.state.only_parts,
                machine_name: that.state.configuration_name,
                machine_total_price: that.state.configuration_total_price,
                machine_category: that.state.machine_category,
                machine_category_year_price_count:
                that.state.category_details.year_price_count,
                machine_category_cost_year_price_count:
                that.state.category_details.cost_year_price_count,
                discount_amount: that.state.discount_amount,
                dealer_discount_amount: that.state.dealer_discount_amount,
                oem_discount: that.state.oem_discount_amount,
                extra_discount: that.state.extra_discount_amount,
                sales_margin: that.state.sales_margin,
                sales_margin_amount: that.state.sales_margin_amount,
                end_user_sales_price: that.state.end_user_sales_price,
                discount_percentage: that.state.discount_percentage,
                trade_in_value: that.state.trade_in_value,
                quantity: that.state.configuration_quantity,
                selected_parts: that.state.selected_parts,
                manual_adjustments: that.state.manualAdjustments,
                country_id: that.state.country_id
            };
        } else {
            params = {
                user_id: this.props.user_id,
                configuration_name: that.state.configuration_name,
                only_parts: that.state.only_parts,
                machine_name: that.state.configuration_name,
                machine_total_price: that.state.configuration_total_price,
                machine_category: that.state.machine_category,
                machine_category_year_price_count:
                that.state.category_details.year_price_count,
                machine_category_cost_year_price_count:
                that.state.category_details.cost_year_price_count,
                discount_amount: that.state.discount_amount,
                dealer_discount_amount: that.state.dealer_discount_amount,
                oem_discount: that.state.oem_discount_amount,
                trade_in_value: that.state.trade_in_value,
                extra_discount: that.state.extra_discount_amount,
                sales_margin: that.state.sales_margin,
                sales_margin_amount: that.state.sales_margin_amount,
                end_user_sales_price: that.state.end_user_sales_price,
                discount_percentage: that.state.discount_percentage,
                quantity: that.state.configuration_quantity,
                selected_parts: that.state.selected_parts,
                manual_adjustments: that.state.manualAdjustments,
                country_id: that.state.country_id
            };
        }

        if (this.props.from) {
            if (this.props.from.toLowerCase().includes("library")) {
                await this.props.edit_library_configuration(
                    this.props.id,
                    params,
                    this.props.is_support
                );
            } else {
                await this.props.edit_configuration(
                    this.props.id,
                    params,
                    this.props.is_support,
                    this.props.from
                );
            }
        } else {
            await this.props.edit_configuration(
                this.props.id,
                params,
                this.props.is_support,
                this.props.from
            );
        }
    }

    render() {
        strings.setLanguage(this.props.language);
        const override = css`
          display: block;
          margin: 0 auto;
          border-color: red;
        `;
        if (this.props.loading || this.state.loading) {
            return (
                <div className="sweet-loading">
                    <div className="container loading-container">
                        <div className="row m-5 p-5">
                            <PulseLoader
                                css={override}
                                sizeUnit={"px"}
                                size={24}
                                color={"#B9373D"}
                            />
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    <div className=" m-4 row">
                        <div className="col"></div>
                        <div className="col-12">
                            <h4 className="mb-4 client-roboto-condensed font-weight-bold text-center">
                                Confirm Configuration
                            </h4>

                            {this.props.change_view ? (
                                <button
                                    className="client-login px-5 my-3"
                                    onClick={() =>
                                        this.setState({
                                            is_dealer: !this.state.is_dealer
                                        })
                                    }
                                >
                                    Change View
                                </button>
                            ) : null}
                            <div className="client-config-details">
                                <Descriptions
                                    title=""
                                    layout="hotizontal"
                                    bordered
                                    size="small"
                                    column={1}
                                >
                                    <Descriptions.Item label="Configuration Name">
                                        {this.state.configuration_name}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Machine Category">
                                        {this.state.configuration_category}
                                    </Descriptions.Item>
                                    {!this.props.role
                                        .toLowerCase()
                                        .includes("customer") ? (
                                        <>
                                            <Descriptions.Item label="Retail Price">
                                                {this.state.configuration_total_price.toFixed(
                                                    2
                                                )}{" "}
                                                {getSymbolFromCurrency(
                                                    this.props.country[0]
                                                        .currency_symbol_html
                                                )}
                                            </Descriptions.Item>
                                            {this.state.is_dealer ? (
                                                <>
                                                    <Descriptions.Item label="Standard Dealer Terms %">
                                                        <input
                                                            className="discount-input"
                                                            style={{
                                                                textAlign: "right",
                                                                width: 50
                                                            }}
                                                            onChange={e =>
                                                                this.handleTextChange(
                                                                    e.target.value,
                                                                    "discount_percentage"
                                                                )
                                                            }
                                                            value={
                                                                this.state.discount_percentage
                                                            }
                                                        />{" "}
                                                        %
                                                    </Descriptions.Item>
                                                    <Descriptions.Item label="Dealer Net % Amount">
                                                        {(
                                                            (this.state
                                                                    .configuration_total_price *
                                                                this.state
                                                                    .discount_percentage) /
                                                            100
                                                        ).toFixed(2)}{" "}
                                                        {getSymbolFromCurrency(
                                                            this.props.country[0]
                                                                .currency_symbol_html
                                                        )}
                                                    </Descriptions.Item>
                                                    {/* <Descriptions.Item label="Dealer Discount Amount">
														<input
															className="discount-input"
															style={{
																textAlign: "right",
																width: 60
															}}
															onChange={e =>
																this.handleTextChange(
																	e.target.value,
																	"discount_amount"
																)
															}
															value={this.state.discount_amount}
														/>{" "}
														{getSymbolFromCurrency(
															this.props.country[0]
																.currency_symbol_html
														)}
													</Descriptions.Item> */}
                                                </>
                                            ) : (
                                                <>
                                                    <Descriptions.Item label="Discount Percentage">
                                                        <input
                                                            className="discount-input"
                                                            style={{
                                                                textAlign: "right",
                                                                width: 50
                                                            }}
                                                            onChange={e =>
                                                                this.handleTextChange(
                                                                    e.target.value,
                                                                    "discount_percentage"
                                                                )
                                                            }
                                                            value={
                                                                this.state.discount_percentage
                                                            }
                                                        />{" "}
                                                        %
                                                    </Descriptions.Item>
                                                    <Descriptions.Item label="Discount Percentage Amount">
                                                        {(
                                                            (this.state
                                                                    .configuration_total_price *
                                                                this.state
                                                                    .discount_percentage) /
                                                            100
                                                        ).toFixed(2)}{" "}
                                                        {getSymbolFromCurrency(
                                                            this.props.country[0]
                                                                .currency_symbol_html
                                                        )}
                                                    </Descriptions.Item>
                                                    <Descriptions.Item label="Discount Amount">
                                                        <input
                                                            className="discount-input"
                                                            style={{
                                                                textAlign: "right",
                                                                width: 60
                                                            }}
                                                            onChange={e =>
                                                                this.handleTextChange(
                                                                    e.target.value,
                                                                    "discount_amount"
                                                                )
                                                            }
                                                            value={this.state.discount_amount}
                                                        />{" "}
                                                        {getSymbolFromCurrency(
                                                            this.props.country[0]
                                                                .currency_symbol_html
                                                        )}
                                                    </Descriptions.Item>
                                                </>
                                            )}
                                            {this.state.is_dealer ? (
                                                <Descriptions.Item label="Dealer Net Total">
                                                    {parseFloat(
                                                        this.state.configuration_total_price -
                                                        (this.state
                                                                .configuration_total_price *
                                                            this.state
                                                                .discount_percentage) /
                                                        100 -
                                                        this.state.discount_amount -
                                                        this.state.dealer_discount_amount
                                                    ).toFixed(2)}{" "}
                                                    {getSymbolFromCurrency(
                                                        this.props.country[0]
                                                            .currency_symbol_html
                                                    )}
                                                </Descriptions.Item>
                                            ) : (
                                                <Descriptions.Item label="Net Total">
                                                    {parseFloat(
                                                        this.state.configuration_total_price -
                                                        (this.state
                                                                .configuration_total_price *
                                                            this.state
                                                                .discount_percentage) /
                                                        100 -
                                                        this.state.discount_amount -
                                                        this.state.dealer_discount_amount
                                                    ).toFixed(2)}{" "}
                                                    {getSymbolFromCurrency(
                                                        this.props.country[0]
                                                            .currency_symbol_html
                                                    )}
                                                </Descriptions.Item>
                                            )}
                                            {this.state.is_dealer ? (
                                                this.props.oem_editable ? (
                                                    <>
                                                        <Descriptions.Item label="OEM Discount Amount">
                                                            <input
                                                                className="discount-input"
                                                                style={{
                                                                    textAlign: "right",
                                                                    width: 60
                                                                }}
                                                                onChange={e =>
                                                                    this.handleTextChange(
                                                                        e.target.value,
                                                                        "oem_discount_amount"
                                                                    )
                                                                }
                                                                value={
                                                                    this.state
                                                                        .oem_discount_amount
                                                                }
                                                            />{" "}
                                                            {getSymbolFromCurrency(
                                                                this.props.country[0]
                                                                    .currency_symbol_html
                                                            )}
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label="Net Net Purchase Price">
                                                            {parseFloat(
                                                                this.state
                                                                    .configuration_total_price -
                                                                (this.state
                                                                        .configuration_total_price *
                                                                    this.state
                                                                        .discount_percentage) /
                                                                100 -
                                                                this.state.discount_amount -
                                                                this.state
                                                                    .dealer_discount_amount -
                                                                this.state
                                                                    .oem_discount_amount
                                                            ).toFixed(2)}{" "}
                                                            {getSymbolFromCurrency(
                                                                this.props.country[0]
                                                                    .currency_symbol_html
                                                            )}
                                                        </Descriptions.Item>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Descriptions.Item label="OEM Discount Amount">
                                                            {this.state.oem_discount_amount}{" "}
                                                            {getSymbolFromCurrency(
                                                                this.props.country[0]
                                                                    .currency_symbol_html
                                                            )}
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label="Net Net Purchase Price">
                                                            {parseFloat(
                                                                this.state
                                                                    .configuration_total_price -
                                                                (this.state
                                                                        .configuration_total_price *
                                                                    this.state
                                                                        .discount_percentage) /
                                                                100 -
                                                                this.state.discount_amount -
                                                                this.state
                                                                    .dealer_discount_amount -
                                                                this.state
                                                                    .oem_discount_amount
                                                            ).toFixed(2)}{" "}
                                                            {getSymbolFromCurrency(
                                                                this.props.country[0]
                                                                    .currency_symbol_html
                                                            )}
                                                        </Descriptions.Item>
                                                    </>
                                                )
                                            ) : null}
                                            {this.state.is_dealer ? (
                                                this.props.extra_extra_discount_editable ? (
                                                    <>
                                                        <Descriptions.Item label="Extra Discount">
                                                            <input
                                                                className="discount-input"
                                                                style={{
                                                                    textAlign: "right",
                                                                    width: 60
                                                                }}
                                                                onChange={e =>
                                                                    this.handleTextChange(
                                                                        e.target.value,
                                                                        "extra_discount_amount"
                                                                    )
                                                                }
                                                                value={
                                                                    this.state
                                                                        .extra_discount_amount
                                                                }
                                                            />
                                                            {" %"}
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label="Reesink Extra Extra Support">
                                                            {parseFloat(
                                                                (this.state
                                                                        .extra_discount_amount *
                                                                    parseFloat(
                                                                        this.state
                                                                            .configuration_total_price -
                                                                        (this.state
                                                                                .configuration_total_price *
                                                                            this.state
                                                                                .discount_percentage) /
                                                                        100 -
                                                                        this.state
                                                                            .discount_amount -
                                                                        this.state
                                                                            .dealer_discount_amount -
                                                                        this.state
                                                                            .oem_discount_amount
                                                                    ).toFixed(2)) /
                                                                100
                                                            ).toFixed(2)}{" "}
                                                            {getSymbolFromCurrency(
                                                                this.props.country[0]
                                                                    .currency_symbol_html
                                                            )}
                                                        </Descriptions.Item>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Descriptions.Item label="Extra Discount">
                                                            {this.state.extra_discount_amount}
                                                            {" %"}
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label="Reesink Extra Extra Support">
                                                            {parseFloat(
                                                                (this.state
                                                                        .extra_discount_amount *
                                                                    parseFloat(
                                                                        this.state
                                                                            .configuration_total_price -
                                                                        (this.state
                                                                                .configuration_total_price *
                                                                            this.state
                                                                                .discount_percentage) /
                                                                        100 -
                                                                        this.state
                                                                            .discount_amount -
                                                                        this.state
                                                                            .dealer_discount_amount -
                                                                        this.state
                                                                            .oem_discount_amount
                                                                    ).toFixed(2)) /
                                                                100
                                                            ).toFixed(2)}{" "}
                                                            {getSymbolFromCurrency(
                                                                this.props.country[0]
                                                                    .currency_symbol_html
                                                            )}
                                                        </Descriptions.Item>
                                                    </>
                                                )
                                            ) : null}
                                            {!this.state.is_dealer ? (
                                                this.props.extra_discount_visible ? (
                                                    <Descriptions.Item label="Extra Discount">
                                                        {this.props
                                                            .extra_discount_visible_and_editable ? (
                                                            <input
                                                                className="discount-input"
                                                                style={{
                                                                    textAlign: "right",
                                                                    width: 60
                                                                }}
                                                                onChange={e =>
                                                                    this.handleTextChange(
                                                                        e.target.value,
                                                                        "dealer_discount_amount"
                                                                    )
                                                                }
                                                                value={
                                                                    this.state
                                                                        .dealer_discount_amount
                                                                }
                                                            />
                                                        ) : (
                                                            this.state.dealer_discount_amount
                                                        )}{" "}
                                                        {getSymbolFromCurrency(
                                                            this.props.country[0]
                                                                .currency_symbol_html
                                                        )}
                                                    </Descriptions.Item>
                                                ) : null
                                            ) : null}
                                            {this.state.is_dealer ? (
                                                <Descriptions.Item label="Net Net Net Price">
                                                    {parseFloat(
                                                        this.state.configuration_total_price -
                                                        (this.state
                                                                .configuration_total_price *
                                                            this.state
                                                                .discount_percentage) /
                                                        100 -
                                                        this.state.discount_amount -
                                                        this.state.dealer_discount_amount -
                                                        this.state.oem_discount_amount -
                                                        parseFloat(
                                                            (this.state
                                                                    .extra_discount_amount *
                                                                parseFloat(
                                                                    this.state
                                                                        .configuration_total_price -
                                                                    (this.state
                                                                            .configuration_total_price *
                                                                        this.state
                                                                            .discount_percentage) /
                                                                    100 -
                                                                    this.state
                                                                        .discount_amount -
                                                                    this.state
                                                                        .dealer_discount_amount -
                                                                    this.state
                                                                        .oem_discount_amount
                                                                ).toFixed(2)) /
                                                            100
                                                        ).toFixed(2)
                                                    ).toFixed(2)}{" "}
                                                    {getSymbolFromCurrency(
                                                        this.props.country[0]
                                                            .currency_symbol_html
                                                    )}
                                                </Descriptions.Item>
                                            ) : null}
                                            {this.props.sales_margin_visible ? (
                                                this.state.is_dealer ? (
                                                    <>
                                                        <Descriptions.Item label="End Customer Purchase Price">
                                                            <input
                                                                className="discount-input"
                                                                style={{
                                                                    textAlign: "right",
                                                                    width: 80
                                                                }}
                                                                onChange={e =>
                                                                    this.handleTextChange(
                                                                        e.target.value,
                                                                        "end_user_sales_price"
                                                                    )
                                                                }
                                                                value={
                                                                    this.state
                                                                        .end_user_sales_price
                                                                }
                                                            />{" "}
                                                            {getSymbolFromCurrency(
                                                                this.props.country[0]
                                                                    .currency_symbol_html
                                                            )}
                                                        </Descriptions.Item>
                                                    </>
                                                ) : (
                                                    <>
                                                        {/* <Descriptions.Item label="Sales Price - Customer">
															{this.state.end_user_sales_price}
														</Descriptions.Item> */}
                                                    </>
                                                )
                                            ) : null}
                                            {this.props.sales_margin_visible ? (
                                                this.state.is_dealer ? (
                                                    <>
                                                        <Descriptions.Item label="Dealer Margin Amount">
                                                            {this.state.sales_margin_amount}{" "}
                                                            {getSymbolFromCurrency(
                                                                this.props.country[0]
                                                                    .currency_symbol_html
                                                            )}
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label="Dealer Margin">
                                                            {this.state.sales_margin}
                                                            {" %"}
                                                        </Descriptions.Item>
                                                    </>
                                                ) : (
                                                    <>
                                                        {/* <Descriptions.Item label="Sales Margin">
															<input
																className="discount-input"
																style={{
																	textAlign: "right",
																	width: 60
																}}
																onChange={e =>
																	this.handleTextChange(
																		e.target.value,
																		"sales_margin"
																	)
																}
																value={this.state.sales_margin}
															/>
															{" %"}
														</Descriptions.Item>
														<Descriptions.Item label="Sales Margin Amount">
															<input
																className="discount-input"
																style={{
																	textAlign: "right",
																	width: 60
																}}
																onChange={e =>
																	this.handleTextChange(
																		e.target.value,
																		"sales_margin_amount"
																	)
																}
																value={
																	this.state
																		.sales_margin_amount
																}
															/>{" "}
															{getSymbolFromCurrency(
																this.props.country[0]
																	.currency_symbol_html
															)}
														</Descriptions.Item> */}
                                                    </>
                                                )
                                            ) : null}
                                            {this.props.customer_discount_visible &&
                                            !this.state.is_dealer ? (
                                                <Descriptions.Item label="Customer Discount">
                                                    {parseFloat(
                                                        (Math.abs(
                                                                this.state
                                                                    .configuration_total_price -
                                                                parseFloat(
                                                                    this.state
                                                                        .configuration_total_price -
                                                                    (this.state
                                                                            .configuration_total_price *
                                                                        this.state
                                                                            .discount_percentage) /
                                                                    100 -
                                                                    this.state
                                                                        .discount_amount -
                                                                    this.state
                                                                        .dealer_discount_amount -
                                                                    this.state
                                                                        .oem_discount_amount -
                                                                    parseFloat(
                                                                        (this.state
                                                                                .extra_discount_amount *
                                                                            parseFloat(
                                                                                this.state
                                                                                    .configuration_total_price -
                                                                                (this.state
                                                                                        .configuration_total_price *
                                                                                    this.state
                                                                                        .discount_percentage) /
                                                                                100 -
                                                                                this.state
                                                                                    .discount_amount -
                                                                                this.state
                                                                                    .dealer_discount_amount -
                                                                                this.state
                                                                                    .oem_discount_amount
                                                                            ).toFixed(2)) /
                                                                        100
                                                                    ).toFixed(2) +
                                                                    (this.state.sales_margin *
                                                                        (this.state
                                                                                .configuration_total_price -
                                                                            (this.state
                                                                                    .configuration_total_price *
                                                                                this.state
                                                                                    .discount_percentage) /
                                                                            100 -
                                                                            this.state
                                                                                .discount_amount -
                                                                            this.state
                                                                                .dealer_discount_amount -
                                                                            this.state
                                                                                .oem_discount_amount -
                                                                            parseFloat(
                                                                                (this.state
                                                                                        .extra_discount_amount *
                                                                                    parseFloat(
                                                                                        this.state
                                                                                            .configuration_total_price -
                                                                                        (this
                                                                                                .state
                                                                                                .configuration_total_price *
                                                                                            this
                                                                                                .state
                                                                                                .discount_percentage) /
                                                                                        100 -
                                                                                        this
                                                                                            .state
                                                                                            .discount_amount -
                                                                                        this
                                                                                            .state
                                                                                            .dealer_discount_amount -
                                                                                        this
                                                                                            .state
                                                                                            .oem_discount_amount
                                                                                    ).toFixed(
                                                                                        2
                                                                                    )) /
                                                                                100
                                                                            ).toFixed(2))) /
                                                                    100
                                                                ).toFixed(2)
                                                            ) /
                                                            this.state
                                                                .configuration_total_price) *
                                                        100
                                                    ).toFixed(2)}
                                                    {" %"}
                                                </Descriptions.Item>
                                            ) : null}
                                        </>
                                    ) : null}
                                    <Descriptions.Item label="Configuration Quantity">
                                        {!this.props.role
                                            .toLowerCase()
                                            .includes("customer") ? (
                                            <span>
												<div
                                                    className="client-counter row"
                                                    style={{
                                                        marginRight: -8
                                                    }}
                                                >
													<button
                                                        onClick={e =>
                                                            this.onQuantityChange(
                                                                this.state
                                                                    .configuration_quantity,
                                                                "minus"
                                                            )
                                                        }
                                                        className="plus-minus"
                                                    >
														-
													</button>
													<input
                                                        readOnly
                                                        type="number"
                                                        min="0"
                                                        max="20"
                                                        value={
                                                            this.state.configuration_quantity <
                                                            20
                                                                ? "0" +
                                                                this.state
                                                                    .configuration_quantity
                                                                : this.state
                                                                    .configuration_quantity
                                                        }
                                                    />
													<button
                                                        onClick={e =>
                                                            this.onQuantityChange(
                                                                this.state
                                                                    .configuration_quantity,
                                                                "plus"
                                                            )
                                                        }
                                                        className="plus-minus"
                                                    >
														+
													</button>
												</div>
											</span>
                                        ) : (
                                            <span>
												<input
                                                    readOnly
                                                    type="number"
                                                    min="0"
                                                    max="20"
                                                    value={
                                                        this.state.configuration_quantity < 20
                                                            ? "0" +
                                                            this.state.configuration_quantity
                                                            : this.state.configuration_quantity
                                                    }
                                                />
											</span>
                                        )}
                                    </Descriptions.Item>
                                    {this.props.cost_price_visible &&
                                    !this.state.is_dealer ? (
                                        <Descriptions.Item label="Total Cost [all machines]">
                                            {(
                                                this.state.configuration_quantity *
                                                parseFloat(
                                                    this.state.configuration_cost_total_price
                                                ).toFixed(2)
                                            ).toFixed(2)}{" "}
                                            {getSymbolFromCurrency(
                                                this.props.country[0].currency_symbol_html
                                            )}
                                        </Descriptions.Item>
                                    ) : null}
                                    {!this.state.is_dealer ? (
                                        <Descriptions.Item label="Total price [all machines]">
                                            {(
                                                this.state.configuration_quantity *
                                                parseFloat(
                                                    this.state.configuration_total_price -
                                                    (this.state
                                                            .configuration_total_price *
                                                        this.state.discount_percentage) /
                                                    100 -
                                                    this.state.discount_amount -
                                                    this.state.dealer_discount_amount -
                                                    this.state.oem_discount_amount -
                                                    parseFloat(
                                                        (this.state.extra_discount_amount *
                                                            parseFloat(
                                                                this.state
                                                                    .configuration_total_price -
                                                                (this.state
                                                                        .configuration_total_price *
                                                                    this.state
                                                                        .discount_percentage) /
                                                                100 -
                                                                this.state
                                                                    .discount_amount -
                                                                this.state
                                                                    .dealer_discount_amount -
                                                                this.state
                                                                    .oem_discount_amount
                                                            ).toFixed(2)) /
                                                        100
                                                    ).toFixed(2)
                                                ).toFixed(2)
                                            ).toFixed(2)}{" "}
                                            {getSymbolFromCurrency(
                                                this.props.country[0].currency_symbol_html
                                            )}
                                        </Descriptions.Item>
                                    ) : null}
                                    {this.props.profit_margin_visible &&
                                    !this.state.is_dealer ? (
                                        <Descriptions.Item label="Total Margin [all machines]">
                                            {(
                                                (((
                                                            this.state.configuration_quantity *
                                                            parseFloat(
                                                                this.state.configuration_total_price -
                                                                (this.state
                                                                        .configuration_total_price *
                                                                    this.state
                                                                        .discount_percentage) /
                                                                100 -
                                                                this.state.discount_amount -
                                                                this.state.dealer_discount_amount -
                                                                this.state.oem_discount_amount -
                                                                parseFloat(
                                                                    (this.state
                                                                            .extra_discount_amount *
                                                                        parseFloat(
                                                                            this.state
                                                                                .configuration_total_price -
                                                                            (this.state
                                                                                    .configuration_total_price *
                                                                                this.state
                                                                                    .discount_percentage) /
                                                                            100 -
                                                                            this.state
                                                                                .discount_amount -
                                                                            this.state
                                                                                .dealer_discount_amount -
                                                                            this.state
                                                                                .oem_discount_amount
                                                                        ).toFixed(2)) /
                                                                    100
                                                                ).toFixed(2)
                                                            ).toFixed(2)
                                                        ).toFixed(2) -
                                                        (
                                                            this.state.configuration_quantity *
                                                            parseFloat(
                                                                this.state
                                                                    .configuration_cost_total_price
                                                            ).toFixed(2)
                                                        ).toFixed(2)) /
                                                    (
                                                        this.state.configuration_quantity *
                                                        parseFloat(
                                                            this.state
                                                                .configuration_total_price -
                                                            (this.state
                                                                    .configuration_total_price *
                                                                this.state
                                                                    .discount_percentage) /
                                                            100 -
                                                            this.state.discount_amount -
                                                            this.state
                                                                .dealer_discount_amount -
                                                            this.state.oem_discount_amount -
                                                            parseFloat(
                                                                (this.state
                                                                        .extra_discount_amount *
                                                                    parseFloat(
                                                                        this.state
                                                                            .configuration_total_price -
                                                                        (this.state
                                                                                .configuration_total_price *
                                                                            this.state
                                                                                .discount_percentage) /
                                                                        100 -
                                                                        this.state
                                                                            .discount_amount -
                                                                        this.state
                                                                            .dealer_discount_amount -
                                                                        this.state
                                                                            .oem_discount_amount
                                                                    ).toFixed(2)) /
                                                                100
                                                            ).toFixed(2)
                                                        ).toFixed(2)
                                                    ).toFixed(2)) *
                                                100
                                            ).toFixed(2) + " %"}
                                        </Descriptions.Item>
                                    ) : null}
                                    {this.props.retail_margin ? (
                                        this.state.landed_cost ? (
                                            <>
                                                <Descriptions.Item label="Retail Margin">
                                                    {parseFloat(
                                                        ((parseFloat(
                                                                    this.state
                                                                        .configuration_total_price -
                                                                    (this.state
                                                                            .configuration_total_price *
                                                                        this.state
                                                                            .discount_percentage) /
                                                                    100 -
                                                                    this.state.discount_amount -
                                                                    this.state
                                                                        .dealer_discount_amount -
                                                                    this.state.oem_discount_amount -
                                                                    parseFloat(
                                                                        (this.state
                                                                                .extra_discount_amount *
                                                                            parseFloat(
                                                                                this.state
                                                                                    .configuration_total_price -
                                                                                (this.state
                                                                                        .configuration_total_price *
                                                                                    this.state
                                                                                        .discount_percentage) /
                                                                                100 -
                                                                                this.state
                                                                                    .discount_amount -
                                                                                this.state
                                                                                    .dealer_discount_amount -
                                                                                this.state
                                                                                    .oem_discount_amount
                                                                            ).toFixed(2)) /
                                                                        100
                                                                    ).toFixed(2)
                                                                ).toFixed(2) -
                                                                parseFloat(
                                                                    parseFloat(
                                                                        this.state
                                                                            .configuration_cost_total_price
                                                                    ).toFixed(2)
                                                                ).toFixed(2) -
                                                                (parseFloat(
                                                                        parseFloat(
                                                                            this.state
                                                                                .configuration_cost_total_price
                                                                        ).toFixed(2)
                                                                    ).toFixed(2) *
                                                                    this.state.landed_cost) /
                                                                100) /
                                                            parseFloat(
                                                                this.state
                                                                    .configuration_total_price -
                                                                (this.state
                                                                        .configuration_total_price *
                                                                    this.state
                                                                        .discount_percentage) /
                                                                100 -
                                                                this.state.discount_amount -
                                                                this.state
                                                                    .dealer_discount_amount -
                                                                this.state
                                                                    .oem_discount_amount -
                                                                parseFloat(
                                                                    (this.state
                                                                            .extra_discount_amount *
                                                                        parseFloat(
                                                                            this.state
                                                                                .configuration_total_price -
                                                                            (this.state
                                                                                    .configuration_total_price *
                                                                                this.state
                                                                                    .discount_percentage) /
                                                                            100 -
                                                                            this.state
                                                                                .discount_amount -
                                                                            this.state
                                                                                .dealer_discount_amount -
                                                                            this.state
                                                                                .oem_discount_amount
                                                                        ).toFixed(2)) /
                                                                    100
                                                                ).toFixed(2)
                                                            ).toFixed(2)) *
                                                        100
                                                    ).toFixed(2)}
                                                    {" %"}
                                                    {/* {parseFloat(
														((parseFloat(
															this.state
																.configuration_total_price -
															(this.state
																.configuration_total_price *
																this.state
																	.discount_percentage) /
															100 -
															this.state.discount_amount -
															this.state
																.dealer_discount_amount -
															this.state.oem_discount_amount -
															parseFloat(
																(this.state
																	.extra_discount_amount *
																	parseFloat(
																		this.state
																			.configuration_total_price -
																		(this.state
																			.configuration_total_price *
																			this.state
																				.discount_percentage) /
																		100 -
																		this.state
																			.discount_amount -
																		this.state
																			.dealer_discount_amount -
																		this.state
																			.oem_discount_amount
																	).toFixed(2)) /
																100
															).toFixed(2)
														).toFixed(2) -
															parseFloat(
																parseFloat(
																	this.state
																		.configuration_cost_total_price
																).toFixed(2)
															).toFixed(2) -
															(parseFloat(
																parseFloat(
																	this.state
																		.configuration_cost_total_price
																).toFixed(2)
															).toFixed(2) *
																this.state.landed_cost) /
															100) /
															parseFloat(
																this.state
																	.configuration_total_price -
																(this.state
																	.configuration_total_price *
																	this.state
																		.discount_percentage) /
																100 -
																this.state.discount_amount -
																this.state
																	.dealer_discount_amount -
																this.state
																	.oem_discount_amount -
																parseFloat(
																	(this.state
																		.extra_discount_amount *
																		parseFloat(
																			this.state
																				.configuration_total_price -
																			(this.state
																				.configuration_total_price *
																				this.state
																					.discount_percentage) /
																			100 -
																			this.state
																				.discount_amount -
																			this.state
																				.dealer_discount_amount -
																			this.state
																				.oem_discount_amount
																		).toFixed(2)) /
																	100
																).toFixed(2)
															).toFixed(2)) *
														100
													).toFixed(2)}
													{" %"} */}
                                                </Descriptions.Item>
                                            </>
                                        ) : null
                                    ) : null}
                                    <Descriptions.Item label="Parts exchange [trade-in]">
                                        <input
                                            className="discount-input"
                                            style={{
                                                textAlign: "right",
                                                width: 100
                                            }}
                                            onChange={e =>
                                                this.handleTextChange(
                                                    e.target.value,
                                                    "trade_in_value"
                                                )
                                            }
                                            value={
                                                this.state.trade_in_value
                                            }
                                        />{" "}
                                        {getSymbolFromCurrency(
                                            this.props.country[0].currency_symbol_html
                                        )}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Price for the customer [sales price - part exchange]">
                                        {(
                                            this.state.configuration_quantity *
                                            parseFloat(
                                                this.state.configuration_total_price -
                                                (this.state
                                                        .configuration_total_price *
                                                    this.state.discount_percentage) /
                                                100 -
                                                this.state.discount_amount -
                                                this.state.dealer_discount_amount -
                                                this.state.oem_discount_amount -
                                                parseFloat(
                                                    (this.state.extra_discount_amount *
                                                        parseFloat(
                                                            this.state
                                                                .configuration_total_price -
                                                            (this.state
                                                                    .configuration_total_price *
                                                                this.state
                                                                    .discount_percentage) /
                                                            100 -
                                                            this.state
                                                                .discount_amount -
                                                            this.state
                                                                .dealer_discount_amount -
                                                            this.state
                                                                .oem_discount_amount
                                                        ).toFixed(2)) /
                                                    100
                                                ).toFixed(2)
                                            ).toFixed(2)
                                            - this.state.trade_in_value
                                        ).toFixed(2)} {" "}
                                        {getSymbolFromCurrency(
                                            this.props.country[0].currency_symbol_html
                                        )}
                                    </Descriptions.Item>
                                </Descriptions>

                                <div className="selected-parts mt-5">
                                    <table className="table table-striped">
                                        <thead className="thead-dark">
                                        <tr>
                                            {/* <th scope="col">#</th> */}
                                            <th scope="col">Model Number</th>
                                            <th scope="col">Part Name</th>
                                            <th scope="col">Remarks</th>
                                            <th scope="col">Note</th>
                                            {this.props.cost_price_visible ? (
                                                <th scope="col">Cost Price</th>
                                            ) : null}
                                            <th scope="col">Price</th>
                                            <th scope="col">Quantity</th>
                                            <th scope="col">Total Price</th>
                                        </tr>
                                        <tr
                                            style={{
                                                backgroundColor: "#B9373D",
                                                color: "#ffffff"
                                            }}
                                        >
                                            <td
                                                style={{
                                                    color: "white",
                                                    fontWeight: 900,
                                                    fontSize: 15
                                                }}
                                            >
                                                Components
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            {this.props.cost_price_visible ? (
                                                <td></td>
                                            ) : null}
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            {/* <td></td> */}
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            {/* <td scope="col" className="min-width-table">#</td> */}
                                            <td className="min-width-table">
                                                {this.state.category_details &&
                                                this.state.category_details !== null
                                                    ? this.state.category_details
                                                        .machine_category_model_number
                                                    : "#"}
                                            </td>
                                            <td className="min-width-table">
                                                {this.state.category_details
                                                    ? this.state.category_details
                                                        .machine_category
                                                    : null}
                                            </td>
                                            <td className="min-width-table"></td>
                                            <td className="min-width-table"></td>
                                            {this.props.cost_price_visible ? (
                                                <td className="money">
                                                    {getSymbolFromCurrency(
                                                        this.state.category_details
                                                            .currency_symbol_html
                                                    )}{" "}
                                                    {this.state.category_details
                                                        ? parseFloat(
                                                            this.state.category_details
                                                                .cost_price
                                                        )
                                                        : null}
                                                </td>
                                            ) : null}
                                            <td className="money min-width-table">
                                                {getSymbolFromCurrency(
                                                    this.state.category_details
                                                        .currency_symbol_html
                                                )}{" "}
                                                {this.state.category_details
                                                    ? parseFloat(
                                                        this.state.category_details
                                                            .price
                                                    ).toFixed(2)
                                                    : null}
                                            </td>
                                            <td>{this.props.first_form ?
                                                this.props.first_form.only_parts ? 0 : 1 : 1}</td>
                                            <td className="money">
                                                {getSymbolFromCurrency(
                                                    this.state.category_details
                                                        .currency_symbol_html
                                                )}{" "}
                                                {
                                                    this.state.category_details ?
                                                        this.props.first_form ?
                                                            this.props.first_form.only_parts ? 0 : parseFloat(this.state.category_details.price).toFixed(2) : parseFloat(this.state.category_details.price).toFixed(2)
                                                        : null
                                                }
                                                {/* {this.state.category_details
														? parseFloat(
															this.state.category_details
																.price
														)
														: null} */}
                                            </td>
                                        </tr>
                                        <tr
                                            style={{
                                                backgroundColor: "#B9373D",
                                                color: "#ffffff"
                                            }}
                                        >
                                            <td
                                                style={{
                                                    color: "white",
                                                    fontWeight: 900,
                                                    fontSize: 15
                                                }}
                                            >
                                                Parts
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            {this.props.cost_price_visible ? (
                                                <td></td>
                                            ) : null}
                                            <td></td>
                                            <td></td>
                                            {/* <td></td> */}
                                        </tr>
                                        {this.state.selected_parts.map((parts, i) => (
                                            <tr key={i}>
                                                {/* <th scope="row">{i+1}</th> */}
                                                <td>
                                                    {parts.machine_part_model_number}
                                                </td>
                                                <td>{parts.machine_part_name}</td>
                                                <td>
                                                    {parts.custom_remark === "" ||
                                                    parts.custom_remark === null
                                                        ? parts.machine_part_remarks !==
                                                        null
                                                            ? parts.machine_part_remarks
                                                            : null
                                                        : parts.custom_remark}
                                                </td>
                                                <td>
                                                    {parts.custom_note === "" ||
                                                    parts.custom_note === null
                                                        ? parts.machine_part_note !== null
                                                            ? parts.machine_part_note
                                                            : null
                                                        : parts.custom_note}
                                                </td>
                                                {this.props.cost_price_visible ? (
                                                    parts.is_included ? (
                                                        <td>Included</td>
                                                    ) : (
                                                        <td className="money">
                                                            {getSymbolFromCurrency(
                                                                parts.currency_symbol_html
                                                            )}{" "}
                                                            {this.state.category_details
                                                                ? parseFloat(
                                                                    parts.cost_price
                                                                )
                                                                : null}
                                                        </td>
                                                    )
                                                ) : null}
                                                {parts.is_included ? (
                                                    <>
                                                        <td>Included</td>
                                                        <td>{parts.quantity}</td>
                                                        <td>Included</td>
                                                    </>
                                                ) : (
                                                    <>
                                                        <td>
                                                            {getSymbolFromCurrency(
                                                                parts.currency_symbol_html
                                                            )}{" "}
                                                            {parts.price.toFixed(2)}
                                                        </td>
                                                        <td>{parts.quantity}</td>
                                                        <td>
                                                            {getSymbolFromCurrency(
                                                                parts.currency_symbol_html
                                                            )}{" "}
                                                            {parts.total_price.toFixed(2)}
                                                        </td>
                                                    </>
                                                )}
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>

                                    <table className="table table-striped table-responsive-md mt-5">
                                        <tbody>
                                        {this.state.manualAdjustments.length > 0 ? (
                                            <>
                                                <tr
                                                    style={{
                                                        backgroundColor: "#B9373D",
                                                        color: "#ffffff"
                                                    }}
                                                >
                                                    <td
                                                        style={{
                                                            color: "white",
                                                            fontWeight: 900,
                                                            fontSize: 15
                                                        }}
                                                    >
                                                        Manual Adjustments
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    {this.props.cost_price_visible ? (
                                                        <td></td>
                                                    ) : null}
                                                    {/* <td></td> */}
                                                    {/* <td></td> */}
                                                    <td></td>
                                                    <td></td>
                                                    {/* <td>
                                                            </td> */}
                                                </tr>
                                                <tr className="thead-dark">
                                                    <th scope="col">Model Number</th>
                                                    <th scope="col">Description</th>
                                                    {/* <th scope="col">Priority</th> */}
                                                    <th scope="col">Note</th>
                                                    {/* <th scope="col"></th> */}
                                                    {this.props.cost_price_visible ? (
                                                        <th></th>
                                                    ) : null}
                                                    <th scope="col">Price</th>
                                                    <th scope="col">Quantity</th>
                                                    <th scope="col">Total Price</th>
                                                    {/* <th scope="col">Actions</th> */}
                                                </tr>
                                            </>
                                        ) : null}
                                        {this.state.manualAdjustments !== null &&
                                        this.state.manualAdjustments !== []
                                            ? this.state.manualAdjustments.map(
                                                (adjustment, i) => (
                                                    <tr key={i}>
                                                        <td className="min-width-table">
                                                            {" "}
                                                            {
                                                                adjustment.manual_adjustment_model_number
                                                            }
                                                        </td>
                                                        <td className="min-width-table">
                                                            {
                                                                adjustment.manual_adjustment_name
                                                            }
                                                        </td>
                                                        <td className="min-width-table">
                                                            {
                                                                adjustment.manual_adjustment_note
                                                            }
                                                        </td>
                                                        {/* <td scope="col" className="min-width-table"></td> */}
                                                        {this.props
                                                            .cost_price_visible ? (
                                                            <td></td>
                                                        ) : null}
                                                        <td className="money min-width-table">
                                                            {getSymbolFromCurrency(
                                                                adjustment.currency_symbol_html
                                                            )}{" "}
                                                            {parseFloat(
                                                                adjustment.manual_adjustment_price
                                                            )}
                                                        </td>
                                                        <td>
                                                            <div className="client-counter row">
                                                                {" "}
                                                                {
                                                                    adjustment.manual_adjustment_quantity
                                                                }
                                                            </div>
                                                        </td>
                                                        <td className="money">
                                                            {getSymbolFromCurrency(
                                                                adjustment.currency_symbol_html
                                                            )}
                                                            {(
                                                                parseFloat(
                                                                    adjustment.manual_adjustment_quantity
                                                                ) *
                                                                parseFloat(
                                                                    adjustment.manual_adjustment_price
                                                                )
                                                            ).toFixed(2)}
                                                        </td>
                                                        {/* <td><button className="plus-minus" onClick={()=>this.onAdjustMentDelete(i)}>X</button></td> */}
                                                    </tr>
                                                )
                                            )
                                            : null}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col"></div>
                    </div>
                    <div className="text-center">
                        <button
                            onClick={this.onBack}
                            type="submit"
                            className="client-roboto-condensed text-uppercase client-login mr-2 ml-2 px-5"
                        >
                            Back
                        </button>
                        <button
                            onClick={this.onSubmit}
                            type="submit"
                            className="client-roboto-condensed text-uppercase client-login mr-2 ml-2 px-5"
                        >
                            Confirm
                        </button>
                    </div>
                </div>
            );
        }
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loginReducer.loading,
        role: state.loginReducer.user.role,
        user_id: state.loginReducer.user.user.id,
        first_form: state.clientConfigurationReducer.first_form,
        configuration:
        state.clientConfigurationReducer.configuration.configuration,
        second_form: state.clientConfigurationReducer.second_form,
        message: state.clientConfigurationReducer.message,
        language: state.languageReducer.selectedLanguage,
        cost_price_visible: state.loginReducer.user.cost_price_visible,
        country_id: state.loginReducer.user.user.country_id,
        country: state.loginReducer.user.country,
        max_discount: state.loginReducer.user.max_discount,
        is_support: state.loginReducer.user.is_support,
        profit_margin_visible: state.loginReducer.user.profit_margin_visible,
        is_dealer: state.loginReducer.user.is_dealer,
        sales_margin_visible: state.loginReducer.user.sales_margin_visible,
        customer_discount_visible:
        state.loginReducer.user.customer_discount_visible,
        extra_discount_visible: state.loginReducer.user.extra_discount_visible,
        extra_discount_visible_and_editable:
        state.loginReducer.user.extra_discount_visible_and_editable,
        oem_editable: state.loginReducer.user.oem_editable,
        extra_extra_discount_editable:
        state.loginReducer.user.extra_extra_discount_editable,
        change_view: state.loginReducer.user.change_view,
        retail_margin: state.loginReducer.user.retail_margin
    };
};

export default connect(mapStateToProps, {
    edit_configuration,
    edit_library_configuration
})(editThirdForm);
