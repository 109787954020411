import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ClientConfigurationService } from "./../service";
import noimage from "./../../../CPQ/noimage.png";
import { css } from "@emotion/react";
import { PulseLoader } from "react-spinners";
import config from "./../../../config/config";
import { notification } from "antd";

class LibraryConfigurations extends Component {
	constructor(props) {
		super(props);
		this.state = {
			configurations: false,
			search_configurations: false,
			search: "",
			total_shown_configuration: 9,
		};
		this.onSearchChange = this.onSearchChange.bind(this);
	}

	onSearchChange(value) {
		let redirect = this;
		redirect.setState({ search: value });

		let parts = redirect.state.configurations;
		parts = parts.filter(item =>
			item.name.toLowerCase().includes(value.toLowerCase())
		);

		redirect.setState({ search_configurations: parts });
		if (parts.length === 0) {
			notification.info({
				message: "Configurations Not Found!",
				description: "Configurations with the given name doesn't exsist"
			});
		}
	}

	async componentDidMount() {
		let redirect = this;

		let configurations = await ClientConfigurationService.showLibraryConfigurations(
			this.props.user_id
		);

		redirect.setState({ configurations: configurations });
		redirect.setState({ search_configurations: configurations });
		if (configurations === undefined) {
			notification.success({
				message: "Success!",
				description: "No configurations found."
			});
		} else {
			// notification.info({
			// 	message: "Success!",
			// 	description: "Configurations fetch successful."
			// });
		}
	}

	render() {
		const override = css`
			display: block;
			margin: 0 auto;
			border-color: red;
		`;
		if (!this.state.search_configurations || this.props.loginLoading) {
			return (
				<div className="sweet-loading">
					<div className="container loading-container">
						<div className="row m-5 p-5">
							<PulseLoader
								css={override}
								sizeUnit={"px"}
								size={24}
								color={"#B9373D"}
							/>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div>
					<div className="my-4 client-roboto-condensed container">
						<div className="text-center my-5">
							<h4
								className="my-4"
								style={{
									fontWeight: 900
								}}
							>
								My Library
							</h4>
						</div>
						<div className="row my-auto">
							<div className="col-sm"></div>
							<div className="col-sm"></div>
							<div className="col-sm"></div>

							<div className="col-sm">
								<div className="form-group">
									<input
										className="form-control"
										type="text"
										style={{
											width: 200
										}}
										onChange={e =>
											this.onSearchChange(e.target.value)
										}
										value={this.props.search}
										placeholder="Search Configurations"
									/>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-sm">
								<div className="client-cards-list">
									{this.state.search_configurations
										? this.state.search_configurations.map(
											(configuration, i) =>
												i <
													this.state.total_shown_configuration ? (

														<Link
															to={
																"/library-configuration/" +
																configuration.id
															}
															style={{
																marginTop: 20,
																marginBottom: 20
															}}
														>
															<div
																id={"curve-" + i}
																className="card"
																style={
																	configuration.machine_category_image !==
																		"" &&
																		configuration.machine_category_image
																		? {
																			backgroundImage:
																				"url(" +
																				config.APP_URL +
																				"/api/storage/" +
																				configuration.machine_category_image +
																				")",
																			height: 200,
																			width: 200
																		}
																		: {
																			backgroundImage:
																				"url(" +
																				noimage +
																				")",
																			height: 200,
																			width: 200
																		}
																}
															>
																<div className="footer">
																	<svg id={"curve-" + i}>
																		<path
																			id={"p" + i}
																			d="M0,200 Q80,100 400,200 V150 H0 V50"
																			transform="translate(0 300)"
																		/>
																		<rect
																			id={"dummyRect" + i}
																			x="0"
																			y="0"
																			height="450"
																			width="400"
																			fill="transparent"
																		/>
																		<animate
																			xlinkHref={"#p" + i}
																			attributeName="d"
																			to="M0,50 Q80,100 400,50 V150 H0 V50"
																			fill="freeze"
																			begin={
																				"dummyRect" +
																				i +
																				".mouseover"
																			}
																			end={
																				"dummyRect" +
																				i +
																				".mouseout"
																			}
																			dur="0.1s"
																			id={"bounce1" + i}
																		/>
																		<animate
																			xlinkHref={"#p" + i}
																			attributeName="d"
																			to="M0,50 Q80,0 400,50 V150 H0 V50"
																			fill="freeze"
																			begin={
																				"bounce1" +
																				i +
																				".end"
																			}
																			end={
																				"dummyRect" +
																				i +
																				".mouseout"
																			}
																			dur="0.15s"
																			id={"bounce2" + i}
																		/>
																		<animate
																			xlinkHref={"#p" + i}
																			attributeName="d"
																			to="M0,50 Q80,80 400,50 V150 H0 V50"
																			fill="freeze"
																			begin={
																				"bounce2" +
																				i +
																				".end"
																			}
																			end={
																				"dummyRect" +
																				i +
																				".mouseout"
																			}
																			dur="0.15s"
																			id={"bounce3" + i}
																		/>
																		<animate
																			xlinkHref={"#p" + i}
																			attributeName="d"
																			to="M0,50 Q80,45 400,50 V150 H0 V50"
																			fill="freeze"
																			begin={
																				"bounce3" +
																				i +
																				".end"
																			}
																			end={
																				"dummyRect" +
																				i +
																				".mouseout"
																			}
																			dur="0.1s"
																			id={"bounce4" + i}
																		/>
																		<animate
																			xlinkHref={"#p" + i}
																			attributeName="d"
																			to="M0,50 Q80,50 400,50 V150 H0 V50"
																			fill="freeze"
																			begin={
																				"bounce4" +
																				i +
																				".end"
																			}
																			end={
																				"dummyRect" +
																				i +
																				".mouseout"
																			}
																			dur="0.05s"
																			id={"bounce5" + i}
																		/>

																		<animate
																			xlinkHref={"#p" + i}
																			attributeName="d"
																			to="M0,200 Q80,100 400,200 V150 H0 V50"
																			fill="freeze"
																			begin={
																				"dummyRect" +
																				i +
																				".mouseout"
																			}
																			dur="0.15s"
																			id={"bounceOut" + i}
																		/>
																	</svg>
																	<div className="info">
																		<div className="job">
																			{configuration.name}
																		</div>
																	</div>
																</div>
																{configuration.is_private ? (
																	<div className="card-blur-green"></div>
																) : (
																		<div className="card-blur-red"></div>
																	)}
															</div>
														</Link>
													) : null
										)
										: null}
								</div>
							</div>
						</div>
						{this.state.search_configurations.length >
							this.state.total_shown_configuration ? (
								<div className="text-center">
									<button
										onClick={e =>
											this.setState({
												total_shown_configuration: 1000000
											})
										}
										type="submit"
										className="client-roboto-condensed client-login mr-2 ml-2"
									>
										Load More
								</button>
								</div>
							) : null}
					</div>
				</div>
			);
		}
	}
}
const mapStateToProps = state => {
	return {
		user_id: state.loginReducer.user.user.id,
		role: state.loginReducer.user.role,
		loginLoading: state.loginReducer.loading
	};
};

export default connect(mapStateToProps, {})(LibraryConfigurations);
