import yearActionTypes from "./actionTypes";
const INITIAL_STATE = {
  message: "",
  loading: false,
  year: false,
  single_year: ""
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case yearActionTypes.SHOW_YEAR_REQUEST:
      return {
        ...state,
        loading: true
      };
    case yearActionTypes.SHOW_YEAR_SUCCESS:
      return {
        ...state,
        year: action.year,
        loading: false,
        message: action.message
      };
    case yearActionTypes.SHOW_YEAR_FALIURE:
      return {
        ...state,
        loading: false,
        message: action.message
      };

    case yearActionTypes.SHOW_SINGLE_YEAR_REQUEST:
      return {
        ...state,
        loading: true
      };
    case yearActionTypes.SHOW_SINGLE_YEAR_SUCCESS:
      return {
        ...state,
        single_year: action.single_year,
        loading: false,
        message: action.message
      };
    case yearActionTypes.SHOW_SINGLE_YEAR_FALIURE:
      return {
        ...state,
        loading: false,
        message: action.message
      };

    case yearActionTypes.ADD_YEAR_REQUEST:
      return {
        ...state,
        loading: true
      };
    case yearActionTypes.ADD_YEAR_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message
      };
    case yearActionTypes.ADD_YEAR_FALIURE:
      return {
        ...state,
        loading: false,
        message: action.message
      };

    case yearActionTypes.EDIT_YEAR_REQUEST:
      return {
        ...state,
        loading: true
      };
    case yearActionTypes.EDIT_YEAR_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message
      };
    case yearActionTypes.EDIT_YEAR_FALIURE:
      return {
        ...state,
        loading: false,
        message: action.message
      };

    case yearActionTypes.DELETE_YEAR_REQUEST:
      return {
        ...state,
        loading: true
      };
    case yearActionTypes.DELETE_YEAR_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message
      };
    case yearActionTypes.DELETE_YEAR_FALIURE:
      return {
        ...state,
        loading: false,
        message: action.message
      };

    case yearActionTypes.CLEAN_MESSAGE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case yearActionTypes.CLEAN_MESSAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        message: ""
      };
    case yearActionTypes.CLEAN_MESSAGE_FALIURE:
      return {
        ...state,
        loading: false
      };

    default:
      return state;
  }
}
