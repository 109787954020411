import { Api } from "./../../config/api";

async function showAllTemplates() {
  let data = Api.get("template/showAllTemplates").then(function(response) {
    return response.data;
  });

  if (data) {
    return data;
  } else {
    return false;
  }
}

function getTemplate(params) {
  let data = Api.get("template/getTemplate/" + params)
    .then(function(response) {
      return response.data[0];
    })
    .catch(function(error) {
      return false;
    });

  if (data) {
    return data;
  } else {
    return false;
  }
}

async function createTemplate(params) {
  let data = Api.post("template/createTemplate", params).then(function(response) {
    return response.data;
  });

  if (data) {
    return data;
  } else {
    return false;
  }
}

async function editTemplate(params) {
  let data = Api.post("template/editTemplate/" + params.get('id'), params).then(function(
    response
  ) {
    return response.data;
  });

  if (data) {
    return data;
  } else {
    return false;
  }
}

async function deleteTemplate(params) {
  let data = Api.get("template/deleteTemplate/" + params, params).then(function(response) {
    return response.data;
  });

  if (data) {
    return data;
  } else {
    return false;
  }
}

export const TemplateService = {
    showAllTemplates,
    getTemplate,
    createTemplate,
    editTemplate,
    deleteTemplate
};
