import { DashboardService } from "./service";
import { take, call, put } from "redux-saga/effects";
import dashboardActionTypes from "./actionTypes";

function* showDashboardData() {
  while (true) {
    yield take(dashboardActionTypes.SHOW_DASHBOARD_DATA_REQUEST);

    const dashboard_data = yield call(DashboardService.showDashboardData);

    if (
      dashboard_data !== "" &&
      dashboard_data !== null &&
      dashboard_data !== [] &&
      dashboard_data
    ) {
      yield put({
        type: dashboardActionTypes.SHOW_DASHBOARD_DATA_SUCCESS,
        message: "Getting Dashboard Data Success",
        dashboard_data: dashboard_data
      });
    } else {
      yield put({
        type: dashboardActionTypes.SHOW_DASHBOARD_DATA_FAILURE,
        message: "Getting Dashboard Data Faliure"
      });
    }
  }
}

export { showDashboardData };
