import React, { Component } from "react";

import AdminSidebar from "./sidebar-admin";

import SiteHeader from "./header-site";
import SiteFooter from "./footer-site";

import NoLoginHeader from "./header-no-login";
import NoLoginFooter from "./footer-no-login";

import Routes from "./../config/route";
import { getUser } from "./../login/action";
import { getLoginInfoWithToken } from "./../login/action";

import { connect } from "react-redux";

import "antd/dist/antd.css";

class Main extends Component {
	async componentDidMount() {
		let search = await window.location.search;
		let params = await new URLSearchParams(search);
		let foo = await params.get('token');

		if (foo !== null && foo !== undefined && foo !== "") {
			await this.props.getUser('Bearer '+foo);
		} else {
			let qw = localStorage.getItem("AppTree");

			if (qw && qw !== null && qw !== undefined && qw !== "") {
				let token = JSON.parse(qw).loginReducer.token;
				if (token && token !== "" && token !== null && token !== undefined) {
					await this.props.getUser(token);
				} else {
					this.setState({ loading: false });
				}
			}

		}
		this.setState({ loading: false });
	}

	render() {
		if (
			this.props.isAuthenticated &&
			this.props.token !== "" &&
			this.props.user !== [] &&
			this.props.user !== "" &&
			this.props.user !== false &&
			this.props.userRole
		) {
			if (this.props.userRole.toLowerCase() === "admin") {
				return (
					<div>
						<div className="page-wrapper chiller-theme toggled">
							<div className="container-fluid">
								<div className="row">
									<AdminSidebar />
									<main className="page-content">
										<div className="container-fluid">
											<Routes className="bg-gray" />
										</div>
									</main>
								</div>
							</div>
						</div>
					</div>
				);
			} else if (!this.props.userRole.toLowerCase().includes("admin")) {
				return (
					<div>
						<SiteHeader />
						<Routes />
						<SiteFooter />
					</div>
				);
			} else {
				return (
					<div>
						<NoLoginHeader />
						<Routes />
						<NoLoginFooter />
					</div>
				);
			}
		} else {
			return (
				<div>
					<NoLoginHeader />
					<Routes />
					<NoLoginFooter />
				</div>
			);
		}
	}
}
const mapStateToProps = state => {
	return {
		isAuthenticated: state.loginReducer.isAuthenticated ? true : false,
		token: state.loginReducer.token,
		user: state.loginReducer.user ? state.loginReducer.user : "",
		userRole: state.loginReducer.user.role ? state.loginReducer.user.role : "",
		loading: state.loginReducer.loading
	};
};

export default connect(mapStateToProps, { getUser, getLoginInfoWithToken })(
	Main
);
