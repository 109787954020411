import React, { Component } from "react";
import { connect } from "react-redux";
import "./../../../css/animation.css";
import { Link } from "react-router-dom";
import { clear_message } from "./../action";
import { css } from "@emotion/react";
import { PulseLoader } from "react-spinners";

class ConfigurationSuccess extends Component {
	constructor(props) {
		super(props);
		this.state = {
			configuration_id: false,
			configuration_type: false
		};
	}

	async componentDidMount() {
		this.setState({ configuration_id: this.props.configuration_id });
		this.setState({ configuration_type: this.props.configuration_type });
		await this.props.clear_message();
	}

	render() {
		const override = css`
			display: block;
			margin: 0 auto;
			border-color: red;
		`;
		if (!this.state.configuration_type || !this.state.configuration_id) {
			return (
				<div className="sweet-loading">
					<div className="container loading-container">
						<div className="row m-5 p-5">
							<PulseLoader
								css={override}
								sizeUnit={"px"}
								size={24}
								color={"#B9373D"}
							/>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div className="m-5">
					<h3 className="client-roboto-condensed text-center">
						Configuration Success
					</h3>
					<div className="m-4 row text-center">
						<div className="container1">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="svg-success"
								viewBox="0 0 24 24"
							>
								<g
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-miterlimit="10"
								>
									<circle
										className="success-circle-outline"
										cx="12"
										cy="12"
										r="11.5"
									/>
									<circle
										className="success-circle-fill"
										cx="12"
										cy="12"
										r="11.5"
									/>
									<polyline
										className="success-tick"
										points="17,8.5 9.5,15.5 7,13"
									/>
								</g>
							</svg>
						</div>
					</div>
					<div className=" row text-center mt-3 mb-3">
						{/* <div className="col"></div> */}
						{!this.props.configuration_id ? (
							<div className="col"></div>
						) : null}
						<div className="col"></div>
						<div className="col-3">
							<Link
								to="/configuration-process"
								className="client-roboto-condensed btn btn-block client-login mx-2"
							>
								Create New Configuration
							</Link>
						</div>
						{/* {this.props.configuration_id  && this.props.lease_process_visible? (
						<div className="col">
							<Link
								to={"/lease-calculation/" + this.props.configuration_id}
								className="client-roboto-condensed text-uppercase btn btn-block client-login mr-2 ml-2"
							>
								Lease Calculation
							</Link>
						</div>
					) : null} */}
						<div className="col-3">
							{this.state.configuration_type
								.toLowerCase()
								.includes("library") ? (
								<Link
									to={
										"/library-configuration/" +
										this.state.configuration_id
									}
									className="client-roboto-condensed btn btn-block client-login mx-2"
								>
									Go to Configuration
								</Link>
							) : (
								<Link
									to={"/configuration/" + this.state.configuration_id}
									className="client-roboto-condensed btn btn-block client-login mx-2"
								>
									Go to Configuration
								</Link>
							)}
						</div>
						<div className="col-3">
							<Link
								to="/create-quote"
								className="client-roboto-condensed btn btn-block client-login mx-2"
							>
								Create New Quote
							</Link>
						</div>
						<div className="col"></div>

						{!this.props.configuration_id ? (
							<div className="col"></div>
						) : null}
					</div>
				</div>
			);
		}
	}
}
const mapStateToProps = state => {
	return {
		configuration_id: state.clientConfigurationReducer.configuration_id,
		configuration_type: state.clientConfigurationReducer.configuration_type,
		lease_process_visible: state.loginReducer.user.lease_process_visible
	};
};

export default connect(mapStateToProps, {
	clear_message
})(ConfigurationSuccess);
