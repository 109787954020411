const loginActionTypes = {
  REGISTER_REQUEST: "register.request",
  REGISTER_SUCCESS: "register.success",
  REGISTER_FALIURE: "register.faliure",

  REGISTER_ADMIN_REQUEST: "register.admin.request",
  REGISTER_ADMIN_SUCCESS: "register.admin.success",
  REGISTER_ADMIN_FALIURE: "register.admin.faliure",

  LOGIN_REQUEST: "login.request",
  LOGIN_SUCCESS: "login.success",
  LOGIN_FALIURE: "login.faliure",

  GET_LOGIN_INFO_WITH_TOKEN_REQUEST: "get.login.info.with.token.request",
  GET_LOGIN_INFO_WITH_TOKEN_SUCCESS: "get.login.info.with.token.success",
  GET_LOGIN_INFO_WITH_TOKEN_FALIURE: "get.login.info.with.token.faliure",

  LOGOUT_REQUEST: "logout.request",
  LOGOUT_SUCCESS: "logout.success",
  LOGOUT_FALIURE: "logout.faliure",

  CLEAN_LOGIN_MESSAGE_REQUEST: "clean.login.message.request",
  CLEAN_LOGIN_MESSAGE_SUCCESS: "clean.login.message.success",
  CLEAN_LOGIN_MESSAGE_FALIURE: "clean.login.message.faliure",

  GET_USER_REQUEST: "get.user.request",
  GET_USER_SUCCESS: "get.user.success",
  GET_USER_FALIURE: "get.user.faliure"
};
export default loginActionTypes;
