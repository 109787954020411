import { NoteRemarkService } from "./service";
import { take, call, put } from "redux-saga/effects";
import noteAndRemarkActionTypes from "./actionTypes";

function* cleanNoteAndRemarkMessage() {
  while (true) {
    yield take(noteAndRemarkActionTypes.CLEAN_MESSAGE_REQUEST);

    yield put({ type: noteAndRemarkActionTypes.CLEAN_MESSAGE_SUCCESS });
  }
}

function* showNotesAndRemarks() {
  while (true) {
    yield take(noteAndRemarkActionTypes.SHOW_NOTE_AND_REMARK_REQUEST);

    const notes_and_remarks = yield call(NoteRemarkService.showNotesAndRemarks);

    if (
      notes_and_remarks !== "" &&
      notes_and_remarks !== null &&
      notes_and_remarks !== [] &&
      notes_and_remarks
    ) {
      yield put({
        type: noteAndRemarkActionTypes.SHOW_NOTE_AND_REMARK_SUCCESS,
        message: "Getting Note and Remark Data Success",
        notes_and_remarks: notes_and_remarks
      });
    } else {
      yield put({
        type: noteAndRemarkActionTypes.SHOW_NOTE_AND_REMARK_FALIURE,
        message: "Getting Note and Remark Data Faliure"
      });
    }
  }
}

function* updateNoteAndRemark() {
  while (true) {
    const { params } = yield take(
      noteAndRemarkActionTypes.UPDATE_NOTE_AND_REMARK_REQUEST
    );

    const data = yield call(NoteRemarkService.updateNoteAndRemark, params);

    if (data !== "" && data !== null && data !== [] && data) {
      yield put({
        type: noteAndRemarkActionTypes.UPDATE_NOTE_AND_REMARK_SUCCESS,
        message: "Adding note and remark data success"
      });
    } else {
      yield put({
        type: noteAndRemarkActionTypes.UPDATE_NOTE_AND_REMARK_FALIURE,
        message: "Adding note and remark data faliure"
      });
    }
  }
}

export { showNotesAndRemarks, updateNoteAndRemark, cleanNoteAndRemarkMessage };
