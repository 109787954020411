import { OrderService } from "./service";
import { take, call, put } from "redux-saga/effects";
import orderActionTypes from "./actionTypes";

function* showOrders() {
  while (true) {
    yield take(orderActionTypes.SHOW_ORDERS_REQUEST);

    let orders = yield call(OrderService.showOrders);

    if (orders !== "" && orders !== null && orders !== [] && orders) {
      yield put({
        type: orderActionTypes.SHOW_ORDERS_SUCCESS,
        message: "Getting Orders Data Success",
        orders: orders
      });
    } else {
      yield put({
        type: orderActionTypes.SHOW_ORDERS_FALIURE,
        message: "Getting Orders Data Faliure"
      });
    }
  }
}

function* showDetailedOrder(param) {
  while (true) {
    let param = yield take(orderActionTypes.SHOW_DETAILED_ORDER_REQUEST);

    let order = yield call(OrderService.showDetailedOrder, param);

    if (order !== "" && order !== null && order !== [] && order) {
      yield put({
        type: orderActionTypes.SHOW_DETAILED_ORDER_REQUEST,
        message: "Getting Detailed Order Success",
        order: order
      });
    } else {
      yield put({
        type: orderActionTypes.SHOW_DETAILED_ORDER_FALIURE,
        message: "Getting Detailed Order Faliure"
      });
    }
  }
}

export { showOrders, showDetailedOrder };
