import { createStore, applyMiddleware, compose } from "redux";
import Logger from "redux-logger";
import reducer from "./reducer";
import createSagaMiddleware from "redux-saga";
import sagas from "./saga";
import storage from "redux-storage";
import createEngine from "redux-storage-engine-localstorage";
import { persistReducer } from "redux-persist";

export default function configureStore(onComplete) {
	const engine = createEngine("AppTree");
	const storeMiddleware = storage.createMiddleware(engine);
	const sagaMiddleware = createSagaMiddleware();

	// const routeMiddleware = createReactNavigationReduxMiddleware(
	//   "main",
	//   state => state.route
	// );

	const persistConfig = {
		key: "root",
		storage: storage,
		whitelist: ["loginReducer"]
	};

	const persistedReducer = persistReducer(persistConfig, reducer);
	storage.createLoader(engine);
	let store = null;
	if (!process.env.NODE_ENV || process.env.NODE_ENV === "production") {
		store = createStore(
			storage.reducer(persistedReducer), //Apply redux-storage so we can persist Redux state to disk
			// reducer,
			compose(
				applyMiddleware(
					sagaMiddleware,
					// routeMiddleware,
					storeMiddleware
					//Logger
				)
				//     devTools(),
			)
		);
	} else {
		store = createStore(
			storage.reducer(persistedReducer), //Apply redux-storage so we can persist Redux state to disk
			// reducer,
			compose(
				applyMiddleware(
					sagaMiddleware,
					// routeMiddleware,
					storeMiddleware,
					Logger
				)
				// devTools(),
			)
		);
	}

	const load = storage.createLoader(engine);
	load(store)
		.then(() => console.log("Loaded state"))
		.catch(() => console.log("Failed to load previous state"));

	if (module.hot) {
		module.hot.accept(() => {
			const nextRootReducer = require("./reducer").default;
			store.replaceReducer(nextRootReducer);
		});
	}
	sagaMiddleware.run(sagas);

	return store;
}
