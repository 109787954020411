import React, { Component } from "react";
import { connect } from "react-redux";
import FirstForm from "./editFirstForm";
import ThirdForm from "./editThirdForm";
import { Steps } from "primereact/steps";
import { get_data_for_editing } from "./../action";
import { Redirect } from "react-router-dom";
import { css } from "@emotion/react";
import { PulseLoader } from "react-spinners";

class EditQuote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      firstFormOn: true,
      secondFormOn: false,
      thirdFormOn: false,
      id: false
    };
    this.handleComponentChange = this.handleComponentChange.bind(this);
  }

  handleComponentChange(value) {
    let that = this;

    switch (value) {
      case "firstFrom":
        that.setState({ activeIndex: 0 });
        that.setState({ firstFormOn: true });
        that.setState({ secondFormOn: false });
        that.setState({ thirdFormOn: false });
        break;

      case "thirdForm":
        that.setState({ activeIndex: 1 });
        that.setState({ firstFormOn: false });
        that.setState({ secondFormOn: false });
        that.setState({ thirdFormOn: true });
        break;

      default:
        break;
    }
  }

  async componentDidMount() {
    const { id } = this.props.match.params;

    this.setState({ id: id });

    await this.props.get_data_for_editing(id);
  }

  render() {
    const override = css`
      display: block;
      margin: 0 auto;
      border-color: red;
    `;
    const items = [
      {
        label: "Quote Information",
        command: event => {
          this.setState({ firstFormOn: true });
          this.setState({ secondFormOn: false });
          this.setState({ thirdFormOn: false });
        }
      },
      {
        label: "Confirmation",
        command: event => {
          this.setState({ firstFormOn: false });
          this.setState({ secondFormOn: false });
          this.setState({ thirdFormOn: true });
        }
      }
    ];
    if (this.props.message === "Editing user quote success") {
      return <Redirect to="/quotes" />;
    } else {
      if (!this.props.secondForm || this.props.loginLoading) {
        return (
          <div className="sweet-loading">
            <div className="container loading-container">
              <div className="row m-5 p-5">
                <PulseLoader
                  css={override}
                  sizeUnit={"px"}
                  size={24}
                  color={"#B9373D"}
                />
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div className="my-2 client-roboto-condensed container">
            <style>
              {
                "\
                            .p-steps .p-steps-item {\
                                width: 50% !important;\
                            }\
                        "
              }
            </style>
            <div className="row">
              <div className="col"></div>
              <div className="col-sm-12 text-center">
                <div className="text-center">
                  <div className="content-section implementation">
                    <Steps
                      className="mt-5 mb-5"
                      model={items}
                      activeIndex={this.state.activeIndex}
                      onSelect={e => this.setState({ activeIndex: e.index })}
                      readOnly={true}
                    />
                  </div>
                </div>
              </div>
              <div className="col"></div>
            </div>
            {this.state.firstFormOn ? (
              <div className="second-form">
                <FirstForm
                  onComponentChange={this.handleComponentChange}
                  process={"edit quote"}
                  id={this.state.id}
                />
              </div>
            ) : null}
            {this.state.thirdFormOn ? (
              <div className="third-form">
                <ThirdForm
                  onComponentChange={this.handleComponentChange}
                  process={"edit quote"}
                  id={this.state.id}
                />
              </div>
            ) : null}
          </div>
        );
      }
    }
  }
}
const mapStateToProps = state => {
  return {
    user_id: state.loginReducer.user.user.id,
    secondForm: state.clientQuoteReducer.secondForm,
    message: state.clientQuoteReducer.message,
    loginLoading: state.loginReducer.loading
  };
};

export default connect(mapStateToProps, {
  get_data_for_editing
})(EditQuote);
