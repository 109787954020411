import React, { Component } from "react";
import "react-dropdown-tree-select/dist/styles.css";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { ArticleService } from "./../service";
import CKEditor from "ckeditor4-react";

class AddArticle extends Component {
	constructor(props) {
		super(props);
		this.state = {
			article_title: "",
			article_description: "",
			article_image: null,
			redirect: false,
			loading: true
		};
		this.validator = new SimpleReactValidator({ autoForceUpdate: this });
		this.onTextChange = this.onTextChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.handleFileChange = this.handleFileChange.bind(this);
		this.onEditorChange = this.onEditorChange.bind(this);
	}

	onEditorChange(evt) {
		this.setState({
			article_description: evt.editor.getData()
		});
	}

	// function to handle file changes
	handleFileChange(event) {
		this.setState({ article_image: event.target.files[0] });
	}

	// function to handle text changes in multiple fields [two-way binding]
	async onTextChange(name, value) {
		let redirect = this;
		switch (name) {
			case "article_title":
				redirect.setState({ article_title: value });
				break;

			case "article_description":
				redirect.setState({ article_description: value });
				break;

			default:
				break;
		}
	}

	// submit button when add brand is pressed
	async onSubmit(e) {
		e.preventDefault();
		const fd = new FormData();

		if (this.validator.allValid()) {
			fd.append("article_title", this.state.article_title);
			fd.append("article_description", this.state.article_description);

			if (this.state.article_image !== null) {
				fd.append("file", this.state.article_image);
			}

      await ArticleService.addArticle(fd);
      this.setState({redirect: true})
		} else {
			this.validator.showMessages();
		}
	}

	async componentDidMount() {
		this.setState({ loading: false });
	}

	render() {
		if (this.state.redirect) {
			return <Redirect to="/article" />;
		} else {
			if (this.state.loading) {
				return (
					<div>
						<div
							className="content"
							style={{
								minHeight: 500
							}}
						>
							<div className="row">
								<div className="col-sm-12">
									<div className="card">
										<div className="card-header">
											<strong className="card-title">
												Add Brand
											</strong>
										</div>
										<div className="card-body">
											<div className="card-content">
												<div className="todo-list">
													<div className="tdl-holder">
														<div className="tdl-content">
															<div className="row">
																<div className="col"></div>
																<div className="col">
																	<div className="text-center">
																		<Loader
																			type="Rings"
																			color="#00BFFF"
																			height={100}
																			width={100}
																		/>
																		<div className="text-center client-roboto-condensed">
																			LOADING...
																		</div>
																	</div>
																</div>
																<div className="col"></div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				);
			} else {
				return (
					<div>
						<div
							className="content"
							style={{
								minHeight: 500
							}}
						>
							<div className="row">
								<div className="col-sm-12">
									<div className="card">
										<div className="card-header">
											<strong className="card-title">
												Add Brand
											</strong>
										</div>
										<div className="card-body">
											<form>
												<div className="form-group">
													<label
														htmlFor="article_title"
														className="control-label mb-1"
													>
														Article Title
													</label>
													<input
														id="article_title"
														name="article_title"
														type="text"
														className="form-control"
														onChange={e =>
															this.onTextChange(
																"article_title",
																e.target.value
															)
														}
														aria-required="true"
													/>
													<div
														className="mt-4"
														style={{
															color: "red"
														}}
													>
														{this.validator.message(
															"brand_name",
															this.state.article_title,
															"required|string"
														)}
													</div>
												</div>
												<div className="form-group">
													<label
														htmlFor="file"
														className="control-label mb-1"
													>
														Article Image
													</label>
													<br />
													<input
														className="form-group"
														type="file"
														name="article_image"
														onChange={e =>
															this.handleFileChange(e)
														}
													/>
												</div>
												<div className="form-group">
													<label
														htmlFor="article_description"
														className="control-label mb-1"
													>
														Article Description
													</label>
													<CKEditor
														onChange={this.onEditorChange}
														data={this.state.article_description}
													/>
													<div
														className="mt-4"
														style={{
															color: "red"
														}}
													>
														{this.validator.message(
															"article_description",
															this.state.article_description,
															"required|string"
														)}
													</div>
												</div>

												<div>
													<button
														onClick={this.onSubmit}
														id="payment-button"
														type="submit"
														className="btn btn-secondary btn-block"
													>
														<i className="fa fa-truck"></i>&nbsp;
														<span id="payment-button-amount">
															Add Article
														</span>
													</button>
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				);
			}
		}
	}
}

const mapStateToProps = state => {
	return {
		message: state.brandReducer.message,
		loading: state.brandReducer.loading
	};
};

export default connect(mapStateToProps, {})(AddArticle);
