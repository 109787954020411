import companyRatesActionTypes from "./actionTypes";
import { CompanyRatesService } from "./service";
import { take, call, put } from "redux-saga/effects";

function* showCompanyRates() {
  while (true) {
    yield take(companyRatesActionTypes.SHOW_COMPANY_RATES_REQUEST);

    const company_rates = yield call(CompanyRatesService.showCompanyRates);

    if (company_rates !== "" && company_rates !== null && company_rates) {
      yield put({
        type: companyRatesActionTypes.SHOW_COMPANY_RATES_SUCCESS,
        message: "Getting company rates successful",
        company_rates: company_rates
      });
    } else {
      yield put({
        type: companyRatesActionTypes.SHOW_COMPANY_RATES_SUCCESS,
        message: "Getting company rates failed"
      });
    }
  }
}

function* addCompanyRates(params) {
  while (true) {
    const { params } = yield take(
      companyRatesActionTypes.ADD_COMPANY_RATES_REQUEST
    );

    const data = yield call(CompanyRatesService.addCompanyRates, params);

    if (data !== "" && data !== null && data) {
      yield put({
        type: companyRatesActionTypes.ADD_COMPANY_RATES_SUCCESS,
        message: "Adding company rates successful"
      });
    } else {
      yield put({
        type: companyRatesActionTypes.ADD_COMPANY_RATES_FALIURE,
        message: "Adding company rates failed"
      });
    }
  }
}

function* editCompanyRates(params) {
  while (true) {
    const { params } = yield take(
      companyRatesActionTypes.EDIT_COMPANY_RATES_REQUEST
    );

    const data = yield call(CompanyRatesService.editCompanyRates, params);

    if (data !== "" && data !== null && data) {
      yield put({
        type: companyRatesActionTypes.EDIT_COMPANY_RATES_SUCCESS,
        message: "Editing company rates successful"
      });
    } else {
      yield put({
        type: companyRatesActionTypes.EDIT_COMPANY_RATES_FALIURE,
        message: "Editing company rates failed"
      });
    }
  }
}

function* deleteCompanyRate(params) {
  while (true) {
    const { params } = yield take(
      companyRatesActionTypes.DELETE_COMPANY_RATES_REQUEST
    );

    const data = yield call(CompanyRatesService.deleteCompanyRate, params);

    if (data !== "" && data !== null && data) {
      yield put({
        type: companyRatesActionTypes.EDIT_COMPANY_RATES_SUCCESS,
        message: "Deleting company rate successful"
      });
    } else {
      yield put({
        type: companyRatesActionTypes.EDIT_COMPANY_RATES_FALIURE,
        message: "Deleting company rate failed"
      });
    }
  }
}

export {
  showCompanyRates,
  addCompanyRates,
  editCompanyRates,
  deleteCompanyRate
};
