import React, { Component } from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import config from "./../../../config/config";
import { CountryService } from "./../../country/service";
import { BrandService } from "./../../brand/service";
import SimpleReactValidator from "simple-react-validator";

class UpdateUserRole extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			user: [],
			role: "",
			roles: [],
			countries: false,
			country_id: "",
			first_name: "",
			last_name: "",
			phone_number: "",
			mobile_number: "",
			company: "",
			user_company_role: "",
			signature_file: null,
			signature: null,
			redirect: false,
			brands: [],
			name: ""
		};
		this.validator = new SimpleReactValidator();
		this.handleRoleChange = this.handleRoleChange.bind(this);
		this.handleCountryChange = this.handleCountryChange.bind(this);
		this.onCompanyChange = this.onCompanyChange.bind(this);
		this.handleFileChange = this.handleFileChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	handleCountryChange(value) {
		this.setState({ country_id: value });
	}

	handleRoleChange(value) {
		this.setState({ role: value });
	}

	onTextChange(name, value) {
		let redirect = this;
		switch (name) {
			case "first_name":
				redirect.setState({ first_name: value });
				break;

			case "last_name":
				redirect.setState({ last_name: value });
				break;

			case "phone_number":
				redirect.setState({ phone_number: value });
				break;

			case "mobile_number":
				redirect.setState({ mobile_number: value });
				break;

			case "user_company_role":
				redirect.setState({ user_company_role: value });
				break;

			default:
				break;
		}
	}

	async onCompanyChange(value) {
		let redirect = this;
		redirect.setState({ company: value });
	}

	async handleFileChange(key, event) {
		switch (key) {
			case "signature_file":
				this.setState({
					signature_file: event.target.files[0],
					signature: null
				});
				break;

			default:
				break;
		}
	}

	async onSubmit(e) {
		e.preventDefault();

		let that = this;
		let { user_id } = this.props.match.params;
		that.setState({ loading: true });
		if (this.validator.allValid()) {
			let fd = new FormData();
			fd.append(
				"name",
				this.state.first_name
					? `${this.state.first_name} ${this.state.last_name}`
					: this.state.name
			);
			fd.append("user_id", this.state.user.id);
			fd.append("role_id", this.state.role);
			fd.append("country_id", this.state.country_id);
			fd.append("first_name", this.state.first_name);
			fd.append("family_name", this.state.last_name);
			fd.append("phone_number", this.state.phone_number);
			fd.append("mobile_number", this.state.mobile_number);
			fd.append("user_responsibility", this.state.user_company_role);
			fd.append("brand_id", this.state.company);
			fd.append("signature_file", this.state.signature_file);

			await axios
				.post(
					config.APP_URL + "/api/auth/changeUserRoleAndCountry/" + user_id,
					fd
				)
				.then(function(response) {
					that.setState({ redirect: true });
				})
				.catch(function(error) {});
		} else {
			this.validator.showMessages();
			this.forceUpdate();
		}
		that.setState({ loading: false });
	}

	async componentDidMount() {
		let { user_id } = this.props.match.params;
		let redirect = this;

		let countries = await CountryService.showCountries();
		let brands = await BrandService.showBrands();

		redirect.setState({
			countries: countries,
			brands: brands,
			company: brands?.[0]?.brand_id || ""
		});

		await axios
			.get(config.APP_URL + "/api/auth/getUser/" + user_id)
			.then(async function(response) {
				await redirect.setState({ user: response.data[0] });
				await redirect.setState({ role: response.data[0].role_id });
				await redirect.setState({ name: response.data[0].name });
				await redirect.setState({
					first_name: response?.data[0]?.first_name || ""
				});
				await redirect.setState({
					last_name: response?.data[0]?.family_name || ""
				});
				await redirect.setState({
					mobile_number: response?.data[0]?.mobile_number || ""
				});
				await redirect.setState({
					phone_number: response?.data[0]?.phone_number || ""
				});
				await redirect.setState({
					company: response?.data[0]?.brand_id || ""
				});
				await redirect.setState({
					user_company_role: response?.data[0]?.user_responsibility || ""
				});
				await redirect.setState({
					signature_file: response?.data[0]?.signature_file || ""
				});
				await redirect.setState({
					signature: response.data[0].signature
				});
				await redirect.setState({
					country_id: response.data[0].country_id
				});
			})
			.catch(function(error) {});

		await axios
			.get(config.APP_URL + "/api/user-role")
			.then(async function(response) {
				redirect.setState({ roles: response.data });
			})
			.catch(function(error) {});

		redirect.setState({ loading: false });
	}

	render() {
		if (this.state.redirect) {
			return <Redirect to="/users" />;
		} else {
			if (this.state.loading) {
				return (
					<div>
						<div
							className="content"
							style={{
								minHeight: 500
							}}
						>
							<div className="row">
								<div className="col-sm-12">
									<div className="card">
										<div className="card-header">
											<strong className="card-title">
												Update User
											</strong>
										</div>
										<div className="card-body">
											<div className="card-content">
												<div className="todo-list">
													<div className="tdl-holder">
														<div className="tdl-content">
															<div className="row">
																<div className="col"></div>
																<div className="col">
																	<div className="text-center">
																		<Loader
																			type="Rings"
																			color="#00BFFF"
																			height={100}
																			width={100}
																		/>
																		<div className="text-center client-roboto-condensed">
																			LOADING...
																		</div>
																	</div>
																</div>
																<div className="col"></div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				);
			} else {
				return (
					<div>
						<div
							className="content"
							style={{
								minHeight: 500
							}}
						>
							<div className="row">
								<div className="col-sm-12">
									<div className="card">
										<div className="card-header">
											<strong className="card-title">
												Update User
											</strong>
										</div>
										<div className="card-body">
											<form>
												<div className="form-group">
													<label>First Name</label>
													<input
														type="text"
														className="form-control"
														placeholder="Enter First Name"
														value={this.state.first_name}
														onChange={e =>
															this.onTextChange(
																"first_name",
																e.target.value
															)
														}
													/>
													<div
														className="mt-4"
														style={{
															color: "red"
														}}
													>
														{this.validator.message(
															"First name",
															this.state.first_name,
															"required|string"
														)}
													</div>
												</div>
												<div className="form-group">
													<label>Last Name</label>
													<input
														type="text"
														className="form-control"
														placeholder="Enter Last Name"
														value={this.state.last_name}
														onChange={e =>
															this.onTextChange(
																"last_name",
																e.target.value
															)
														}
													/>
													<div
														className="mt-4"
														style={{
															color: "red"
														}}
													>
														{this.validator.message(
															"Last name",
															this.state.last_name,
															"required|string"
														)}
													</div>
												</div>
												<div className="form-group">
													<label
														htmlFor="roleName"
														className="control-label mb-1"
													>
														Role Name
													</label>
													<select
														onChange={e =>
															this.handleRoleChange(
																String(e.target.value)
															)
														}
														className="custom-select"
														value={String(this.state.role)}
														disabled={
															this.state.user.role === 0
																? true
																: false
														}
													>
														{this.state.roles.map((role, i) => (
															<option key={i} value={role.id}>
																{role.role_name}
															</option>
														))}
													</select>
													<div
														className="mt-4"
														style={{
															color: "red"
														}}
													>
														{this.validator.message(
															"Role",
															this.state.role,
															"required"
														)}
													</div>
												</div>
												<div className="form-group">
													<label>Phone Number</label>
													<input
														type="text"
														className="form-control"
														placeholder="Enter Phone Number"
														value={this.state.phone_number}
														onChange={e =>
															this.onTextChange(
																"phone_number",
																e.target.value
															)
														}
													/>
													<div
														className="mt-4"
														style={{
															color: "red"
														}}
													>
														{this.validator.message(
															"Phone Number",
															this.state.phone_number,
															"required|string"
														)}
													</div>
												</div>

												<div className="form-group">
													<label>Mobile Number</label>
													<input
														type="text"
														className="form-control"
														placeholder="Enter Mobile Number"
														value={this.state.mobile_number}
														onChange={e =>
															this.onTextChange(
																"mobile_number",
																e.target.value
															)
														}
													/>
													<div
														className="mt-4"
														style={{
															color: "red"
														}}
													>
														{this.validator.message(
															"Mobile Number",
															this.state.mobile_number,
															"required|string"
														)}
													</div>
												</div>

												<div className="form-group">
													<label>Company</label>
													<select
														className="custom-select"
														onChange={e =>
															this.onCompanyChange(
																String(e.target.value)
															)
														}
														value={
															this.state.company || {
																value: "",
																label: "Select Company"
															}
														}
													>
														<option value="" select>
															Select Company
														</option>
														{this.state.brands.map(
															(brand, id) => (
																<option
																	key={id}
																	value={brand.id}
																>
																	{brand.brand_name}
																</option>
															)
														)}
													</select>
													<div
														className="mt-4"
														style={{
															color: "red"
														}}
													>
														{this.validator.message(
															"company",
															this.state.company,
															"required"
														)}
													</div>
												</div>

												{this.state?.company !== "" &&
													this.state?.brands?.find(
														brand =>
															brand.id ===
															parseInt(this.state?.company)
													)?.brand_image !== null && (
														<div className="image-card my-4">
															<div className="imagewrap">
																<div className="thumbnail">
																	<img
																		loading="lazy"
																		alt="brand"
																		src={
																			config.APP_URL +
																			"/api/storage/" +
																			this.state.brands.find(
																				brand =>
																					brand.id ===
																					parseInt(
																						this.state
																							?.company
																					)
																			).brand_image
																		}
																	/>
																</div>
															</div>
														</div>
													)}

												<div className="form-group">
													<label>User Functionality</label>
													<input
														type="text"
														className="form-control"
														placeholder="Enter  Company User Role"
														value={this.state.user_company_role}
														onChange={e =>
															this.onTextChange(
																"user_company_role",
																e.target.value
															)
														}
													/>
													<div
														className="mt-4"
														style={{
															color: "red"
														}}
													>
														{this.validator.message(
															"User Role",
															this.state.user_company_role,
															"required|string"
														)}
													</div>
												</div>

												<div className="form-group">
													<label
														htmlFor="roleName"
														className="control-label mb-1"
													>
														Country
													</label>
													<select
														onChange={e =>
															this.handleCountryChange(
																e.target.value
															)
														}
														className="custom-select"
														value={this.state.country_id}
													>
														{this.state.countries.map(
															(country, i) => (
																<option
																	key={i}
																	value={country.id}
																>
																	{country.country_name}
																</option>
															)
														)}
													</select>
													<div
														className="mt-4"
														style={{
															color: "red"
														}}
													>
														{this.validator.message(
															"Country",
															this.state.country_id,
															"required"
														)}
													</div>
												</div>

												{this.state.signature !== null && (
													<div className="image-card my-4">
														<div className="imagewrap">
															<div className="thumbnail">
																<img
																	loading="lazy"
																	alt="user signature"
																	src={
																		config.APP_URL +
																		"/api/storage/" +
																		this.state.signature
																	}
																/>
															</div>
														</div>
													</div>
												)}

												<div className="form-group">
													<label>User Signature</label>
													<input
														type="file"
														className="form-control"
														placeholder="Enter User Signature"
														onChange={e =>
															this.handleFileChange(
																"signature_file",
																e
															)
														}
														accept="image/jpg, image/jpeg, image/png"
													/>
													{/* <div
														className="mt-4"
														style={{
															color: "red"
														}}
													>
														{this.validator.message(
															"User Signature",
															this.state.signature_file,
															"required"
														)}
													</div> */}
												</div>

												<div>
													<button
														id="payment-button"
														type="submit"
														onClick={this.onSubmit}
														className="btn btn-secondary btn-block"
													>
														<i className="fa fa-user"></i>&nbsp;
														<span id="payment-button-amount">
															Update User
														</span>
													</button>
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				);
			}
		}
	}
}

const mapStateToProps = state => {
	return {
		isAuthenticated: state.loginReducer.isAuthenticated,
		token: state.loginReducer.token,
		user: state.loginReducer.user.user
	};
};

export default connect(mapStateToProps, {})(UpdateUserRole);
