import featureSetActionTypes from "./actionTypes";
const INITIAL_STATE = {
  message: "",
  loading: false,
  features: [],
  feature: []
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case featureSetActionTypes.CLEAN_MESSAGE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case featureSetActionTypes.CLEAN_MESSAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        message: ""
      };
    case featureSetActionTypes.CLEAN_MESSAGE_FALIURE:
      return {
        ...state,
        loading: false
      };

    case featureSetActionTypes.SHOW_FEATURES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case featureSetActionTypes.SHOW_FEATURES_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
        features: action.features
      };
    case featureSetActionTypes.SHOW_FEATURES_FALIURE:
      return {
        ...state,
        loading: false,
        message: action.message
      };

    case featureSetActionTypes.SHOW_SINGLE_FEATURE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case featureSetActionTypes.SHOW_SINGLE_FEATURE_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
        feature: action.feature
      };
    case featureSetActionTypes.SHOW_SINGLE_FEATURE_FALIURE:
      return {
        ...state,
        loading: false,
        message: action.message
      };

    case featureSetActionTypes.ADD_FEATURE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case featureSetActionTypes.ADD_FEATURE_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message
      };
    case featureSetActionTypes.ADD_FEATURE_FALIURE:
      return {
        ...state,
        loading: false,
        message: action.message
      };

    case featureSetActionTypes.UPDATE_FEATURE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case featureSetActionTypes.UPDATE_FEATURE_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message
      };
    case featureSetActionTypes.UPDATE_FEATURE_FALIURE:
      return {
        ...state,
        loading: false,
        message: action.message
      };

    case featureSetActionTypes.DELETE_FEATURE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case featureSetActionTypes.DELETE_FEATURE_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message
      };
    case featureSetActionTypes.DELETE_FEATURE_FALIURE:
      return {
        ...state,
        loading: false,
        message: action.message
      };

    case featureSetActionTypes.UPDATE_FEATURES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case featureSetActionTypes.UPDATE_FEATURES_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message
      };
    case featureSetActionTypes.UPDATE_FEATURES_FALIURE:
      return {
        ...state,
        loading: false,
        message: action.message
      };

    default:
      return state;
  }
}
