import { FeatureSetService } from "./service";
import { take, call, put } from "redux-saga/effects";
import featureSetActionTypes from "./actionTypes";

function* cleanFeatureMessage() {
  while (true) {
    yield take(featureSetActionTypes.CLEAN_MESSAGE_REQUEST);

    yield put({
      type: featureSetActionTypes.CLEAN_MESSAGE_SUCCESS
    });
  }
}

function* showFeatures() {
  while (true) {
    yield take(featureSetActionTypes.SHOW_FEATURES_REQUEST);

    const features = yield call(FeatureSetService.showFeatures);

    if (features !== "" && features !== null && features !== [] && features) {
      yield put({
        type: featureSetActionTypes.SHOW_FEATURES_SUCCESS,
        message: "Getting features data success",
        features: features
      });
    } else {
      yield put({
        type: featureSetActionTypes.SHOW_FEATURES_FALIURE,
        message: "Getting features data faliure"
      });
    }
  }
}

function* showSingleFeatures(params) {
  while (true) {
    const { params } = yield take(
      featureSetActionTypes.SHOW_SINGLE_FEATURE_REQUEST
    );

    const feature = yield call(FeatureSetService.showSingleFeatures, params);

    if (feature !== "" && feature !== null && feature !== [] && feature) {
      yield put({
        type: featureSetActionTypes.SHOW_SINGLE_FEATURE_SUCCESS,
        message: "Getting feature data success",
        feature: feature
      });
    } else {
      yield put({
        type: featureSetActionTypes.SHOW_SINGLE_FEATURE_FALIURE,
        message: "Getting feature data faliure"
      });
    }
  }
}

function* addFeature(params) {
  while (true) {
    const { params } = yield take(featureSetActionTypes.ADD_FEATURE_REQUEST);

    const feature = yield call(FeatureSetService.addFeature, params);

    if (feature !== "" && feature !== null && feature !== [] && feature) {
      yield put({
        type: featureSetActionTypes.ADD_FEATURE_SUCCESS,
        message: "Adding feature data success"
      });
    } else {
      yield put({
        type: featureSetActionTypes.ADD_FEATURE_FALIURE,
        message: "Adding feature data faliure"
      });
    }
  }
}

function* editFeature(params) {
  while (true) {
    const { params } = yield take(featureSetActionTypes.UPDATE_FEATURE_REQUEST);

    const feature = yield call(FeatureSetService.editFeature, params);

    if (feature !== "" && feature !== null && feature !== [] && feature) {
      yield put({
        type: featureSetActionTypes.UPDATE_FEATURE_SUCCESS,
        message: "Updating feature data success"
      });
    } else {
      yield put({
        type: featureSetActionTypes.UPDATE_FEATURE_FALIURE,
        message: "Updating feature data faliure"
      });
    }
  }
}

function* deleteFeature(params) {
  while (true) {
    const { params } = yield take(featureSetActionTypes.DELETE_FEATURE_REQUEST);

    const feature = yield call(FeatureSetService.deleteFeature, params);

    if (feature !== "" && feature !== null && feature !== [] && feature) {
      yield put({
        type: featureSetActionTypes.DELETE_FEATURE_SUCCESS,
        message: "Deleting feature data success",
        feature: feature
      });
    } else {
      yield put({
        type: featureSetActionTypes.DELETE_FEATURE_FALIURE,
        message: "Deleting feature data faliure"
      });
    }
  }
}

function* addFeaturesToCategory(params) {
  while (true) {
    const { parts_array, machine_category_id } = yield take(
      featureSetActionTypes.UPDATE_FEATURES_REQUEST
    );

    const feature = yield call(
      FeatureSetService.addFeaturesToCategory,
      parts_array,
      machine_category_id
    );

    if (feature !== "" && feature !== null && feature !== [] && feature) {
      yield put({
        type: featureSetActionTypes.UPDATE_FEATURES_SUCCESS,
        message: "Adding  features to category success"
      });
    } else {
      yield put({
        type: featureSetActionTypes.UPDATE_FEATURES_FALIURE,
        message: "Adding features to category faliure"
      });
    }
  }
}

export {
  showFeatures,
  cleanFeatureMessage,
  showSingleFeatures,
  addFeature,
  editFeature,
  deleteFeature,
  addFeaturesToCategory
};
