import clientOrderActionTypes from "./actionTypes";
const INITIAL_STATE = {
  message: "",
  loading: false,
  orders: [],
  order: false
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case clientOrderActionTypes.SHOW_ORDERS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case clientOrderActionTypes.SHOW_ORDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
        orders: action.orders
      };
    case clientOrderActionTypes.SHOW_ORDERS_FALIURE:
      return {
        ...state,
        loading: false,
        message: action.message
      };

    case clientOrderActionTypes.SHOW_SINGLE_ORDER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case clientOrderActionTypes.SHOW_SINGLE_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
        order: action.order
      };
    case clientOrderActionTypes.SHOW_SINGLE_ORDER_FALIURE:
      return {
        ...state,
        loading: false,
        message: action.message,
        order: false
      };

    case clientOrderActionTypes.CREATE_ORDER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case clientOrderActionTypes.CREATE_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message
      };
    case clientOrderActionTypes.CREATE_ORDER_FALIURE:
      return {
        ...state,
        loading: false,
        message: action.message
      };

    default:
      return state;
  }
}
