import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import config from "./../../../config/config";
import { Api } from "./../../../config/api";
import Swal from "sweetalert2";

export default class ListRole extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			roles: [],
			role_results: [],
			search: ""
		};
		this.onRoleChange = this.onRoleChange.bind(this);
		this.onDelete = this.onDelete.bind(this);
	}

	async onDelete(value) {
		let redirect = this;

		let result = await Swal.fire({
			title: "Are you sure?",
			text: "Do you want delete this user role?",
			icon: "info",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, Delete!"
		}).then(result => {
			return result;
		});

		if (result.value) {
			let role_assigned = await Api.get(
				"user-role/isRoleAssigned/" + value
			).then(async function(response) {
				return response.data;
      });
      
			if (role_assigned.length !== 0) {
				Swal.fire({
					title: "Denied!",
					text:
						"The role iscurrently assigned to multiple users so you cannot delete it. Please unassign the roles from the user before deleting the role.",
					icon: "info",
					confirmButtonText: "OK"
				});
			} else {
				await Api.get("user-role/delete/" + value)
					.then(async function(response) {
						await Api.get("user-role").then(function(response) {
							redirect.setState({ roles: response.data });
							redirect.setState({ role_results: response.data });
						});
					})
					.catch(function(error) {
						Swal.fire({
							title: "Error!",
							text: "User role delete error.",
							icon: "error",
							confirmButtonText: "OK"
						});
					});
			}
		}
	}

	onRoleChange(name, value) {
		let redirect = this;

		switch (name) {
			case "role":
				redirect.setState({ search: value });
				let roles = redirect.state.roles;
				roles = roles.filter(item =>
					item.role_name.toLowerCase().includes(value)
				);
				redirect.setState({ role_results: roles });
				break;

			default:
				break;
		}
	}

	async componentDidMount() {
		let redirect = this;
		await axios
			.get(config.APP_URL + "/api/user-role")
			.then(function(response) {
				redirect.setState({ roles: response.data });
				redirect.setState({ role_results: response.data });
			});

		redirect.setState({ loading: false });
	}

	render() {
		if (this.state.loading) {
			return (
				<div>
					<div
						className="content"
						style={{
							minHeight: 500
						}}
					>
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-header">
										<strong className="card-title">User Roles</strong>
									</div>
									<div className="card-body">
										<div className="card-content">
											<div className="todo-list">
												<div className="tdl-holder">
													<div className="tdl-content">
														<div className="row">
															<div className="col"></div>
															<div className="col">
																<div className="text-center">
																	<Loader
																		type="Rings"
																		color="#00BFFF"
																		height={100}
																		width={100}
																	/>
																	<div className="text-center client-roboto-condensed">
																		LOADING...
																	</div>
																</div>
															</div>
															<div className="col"></div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div>
					<div
						className="content"
						style={{
							minHeight: 500
						}}
					>
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-header">
										<strong className="card-title">User Roles</strong>
									</div>
									<div className="card-body">
										<div className="card-content">
											<div className="todo-list">
												<div className="tdl-holder">
													<div className="tdl-content">
														<div className="row">
															<div className="col"></div>
															<div className="col">
																<div className="form-group">
																	<input
																		placeholder="Filter Roles"
																		name="role"
																		onChange={e =>
																			this.onRoleChange(
																				"role",
																				e.target.value
																			)
																		}
																		className="form-control"
																		tvalue={this.state.search}
																	/>
																</div>
															</div>
														</div>
														<ol>
															{this.state.role_results.map(
																(role, i) => (
																	<li key={i}>
																		<label
																			style={{
																				color: "black"
																			}}
																		>
																			{role.role_name}
																			{role.role_name
																				.toLowerCase()
																				.includes(
																					"admin"
																				) ? null : (
																				<Link
																					onClick={e =>
																						this.onDelete(
																							role.id
																						)
																					}
																				>
																					<i className="fa fa-times text-dark"></i>
																				</Link>
																			)}
																			<Link
																				to={
																					"/update-user-role/" +
																					role.id
																				}
																			>
																				<i className="fa fa-pencil text-dark"></i>
																			</Link>
																		</label>
																	</li>
																)
															)}
														</ol>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}
}
