import compatibilityActionTypes from "./actionTypes";
import { CompatibilityService } from "./service";
import { take, call, put } from "redux-saga/effects";

function* showCompatiblePartsForCategory(param) {
  while (true) {
    let machine_category = yield take(
      compatibilityActionTypes.SHOW_COMPATIBILITY_FOR_CATEGORY_REQUEST
    );

    const compatible_categories = yield call(
      CompatibilityService.showCompatiblePartsForCategory,
      machine_category
    );

    if (
      compatible_categories !== "" &&
      compatible_categories !== null &&
      compatible_categories
    ) {
      yield put({
        type: compatibilityActionTypes.SHOW_COMPATIBILITY_FOR_CATEGORY_SUCCESS,
        message: "Getting compatible machine parts for a category success",
        compatible_categories: compatible_categories
      });
    } else {
      yield put({
        type: compatibilityActionTypes.SHOW_COMPATIBILITY_FOR_CATEGORY_FALIURE,
        message: "Getting compatible machine parts for a category faliure"
      });
    }
  }
}

function* showAllCategories() {
  while (true) {
    yield take(compatibilityActionTypes.SHOW_ALL_CATEGORIES_REQUEST);

    let all_categories = yield call(CompatibilityService.showAllCategories);

    if (
      all_categories !== "" &&
      all_categories !== null &&
      all_categories !== [] &&
      all_categories
    ) {
      yield put({
        type: compatibilityActionTypes.SHOW_ALL_CATEGORIES_SUCCESS,
        message: "Getting all categories for compatibility success",
        all_categories: all_categories
      });
    } else {
      yield put({
        type: compatibilityActionTypes.SHOW_ALL_CATEGORIES_FALIURE,
        message: "Getting all categories for compatibility faliure"
      });
    }
  }
}

function* showAllMachineParts() {
  while (true) {
    yield take(compatibilityActionTypes.SHOW_ALL_MACHINE_PARTS_REQUEST);

    let all_machine_parts = yield call(
      CompatibilityService.showAllMachineParts
    );

    if (
      all_machine_parts !== "" &&
      all_machine_parts !== null &&
      all_machine_parts !== [] &&
      all_machine_parts
    ) {
      yield put({
        type: compatibilityActionTypes.SHOW_ALL_MACHINE_PARTS_SUCCESS,
        message: "Getting all machine parts for compatibility success",
        all_machine_parts: all_machine_parts
      });
    } else {
      yield put({
        type: compatibilityActionTypes.SHOW_ALL_MACHINE_PARTS_FALIURE,
        message: "Getting all machine parts for compatibility faliure"
      });
    }
  }
}

function* updateCompatibility() {
  while (true) {
    let { parts_array, id } = yield take(
      compatibilityActionTypes.UPDATE_COMPATIBILITY_FOR_CATEGORY_REQUEST
    );

    let data = yield call(
      CompatibilityService.updateCompatibility,
      parts_array,
      id
    );

    if (data !== "" && data !== null && data !== [] && data) {
      yield put({
        type:
          compatibilityActionTypes.UPDATE_COMPATIBILITY_FOR_CATEGORY_SUCCESS,
        message: "Updating Compatibility Success"
      });
    } else {
      yield put({
        type:
          compatibilityActionTypes.UPDATE_COMPATIBILITY_FOR_CATEGORY_FALIURE,
        message: "Updating Compatibility Faliure"
      });
    }
  }
}

function* getTreeCategory() {
  while (true) {
    yield take(compatibilityActionTypes.GET_TREE_CATEGORY_REQUEST);

    const data = yield call(CompatibilityService.getTreeCategory);

    if (data !== "" && data !== null && data !== [] && data) {
      yield put({
        type: compatibilityActionTypes.GET_TREE_CATEGORY_SUCCESS,
        message: "Getting tree category success",
        tree: data
      });
    } else {
      yield put({
        type: compatibilityActionTypes.GET_TREE_CATEGORY_FALIURE,
        message: "Getting tree category faliure"
      });
    }
  }
}

export {
  showCompatiblePartsForCategory,
  getTreeCategory,
  showAllCategories,
  updateCompatibility,
  showAllMachineParts
};
