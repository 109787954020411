const featureSetActionTypes = {
  SHOW_FEATURES_REQUEST: "show.features.request",
  SHOW_FEATURES_SUCCESS: "show.features.success",
  SHOW_FEATURES_FALIURE: "show.features.faliure",

  SHOW_SINGLE_FEATURE_REQUEST: "show.single.feature.request",
  SHOW_SINGLE_FEATURE_SUCCESS: "show.single.feature.success",
  SHOW_SINGLE_FEATURE_FALIURE: "show.single.feature.faliure",

  ADD_FEATURE_REQUEST: "add.feature.request",
  ADD_FEATURE_SUCCESS: "add.feature.success",
  ADD_FEATURE_FALIURE: "add.feature.faliure",

  UPDATE_FEATURE_REQUEST: "update.feature.request",
  UPDATE_FEATURE_SUCCESS: "update.feature.success",
  UPDATE_FEATURE_FALIURE: "update.feature.faliure",

  DELETE_FEATURE_REQUEST: "delete.feature.request",
  DELETE_FEATURE_SUCCESS: "delete.feature.success",
  DELETE_FEATURE_FALIURE: "delete.feature.faliure",

  UPDATE_FEATURES_REQUEST: "update.features.request",
  UPDATE_FEATURES_SUCCESS: "update.features.success",
  UPDATE_FEATURES_FALIURE: "update.features.faliure",

  CLEAN_MESSAGE_REQUEST: "clean.message.request",
  CLEAN_MESSAGE_SUCCESS: "clean.message.success",
  CLEAN_MESSAGE_FALIURE: "clean.message.faliure"
};
export default featureSetActionTypes;
