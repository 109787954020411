import clientProfileActionTypes from "./actionTypes";

const INITIAL_STATE = {
  message: "",
  loading: false,
  user_profile: []
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case clientProfileActionTypes.SHOW_USER_PROFILE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case clientProfileActionTypes.SHOW_USER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
        user_profile: action.user_profile
      };
    case clientProfileActionTypes.SHOW_USER_PROFILE_FALIURE:
      return {
        ...state,
        loading: false,
        message: action.message
      };

    default:
      return state;
  }
}
