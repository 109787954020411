import React, { Component } from "react";
import { connect } from "react-redux";
import { Steps } from "primereact/steps";
import { Redirect } from "react-router-dom";

import FirstForm from "./../../configuration/views/editFirstForm";
import SecondForm from "./../../configuration/views/editSecondForm";
import ThirdForm from "./../../configuration/views/editThirdForm";
import { css } from "@emotion/react";
import { PulseLoader } from "react-spinners";

import {
	show_single_user_configuration_for_edit,
	show_single_library_configuration_for_edit
} from "./../action";

class EditConfigurationProcess extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			activeIndex: 0,
			firstFormOn: true,
			secondFormOn: false,
			thirdFormOn: false,
			id: false,
			from: false
		};
		this.handleComponentChange = this.handleComponentChange.bind(this);
	}

	handleComponentChange(value) {
		let that = this;

		switch (value) {
			case "firstForm":
				that.setState({ activeIndex: 0 });
				that.setState({ firstFormOn: true });
				that.setState({ secondFormOn: false });
				that.setState({ thirdFormOn: false });
				break;

			case "seconForm":
				that.setState({ activeIndex: 1 });
				that.setState({ firstFormOn: false });
				that.setState({ secondFormOn: true });
				that.setState({ thirdFormOn: false });
				break;

			case "thirdForm":
				that.setState({ activeIndex: 2 });
				that.setState({ firstFormOn: false });
				that.setState({ secondFormOn: false });
				that.setState({ thirdFormOn: true });
				break;

			default:
				break;
		}
	}

	async componentDidMount() {
		const { id } = this.props.match.params;
		const { from } = this.props.match.params;

		this.setState({ id: id });
		this.setState({ from: from });

		if (from === "library") {
			await this.props.show_single_library_configuration_for_edit(
				this.props.user_id,
				id,
				this.props.is_support
			);
		} else {
			await this.props.show_single_user_configuration_for_edit(
				this.props.user_id,
				id,
				this.props.is_support
			);
		}

		await this.setState({ loading: false });
	}

	render() {
		const items = [
			{
				label: "Configuration Information",
				command: event => {
					this.setState({ firstFormOn: true });
					this.setState({ secondFormOn: false });
					this.setState({ thirdFormOn: false });
				}
			},
			{
				label: "Parts Selection",
				command: event => {
					this.setState({ firstFormOn: false });
					this.setState({ secondFormOn: true });
					this.setState({ thirdFormOn: false });
				}
			},
			{
				label: "Confirmation",
				command: event => {
					this.setState({ firstFormOn: false });
					this.setState({ secondFormOn: false });
					this.setState({ thirdFormOn: true });
				}
			}
		];
		const override = css`
			display: block;
			margin: 0 auto;
			border-color: red;
		`;
		if (this.props.loginLoading || this.state.loading || this.props.loading) {
			return (
				<div className="sweet-loading">
					<div className="container loading-container">
						<div className="row m-5 p-5">
							<PulseLoader
								css={override}
								sizeUnit={"px"}
								size={24}
								color={"#B9373D"}
							/>
						</div>
					</div>
				</div>
			);
		} else {
			if (this.props.message === "Editing configuration success") {
				return <Redirect to="/configurations" />;
			} else {
				return (
					<div className="mt-4 mb-4 client-roboto-condensed container">
						<style>
							{
								"\
                            .p-steps .p-steps-item {\
                                width: 33.33% !important;\
                            }\
                        "
							}
						</style>
						<div className="row">
							<div className="col-lg-12 text-center">
								<div className="text-center">
									<div className="content-section implementation">
										<Steps
											className="mt-5 mb-5"
											model={items}
											activeIndex={this.state.activeIndex}
											onSelect={e =>
												this.setState({ activeIndex: e.index })
											}
											readOnly={true}
										/>
									</div>
								</div>
							</div>
						</div>
						{this.state.firstFormOn ? (
							<div className="first-form">
								<FirstForm
									onComponentChange={this.handleComponentChange}
									id={this.state.id}
								/>
							</div>
						) : null}
						{this.state.secondFormOn ? (
							<div className="second-form">
								<SecondForm
									onComponentChange={this.handleComponentChange}
									id={this.state.id}
									from={this.state.from}
								/>
							</div>
						) : null}
						{this.state.thirdFormOn ? (
							<div className="third-form">
								<ThirdForm
									onComponentChange={this.handleComponentChange}
									id={this.state.id}
									from={this.state.from}
								/>
							</div>
						) : null}
					</div>
				);
			}
		}
	}
}
const mapStateToProps = state => {
	return {
		role: state.loginReducer.user.role,
		user_id: state.loginReducer.user.user.id,
		message: state.clientConfigurationReducer.message,
		loading: state.clientConfigurationReducer.loading,
		loginLoading: state.loginReducer.loading,
		first_form: state.clientConfigurationReducer.first_form,
		is_support: state.loginReducer.user.is_support
	};
};

export default connect(mapStateToProps, {
	show_single_user_configuration_for_edit,
	show_single_library_configuration_for_edit
})(EditConfigurationProcess);
