const compatibilityActionTypes = {
  SHOW_COMPATIBILITY_FOR_CATEGORY_REQUEST:
    "show.compatibility.for.category.request",
  SHOW_COMPATIBILITY_FOR_CATEGORY_SUCCESS:
    "show.compatibility.for.category.success",
  SHOW_COMPATIBILITY_FOR_CATEGORY_FALIURE:
    "show.compatibility.for.category.faliure",

  UPDATE_COMPATIBILITY_FOR_CATEGORY_REQUEST:
    "update.compatibility.for.category.request",
  UPDATE_COMPATIBILITY_FOR_CATEGORY_SUCCESS:
    "update.compatibility.for.category.success",
  UPDATE_COMPATIBILITY_FOR_CATEGORY_FALIURE:
    "update.compatibility.for.category.faliure",

  SHOW_ALL_CATEGORIES_REQUEST: "show.all.categories.request",
  SHOW_ALL_CATEGORIES_SUCCESS: "show.all.categories.success",
  SHOW_ALL_CATEGORIES_FALIURE: "show.all.categories.faliure",

  SHOW_ALL_MACHINE_PARTS_REQUEST: "show.all.machine.parts.request",
  SHOW_ALL_MACHINE_PARTS_SUCCESS: "show.all.machine.parts.success",
  SHOW_ALL_MACHINE_PARTS_FALIURE: "show.all.machine.parts.faliure",

  GET_TREE_CATEGORY_REQUEST: "get.tree.category.request",
  GET_TREE_CATEGORY_SUCCESS: "get.tree.category.success",
  GET_TREE_CATEGORY_FALIURE: "get.tree.category.faliure"
};
export default compatibilityActionTypes;
