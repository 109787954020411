import React, { Component } from "react";
import { connect } from "react-redux";
import { show_single_library_configuration } from "./../action";
import { Link } from "react-router-dom";
import noimage from "./../../../CPQ/noimage.png";
import { css } from "@emotion/react";
import { PulseLoader } from "react-spinners";
import { Api } from "../../../config/api";
import { Redirect } from "react-router-dom";
import strings from "./../../../config/language";
import config from "./../../../config/config";
import getSymbolFromCurrency from "currency-symbol-map";
import { Descriptions, notification } from "antd";
import CurrencyFormat from "react-currency-format";

class DetailedLibraryConfiguration extends Component {
	constructor(props) {
		super(props);
		this.state = {
			configuration: [],
			parts: [],
			id: [],
			ordered: false,
			delete: false,
			is_private: 0,
			category_details: this.props.category_details
				? this.props.category_details
				: false,
			loading: true,
			has_children: false
		};
		this.deleteConfiguration = this.deleteConfiguration.bind(this);
		this.changeStatus = this.changeStatus.bind(this);
	}

	async changeStatus() {
		let { id } = this.props.match.params;
		let that = this;

		let params = {
			is_private: this.state.is_private === 0 ? 1 : 0
		};

		Api.post("/configuration/changeLibraryConfigurationStatus/" + id, params)
			.then(async function (response) {
				notification.info({
					message: "Status changed!",
					description: "library configuration changed."
				});

				await that.props.show_single_library_configuration(id);

				await Api.get(
					"/configuration/getStatusOfLibraryConfiguration/" + id
				).then(function (response) {
					that.setState({ is_private: response.data.is_private });
				});
			})
			.catch(function (error) { });
	}

	async deleteConfiguration(id) {
		let that = this;
		let confirmation = window.confirm("Are you sure?");
		if (confirmation) {
			await Api.get("/configuration/delete-library/" + id)
				.then(function (response) {
					that.setState({ delete: true });
				})
				.catch(function (error) { });
		}
	}

	async componentDidMount() {
		let that = this;

		let { id } = that.props.match.params;
		that.setState({ id: id });
		await that.props.show_single_library_configuration(id);

		await Api.get(
			"/configuration/getStatusOfLibraryConfiguration/" + id
		).then(function (response) {
			that.setState({ is_private: response.data.is_private });
		});

		await Api.get("/auth/hasChildren/" + this.props.user_id).then(function (
			response
		) {
			that.setState({ has_children: response.data.has_children });
		});

		await that.setState({ loading: false });
	}

	render() {
		strings.setLanguage(this.props.language);
		const override = css`
			display: block;
			margin: 0 auto;
			border-color: red;
		`;
		if (this.state.delete) {
			return <Redirect to="/library-configurations" />;
		} else {
			if (
				this.props.loading ||
				this.props.loginLoading ||
				this.state.loading
			) {
				return (
					<div className="sweet-loading">
						<div className="container loading-container">
							<div className="row m-5 p-5">
								<PulseLoader
									css={override}
									sizeUnit={"px"}
									size={24}
									color={"#B9373D"}
								/>
							</div>
						</div>
					</div>
				);
			} else {
				if (!this.props.configuration || this.props.loading) {
					return (
						<>
							<div
								className="modal-body"
								style={{
									justifyContent: "center"
								}}
							>
								<div
									className="row m-5"
									style={{
										justifyContent: "center",
										flexDirection: "row"
									}}
								>
									<strong>
										Either there is something wrong with the configuration (can be deleted by the user) or you don't have proper authority to access this configuration, please contact the administrator. Then we will try to fix it.
									</strong>
								</div>
							</div>
						</>
					);
				} else {
					return (
						<div>
							<div className="mt-4 mb-4 client-roboto-condensed container">
								<div className="text-center">
									<h4
										className="my-5"
										style={{
											fontWeight: 900
										}}
									>
										Library Configuration Details
									</h4>
								</div>
								<div className="row m-5">
									<div className="col-sm-4">
										<div className="">
											{this.props.configuration[0]
												.machine_category_image !== [] &&
												this.props.configuration[0]
													.machine_category_image !== null ? (
													<img loading="lazy"
														width="70%"
														height="70%"
														className="img-responsive"
														alt=""
														src={
															config.APP_URL +
															"/api/storage/" +
															this.props.configuration[0]
																.machine_category_image
														}
													/>
												) : (
													<img loading="lazy"
														width="70%"
														height="70%"
														className="img-responsive"
														alt=""
														src={noimage}
													/>
												)}
										</div>
									</div>
									<div className="col-sm-8">
										<div className="client-config-details ml-5 mr-5 mb-5">
											<Descriptions
												title=""
												layout="hotizontal"
												bordered
												size="small"
												column={1}
											>
												<Descriptions.Item label="Configuration Name">
													{this.props.configuration !== []
														? this.props.configuration[0].name
														: ""}
												</Descriptions.Item>
												<Descriptions.Item label="Product Category">
													{this.props.configuration !== []
														? this.props.configuration[0]
															.machine_category
														: ""}
												</Descriptions.Item>
												<Descriptions.Item label="Retail Price">
													{this.props.configuration !== []
														? parseFloat(
															this.props.configuration[0]
																.machine_total_price /
															this.props.configuration[0]
																.quantity
														).toFixed(2)
														: ""}{" "}
													{getSymbolFromCurrency(
														this.props.country[0]
															.currency_symbol_html
													)}
												</Descriptions.Item>
												{this.props.configuration !== [] &&
													this.props.configuration[0]
														.discount_percentage !== 0 ? (
														this.props.is_dealer ? (
															<Descriptions.Item label="Standard Dealer Terms %">
																{
																	this.props.configuration[0]
																		.discount_percentage
																}{" "}
															%
															</Descriptions.Item>
														) : (
																<Descriptions.Item label="Discount Percentage">
																	{
																		this.props.configuration[0]
																			.discount_percentage
																	}{" "}
															%
																</Descriptions.Item>
															)
													) : null}
												{this.props.configuration !== [] &&
													this.props.configuration[0]
														.discount_amount !== 0 ? (
														this.props.is_dealer ? (
															<Descriptions.Item label="Dealer Discount Amount">
																{
																	this.props.configuration[0]
																		.discount_amount
																}{" "}
																{getSymbolFromCurrency(
																	this.props.configuration[0]
																		.currency_symbol_html
																)}
															</Descriptions.Item>
														) : (
																<Descriptions.Item label="Discount Amount">
																	{
																		this.props.configuration[0]
																			.discount_amount
																	}{" "}
																	{getSymbolFromCurrency(
																		this.props.configuration[0]
																			.currency_symbol_html
																	)}
																</Descriptions.Item>
															)
													) : null}

												{this.props.configuration !== [] &&
													this.props.configuration[0]
														.dealer_discount_amount !== 0 ? (
														this.props.extra_discount_visible ? (
															<Descriptions.Item label="Extra Discount">
																{
																	this.props.configuration[0]
																		.dealer_discount_amount
																}{" "}
																{getSymbolFromCurrency(
																	this.props.configuration[0]
																		.currency_symbol_html
																)}
															</Descriptions.Item>
														) : null
													) : null}
												{this.props.is_dealer ? (
													<Descriptions.Item label="Dealer Net Total">
														{this.props.configuration !== [] ? (
															<CurrencyFormat
																className="py-1 pr-2 text-right"
																displayType={"text"}
																decimalSeparator={"."}
																decimalScale={2}
																fixedDecimalScale={true}
																value={parseFloat(
																	this.props.configuration[0]
																		.machine_total_price /
																	this.props
																		.configuration[0]
																		.quantity -
																	(this.props
																		.configuration[0]
																		.discount_percentage *
																		(this.props
																			.configuration[0]
																			.machine_total_price /
																			this.props
																				.configuration[0]
																				.quantity)) /
																	100 -
																	this.props
																		.configuration[0]
																		.discount_amount -
																	this.props
																		.configuration[0]
																		.dealer_discount_amount
																)}
																thousandSeparator={true}
																suffix={
																	" " +
																	getSymbolFromCurrency(
																		this.props
																			.configuration[0]
																			.currency_symbol_html
																	)
																}
															/>
														) : null}
													</Descriptions.Item>
												) : (
														<Descriptions.Item label="Net Total">
															{this.props.configuration !== [] ? (
																<CurrencyFormat
																	className="py-1 pr-2 text-right"
																	displayType={"text"}
																	decimalSeparator={"."}
																	decimalScale={2}
																	fixedDecimalScale={true}
																	value={parseFloat(
																		this.props.configuration[0]
																			.machine_total_price /
																		this.props
																			.configuration[0]
																			.quantity -
																		(this.props
																			.configuration[0]
																			.discount_percentage *
																			(this.props
																				.configuration[0]
																				.machine_total_price /
																				this.props
																					.configuration[0]
																					.quantity)) /
																		100 -
																		this.props
																			.configuration[0]
																			.discount_amount -
																		this.props
																			.configuration[0]
																			.dealer_discount_amount
																	)}
																	thousandSeparator={true}
																	suffix={
																		" " +
																		getSymbolFromCurrency(
																			this.props
																				.configuration[0]
																				.currency_symbol_html
																		)
																	}
																/>
															) : null}
														</Descriptions.Item>
													)}

												{this.props.is_dealer ? (
													<>
														<Descriptions.Item label="OEM Programme">
															{
																this.props.configuration[0]
																	.oem_discount
															}
															{" " +
																getSymbolFromCurrency(
																	this.props.configuration[0]
																		.currency_symbol_html
																)}
														</Descriptions.Item>
														<Descriptions.Item label="Net Net Purchase Price">
															<CurrencyFormat
																className="py-1 pr-2 text-right"
																displayType={"text"}
																decimalSeparator={"."}
																decimalScale={2}
																fixedDecimalScale={true}
																value={parseFloat(
																	this.props.configuration[0]
																		.machine_total_price /
																	this.props
																		.configuration[0]
																		.quantity -
																	(this.props
																		.configuration[0]
																		.discount_percentage *
																		(this.props
																			.configuration[0]
																			.machine_total_price /
																			this.props
																				.configuration[0]
																				.quantity)) /
																	100 -
																	this.props
																		.configuration[0]
																		.discount_amount -
																	this.props
																		.configuration[0]
																		.dealer_discount_amount -
																	this.props
																		.configuration[0]
																		.oem_discount
																).toFixed(2)}
																thousandSeparator={true}
																suffix={
																	" " +
																	getSymbolFromCurrency(
																		this.props
																			.configuration[0]
																			.currency_symbol_html
																	)
																}
															/>
														</Descriptions.Item>
													</>
												) : null}
												{this.props.is_dealer ? (
													<>
														<Descriptions.Item label="Extra Discount">
															{
																this.props.configuration[0]
																	.extra_discount
															}
															{" %"}
														</Descriptions.Item>
														<Descriptions.Item label="Reesink Extra Extra Support">
															<CurrencyFormat
																className="py-1 pr-2 text-right"
																displayType={"text"}
																decimalSeparator={"."}
																decimalScale={2}
																fixedDecimalScale={true}
																value={parseFloat(
																	(this.props.configuration[0]
																		.extra_discount *
																		parseFloat(
																			this.props
																				.configuration[0]
																				.machine_total_price /
																			this.props
																				.configuration[0]
																				.quantity -
																			(this.props
																				.configuration[0]
																				.discount_percentage *
																				(this.props
																					.configuration[0]
																					.machine_total_price /
																					this.props
																						.configuration[0]
																						.quantity)) /
																			100 -
																			this.props
																				.configuration[0]
																				.discount_amount -
																			this.props
																				.configuration[0]
																				.dealer_discount_amount -
																			this.props
																				.configuration[0]
																				.oem_discount
																		).toFixed(2)) /
																	100
																).toFixed(2)}
																thousandSeparator={true}
																suffix={
																	" " +
																	getSymbolFromCurrency(
																		this.props
																			.configuration[0]
																			.currency_symbol_html
																	)
																}
															/>
														</Descriptions.Item>
													</>
												) : null}
												{this.props.is_dealer ? (
													<Descriptions.Item label="Net Net Net Price">
														<CurrencyFormat
															className="py-1 pr-2 text-right"
															displayType={"text"}
															decimalSeparator={"."}
															decimalScale={2}
															fixedDecimalScale={true}
															value={parseFloat(
																parseFloat(
																	this.props.configuration[0]
																		.machine_total_price /
																	this.props
																		.configuration[0]
																		.quantity -
																	(this.props
																		.configuration[0]
																		.discount_percentage *
																		(this.props
																			.configuration[0]
																			.machine_total_price /
																			this.props
																				.configuration[0]
																				.quantity)) /
																	100 -
																	this.props
																		.configuration[0]
																		.discount_amount -
																	this.props
																		.configuration[0]
																		.dealer_discount_amount -
																	this.props
																		.configuration[0]
																		.oem_discount
																).toFixed(2) -
																parseFloat(
																	(this.props
																		.configuration[0]
																		.extra_discount *
																		parseFloat(
																			this.props
																				.configuration[0]
																				.machine_total_price /
																			this.props
																				.configuration[0]
																				.quantity -
																			(this.props
																				.configuration[0]
																				.discount_percentage *
																				(this.props
																					.configuration[0]
																					.machine_total_price /
																					this.props
																						.configuration[0]
																						.quantity)) /
																			100 -
																			this.props
																				.configuration[0]
																				.discount_amount -
																			this.props
																				.configuration[0]
																				.dealer_discount_amount -
																			this.props
																				.configuration[0]
																				.oem_discount
																		).toFixed(2)) /
																	100
																).toFixed(2)
															).toFixed(2)}
															thousandSeparator={true}
															suffix={
																" " +
																getSymbolFromCurrency(
																	this.props.configuration[0]
																		.currency_symbol_html
																)
															}
														/>
													</Descriptions.Item>
												) :
													null}
												{this.props.configuration !== [] &&
													this.props.configuration[0].sales_margin !==
													0 ? (
														this.props.sales_margin_visible ? (
															this.props.is_dealer ? (
																<>
																	<Descriptions.Item label="End Customer Purchase Price">
																		{parseFloat(
																			this.props
																				.configuration[0]
																				.end_user_sales_price
																		).toFixed(2)}{" "}
																		{getSymbolFromCurrency(
																			this.props.country[0]
																				.currency_symbol_html
																		)}
																	</Descriptions.Item>
																	<Descriptions.Item label="Dealer Margin Amount">
																		<CurrencyFormat
																			className="py-1 pr-2 text-right"
																			displayType={"text"}
																			decimalSeparator={"."}
																			decimalScale={2}
																			fixedDecimalScale={true}
																			value={
																				this.props
																					.configuration[0]
																					.sales_margin_amount
																			}
																			thousandSeparator={true}
																			suffix={
																				" " +
																				getSymbolFromCurrency(
																					this.props
																						.configuration[0]
																						.currency_symbol_html
																				)
																			}
																		/>
																	</Descriptions.Item>
																	<Descriptions.Item label="Dealer Margin">
																		{
																			this.props
																				.configuration[0]
																				.sales_margin
																		}
																		{" %"}
																	</Descriptions.Item>
																</>
															) : (
																	<>
																		<Descriptions.Item label="Sales Margin">
																			{
																				this.props
																					.configuration[0]
																					.sales_margin
																			}
																			{" %"}
																		</Descriptions.Item>
																		<Descriptions.Item label="Total Invoice for customer">
																			{parseFloat(
																				this.props
																					.configuration[0]
																					.end_user_sales_price
																			).toFixed(2)}{" "}
																			{getSymbolFromCurrency(
																				this.props.country[0]
																					.currency_symbol_html
																			)}
																		</Descriptions.Item>
																	</>
																)
														) : null
													) : null}
												{this.props.customer_discount_visible &&
													!this.props.is_dealer ? (
														<Descriptions.Item label="Customer Discount">
															{parseFloat(
																(Math.abs(
																	this.props.configuration[0]
																		.machine_total_price -
																	parseFloat(
																		this.props
																			.configuration[0]
																			.machine_total_price -
																		(this.props
																			.configuration[0]
																			.machine_total_price *
																			this.props
																				.configuration[0]
																				.discount_percentage) /
																		100 -
																		this.props
																			.configuration[0]
																			.discount_amount -
																		this.props
																			.configuration[0]
																			.dealer_discount_amount -
																		this.props
																			.configuration[0]
																			.oem_discount -
																		parseFloat(
																			(this.props
																				.configuration[0]
																				.extra_discount *
																				parseFloat(
																					this.props
																						.configuration[0]
																						.machine_total_price /
																					this.props
																						.configuration[0]
																						.quantity -
																					(this.props
																						.configuration[0]
																						.discount_percentage *
																						(this
																							.props
																							.configuration[0]
																							.machine_total_price /
																							this
																								.props
																								.configuration[0]
																								.quantity)) /
																					100 -
																					this.props
																						.configuration[0]
																						.discount_amount -
																					this.props
																						.configuration[0]
																						.dealer_discount_amount -
																					this.props
																						.configuration[0]
																						.oem_discount
																				).toFixed(2)) /
																			100
																		).toFixed(2) +
																		(this.props
																			.configuration[0]
																			.sales_margin *
																			(this.props
																				.configuration[0]
																				.machine_total_price -
																				(this.props
																					.configuration[0]
																					.machine_total_price *
																					this.props
																						.configuration[0]
																						.discount_percentage) /
																				100 -
																				this.props
																					.configuration[0]
																					.discount_amount -
																				this.props
																					.configuration[0]
																					.dealer_discount_amount -
																				this.props
																					.configuration[0]
																					.oem_discount -
																				parseFloat(
																					(this.props
																						.configuration[0]
																						.extra_discount *
																						parseFloat(
																							this
																								.props
																								.configuration[0]
																								.machine_total_price /
																							this
																								.props
																								.configuration[0]
																								.quantity -
																							(this
																								.props
																								.configuration[0]
																								.discount_percentage *
																								(this
																									.props
																									.configuration[0]
																									.machine_total_price /
																									this
																										.props
																										.configuration[0]
																										.quantity)) /
																							100 -
																							this
																								.props
																								.configuration[0]
																								.discount_amount -
																							this
																								.props
																								.configuration[0]
																								.dealer_discount_amount -
																							this
																								.props
																								.configuration[0]
																								.oem_discount
																						).toFixed(
																							2
																						)) /
																					100
																				).toFixed(2))) /
																		100
																	).toFixed(2)
																) /
																	this.props.configuration[0]
																		.machine_total_price) *
																100
															).toFixed(2)}
															{" %"}
														</Descriptions.Item>
													) : null}
												{this.props.configuration !== [] &&
													this.props.configuration[0].quantity !==
													0 ? (
														<Descriptions.Item label="Configuration Quantity">
															{this.props.configuration[0].quantity}
														</Descriptions.Item>
													) : null}
												<Descriptions.Item label="Final Net total">
													{this.props.configuration !== [] ? (
														<CurrencyFormat
															className="py-1 pr-2 text-right"
															displayType={"text"}
															decimalSeparator={"."}
															decimalScale={2}
															fixedDecimalScale={true}
															value={
																parseFloat(
																	this.props.configuration[0]
																		.machine_total_price /
																	this.props
																		.configuration[0]
																		.quantity -
																	(this.props
																		.configuration[0]
																		.discount_percentage *
																		(this.props
																			.configuration[0]
																			.machine_total_price /
																			this.props
																				.configuration[0]
																				.quantity)) /
																	100 -
																	this.props
																		.configuration[0]
																		.discount_amount -
																	this.props
																		.configuration[0]
																		.dealer_discount_amount -
																	this.props
																		.configuration[0]
																		.oem_discount -
																	parseFloat(
																		(this.props
																			.configuration[0]
																			.extra_discount *
																			parseFloat(
																				this.props
																					.configuration[0]
																					.machine_total_price /
																				this.props
																					.configuration[0]
																					.quantity -
																				(this.props
																					.configuration[0]
																					.discount_percentage *
																					(this.props
																						.configuration[0]
																						.machine_total_price /
																						this
																							.props
																							.configuration[0]
																							.quantity)) /
																				100 -
																				this.props
																					.configuration[0]
																					.discount_amount -
																				this.props
																					.configuration[0]
																					.dealer_discount_amount -
																				this.props
																					.configuration[0]
																					.oem_discount
																			).toFixed(2)) /
																		100
																	).toFixed(2)
																) *
																this.props.configuration[0]
																	.quantity
															}
															thousandSeparator={true}
															suffix={
																" " +
																getSymbolFromCurrency(
																	this.props.configuration[0]
																		.currency_symbol_html
																)
															}
														/>
													) : null}
												</Descriptions.Item>
											</Descriptions>
										</div>
									</div>
								</div>
								<div className="row m-5">
									<div className="client-selected-parts">
										<table className="table table-striped table-responsive-md">
											<thead className="thead-dark">
												<tr>
													{/* <th scope="col" className="min-width-table">#</th> */}
													<th
														scope="col"
														className="min-width-table"
													>
														Model Number
													</th>
													<th
														scope="col"
														className="min-width-table"
													>
														Description
													</th>
													<th
														scope="col"
														className="min-width-table"
													>
														Remarks
													</th>
													<th
														scope="col"
														className="min-width-table"
													>
														Note
													</th>
													<th
														scope="col"
														className="min-width-table"
													>
														Price
													</th>
													<th
														scope="col"
														className="min-width-table"
													>
														Quantity
													</th>
													<th
														scope="col"
														className="min-width-table"
													>
														Total Price
													</th>
												</tr>
											</thead>
											<tbody>
												<tr
													style={{
														backgroundColor: "#B9373D",
														color: "#ffffff"
													}}
												>
													<td
														style={{
															color: "white"
														}}
													>
														COMPONENTS
													</td>
													<td></td>
													<td></td>
													<td></td>
													<td></td>
													<td></td>
													<td></td>
													{/* <td></td> */}
												</tr>
												<tr>
													{this.props.loading ? null : (
														<>
															<td className="min-width-table">
																{this.props.category_details &&
																	this.props.category_details !==
																	null
																	? this.props
																		.category_details[0]
																		.machine_category_model_number
																	: "#"}
															</td>
															<td className="min-width-table">
																{this.props.category_details
																	? this.props
																		.category_details[0]
																		.machine_category
																	: null}
															</td>
															<td className="min-width-table"></td>
															<td className="min-width-table"></td>
															{/* <td scope="col" className="min-width-table"></td> */}
															<td className="money min-width-table">
																{getSymbolFromCurrency(
																	this.props
																		.category_details[0]
																		.currency_symbol_html
																)}{" "}
																{this.props.category_details
																	? parseFloat(
																		this.props
																			.category_details[0]
																			.price
																	).toFixed(2)
																	: null}
															</td>
															<td>1</td>
															<td className="money">
																{getSymbolFromCurrency(
																	this.props
																		.category_details[0]
																		.currency_symbol_html
																)}{" "}
																{this.props.category_details
																	? parseFloat(
																		this.props
																			.category_details[0]
																			.price
																	).toFixed(2)
																	: null}
															</td>
														</>
													)}
												</tr>
												<tr
													style={{
														backgroundColor: "#B9373D",
														color: "#ffffff"
													}}
												>
													<td
														style={{
															color: "white"
														}}
													>
														PARTS
													</td>
													<td></td>
													<td></td>
													<td></td>
													<td></td>
													<td></td>
													<td></td>
													{/* <td></td> */}
												</tr>
												{this.props.parts !== []
													? this.props.parts.map((part, i) => (
														<tr key={i}>
															{/* <th scope="row" className="min-width-table">{i+1}</th> */}
															<td className="min-width-table">
																{
																	part.machine_part_model_number
																}
															</td>
															<td className="min-width-table">
																{part.machine_part_name}
															</td>
															<td className="min-width-table">
																{part.custom_remark === "" ||
																	part.custom_remark === null
																	? part.machine_part_remarks !==
																		null
																		? part.machine_part_remarks
																		: null
																	: part.custom_remark}
															</td>
															<td className="min-width-table">
																{part.custom_note === "" ||
																	part.custom_note === null
																	? part.machine_part_note !==
																		null
																		? part.machine_part_note
																		: null
																	: part.custom_note}
															</td>
															{part.is_included ? (
																<>
																	<td className="min-width-table">
																		Included
																		</td>
																	<td className="min-width-table">
																		{part.quantity}
																	</td>
																	<td className="min-width-table">
																		Included
																		</td>
																</>
															) : (
																	<>
																		<td className="min-width-table">
																			{getSymbolFromCurrency(
																				part.currency_symbol_html
																			)}{" "}
																			{part.price.toFixed(2)}
																		</td>
																		<td className="min-width-table">
																			{part.quantity}
																		</td>
																		<td className="min-width-table">
																			{getSymbolFromCurrency(
																				part.currency_symbol_html
																			)}{" "}
																			{(
																				part.quantity *
																				part.price
																			).toFixed(2)}
																		</td>
																	</>
																)}
														</tr>
													))
													: null}
											</tbody>
										</table>

										<table className="table table-striped table-responsive-md mt-5">
											<thead>
												{this.props.manual_adjustments.length >
													0 ? (
														<>
															<tr
																style={{
																	backgroundColor: "#B9373D",
																	color: "#ffffff"
																}}
															>
																<td style={{}}>
																	Manual Adjustments
															</td>
																<td></td>
																<td></td>
																<td></td>
																{/* <td></td> */}
																{/* <td></td> */}
																<td></td>
																<td></td>
																{/* <td>
																</td> */}
															</tr>
															<tr className="thead-dark">
																<th scope="col">Model Number</th>
																<th scope="col">Description</th>
																{/* <th scope="col">Priority</th> */}
																<th scope="col">Note</th>
																{/* <th scope="col"></th> */}
																<th scope="col">Price</th>
																<th scope="col">Quantity</th>
																<th scope="col">Total Price</th>
																{/* <th scope="col">Actions</th> */}
															</tr>
														</>
													) : null}
											</thead>
											<tbody>
												{this.props.manual_adjustments !== null &&
													this.props.manual_adjustments !== []
													? this.props.manual_adjustments.map(
														(adjustment, i) => (
															<tr key={i}>
																<td className="min-width-table">
																	{" "}
																	{
																		adjustment.manual_adjustment_model_number
																	}
																</td>
																<td className="min-width-table">
																	{
																		adjustment.manual_adjustment_name
																	}
																</td>
																<td className="min-width-table">
																	{
																		adjustment.manual_adjustment_note
																	}
																</td>
																{/* <td scope="col" className="min-width-table"></td> */}
																<td className="money min-width-table">
																	{getSymbolFromCurrency(
																		adjustment.currency_symbol_html
																	)}{" "}
																	{parseFloat(
																		adjustment.manual_adjustment_price
																	)}
																</td>
																<td>
																	<div className="client-counter row">
																		{" "}
																		{
																			adjustment.manual_adjustment_quantity
																		}
																	</div>
																</td>
																<td className="money">
																	{getSymbolFromCurrency(
																		adjustment.currency_symbol_html
																	)}
																	{(
																		parseFloat(
																			adjustment.manual_adjustment_quantity
																		) *
																		parseFloat(
																			adjustment.manual_adjustment_price
																		)
																	).toFixed(2)}
																</td>
																{/* <td><button className="plus-minus" onClick={()=>this.onAdjustMentDelete(i)}>X</button></td> */}
															</tr>
														)
													)
													: null}
											</tbody>
										</table>
									</div>
								</div>
								<div className="row m-5">
									{this.props.configuration ? (
										this.props.configuration[0].user_id ===
											this.props.user_id ? (
												this.state.has_children ? (
													this.props.configuration[0].is_private ? (
														<div className="col-3">
															<button
																onClick={() => this.changeStatus()}
																className="client-login btn-block"
															>
																Share with team
														</button>
														</div>
													) : (
															<div className="col-3">
																<button
																	onClick={() => this.changeStatus()}
																	className="client-login btn-block"
																>
																	Stop sharing
														</button>
															</div>
														)
												) : null
											) : null
									) : null}
									{this.props.configuration ? (
										this.props.configuration[0].user_id ===
											this.props.user_id ? (
												<div className="col-3">
													<Link
														to={
															"/edit-configuration-process/" +
															this.state.id +
															"/library"
														}
														className="client-login text-center btn-block"
													>
														Edit Configuration
												</Link>
												</div>
											) : null
									) : null}

									<div className="col-3">
										<Link
											to={
												"/configuration-process/" +
												this.state.id +
												"/library"
											}
											className="client-login text-center btn-block"
										>
											Duplicate and Edit
										</Link>
									</div>
									{this.props.configuration ? (
										this.props.configuration[0].user_id ===
											this.props.user_id ? (
												<div className="col-3">
													<Link
														to="#"
														onClick={() =>
															this.deleteConfiguration(
																this.state.id
															)
														}
														className="client-login text-center btn-block"
													>
														Delete Configuration
												</Link>
												</div>
											) : null
									) : null}
								</div>
							</div>
						</div>
					);
				}
			}
		}
	}
}
const mapStateToProps = state => {
	return {
		first_form: state.clientConfigurationReducer.first_form,
		user_id: state.loginReducer.user.user.id,
		role: state.loginReducer.user.role,
		country: state.loginReducer.user.country,
		configuration: state.clientConfigurationReducer.configuration
			? state.clientConfigurationReducer.configuration.configuration
			: false,
		loading: state.clientConfigurationReducer.loading,
		parts: state.clientConfigurationReducer.configuration
			? state.clientConfigurationReducer.configuration.parts
			: false,
		manual_adjustments:
			state.clientConfigurationReducer.configuration.manual_adjustments,
		category_details: state.clientConfigurationReducer.category_details,
		loginLoading: state.loginReducer.loading,
		language: state.languageReducer.selectedLanguage,
		configuration_loading: state.clientConfigurationReducer.loading,
		configuration_message: state.clientConfigurationReducer.message,
		lease_process_visible: state.loginReducer.user.lease_process_visible,
		is_support: state.loginReducer.user.is_support,
		higher_discount_request: state.loginReducer.user.higher_discount_request,
		is_dealer: state.loginReducer.user.is_dealer,
		sales_margin_visible: state.loginReducer.user.sales_margin_visible,
		customer_discount_visible:
			state.loginReducer.user.customer_discount_visible,
		extra_discount_visible: state.loginReducer.user.extra_discount_visible,
		extra_discount_visible_and_editable:
			state.loginReducer.user.extra_discount_visible_and_editable,
		oem_editable: state.loginReducer.user.oem_editable,
		extra_extra_discount_editable:
			state.loginReducer.user.extra_extra_discount_editable
	};
};

export default connect(mapStateToProps, {
	show_single_library_configuration
})(DetailedLibraryConfiguration);
