import React, { Component } from "react";
import { connect } from "react-redux";
import { show_single_quotes, order_quote } from "./../action";
import { Link, Redirect } from "react-router-dom";
import { show_single_user_configuration } from "./../../configuration/action";
import { Api } from "../../../config/api";
import { css } from "@emotion/react";
import { PulseLoader } from "react-spinners";
import { ClientQuoteService } from "./../service";
import Avatar from "react-avatar";
import noimage from "./../../../CPQ/noimage.png";
import strings from "./../../../config/language";
import config from "./../../../config/config";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Swal from "sweetalert2";
import { Calendar } from "primereact/calendar";
import { notification } from "antd";
import { Comment, Form, List, Input, Tooltip } from "antd";
import { Modal, Descriptions } from "antd";
import moment from "moment";
import { GenericQuestionService } from "./../../../admin/generic_questions/service";
import getSymbolFromCurrency from "currency-symbol-map";
import Axios from "axios";
import { Tabs } from "antd";
import { RoleManagementService } from "../../../admin/role/service";
import CurrencyFormat from "react-currency-format";
import LoadingOverlay from 'react-loading-overlay';

const { TabPane } = Tabs;

const { TextArea } = Input;

class MyDetailedQuote extends Component {
	constructor(props) {
		super(props);
		this.state = {
			configurations: false,
			loading: true,
			projectOverviewModal: false,
			redirect: false,
			conf_id: false,
			comments: false,
			modifyOn: false,
			comment: "",
			id: false,
			modalIsActive: false,
			ordered: false,
			quotationStatus: "",
			orderOpportunity: 10,
			orderOpportunityOn: false,
			expectedDeliveryDate: "",
			expectedDeliveryDateOn: false,
			generic_id: false,
			custom_comment: "",
			generic_reasons: [],
			projectOverviewData: false,
			projectOverviewExportArray: [],
			interest_rate: 0,
			log_configuration_data: false,
			smart_log_configuration_data: false,
			log_configuration_modal_visible: false,
			available_templates: false,
			current_template_id: false,
			template_editable: false,
			delete: false,
			bulkDiscountVisibility: false,
			disable_while_submitting: false,
		};
		this.onDownloadDocs = this.onDownloadDocs.bind(this);
		this.onDownloadExcel = this.onDownloadExcel.bind(this);
		this.onOrderQuote = this.onOrderQuote.bind(this);
		this.showModal = this.showModal.bind(this);
		this.removeConfiguration = this.removeConfiguration.bind(this);
		this.onSelectChange = this.onSelectChange.bind(this);
		this.handleStatusChange = this.handleStatusChange.bind(this);
		this.handleCommentChange = this.handleCommentChange.bind(this);
		this.onComment = this.onComment.bind(this);
		this.modificationChange = this.modificationChange.bind(this);
		this.onTextChange = this.onTextChange.bind(this);
		this.editOrderOpportunity = this.editOrderOpportunity.bind(this);
		this.editExpectedDeliveryDate = this.editExpectedDeliveryDate.bind(this);
		this.handleExpectedDeliveryDateChange = this.handleExpectedDeliveryDateChange.bind(
			this
		);
		this.handleExpectedDeliveryDateButton = this.handleExpectedDeliveryDateButton.bind(
			this
		);
		this.handleOrderOpportunityButton = this.handleOrderOpportunityButton.bind(
			this
		);
		this.showModal1 = this.showModal1.bind(this);
		this.handleOk = this.handleOk.bind(this);
		this.handleCancel = this.handleCancel.bind(this);
		this.showProjectOveriewModal = this.showProjectOveriewModal.bind(this);
		this.projectModalOk = this.projectModalOk.bind(this);
		this.projectModalCancel = this.projectModalCancel.bind(this);
		this.logModalOk = this.logModalOk.bind(this);
		this.logModalCancel = this.logModalCancel.bind(this);
		this.onModalCheckboxSelect = this.onModalCheckboxSelect.bind(this);
		this.leaseCalcualtionFormula = this.leaseCalcualtionFormula.bind(this);
		this.onDiscountChange = this.onDiscountChange.bind(this);
		this.onDiscountEditableChange = this.onDiscountEditableChange.bind(this);
		this.submitDiscount = this.submitDiscount.bind(this);
		this.exportProjectData = this.exportProjectData.bind(this);
		this.loadLogsForConfiguration = this.loadLogsForConfiguration.bind(this);
		this.templateEditable = this.templateEditable.bind(this);
		this.onTemplateChange = this.onTemplateChange.bind(this);
		this.submitTemplate = this.submitTemplate.bind(this);
		this.deleteQuote = this.deleteQuote.bind(this);
		this.handleBulkDiscountChange = this.handleBulkDiscountChange.bind(this);
		this.submitBulkDiscount = this.submitBulkDiscount.bind(this);
		this.handlerequestApprovalNotification = this.handlerequestApprovalNotification.bind(this);
		this.handleapprovedQuoteNotification = this.handleapprovedQuoteNotification.bind(this);
		this.approveConfiguration = this.approveConfiguration.bind(this);
		this.downloadXlsx = this.downloadXlsx.bind(this);
		this.downloadDocx = this.downloadDocx.bind(this);



	}
	async downloadXlsx(value) {
		if(value === null){
			return
		}
        if(value.split("/")[0].toLowerCase() === "Xlsx".toLowerCase()){
			window.open(await ClientQuoteService.fetchDocumentS3("documents/" + value));
        }else{
			window.open(config.APP_URL + '/api/storage/' + value + '?date=' + new Date().getMilliseconds());
        }
    }
	async downloadDocx(value) {
		if(value === null){
			return
		}
        if(value.split("/")[0].toLowerCase() === "Docx".toLowerCase()){
			window.open(await ClientQuoteService.fetchDocumentS3("documents/" + value));
        }else{
			window.open(config.APP_URL + '/api/storage/' + value + '?date=' + new Date().getMilliseconds());
        }
    }

	async approveConfiguration(id) {
		await Api.get('/configuration/approveConfiguration/' + id).then(function (response) {

		}).catch(function (error) {

		})

		await this.props.show_single_quotes(
			this.props.user_id,
			this.props.match.params.id,
			this.props.is_support
		);
	}

	async handlerequestApprovalNotification() {
		let { id } = this.props.match.params;

		await Api.post('/quote/requestApprovalNotification/' + id + '/' + this.props.user_id).then(function (response) {

		}).catch(function (error) {

		})

		await this.props.show_single_quotes(
			this.props.user_id,
			this.props.match.params.id,
			this.props.is_support
		);

	}

	async handleapprovedQuoteNotification() {
		let { id } = this.props.match.params;

		await Api.post('/quote/approvedQuoteNotification/' + id + '/' + this.props.user_id).then(function (response) {

		}).catch(function (error) {

		})


		await this.props.show_single_quotes(
			this.props.user_id,
			this.props.match.params.id,
			this.props.is_support
		);
	}

	async submitBulkDiscount() {
		this.setState({ disable_while_submitting: true })
		let that = this
		let params = {
			configurationsRequest: that.state.configurations
		}
		await Api.post('discount-notification/QuotesDiscount/' + that.props.user_id, params).then(function (response) {
			that.bulkDiscountOk();
			Swal.fire({
				title: "Discount Requested",
				text: "Discount requested successfully",
				icon: "success",
				confirmButtonText: "OK"
			});
			that.setState({
				visible: false
			});
		}).catch(function (error) {
			notification.error({
				message: "Error!",
				description: "Discount request notification failed."
			});
		})
		this.setState({ disable_while_submitting: true })
	}

	handleBulkDiscountChange(i, name, value) {
		let configurations = false;
		switch (name) {
			case 'requested_discount_percentage':
				if (!isNaN(value)) {
					configurations = this.state.configurations
					configurations[i].requested_discount_percentage = value
					this.setState({ configurations: configurations })
				}
				break;

			case 'requested_comment':
				configurations = this.state.configurations
				configurations[i].requested_comment = value
				this.setState({ configurations: configurations })
				break;

			default:
				break;
		}
	}


	bulkDiscountOk = (e, id) => {
		this.setState({
			bulkDiscountVisibility: false
		});
	};

	bulkDiscountCancel = (e, id) => {
		this.setState({
			bulkDiscountVisibility: false
		});
	};

	async deleteQuote(id) {
		let that = this;
		let confirmation = window.confirm(
			"Are you sure you want to delete this quote? Deleting this quote will result in deleting the configurations in the quote as well."
		);
		if (confirmation) {
			await Api.get("/quote/delete/" + id)
				.then(function (response) {
					that.setState({ delete: true });
				})
				.catch(function (error) { });
		}
	}

	columns = [
		{
			title: "Configuration Name",
			dataIndex: "name",
			key: "name"
		},
		{
			title: "Quantity",
			dataIndex: "quantity",
			key: "quantity"
		},
		{
			title: "Approval Status",
			dataIndex: "approve_request",
			key: "approve_request",
			render: (approve_request, approve_with_change, user_id) => (
				this.props.user_id !== user_id ?
					approve_request === 1 ?
						approve_with_change === 1 ?
							'Approved with change'
							: 'Approved'
						: 'Not Approved'
					: null
			)
		},
		{
			title: "Actions",
			dataIndex: "id",
			key: "id",
			render: (id, user_id, approve_request, approve_with_change) => (
				<>
					<button
						href="#"
						style={{
							background: "none",
							color: "inherit",
							border: "none",
							padding: 0,
							font: "inherit",
							borderBottom: 1,
							cursor: "pointer"
						}}
						onClick={() => this.showModal(id)}
						className="red-link mr-2"
						data-toggle="modal"
						data-target="#exampleModal"
					>
						See Configuration
					</button>
					<br />
					{!this.props.quote.quote[0].status
						.toLowerCase()
						.includes("submitted order") ? (
						<Link
							className="red-link"
							to={"/edit-configuration-process/" + id}
						>
							Edit Configuration
						</Link>
					) : null}
					<br />
					{this.props.log_visibility ? (
						<button
							href="#"
							style={{
								background: "none",
								color: "inherit",
								border: "none",
								padding: 0,
								font: "inherit",
								borderBottom: 1,
								cursor: "pointer"
							}}
							className="red-link"
							onClick={() => this.loadLogsForConfiguration(id)}
						>
							Configuration History
						</button>
					) : null}
					<br />
					{!this.state.ordered &&
						this.props.quote.configurations.length > 1 ? (
						<button
							style={{
								background: "none",
								color: "inherit",
								border: "none",
								padding: 0,
								font: "inherit",
								borderBottom: 1,
								cursor: "pointer"
							}}
							onClick={() => this.removeConfiguration(id)}
							className="red-link"
						>
							Remove Configuration
						</button>
					) : null}
					{
						this.props.is_manager ?
							user_id !== this.props.user_id ?
								approve_request === 1 || approve_with_change === 1 ?
									null :
									<>
										<button
											style={{
												background: "none",
												color: "inherit",
												border: "none",
												padding: 0,
												font: "inherit",
												borderBottom: 1,
												cursor: "pointer"
											}}
											className="red-link"
											onClick={() => this.approveConfiguration(id)}
										>
											Approve
									</button>
										<br />
										{/* <Link
											to={"/edit-configuration-process/" + id + '/approval'}
											style={{
												background: "none",
												color: "inherit",
												border: "none",
												padding: 0,
												font: "inherit",
												borderBottom: 1,
												cursor: "pointer"
											}}
											className="red-link"
										>
											Approve with Edit
									</Link> */}
									</> : null : null
					}
				</>
			)
		}
	];

	async templateEditable() {
		this.setState({ template_editable: true });
	}

	async onTemplateChange(value) {
		this.setState({ current_template_id: value });
	}

	async submitTemplate() {
		let that = this;
		let params = {
			template_id: this.state.current_template_id
		};
		Api.post(
			"quote/updateTemplateForQuote/" + this.props.match.params.id,
			params
		)
			.then(function (response) {
				notification.success({
					message: "Template updated!",
					description:
						"The template for this quote has been updated successfully."
				});
				that.setState({ template_editable: false });
			})
			.catch(function (error) {
				notification.error({
					message: "Template update error!",
					description:
						"The template for this quote has not been updated successfully."
				});
			});
	}

	getChangedStatemments(type, values) {

	}

	async loadLogsForConfiguration(configuration_id) {
		await this.showModal(configuration_id)
		let that = this;

		await Api.get(
			"configuration/getLogsForConfiguration/" + configuration_id
		).then(function (response) {
			that.setState({ log_configuration_data: response.data });
		});

		await Api.get('configuration/getChangedLogForConfiguration/' + configuration_id).then(function (response) {
			that.setState({ smart_log_configuration_data: response.data })
		})

		await that.setState({ log_configuration_modal_visible: true });
	}

	async exportProjectData() {
		let projectOverviewData = this.state.projectOverviewData;

		projectOverviewData.forEach(element => {
			element.lease_calculation1 = this.leaseCalcualtionFormula(
				element.quantity *
				parseFloat(
					element.machine_total_price -
					element.discount_amount +
					(element.discount_percentage *
						element.machine_total_price) /
					100
				).toFixed(0),
				(element.quantity *
					parseFloat(
						element.machine_total_price -
						element.discount_amount +
						(element.discount_percentage *
							element.machine_total_price) /
						100
					).toFixed(0)) /
				10,
				this.state.interest_rate,
				24
			);
			element.lease_calculation2 = this.leaseCalcualtionFormula(
				element.quantity *
				parseFloat(
					element.machine_total_price -
					element.discount_amount +
					(element.discount_percentage *
						element.machine_total_price) /
					100
				).toFixed(0),
				(element.quantity *
					parseFloat(
						element.machine_total_price -
						element.discount_amount +
						(element.discount_percentage *
							element.machine_total_price) /
						100
					).toFixed(0)) /
				10,
				this.state.interest_rate,
				24
			);
			element.lease_calculation3 = this.leaseCalcualtionFormula(
				element.quantity *
				parseFloat(
					element.machine_total_price -
					element.discount_amount +
					(element.discount_percentage *
						element.machine_total_price) /
					100
				).toFixed(0),
				(element.quantity *
					parseFloat(
						element.machine_total_price -
						element.discount_amount +
						(element.discount_percentage *
							element.machine_total_price) /
						100
					).toFixed(0)) /
				10,
				this.state.interest_rate,
				60
			);
			element.lease_calculation4 = this.leaseCalcualtionFormula(
				element.quantity *
				parseFloat(
					element.machine_total_price -
					element.discount_amount +
					(element.discount_percentage *
						element.machine_total_price) /
					100
				).toFixed(0),
				(element.quantity *
					parseFloat(
						element.machine_total_price -
						element.discount_amount +
						(element.discount_percentage *
							element.machine_total_price) /
						100
					).toFixed(0)) /
				10,
				this.state.interest_rate,
				60
			);
			element.discount_total = parseFloat(
				parseFloat(element.discount_amount * element.quantity) +
				parseFloat(
					(element.discount_percentage *
						(element.machine_total_price * element.quantity)) /
					100
				)
			).toFixed(0);
			element.balance_total = parseFloat(
				element.machine_total_price * element.quantity -
				element.quantity * element.discount_amount -
				(element.discount_percentage *
					element.machine_total_price *
					element.quantity) /
				100
			).toFixed(0);
			element.balance_to_finance =
				element.balance_total - element.trade_in_value;
			element.currency_symbol_html = this.props.country[0].currency_symbol_html;
		});

		let params = {
			export_array: this.state.projectOverviewExportArray,
			projectOverviewData: projectOverviewData
		};

		await Axios({
			url:
				config.APP_URL +
				"/api/quote/exportProjectOverView/" +
				this.props.match.params.id,
			data: params,
			method: "POST",
			responseType: "blob" // important
		})
			.then(response => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute(
					"download",
					this.props.quote.quote[0].quotation_name +
					"-" +
					this.props.quote.quote[0].quotation_name +
					".xlsx"
				);
				document.body.appendChild(link);
				link.click();
				return "all_machine_parts.xlsx ready for download";
			})
			.catch(function (error) {
				return false;
			});
	}

	async submitDiscount(name, i) {
		let that = this;
		let projectOverviewData = this.state.projectOverviewData;
		let params = {
			machine_total_price: projectOverviewData[i].machine_total_price * projectOverviewData[i].quantity,
			discount_amount: projectOverviewData[i].discount_amount,
			discount_percentage: projectOverviewData[i].discount_percentage,
			quantity: projectOverviewData[i].quantity,
			trade_in_value: projectOverviewData[i].trade_in_value,
			service_rate: projectOverviewData[i].service_rate,
			comments: projectOverviewData[i].comments
		};

		let result = false;
		switch (name) {
			case "discount_percentage":
				result = await Api.post(
					config.APP_URL +
					"/api/configuration/updateDiscountForConfiguration/" +
					projectOverviewData[i].configuration_id,
					params
				)
					.then(function (response) {
						projectOverviewData[i].discount_percentage_editable = false;
						return true;
					})
					.catch(function (error) {
						return false;
					});
				break;

			case "quantity":
				result = await Api.post(
					config.APP_URL +
					"/api/configuration/updateDiscountForConfiguration/" +
					projectOverviewData[i].configuration_id,
					params
				)
					.then(function (response) {
						projectOverviewData[i].quantity_editable = false;
						return true;
					})
					.catch(function (error) {
						return false;
					});
				break;

			case "discount_amount":
				result = await Api.post(
					config.APP_URL +
					"/api/configuration/updateDiscountForConfiguration/" +
					projectOverviewData[i].configuration_id,
					params
				)
					.then(function (response) {
						projectOverviewData[i].discount_amount_editable = false;
						return true;
					})
					.catch(function (error) {
						return false;
					});
				break;

			case "trade_in_value":
				result = await Api.post(
					config.APP_URL +
					"/api/configuration/updateDiscountForConfiguration/" +
					projectOverviewData[i].configuration_id,
					params
				)
					.then(function (response) {
						projectOverviewData[i].trade_in_value_editable = false;
						return true;
					})
					.catch(function (error) {
						return false;
					});
				break;

			case "service_rate":
				result = await Api.post(
					config.APP_URL +
					"/api/configuration/updateDiscountForConfiguration/" +
					projectOverviewData[i].configuration_id,
					params
				)
					.then(function (response) {
						projectOverviewData[i].service_rate_editable = false;
						return true;
					})
					.catch(function (error) {
						return false;
					});
				break;

			case "comments":
				result = await Api.post(
					config.APP_URL +
					"/api/configuration/updateDiscountForConfiguration/" +
					projectOverviewData[i].configuration_id,
					params
				)
					.then(function (response) {
						projectOverviewData[i].comments_editable = false;
						return true;
					})
					.catch(function (error) {
						return false;
					});
				break;

			default:
				break;
		}
		this.setState({ projectOverviewData: false });


		if (result) {
			await Api.get(
				config.APP_URL +
				"/api/getProjectOverviewData/" +
				this.props.match.params.id
			)
				.then(function (response) {
					that.setState({
						projectOverviewData: response.data.configurations
					});
				})
				.catch(function (error) {
					return false;
				});
		}

		this.setState({ projectOverviewData: projectOverviewData });
	}

	async onDiscountEditableChange(name, i) {
		let projectOverviewData = this.state.projectOverviewData;

		switch (name) {
			case "discount_percentage":
				projectOverviewData[i].discount_percentage_editable = true;
				break;

			case "quantity":
				projectOverviewData[i].quantity_editable = true;
				break;

			case "discount_amount":
				projectOverviewData[i].discount_amount_editable = true;
				break;

			case "trade_in_value":
				projectOverviewData[i].trade_in_value_editable = true;
				break;

			case "service_rate":
				projectOverviewData[i].service_rate_editable = true;
				break;

			case "comments":
				let value = await Swal.fire({
					title:
						"Comment for " +
						projectOverviewData[i].brand_name +
						" " +
						projectOverviewData[i].machine_category,
					html:
						'<label>Comment</label><textarea id="comments" class="swal2-input" style="height:150px"></textarea>',
					focusConfirm: false,
					allowOutsideClick: true,
					onOpen: async () => {
						document.getElementById("comments").value =
							projectOverviewData[i].comments;
					},
					preConfirm: async () => {
						if (document.getElementById("comments").value === "") {
							return false;
						} else {
							return document.getElementById("comments").value;
						}
					}
				});
				projectOverviewData[i].comments = value.value;

				let params = {
					discount_amount: projectOverviewData[i].discount_amount,
					discount_percentage: projectOverviewData[i].discount_percentage,
					quantity: projectOverviewData[i].quantity,
					trade_in_value: projectOverviewData[i].trade_in_value,
					service_rate: projectOverviewData[i].service_rate,
					comments: projectOverviewData[i].comments
				};
				await Api.post(
					config.APP_URL +
					"/api/configuration/updateDiscountForConfiguration/" +
					projectOverviewData[i].configuration_id,
					params
				)
					.then(function (response) {
						projectOverviewData[i].comments_editable = false;
						return true;
					})
					.catch(function (error) {
						return false;
					});
				break;

			default:
				break;
		}

		this.setState({ projectOverviewData: projectOverviewData });
	}

	onDiscountChange(name, value, i) {
		let that = this;
		let projectOverviewData = this.state.projectOverviewData;
		let max_discount = 100;

		switch (name) {
			case "interest_rate":
				if (!isNaN(value) && value < 100) {
					that.setState({ interest_rate: value });
				}
				break;

			case "discount_percentage":
				max_discount = projectOverviewData[i].max_discount_category <
					this.props.max_discount
					? projectOverviewData[i].max_discount_category
					: this.props.max_discount;
				if (!isNaN(value)) {
					if (
						parseFloat(
							(parseFloat(value !== "" ? value : 0) *
								projectOverviewData[i].machine_total_price) /
							100 +
							parseFloat(projectOverviewData[i].discount_amount)
						) <=
						parseFloat(
							(max_discount *
								projectOverviewData[i].machine_total_price) / 100
						)
					) {
						projectOverviewData[i].discount_percentage = value;
					} else {
						notification.info({
							message: "Info!",
							description:
								"Discount can not amount to more than " +
								parseFloat(
									(max_discount *
										projectOverviewData[i].machine_total_price) /
									100
								)
						});
					}
				}
				break;

			case "quantity":
				if (!isNaN(value) && value >= 0) {
					projectOverviewData[i].quantity = value;
				}
				break;

			case "discount_amount":
				max_discount = projectOverviewData[i].max_discount_category <
					this.props.max_discount
					? projectOverviewData[i].max_discount_category
					: this.props.max_discount;
				if (!isNaN(value)) {
					if (
						parseFloat(
							(projectOverviewData[i].discount_percentage *
								projectOverviewData[i].machine_total_price) /
							100 +
							parseFloat(value !== "" ? value : 0)
						) <=
						parseFloat(
							(max_discount *
								projectOverviewData[i].machine_total_price) / 100
						)
					) {
						projectOverviewData[i].discount_amount = value;
					} else {
						notification.info({
							message: "Info!",
							description:
								"Discount can not amount to more than " +
								parseFloat(
									(max_discount *
										projectOverviewData[i].machine_total_price) /
									100
								)
						});
					}
				}
				break;

			case "trade_in_value":
				if (!isNaN(value) && value >= 0) {
					projectOverviewData[i].trade_in_value = value;
				}
				break;

			case "service_rate":
				if (!isNaN(value) && value >= 0) {
					projectOverviewData[i].service_rate = value;
				}
				break;

			case "comments":
				break;

			default:
				break;
		}
		this.setState({ projectOverviewData: projectOverviewData });
	}

	leaseCalcualtionFormula(net_total, residual_value, rate, time) {
		if (rate === 0 || isNaN(rate)) {
			return 0;
		} else {
			let monthly_payment =
				((-net_total * Math.pow(1 + rate / 1200, time) + residual_value) *
					rate) /
				1200 /
				(1 - Math.pow(1 + rate / 1200, time));
			return monthly_payment.toFixed(0);
		}
	}

	onModalCheckboxSelect(name) {
		let projectOverviewExportArray = this.state.projectOverviewExportArray;
		switch (name) {
			case "machine_category":
				if (projectOverviewExportArray.includes("machine_category")) {
					projectOverviewExportArray = projectOverviewExportArray.filter(
						item => item !== "machine_category"
					);
				} else {
					projectOverviewExportArray.push("machine_category");
				}
				break;

			case "quantity":
				if (projectOverviewExportArray.includes("quantity")) {
					projectOverviewExportArray = projectOverviewExportArray.filter(
						item => item !== "quantity"
					);
				} else {
					projectOverviewExportArray.push("quantity");
				}
				break;

			case "machine_total_price":
				if (projectOverviewExportArray.includes("machine_total_price")) {
					projectOverviewExportArray = projectOverviewExportArray.filter(
						item => item !== "machine_total_price"
					);
				} else {
					projectOverviewExportArray.push("machine_total_price");
				}
				break;

			case "machine_grand_total_price":
				if (
					projectOverviewExportArray.includes("machine_grand_total_price")
				) {
					projectOverviewExportArray = projectOverviewExportArray.filter(
						item => item !== "machine_grand_total_price"
					);
				} else {
					projectOverviewExportArray.push("machine_grand_total_price");
				}
				break;

			case "discount_amount":
				if (projectOverviewExportArray.includes("discount_amount")) {
					projectOverviewExportArray = projectOverviewExportArray.filter(
						item => item !== "discount_amount"
					);
				} else {
					projectOverviewExportArray.push("discount_amount");
				}
				break;

			case "discount_percentage":
				if (projectOverviewExportArray.includes("discount_percentage")) {
					projectOverviewExportArray = projectOverviewExportArray.filter(
						item => item !== "discount_percentage"
					);
				} else {
					projectOverviewExportArray.push("discount_percentage");
				}
				break;

			case "discount_total":
				if (projectOverviewExportArray.includes("discount_total")) {
					projectOverviewExportArray = projectOverviewExportArray.filter(
						item => item !== "discount_total"
					);
				} else {
					projectOverviewExportArray.push("discount_total");
				}
				break;

			case "balance_total":
				if (projectOverviewExportArray.includes("balance_total")) {
					projectOverviewExportArray = projectOverviewExportArray.filter(
						item => item !== "balance_total"
					);
				} else {
					projectOverviewExportArray.push("balance_total");
				}
				break;

			case "trade_in_value":
				if (projectOverviewExportArray.includes("trade_in_value")) {
					projectOverviewExportArray = projectOverviewExportArray.filter(
						item => item !== "trade_in_value"
					);
				} else {
					projectOverviewExportArray.push("trade_in_value");
				}
				break;

			case "balance_to_finance":
				if (projectOverviewExportArray.includes("balance_to_finance")) {
					projectOverviewExportArray = projectOverviewExportArray.filter(
						item => item !== "balance_to_finance"
					);
				} else {
					projectOverviewExportArray.push("balance_to_finance");
				}
				break;

			case "lease_calculation1":
				if (projectOverviewExportArray.includes("lease_calculation1")) {
					projectOverviewExportArray = projectOverviewExportArray.filter(
						item => item !== "lease_calculation1"
					);
				} else {
					projectOverviewExportArray.push("lease_calculation1");
				}
				break;

			case "lease_calculation2":
				if (projectOverviewExportArray.includes("lease_calculation2")) {
					projectOverviewExportArray = projectOverviewExportArray.filter(
						item => item !== "lease_calculation2"
					);
				} else {
					projectOverviewExportArray.push("lease_calculation2");
				}
				break;

			case "lease_calculation3":
				if (projectOverviewExportArray.includes("lease_calculation3")) {
					projectOverviewExportArray = projectOverviewExportArray.filter(
						item => item !== "lease_calculation3"
					);
				} else {
					projectOverviewExportArray.push("lease_calculation3");
				}
				break;

			case "lease_calculation4":
				if (projectOverviewExportArray.includes("lease_calculation4")) {
					projectOverviewExportArray = projectOverviewExportArray.filter(
						item => item !== "lease_calculation4"
					);
				} else {
					projectOverviewExportArray.push("lease_calculation4");
				}
				break;

			case "service_rate":
				if (projectOverviewExportArray.includes("service_rate")) {
					projectOverviewExportArray = projectOverviewExportArray.filter(
						item => item !== "service_rate"
					);
				} else {
					projectOverviewExportArray.push("service_rate");
				}
				break;

			case "comments":
				if (projectOverviewExportArray.includes("comments")) {
					projectOverviewExportArray = projectOverviewExportArray.filter(
						item => item !== "comments"
					);
				} else {
					projectOverviewExportArray.push("comments");
				}
				break;

			default:
				break;
		}
		this.setState({ projectOverviewExportArray: projectOverviewExportArray });
	}

	showModal1 = () => {
		this.setState({
			visible: true
		});
	};

	handleOk = (e, id) => {
		this.setState({
			visible: false
		});
	};

	handleCancel = e => {
		this.setState({ generic_id: false });
		this.setState({ custom_comment: "" });
		this.setState({
			visible: false
		});
	};

	showProjectOveriewModal = () => {
		this.setState({
			projectOverviewModal: true
		});
	};

	projectModalOk = (e, id) => {
		this.setState({
			projectOverviewModal: false
		});
	};

	projectModalCancel = e => {
		this.setState({
			projectOverviewModal: false
		});
	};

	logModalOk = (e, id) => {
		this.setState({
			log_configuration_modal_visible: false
		});
	};

	logModalCancel = e => {
		this.setState({
			log_configuration_modal_visible: false
		});
	};

	async handleOrderOpportunityButton() {
		let that = this;
		await that.setState({
			orderOpportunity: this.props.quote.quote[0].order_opportunity
		});
		this.setState({ orderOpportunityOn: true });
	}

	async handleExpectedDeliveryDateButton() {
		let that = this;
		if (
			this.props.quote.quote[0].expected_delivery_date !== null &&
			this.props.quote.quote[0].expected_delivery_date !== ""
		) {
			await that.setState({
				expectedDeliveryDate: new Date(
					this.props.quote.quote[0].expected_delivery_date.replace(
						/-/g,
						"/"
					)
				)
			});
		} else {
			await that.setState({
				expectedDeliveryDate: new Date()
			});
		}
		this.setState({ expectedDeliveryDateOn: true });
	}

	handleExpectedDeliveryDateChange(value) {
		let date = value;

		this.setState({ expectedDeliveryDate: date });
	}

	async editOrderOpportunity() {
		let { id } = this.props.match.params;
		this.setState({ loading: true });

		let params = {
			order_opportunity: this.state.orderOpportunity
		};

		await Api.post("quote/editOrderOpportunity/" + id, params)
			.then(function (response) {
				Swal.fire({
					title: "Info!",
					text: response.data.message,
					icon: "Success",
					confirmButtonText: "Ok"
				});
			})
			.catch(function (error) { });
		let comments = false;
		this.setState({ id: id });

		comments = await ClientQuoteService.getAllCommentsForQuote(id);

		this.setState({ comments: comments });

		let that = this;

		await Api.get(
			config.APP_URL +
			"/api/quote/checkQuoteOrdered/" +
			this.props.match.params.id
		)
			.then(function (response) {
				if (response.data.message.toLowerCase().includes("order")) {
					that.setState({ ordered: true });
				} else {
				}
			})
			.catch(function (error) {
				return false;
			});

		this.forceUpdate();

		await this.props.show_single_quotes(
			this.props.user_id,
			id,
			this.props.is_support
		);

		await this.setState({ orderOpportunityOn: false });

		this.setState({ loading: false });
	}

	async editExpectedDeliveryDate() {
		let { id } = this.props.match.params;
		this.setState({ loading: true });

		let month = parseInt(this.state.expectedDeliveryDate.getMonth()) + 1;
		let params = {
			expected_delivery_date:
				this.state.expectedDeliveryDate.getFullYear() +
				"-" +
				month +
				"-" +
				this.state.expectedDeliveryDate.getDate()
		};

		await Api.post("quote/editExpectedDeliveryDate/" + id, params)
			.then(function (response) {
				Swal.fire({
					title: "Info!",
					text: response.data.message,
					icon: "Success",
					confirmButtonText: "Ok"
				});
			})
			.catch(function (error) { });
		let comments = false;
		this.setState({ id: id });

		comments = await ClientQuoteService.getAllCommentsForQuote(id);

		this.setState({ comments: comments });

		let that = this;

		await Api.get(
			config.APP_URL +
			"/api/quote/checkQuoteOrdered/" +
			this.props.match.params.id
		)
			.then(function (response) {
				if (response.data.message.toLowerCase().includes("order")) {
					that.setState({ ordered: true });
				} else {
				}
			})
			.catch(function (error) {
				return false;
			});

		this.forceUpdate();

		await this.props.show_single_quotes(
			this.props.user_id,
			id,
			this.props.is_support
		);

		await this.setState({ expectedDeliveryDateOn: false });

		this.setState({ loading: false });
	}

	onTextChange(name, value) {
		let redirect = this;
		switch (name) {
			case "generic_id":
				this.setState({ generic_id: value });
				break;

			case "custom_comment":
				this.setState({ custom_comment: value });
				break;

			case "order_opportunity":
				if (!isNaN(value)) {
					if (value <= 100) {
						redirect.setState({ orderOpportunity: value });
					}
				}
				break;

			default:
				break;
		}
	}

	modificationChange() {
		if (this.state.ordered) {
			if (this.props.is_support) {
				this.setState({ modifyOn: true });
			} else {
				alert(
					"You can not change the status of this quote because it is ordered. Please contact sales support in case something needs to be changed"
				);
			}
		} else {
			this.setState({ modifyOn: true });
		}
	}

	async onComment(e) {
		e.preventDefault();
		let { id } = this.props.match.params;
		let comments = false;
		let that = this;
		let params = {
			comment: that.state.comment,
			quote_id: parseInt(that.props.match.params.id),
			user_id: that.props.user_id
		};

		await Api.post(config.APP_URL + "/api/comments", params)
			.then(async function (response) {
				comments = await ClientQuoteService.getAllCommentsForQuote(id);

				that.setState({ comments: comments });
				that.setState({ comment: "" });
			})
			.catch(function (error) { });
	}

	handleCommentChange(value) {
		this.setState({ comment: value });
	}

	async handleStatusChange() {
		let { id } = this.props.match.params;
		let that = this;

		await that.setState({ loading: true });

		let user_id = this.props.user_id;
		let quote_id = parseInt(this.props.match.params.id);
		let params = {
			status: that.state.quotationStatus,
			generic_id: that.state.generic_id,
			custom_comment: that.state.custom_comment
		};

		if (this.state.quotationStatus.toLowerCase().includes("order")) {
			if (this.props.is_support) {
				let params = {
					order_name: this.props.quote.quote[0].quotation_name + "-order",
					configuration_id: this.props.quote.quote[0].configuration_id,
					quote_id: quote_id
				};
				await that.props.order_quote(
					this.props.quote.quote[0].user_id,
					params
				);
			} else {
				let params = {
					order_name: this.props.quote.quote[0].quotation_name + "-order",
					configuration_id: this.props.quote.quote[0].configuration_id,
					quote_id: quote_id
				};

				await that.props.order_quote(this.props.user_id, params);
			}
		}

		await Api.post(
			config.APP_URL +
			"/api/quote/changeStatusOfQuote/" +
			user_id +
			"/" +
			quote_id,
			params
		)
			.then(async function (response) {
				await that.props.show_single_quotes(
					user_id,
					quote_id,
					that.props.is_support
				);
				that.setState({ modifyOn: false });
			})
			.catch(function (error) { });

		await this.props.show_single_quotes(
			this.props.user_id,
			id,
			this.props.is_support
		);

		await Api.get(
			config.APP_URL +
			"/api/quote/checkQuoteOrdered/" +
			this.props.match.params.id
		)
			.then(function (response) {
				if (response.data.message.toLowerCase().includes("order")) {
					that.setState({ ordered: true });
				} else {
				}
			})
			.catch(function (error) {
				return false;
			});

		this.forceUpdate();

		await that.setState({ loading: false });
	}

	onSelectChange(value) {
		if (!value.toLowerCase().includes("order")) {
			this.showModal1();
		}
		this.setState({ quotationStatus: value });
	}

	async removeConfiguration(configuration_id) {
		await this.setState({ loading: true });

		let confirmation = window.confirm("Are you sure?");
		if (confirmation) {
			this.setState({ loading: true });
			let { id } = this.props.match.params;

			let that = this;

			await Api.get(
				config.APP_URL +
				"/api/quote/removeConfigurationFromQuote/" +
				id +
				"/" +
				configuration_id
			)
				.then(async (response) => {
					notification.success({
						message: "Configuration Removed!",
						description:
							"The configuration has been removed from this quote!"
					});
					that.setState({ loading: false });

					//todo add the code here
					await Api.get(
						config.APP_URL +
						"/api/getProjectOverviewData/" +
						this.props.match.params.id
					)
						.then(function (response) {
							that.setState({
								projectOverviewData: response.data.configurations
							});
						})
						.catch(function (error) {
							return false;
						});
					this.forceUpdate();
				})
				.catch(function (error) { });

			await this.props.show_single_quotes(
				this.props.user_id,
				id,
				this.props.is_support
			);
		}
		await this.setState({ loading: false });
	}

	formatDate(date) {
		let formatted_date = new Date(date.replace(/-/g, "/"));
		let newMonth = parseInt(formatted_date.getMonth()) + 1;

		return (
			formatted_date.getFullYear() +
			"-" +
			newMonth +
			"-" +
			formatted_date.getDate()
		);
	}

	async onDownloadExcel(quote_id) {
		await ClientQuoteService.downloadXlsx(quote_id);
	}

	async onDownloadDocs(quote_id){
		await ClientQuoteService.downloadDocx(quote_id);
	}

	async onOrderQuote() {
		let { id } = this.props.match.params;
		let that = this;

		let result = await Swal.fire({
			title: "Are you sure?",
			text: "Do you want to order this?",
			icon: "info",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, order it!"
		}).then(result => {
			return result;
		});

		if (result.value) {
			if (this.props.is_support) {
				let params = {
					order_name: this.props.quote.quote[0].quotation_name + "-order",
					configuration_id: this.props.quote.quote[0].configuration_id,
					quote_id: this.props.quote.quote[0].id
				};
				await that.props.order_quote(
					this.props.quote.quote[0].user_id,
					params
				);
			} else {
				let params = {
					order_name: this.props.quote.quote[0].quotation_name + "-order",
					configuration_id: this.props.quote.quote[0].configuration_id,
					quote_id: this.props.quote.quote[0].id
				};
				await that.props.order_quote(
					this.props.quote.quote[0].user_id,
					params
				);
			}
		}

		await this.setState({ loading: true });
		await Api.get(
			config.APP_URL +
			"/api/quote/checkQuoteOrdered/" +
			this.props.match.params.id
		)
			.then(async function (response) {
				if (response.data.message.toLowerCase().includes("order")) {
					await that.setState({ ordered: true });
				} else {
				}
			})
			.catch(function (error) {
				return false;
			});

		await this.props.show_single_quotes(
			this.props.user_id,
			id,
			this.props.is_support
		);
		await this.setState({ loading: false });
	}

	async showModal(id) {
		let that = this;
		await that.props.show_single_user_configuration(
			that.props.user_id,
			id,
			this.props.userRole
		);
	}

	async componentDidMount() {
		let { id } = this.props.match.params;
		let comments = false;
		this.setState({ id: id });

		let generic_reasons = await GenericQuestionService.showAllGenericQuestions();

		this.setState({ generic_reasons: generic_reasons });

		comments = await ClientQuoteService.getAllCommentsForQuote(id);

		this.setState({ comments: comments });

		let that = this;

		await Api.get(
			config.APP_URL +
			"/api/quote/checkQuoteOrdered/" +
			this.props.match.params.id
		)
			.then(function (response) {
				if (response.data.message.toLowerCase().includes("order")) {
					that.setState({ ordered: true });
				} else {
				}
			})
			.catch(function (error) {
				return false;
			});

		await Api.get(
			config.APP_URL +
			"/api/getProjectOverviewData/" +
			this.props.match.params.id
		)
			.then(function (response) {
				that.setState({
					projectOverviewData: response.data.configurations
				});
			})
			.catch(function (error) {
				return false;
			});
		this.forceUpdate();

		await this.props.show_single_quotes(
			this.props.user_id,
			id,
			this.props.is_support
		);

		let templates = await RoleManagementService.getCurrentTemplateForRole(
			this.props.role_id
		);

		await this.setState({
			available_templates: templates.selected_templates
		});

		await Api.get(
			"quote/getCurrentTemplate/" + this.props.match.params.id
		).then(function (response) {
			if (response.data[0]) {
				that.setState({
					current_template_id: response.data[0].template_id
				});
			}
		});

		let interimConfigurations = this.props.quote ? this.props.quote.configurations : false
		let configurations = [];
		if (interimConfigurations) {
			let element = null;
			for (element of interimConfigurations) {
				configurations.push({
					'requested_configuration_id': element.id,
					'requested_configuration_name': element.name,
					'requested_discount_percentage': 0,
					'requested_comment': ''
				})
			}
		}

		this.setState({ configurations: configurations })

		await this.setState({ loading: false });
	}

	render() {

		strings.setLanguage(this.props.language);
		const override = css`
			display: block;
			margin: 0 auto;
			border-color: red;
		`;
		if (this.props.message.includes("Ordering quote success")) {
			return <Redirect to="/orders" />;
		} else {
			if (
				this.state.loading ||
				this.props.loginLoading ||
				this.state.loading ||
				this.props.loading ||
				this.props.quoteLoading
			) {
				return (
					<div className="sweet-loading">
						<div className="container loading-container">
							<div className="row my-5 p-5">
								<PulseLoader
									css={override}
									sizeUnit={"px"}
									size={24}
									color={"#B9373D"}
								/>
							</div>
						</div>
					</div>
				);
			} else {
				if (this.state.redirect) {
					return (
						<Redirect
							to={"/edit-configuration-process/" + this.state.conf_id}
						/>
					);
				} else if (this.state.delete) {
					return <Redirect to={"/quotes"} />;
				} else if (this.props.quote) {
					return (
						<div>
							<Modal
								onOk={this.bulkDiscountOk}
								onCancel={this.bulkDiscountCancel}
								footer={null}
								title="Multiple Discount Request"
								visible={this.state.bulkDiscountVisibility}
							>
								<LoadingOverlay
									active={this.state.disable_while_submitting}
									spinner
									text='Submitting...'
								>
									<div className="container">
										<div className="row">
											{this.state.configurations ?
												this.state.configurations.map((configuration, i) =>
													<div key={i} className="col-6 my-2">
														<div className="form-group my-2">
															<label className="client-roboto-condensed font-weight-bold">
																Configuration ID
															</label>
															<br />
															<input className="form-control" readOnly disabled value={configuration.requested_configuration_id} />
														</div>
														<div className="form-group my-2">
															<label className="client-roboto-condensed font-weight-bold">
																Configuration Name
															</label>
															<br />
															<input className="form-control" readOnly disabled value={configuration.requested_configuration_name} />
														</div>
														<div className="form-group my-2">
															<label className="client-roboto-condensed font-weight-bold">
																Requested Discount Percentage
															</label>
															<br />
															<input className="form-control" onChange={(e) => this.handleBulkDiscountChange(i, 'requested_discount_percentage', e.target.value)} value={configuration.requested_discount_percentage} />
														</div>
														<div className="form-group my-2">
															<label className="client-roboto-condensed font-weight-bold">
																Requested Comment
															</label>
															<br />
															<input className="form-control" onChange={(e) => this.handleBulkDiscountChange(i, 'requested_comment', e.target.value)} value={configuration.requested_comment} />
														</div>
													</div>
												) : null}
											<div className="col-12 text-center my-3">
												<button onClick={() => this.submitBulkDiscount()} className="client-login px-5">Submit Discount Request</button>
											</div>
										</div>
									</div>
								</LoadingOverlay>
							</Modal>
							<div className="mt-5 client-roboto-condensed container">
								<div className="text-center">
									<h4
										className="mb-4"
										style={{
											fontWeight: 900
										}}
									>
										Quote Details
									</h4>
									<Modal
										onOk={this.projectModalOk}
										onCancel={this.projectModalCancel}
										footer={null}
										title="Project Overview"
										visible={this.state.projectOverviewModal}
									>
										<div className="container-fluid">
											<div className="row my-3">
												<div className="col">
													<button
														onClick={() =>
															this.exportProjectData()
														}
														className="btn client-login float-right"
													>
														Export Data
													</button>
												</div>
											</div>
											<div
												style={{
													overflow: "auto",
													whiteSpace: "nowrap"
												}}
											>
												<table
													className="table table-striped"
													style={{
														width: "auto"
													}}
												>
													<thead className="thead-dark">
														<tr>
															<th className="ten-percent">
																<span
																	style={{
																		marginRight: 10,
																		color: "white"
																	}}
																>
																	Interest Rate (%)
																</span>
																<input
																	style={{
																		color: "black",
																		height: 30,
																		width: 30,
																		border: "none",
																		textAlign: "right",
																		paddingRight: 5
																	}}
																	onChange={e =>
																		this.onDiscountChange(
																			"interest_rate",
																			e.target.value,
																			1
																		)
																	}
																	value={
																		this.state.interest_rate
																	}
																/>
															</th>
															<th className="ten-percent"></th>
															<th className="ten-percent"></th>
															<th className="ten-percent"></th>
															<th className="ten-percent"></th>
															<th className="ten-percent"></th>
															<th className="ten-percent"></th>
															<th className="ten-percent"></th>
															<th className="ten-percent"></th>
															<th className="ten-percent"></th>
															<th className="ten-percent">
																Hire Purchase 2 years
															</th>
															<th className="ten-percent">
																Lease Hire 2 years
															</th>
															<th className="ten-percent">
																Hire Purchase 5 years
															</th>
															<th className="ten-percent">
																Lease Hire 5 years
															</th>
															<th className="ten-percent"></th>
															<th className="thirty-percent"></th>
														</tr>
														<tr>
															<th className="ten-percent">
																Machine Description
															</th>
															<th className="ten-percent">
																Quantity
															</th>
															<th className="ten-percent">
																RPP Per Machine
															</th>
															<th className="ten-percent">
																RPP total
															</th>
															<th className="ten-percent">
																Discount Percent
															</th>
															<th className="ten-percent">
																Discount per machine
															</th>
															<th className="ten-percent">
																Discount Total
															</th>
															<th className="ten-percent">
																Balance total
															</th>
															<th className="ten-percent">
																Trade In Value
															</th>
															<th className="ten-percent">
																Balance to Finance
															</th>
															<th className="ten-percent">
																1+1 Annual Payments
															</th>
															<th className="ten-percent">
																1+1 Annual Payments
															</th>
															<th className="ten-percent">
																1+4 Annual Payments
															</th>
															<th className="ten-percent">
																1+4 Annual Payments
															</th>
															<th className="ten-percent">
																Service Rate
															</th>
															<th className="thirty-percent">
																Comments
															</th>
														</tr>
													</thead>
													<tbody>
														<tr>
															<td>
																<input
																	type="checkbox"
																	onChange={() =>
																		this.onModalCheckboxSelect(
																			"machine_category"
																		)
																	}
																/>
															</td>
															<td>
																<input
																	type="checkbox"
																	onChange={() =>
																		this.onModalCheckboxSelect(
																			"quantity"
																		)
																	}
																/>
															</td>
															<td>
																<input
																	type="checkbox"
																	onChange={() =>
																		this.onModalCheckboxSelect(
																			"machine_total_price"
																		)
																	}
																/>
															</td>
															<td>
																<input
																	type="checkbox"
																	onChange={() =>
																		this.onModalCheckboxSelect(
																			"machine_grand_total_price"
																		)
																	}
																/>
															</td>
															<td>
																<input
																	type="checkbox"
																	onChange={() =>
																		this.onModalCheckboxSelect(
																			"discount_percentage"
																		)
																	}
																/>
															</td>
															<td>
																<input
																	type="checkbox"
																	onChange={() =>
																		this.onModalCheckboxSelect(
																			"discount_amount"
																		)
																	}
																/>
															</td>
															<td>
																<input
																	type="checkbox"
																	onChange={() =>
																		this.onModalCheckboxSelect(
																			"discount_total"
																		)
																	}
																/>
															</td>
															<td>
																<input
																	type="checkbox"
																	onChange={() =>
																		this.onModalCheckboxSelect(
																			"balance_total"
																		)
																	}
																/>
															</td>
															<td>
																<input
																	type="checkbox"
																	onChange={() =>
																		this.onModalCheckboxSelect(
																			"trade_in_value"
																		)
																	}
																/>
															</td>
															<td>
																<input
																	type="checkbox"
																	onChange={() =>
																		this.onModalCheckboxSelect(
																			"balance_to_finance"
																		)
																	}
																/>
															</td>
															<td>
																<input
																	type="checkbox"
																	onChange={() =>
																		this.onModalCheckboxSelect(
																			"lease_calculation1"
																		)
																	}
																/>
															</td>
															<td>
																<input
																	type="checkbox"
																	onChange={() =>
																		this.onModalCheckboxSelect(
																			"lease_calculation2"
																		)
																	}
																/>
															</td>
															<td>
																<input
																	type="checkbox"
																	onChange={() =>
																		this.onModalCheckboxSelect(
																			"lease_calculation3"
																		)
																	}
																/>
															</td>
															<td>
																<input
																	type="checkbox"
																	onChange={() =>
																		this.onModalCheckboxSelect(
																			"lease_calculation4"
																		)
																	}
																/>
															</td>
															<td>
																<input
																	type="checkbox"
																	onChange={() =>
																		this.onModalCheckboxSelect(
																			"service_rate"
																		)
																	}
																/>
															</td>
															<td>
																<input
																	type="checkbox"
																	onChange={() =>
																		this.onModalCheckboxSelect(
																			"comments"
																		)
																	}
																/>
															</td>
														</tr>
														{this.state.projectOverviewData
															? this.state.projectOverviewData.map(
																(data, i) => (
																	<tr key={i}>
																		<td>
																			{data.parent_category_name +
																				" " +
																				data.machine_category}
																		</td>
																		<td>
																			{data.quantity_editable ? (
																				<>
																					<input
																						className="half"
																						onChange={e =>
																							this.onDiscountChange(
																								"quantity",
																								e
																									.target
																									.value,
																								i
																							)
																						}
																						value={
																							data.quantity
																						}
																					/>
																					<i
																						onClick={() =>
																							this.submitDiscount(
																								"quantity",
																								i
																							)
																						}
																						className="ml-2 fas fa-check"
																					></i>
																				</>
																			) : (
																				<>
																					{
																						data.quantity
																					}
																					{
																						<i
																							onClick={() =>
																								this.onDiscountEditableChange(
																									"quantity",
																									i
																								)
																							}
																							className="ml-2 fas fa-edit"
																						></i>
																					}
																				</>
																			)}
																		</td>
																		<td style={{
																			backgroundColor: 'rgba(46, 204, 113, 0.4)'
																		}}>
																			{getSymbolFromCurrency(
																				this.props
																					.country[0]
																					.currency_symbol_html
																			) +
																				" " +
																				data.machine_total_price.toFixed(
																					0
																				)}
																		</td>
																		<td>
																			{getSymbolFromCurrency(
																				this.props
																					.country[0]
																					.currency_symbol_html
																			) +
																				" " +
																				parseFloat(
																					data.machine_total_price *
																					data.quantity
																				).toFixed(0)}
																		</td>
																		<td>
																			{data.discount_percentage_editable ? (
																				<>
																					<input
																						className="half"
																						onChange={e =>
																							this.onDiscountChange(
																								"discount_percentage",
																								e
																									.target
																									.value,
																								i
																							)
																						}
																						value={
																							data.discount_percentage
																						}
																					/>
																						%
																						<i
																						onClick={() =>
																							this.submitDiscount(
																								"discount_percentage",
																								i
																							)
																						}
																						className="ml-2 fas fa-check"
																					></i>
																				</>
																			) : (
																				<>
																					{data.discount_percentage +
																						" %"}
																					{
																						<i
																							onClick={() =>
																								this.onDiscountEditableChange(
																									"discount_percentage",
																									i
																								)
																							}
																							className="ml-2 fas fa-edit"
																						></i>
																					}
																				</>
																			)}
																		</td>
																		<td>
																			{data.discount_amount_editable ? (
																				<>
																					{getSymbolFromCurrency(
																						this.props
																							.country[0]
																							.currency_symbol_html
																					) + " "}
																					<input
																						onChange={e =>
																							this.onDiscountChange(
																								"discount_amount",
																								e
																									.target
																									.value,
																								i
																							)
																						}
																						className="half"
																						value={
																							data.discount_amount
																						}
																					/>
																					<i
																						onClick={() =>
																							this.submitDiscount(
																								"discount_amount",
																								i
																							)
																						}
																						className="ml-2 fas fa-check"
																					></i>
																				</>
																			) : (
																				<>
																					{getSymbolFromCurrency(
																						this.props
																							.country[0]
																							.currency_symbol_html
																					) +
																						" " +
																						data.discount_amount}
																					{
																						<i
																							onClick={() =>
																								this.onDiscountEditableChange(
																									"discount_amount",
																									i
																								)
																							}
																							className="ml-2 fas fa-edit"
																						></i>
																					}
																				</>
																			)}
																		</td>
																		<td>
																			{getSymbolFromCurrency(
																				this.props
																					.country[0]
																					.currency_symbol_html
																			) +
																				" " +
																				parseFloat(
																					parseFloat(
																						data.discount_amount *
																						data.quantity
																					) +
																					parseFloat(
																						(data.discount_percentage *
																							(data.machine_total_price *
																								data.quantity)) /
																						100
																					)
																				).toFixed(0)}
																		</td>
																		<td style={{
																			backgroundColor: 'rgba(46, 204, 113, 0.4)'
																		}}>
																			{getSymbolFromCurrency(
																				this.props
																					.country[0]
																					.currency_symbol_html
																			) +
																				" " +
																				parseFloat(
																					data.machine_total_price *
																					data.quantity -
																					data.quantity *
																					data.discount_amount -
																					(data.discount_percentage *
																						data.machine_total_price *
																						data.quantity) /
																					100
																				).toFixed(0)}
																		</td>
																		<td>
																			{data.trade_in_value_editable ? (
																				<>
																					{getSymbolFromCurrency(
																						this.props
																							.country[0]
																							.currency_symbol_html
																					) + " "}
																					<input
																						onChange={e =>
																							this.onDiscountChange(
																								"trade_in_value",
																								e
																									.target
																									.value,
																								i
																							)
																						}
																						className="half"
																						value={
																							data.trade_in_value
																						}
																					/>
																					<i
																						onClick={() =>
																							this.submitDiscount(
																								"trade_in_value",
																								i
																							)
																						}
																						className="ml-2 fas fa-check"
																					></i>
																				</>
																			) : (
																				<>
																					{getSymbolFromCurrency(
																						this.props
																							.country[0]
																							.currency_symbol_html
																					) +
																						" " +
																						data.trade_in_value}
																					{
																						<i
																							onClick={() =>
																								this.onDiscountEditableChange(
																									"trade_in_value",
																									i
																								)
																							}
																							className="ml-2 fas fa-edit"
																						></i>
																					}
																				</>
																			)}
																		</td>
																		<td>
																			{getSymbolFromCurrency(
																				this.props
																					.country[0]
																					.currency_symbol_html
																			) +
																				" " +
																				parseFloat(
																					parseFloat(
																						data.machine_total_price *
																						data.quantity -
																						data.discount_amount *
																						data.quantity -
																						(data.discount_percentage *
																							data.machine_total_price *
																							data.quantity) /
																						100
																					).toFixed(0) -
																					parseFloat(
																						data.trade_in_value
																					).toFixed(
																						0
																					)
																				)}
																		</td>
																		<td>
																			{getSymbolFromCurrency(
																				this.props
																					.country[0]
																					.currency_symbol_html
																			) +
																				" " +
																				this.leaseCalcualtionFormula(
																					data.quantity *
																					parseFloat(
																						data.machine_total_price -
																						data.discount_amount +
																						(data.discount_percentage *
																							data.machine_total_price) /
																						100
																					).toFixed(
																						0
																					),
																					(data.quantity *
																						parseFloat(
																							data.machine_total_price -
																							data.discount_amount +
																							(data.discount_percentage *
																								data.machine_total_price) /
																							100
																						).toFixed(
																							0
																						)) /
																					10,
																					this.state
																						.interest_rate,
																					24
																				)}
																		</td>
																		<td>
																			{getSymbolFromCurrency(
																				this.props
																					.country[0]
																					.currency_symbol_html
																			) +
																				" " +
																				this.leaseCalcualtionFormula(
																					data.quantity *
																					parseFloat(
																						data.machine_total_price -
																						data.discount_amount +
																						(data.discount_percentage *
																							data.machine_total_price) /
																						100
																					).toFixed(
																						0
																					),
																					(data.quantity *
																						parseFloat(
																							data.machine_total_price -
																							data.discount_amount +
																							(data.discount_percentage *
																								data.machine_total_price) /
																							100
																						).toFixed(
																							0
																						)) /
																					10,
																					this.state
																						.interest_rate,
																					24
																				)}
																		</td>
																		<td>
																			{getSymbolFromCurrency(
																				this.props
																					.country[0]
																					.currency_symbol_html
																			) +
																				" " +
																				this.leaseCalcualtionFormula(
																					data.quantity *
																					parseFloat(
																						data.machine_total_price -
																						data.discount_amount +
																						(data.discount_percentage *
																							data.machine_total_price) /
																						100
																					).toFixed(
																						0
																					),
																					(data.quantity *
																						parseFloat(
																							data.machine_total_price -
																							data.discount_amount +
																							(data.discount_percentage *
																								data.machine_total_price) /
																							100
																						).toFixed(
																							0
																						)) /
																					10,
																					this.state
																						.interest_rate,
																					60
																				)}
																		</td>
																		<td>
																			{getSymbolFromCurrency(
																				this.props
																					.country[0]
																					.currency_symbol_html
																			) +
																				" " +
																				this.leaseCalcualtionFormula(
																					data.quantity *
																					parseFloat(
																						data.machine_total_price -
																						data.discount_amount +
																						(data.discount_percentage *
																							data.machine_total_price) /
																						100
																					).toFixed(
																						0
																					),
																					(data.quantity *
																						parseFloat(
																							data.machine_total_price -
																							data.discount_amount +
																							(data.discount_percentage *
																								data.machine_total_price) /
																							100
																						).toFixed(
																							0
																						)) /
																					10,
																					this.state
																						.interest_rate,
																					60
																				)}
																		</td>
																		<td>
																			{data.service_rate_editable ? (
																				<>
																					<input
																						className="half"
																						onChange={e =>
																							this.onDiscountChange(
																								"service_rate",
																								e
																									.target
																									.value,
																								i
																							)
																						}
																						value={
																							data.service_rate
																						}
																					/>
																					<i
																						onClick={() =>
																							this.submitDiscount(
																								"service_rate",
																								i
																							)
																						}
																						className="ml-2 fas fa-check"
																					></i>
																				</>
																			) : (
																				<>
																					{getSymbolFromCurrency(
																						this.props
																							.country[0]
																							.currency_symbol_html
																					) +
																						" " +
																						parseFloat(
																							data.service_rate
																						)}
																					{
																						<i
																							onClick={() =>
																								this.onDiscountEditableChange(
																									"service_rate",
																									i
																								)
																							}
																							className="ml-2 fas fa-edit"
																						></i>
																					}
																				</>
																			)}
																		</td>
																		<td>
																			{data.comments_editable ? (
																				<>
																					<input
																						style={{
																							width: 300,
																							height: 30
																						}}
																						onChange={e =>
																							this.onDiscountChange(
																								"comments",
																								e
																									.target
																									.value,
																								i
																							)
																						}
																						value={
																							data.comments
																						}
																					/>
																					<i
																						onClick={() =>
																							this.submitDiscount(
																								"comments",
																								i
																							)
																						}
																						className="ml-2 fas fa-check"
																					></i>
																				</>
																			) : (
																				<>
																					{
																						<span
																							style={{
																								width: 500
																							}}
																						>
																							{
																								data.comments
																							}
																						</span>
																					}
																					{
																						<i
																							onClick={() =>
																								this.onDiscountEditableChange(
																									"comments",
																									i
																								)
																							}
																							className="ml-2 fas fa-edit"
																						></i>
																					}
																				</>
																			)}
																		</td>
																	</tr>
																)
															)
															:
															<tr className="sweet-loading">
																<td colSpan="7" className="container loading-container">
																	<div className="row my-5 p-5">
																		<PulseLoader
																			css={override}
																			sizeUnit={"px"}
																			size={24}
																			color={"#B9373D"}
																		/>
																	</div>
																</td>
															</tr>}
													</tbody>
												</table>
											</div>
										</div>
									</Modal>
									<Modal
										title="Status Change Reason"
										visible={this.state.visible}
										onOk={this.handleOk}
										onCancel={this.handleCancel}
									>
										<h6>
											Why do you want to change the status of this
											quote? {this.state.quote_id}
										</h6>
										<div className="row">
											<div className="col">
												<div className="form-group">
													<label
														htmlFor="brand_name"
														className="control-label mb-1"
													>
														Generic Reason
													</label>
													<select
														onChange={e =>
															this.onTextChange(
																"generic_id",
																e.target.value
															)
														}
														value={
															this.state.generic_id
																? this.state.generic_id
																: null
														}
														className="custom-select"
													>
														<option>
															Select a generic reason
														</option>
														{this.state.generic_reasons.map(
															(reason, i) => (
																<option
																	key={i}
																	value={reason.generic_id}
																>
																	{reason.generic_question}
																</option>
															)
														)}
													</select>
												</div>
												<div className="form-group">
													<label
														htmlFor="brand_name"
														className="control-label mb-1"
													>
														Custom Comment
													</label>
													<input
														id="brand_name"
														name="brand_name"
														type="text"
														className="form-control"
														aria-required="true"
														onChange={e =>
															this.onTextChange(
																"custom_comment",
																e.target.value
															)
														}
														value={this.state.custom_comment}
													/>
												</div>
											</div>
										</div>
									</Modal>
								</div>
								<div className="row my-5">
									<div className="col-sm-6">
										<div className="client-config-details ml-5 mr-5 mb-5">
											<Descriptions
												title=""
												layout="hotizontal"
												bordered
												size="small"
												column={1}
											>
												<Descriptions.Item label="Quote Name">
													{
														this.props.quote.quote[0]
															.quotation_name
													}
												</Descriptions.Item>
												<Descriptions.Item label="Quote Number">
													{this.props.quote.quote[0].id}
												</Descriptions.Item>
												<Descriptions.Item label="Total Configurations">
													{this.props.quote.configurations.length}
												</Descriptions.Item>
												<Descriptions.Item label="Quote For">
													{this.props.quote.quote[0].quotation_for}
												</Descriptions.Item>
												<Descriptions.Item label="Created At">
													{this.props.quote.quote[0].created_at}
												</Descriptions.Item>
												{this.props.quote.quote[0]
													.order_opportunity !== null &&
													this.props.quote.quote[0]
														.order_opportunity !== "" ? (
													<Descriptions.Item label="Order Opportunity">
														{this.props.quote.quote[0]
															.order_opportunity + "%"}
													</Descriptions.Item>
												) : null}
												{this.props.quote.quote[0]
													.expected_delivery_date !== null &&
													this.props.quote.quote[0]
														.expected_delivery_date !== "" ? (
													<Descriptions.Item label="Expected Delivery Date">
														{this.formatDate(
															this.props.quote.quote[0]
																.expected_delivery_date
														)}
													</Descriptions.Item>
												) : null}
												{this.props.is_support ? (
													<Descriptions.Item label="Created By">
														{this.props.quote.quote[0].name}
													</Descriptions.Item>
												) : null}
											</Descriptions>
										</div>
									</div>
									<div className="col-sm-4">
										{this.props.user_id ===
											this.props.quote.quote[0].user_id ? (
											!this.state.ordered ? (
												<>
													<div className="float-left text-center mb-2 col-sm-12">
														<Link
															to={"/edit-quote/" + this.state.id}
															className="btn-block client-login"
														>
															Change quote name
														</Link>
													</div>
													<div className="float-left text-center mt-2 mb-2 col-sm-12">
														<Link
															to={
																"/add-configuration-quote/" +
																this.state.id
															}
															className="btn-block client-login"
														>
															Add configurations
														</Link>
													</div>
												</>
											) : null
										) : null}

										{this.state.template_editable ? (
											<>
												<div className="float-left text-center mt-2 mb-2 col-sm-12">
													<select
														onChange={e =>
															this.onTemplateChange(
																e.target.value
															)
														}
														className="custom-select mb-2"
														value={this.state.current_template_id}
													>
														{this.state.available_templates
															? this.state.available_templates.map(
																(template, i) => (
																	<option
																		value={
																			template.template_id
																		}
																	>
																		{
																			template.template_name
																		}
																	</option>
																)
															)
															: null}
													</select>
													<button
														onClick={this.submitTemplate}
														className="btn-block client-login text-white"
													>
														<i className="fas fa-check"></i>
													</button>
												</div>
											</>
										) : (
											<div className="float-left text-center mt-2 mb-2 col-sm-12">
												<button
													onClick={() => this.templateEditable()}
													className="btn-block client-login"
												>
													Quote template change
												</button>
											</div>
										)}
										{
											this.props.is_dealer || !this.props.need_approval ?
												<>
													<div className="float-left text-center mt-2 mb-2 col-sm-12">
														{/* <a
															className="btn-block client-login"
															rel="noopener noreferrer"
															target="_blank"
															href={
																config.APP_URL +
																"/api/storage/" +
																this.props.quote.quote[0]
																	.quotation_file_doc
															}
															download
														>
															Download quote documentxxxxxx
													</a> */}
													<button
														onClick={()=>this.downloadDocx(this.props.quote.quote[0].quotation_file_doc)}
											// this.onDownloadDocs(this.props.quote.quote[0].id)}
																className="btn-block client-login"
															>
																Download quote document
															</button>
													</div>
													<div className="float-left text-center mt-2 mb-2 col-sm-12">
														{/* <a
															className="btn-block client-login"
															rel="noopener noreferrer"
															target="_blank"
															href={
																config.APP_URL +
																"/api/storage/" +
																this.props.quote.quote[0]
																	.quotation_file_xls
															}
															download
														>
															Download quote excelxxxxxxxxx
													</a> */}
													<button
																onClick={()=>this.downloadXlsx(this.props.quote.quote[0].quotation_file_xls)}
																// onClick={()=>this.onDownloadExcel(this.props.quote.quote[0].id)}
																className="btn-block client-login"
															>
																Download quote excel
															</button>
													</div>
												</>
												:
												this.props.quote.quote[0].approve_request === 1 ?
													<>
														<div className="float-left text-center mt-2 mb-2 col-sm-12">
															{/* <a
																className="btn-block client-login"
																rel="noopener noreferrer"
																target="_blank"
																href={
																	config.APP_URL +
																	"/api/storage/" +
																	this.props.quote.quote[0]
																		.quotation_file_doc
																}
																download
															>
																Download quote documentxx
													</a> */}
													<button
																onClick={()=>this.downloadDocx(this.props.quote.quote[0].quotation_file_doc)}
																// onClick={()=>this.onDownloadDocs(this.props.quote.quote[0].id)}
																className="btn-block client-login"
															>
																Download quote document
															</button>
														</div>
														<div className="float-left text-center mt-2 mb-2 col-sm-12">
															{/* <a
																className="btn-block client-login"
																rel="noopener noreferrer"
																target="_blank"
																href={
																	config.APP_URL +
																	"/api/storage/" +
																	this.props.quote.quote[0]
																		.quotation_file_xls
																}
																download
															>
																Download quote excel
													</a> */}
														<button
																onClick={()=>this.downloadXlsx(this.props.quote.quote[0].quotation_file_xls)}
																// onClick={()=>this.onDownloadExcel(this.props.quote.quote[0].id)}
																className="btn-block client-login"
															>
																Download quote excel
															</button>
														</div>
													</>
													: null

										}
										{/* <div className="float-left text-center mt-2 mb-2 col-sm-12">
											<a
												className="btn-block client-login"
												rel="noopener noreferrer"
												target="_blank"
												href={
													config.APP_URL +
													"/api/storage/" +
													this.props.quote.quote[0]
														.quotation_file_doc
												}
												download
											>
												Download quote document
											</a>
										</div>
										<div className="float-left text-center mt-2 mb-2 col-sm-12">
											<a
												className="btn-block client-login"
												rel="noopener noreferrer"
												target="_blank"
												href={
													config.APP_URL +
													"/api/storage/" +
													this.props.quote.quote[0]
														.quotation_file_xls
												}
												download
											>
												Download quote excel
											</a>
										</div> */}
										{!this.state.ordered ? (
											<div className="float-left text-center mt-2 mb-2 col-sm-12">
												<button
													onClick={this.onOrderQuote}
													className="btn-block client-login"
												>
													Order this quote
												</button>
											</div>
										) : null}
										{(
											!this.state.orderOpportunityOn ? (
												<div className="float-left text-center mt-2 mb-2 col-sm-12">
													<button
														onClick={
															this.handleOrderOpportunityButton
														}
														className="btn-block client-login"
													>
														Order opportunity
													</button>
												</div>
											) : (
												<>
													<div className="float-left text-center mt-2 mb-2 col-sm-12">
														<select
															onChange={e =>
																this.onTextChange(
																	"order_opportunity",
																	e.target.value
																)
															}
															className="half custom-select mb-2"
															value={this.state.orderOpportunity}
														>
															<option value={0}>0 %</option>
															<option value={10}>10 %</option>
															<option value={30}>30 %</option>
															<option value={70}>70 %</option>
															<option value={90}>90 %</option>
														</select>
														<button
															onClick={this.editOrderOpportunity}
															className="btn-block client-login"
														>
															<i className="fas fa-check"></i>
														</button>
													</div>
												</>
											)
										)}
										{(
											!this.state.expectedDeliveryDateOn ? (
												<div className="float-left text-center mt-2 mb-2 col-sm-12">
													<button
														onClick={
															this
																.handleExpectedDeliveryDateButton
														}
														className="btn-block client-login"
													>
														Expected delivery date
													</button>
												</div>
											) : (
												<div className="float-left text-center mt-2 mb-2 col-sm-12">
													<Calendar
														dateFormat="yy-mm-dd"
														showIcon={true}
														value={
															this.state.expectedDeliveryDate !==
																null &&
																this.state.expectedDeliveryDate !==
																undefined &&
																this.state.expectedDeliveryDate !==
																"" &&
																this.state.expectedDeliveryDate
																? new Date(
																	this.state.expectedDeliveryDate
																)
																: new Date()
														}
														className="mb-2"
														onChange={e =>
															this.handleExpectedDeliveryDateChange(
																e.value
															)
														}
													></Calendar>
													<button
														onClick={
															this.editExpectedDeliveryDate
														}
														className="btn-block client-login"
													>
														<i className="fas fa-check"></i>
													</button>
												</div>
											)
										)}
										{this.props.project_overview_visible ? (
											<div className="float-left text-center mt-2 mb-2 col-sm-12">
												<button
													onClick={this.showProjectOveriewModal}
													className="btn-block client-login"
												>
													Project overview
												</button>
											</div>
										) : null}

										{this.state.modifyOn ? (
											<div className="float-left text-center mt-2 mb-2 col-sm-12">
												<select
													className="custom-select"
													style={{
														borderRadius: 4
													}}
													defaultValue={this.state.quotationStatus}
													onChange={e =>
														this.onSelectChange(e.target.value)
													}
												>
													<option value="pending">Pending</option>
													<option value="lost">Lost</option>
													<option value="postponed">
														Postponed
													</option>
												</select>
												<button
													onClick={this.handleStatusChange}
													className="mt-2 btn-block client-login"
												>
													Confirm New Status
												</button>
											</div>
										) : null}
										{!this.state.modifyOn ? (
											<div className="float-left text-center mt-2 mb-2 col-sm-12">
												<button
													onClick={this.modificationChange}
													className="btn-block client-login"
												>
													Quote status
												</button>
											</div>
										) : null}

										<div className="float-left text-center mt-2 mb-2 col-sm-12">
											<button
												onClick={() =>
													this.deleteQuote(this.state.id)
												}
												className="btn-block client-login"
											>
												Delete Quote
											</button>
										</div>
										{
											this.props.higher_discount_request ?
												<div className="float-left text-center mt-2 mb-2 col-sm-12">
													<button
														onClick={() =>
															this.setState({ bulkDiscountVisibility: true })
														}
														className="btn-block client-login"
													>
														Multiple Discount Request
												</button>
												</div> : null
										}
										{
											this.props.quote ?
												this.props.quote.quote[0].approve_request === 0 ?
													this.props.quote.quote[0].user_id === this.props.user_id ?
														!this.props.is_dealer && this.props.need_approval ?
															this.props.quote.quote[0].approve_pending === 0 ?
																<div className="float-left text-center mt-2 mb-2 col-sm-12">
																	<button
																		onClick={() =>
																			this.handlerequestApprovalNotification()
																		}
																		className="btn-block client-login"
																	>
																		Request Approval
																	</button>
																</div> :
																<div className="float-left text-center mt-2 mb-2 col-sm-12">
																	<button
																		onClick={() =>
																			this.handlerequestApprovalNotification()
																		}
																		disabled
																		className="btn-block client-login"
																	>
																		Approval Pending
																	</button>
																</div> : null :
														<div className="float-left text-center mt-2 mb-2 col-sm-12">
															<button
																onClick={() =>
																	this.handleapprovedQuoteNotification()
																}
																className="btn-block client-login"
															>
																Approve Request
																</button>
														</div>
													: null
												: null
										}
									</div>
								</div>
								<div className="row my-5">
									<div className="col-sm-10 ml-5">
										<h5 className="text-center my-3 client-roboto-condensed">
											Configurations in this Quote
										</h5>
										<table className="table table-striped table-bordered">
											<thead>
												<tr>
													<th>Configuration Name</th>
													<th>Quantity</th>
													{!this.props.is_dealer && this.props.need_approval ?
														<th>Approval Status</th> : null
													}
													<th>Brand</th>
													<th>Actions</th>
												</tr>
											</thead>
											<tbody>
												{
													this.props.quote ?
														this.props.quote.configurations.map((configuration, i) =>
															<tr key={i}>
																<td>{configuration.name}</td>
																<td>{configuration.quantity}</td>
																<td>{configuration.brand_name}</td>
																{!this.props.is_dealer && this.props.need_approval ?
																	<td>
																		{
																			configuration.approve_request === 1 ?
																				'Approved'
																				: 'Not Approved'
																		}
																	</td> : null
																}
																<td>
																	<button
																		href="#"
																		style={{
																			background: "none",
																			color: "inherit",
																			border: "none",
																			padding: 0,
																			font: "inherit",
																			borderBottom: 1,
																			cursor: "pointer"
																		}}
																		onClick={() => this.showModal(configuration.id)}
																		className="red-link mr-2"
																		data-toggle="modal"
																		data-target="#exampleModal"
																	>
																		See Configuration
																	</button>
																	<br />
																	{!this.props.quote.quote[0].status
																		.toLowerCase()
																		.includes("submitted order") ? (
																		<Link
																			className="red-link"
																			to={"/edit-configuration-process/" + configuration.id}
																		>
																			Edit Configuration
																		</Link>
																	) : null}
																	<br />
																	{this.props.log_visibility ? (
																		<button
																			href="#"
																			style={{
																				background: "none",
																				color: "inherit",
																				border: "none",
																				padding: 0,
																				font: "inherit",
																				borderBottom: 1,
																				cursor: "pointer"
																			}}
																			className="red-link"
																			onClick={() => this.loadLogsForConfiguration(configuration.id)}
																		>
																			Configuration History
																		</button>
																	) : null}
																	<br />
																	{!this.state.ordered &&
																		this.props.quote.configurations.length > 1 ? (
																		<button
																			style={{
																				background: "none",
																				color: "inherit",
																				border: "none",
																				padding: 0,
																				font: "inherit",
																				borderBottom: 1,
																				cursor: "pointer"
																			}}
																			onClick={() => this.removeConfiguration(configuration.id)}
																			className="red-link"
																		>
																			Remove Configuration
																		</button>
																	) : null}
																	{
																		!this.props.is_dealer || this.props.need_approval ?
																			// this.props.is_manager ?
																			configuration.user_id !== this.props.user_id ?
																				configuration.approve_request === 1 ?
																					null :
																					<>
																						<button
																							style={{
																								background: "none",
																								color: "inherit",
																								border: "none",
																								padding: 0,
																								font: "inherit",
																								borderBottom: 1,
																								cursor: "pointer"
																							}}
																							className="red-link"
																							onClick={() => this.approveConfiguration(configuration.id)}
																						>
																							Approve
																						</button>
																						<br />
																						{/* <Link
																								to={"/edit-configuration-process/" + configuration.id + '/approval'}
																								style={{
																									background: "none",
																									color: "inherit",
																									border: "none",
																									padding: 0,
																									font: "inherit",
																									borderBottom: 1,
																									cursor: "pointer"
																								}}
																								className="red-link"
																							>
																								Approve with Edit
																						</Link> */}
																					</> : null : null
																		// : null
																	}</td>
															</tr>
														) : null
												}
											</tbody>
										</table>
									</div>
								</div>
							</div>
							{
								<div className="container">
									<div
										className="modal fade"
										id="exampleModal"
										tabIndex="-1"
										role="dialog"
										aria-labelledby="exampleModalLabel"
										aria-hidden="true"
									>
										<div className="modal-dialog" role="document">
											<div className="modal-content">
												<div className="modal-header">
													<h6
														className="modal-title"
														id="exampleModalLabel"
													>
														Configuration Details
													</h6>
													<button
														type="button"
														className="close"
														data-dismiss="modal"
														aria-label="Close"
													>
														<span aria-hidden="true">
															&times;
														</span>
													</button>
												</div>
												{this.props.configuration &&
													!this.props.configuration_loading ? (
													<div className="modal-body">
														<div className="row m-5">
															<div className="col-sm-4">
																{this.props.configuration[0]
																	.machine_category_image !==
																	[] &&
																	this.props.configuration[0]
																		.machine_category_image !==
																	null ? (
																	<img loading="lazy"
																		width="100%"
																		className="img-responsive"
																		alt=""
																		src={
																			config.APP_URL +
																			"/api/storage/" +
																			this.props
																				.configuration[0]
																				.machine_category_image
																		}
																	/>
																) : (
																	<img loading="lazy"
																		width="100%"
																		className="img-responsive"
																		alt=""
																		src={noimage}
																	/>
																)}
															</div>
															<div className="col-sm-8">
																<div className="client-config-details ml-5 mr-5 mb-5">
																	<Descriptions
																		title=""
																		layout="hotizontal"
																		bordered
																		size="small"
																		column={1}
																	>
																		<Descriptions.Item label="Configuration Name">
																			{this.props
																				.configuration !==
																				[]
																				? this.props
																					.configuration[0]
																					.name
																				: ""}
																		</Descriptions.Item>
																		<Descriptions.Item label="Product Category">
																			{this.props
																				.configuration !==
																				[]
																				? this.props
																					.configuration[0]
																					.machine_category
																				: ""}
																		</Descriptions.Item>
																		<Descriptions.Item label="Retail Price">
																			{this.props
																				.configuration !==
																				[]
																				? parseFloat(
																					this.props
																						.configuration[0]
																						.machine_total_price /
																					this.props
																						.configuration[0]
																						.quantity
																				).toFixed(2)
																				: ""}{" "}
																			{getSymbolFromCurrency(
																				this.props
																					.country[0]
																					.currency_symbol_html
																			)}
																		</Descriptions.Item>
																		{this.props
																			.configuration !==
																			[] &&
																			this.props
																				.configuration[0]
																				.discount_percentage !==
																			0 ? (
																			this.props
																				.is_dealer ? (
																				<Descriptions.Item label="Standard Dealer Terms %">
																					{
																						this.props
																							.configuration[0]
																							.discount_percentage
																					}{" "}
																					%
																				</Descriptions.Item>
																			) : (
																				<>
																					<Descriptions.Item label="Discount Percentage">
																						{
																							this.props
																								.configuration[0]
																								.discount_percentage
																						}{" "}
																						%
																					</Descriptions.Item>
																					<Descriptions.Item label="Discount Percentage Amount">
																						<CurrencyFormat
																							className="py-1 pr-2 text-right"
																							displayType={
																								"text"
																							}
																							decimalSeparator={
																								"."
																							}
																							decimalScale={
																								2
																							}
																							fixedDecimalScale={
																								true
																							}
																							value={parseFloat(
																								((this
																									.props
																									.configuration[0]
																									.machine_total_price /
																									this
																										.props
																										.configuration[0]
																										.quantity) *
																									this
																										.props
																										.configuration[0]
																										.discount_percentage) /
																								100
																							).toFixed(
																								2
																							)}
																							thousandSeparator={
																								true
																							}
																							suffix={
																								" " +
																								getSymbolFromCurrency(
																									this
																										.props
																										.configuration[0]
																										.currency_symbol_html
																								)
																							}
																						/>
																					</Descriptions.Item>
																				</>
																			)
																		) : null}
																		{this.props
																			.configuration !==
																			[] &&
																			this.props
																				.configuration[0]
																				.discount_amount !==
																			0 ? (
																			this.props
																				.is_dealer ? (
																				<Descriptions.Item label="Dealer Discount Amount">
																					{
																						this.props
																							.configuration[0]
																							.discount_amount
																					}{" "}
																					{getSymbolFromCurrency(
																						this.props
																							.configuration[0]
																							.currency_symbol_html
																					)}
																				</Descriptions.Item>
																			) : (
																				<Descriptions.Item label="Discount Amount">
																					{
																						this.props
																							.configuration[0]
																							.discount_amount
																					}{" "}
																					{getSymbolFromCurrency(
																						this.props
																							.configuration[0]
																							.currency_symbol_html
																					)}
																				</Descriptions.Item>
																			)
																		) : null}

																		{this.props
																			.configuration !==
																			[] &&
																			this.props
																				.configuration[0]
																				.dealer_discount_amount !==
																			0 ? (
																			this.props
																				.extra_discount_visible ? (
																				<Descriptions.Item label="Extra Discount">
																					{
																						this.props
																							.configuration[0]
																							.dealer_discount_amount
																					}{" "}
																					{getSymbolFromCurrency(
																						this.props
																							.configuration[0]
																							.currency_symbol_html
																					)}
																				</Descriptions.Item>
																			) : null
																		) : null}
																		{this.props.is_dealer ? (
																			<Descriptions.Item label="Dealer Net Total">
																				{this.props
																					.configuration !==
																					[] ? (
																					<CurrencyFormat
																						className="py-1 pr-2 text-right"
																						displayType={
																							"text"
																						}
																						decimalSeparator={
																							"."
																						}
																						decimalScale={
																							2
																						}
																						fixedDecimalScale={
																							true
																						}
																						value={parseFloat(
																							this.props
																								.configuration[0]
																								.machine_total_price /
																							this
																								.props
																								.configuration[0]
																								.quantity -
																							(this
																								.props
																								.configuration[0]
																								.discount_percentage *
																								(this
																									.props
																									.configuration[0]
																									.machine_total_price /
																									this
																										.props
																										.configuration[0]
																										.quantity)) /
																							100 -
																							this
																								.props
																								.configuration[0]
																								.discount_amount -
																							this
																								.props
																								.configuration[0]
																								.dealer_discount_amount
																						)}
																						thousandSeparator={
																							true
																						}
																						suffix={
																							" " +
																							getSymbolFromCurrency(
																								this
																									.props
																									.configuration[0]
																									.currency_symbol_html
																							)
																						}
																					/>
																				) : null}
																			</Descriptions.Item>
																		) : (
																			<Descriptions.Item label="Net Total">
																				{this.props
																					.configuration !==
																					[] ? (
																					<CurrencyFormat
																						className="py-1 pr-2 text-right"
																						displayType={
																							"text"
																						}
																						decimalSeparator={
																							"."
																						}
																						decimalScale={
																							2
																						}
																						fixedDecimalScale={
																							true
																						}
																						value={parseFloat(
																							this.props
																								.configuration[0]
																								.machine_total_price /
																							this
																								.props
																								.configuration[0]
																								.quantity -
																							(this
																								.props
																								.configuration[0]
																								.discount_percentage *
																								(this
																									.props
																									.configuration[0]
																									.machine_total_price /
																									this
																										.props
																										.configuration[0]
																										.quantity)) /
																							100 -
																							this
																								.props
																								.configuration[0]
																								.discount_amount -
																							this
																								.props
																								.configuration[0]
																								.dealer_discount_amount
																						)}
																						thousandSeparator={
																							true
																						}
																						suffix={
																							" " +
																							getSymbolFromCurrency(
																								this
																									.props
																									.configuration[0]
																									.currency_symbol_html
																							)
																						}
																					/>
																				) : null}
																			</Descriptions.Item>
																		)}

																		{this.props.is_dealer ? (
																			<>
																				<Descriptions.Item label="OEM Programme">
																					{
																						this.props
																							.configuration[0]
																							.oem_discount
																					}
																					{" " +
																						getSymbolFromCurrency(
																							this.props
																								.configuration[0]
																								.currency_symbol_html
																						)}
																				</Descriptions.Item>
																				<Descriptions.Item label="Net Net Purchase Price">
																					<CurrencyFormat
																						className="py-1 pr-2 text-right"
																						displayType={
																							"text"
																						}
																						decimalSeparator={
																							"."
																						}
																						decimalScale={
																							2
																						}
																						fixedDecimalScale={
																							true
																						}
																						value={parseFloat(
																							this.props
																								.configuration[0]
																								.machine_total_price /
																							this
																								.props
																								.configuration[0]
																								.quantity -
																							(this
																								.props
																								.configuration[0]
																								.discount_percentage *
																								(this
																									.props
																									.configuration[0]
																									.machine_total_price /
																									this
																										.props
																										.configuration[0]
																										.quantity)) /
																							100 -
																							this
																								.props
																								.configuration[0]
																								.discount_amount -
																							this
																								.props
																								.configuration[0]
																								.dealer_discount_amount -
																							this
																								.props
																								.configuration[0]
																								.oem_discount
																						).toFixed(2)}
																						thousandSeparator={
																							true
																						}
																						suffix={
																							" " +
																							getSymbolFromCurrency(
																								this
																									.props
																									.configuration[0]
																									.currency_symbol_html
																							)
																						}
																					/>
																				</Descriptions.Item>
																			</>
																		) : null}
																		{this.props.is_dealer ? (
																			<>
																				<Descriptions.Item label="Extra Discount">
																					{
																						this.props
																							.configuration[0]
																							.extra_discount
																					}
																					{" %"}
																				</Descriptions.Item>
																				<Descriptions.Item label="Reesink Extra Extra Support">
																					<CurrencyFormat
																						className="py-1 pr-2 text-right"
																						displayType={
																							"text"
																						}
																						decimalSeparator={
																							"."
																						}
																						decimalScale={
																							2
																						}
																						fixedDecimalScale={
																							true
																						}
																						value={parseFloat(
																							(this.props
																								.configuration[0]
																								.extra_discount *
																								parseFloat(
																									this
																										.props
																										.configuration[0]
																										.machine_total_price /
																									this
																										.props
																										.configuration[0]
																										.quantity -
																									(this
																										.props
																										.configuration[0]
																										.discount_percentage *
																										(this
																											.props
																											.configuration[0]
																											.machine_total_price /
																											this
																												.props
																												.configuration[0]
																												.quantity)) /
																									100 -
																									this
																										.props
																										.configuration[0]
																										.discount_amount -
																									this
																										.props
																										.configuration[0]
																										.dealer_discount_amount -
																									this
																										.props
																										.configuration[0]
																										.oem_discount
																								).toFixed(
																									2
																								)) /
																							100
																						).toFixed(2)}
																						thousandSeparator={
																							true
																						}
																						suffix={
																							" " +
																							getSymbolFromCurrency(
																								this
																									.props
																									.configuration[0]
																									.currency_symbol_html
																							)
																						}
																					/>
																				</Descriptions.Item>
																			</>
																		) : null}
																		{this.props.is_dealer ? (
																			<Descriptions.Item label="Net Net Net Price">
																				<CurrencyFormat
																					className="py-1 pr-2 text-right"
																					displayType={
																						"text"
																					}
																					decimalSeparator={
																						"."
																					}
																					decimalScale={2}
																					fixedDecimalScale={
																						true
																					}
																					value={parseFloat(
																						parseFloat(
																							this.props
																								.configuration[0]
																								.machine_total_price /
																							this
																								.props
																								.configuration[0]
																								.quantity -
																							(this
																								.props
																								.configuration[0]
																								.discount_percentage *
																								(this
																									.props
																									.configuration[0]
																									.machine_total_price /
																									this
																										.props
																										.configuration[0]
																										.quantity)) /
																							100 -
																							this
																								.props
																								.configuration[0]
																								.discount_amount -
																							this
																								.props
																								.configuration[0]
																								.dealer_discount_amount -
																							this
																								.props
																								.configuration[0]
																								.oem_discount
																						).toFixed(2) -
																						parseFloat(
																							(this
																								.props
																								.configuration[0]
																								.extra_discount *
																								parseFloat(
																									this
																										.props
																										.configuration[0]
																										.machine_total_price /
																									this
																										.props
																										.configuration[0]
																										.quantity -
																									(this
																										.props
																										.configuration[0]
																										.discount_percentage *
																										(this
																											.props
																											.configuration[0]
																											.machine_total_price /
																											this
																												.props
																												.configuration[0]
																												.quantity)) /
																									100 -
																									this
																										.props
																										.configuration[0]
																										.discount_amount -
																									this
																										.props
																										.configuration[0]
																										.dealer_discount_amount -
																									this
																										.props
																										.configuration[0]
																										.oem_discount
																								).toFixed(
																									2
																								)) /
																							100
																						).toFixed(
																							2
																						)
																					).toFixed(2)}
																					thousandSeparator={
																						true
																					}
																					suffix={
																						" " +
																						getSymbolFromCurrency(
																							this.props
																								.configuration[0]
																								.currency_symbol_html
																						)
																					}
																				/>
																			</Descriptions.Item>
																		) : null
																		}
																		{this.props
																			.configuration !==
																			[] &&
																			this.props
																				.configuration[0]
																				.sales_margin !== 0 ? (
																			this.props
																				.sales_margin_visible ? (
																				this.props
																					.is_dealer ? (
																					<>
																						<Descriptions.Item label="End Customer Purchase Price">
																							{parseFloat(
																								this
																									.props
																									.configuration[0]
																									.end_user_sales_price
																							).toFixed(
																								2
																							)}{" "}
																							{getSymbolFromCurrency(
																								this
																									.props
																									.country[0]
																									.currency_symbol_html
																							)}
																						</Descriptions.Item>
																						<Descriptions.Item label="Dealer Margin Amount">
																							<CurrencyFormat
																								className="py-1 pr-2 text-right"
																								displayType={
																									"text"
																								}
																								decimalSeparator={
																									"."
																								}
																								decimalScale={
																									2
																								}
																								fixedDecimalScale={
																									true
																								}
																								value={
																									this
																										.props
																										.configuration[0]
																										.sales_margin_amount
																								}
																								thousandSeparator={
																									true
																								}
																								suffix={
																									" " +
																									getSymbolFromCurrency(
																										this
																											.props
																											.configuration[0]
																											.currency_symbol_html
																									)
																								}
																							/>
																						</Descriptions.Item>
																						<Descriptions.Item label="Dealer Margin">
																							{
																								this
																									.props
																									.configuration[0]
																									.sales_margin
																							}
																							{" %"}
																						</Descriptions.Item>
																					</>
																				) : (
																					<>
																						<Descriptions.Item label="Sales Margin">
																							{
																								this
																									.props
																									.configuration[0]
																									.sales_margin
																							}
																							{" %"}
																						</Descriptions.Item>
																						<Descriptions.Item label="Total Invoice for customer">
																							{parseFloat(
																								this
																									.props
																									.configuration[0]
																									.end_user_sales_price
																							).toFixed(
																								2
																							)}{" "}
																							{getSymbolFromCurrency(
																								this
																									.props
																									.country[0]
																									.currency_symbol_html
																							)}
																						</Descriptions.Item>
																					</>
																				)
																			) : null
																		) : null}
																		{this.props
																			.customer_discount_visible &&
																			!this.props.is_dealer ? (
																			<Descriptions.Item label="Customer Discount">
																				{parseFloat(
																					(Math.abs(
																						this.props
																							.configuration[0]
																							.machine_total_price -
																						parseFloat(
																							this
																								.props
																								.configuration[0]
																								.machine_total_price -
																							(this
																								.props
																								.configuration[0]
																								.machine_total_price *
																								this
																									.props
																									.configuration[0]
																									.discount_percentage) /
																							100 -
																							this
																								.props
																								.configuration[0]
																								.discount_amount -
																							this
																								.props
																								.configuration[0]
																								.dealer_discount_amount -
																							this
																								.props
																								.configuration[0]
																								.oem_discount -
																							parseFloat(
																								(this
																									.props
																									.configuration[0]
																									.extra_discount *
																									parseFloat(
																										this
																											.props
																											.configuration[0]
																											.machine_total_price /
																										this
																											.props
																											.configuration[0]
																											.quantity -
																										(this
																											.props
																											.configuration[0]
																											.discount_percentage *
																											(this
																												.props
																												.configuration[0]
																												.machine_total_price /
																												this
																													.props
																													.configuration[0]
																													.quantity)) /
																										100 -
																										this
																											.props
																											.configuration[0]
																											.discount_amount -
																										this
																											.props
																											.configuration[0]
																											.dealer_discount_amount -
																										this
																											.props
																											.configuration[0]
																											.oem_discount
																									).toFixed(
																										2
																									)) /
																								100
																							).toFixed(
																								2
																							) +
																							(this
																								.props
																								.configuration[0]
																								.sales_margin *
																								(this
																									.props
																									.configuration[0]
																									.machine_total_price -
																									(this
																										.props
																										.configuration[0]
																										.machine_total_price *
																										this
																											.props
																											.configuration[0]
																											.discount_percentage) /
																									100 -
																									this
																										.props
																										.configuration[0]
																										.discount_amount -
																									this
																										.props
																										.configuration[0]
																										.dealer_discount_amount -
																									this
																										.props
																										.configuration[0]
																										.oem_discount -
																									parseFloat(
																										(this
																											.props
																											.configuration[0]
																											.extra_discount *
																											parseFloat(
																												this
																													.props
																													.configuration[0]
																													.machine_total_price /
																												this
																													.props
																													.configuration[0]
																													.quantity -
																												(this
																													.props
																													.configuration[0]
																													.discount_percentage *
																													(this
																														.props
																														.configuration[0]
																														.machine_total_price /
																														this
																															.props
																															.configuration[0]
																															.quantity)) /
																												100 -
																												this
																													.props
																													.configuration[0]
																													.discount_amount -
																												this
																													.props
																													.configuration[0]
																													.dealer_discount_amount -
																												this
																													.props
																													.configuration[0]
																													.oem_discount
																											).toFixed(
																												2
																											)) /
																										100
																									).toFixed(
																										2
																									))) /
																							100
																						).toFixed(
																							2
																						)
																					) /
																						this.props
																							.configuration[0]
																							.machine_total_price) *
																					100
																				).toFixed(2)}
																				{" %"}
																			</Descriptions.Item>
																		) : null}
																		{this.props
																			.configuration !==
																			[] &&
																			this.props
																				.configuration[0]
																				.quantity !== 0 ? (
																			<Descriptions.Item label="Configuration Quantity">
																				{
																					this.props
																						.configuration[0]
																						.quantity
																				}
																			</Descriptions.Item>
																		) : null}
																		<Descriptions.Item label="Final Net total">
																			{this.props
																				.configuration !==
																				[] ? (
																				<CurrencyFormat
																					className="py-1 pr-2 text-right"
																					displayType={
																						"text"
																					}
																					decimalSeparator={
																						"."
																					}
																					decimalScale={2}
																					fixedDecimalScale={
																						true
																					}
																					value={
																						parseFloat(
																							this.props
																								.configuration[0]
																								.machine_total_price /
																							this
																								.props
																								.configuration[0]
																								.quantity -
																							(this
																								.props
																								.configuration[0]
																								.discount_percentage *
																								(this
																									.props
																									.configuration[0]
																									.machine_total_price /
																									this
																										.props
																										.configuration[0]
																										.quantity)) /
																							100 -
																							this
																								.props
																								.configuration[0]
																								.discount_amount -
																							this
																								.props
																								.configuration[0]
																								.dealer_discount_amount -
																							this
																								.props
																								.configuration[0]
																								.oem_discount -
																							parseFloat(
																								(this
																									.props
																									.configuration[0]
																									.extra_discount *
																									parseFloat(
																										this
																											.props
																											.configuration[0]
																											.machine_total_price /
																										this
																											.props
																											.configuration[0]
																											.quantity -
																										(this
																											.props
																											.configuration[0]
																											.discount_percentage *
																											(this
																												.props
																												.configuration[0]
																												.machine_total_price /
																												this
																													.props
																													.configuration[0]
																													.quantity)) /
																										100 -
																										this
																											.props
																											.configuration[0]
																											.discount_amount -
																										this
																											.props
																											.configuration[0]
																											.dealer_discount_amount -
																										this
																											.props
																											.configuration[0]
																											.oem_discount
																									).toFixed(
																										2
																									)) /
																								100
																							).toFixed(
																								2
																							)
																						) *
																						this.props
																							.configuration[0]
																							.quantity
																					}
																					thousandSeparator={
																						true
																					}
																					suffix={
																						" " +
																						getSymbolFromCurrency(
																							this.props
																								.configuration[0]
																								.currency_symbol_html
																						)
																					}
																				/>
																			) : null}
																		</Descriptions.Item>
																		<Descriptions.Item label="Parts exchange (trade-in)">
																			{this.props.configuration[0].trade_in_value}
																		</Descriptions.Item>
																		<Descriptions.Item label="Final Net total">
																			{this.props
																				.configuration !==
																			[] ? (
																				<CurrencyFormat
																					className="py-1 pr-2 text-right"
																					displayType={"text"}
																					decimalSeparator={
																						"."
																					}
																					decimalScale={2}
																					fixedDecimalScale={
																						true
																					}
																					value={
																						parseFloat(
																							this.props
																								.configuration[0]
																								.machine_total_price /
																							this.props
																								.configuration[0]
																								.quantity -
																							(this.props
																									.configuration[0]
																									.discount_percentage *
																								(this
																										.props
																										.configuration[0]
																										.machine_total_price /
																									this
																										.props
																										.configuration[0]
																										.quantity)) /
																							100 -
																							this.props
																								.configuration[0]
																								.discount_amount -
																							this.props
																								.configuration[0]
																								.dealer_discount_amount -
																							this.props
																								.configuration[0]
																								.oem_discount -
																							parseFloat(
																								(this
																										.props
																										.configuration[0]
																										.extra_discount *
																									parseFloat(
																										this
																											.props
																											.configuration[0]
																											.machine_total_price /
																										this
																											.props
																											.configuration[0]
																											.quantity -
																										(this
																												.props
																												.configuration[0]
																												.discount_percentage *
																											(this
																													.props
																													.configuration[0]
																													.machine_total_price /
																												this
																													.props
																													.configuration[0]
																													.quantity)) /
																										100 -
																										this
																											.props
																											.configuration[0]
																											.discount_amount -
																										this
																											.props
																											.configuration[0]
																											.dealer_discount_amount -
																										this
																											.props
																											.configuration[0]
																											.oem_discount
																									).toFixed(
																										2
																									)) /
																								100
																							).toFixed(
																								2
																							)
																						) *
																						this.props
																							.configuration[0]
																							.quantity - this.props.configuration[0].trade_in_value
																					}
																					thousandSeparator={
																						true
																					}
																					suffix={
																						" " +
																						getSymbolFromCurrency(
																							this.props
																								.configuration[0]
																								.currency_symbol_html
																						)
																					}
																				/>
																			) : null}
																		</Descriptions.Item>
																	</Descriptions>
																</div>
															</div>
														</div>
														<div className="row m-5">
															<div className="client-selected-parts">
																<table className="table table-striped table-responsive-md">
																	<thead className="thead-dark">
																		<tr>
																			<th
																				scope="col"
																				className="min-width-table"
																			>
																				Model Number
																			</th>
																			<th
																				scope="col"
																				className="min-width-table"
																			>
																				Description
																			</th>
																			<th
																				scope="col"
																				className="min-width-table"
																			>
																				Price
																			</th>
																			<th
																				scope="col"
																				className="min-width-table"
																			>
																				Quantity
																			</th>
																			<th
																				scope="col"
																				className="min-width-table"
																			>
																				Total Price
																			</th>
																		</tr>
																		<tr
																			style={{
																				backgroundColor:
																					"#B9373D",
																				color: "#ffffff"
																			}}
																		>
																			<td
																				style={{
																					color: "white",
																					fontWeight: 900,
																					fontSize: 15
																				}}
																			>
																				Components
																			</td>
																			<td></td>
																			<td></td>
																			<td></td>
																			<td></td>
																		</tr>
																	</thead>
																	<tbody>
																		<tr>
																			<td className="min-width-table">
																				{this.props
																					.category_details
																					? this.props
																						.category_details[0]
																						.machine_category_model_number
																					: null}
																			</td>
																			<td className="min-width-table">
																				{this.props
																					.category_details
																					? this.props
																						.category_details[0]
																						.machine_category
																					: null}
																			</td>
																			<td className="money min-width-table">
																				{getSymbolFromCurrency(
																					this.props
																						.category_details[0]
																						.currency_symbol_html
																				)}{" "}
																				{this.props
																					.category_details
																					? parseFloat(
																						this.props
																							.category_details[0]
																							.price
																					).toFixed(2)
																					: null}
																			</td>
																			<td>{this.props.configuration ?
																				this.props.configuration[0].only_parts ? 0 : 1 : 1}</td>
																			<td className="money">
																				{getSymbolFromCurrency(
																					this.props
																						.category_details[0]
																						.currency_symbol_html
																				)}{" "}
																				{
																					this.props.category_details ?
																						this.props.configuration ?
																							this.props.configuration[0].only_parts ? 0 : parseFloat(this.props.category_details[0].price).toFixed(2) : parseFloat(this.props.category_details[0].price).toFixed(2)
																						: null
																				}
																			</td>
																		</tr>
																		<tr
																			style={{
																				backgroundColor:
																					"#B9373D",
																				color: "#ffffff"
																			}}
																		>
																			<td
																				style={{
																					color: "white",
																					fontWeight: 900,
																					fontSize: 15
																				}}
																			>
																				Parts
																			</td>
																			<td></td>
																			<td></td>
																			<td></td>
																			<td></td>
																		</tr>
																		{this.props.parts !== []
																			? this.props.parts.map(
																				(part, i) => (
																					<tr key={i}>
																						<td className="min-width-table">
																							{
																								part.machine_part_model_number
																							}
																						</td>
																						<td className="min-width-table">
																							{
																								part.machine_part_name
																							}
																						</td>
																						{part.is_included ? (
																							<>
																								<td className="min-width-table">
																									Included
																									</td>
																								<td className="min-width-table">
																									{
																										part.quantity
																									}
																								</td>
																								<td className="min-width-table">
																									Included
																									</td>
																							</>
																						) : (
																							<>
																								<td className="money min-width-table">
																									{getSymbolFromCurrency(
																										part.currency_symbol_html
																									)}{" "}
																									{part.price.toFixed(
																										2
																									)}
																								</td>
																								<td className="min-width-table">
																									{
																										part.quantity
																									}
																								</td>
																								<td className="money min-width-table">
																									{getSymbolFromCurrency(
																										part.currency_symbol_html
																									)}{" "}
																									{parseFloat(
																										part.quantity *
																										part.price
																									).toFixed(
																										2
																									)}
																								</td>
																							</>
																						)}
																					</tr>
																				)
																			)
																			: null}
																	</tbody>
																</table>

																<table className="table table-striped table-responsive-md mt-5">
																	<tbody>
																		{this.props
																			.manual_adjustments
																			.length > 0 ? (
																			<>
																				<tr
																					style={{
																						backgroundColor:
																							"#B9373D",
																						color:
																							"#ffffff"
																					}}
																				>
																					<td
																						style={{
																							color:
																								"white",
																							fontWeight: 900,
																							fontSize: 15
																						}}
																					>
																						Manual
																						Adjustments
																					</td>
																					<td></td>
																					<td></td>
																					<td></td>
																					<td></td>
																					<td></td>
																				</tr>
																				<tr className="thead-dark">
																					<th scope="col">
																						Model Number
																					</th>
																					<th scope="col">
																						Description
																					</th>
																					<th scope="col">
																						Note
																					</th>
																					<th scope="col">
																						Price
																					</th>
																					<th scope="col">
																						Quantity
																					</th>
																					<th scope="col">
																						Total Price
																					</th>
																				</tr>
																			</>
																		) : null}
																		{this.props
																			.manual_adjustments !==
																			null &&
																			this.props
																				.manual_adjustments !==
																			[]
																			? this.props.manual_adjustments.map(
																				(
																					adjustment,
																					i
																				) => (
																					<tr key={i}>
																						<td className="min-width-table">
																							{" "}
																							{
																								adjustment.manual_adjustment_model_number
																							}
																						</td>
																						<td className="min-width-table">
																							{
																								adjustment.manual_adjustment_name
																							}
																						</td>
																						<td className="min-width-table">
																							{
																								adjustment.manual_adjustment_note
																							}
																						</td>
																						<td className="money min-width-table">
																							{getSymbolFromCurrency(
																								adjustment.currency_symbol_html
																							)}{" "}
																							{parseFloat(
																								adjustment.manual_adjustment_price
																							)}
																						</td>
																						<td>
																							<div className="client-counter row">
																								{" "}
																								{
																									adjustment.manual_adjustment_quantity
																								}
																							</div>
																						</td>
																						<td className="money">
																							{getSymbolFromCurrency(
																								adjustment.currency_symbol_html
																							)}
																							{(
																								parseFloat(
																									adjustment.manual_adjustment_quantity
																								) *
																								parseFloat(
																									adjustment.manual_adjustment_price
																								)
																							).toFixed(
																								2
																							)}
																						</td>
																					</tr>
																				)
																			)
																			: null}
																	</tbody>
																</table>
															</div>
														</div>
													</div>
												) : (
													<div
														className="modal-body"
														style={{
															justifyContent: "center"
														}}
													>
														<div
															className="row my-5"
															style={{
																justifyContent: "center",
																flexDirection: "row"
															}}
														>
															{this.props.configuration_message.includes(
																"Getting single user configuration faliure"
															) ? (
																<strong>
																	There is something wrong with
																	the configuration please
																	contact the administrator.
																	Then we will try to fix it.
																</strong>
															) : (
																<Loader
																	type="Rings"
																	color="#00BFFF"
																	height={100}
																	width={100}
																/>
															)}
														</div>
													</div>
												)
												}
											</div>
										</div>
									</div>
								</div>
							}

							<Modal
								onOk={this.logModalOk}
								onCancel={this.logModalCancel}
								footer={null}
								title="Configuration Logs"
								visible={this.state.log_configuration_modal_visible}
							>
								{this.state.log_configuration_data ? (
									<div className="card-container">
										<Tabs type="card">
											<TabPane tab="Changes Summary" key={'summary'}>
												{
													<div className="container">
														{
															this.state.smart_log_configuration_data ?
																this.state.smart_log_configuration_data.map((configuration, i) =>
																	<div className="row">
																		{
																			i === 0 ?
																				'Current Change' :
																				(this.state.smart_log_configuration_data.length - i) + ' changes'
																		}

																		<table className="table table-striped table-responsive-md">
																			<thead className="thead-dark">
																				<tr>
																					<th
																						scope="col"
																						className="min-width-table"
																					>
																						Changes Field
																			</th>
																					<th
																						scope="col"
																						className="min-width-table"
																					>
																						Version
																			</th>
																					<th
																						scope="col"
																						className="min-width-table"
																					>
																						Machine Part Number
																			</th>
																					<th
																						scope="col"
																						className="min-width-table"
																					>
																						Machine Part Description
																			</th>
																					<th
																						scope="col"
																						className="min-width-table"
																					>
																						Modifications
																			</th>
																					<th
																						scope="col"
																						className="min-width-table"
																					>
																						Modified By
																			</th>
																					{/* <th
																						scope="col"
																						className="min-width-table"
																					>
																						Date Modified
																			</th> */}
																				</tr>
																			</thead>
																			<tbody>
																				{
																					configuration.current_approve_request !== configuration.old_approve_request ?
																						<tr>
																							<td>Approve Request</td>
																							<td>{i === 0 ?
																								'Latest' :
																								(this.state.smart_log_configuration_data.length - i)}
																							</td>
																							<td></td>
																							<td></td>
																							<td>{configuration.current_approve_request ? 'Approved' : 'Not Approved'}</td>
																							<td>{configuration.changed_by}</td>
																							{/* <td>{configuration.modified_date}</td> */}
																						</tr>
																						: null
																				}
																				{
																					configuration.current_approve_with_change !== configuration.old_approve_with_change ?
																						<tr>
																							<td>Approve Request with change</td>
																							<td>{i === 0 ?
																								'Latest' :
																								(this.state.smart_log_configuration_data.length - i)}
																							</td>
																							<td></td>
																							<td></td>
																							<td>{configuration.current_approve_with_change ? 'Approved with change' : 'Not Approved'}</td>
																							<td>{configuration.changed_by}</td>
																							{/* <td>{configuration.modified_date}</td> */}
																						</tr>
																						: null
																				}
																				{
																					configuration.quantity_difference !== 0 ?
																						<tr>
																							<td>Quantity</td>
																							<td>{i === 0 ?
																								'Latest' :
																								(this.state.smart_log_configuration_data.length - i)}
																							</td>
																							<td></td>
																							<td></td>
																							<td>{'From [' + (configuration.current_quantity - configuration.quantity_difference) + '] to [' + configuration.current_quantity + ']'}</td>
																							<td>{configuration.changed_by}</td>
																							{/* <td>{configuration.modified_date}</td> */}
																						</tr>
																						: null
																				}
																				{
																					configuration.discount_percentage_difference !== 0 ?
																						<tr>
																							<td>Discount Percentage</td>
																							<td>{i === 0 ?
																								'Latest' :
																								(this.state.smart_log_configuration_data.length - i)}
																							</td>
																							<td></td>
																							<td></td>
																							<td>{'From [' + (configuration.current_discount_percentage - configuration.discount_percentage_difference) + '%] to [' + configuration.current_discount_percentage + '%]'}</td>
																							<td>{configuration.changed_by}</td>
																							{/* <td>{configuration.modified_date}</td> */}
																						</tr>
																						: null
																				}
																				{
																					configuration.disount_amount_difference !== 0 ?
																						<tr>
																							<td>Discount Amount</td>
																							<td>{i === 0 ?
																								'Latest' :
																								(this.state.smart_log_configuration_data.length - i)}
																							</td>
																							<td></td>
																							<td></td>
																							<td>{'From ' + (configuration.current_discount_amount - configuration.disount_amount_difference) + ' to ' + configuration.current_discount_amount}</td>
																							<td>{configuration.changed_by}</td>
																							{/* <td>{configuration.modified_date}</td> */}
																						</tr>
																						: null
																				}
																				{
																					configuration.dealer_discount_amount_difference !== 0 ?
																						<tr>
																							<td>Dealer Discount Percentage</td>
																							<td>{i === 0 ?
																								'Latest' :
																								(this.state.smart_log_configuration_data.length - i)}
																							</td>
																							<td></td>
																							<td></td>
																							<td>{'From ' + (configuration.current_dealer_discount_amount - configuration.dealer_discount_amount_difference) + ' to ' + configuration.current_dealer_discount_amount}</td>
																							<td>{configuration.changed_by}</td>
																							{/* <td>{configuration.modified_date}</td> */}
																						</tr>
																						: null
																				}
																				{
																					configuration.oem_discount_difference !== 0 ?
																						<tr>
																							<td>OEM Discount Percentage</td>
																							<td>{i === 0 ?
																								'Latest' :
																								(this.state.smart_log_configuration_data.length - i)}
																							</td>
																							<td></td>
																							<td></td>
																							<td>{'From ' + (configuration.current_oem_discount - configuration.oem_discount_difference) + ' to ' + configuration.current_oem_discount}</td>
																							<td>{configuration.changed_by}</td>
																							{/* <td>{configuration.modified_date}</td> */}
																						</tr>
																						: null
																				}
																				{
																					configuration.extra_discount_difference !== 0 ?
																						<tr>
																							<td>Extra Discount Percentage</td>
																							<td>{i === 0 ?
																								'Latest' :
																								(this.state.smart_log_configuration_data.length - i)}
																							</td>
																							<td></td>
																							<td></td>
																							<td>{'From ' + (configuration.current_extra_discount - configuration.extra_discount_difference) + ' to ' + configuration.current_extra_discount}</td>
																							<td>{configuration.changed_by}</td>
																							{/* <td>{configuration.modified_date}</td> */}
																						</tr>
																						: null
																				}
																				{
																					configuration.sales_margin_difference !== 0 ?
																						<tr>
																							<td>Sales Margin</td>
																							<td>{i === 0 ?
																								'Latest' :
																								(this.state.smart_log_configuration_data.length - i)}
																							</td>
																							<td></td>
																							<td></td>
																							<td>{'From [' + (configuration.current_sales_margin - configuration.sales_margin_difference) + '%] to [' + configuration.current_sales_margin + '%]'}</td>
																							<td>{configuration.changed_by}</td>
																							{/* <td>{configuration.modified_date}</td> */}
																						</tr>
																						: null
																				}
																				{
																					configuration.parts_changed.length !== 0 ?
																						configuration.parts_changed.map((part, j) =>
																							part.added ?
																								<tr>
																									<td>Add Machine Part</td>
																									<td>{i === 0 ?
																										'Latest' :
																										(this.state.smart_log_configuration_data.length - i)}
																									</td>
																									<td>{part.machine_part_model_number}</td>
																									<td>{part.machine_part_name}</td>
																									<td>Add</td>
																									<td>{configuration.changed_by}</td>
																									{/* <td>{configuration.modified_date}</td> */}
																								</tr> :
																								part.removed ?
																									<tr>
																										<td>Remove Machine Part</td>
																										<td>{i === 0 ?
																											'Latest' :
																											(this.state.smart_log_configuration_data.length - i)}
																										</td>
																										<td>{part.machine_part_model_number}</td>
																										<td>{part.machine_part_name}</td>
																										<td>Delete</td>
																										<td>{configuration.changed_by}</td>
																										{/* <td>{configuration.modified_date}</td> */}
																									</tr>
																									:
																									part.quantity_change ?
																										<tr>
																											<td>Machine Quantity Change</td>
																											<td>{i === 0 ?
																												'Latest' :
																												(this.state.smart_log_configuration_data.length - i)}
																											</td>
																											<td>{part.machine_part_model_number}</td>
																											<td>{part.machine_part_name}</td>
																											<td>{'From ' + (part.current_quantity - part.quantity) + ' to ' + part.current_quantity}</td>
																											<td>{configuration.changed_by}</td>
																											{/* <td>{configuration.modified_date}</td> */}
																										</tr>
																										: null

																						)
																						: null
																				}
																				{
																					configuration.manual_adjustment_changed.length !== 0 ?
																						configuration.manual_adjustment_changed.map((part, j) =>
																							part.added ?
																								<tr>
																									<td>Add Machine Part</td>
																									<td>{i === 0 ?
																										'Latest' :
																										(this.state.smart_log_configuration_data.length - i)}
																									</td>
																									<td>{part.manual_adjustment_model_number}</td>
																									<td>{part.manual_adjustment_name}</td>
																									<td>Add</td>
																									<td>{configuration.changed_by}</td>
																									{/* <td>{configuration.modified_date}</td> */}
																								</tr> :
																								part.removed ?
																									<tr>
																										<td>Remove Machine Part</td>
																										<td>{i === 0 ?
																											'Latest' :
																											(this.state.smart_log_configuration_data.length - i)}
																										</td>
																										<td>{part.manual_adjustment_model_number}</td>
																										<td>{part.manual_adjustment_name}</td>
																										<td>Delete</td>
																										<td>{configuration.changed_by}</td>
																										{/* <td>{configuration.modified_date}</td> */}
																									</tr>
																									:
																									part.quantity_change ?
																										<tr>
																											<td>Machine Quantity Change</td>
																											<td>{i === 0 ?
																												'Latest' :
																												(this.state.smart_log_configuration_data.length - i)}
																											</td>
																											<td>{part.manual_adjustment_model_number}</td>
																											<td>{part.manual_adjustment_name}</td>
																											<td>{'From ' + (part.current_quantity - part.quantity) + ' to ' + part.current_quantity}</td>
																											<td>{configuration.changed_by}</td>
																											{/* <td>{configuration.modified_date}</td> */}
																										</tr>
																										: null

																						)
																						: null
																				}
																				{
																					configuration.machine_total_price_difference !== 0 ?
																						<tr>
																							<td>Total Machine Price</td>
																							<td>{i === 0 ?
																								'Latest' :
																								(this.state.smart_log_configuration_data.length - i)}
																							</td>
																							<td></td>
																							<td></td>
																							<td>{'From ' + (configuration.current_machine_total_price - configuration.machine_total_price_difference) + ' to ' + configuration.current_machine_total_price}</td>
																							<td>{configuration.changed_by}</td>
																							{/* <td>{configuration.modified_date}</td> */}
																						</tr>
																						: null
																				}
																				{
																					configuration.machine_total_price_difference !== 0 ?
																						<tr>
																							<td>Current Total Machine Price</td>
																							<td>{i === 0 ?
																								'Latest' :
																								(this.state.smart_log_configuration_data.length - i)}
																							</td>
																							<td></td>
																							<td></td>
																							<td>{configuration.current_machine_total_price}</td>
																							<td>{configuration.changed_by}</td>
																							{/* <td>{configuration.modified_date}</td> */}
																						</tr>
																						: null
																				}
																			</tbody>
																		</table>


																	</div>
																)
																: null
														}
													</div>
												}
											</TabPane>
											<TabPane tab="Current Machine" key={0}>

												{this.props.configuration &&
													!this.props.configuration_loading ? (
													<div className="modal-body">
														<div className="row m-5">
															<div className="col-sm-4">
																{this.props.configuration[0]
																	.machine_category_image !==
																	[] &&
																	this.props.configuration[0]
																		.machine_category_image !==
																	null ? (
																	<img loading="lazy"
																		width="100%"
																		className="img-responsive"
																		alt=""
																		src={
																			config.APP_URL +
																			"/api/storage/" +
																			this.props
																				.configuration[0]
																				.machine_category_image
																		}
																	/>
																) : (
																	<img loading="lazy"
																		width="100%"
																		className="img-responsive"
																		alt=""
																		src={noimage}
																	/>
																)}
															</div>
															<div className="col-sm-8">
																<div className="client-config-details ml-5 mr-5 mb-5">
																	<Descriptions
																		title=""
																		layout="hotizontal"
																		bordered
																		size="small"
																		column={1}
																	>
																		<Descriptions.Item label="Configuration Name">
																			{this.props
																				.configuration !==
																				[]
																				? this.props
																					.configuration[0]
																					.name
																				: ""}
																		</Descriptions.Item>
																		<Descriptions.Item label="Product Category">
																			{this.props
																				.configuration !==
																				[]
																				? this.props
																					.configuration[0]
																					.machine_category
																				: ""}
																		</Descriptions.Item>
																		<Descriptions.Item label="Retail Price">
																			{this.props
																				.configuration !==
																				[]
																				? parseFloat(
																					this.props
																						.configuration[0]
																						.machine_total_price /
																					this.props
																						.configuration[0]
																						.quantity
																				).toFixed(2)
																				: ""}{" "}
																			{getSymbolFromCurrency(
																				this.props
																					.country[0]
																					.currency_symbol_html
																			)}
																		</Descriptions.Item>
																		{this.props
																			.configuration !==
																			[] &&
																			this.props
																				.configuration[0]
																				.discount_percentage !==
																			0 ? (
																			this.props
																				.is_dealer ? (
																				<Descriptions.Item label="Standard Dealer Terms %">
																					{
																						this.props
																							.configuration[0]
																							.discount_percentage
																					}{" "}
																					%
																				</Descriptions.Item>
																			) : (
																				<>
																					<Descriptions.Item label="Discount Percentage">
																						{
																							this.props
																								.configuration[0]
																								.discount_percentage
																						}{" "}
																						%
																					</Descriptions.Item>
																					<Descriptions.Item label="Discount Percentage Amount">
																						<CurrencyFormat
																							className="py-1 pr-2 text-right"
																							displayType={
																								"text"
																							}
																							decimalSeparator={
																								"."
																							}
																							decimalScale={
																								2
																							}
																							fixedDecimalScale={
																								true
																							}
																							value={parseFloat(
																								((this
																									.props
																									.configuration[0]
																									.machine_total_price /
																									this
																										.props
																										.configuration[0]
																										.quantity) *
																									this
																										.props
																										.configuration[0]
																										.discount_percentage) /
																								100
																							).toFixed(
																								2
																							)}
																							thousandSeparator={
																								true
																							}
																							suffix={
																								" " +
																								getSymbolFromCurrency(
																									this
																										.props
																										.configuration[0]
																										.currency_symbol_html
																								)
																							}
																						/>
																					</Descriptions.Item>
																				</>
																			)
																		) : null}
																		{this.props
																			.configuration !==
																			[] &&
																			this.props
																				.configuration[0]
																				.discount_amount !==
																			0 ? (
																			this.props
																				.is_dealer ? (
																				<Descriptions.Item label="Dealer Discount Amount">
																					{
																						this.props
																							.configuration[0]
																							.discount_amount
																					}{" "}
																					{getSymbolFromCurrency(
																						this.props
																							.configuration[0]
																							.currency_symbol_html
																					)}
																				</Descriptions.Item>
																			) : (
																				<Descriptions.Item label="Discount Amount">
																					{
																						this.props
																							.configuration[0]
																							.discount_amount
																					}{" "}
																					{getSymbolFromCurrency(
																						this.props
																							.configuration[0]
																							.currency_symbol_html
																					)}
																				</Descriptions.Item>
																			)
																		) : null}

																		{this.props
																			.configuration !==
																			[] &&
																			this.props
																				.configuration[0]
																				.dealer_discount_amount !==
																			0 ? (
																			this.props
																				.extra_discount_visible ? (
																				<Descriptions.Item label="Extra Discount">
																					{
																						this.props
																							.configuration[0]
																							.dealer_discount_amount
																					}{" "}
																					{getSymbolFromCurrency(
																						this.props
																							.configuration[0]
																							.currency_symbol_html
																					)}
																				</Descriptions.Item>
																			) : null
																		) : null}
																		{this.props.is_dealer ? (
																			<Descriptions.Item label="Dealer Net Total">
																				{this.props
																					.configuration !==
																					[] ? (
																					<CurrencyFormat
																						className="py-1 pr-2 text-right"
																						displayType={
																							"text"
																						}
																						decimalSeparator={
																							"."
																						}
																						decimalScale={
																							2
																						}
																						fixedDecimalScale={
																							true
																						}
																						value={parseFloat(
																							this.props
																								.configuration[0]
																								.machine_total_price /
																							this
																								.props
																								.configuration[0]
																								.quantity -
																							(this
																								.props
																								.configuration[0]
																								.discount_percentage *
																								(this
																									.props
																									.configuration[0]
																									.machine_total_price /
																									this
																										.props
																										.configuration[0]
																										.quantity)) /
																							100 -
																							this
																								.props
																								.configuration[0]
																								.discount_amount -
																							this
																								.props
																								.configuration[0]
																								.dealer_discount_amount
																						)}
																						thousandSeparator={
																							true
																						}
																						suffix={
																							" " +
																							getSymbolFromCurrency(
																								this
																									.props
																									.configuration[0]
																									.currency_symbol_html
																							)
																						}
																					/>
																				) : null}
																			</Descriptions.Item>
																		) : (
																			<Descriptions.Item label="Net Total">
																				{this.props
																					.configuration !==
																					[] ? (
																					<CurrencyFormat
																						className="py-1 pr-2 text-right"
																						displayType={
																							"text"
																						}
																						decimalSeparator={
																							"."
																						}
																						decimalScale={
																							2
																						}
																						fixedDecimalScale={
																							true
																						}
																						value={parseFloat(
																							this.props
																								.configuration[0]
																								.machine_total_price /
																							this
																								.props
																								.configuration[0]
																								.quantity -
																							(this
																								.props
																								.configuration[0]
																								.discount_percentage *
																								(this
																									.props
																									.configuration[0]
																									.machine_total_price /
																									this
																										.props
																										.configuration[0]
																										.quantity)) /
																							100 -
																							this
																								.props
																								.configuration[0]
																								.discount_amount -
																							this
																								.props
																								.configuration[0]
																								.dealer_discount_amount
																						)}
																						thousandSeparator={
																							true
																						}
																						suffix={
																							" " +
																							getSymbolFromCurrency(
																								this
																									.props
																									.configuration[0]
																									.currency_symbol_html
																							)
																						}
																					/>
																				) : null}
																			</Descriptions.Item>
																		)}

																		{this.props.is_dealer ? (
																			<>
																				<Descriptions.Item label="OEM Programme">
																					{
																						this.props
																							.configuration[0]
																							.oem_discount
																					}
																					{" " +
																						getSymbolFromCurrency(
																							this.props
																								.configuration[0]
																								.currency_symbol_html
																						)}
																				</Descriptions.Item>
																				<Descriptions.Item label="Net Net Purchase Price">
																					<CurrencyFormat
																						className="py-1 pr-2 text-right"
																						displayType={
																							"text"
																						}
																						decimalSeparator={
																							"."
																						}
																						decimalScale={
																							2
																						}
																						fixedDecimalScale={
																							true
																						}
																						value={parseFloat(
																							this.props
																								.configuration[0]
																								.machine_total_price /
																							this
																								.props
																								.configuration[0]
																								.quantity -
																							(this
																								.props
																								.configuration[0]
																								.discount_percentage *
																								(this
																									.props
																									.configuration[0]
																									.machine_total_price /
																									this
																										.props
																										.configuration[0]
																										.quantity)) /
																							100 -
																							this
																								.props
																								.configuration[0]
																								.discount_amount -
																							this
																								.props
																								.configuration[0]
																								.dealer_discount_amount -
																							this
																								.props
																								.configuration[0]
																								.oem_discount
																						).toFixed(2)}
																						thousandSeparator={
																							true
																						}
																						suffix={
																							" " +
																							getSymbolFromCurrency(
																								this
																									.props
																									.configuration[0]
																									.currency_symbol_html
																							)
																						}
																					/>
																				</Descriptions.Item>
																			</>
																		) : null}
																		{this.props.is_dealer ? (
																			<>
																				<Descriptions.Item label="Extra Discount">
																					{
																						this.props
																							.configuration[0]
																							.extra_discount
																					}
																					{" %"}
																				</Descriptions.Item>
																				<Descriptions.Item label="Reesink Extra Extra Support">
																					<CurrencyFormat
																						className="py-1 pr-2 text-right"
																						displayType={
																							"text"
																						}
																						decimalSeparator={
																							"."
																						}
																						decimalScale={
																							2
																						}
																						fixedDecimalScale={
																							true
																						}
																						value={parseFloat(
																							(this.props
																								.configuration[0]
																								.extra_discount *
																								parseFloat(
																									this
																										.props
																										.configuration[0]
																										.machine_total_price /
																									this
																										.props
																										.configuration[0]
																										.quantity -
																									(this
																										.props
																										.configuration[0]
																										.discount_percentage *
																										(this
																											.props
																											.configuration[0]
																											.machine_total_price /
																											this
																												.props
																												.configuration[0]
																												.quantity)) /
																									100 -
																									this
																										.props
																										.configuration[0]
																										.discount_amount -
																									this
																										.props
																										.configuration[0]
																										.dealer_discount_amount -
																									this
																										.props
																										.configuration[0]
																										.oem_discount
																								).toFixed(
																									2
																								)) /
																							100
																						).toFixed(2)}
																						thousandSeparator={
																							true
																						}
																						suffix={
																							" " +
																							getSymbolFromCurrency(
																								this
																									.props
																									.configuration[0]
																									.currency_symbol_html
																							)
																						}
																					/>
																				</Descriptions.Item>
																			</>
																		) : null}
																		{this.props.is_dealer ? (
																			<Descriptions.Item label="Net Net Net Price">
																				<CurrencyFormat
																					className="py-1 pr-2 text-right"
																					displayType={
																						"text"
																					}
																					decimalSeparator={
																						"."
																					}
																					decimalScale={2}
																					fixedDecimalScale={
																						true
																					}
																					value={parseFloat(
																						parseFloat(
																							this.props
																								.configuration[0]
																								.machine_total_price /
																							this
																								.props
																								.configuration[0]
																								.quantity -
																							(this
																								.props
																								.configuration[0]
																								.discount_percentage *
																								(this
																									.props
																									.configuration[0]
																									.machine_total_price /
																									this
																										.props
																										.configuration[0]
																										.quantity)) /
																							100 -
																							this
																								.props
																								.configuration[0]
																								.discount_amount -
																							this
																								.props
																								.configuration[0]
																								.dealer_discount_amount -
																							this
																								.props
																								.configuration[0]
																								.oem_discount
																						).toFixed(2) -
																						parseFloat(
																							(this
																								.props
																								.configuration[0]
																								.extra_discount *
																								parseFloat(
																									this
																										.props
																										.configuration[0]
																										.machine_total_price /
																									this
																										.props
																										.configuration[0]
																										.quantity -
																									(this
																										.props
																										.configuration[0]
																										.discount_percentage *
																										(this
																											.props
																											.configuration[0]
																											.machine_total_price /
																											this
																												.props
																												.configuration[0]
																												.quantity)) /
																									100 -
																									this
																										.props
																										.configuration[0]
																										.discount_amount -
																									this
																										.props
																										.configuration[0]
																										.dealer_discount_amount -
																									this
																										.props
																										.configuration[0]
																										.oem_discount
																								).toFixed(
																									2
																								)) /
																							100
																						).toFixed(
																							2
																						)
																					).toFixed(2)}
																					thousandSeparator={
																						true
																					}
																					suffix={
																						" " +
																						getSymbolFromCurrency(
																							this.props
																								.configuration[0]
																								.currency_symbol_html
																						)
																					}
																				/>
																			</Descriptions.Item>
																		) : null
																		}
																		{this.props
																			.configuration !==
																			[] &&
																			this.props
																				.configuration[0]
																				.sales_margin !== 0 ? (
																			this.props
																				.sales_margin_visible ? (
																				this.props
																					.is_dealer ? (
																					<>
																						<Descriptions.Item label="End Customer Purchase Price">
																							{parseFloat(
																								this
																									.props
																									.configuration[0]
																									.end_user_sales_price
																							).toFixed(
																								2
																							)}{" "}
																							{getSymbolFromCurrency(
																								this
																									.props
																									.country[0]
																									.currency_symbol_html
																							)}
																						</Descriptions.Item>
																						<Descriptions.Item label="Dealer Margin Amount">
																							<CurrencyFormat
																								className="py-1 pr-2 text-right"
																								displayType={
																									"text"
																								}
																								decimalSeparator={
																									"."
																								}
																								decimalScale={
																									2
																								}
																								fixedDecimalScale={
																									true
																								}
																								value={
																									this
																										.props
																										.configuration[0]
																										.sales_margin_amount
																								}
																								thousandSeparator={
																									true
																								}
																								suffix={
																									" " +
																									getSymbolFromCurrency(
																										this
																											.props
																											.configuration[0]
																											.currency_symbol_html
																									)
																								}
																							/>
																						</Descriptions.Item>
																						<Descriptions.Item label="Dealer Margin">
																							{
																								this
																									.props
																									.configuration[0]
																									.sales_margin
																							}
																							{" %"}
																						</Descriptions.Item>
																					</>
																				) : (
																					<>
																						<Descriptions.Item label="Sales Margin">
																							{
																								this
																									.props
																									.configuration[0]
																									.sales_margin
																							}
																							{" %"}
																						</Descriptions.Item>
																						<Descriptions.Item label="Total Invoice for customer">
																							{parseFloat(
																								this
																									.props
																									.configuration[0]
																									.end_user_sales_price
																							).toFixed(
																								2
																							)}{" "}
																							{getSymbolFromCurrency(
																								this
																									.props
																									.country[0]
																									.currency_symbol_html
																							)}
																						</Descriptions.Item>
																					</>
																				)
																			) : null
																		) : null}
																		{this.props
																			.customer_discount_visible &&
																			!this.props.is_dealer ? (
																			<Descriptions.Item label="Customer Discount">
																				{parseFloat(
																					(Math.abs(
																						this.props
																							.configuration[0]
																							.machine_total_price -
																						parseFloat(
																							this
																								.props
																								.configuration[0]
																								.machine_total_price -
																							(this
																								.props
																								.configuration[0]
																								.machine_total_price *
																								this
																									.props
																									.configuration[0]
																									.discount_percentage) /
																							100 -
																							this
																								.props
																								.configuration[0]
																								.discount_amount -
																							this
																								.props
																								.configuration[0]
																								.dealer_discount_amount -
																							this
																								.props
																								.configuration[0]
																								.oem_discount -
																							parseFloat(
																								(this
																									.props
																									.configuration[0]
																									.extra_discount *
																									parseFloat(
																										this
																											.props
																											.configuration[0]
																											.machine_total_price /
																										this
																											.props
																											.configuration[0]
																											.quantity -
																										(this
																											.props
																											.configuration[0]
																											.discount_percentage *
																											(this
																												.props
																												.configuration[0]
																												.machine_total_price /
																												this
																													.props
																													.configuration[0]
																													.quantity)) /
																										100 -
																										this
																											.props
																											.configuration[0]
																											.discount_amount -
																										this
																											.props
																											.configuration[0]
																											.dealer_discount_amount -
																										this
																											.props
																											.configuration[0]
																											.oem_discount
																									).toFixed(
																										2
																									)) /
																								100
																							).toFixed(
																								2
																							) +
																							(this
																								.props
																								.configuration[0]
																								.sales_margin *
																								(this
																									.props
																									.configuration[0]
																									.machine_total_price -
																									(this
																										.props
																										.configuration[0]
																										.machine_total_price *
																										this
																											.props
																											.configuration[0]
																											.discount_percentage) /
																									100 -
																									this
																										.props
																										.configuration[0]
																										.discount_amount -
																									this
																										.props
																										.configuration[0]
																										.dealer_discount_amount -
																									this
																										.props
																										.configuration[0]
																										.oem_discount -
																									parseFloat(
																										(this
																											.props
																											.configuration[0]
																											.extra_discount *
																											parseFloat(
																												this
																													.props
																													.configuration[0]
																													.machine_total_price /
																												this
																													.props
																													.configuration[0]
																													.quantity -
																												(this
																													.props
																													.configuration[0]
																													.discount_percentage *
																													(this
																														.props
																														.configuration[0]
																														.machine_total_price /
																														this
																															.props
																															.configuration[0]
																															.quantity)) /
																												100 -
																												this
																													.props
																													.configuration[0]
																													.discount_amount -
																												this
																													.props
																													.configuration[0]
																													.dealer_discount_amount -
																												this
																													.props
																													.configuration[0]
																													.oem_discount
																											).toFixed(
																												2
																											)) /
																										100
																									).toFixed(
																										2
																									))) /
																							100
																						).toFixed(
																							2
																						)
																					) /
																						this.props
																							.configuration[0]
																							.machine_total_price) *
																					100
																				).toFixed(2)}
																				{" %"}
																			</Descriptions.Item>
																		) : null}
																		{this.props
																			.configuration !==
																			[] &&
																			this.props
																				.configuration[0]
																				.quantity !== 0 ? (
																			<Descriptions.Item label="Configuration Quantity">
																				{
																					this.props
																						.configuration[0]
																						.quantity
																				}
																			</Descriptions.Item>
																		) : null}
																		<Descriptions.Item label="Final Net total">
																			{this.props
																				.configuration !==
																				[] ? (
																				<CurrencyFormat
																					className="py-1 pr-2 text-right"
																					displayType={
																						"text"
																					}
																					decimalSeparator={
																						"."
																					}
																					decimalScale={2}
																					fixedDecimalScale={
																						true
																					}
																					value={
																						parseFloat(
																							this.props
																								.configuration[0]
																								.machine_total_price /
																							this
																								.props
																								.configuration[0]
																								.quantity -
																							(this
																								.props
																								.configuration[0]
																								.discount_percentage *
																								(this
																									.props
																									.configuration[0]
																									.machine_total_price /
																									this
																										.props
																										.configuration[0]
																										.quantity)) /
																							100 -
																							this
																								.props
																								.configuration[0]
																								.discount_amount -
																							this
																								.props
																								.configuration[0]
																								.dealer_discount_amount -
																							this
																								.props
																								.configuration[0]
																								.oem_discount -
																							parseFloat(
																								(this
																									.props
																									.configuration[0]
																									.extra_discount *
																									parseFloat(
																										this
																											.props
																											.configuration[0]
																											.machine_total_price /
																										this
																											.props
																											.configuration[0]
																											.quantity -
																										(this
																											.props
																											.configuration[0]
																											.discount_percentage *
																											(this
																												.props
																												.configuration[0]
																												.machine_total_price /
																												this
																													.props
																													.configuration[0]
																													.quantity)) /
																										100 -
																										this
																											.props
																											.configuration[0]
																											.discount_amount -
																										this
																											.props
																											.configuration[0]
																											.dealer_discount_amount -
																										this
																											.props
																											.configuration[0]
																											.oem_discount
																									).toFixed(
																										2
																									)) /
																								100
																							).toFixed(
																								2
																							)
																						) *
																						this.props
																							.configuration[0]
																							.quantity
																					}
																					thousandSeparator={
																						true
																					}
																					suffix={
																						" " +
																						getSymbolFromCurrency(
																							this.props
																								.configuration[0]
																								.currency_symbol_html
																						)
																					}
																				/>
																			) : null}
																		</Descriptions.Item>
																	</Descriptions>
																</div>
															</div>
														</div>
														<div className="row m-5">
															<div className="client-selected-parts">
																<table className="table table-striped table-responsive-md">
																	<thead className="thead-dark">
																		<tr>
																			<th
																				scope="col"
																				className="min-width-table"
																			>
																				Model Number
																			</th>
																			<th
																				scope="col"
																				className="min-width-table"
																			>
																				Description
																			</th>
																			<th
																				scope="col"
																				className="min-width-table"
																			>
																				Price
																			</th>
																			<th
																				scope="col"
																				className="min-width-table"
																			>
																				Quantity
																			</th>
																			<th
																				scope="col"
																				className="min-width-table"
																			>
																				Total Price
																			</th>
																		</tr>
																		<tr
																			style={{
																				backgroundColor:
																					"#B9373D",
																				color: "#ffffff"
																			}}
																		>
																			<td
																				style={{
																					color: "white",
																					fontWeight: 900,
																					fontSize: 15
																				}}
																			>
																				Components
																			</td>
																			<td></td>
																			<td></td>
																			<td></td>
																			<td></td>
																		</tr>
																	</thead>
																	<tbody>
																		<tr>
																			<td className="min-width-table">
																				{this.props
																					.category_details
																					? this.props
																						.category_details[0]
																						.machine_category_model_number
																					: null}
																			</td>
																			<td className="min-width-table">
																				{this.props
																					.category_details
																					? this.props
																						.category_details[0]
																						.machine_category
																					: null}
																			</td>
																			<td className="money min-width-table">
																				{getSymbolFromCurrency(
																					this.props
																						.category_details[0]
																						.currency_symbol_html
																				)}{" "}
																				{this.props
																					.category_details
																					? parseFloat(
																						this.props
																							.category_details[0]
																							.price
																					).toFixed(2)
																					: null}
																			</td>
																			<td>{this.props.configuration ?
																				this.props.configuration[0].only_parts ? 0 : 1 : 1}</td>
																			<td className="money">
																				{getSymbolFromCurrency(
																					this.props
																						.category_details[0]
																						.currency_symbol_html
																				)}{" "}
																				{
																					this.props.category_details ?
																						this.props.configuration ?
																							this.props.configuration[0].only_parts ? 0 : parseFloat(this.props.category_details[0].price).toFixed(2) : parseFloat(this.props.category_details[0].price).toFixed(2)
																						: null
																				}
																				{/* {this.props
																					.category_details
																					? parseFloat(
																						this.props
																							.category_details[0]
																							.price
																					)
																					: null} */}
																			</td>
																		</tr>
																		<tr
																			style={{
																				backgroundColor:
																					"#B9373D",
																				color: "#ffffff"
																			}}
																		>
																			<td
																				style={{
																					color: "white",
																					fontWeight: 900,
																					fontSize: 15
																				}}
																			>
																				Parts
																			</td>
																			<td></td>
																			<td></td>
																			<td></td>
																			<td></td>
																		</tr>
																		{this.props.parts !== []
																			? this.props.parts.map(
																				(part, i) => (
																					<tr key={i}>
																						<td className="min-width-table">
																							{
																								part.machine_part_model_number
																							}
																						</td>
																						<td className="min-width-table">
																							{
																								part.machine_part_name
																							}
																						</td>
																						{part.is_included ? (
																							<>
																								<td className="min-width-table">
																									Included
																									</td>
																								<td className="min-width-table">
																									{
																										part.quantity
																									}
																								</td>
																								<td className="min-width-table">
																									Included
																									</td>
																							</>
																						) : (
																							<>
																								<td className="money min-width-table">
																									{getSymbolFromCurrency(
																										part.currency_symbol_html
																									)}{" "}
																									{part.price.toFixed(
																										2
																									)}
																								</td>
																								<td className="min-width-table">
																									{
																										part.quantity
																									}
																								</td>
																								<td className="money min-width-table">
																									{getSymbolFromCurrency(
																										part.currency_symbol_html
																									)}{" "}
																									{parseFloat(
																										part.quantity *
																										part.price
																									).toFixed(
																										2
																									)}
																								</td>
																							</>
																						)}
																					</tr>
																				)
																			)
																			: null}
																	</tbody>
																</table>

																<table className="table table-striped table-responsive-md mt-5">
																	<tbody>
																		{this.props
																			.manual_adjustments
																			.length > 0 ? (
																			<>
																				<tr
																					style={{
																						backgroundColor:
																							"#B9373D",
																						color:
																							"#ffffff"
																					}}
																				>
																					<td
																						style={{
																							color:
																								"white",
																							fontWeight: 900,
																							fontSize: 15
																						}}
																					>
																						Manual
																						Adjustments
																					</td>
																					<td></td>
																					<td></td>
																					<td></td>
																					<td></td>
																					<td></td>
																				</tr>
																				<tr className="thead-dark">
																					<th scope="col">
																						Model Number
																					</th>
																					<th scope="col">
																						Description
																					</th>
																					<th scope="col">
																						Note
																					</th>
																					<th scope="col">
																						Price
																					</th>
																					<th scope="col">
																						Quantity
																					</th>
																					<th scope="col">
																						Total Price
																					</th>
																				</tr>
																			</>
																		) : null}
																		{this.props
																			.manual_adjustments !==
																			null &&
																			this.props
																				.manual_adjustments !==
																			[]
																			? this.props.manual_adjustments.map(
																				(
																					adjustment,
																					i
																				) => (
																					<tr key={i}>
																						<td className="min-width-table">
																							{" "}
																							{
																								adjustment.manual_adjustment_model_number
																							}
																						</td>
																						<td className="min-width-table">
																							{
																								adjustment.manual_adjustment_name
																							}
																						</td>
																						<td className="min-width-table">
																							{
																								adjustment.manual_adjustment_note
																							}
																						</td>
																						<td className="money min-width-table">
																							{getSymbolFromCurrency(
																								adjustment.currency_symbol_html
																							)}{" "}
																							{parseFloat(
																								adjustment.manual_adjustment_price
																							)}
																						</td>
																						<td>
																							<div className="client-counter row">
																								{" "}
																								{
																									adjustment.manual_adjustment_quantity
																								}
																							</div>
																						</td>
																						<td className="money">
																							{getSymbolFromCurrency(
																								adjustment.currency_symbol_html
																							)}
																							{(
																								parseFloat(
																									adjustment.manual_adjustment_quantity
																								) *
																								parseFloat(
																									adjustment.manual_adjustment_price
																								)
																							).toFixed(
																								2
																							)}
																						</td>
																					</tr>
																				)
																			)
																			: null}
																	</tbody>
																</table>
															</div>
														</div>
													</div>
												) : (
													<div
														className="modal-body"
														style={{
															justifyContent: "center"
														}}
													>
														<div
															className="row my-5"
															style={{
																justifyContent: "center",
																flexDirection: "row"
															}}
														>
															{this.props.configuration_message.includes(
																"Getting single user configuration faliure"
															) ? (
																<strong>
																	There is something wrong with
																	the configuration please
																	contact the administrator.
																	Then we will try to fix it.
																</strong>
															) : (
																<Loader
																	type="Rings"
																	color="#00BFFF"
																	height={100}
																	width={100}
																/>
															)}
														</div>
													</div>
												)
												}
											</TabPane>
											{this.state.log_configuration_data.map(
												(configuration, i) => (
													<TabPane
														tab={
															configuration.log_configuration
																.name +
															" - " +
															configuration.log_configuration
																.log_configuration_id
														}
														key={i + 1}
													>
														<div className="container">
															<div className="row m-5">
																<div className="col-sm-4">
																	{configuration
																		.log_configuration
																		.machine_category_image !==
																		[] &&
																		configuration
																			.log_configuration
																			.machine_category_image !==
																		null ? (
																		<img loading="lazy"
																			width="100%"
																			className="img-responsive"
																			alt=""
																			src={
																				config.APP_URL +
																				"/api/storage/" +
																				configuration
																					.log_configuration
																					.machine_category_image
																			}
																		/>
																	) : (
																		<img loading="lazy"
																			width="100%"
																			className="img-responsive"
																			alt=""
																			src={noimage}
																		/>
																	)}
																</div>
																<div className="col-sm-8">
																	<div className="client-config-details ml-5 mr-5 mb-5">
																		{configuration.log_configuration !==
																			[] ? (
																			<Descriptions
																				title=""
																				layout="hotizontal"
																				bordered
																				size="small"
																				column={1}
																			>
																				<Descriptions.Item label="Configuration Name">
																					{configuration.log_configuration !==
																						[]
																						? configuration
																							.log_configuration
																							.name
																						: ""}
																				</Descriptions.Item>
																				<Descriptions.Item label="Product Category">
																					{configuration.log_configuration !==
																						[]
																						? configuration
																							.log_configuration
																							.machine_category
																						: ""}
																				</Descriptions.Item>
																				<Descriptions.Item label="Retail Price">
																					{configuration.log_configuration !==
																						[]
																						? parseFloat(
																							configuration
																								.log_configuration
																								.machine_total_price /
																							configuration
																								.log_configuration
																								.quantity
																						).toFixed(2)
																						: ""}{" "}
																					{getSymbolFromCurrency(
																						configuration
																							.log_configuration
																							.currency_symbol_html
																					)}
																				</Descriptions.Item>
																				{configuration.log_configuration !==
																					[] &&
																					configuration
																						.log_configuration
																						.discount_percentage !==
																					0 ? (
																					this.props
																						.is_dealer ? (
																						<Descriptions.Item label="Standard Dealer Terms %">
																							{
																								configuration
																									.log_configuration
																									.discount_percentage
																							}{" "}
																							%
																						</Descriptions.Item>
																					) : (
																						<>
																							<Descriptions.Item label="Discount Percentage">
																								{
																									configuration
																										.log_configuration
																										.discount_percentage
																								}{" "}
																								%
																							</Descriptions.Item>

																							<Descriptions.Item label="Discount Percentage Amount">
																								<CurrencyFormat
																									className="py-1 pr-2 text-right"
																									displayType={
																										"text"
																									}
																									decimalSeparator={
																										"."
																									}
																									decimalScale={
																										2
																									}
																									fixedDecimalScale={
																										true
																									}
																									value={parseFloat(
																										((configuration
																											.log_configuration
																											.machine_total_price /
																											configuration
																												.log_configuration
																												.quantity) *
																											configuration
																												.log_configuration
																												.discount_percentage) /
																										100
																									).toFixed(
																										2
																									)}
																									thousandSeparator={
																										true
																									}
																									suffix={
																										" " +
																										getSymbolFromCurrency(
																											configuration.log_configuration.currency_symbol_html
																										)
																									}
																								/>
																							</Descriptions.Item>
																						</>
																					)
																				) : null}
																				{configuration.log_configuration !==
																					[] &&
																					configuration
																						.log_configuration
																						.discount_amount !==
																					0 ? (
																					this.props
																						.is_dealer ? (
																						<Descriptions.Item label="Dealer Discount Amount">
																							{
																								configuration
																									.log_configuration
																									.discount_amount
																							}{" "}
																							{getSymbolFromCurrency(
																								configuration
																									.log_configuration
																									.currency_symbol_html
																							)}
																						</Descriptions.Item>
																					) : (
																						<Descriptions.Item label="Discount Amount">
																							{
																								configuration
																									.log_configuration
																									.discount_amount
																							}{" "}
																							{getSymbolFromCurrency(
																								configuration
																									.log_configuration
																									.currency_symbol_html
																							)}
																						</Descriptions.Item>
																					)
																				) : null}

																				{configuration.log_configuration !==
																					[] &&
																					configuration
																						.log_configuration
																						.dealer_discount_amount !==
																					0 ? (
																					this.props
																						.extra_discount_visible ? (
																						<Descriptions.Item label="Extra Discount">
																							{
																								configuration
																									.log_configuration
																									.dealer_discount_amount
																							}{" "}
																							{getSymbolFromCurrency(
																								configuration
																									.log_configuration
																									.currency_symbol_html
																							)}
																						</Descriptions.Item>
																					) : null
																				) : null}
																				{this.props
																					.is_dealer ? (
																					<Descriptions.Item label="Dealer Net Total">
																						{configuration.log_configuration !==
																							[] ? (
																							<CurrencyFormat
																								className="py-1 pr-2 text-right"
																								displayType={
																									"text"
																								}
																								decimalSeparator={
																									"."
																								}
																								decimalScale={
																									2
																								}
																								fixedDecimalScale={
																									true
																								}
																								value={parseFloat(
																									configuration
																										.log_configuration
																										.machine_total_price /
																									configuration
																										.log_configuration
																										.quantity -
																									(configuration
																										.log_configuration
																										.discount_percentage *
																										(configuration
																											.log_configuration
																											.machine_total_price /
																											configuration
																												.log_configuration
																												.quantity)) /
																									100 -
																									configuration
																										.log_configuration
																										.discount_amount -
																									configuration
																										.log_configuration
																										.dealer_discount_amount
																								)}
																								thousandSeparator={
																									true
																								}
																								suffix={
																									" " +
																									getSymbolFromCurrency(
																										configuration
																											.log_configuration
																											.currency_symbol_html
																									)
																								}
																							/>
																						) : null}
																					</Descriptions.Item>
																				) : (
																					<Descriptions.Item label="Net Total">
																						{configuration.log_configuration !==
																							[] ? (
																							<CurrencyFormat
																								className="py-1 pr-2 text-right"
																								displayType={
																									"text"
																								}
																								decimalSeparator={
																									"."
																								}
																								decimalScale={
																									2
																								}
																								fixedDecimalScale={
																									true
																								}
																								value={parseFloat(
																									configuration
																										.log_configuration
																										.machine_total_price /
																									configuration
																										.log_configuration
																										.quantity -
																									(configuration
																										.log_configuration
																										.discount_percentage *
																										(configuration
																											.log_configuration
																											.machine_total_price /
																											configuration
																												.log_configuration
																												.quantity)) /
																									100 -
																									configuration
																										.log_configuration
																										.discount_amount -
																									configuration
																										.log_configuration
																										.dealer_discount_amount
																								)}
																								thousandSeparator={
																									true
																								}
																								suffix={
																									" " +
																									getSymbolFromCurrency(
																										configuration
																											.log_configuration
																											.currency_symbol_html
																									)
																								}
																							/>
																						) : null}
																					</Descriptions.Item>
																				)}

																				{this.props
																					.is_dealer ? (
																					<>
																						<Descriptions.Item label="OEM Programme">
																							{
																								configuration
																									.log_configuration
																									.oem_discount
																							}
																							{" " +
																								getSymbolFromCurrency(
																									configuration
																										.log_configuration
																										.currency_symbol_html
																								)}
																						</Descriptions.Item>
																						<Descriptions.Item label="Net Net Purchase Price">
																							<CurrencyFormat
																								className="py-1 pr-2 text-right"
																								displayType={
																									"text"
																								}
																								decimalSeparator={
																									"."
																								}
																								decimalScale={
																									2
																								}
																								fixedDecimalScale={
																									true
																								}
																								value={parseFloat(
																									configuration
																										.log_configuration
																										.machine_total_price /
																									configuration
																										.log_configuration
																										.quantity -
																									(configuration
																										.log_configuration
																										.discount_percentage *
																										(configuration
																											.log_configuration
																											.machine_total_price /
																											configuration
																												.log_configuration
																												.quantity)) /
																									100 -
																									configuration
																										.log_configuration
																										.discount_amount -
																									configuration
																										.log_configuration
																										.dealer_discount_amount -
																									configuration
																										.log_configuration
																										.oem_discount
																								).toFixed(
																									2
																								)}
																								thousandSeparator={
																									true
																								}
																								suffix={
																									" " +
																									getSymbolFromCurrency(
																										configuration
																											.log_configuration
																											.currency_symbol_html
																									)
																								}
																							/>
																						</Descriptions.Item>
																					</>
																				) : null}
																				{this.props
																					.is_dealer ? (
																					<>
																						<Descriptions.Item label="Extra Discount">
																							{
																								configuration
																									.log_configuration
																									.extra_discount
																							}
																							{" %"}
																						</Descriptions.Item>
																						<Descriptions.Item label="Reesink Extra Extra Support">
																							<CurrencyFormat
																								className="py-1 pr-2 text-right"
																								displayType={
																									"text"
																								}
																								decimalSeparator={
																									"."
																								}
																								decimalScale={
																									2
																								}
																								fixedDecimalScale={
																									true
																								}
																								value={parseFloat(
																									(configuration
																										.log_configuration
																										.extra_discount *
																										parseFloat(
																											configuration
																												.log_configuration
																												.machine_total_price /
																											configuration
																												.log_configuration
																												.quantity -
																											(configuration
																												.log_configuration
																												.discount_percentage *
																												(configuration
																													.log_configuration
																													.machine_total_price /
																													configuration
																														.log_configuration
																														.quantity)) /
																											100 -
																											configuration
																												.log_configuration
																												.discount_amount -
																											configuration
																												.log_configuration
																												.dealer_discount_amount -
																											configuration
																												.log_configuration
																												.oem_discount
																										).toFixed(
																											2
																										)) /
																									100
																								).toFixed(
																									2
																								)}
																								thousandSeparator={
																									true
																								}
																								suffix={
																									" " +
																									getSymbolFromCurrency(
																										configuration
																											.log_configuration
																											.currency_symbol_html
																									)
																								}
																							/>
																						</Descriptions.Item>
																					</>
																				) : null}
																				{this.props
																					.is_dealer ? (
																					<Descriptions.Item label="Net Net Net Price">
																						<CurrencyFormat
																							className="py-1 pr-2 text-right"
																							displayType={
																								"text"
																							}
																							decimalSeparator={
																								"."
																							}
																							decimalScale={
																								2
																							}
																							fixedDecimalScale={
																								true
																							}
																							value={parseFloat(
																								parseFloat(
																									configuration
																										.log_configuration
																										.machine_total_price /
																									configuration
																										.log_configuration
																										.quantity -
																									(configuration
																										.log_configuration
																										.discount_percentage *
																										(configuration
																											.log_configuration
																											.machine_total_price /
																											configuration
																												.log_configuration
																												.quantity)) /
																									100 -
																									configuration
																										.log_configuration
																										.discount_amount -
																									configuration
																										.log_configuration
																										.dealer_discount_amount -
																									configuration
																										.log_configuration
																										.oem_discount
																								).toFixed(
																									2
																								) -
																								parseFloat(
																									(configuration
																										.log_configuration
																										.extra_discount *
																										parseFloat(
																											configuration
																												.log_configuration
																												.machine_total_price /
																											configuration
																												.log_configuration
																												.quantity -
																											(configuration
																												.log_configuration
																												.discount_percentage *
																												(configuration
																													.log_configuration
																													.machine_total_price /
																													configuration
																														.log_configuration
																														.quantity)) /
																											100 -
																											configuration
																												.log_configuration
																												.discount_amount -
																											configuration
																												.log_configuration
																												.dealer_discount_amount -
																											configuration
																												.log_configuration
																												.oem_discount
																										).toFixed(
																											2
																										)) /
																									100
																								).toFixed(
																									2
																								)
																							).toFixed(
																								2
																							)}
																							thousandSeparator={
																								true
																							}
																							suffix={
																								" " +
																								getSymbolFromCurrency(
																									configuration
																										.log_configuration
																										.currency_symbol_html
																								)
																							}
																						/>
																					</Descriptions.Item>
																				) : null
																				}
																				{configuration.log_configuration !==
																					[] &&
																					configuration
																						.log_configuration
																						.sales_margin !==
																					0 ? (
																					this.props
																						.sales_margin_visible ? (
																						this.props
																							.is_dealer ? (
																							<>
																								<Descriptions.Item label="End Customer Purchase Price">
																									{parseFloat(
																										configuration
																											.log_configuration
																											.end_user_sales_price
																									).toFixed(
																										2
																									)}{" "}
																									{getSymbolFromCurrency(
																										configuration
																											.log_configuration
																											.currency_symbol_html
																									)}
																								</Descriptions.Item>
																								<Descriptions.Item label="Dealer Margin Amount">
																									<CurrencyFormat
																										className="py-1 pr-2 text-right"
																										displayType={
																											"text"
																										}
																										decimalSeparator={
																											"."
																										}
																										decimalScale={
																											2
																										}
																										fixedDecimalScale={
																											true
																										}
																										value={
																											configuration
																												.log_configuration
																												.sales_margin_amount
																										}
																										thousandSeparator={
																											true
																										}
																										suffix={
																											" " +
																											getSymbolFromCurrency(
																												configuration
																													.log_configuration
																													.currency_symbol_html
																											)
																										}
																									/>
																								</Descriptions.Item>
																								<Descriptions.Item label="Dealer Margin">
																									{
																										configuration
																											.log_configuration
																											.sales_margin
																									}
																									{
																										" %"
																									}
																								</Descriptions.Item>
																							</>
																						) : (
																							<>
																								<Descriptions.Item label="Sales Margin">
																									{
																										configuration
																											.log_configuration
																											.sales_margin
																									}
																									{
																										" %"
																									}
																								</Descriptions.Item>
																								<Descriptions.Item label="Total Invoice for customer">
																									{parseFloat(
																										configuration
																											.log_configuration
																											.end_user_sales_price
																									).toFixed(
																										2
																									)}{" "}
																									{getSymbolFromCurrency(
																										configuration
																											.log_configuration
																											.currency_symbol_html
																									)}
																								</Descriptions.Item>
																							</>
																						)
																					) : null
																				) : null}
																				{this.props
																					.customer_discount_visible &&
																					!this.props
																						.is_dealer ? (
																					<Descriptions.Item label="Customer Discount">
																						{parseFloat(
																							(Math.abs(
																								configuration
																									.log_configuration
																									.machine_total_price -
																								parseFloat(
																									configuration
																										.log_configuration
																										.machine_total_price -
																									(configuration
																										.log_configuration
																										.machine_total_price *
																										configuration
																											.log_configuration
																											.discount_percentage) /
																									100 -
																									configuration
																										.log_configuration
																										.discount_amount -
																									configuration
																										.log_configuration
																										.dealer_discount_amount -
																									configuration
																										.log_configuration
																										.oem_discount -
																									parseFloat(
																										(configuration
																											.log_configuration
																											.extra_discount *
																											parseFloat(
																												configuration
																													.log_configuration
																													.machine_total_price /
																												configuration
																													.log_configuration
																													.quantity -
																												(configuration
																													.log_configuration
																													.discount_percentage *
																													(configuration
																														.log_configuration
																														.machine_total_price /
																														configuration
																															.log_configuration
																															.quantity)) /
																												100 -
																												configuration
																													.log_configuration
																													.discount_amount -
																												configuration
																													.log_configuration
																													.dealer_discount_amount -
																												configuration
																													.log_configuration
																													.oem_discount
																											).toFixed(
																												2
																											)) /
																										100
																									).toFixed(
																										2
																									) +
																									(configuration
																										.log_configuration
																										.sales_margin *
																										(configuration
																											.log_configuration
																											.machine_total_price -
																											(configuration
																												.log_configuration
																												.machine_total_price *
																												configuration
																													.log_configuration
																													.discount_percentage) /
																											100 -
																											configuration
																												.log_configuration
																												.discount_amount -
																											configuration
																												.log_configuration
																												.dealer_discount_amount -
																											configuration
																												.log_configuration
																												.oem_discount -
																											parseFloat(
																												(configuration
																													.log_configuration
																													.extra_discount *
																													parseFloat(
																														configuration
																															.log_configuration
																															.machine_total_price /
																														configuration
																															.log_configuration
																															.quantity -
																														(configuration
																															.log_configuration
																															.discount_percentage *
																															(configuration
																																.log_configuration
																																.machine_total_price /
																																configuration
																																	.log_configuration
																																	.quantity)) /
																														100 -
																														configuration
																															.log_configuration
																															.discount_amount -
																														configuration
																															.log_configuration
																															.dealer_discount_amount -
																														configuration
																															.log_configuration
																															.oem_discount
																													).toFixed(
																														2
																													)) /
																												100
																											).toFixed(
																												2
																											))) /
																									100
																								).toFixed(
																									2
																								)
																							) /
																								configuration
																									.log_configuration
																									.machine_total_price) *
																							100
																						).toFixed(2)}
																						{" %"}
																					</Descriptions.Item>
																				) : null}
																				{configuration.log_configuration !==
																					[] &&
																					configuration
																						.log_configuration
																						.quantity !==
																					0 ? (
																					<Descriptions.Item label="Configuration Quantity">
																						{
																							configuration
																								.log_configuration
																								.quantity
																						}
																					</Descriptions.Item>
																				) : null}
																				<Descriptions.Item label="Final Net total">
																					{configuration.log_configuration !==
																						[] ? (
																						<CurrencyFormat
																							className="py-1 pr-2 text-right"
																							displayType={
																								"text"
																							}
																							decimalSeparator={
																								"."
																							}
																							decimalScale={
																								2
																							}
																							fixedDecimalScale={
																								true
																							}
																							value={
																								parseFloat(
																									configuration
																										.log_configuration
																										.machine_total_price /
																									configuration
																										.log_configuration
																										.quantity -
																									(configuration
																										.log_configuration
																										.discount_percentage *
																										(configuration
																											.log_configuration
																											.machine_total_price /
																											configuration
																												.log_configuration
																												.quantity)) /
																									100 -
																									configuration
																										.log_configuration
																										.discount_amount -
																									configuration
																										.log_configuration
																										.dealer_discount_amount -
																									configuration
																										.log_configuration
																										.oem_discount -
																									parseFloat(
																										(configuration
																											.log_configuration
																											.extra_discount *
																											parseFloat(
																												configuration
																													.log_configuration
																													.machine_total_price /
																												configuration
																													.log_configuration
																													.quantity -
																												(configuration
																													.log_configuration
																													.discount_percentage *
																													(configuration
																														.log_configuration
																														.machine_total_price /
																														configuration
																															.log_configuration
																															.quantity)) /
																												100 -
																												configuration
																													.log_configuration
																													.discount_amount -
																												configuration
																													.log_configuration
																													.dealer_discount_amount -
																												configuration
																													.log_configuration
																													.oem_discount
																											).toFixed(
																												2
																											)) /
																										100
																									).toFixed(
																										2
																									)
																								) *
																								configuration
																									.log_configuration
																									.quantity
																							}
																							thousandSeparator={
																								true
																							}
																							suffix={
																								" " +
																								getSymbolFromCurrency(
																									configuration
																										.log_configuration
																										.currency_symbol_html
																								)
																							}
																						/>
																					) : null}
																				</Descriptions.Item>
																			</Descriptions>
																		) : null}
																	</div>
																</div>
															</div>
															<div className="row m-5">
																<div className="client-selected-parts">
																	<table className="table table-striped table-responsive-md">
																		<thead className="thead-dark">
																			<tr>
																				<th
																					scope="col"
																					className="min-width-table"
																				>
																					Model Number
																				</th>
																				<th
																					scope="col"
																					className="min-width-table"
																				>
																					Description
																				</th>
																				<th
																					scope="col"
																					className="min-width-table"
																				>
																					Price
																				</th>
																				<th
																					scope="col"
																					className="min-width-table"
																				>
																					Quantity
																				</th>
																				<th
																					scope="col"
																					className="min-width-table"
																				>
																					Total Price
																				</th>
																			</tr>
																			<tr
																				style={{
																					backgroundColor:
																						"#B9373D",
																					color: "#ffffff"
																				}}
																			>
																				<td
																					style={{
																						color:
																							"white",
																						fontWeight: 900,
																						fontSize: 15
																					}}
																				>
																					Components
																				</td>
																				<td></td>
																				<td></td>
																				<td></td>
																				<td></td>
																			</tr>
																		</thead>
																		<tbody>
																			<tr>
																				<td className="min-width-table">
																					{configuration.log_configuration
																						? configuration
																							.log_configuration
																							.machine_category
																						: null}
																				</td>
																				<td className="min-width-table"></td>
																				<td className="money min-width-table">
																					{getSymbolFromCurrency(
																						configuration
																							.log_configuration
																							.currency_symbol_html
																					)}{" "}
																					{configuration.log_configuration
																						? parseFloat(
																							configuration
																								.log_configuration
																								.price
																						).toFixed(2)
																						: null}
																				</td>
																				<td>{configuration.log_configuration ?
																					configuration.log_configuration.only_parts ? 0 : 1 : 1}</td>
																				<td className="money">
																					{getSymbolFromCurrency(
																						configuration
																							.log_configuration
																							.currency_symbol_html
																					)}{" "}
																					{
																						configuration.log_configuration ?
																							configuration.log_configuration.only_parts ? 0 : parseFloat(configuration.log_configuration.price).toFixed(2) : parseFloat(configuration.log_configuration.price).toFixed(2)
																					}
																					{/* {configuration.log_configuration
																						? parseFloat(
																							configuration
																								.log_configuration
																								.price
																						)
																						: null} */}
																				</td>
																			</tr>
																			<tr
																				style={{
																					backgroundColor:
																						"#B9373D",
																					color: "#ffffff"
																				}}
																			>
																				<td
																					style={{
																						color:
																							"white",
																						fontWeight: 900,
																						fontSize: 15
																					}}
																				>
																					Parts
																				</td>
																				<td></td>
																				<td></td>
																				<td></td>
																				<td></td>
																			</tr>
																			{configuration.parts !==
																				[]
																				? configuration.parts.map(
																					(part, i) => (
																						<tr
																							key={i}
																						>
																							<td className="min-width-table">
																								{
																									part.machine_part_model_number
																								}
																							</td>
																							<td className="min-width-table">
																								{
																									part.machine_part_name
																								}
																							</td>
																							{part.is_included ? (
																								<>
																									<td className="min-width-table">
																										Included
																										</td>
																									<td className="min-width-table">
																										{
																											part.quantity
																										}
																									</td>
																									<td className="min-width-table">
																										Included
																										</td>
																								</>
																							) : (
																								<>
																									<td className="money min-width-table">
																										{getSymbolFromCurrency(
																											part.currency_symbol_html
																										)}{" "}
																										{part.price.toFixed(
																											2
																										)}
																									</td>
																									<td className="min-width-table">
																										{" "}
																										{
																											part.quantity
																										}
																									</td>
																									<td className="money min-width-table">
																										{getSymbolFromCurrency(
																											part.currency_symbol_html
																										)}{" "}
																										{parseFloat(
																											part.quantity *
																											part.price
																										).toFixed(
																											2
																										)}
																									</td>
																								</>
																							)}
																						</tr>
																					)
																				)
																				: null}
																		</tbody>
																	</table>

																	<table className="table table-striped table-responsive-md mt-5">
																		<tbody>
																			{configuration
																				.manual_adjustments
																				.length > 0 ? (
																				<>
																					<tr
																						style={{
																							backgroundColor:
																								"#B9373D",
																							color:
																								"#ffffff"
																						}}
																					>
																						<td
																							style={{
																								color:
																									"white",
																								fontWeight: 900,
																								fontSize: 15
																							}}
																						>
																							Manual
																							Adjustments
																						</td>
																						<td></td>
																						<td></td>
																						<td></td>
																						<td></td>
																						<td></td>
																					</tr>
																					<tr className="thead-dark">
																						<th scope="col">
																							Model
																							Number
																						</th>
																						<th scope="col">
																							Description
																						</th>
																						<th scope="col">
																							Note
																						</th>
																						<th scope="col">
																							Price
																						</th>
																						<th scope="col">
																							Quantity
																						</th>
																						<th scope="col">
																							Total Price
																						</th>
																					</tr>
																				</>
																			) : null}
																			{configuration.manual_adjustments !==
																				null &&
																				configuration.manual_adjustments !==
																				[]
																				? configuration.manual_adjustments.map(
																					(
																						adjustment,
																						i
																					) => (
																						<tr
																							key={i}
																						>
																							<td className="min-width-table">
																								{" "}
																								{
																									adjustment.manual_adjustment_model_number
																								}
																							</td>
																							<td className="min-width-table">
																								{
																									adjustment.manual_adjustment_name
																								}
																							</td>
																							<td className="min-width-table">
																								{
																									adjustment.manual_adjustment_note
																								}
																							</td>
																							<td className="money min-width-table">
																								{getSymbolFromCurrency(
																									adjustment.currency_symbol_html
																								)}{" "}
																								{parseFloat(
																									adjustment.manual_adjustment_price
																								)}
																							</td>
																							<td>
																								<div className="client-counter row">
																									{" "}
																									{
																										adjustment.manual_adjustment_quantity
																									}
																								</div>
																							</td>
																							<td className="money">
																								{getSymbolFromCurrency(
																									adjustment.currency_symbol_html
																								)}
																								{(
																									parseFloat(
																										adjustment.manual_adjustment_quantity
																									) *
																									parseFloat(
																										adjustment.manual_adjustment_price
																									)
																								).toFixed(
																									2
																								)}
																							</td>
																						</tr>
																					)
																				)
																				: null}
																		</tbody>
																	</table>
																</div>
															</div>
														</div>
													</TabPane>
												)
											)}
										</Tabs>
									</div>
								) : (
									<div
										className="modal-body"
										style={{
											justifyContent: "center"
										}}
									>
										<div
											className="row my-5"
											style={{
												justifyContent: "center",
												flexDirection: "row"
											}}
										>
											{this.props.configuration_message.includes(
												"Getting single user configuration faliure"
											) ? (
												<strong>
													Either there is something wrong with the configuration (can be deleted by the user) or you don't have proper authority to access this configuration, please contact the administrator. Then we will try to fix it.
												</strong>
											) : (
												<Loader
													type="Rings"
													color="#00BFFF"
													height={100}
													width={100}
												/>
											)}
										</div>
									</div>
								)}
							</Modal>
							<div className="container">
								<div className="comment_block mx-5">
									<div className="comments-container">
										<ul id="comments-list" className="comments-list">
											<List itemLayout="horizontal">
												{this.state.comments
													? this.state.comments.map(
														(comment, i) => (
															<>
																<Comment
																	author={comment.user_name}
																	avatar={
																		<Avatar
																			shape="square"
																			size={60}
																			name={comment.user_name
																				.split(" ")
																				.map(n => n[0])
																				.join("")}
																		/>
																	}
																	content={comment.comment}
																	datetime={
																		<Tooltip
																			title={
																				comment.updated_at !==
																					null
																					? moment(
																						comment.updated_at
																					).format(
																						"MMMM Do YYYY, h:mm:ss a"
																					)
																					: moment(
																						comment.created_at
																					).format(
																						"MMMM Do YYYY, h:mm:ss a"
																					)
																			}
																		>
																			<span>
																				{comment.updated_at !==
																					null
																					? moment(
																						comment.updated_at
																					).format(
																						"MMMM Do YYYY"
																					)
																					: moment(
																						comment.created_at
																					).format(
																						"MMMM Do YYYY"
																					)}
																			</span>
																		</Tooltip>
																	}
																/>
															</>
														)
													)
													: null}
											</List>
										</ul>
										<ul id="comments-list" className="comments-list">
											<li>
												<div className="comment-main-level">
													<div>
														<Form.Item>
															<TextArea
																rows={4}
																onChange={e =>
																	this.handleCommentChange(
																		e.target.value
																	)
																}
																value={this.state.comment}
															/>
														</Form.Item>
														<Form.Item>
															<button
																className="client-login px-5"
																style={{
																	paddingTop: 0,
																	paddingBottom: 0
																}}
																htmltype="submit"
																onClick={this.onComment}
																type="primary"
															>
																Add Comment
															</button>
														</Form.Item>
													</div>
												</div>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					);
				} else {
					return (
						<div className="container my-5 text-center">
							<strong>
								Either there is something wrong with the quote (can be deleted by the user) or you don't have proper authority to access this quote, please contact the administrator. Then we will try to fix it.
							</strong>
						</div>
					)
				}
			}
		}
	}
}
const mapStateToProps = state => {
	return {
		name: state.loginReducer.user.user.name,
		user_id: state.loginReducer.user.user.id,
		loading: state.clientQuoteReducer.loading,
		userRole: state.loginReducer.user.role,
		quote: state.clientQuoteReducer.quote,
		quoteLoading: state.clientQuoteReducer.loading,
		configuration:
			state.clientConfigurationReducer.configuration.configuration,
		category_details: state.clientConfigurationReducer.category_details,
		parts: state.clientConfigurationReducer.configuration.parts,
		manual_adjustments:
			state.clientConfigurationReducer.configuration.manual_adjustments,
		message: state.clientQuoteReducer.message,
		loginLoading: state.loginReducer.loading,
		language: state.languageReducer.selectedLanguage,
		configuration_loading: state.clientConfigurationReducer.loading,
		configuration_message: state.clientConfigurationReducer.message,
		country: state.loginReducer.user.country,
		is_support: state.loginReducer.user.is_support,
		max_discount: state.loginReducer.user.max_discount,
		project_overview_visible:
			state.loginReducer.user.project_overview_visible,
		log_visibility: state.loginReducer.user.log_visibility,
		role_id: state.loginReducer.user.role_id,
		is_dealer: state.loginReducer.user.is_dealer,
		sales_margin_visible: state.loginReducer.user.sales_margin_visible,
		customer_discount_visible:
			state.loginReducer.user.customer_discount_visible,
		extra_discount_visible: state.loginReducer.user.extra_discount_visible,
		extra_discount_visible_and_editable:
			state.loginReducer.user.extra_discount_visible_and_editable,
		oem_editable: state.loginReducer.user.oem_editable,
		extra_extra_discount_editable:
			state.loginReducer.user.extra_extra_discount_editable,
		is_manager: state.loginReducer.user.is_manager,
		higher_discount_request: state.loginReducer.user.higher_discount_request,
		need_approval: state.loginReducer.user.need_approval,
	};
};

export default connect(mapStateToProps, {
	show_single_quotes,
	order_quote,
	show_single_user_configuration
})(MyDetailedQuote);
