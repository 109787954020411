const configuredMachineActionTypes = {
  SHOW_CONFIGURED_MACHINE_REQUEST: "show.configured.machine.request",
  SHOW_CONFIGURED_MACHINE_SUCCESS: "show.configured.machine.success",
  SHOW_CONFIGURED_MACHINE_FALIURE: "show.configured.machine.faliure",

  ADD_CONFIGURED_MACHINE_REQUEST: "add.configured.machine.request",
  ADD_CONFIGURED_MACHINE_SUCCESS: "add.configured.machine.success",
  ADD_CONFIGURED_MACHINE_FALIURE: "add.configured.machine.faliure",

  UPDATE_CONFIGURED_MACHINE_REQUEST: "update.configured.machine.request",
  UPDATE_CONFIGURED_MACHINE_SUCCESS: "update.configured.machine.success",
  UPDATE_CONFIGURED_MACHINE_FALIURE: "update.configured.machine.faliure",

  DELETE_CONFIGURED_MACHINE_REQUEST: "delete.configured.machine.request",
  DELETE_CONFIGURED_MACHINE_SUCCESS: "delete.configured.machine.success",
  DELETE_CONFIGURED_MACHINE_FALIURE: "delete.configured.machine.faliure"
};
export default configuredMachineActionTypes;
