import { Api } from "./../../config/api";

// api call to show all brands
function showAllGenericQuestions() {
  let data = Api.get("generic-question").then(function(response) {
    return response.data;
  });

  if (data) {
    return data;
  } else {
    return false;
  }
}

//api call to show a single brand
function showSingleGenericQuestion(param) {
  let data = Api.get("generic-question/" + param).then(function(response) {
    return response.data;
  });

  if (data) {
    return data;
  } else {
    return false;
  }
}

//api call to add a brand into the system
function addGenericQuestion(params) {
  let data = Api.post("generic-question", params).then(function(response) {
    return response.data;
  });

  if (data) {
    return data;
  } else {
    return false;
  }
}

//api call to edit brand exisitng in the system
function updateGenericQuestion(params) {
  let data = Api.post("generic-question/edit/" + params.get("id"), params).then(function(
    response
  ) {
    return response.data;
  });

  if (data) {
    return data;
  } else {
    return false;
  }
}

//api call to delete exisitng brand from the system
function deleteGenericQuestion(params) {
  let data = Api.get("generic-question/delete/" + params).then(function(response) {
    return response.data;
  });

  if (data) {
    return data;
  } else {
    return false;
  }
}

export const GenericQuestionService = {
    showAllGenericQuestions,
    showSingleGenericQuestion,
    addGenericQuestion,
    updateGenericQuestion,
    deleteGenericQuestion
};
