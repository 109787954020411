const clientOrderActionTypes = {
  SHOW_ORDERS_REQUEST: "show.orders.request",
  SHOW_ORDERS_SUCCESS: "show.orders.success",
  SHOW_ORDERS_FALIURE: "show.orders.faliure",

  SHOW_SINGLE_ORDER_REQUEST: "show.single.order.request",
  SHOW_SINGLE_ORDER_SUCCESS: "show.single.order.success",
  SHOW_SINGLE_ORDER_FALIURE: "show.single.order.faliure",

  CREATE_ORDER_REQUEST: "create.order.request",
  CREATE_ORDER_SUCCESS: "create.order.success",
  CREATE_ORDER_FALIURE: "create.order.faliure"
};
export default clientOrderActionTypes;
