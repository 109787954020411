import React, { Component } from "react";
import { Api } from "./../../../config/api";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import config from "./../../../config/config";
import { notification } from "antd";
import axios from "axios";

export default class CreateRole extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			roleName: "",
			role_image: null,
			existing_roles: false,
			selected_role_id: false
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleFileChange = this.handleFileChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleSelectChange = this.handleSelectChange.bind(this);
	}

	handleSelectChange(value) {
		this.setState({ selected_role_id: value });
	}

	handleFileChange(event) {
		this.setState({ role_image: event.target.files[0] });
	}

	handleChange(value) {
		let that = this;
		that.setState({ roleName: value });
	}

	async handleSubmit(e) {
		this.setState({ loading: true });
		let that = this;
		e.preventDefault();

		let data = new FormData();
		data.append("role_name", this.state.roleName);
		if (this.state.role_image !== null) {
			data.append("role_banner_image", this.state.role_image);
		}
		if (this.state.selected_role_id) {
			await Api.post(
				config.APP_URL + "/api/user-role/" + this.state.selected_role_id,
				data
			)
				.then(function(response) {
					notification.success({
						message: "Success!",
						description: response.data.message
					});
					that.setState({ roleName: "" });
				})
				.catch(function(error) {
					notification.error({
						message: "Error!",
						description: "Role not created."
					});
				});
		} else {
			await Api.post(config.APP_URL + "/api/user-role", data)
				.then(function(response) {
					notification.success({
						message: "Success!",
						description: response.data.message
					});
					that.setState({ roleName: "" });
				})
				.catch(function(error) {
					notification.error({
						message: "Error!",
						description: "Role not created."
					});
				});
		}

		that.setState({ loading: false });
	}

	async componentDidMount() {
		let redirect = this;
		await axios
			.get(config.APP_URL + "/api/user-role")
			.then(function(response) {
				redirect.setState({ existing_roles: response.data });
			});

		this.setState({ loading: false });
	}

	render() {
		if (this.state.loading) {
			return (
				<div>
					<div
						className="content"
						style={{
							minHeight: 500
						}}
					>
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-header">
										<strong className="card-title">
											Create User Role
										</strong>
									</div>
									<div className="card-body">
										<div className="card-content">
											<div className="todo-list">
												<div className="tdl-holder">
													<div className="tdl-content">
														<div className="row">
															<div className="col"></div>
															<div className="col">
																<div className="text-center">
																	<Loader
																		type="Rings"
																		color="#00BFFF"
																		height={100}
																		width={100}
																	/>
																	<div className="text-center client-roboto-condensed">
																		LOADING...
																	</div>
																</div>
															</div>
															<div className="col"></div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div>
					<div
						className="content"
						style={{
							minHeight: 500
						}}
					>
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-header">
										<strong className="card-title">
											Create User Role
										</strong>
									</div>
									<div className="card-body">
										<form>
											<div className="form-group">
												<label
													htmlFor="roleName"
													className="control-label mb-1"
												>
													Role Name
												</label>
												<input
													id="roleName"
													name="roleName"
													type="text"
													onChange={e =>
														this.handleChange(e.target.value)
													}
													value={this.state.roleName}
													className="form-control"
													aria-required="true"
												/>
											</div>
											<div className="form-group">
												<div className="file-input">
													<input
														type="file"
														id="file-input"
														className="file-input__input"
														name="category_image"
														onChange={e =>
															this.handleFileChange(e)
														}
														accept="image/jpg, image/jpeg, image/png"
													/>
													{/* <label
														className="file-input__label"
														for="file-input"
													>
														<svg
															aria-hidden="true"
															focusable="false"
															data-prefix="fas"
															data-icon="upload"
															className="svg-inline--fa fa-upload fa-w-16"
															role="img"
															xmlns="http://www.w3.org/2000/svg"
															viewBox="0 0 512 512"
														>
															<path
																fill="currentColor"
																d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"
															></path>
														</svg>
														<span>Role Image</span>
													</label> */}
												</div>
											</div>
											<div className="form-group">
												<label
													htmlFor="roleName"
													className="control-label mb-1"
												>
													Copy Data from existing role
												</label>
												<select
													className="custom-select"
													defaultValue={
														this.state.selected_role_id
													}
													onChange={e =>
														this.handleSelectChange(
															e.target.value
														)
													}
												>
													<option value={false}>Select Existing Role</option>
													{this.state.existing_roles
														? this.state.existing_roles.map(
																(role, i) => (
																	<option value={role.id}>
																		{role.role_name}
																	</option>
																)
														  )
														: null}
												</select>
											</div>
											<div>
												<button
													id="payment-button"
													type="submit"
													onClick={this.handleSubmit}
													className="btn btn-secondary btn-block"
												>
													<i className="fa fa-user"></i>&nbsp;
													<span id="payment-button-amount">
														Create Role
													</span>
												</button>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}
}
