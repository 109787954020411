import React, { Component } from "react";
import "react-dropdown-tree-select/dist/styles.css";
import { connect } from "react-redux";
import { MachineCategoryService } from "./../service";
import { add_machine_categories } from "./../action";
import "./../css/index.css";
import { Redirect } from "react-router-dom";
import { YearService } from "./../../year/service";
import SimpleReactValidator from "simple-react-validator";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { CountryService } from "./../../country/service";

import { TreeSelect } from "antd";
import "antd/es/tree-select/style/css";

class AddMachineCategory extends Component {
	constructor(props) {
		super(props);
		this.state = {
			machine_category_model_number: "",
			category_name: "",
			description: "",
			title_name: "",
			parent_category: 0,
			machine_category_list: [],
			category_level: 1,
			brands: [],
			brand: null,
			price: 0,
			cost_price: 0,
			category_image: null,
			category_banner_image: null,
			years: [],
			year: "",
			machine_category_selectable: null,
			countries: [],
			country: ""
		};
		this.validator = new SimpleReactValidator({ autoForceUpdate: this });
		this.onTextChange = this.onTextChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.handleFileChange = this.handleFileChange.bind(this);
		this.onYearChange = this.onYearChange.bind(this);
		this.onCountryChange = this.onCountryChange.bind(this);
	}

	onCountryChange(value) {
		this.setState({ country: value });
	}

	onYearChange(value) {
		this.setState({ year: value });
	}
	onMachineSelectableChange(value) {
		this.setState({ machine_category_selectable: value });
	}

	onSelectChange(value) {
		this.setState({ brand: value });
	}

	handleFileChange(event, key) {
		switch (key) {
			case "category_banner_image":
				this.setState({ category_banner_image: event.target.files[0] });
				break;

			case "category_image":
				this.setState({ category_image: event.target.files[0] });
				break;

			default:
				break;
		}
	}

	async onTextChange(name, value, value1) {
		let redirect = this;
		switch (name) {
			case "machine_category_model_number":
				redirect.setState({ machine_category_model_number: value });
				break;

			case "category_name":
				redirect.setState({ category_name: value });
				break;

			case "title_name":
				redirect.setState({ title_name: value });
				break;

			case "description":
				redirect.setState({ description: value });
				break;

			case "parent_category":
				let data = await MachineCategoryService.showSingleMachineCategory(
					value
				);
				value1 = data.category_level;
				redirect.setState({ parent_category: value });
				redirect.setState({ category_level: value1 + 1 });
				let categories = await MachineCategoryService.getTreeSelectedData(
					value,
					this.props.role_id
				);
				redirect.setState({ machine_category_list: categories });

				break;

			case "price":
				redirect.setState({ price: value });
				break;

			case "cost_price":
				redirect.setState({ cost_price: value });
				break;

			default:
				break;
		}
	}

	onSubmit(e) {
		e.preventDefault();
		const fd = new FormData();

		if (this.validator.allValid()) {
			fd.append(
				"machine_category_model_number",
				this.state.machine_category_model_number
			);
			fd.append("machine_category", this.state.category_name);
			fd.append("parent_category", this.state.parent_category);
			fd.append("category_level", this.state.category_level);
			fd.append("brand_id", this.state.brand);
			fd.append("price", this.state.price);
			fd.append("cost_price", this.state.cost_price);
			fd.append("year_id", this.state.year);
			fd.append("country_id", this.state.country);
			fd.append("description", this.state.description);
			fd.append(
				"machine_category_selectable",
				this.state.machine_category_selectable
			);
			fd.append("title_name", this.state.title_name);

			if (this.state.category_image !== null) {
				fd.append("category_image", this.state.category_image);
			}
			if (this.state.category_banner_image !== null) {
				fd.append(
					"category_banner_image",
					this.state.category_banner_image
				);
			}

			this.props.add_machine_categories(fd);
		} else {
			this.validator.showMessages();
		}
	}

	async componentDidMount() {
		let redirect = this;

		let countries = await CountryService.showCountries();

		await redirect.setState({ countries: countries });

		let years = await YearService.showYear();
		redirect.setState({ years: years });

		let brands = await MachineCategoryService.getBrands();

		redirect.setState({ brands: brands });
		let data = await MachineCategoryService.getTreeSelectData();

		redirect.setState({ machine_category_list: data });
		redirect.setState({ selected_machine_category_list: data });
	}

	render() {
		if (this.props.message.includes("Adding Machine Category Success")) {
			return <Redirect to="/machine-category" />;
		} else {
			if (this.props.loading) {
				return (
					<div>
						<div
							className="content"
							style={{
								minHeight: 500
							}}
						>
							<div className="row">
								<div className="col-sm-12">
									<div className="card">
										<div className="card-header">
											<strong className="card-title">
												Add Machine Category
											</strong>
										</div>
										<div className="card-body">
											<div className="card-content">
												<div className="todo-list">
													<div className="tdl-holder">
														<div className="tdl-content">
															<div className="row">
																<div className="col"></div>
																<div className="col">
																	<div className="text-center">
																		<Loader
																			type="Rings"
																			color="#00BFFF"
																			height={100}
																			width={100}
																		/>
																		<div className="text-center client-roboto-condensed">
																			LOADING...
																		</div>
																	</div>
																</div>
																<div className="col"></div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				);
			} else {
				return (
					<div>
						<div
							className="content"
							style={{
								minHeight: 500
							}}
						>
							<div className="row">
								<div className="col-sm-12">
									<div className="card">
										<div className="card-header">
											<strong className="card-title">
												Add Machine Categories
											</strong>
										</div>
										<div className="card-body">
											<form>
												<div className="form-group">
													<label
														htmlFor="machine_category_model_number"
														className="control-label mb-1"
													>
														Machine Category Model Number
													</label>
													<input
														id="machine_category_model_number"
														name="machine_category_model_number"
														type="text"
														className="form-control"
														onChange={e =>
															this.onTextChange(
																"machine_category_model_number",
																e.target.value
															)
														}
														aria-required="true"
													/>
													<div
														className="mt-4"
														style={{
															color: "red"
														}}
													>
														{this.validator.message(
															"machine_category_model_number",
															this.state
																.machine_category_model_number,
															"string"
														)}
													</div>
												</div>

												<div className="form-group">
													<label
														htmlFor="category_name"
														className="control-label mb-1"
													>
														Machine Category Name
													</label>
													<input
														id="category_name"
														name="category_name"
														type="text"
														className="form-control"
														onChange={e =>
															this.onTextChange(
																"category_name",
																e.target.value
															)
														}
														aria-required="true"
													/>
													<div
														className="mt-4"
														style={{
															color: "red"
														}}
													>
														{this.validator.message(
															"category_name",
															this.state.category_name,
															"required|string"
														)}
													</div>
												</div>

												<div className="form-group">
													<label
														htmlFor="description"
														className="control-label mb-1"
													>
														Machine Category Description
													</label>
													<input
														id="description"
														name="description"
														type="text"
														className="form-control"
														onChange={e =>
															this.onTextChange(
																"description",
																e.target.value
															)
														}
														value={
															this.state.description !== null
																? this.state.description
																: ""
														}
														aria-required="true"
													/>
												</div>

												<div className="form-group">
													<label
														htmlFor="parent_category"
														className="control-label mb-1"
													>
														Parent Category
													</label>

													<TreeSelect
														showSearch
														style={{ width: "100%" }}
														value={this.state.parent_category}
														dropdownStyle={{
															maxHeight: 200,
															overflow: "auto"
														}}
														treeData={
															this.state.machine_category_list
														}
														placeholder="Please select a product"
														treeDefaultExpandAll
														treeNodeFilterProp="title"
														searchPlaceholder="Filter Products"
														onChange={e =>
															this.onTextChange(
																"parent_category",
																e,
																e.category_level
															)
														}
													/>
													<div
														className="mt-4"
														style={{
															color: "red"
														}}
													>
														{this.validator.message(
															"parent_category",
															this.state.parent_category,
															"required|numeric"
														)}
													</div>
												</div>

												<div className="form-group">
													<label
														htmlFor="year"
														className="control-label mb-1"
													>
														Machine Category selectable
													</label>
													<select
														onChange={e =>
															this.onMachineSelectableChange(
																e.target.value
															)
														}
														defaultValue={
															this?.state
																?.machine_category_selectable
														}
														className="custom-select"
													>
														<option value={null}>
															Select Option
														</option>
														{[
															{
																value: 1,
																label: "Yes"
															},
															{
																value: 0,
																label: "NO"
															}
														].map((year, i) => (
															<option
																key={`option-${year.value}-${year.label}`}
																value={year.value}
															>
																{year.label}
															</option>
														))}
													</select>
													<div
														className="mt-4"
														style={{
															color: "red"
														}}
													>
														{this.validator.message(
															"year",
															this.state
																?.machine_category_selectable,
															"required|numeric"
														)}
													</div>
												</div>

												<div className="form-group">
													<label
														htmlFor="file-input"
														className="control-label mb-1"
													>
														Machine Category Image (Current)
													</label>
													<input
														type="file"
														id="file-input"
														name="category_image"
														className="form-control"
														onChange={e =>
															this.handleFileChange(
																e,
																"category_image"
															)
														}
														accept="image/jpg, image/jpeg, image/png"
													/>
												</div>

												<div className="form-group">
													<label
														htmlFor="file-input-banner"
														className="control-label mb-1"
													>
														Machine Category Banner Image
													</label>
													<input
														type="file"
														id="file-input-banner"
														className="form-control"
														name="category_banner_image"
														onChange={e =>
															this.handleFileChange(
																e,
																"category_banner_image"
															)
														}
														accept="image/jpg, image/jpeg, image/png"
													/>
												</div>

												<div className="form-group">
													<label
														htmlFor="title_name"
														className="control-label mb-1"
													>
														Machine Category Title Name
													</label>
													<input
														id="title_name"
														name="title_name"
														type="text"
														className="form-control"
														onChange={e =>
															this.onTextChange(
																"title_name",
																e.target.value
															)
														}
														value={
															this.state.title_name !== null
																? this.state.title_name
																: ""
														}
														aria-required="true"
													/>
												</div>
												<div className="form-group">
													<label
														htmlFor="price"
														className="control-label mb-1"
													>
														Machine Category Price
													</label>
													<input
														id="price"
														name="price"
														type="text"
														className="form-control"
														aria-required="true"
														placeholder="E.g. 47000.00"
														onChange={e =>
															this.onTextChange(
																"price",
																e.target.value
															)
														}
														value={this.state.price}
													/>
													<div
														className="mt-4"
														style={{
															color: "red"
														}}
													>
														{this.validator.message(
															"price",
															this.state.price,
															"required|numeric"
														)}
													</div>
												</div>

												<div className="form-group">
													<label
														htmlFor="cost_price"
														className="control-label mb-1"
													>
														Machine Category Cost Price
													</label>
													<input
														id="cost_price"
														name="cost_price"
														type="text"
														className="form-control"
														aria-required="true"
														placeholder="E.g. 47000.00"
														onChange={e =>
															this.onTextChange(
																"cost_price",
																e.target.value
															)
														}
														value={this.state.cost_price}
													/>
													<div
														className="mt-4"
														style={{
															color: "red"
														}}
													>
														{this.validator.message(
															"cost_price",
															this.state.cost_price,
															"required|numeric"
														)}
													</div>
												</div>

												<div className="form-group">
													<label
														htmlFor="year"
														className="control-label mb-1"
													>
														Year
													</label>
													<select
														onChange={e =>
															this.onYearChange(e.target.value)
														}
														defaultValue={this.state.year}
														className="custom-select"
													>
														<option value={null}>
															Select Year
														</option>
														{this.state.years.map((year, i) => (
															<option key={i} value={year.id}>
																{year.year}
															</option>
														))}
													</select>
													<div
														className="mt-4"
														style={{
															color: "red"
														}}
													>
														{this.validator.message(
															"year",
															this.state.year,
															"required|numeric"
														)}
													</div>
												</div>

												<div className="form-group">
													<label
														htmlFor="year"
														className="control-label mb-1"
													>
														Country
													</label>
													<select
														onChange={e =>
															this.onCountryChange(
																e.target.value
															)
														}
														value={this.state.country}
														className="custom-select"
													>
														<option value="">
															Select Country
														</option>
														{this.state.countries
															? this.state.countries.map(
																	(country, i) => (
																		<option
																			key={i}
																			value={country.id}
																		>
																			{country.country_name}
																		</option>
																	)
															  )
															: null}
													</select>
													<div
														className="mt-4"
														style={{
															color: "red"
														}}
													>
														{this.validator.message(
															"country",
															this.state.country,
															"required|numeric"
														)}
													</div>
												</div>

												<div className="form-group">
													<label
														htmlFor="price"
														className="control-label mb-1"
													>
														Machine Brand (optional)
													</label>
													<select
														onChange={e =>
															this.onSelectChange(e.target.value)
														}
														defaultValue={this.state.brand}
														className="custom-select"
													>
														<option value={null}>
															Select Brand
														</option>
														{this.state.brands.map((brand, i) => (
															<option key={i} value={brand.id}>
																{brand.brand_name}
															</option>
														))}
													</select>
													<div
														className="mt-4"
														style={{
															color: "red"
														}}
													>
														{this.validator.message(
															"brand",
															this.state.brand,
															"numeric"
														)}
													</div>
												</div>

												<div>
													<button
														onClick={this.onSubmit}
														id="payment-button"
														type="submit"
														className="btn btn-secondary btn-block"
													>
														<i className="fa fa-truck"></i>&nbsp;
														<span id="payment-button-amount">
															Add Machine Category
														</span>
													</button>
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				);
			}
		}
	}
}

const mapStateToProps = state => {
	return {
		message: state.showMachineCategoryReducer.message,
		loading: state.showMachineCategoryReducer.loading,
		role_id: state.loginReducer.user.role_id
	};
};

export default connect(mapStateToProps, {
	add_machine_categories
})(AddMachineCategory);


