import React, { Component } from "react";

export default class GraphicalData extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <div>
        <div
          className="content"
          style={{
            minHeight: 500
          }}
        ></div>
      </div>
    );
  }
}
