import { Api } from "./../../config/api";

function showNotesAndRemarks(params) {
  let data = Api.get(
    "compatibility/getCustomNoteAndRemark/" +
      params.machine_part_id +
      "/" +
      params.machine_category_id
  )
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {});

  if (data) {
    return data;
  } else {
    return false;
  }
}

function updateNoteAndRemark(params) {
  let data = Api.post(
    "compatibility/addOrUpdateCustomNoteAndRemark/" +
      params.machine_part_id +
      "/" +
      params.machine_category_id,
    params
  )
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {});

  if (data) {
    return data;
  } else {
    return false;
  }
}

export const NoteRemarkService = {
  showNotesAndRemarks,
  updateNoteAndRemark
};
