import React, { Component } from "react";
import { connect } from "react-redux";
import { ClientConfigurationService } from "./../../configuration/service";
import { MultiSelect } from "primereact/multiselect";
import { save_second_form_data } from "./../action";
import { css } from "@emotion/react";
import { PulseLoader } from "react-spinners";
import { RoleManagementService } from "../../../admin/role/service";
import { notification } from "antd";
import {Link} from 'react-router-dom';

class EditSecondForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			data: false,
			configurations1: false,
			message: "",
			templates: false,
			template: this.props.quote[0].template_id
		};
		this.onNext = this.onNext.bind(this);
		this.onBack = this.onBack.bind(this);
		this.carTemplate = this.carTemplate.bind(this);
		this.selectedCarTemplate = this.selectedCarTemplate.bind(this);
		this.onTemplateChange = this.onTemplateChange.bind(this);
	}

	async onTemplateChange(value) {
		this.setState({ template: value });
	}

	carTemplate(option) {
		return (
			<div className="p-clearfix">
				<span style={{ fontSize: "1em", float: "right", marginTop: "4px" }}>
					{option.label}
				</span>
			</div>
		);
	}

	selectedCarTemplate(value) {
		if (value) {
			return (
				<div className="my-multiselected-item-token">
					<span>{value}</span>
				</div>
			);
		} else {
			return <span className="my-multiselected-empty-token">Choose</span>;
		}
	}

	async onNext(e) {
		e.preventDefault();
		let that = this;

		let configurations = that.state.data;
		let ids = that.state.configurations1;

		let final_array = [];

		if (ids) {
			configurations.forEach(element => {
				ids.forEach(element1 => {
					if (element.value === element1) {
						final_array.push(element);
					}
				});
			});
		}

		let params = {
			selected_configurations: final_array,
			template: this.state.template
		};

		this.props.save_second_form_data(params);
		this.props.onComponentChange("thirdForm");
	}

	async onBack(e) {
		e.preventDefault();
		let that = this;

		let configurations = that.state.data;
		let ids = that.state.configurations1;

		let final_array = [];

		if (ids) {
			configurations.forEach(element => {
				ids.forEach(element1 => {
					if (element.value === element1) {
						final_array.push(element);
					}
				});
			});
		}

		let params = {
			selected_configurations: final_array,
			template: this.state.template
		};

		this.props.save_second_form_data(params);
		this.props.onComponentChange("firstForm");
	}

	async componentDidMount() {
		let that = this;

		let configurations = await ClientConfigurationService.showUserConfigurationsForMultiselect(
			this.props.quote[0].user_id
		);

		if (configurations.length === 0) {
			that.setState({
				message:
					"Please create a new configuration or duplicate an existing configuration first. Then you can connect the configuration to the quote. Library configurations cannot be connected to the quotations."
			});
			notification.info({
				message: "No Configurations!",
				duration: 0,
				description:
					"Please create new configuration or duplicate an existing one"
			});
		}

		let templates = await RoleManagementService.getCurrentTemplateForRole(
			this.props.role_id
		);

		await this.setState({ templates: templates.selected_templates });

		await that.setState({ data: configurations });

		await this.setState({ loading: false });
	}

	render() {
		const override = css`
			display: block;
			margin: 0 auto;
			border-color: red;
		`;
		if (!this.state.data || this.state.loading) {
			return (
				<div className="sweet-loading">
					<div className="container loading-container">
						<div className="row m-5 p-5">
							<PulseLoader
								css={override}
								sizeUnit={"px"}
								size={24}
								color={"#B9373D"}
							/>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div className="my-2">
					<div className="row my-3">
						<div className="col"></div>
						<div className="col-6">
							<div className="content-section implementation multiselect-demo">
								<h5 className="roboto-condensed font-weight-bold text-center my-4">
									Select Configurations
								</h5>
								<MultiSelect
									value={this.state.configurations1}
									options={this.state.data}
									onChange={e =>
										this.setState({ configurations1: e.value })
									}
									className="multi-select"
									filter={true}
									placeholder="Choose Configurations"
								/>
							</div>
						</div>

						<div className="col"></div>
					</div>
					{this.state.message !== "" ? (
						<>
							<div className="row m-3">
								<div className="col-sm">
									<div
										className="mt-4"
										style={{
											color: "red"
										}}
									>
										{this.state.message}
									</div>
								</div>
							</div>

							<div className="row m-3">
								<div className="col-sm text-center">
									<Link
										className="btn client-login"
										style={{
											width: 200
										}}
										to="/configuration-process"
									>
										Create New Configuration
									</Link>
								</div>
							</div>
						</>
					) : null}
					<div className="row">
						<div className="col"></div>
						<div className="col-6">
							<label className="client-roboto-condensed font-weight-bold">
								Select Template
							</label>
							{this.state.templates ? (
								<select
									value={this.state.template}
									onChange={e => this.onTemplateChange(e.target.value)}
									className="custom-select"
								>
									{this.state.templates.map((template, i) => (
										<option key={i} value={template.template_id}>
											{template.template_name}
										</option>
									))}
								</select>
							) : null}
						</div>
						<div className="col"></div>
					</div>
					<div className="row">
						<div className="col">
							<div className="text-center mt-3 mb-3">
								{/* <button type="submit" onClick={this.onBack} className="client-roboto-condensed text-uppercase client-login m-2">Back</button> */}
								<button
									type="submit"
									onClick={this.onNext}
									className="client-roboto-condensed text-uppercase client-login m-2 px-5"
								>
									Next
								</button>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}
}
const mapStateToProps = state => {
	return {
		user_id: state.loginReducer.user.user.id,
		role_id: state.loginReducer.user.role_id,
		secondForm: state.clientQuoteReducer.secondForm,
		quote: state.clientQuoteReducer.quote.quote
	};
};

export default connect(mapStateToProps, {
	save_second_form_data
})(EditSecondForm);
