import { Api } from "./../../config/api";

function showDashboardData() {
  let data = Api.get("dashboard/data")
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {});

  if (data) {
    return data;
  } else {
    return false;
  }
}

export const DashboardService = { showDashboardData };
