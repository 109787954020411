const keyModelFeatureSetActionTypes = {
	SHOW_KEY_MODEL_FEATURES_REQUEST: "show.key.model.features.request",
	SHOW_KEY_MODEL_FEATURES_SUCCESS: "show.key.model.features.success",
	SHOW_KEY_MODEL_FEATURES_FALIURE: "show.key.model.features.faliure",

	SHOW_SINGLE_KEY_MODEL_FEATURE_REQUEST:
		"show.single.key.model.feature.request",
	SHOW_SINGLE_KEY_MODEL_FEATURE_SUCCESS:
		"show.single.key.model.feature.success",
	SHOW_SINGLE_KEY_MODEL_FEATURE_FALIURE:
		"show.single.key.model.feature.faliure",

	ADD_KEY_MODEL_FEATURE_REQUEST: "add.key.model.key.model.feature.request",
	ADD_KEY_MODEL_FEATURE_SUCCESS: "add.key.model.key.model.feature.success",
	ADD_KEY_MODEL_FEATURE_FALIURE: "add.key.model.key.model.feature.faliure",

	UPDATE_KEY_MODEL_FEATURE_REQUEST: "update.key.model.feature.request",
	UPDATE_KEY_MODEL_FEATURE_SUCCESS: "update.key.model.feature.success",
	UPDATE_KEY_MODEL_FEATURE_FALIURE: "update.key.model.feature.faliure",

	DELETE_KEY_MODEL_FEATURE_REQUEST: "delete.key.model.feature.request",
	DELETE_KEY_MODEL_FEATURE_SUCCESS: "delete.key.model.feature.success",
	DELETE_KEY_MODEL_FEATURE_FALIURE: "delete.key.model.feature.faliure",

	UPDATE_KEY_MODEL_FEATURES_REQUEST: "update.key.model.features.request",
	UPDATE_KEY_MODEL_FEATURES_SUCCESS: "update.key.model.features.success",
	UPDATE_KEY_MODEL_FEATURES_FALIURE: "update.key.model.features.faliure",

	CLEAN_MESSAGE_REQUEST: "clean.message.request",
	CLEAN_MESSAGE_SUCCESS: "clean.message.success",
	CLEAN_MESSAGE_FALIURE: "clean.message.faliure"
};
export default keyModelFeatureSetActionTypes;
