import React, { Component } from "react";
import { connect } from "react-redux";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Redirect } from "react-router";
import SimpleReactValidator from "simple-react-validator";
import { Api } from "../../../config/api";

class UpdateDiscountForCategoryByRole extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			max_discount_category: 100,
			machine_category_data: false
		};
		this.validator = new SimpleReactValidator({ autoForceUpdate: this });
		this.onTextChange = this.onTextChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	async onSubmit() {
		let that = this;
		let { role_id } = this.props.match.params;
		let { machine_category_id } = this.props.match.params;
		let params = {
			max_discount_category: this.state.max_discount_category
		};

		if (this.validator.allValid()) {
			Api.post(
				"machine-category/updateMachineCategoryMaxDiscountByRole/" +
					role_id +
					"/" +
					machine_category_id,
				params
			)
				.then(function(response) {
					that.setState({ redirect: true });
				})
				.catch(function(response) {});
		} else {
			this.validator.showMessages();
		}
	}

	async onTextChange(name, value) {
		let redirect = this;
		switch (name) {
			case "max_discount_category":
				redirect.setState({ max_discount_category: value });
				break;

			default:
				break;
		}
	}

	async componentDidMount() {
		let that = this;
		let { role_id } = this.props.match.params;
		let { machine_category_id } = this.props.match.params;

		await Api.get(
			"machine-category/getMachineCategoryMaxDiscountByRole/" +
				role_id +
				"/" +
				machine_category_id
		)
			.then(function(response) {
				that.setState({ machine_category_data: response.data });
				that.setState({
					max_discount_category: response.data[0].max_discount_category
				});
			})
			.catch(function(error) {});

		await this.setState({ loading: false });
	}

	render() {
		if (this.state.redirect) {
			return <Redirect to="/show-cost-price-management-for-role" />;
		} else {
			if (!this.props.loading && !this.state.loading) {
				return (
					<div>
						<div
							className="content"
							style={{
								minHeight: 500
							}}
						>
							<div className="row">
								<div className="col-sm-12">
									<div className="card">
										<div className="card-header">
											<strong className="card-title">
												Max discount for{" "}
												{this.state.machine_category_data
													? this.state.machine_category_data[0]
															.machine_category
													: null}{" "}
												For Role
											</strong>
										</div>
										<div className="card-body">
											<form>
												<div className="row">
													<div className="col-sm-6">
														<div className="form-group">
															<label
																htmlFor="max_discount_category"
																className="control-label mb-1"
															>
																Maximum Discount
															</label>
															<input
																id="max_discount_category"
																name="max_discount_category"
																type="text"
																className="form-control"
																aria-required="true"
																onChange={e =>
																	this.onTextChange(
																		"max_discount_category",
																		e.target.value
																	)
																}
																value={
																	this.state
																		.max_discount_category
																}
															/>
														</div>
														<div
															className="mt-4"
															style={{
																color: "red"
															}}
														>
															{this.validator.message(
																"max_discount_category",
																this.state.max_discount_category,
																"required|numeric"
															)}
														</div>
													</div>
												</div>
											</form>

											<div className="mt-3 text-center">
												<button
													onClick={this.onSubmit}
													className="btn btn-secondary"
													style={{
														borderRadius: "10em"
													}}
												>
													Confirm Max Discount Category By Role
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				);
			} else {
				return (
					<div>
						<div
							className="content"
							style={{
								minHeight: 500
							}}
						>
							<div className="row">
								<div className="col-sm-12">
									<div className="card">
										<div className="card-header">
											<strong className="card-title">
												Show Cost Price Visibility For Role
											</strong>
										</div>
										<div className="card-body">
											<div className="card-content">
												<div className="todo-list">
													<div className="tdl-holder">
														<div className="tdl-content">
															<div className="row">
																<div className="col"></div>
																<div className="col">
																	<div className="text-center">
																		<Loader
																			type="Rings"
																			color="#00BFFF"
																			height={100}
																			width={100}
																		/>
																		<div className="text-center client-roboto-condensed">
																			LOADING...
																		</div>
																	</div>
																</div>
																<div className="col"></div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				);
			}
		}
	}
}
const mapStateToProps = state => {
	return {};
};

export default connect(mapStateToProps, {})(UpdateDiscountForCategoryByRole);
