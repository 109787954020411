import React, { Component } from "react";
import { connect } from "react-redux";
import { ClientConfigurationService } from "./../service";
import { save_second_form } from "./../action";
import { message, notification, Tooltip } from "antd";
import { Api } from "./../../../config/api";
import { css } from "@emotion/react";
import { PulseLoader } from "react-spinners";
import { Redirect } from "react-router-dom";
import strings from "./../../../config/language";
import config from "./../../../config/config";
import Modal from "react-modal";
import getSymbolFromCurrency from "currency-symbol-map";

const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)"
	}
};

class SecondForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			modalIsOpen: false,
			modalImage: false,
			compatible_machine_parts: false,
			final_machine_parts: [],
			total_types: false,
			category_details: false,
			noPartRedirection: false,
			manualAdjustments: this.props.second_form
				? this.props.second_form.manual_adjustments
				: [],
			parent_model_number: [],
			negative_parent_model_number: []
		};
		this.onAdjustmentsQuantityChange = this.onAdjustmentsQuantityChange.bind(
			this
		);
		this.onAdjustmentsQuantityChange = this.onAdjustmentsQuantityChange.bind(
			this
		);
		this.handleAdjustmentChange = this.handleAdjustmentChange.bind(this);
		this.addManualAdjustmentField = this.addManualAdjustmentField.bind(this);
		this.onQuantityChange = this.onQuantityChange.bind(this);
		this.onNext = this.onNext.bind(this);
		this.onBack = this.onBack.bind(this);

		this.openModal = this.openModal.bind(this);
		this.afterOpenModal = this.afterOpenModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
	}

	camelCasetoWords(str) {
		return str.charAt(0).toUpperCase() + str.slice(1);
	}

	onAdjustMentDelete(i) {
		let new_quantity_plus = this.state.manualAdjustments;
		new_quantity_plus.splice(i, 1);

		this.setState({ manualAdjustments: new_quantity_plus });
	}

	async openModal(value) {
		await this.setState({ modalImage: value });
		this.setState({ modalIsOpen: true });
	}

	afterOpenModal() { }

	async closeModal() {
		await this.setState({ modalImage: false });
		this.setState({ modalIsOpen: false });
	}

	async onAdjustmentsQuantityChange(i, quantity, operation) {
		switch (operation) {
			case "plus":
				if (quantity !== 100) {
					let new_quantity_plus = this.state.manualAdjustments;
					quantity++;
					new_quantity_plus[i].manual_adjustment_quantity = quantity;
					this.setState({ manualAdjustments: new_quantity_plus });
				} else {
					notification.info({
						message: "Information!",
						description:
							"Manual adjustments parts cannot be more than 100."
					});
				}
				break;

			case "minus":
				if (quantity !== 1) {
					let new_quantity_minus = this.state.manualAdjustments;
					quantity--;
					new_quantity_minus[i].manual_adjustment_quantity = quantity;
					this.setState({ manualAdjustments: new_quantity_minus });
				} else {
					notification.info({
						message: "Information!",
						description: "Manual adjutments parts cannot be less than 1."
					});
				}
				break;

			default:
				break;
		}
	}

	handleAdjustmentChange(event, name, i) {
		switch (name) {
			case "manual_adjustment_model_number":
				if (event.length < 255) {
					let new_manual_adjustment_model_number = this.state
						.manualAdjustments;
					new_manual_adjustment_model_number[
						i
					].manual_adjustment_model_number = event;
					this.setState({
						manualAdjustments: new_manual_adjustment_model_number
					});
				} else {
					notification.info({
						message: "Information!",
						description: "Model number length exceeded."
					});
				}
				break;

			case "manual_adjustment_name":
				if (event.length < 255) {
					let new_manual_adjustment_name = this.state.manualAdjustments;
					new_manual_adjustment_name[i].manual_adjustment_name = event;
					this.setState({ manualAdjustments: new_manual_adjustment_name });
				} else {
					notification.info({
						message: "Information!",
						description: "Adjustment name length exceeded."
					});
				}
				break;

			case "manual_adjustment_type":
				if (event.length < 255) {
					let new_manual_adjustment_type = this.state.manualAdjustments;
					new_manual_adjustment_type[i].manual_adjustment_type = event;
					this.setState({ manualAdjustments: new_manual_adjustment_type });
				} else {
					notification.info({
						message: "Information!",
						description: "Adjustment type length exceeded."
					});
				}
				break;

			case "manual_adjustment_note":
				if (event.length < 255) {
					let new_manual_adjustment_note = this.state.manualAdjustments;
					new_manual_adjustment_note[i].manual_adjustment_note = event;
					this.setState({ manualAdjustments: new_manual_adjustment_note });
				} else {
					notification.info({
						message: "Information!",
						description: "Adjustment note length exceeded."
					});
				}
				break;

			case "manual_adjustment_price":
				let new_field_size = this.state.manualAdjustments;
				if (!isNaN(event)) {
					new_field_size[i].manual_adjustment_price = event;
					this.setState({ manualAdjustments: new_field_size });
				}
				break;

			default:
				break;
		}
	}

	addManualAdjustmentField() {
		this.setState({
			manualAdjustments: [
				...this.state.manualAdjustments,
				...[
					{
						manual_adjustment_model_number: "",
						manual_adjustment_name: "",
						manual_adjustment_type: "",
						manual_adjustment_note: "",
						manual_adjustment_price: 0,
						manual_adjustment_quantity: 1,
						currency_symbol_html: this.state.category_details
							.currency_symbol_html
					}
				]
			]
		});
	}

	async removeItem(arr, item) {
		return arr.filter(f => f !== item);
	}

	async onQuantityChange(i, quantity, operation) {
		let that = this;
		let new_data = that.state.compatible_machine_parts;
		let message = "Please select one of these ";
		let message1 =
			"Because you selected the model number " +
			new_data[i].machine_part_model_number +
			", Please don't select one of these ";
		let parent_model_number = that.state.parent_model_number;
		parent_model_number = await this.removeItem(
			parent_model_number,
			new_data[i].machine_part_model_number
		);

		let negative_parent_model_number =
			that.state.negative_parent_model_number;

		if (operation.toLowerCase().includes("minus") && quantity === 1) {
			let data = { 0: new_data[i] };

			if (
				data[0].custom_negative_parent_model_number !== "" &&
				data[0].custom_negative_parent_model_number !== null
			) {
				let splitted_model_number = data[0].custom_negative_parent_model_number.split(
					","
				);

				await splitted_model_number.forEach(async element => {
					negative_parent_model_number = negative_parent_model_number.filter(
						f => f !== element
					);
				});
			}
		}

		if (
			operation.toLowerCase().includes("plus") ||
			(operation.toLowerCase().includes("minus") && quantity > 1)
		) {
			let data = { 0: new_data[i] };
			if (
				data[0].custom_parent_model_number !== "" &&
				data[0].custom_parent_model_number !== null
			) {
				let splitted_model_number = data[0].custom_parent_model_number.split(
					","
				);
				splitted_model_number.forEach(element => {
					let part_quantity = this.state.compatible_machine_parts.filter(
						item => item.machine_part_model_number.includes(element)
					);

					if (part_quantity.length === 0) {
						splitted_model_number = splitted_model_number.filter(
							f => f !== element
						);
					}
				});

				let is_selected = 0;

				splitted_model_number.forEach(element => {
					let part_quantity = this.state.compatible_machine_parts.filter(
						item =>
							item.machine_part_model_number.includes(element) &&
							item.quantity > 0
					);

					if (part_quantity.length > 0) {
						is_selected = is_selected + 1;
					}
				});

				if (splitted_model_number.length === 0) {
					is_selected = 1;
				}

				if (is_selected === 0) {
					splitted_model_number.forEach(element => {
						let part_quantity = this.state.compatible_machine_parts.filter(
							item => item.machine_part_model_number.includes(element)
						);
						if (part_quantity.length === 0) {
							message =
								"Please ask the 'Admin' to add model number " +
								element +
								" to the system or compatibility";
						} else {
							message =
								message +
								"," +
								part_quantity[0].machine_part_model_number;
							parent_model_number.push(
								part_quantity[0].machine_part_model_number
							);
						}
					});

					if (quantity === 0) {
						notification.info({
							message: "Information!",
							description: message,
							duration: 0
						});
					}
				}
			}

			if (
				data[0].custom_negative_parent_model_number !== "" &&
				data[0].custom_negative_parent_model_number !== null
			) {
				let splitted_model_number = data[0].custom_negative_parent_model_number.split(
					","
				);
				splitted_model_number.forEach(element => {
					let part_quantity = this.state.compatible_machine_parts.filter(
						item => item.machine_part_model_number.includes(element)
					);
					if (part_quantity.length === 0) {
						message1 = null;
					} else {
						message1 =
							message1 +
							"," +
							part_quantity[0].machine_part_model_number;
						negative_parent_model_number.push(
							part_quantity[0].machine_part_model_number
						);
					}
				});

				if (message1 !== null && quantity === 0) {
					notification.info({
						duration: 0,
						message: "Information!",
						description: message1
					});
				}
			}
		}

		that.setState({ parent_model_number: parent_model_number });
		that.setState({
			negative_parent_model_number: negative_parent_model_number
		});

		switch (operation) {
			case "plus":
				if (quantity === 100) {
					notification.info({
						message: "Information!",
						description: "Quantity cannot be more than 100."
					});
					break;
				}
				quantity++;
				new_data[i].quantity = quantity;
				new_data[i].total_price = quantity * new_data[i].price;

				that.setState({ compatible_machine_parts: new_data });
				break;

			case "minus":
				if (quantity === 0) {
					notification.info({
						message: "Information!",
						description: "Quantity cannot be less than 0."
					});
					break;
				}
				quantity--;
				new_data[i].quantity = quantity;
				new_data[i].total_price = quantity * new_data[i].price;

				that.setState({ compatible_machine_parts: new_data });
				break;

			default:
				break;
		}
	}

	async finalizeMachineParts() {
		let that = this;

		let total_types = [];

		let compatible_machine_parts = await ClientConfigurationService.showCompatibilityForCategory(
			this.props.first_form.configuration_category,
			this.props.country_id
		);

		compatible_machine_parts.forEach(element => {
			total_types.push(element.machine_part_type.toLowerCase());
		});

		total_types = [...new Set(total_types)];


		let final_machine_parts = [];
		that.state.compatible_machine_parts.forEach(element => {
			if (element.quantity !== 0) {
				final_machine_parts.push(element);
			}
		});

		that.setState({ final_machine_parts: final_machine_parts });
	}

	async onNext(e) {
		e.preventDefault();

		this.setState({ loading: true });

		await this.finalizeMachineParts();

		let checker = 0;

		let message = "Please select one of these";
		let element = null;
		let element1 = null;
		let element2 = null;
		let final_machine_parts = this.state.final_machine_parts;
		await this.setState({ parent_model_number: [] });

		for (element of final_machine_parts) {
			if (
				element.custom_parent_model_number !== "" &&
				element.custom_parent_model_number !== null
			) {
				let splitted_model_number = element.custom_parent_model_number.split(
					","
				);
				splitted_model_number.forEach(element => {
					let part_quantity = this.state.compatible_machine_parts.filter(
						item => item.machine_part_model_number.includes(element)
					);

					if (part_quantity.length === 0) {
						splitted_model_number = splitted_model_number.filter(
							f => f !== element
						);
					}
				});
				let is_selected = 0;

				for (element1 of splitted_model_number) {
					// eslint-disable-next-line no-loop-func
					let part_quantity = await this.state.compatible_machine_parts.filter(
						// eslint-disable-next-line no-loop-func
						item =>
							item.machine_part_model_number.includes(element1) &&
							item.quantity > 0
					);

					if (part_quantity.length > 0) {
						is_selected = is_selected + 1;
					}
				}
				let parent_model_number = this.state.parent_model_number;

				if (splitted_model_number.length === 0) {
					is_selected = 1;
				}
				if (is_selected === 0) {
					await checker++;

					for (element2 of splitted_model_number) {
						// eslint-disable-next-line no-loop-func
						let part_quantity = await this.state.compatible_machine_parts.filter(
							// eslint-disable-next-line no-loop-func
							item => item.machine_part_model_number.includes(element2)
						);
						if (part_quantity.length === 0) {
							message =
								"Please ask the 'Admin' to add model number " +
								element2 +
								" to the system and compatibility";
						} else {
							message =
								message +
								"," +
								part_quantity[0].machine_part_model_number;
							parent_model_number.push(
								part_quantity[0].machine_part_model_number
							);
						}
					}

					notification.info({
						duration: 0,
						message: "Information!",
						description: message
					});
				}
				this.setState({ parent_model_number: parent_model_number });
			}
		}

		this.state.manualAdjustments.forEach(element => {
			if (element.manual_adjustment_name === "") {
				checker = 1;
				notification.info({
					duration: 0,
					message: "Information!",
					description: "Manual adjustment name cannot be empty."
				});
			}
		});

		if (checker === 0) {
			let that = this;
			if (that.state.final_machine_parts) {
				let params = {
					compatible_machine_parts: that.state.final_machine_parts,
					manual_adjustments: that.state.manualAdjustments
				};

				this.props.save_second_form(params);

				this.props.onComponentChange("thirdForm", "");
			}
		}

		this.setState({ loading: false });
	}

	async onBack(e) {
		e.preventDefault();

		this.setState({ loading: true });

		await this.finalizeMachineParts();
		let that = this;
		if (that.state.final_machine_parts) {
			let params = {
				compatible_machine_parts: that.state.final_machine_parts,
				manual_adjustments: that.state.manualAdjustments
			};

			this.props.save_second_form(params);

			this.props.onComponentChange("firstForm", "");
		}

		this.setState({ loading: false });
	}

	async componentDidMount() {
		let redirect = this;

		await Api.get(
			config.APP_URL +
			"/api/machine-category/" +
			this.props.first_form.configuration_category
		)
			.then(function (response) {
				redirect.setState({ category_details: response.data[0] });
			})
			.catch(function (error) {
				return false;
			});

		let total_types = [];

		let compatible_machine_parts = await ClientConfigurationService.showCompatibilityForCategory(
			this.props.first_form.configuration_category,
			this.props.country_id
		);

		if(this.props.first_form.only_parts){
			compatible_machine_parts.forEach(element => {
				element.quantity = 0;
			});
		}


		compatible_machine_parts.forEach(element => {
			total_types.push(element.machine_part_type.trim().toLowerCase());
		});

		total_types = [...new Set(total_types)];

		let existing_sequence = await Api.get(
			"sequence/" + this.props.first_form.configuration_category
		)
			.then(function (response) {
				return response.data;
			})
			.catch(function (error) {
				return false;
			});

		if (
			existing_sequence.length === 0 ||
			existing_sequence.length < total_types.length
		) {
			total_types.sort(function (a, b) {
				if (existing_sequence.indexOf(a) === -1) {
					return total_types.length;
				}
				return existing_sequence.indexOf(a) - existing_sequence.indexOf(b);
			});
			await redirect.setState({ total_types: total_types });
		} else {
			total_types.sort(function (a, b) {
				return existing_sequence.indexOf(a) - existing_sequence.indexOf(b);
			});
			await redirect.setState({ total_types: total_types });
		}

		if (this.props.second_form) {
			let stored_parts = this.props.second_form.compatible_machine_parts;

			let finalOut = [];

			compatible_machine_parts.forEach(element => {
				stored_parts.forEach(element1 => {
					if (
						element1.machine_part_model_number ===
						element.machine_part_model_number
					) {
						element.quantity = element1.quantity;
						element.total_price = element.quantity * element.price;
					}
				});
			});
			finalOut = compatible_machine_parts;

			// if (finalOut.length === 0) {
			// 	this.props.onComponentChange(
			// 		"firstForm",
			// 		"There are no parts for the selected machine please choose another machine"
			// 	);
			// }
			redirect.setState({ compatible_machine_parts: finalOut });
		} else {
			if (compatible_machine_parts.length === 0) {
				message.info("There are no parts for the selected machine, please use this for custom information.")
				// this.props.onComponentChange(
				// 	"firstForm",
				// 	"There are no parts for the selected machine please choose another machine"
				// );
			}
			redirect.setState({
				compatible_machine_parts: compatible_machine_parts
			});
		}

		// if (this.state.compatible_machine_parts.length === 0) {
		// 	notification.info({
		// 		message: "Sorry!",
		// 		description:
		// 			"The selected machine category has no compatible machine parts."
		// 	});
		// }

		await this.setState({ loading: false });
	}

	merge_array(array1, array2) {
		var result_array = [];
		var arr = array1.concat(array2);
		var len = arr.length;
		var assoc = {};

		while (len--) {
			var item = arr[len];

			if (!assoc[item]) {
				result_array.unshift(item);
				assoc[item] = true;
			}
		}

		return result_array;
	}

	render() {
		strings.setLanguage(this.props.language);
		const override = css`
			display: block;
			margin: 0 auto;
			border-color: red;
		`;
		if (!this.state.compatible_machine_parts || this.state.loading) {
			return (
				<div className="sweet-loading">
					<div className="container loading-container">
						<div className="row m-5 p-5">
							<PulseLoader
								css={override}
								sizeUnit={"px"}
								size={24}
								color={"#B9373D"}
							/>
						</div>
					</div>
				</div>
			);
		} else {
			if (this.state.noPartRedirection) {
				return <Redirect to="/configuration-process" />;
			} else {
				return (
					<div className="row">
						<Modal
							isOpen={this.state.modalIsOpen}
							onAfterOpen={this.afterOpenModal}
							onRequestClose={this.closeModal}
							style={customStyles}
							contentLabel="Example Modal"
						>
							<div
								className="row"
								style={{
									justifyContent: "flex-end"
								}}
							>
								<i
									className="fas fa-times m-3"
									onClick={this.closeModal}
								/>
							</div>
							<div>
								{this.state.modalImage ? (
									<img loading="lazy"
										width="500"
										src={
											config.APP_URL +
											"/api/storage/" +
											this.state.modalImage
										}
										alt="machine-part"
									/>
								) : null}
							</div>
						</Modal>

						<table
							className="table table-striped"
							style={{
								width: "auto",
								minWidth: "100%"
							}}
						>
							<thead className="thead-dark">
								<tr>
									<th scope="col" className="five-percent">
										Model Number
									</th>
									<th scope="col">Description</th>
									<th scope="col">Remarks</th>
									<th scope="col">Note</th>
									{this.props.cost_price_visible ? (
										<th scope="col" className="ten-percent">
											Cost Price
										</th>
									) : null}
									<th scope="col" className="ten-percent">
										Price
									</th>
									<th scope="col" className="eleven-percent">
										Quantity
									</th>
									<th scope="col" className="ten-percent">
										Total Price
									</th>
									<th scope="col" className="five-percent">
										<i className="fas fa-camera-retro"></i>
									</th>
								</tr>
							</thead>
							<tbody>
								<tr
									style={{
										backgroundColor: "#B9373D",
										color: "#ffffff"
									}}
								>
									<td
										colSpan={5}
										style={{
											color: "white",
											fontWeight: 900,
											fontSize: 15
										}}
									>
										Components
									</td>
									{this.props.cost_price_visible ? <td></td> : null}
									<td></td>
									<td></td>
									<td></td>
								</tr>
								<tr>
									<td className="min-width-table">
										{this.state.category_details &&
											this.state.category_details !== null
											? this.state.category_details
												.machine_category_model_number
											: "#"}
									</td>
									<td className="min-width-table">
										{this.state.category_details
											? this.state.category_details.machine_category
											: null}
									</td>
									<td className="min-width-table"></td>
									<td className="min-width-table"></td>
									{this.props.cost_price_visible ? (
										<td className="money min-width-table">
											{getSymbolFromCurrency(
												this.state.category_details
													.currency_symbol_html
											)}{" "}
											{this.state.category_details
												? parseFloat(
													this.state.category_details.cost_price
												).toFixed(2)
												: null}
										</td>
									) : null}
									<td className="money min-width-table">
										{getSymbolFromCurrency(
											this.state.category_details
												.currency_symbol_html
										)}{" "}
										{this.state.category_details
											? parseFloat(
												this.state.category_details.price
											).toFixed(2)
											: null}
									</td>
									<td>{this.props.first_form ?
										this.props.first_form.only_parts ? 0 : 1 : 1}</td>
									<td className="money">
										{getSymbolFromCurrency(
											this.state.category_details
												.currency_symbol_html
										)}{" "}
										{
											this.state.category_details ?
												this.props.first_form ?
													this.props.first_form.only_parts ? 0 : parseFloat(this.state.category_details.price).toFixed(2) : parseFloat(this.state.category_details.price).toFixed(2)
												: null
										}
									</td>
									<td>
										{this.state.category_details
											.machine_category_image !== null ? (
											<i
												onClick={() =>
													this.openModal(
														this.state.category_details
															.machine_category_image
													)
												}
												className="fas fa-camera-retro"
											></i>
										) : null}
									</td>
								</tr>
								{this.state.total_types
									? this.state.total_types.map((type, j) => {
										var k = this.state.compatible_machine_parts.map(
											(parts, i) =>
												type.toLowerCase() ===
													parts.machine_part_type
														.trim()
														.toLowerCase() ? (
													this.state.parent_model_number.includes(
														parts.machine_part_model_number
													) &&
														!this.state.negative_parent_model_number.includes(
															parts.machine_part_model_number
														) ? (
														<tr
															style={{
																backgroundColor:
																	"rgba(46, 204, 113, 0.4)",
																color: "#ffffff"
															}}
														>
															<td className="min-width-table">
																{
																	parts.machine_part_model_number
																}
															</td>
															<td className="min-width-table">
																{parts.machine_part_name}
															</td>
															<td className="min-width-table">
																{parts.custom_remark ===
																	"" ||
																	parts.custom_remark === null
																	? parts.machine_part_remarks !==
																		null
																		? parts.machine_part_remarks
																		: null
																	: parts.custom_remark}
															</td>
															<td className="min-width-table">
																{parts.custom_note === "" ||
																	parts.custom_note === null
																	? parts.machine_part_note !==
																		null
																		? parts.machine_part_note
																		: null
																	: parts.custom_note}
															</td>

															{this.props
																.cost_price_visible ? (
																parts.is_included && !this.props.first_form.only_parts ? (
																	<td className="money min-width-table">
																		Included
																	</td>
																) : (
																	<td className="money min-width-table">
																		{getSymbolFromCurrency(
																			parts.currency_symbol_html
																		)}{" "}
																		{parts.cost_price.toFixed(
																			2
																		)}
																	</td>
																)
															) : null}

															{parts.is_included && !this.props.first_form.only_parts ? (
																<>
																	<td className="money min-width-table">
																		Included
																		</td>
																	<td>{parts.quantity}</td>
																	<td className="money">
																		Included
																		</td>
																</>
															) : (
																<>
																	<td className="money min-width-table">
																		{getSymbolFromCurrency(
																			parts.currency_symbol_html
																		)}{" "}
																		{parts.price.toFixed(
																			2
																		)}
																	</td>
																	<td>
																		<div className="client-counter row">
																			<button
																				onClick={e =>
																					this.onQuantityChange(
																						i,
																						parts.quantity,
																						"minus"
																					)
																				}
																				className="plus-minus"
																			>
																				-
																				</button>
																			<input
																				type="number"
																				min="0"
																				max="100"
																				value={
																					parts.quantity <
																						10
																						? "0" +
																						parts.quantity
																						: parts.quantity
																				}
																			/>
																			{parts.custom_quantity &&
																				parts.custom_quantity !==
																				null ? (
																				<Tooltip
																					placement="top"
																					title={
																						"Please select " +
																						parts.custom_quantity +
																						" of this machine part."
																					}
																				>
																					<button
																						onClick={e =>
																							this.onQuantityChange(
																								i,
																								parts.quantity,
																								"plus"
																							)
																						}
																						className="plus-minus"
																					>
																						+
																						</button>
																				</Tooltip>
																			) : (
																				<button
																					onClick={e =>
																						this.onQuantityChange(
																							i,
																							parts.quantity,
																							"plus"
																						)
																					}
																					className="plus-minus"
																				>
																					+
																				</button>
																			)}
																		</div>
																	</td>
																	<td className="money">
																		{getSymbolFromCurrency(
																			parts.currency_symbol_html
																		)}{" "}
																		{parts.total_price.toFixed(
																			2
																		)}
																	</td>
																</>
															)}
															<td>
																{parts.machine_part_image !==
																	null ? (
																	<i
																		onClick={() =>
																			this.openModal(
																				parts.machine_part_image
																			)
																		}
																		className="fas fa-camera-retro"
																	></i>
																) : null}
															</td>
														</tr>
													) : this.state.negative_parent_model_number.includes(
														parts.machine_part_model_number
													) ? (
														<tr
															style={{
																backgroundColor:
																	"rgba(185, 55, 61, 0.4)",
																color: "#000000"
															}}
														>
															<td className="min-width-table">
																{
																	parts.machine_part_model_number
																}
															</td>
															<td className="min-width-table">
																{parts.machine_part_name}
															</td>
															<td className="min-width-table">
																{parts.custom_remark ===
																	"" ||
																	parts.custom_remark === null
																	? parts.machine_part_remarks !==
																		null
																		? parts.machine_part_remarks
																		: null
																	: parts.custom_remark}
															</td>
															<td className="min-width-table">
																{parts.custom_note === "" ||
																	parts.custom_note === null
																	? parts.machine_part_note !==
																		null
																		? parts.machine_part_note
																		: null
																	: parts.custom_note}
															</td>

															{this.props
																.cost_price_visible ? (
																parts.is_included && !this.props.first_form.only_parts ? (
																	<td className="money min-width-table">
																		Included
																	</td>
																) : (
																	<td className="money min-width-table">
																		{getSymbolFromCurrency(
																			parts.currency_symbol_html
																		)}{" "}
																		{parts.cost_price.toFixed(
																			2
																		)}
																	</td>
																)
															) : null}

															{parts.is_included && !this.props.first_form.only_parts ? (
																<>
																	<td className="money min-width-table">
																		Included
																		</td>
																	<td>{parts.quantity}</td>
																	<td className="money">
																		Included
																		</td>
																</>
															) : (
																<>
																	<td className="money min-width-table">
																		{getSymbolFromCurrency(
																			parts.currency_symbol_html
																		)}{" "}
																		{parts.price.toFixed(
																			2
																		)}
																	</td>
																	<td>
																		<div className="client-counter row">
																			<button
																				onClick={e =>
																					this.onQuantityChange(
																						i,
																						parts.quantity,
																						"minus"
																					)
																				}
																				className="plus-minus"
																			>
																				-
																				</button>
																			<input
																				type="number"
																				min="0"
																				max="100"
																				value={
																					parts.quantity <
																						10
																						? "0" +
																						parts.quantity
																						: parts.quantity
																				}
																			/>

																			{parts.custom_quantity &&
																				parts.custom_quantity !==
																				null ? (
																				<Tooltip
																					placement="top"
																					title={
																						"You can't select this machine because one of " +
																						parts.negative_child_model_number +
																						" is already selected."
																					}
																				>
																					<button
																						disabled
																						onClick={e =>
																							this.onQuantityChange(
																								i,
																								parts.quantity,
																								"plus"
																							)
																						}
																						className="plus-minus"
																					>
																						+
																						</button>
																				</Tooltip>
																			) : (
																				<Tooltip
																					placement="top"
																					title={
																						"You can't select this machine because one of " +
																						parts.negative_child_model_number +
																						" is already selected."
																					}
																				>
																					<button
																						disabled
																						style={{
																							border:
																								"none"
																						}}
																						onClick={e =>
																							this.onQuantityChange(
																								i,
																								parts.quantity,
																								"plus"
																							)
																						}
																						className="plus-minus"
																					>
																						+
																						</button>
																				</Tooltip>
																			)}
																		</div>
																	</td>
																	<td className="money">
																		{getSymbolFromCurrency(
																			parts.currency_symbol_html
																		)}{" "}
																		{parts.total_price.toFixed(
																			2
																		)}
																	</td>
																</>
															)}
															<td>
																{parts.machine_part_image !==
																	null ? (
																	<i
																		onClick={() =>
																			this.openModal(
																				parts.machine_part_image
																			)
																		}
																		className="fas fa-camera-retro"
																	></i>
																) : null}
															</td>
														</tr>
													) : parts.custom_priority &&
														parts.custom_priority !== null ? (
														<tr
															style={{
																backgroundColor:
																	"rgba(44, 130, 201, 0.4)",
																color: "#ffffff"
															}}
														>
															<td className="min-width-table">
																{
																	parts.machine_part_model_number
																}
															</td>
															<td className="min-width-table">
																{parts.machine_part_name}
															</td>
															<td className="min-width-table">
																{parts.custom_remark ===
																	"" ||
																	parts.custom_remark === null
																	? parts.machine_part_remarks !==
																		null
																		? parts.machine_part_remarks
																		: null
																	: parts.custom_remark}
															</td>
															<td className="min-width-table">
																{parts.custom_note === "" ||
																	parts.custom_note === null
																	? parts.machine_part_note !==
																		null
																		? parts.machine_part_note
																		: null
																	: parts.custom_note}
															</td>

															{this.props
																.cost_price_visible ? (
																parts.is_included && !this.props.first_form.only_parts ? (
																	<td className="money min-width-table">
																		Included
																	</td>
																) : (
																	<td className="money min-width-table">
																		{getSymbolFromCurrency(
																			parts.currency_symbol_html
																		)}{" "}
																		{parts.cost_price.toFixed(
																			2
																		)}
																	</td>
																)
															) : null}

															{parts.is_included && !this.props.first_form.only_parts ? (
																<>
																	<td className="money min-width-table">
																		Included
																		</td>
																	<td>{parts.quantity}</td>
																	<td className="money">
																		Included
																		</td>
																</>
															) : (
																<>
																	<td className="money min-width-table">
																		{getSymbolFromCurrency(
																			parts.currency_symbol_html
																		)}{" "}
																		{parts.price.toFixed(
																			2
																		)}
																	</td>
																	<td>
																		<div className="client-counter row">
																			<button
																				onClick={e =>
																					this.onQuantityChange(
																						i,
																						parts.quantity,
																						"minus"
																					)
																				}
																				className="plus-minus"
																			>
																				-
																				</button>
																			<input
																				type="number"
																				min="0"
																				max="100"
																				value={
																					parts.quantity <
																						10
																						? "0" +
																						parts.quantity
																						: parts.quantity
																				}
																			/>

																			{parts.custom_quantity &&
																				parts.custom_quantity !==
																				null ? (
																				<Tooltip
																					placement="top"
																					title={
																						"Please select " +
																						parts.custom_quantity +
																						" of this machine part."
																					}
																				>
																					<button
																						onClick={e =>
																							this.onQuantityChange(
																								i,
																								parts.quantity,
																								"plus"
																							)
																						}
																						className="plus-minus"
																					>
																						+
																						</button>
																				</Tooltip>
																			) : (
																				<button
																					onClick={e =>
																						this.onQuantityChange(
																							i,
																							parts.quantity,
																							"plus"
																						)
																					}
																					className="plus-minus"
																				>
																					+
																				</button>
																			)}
																		</div>
																	</td>
																	<td className="money">
																		{getSymbolFromCurrency(
																			parts.currency_symbol_html
																		)}{" "}
																		{parts.total_price.toFixed(
																			2
																		)}
																	</td>
																</>
															)}
															<td>
																{parts.machine_part_image !==
																	null ? (
																	<i
																		onClick={() =>
																			this.openModal(
																				parts.machine_part_image
																			)
																		}
																		className="fas fa-camera-retro"
																	></i>
																) : null}
															</td>
														</tr>
													) : (
														<tr>
															<td className="min-width-table">
																{
																	parts.machine_part_model_number
																}
															</td>
															<td className="min-width-table">
																{parts.machine_part_name}
															</td>
															<td className="min-width-table">
																{parts.custom_remark ===
																	"" ||
																	parts.custom_remark === null
																	? parts.machine_part_remarks !==
																		null
																		? parts.machine_part_remarks
																		: null
																	: parts.custom_remark}
															</td>
															<td className="min-width-table">
																{parts.custom_note === "" ||
																	parts.custom_note === null
																	? parts.machine_part_note !==
																		null
																		? parts.machine_part_note
																		: null
																	: parts.custom_note}
															</td>

															{this.props
																.cost_price_visible ? (
																parts.is_included && !this.props.first_form.only_parts ? (
																	<td className="money min-width-table">
																		Included
																	</td>
																) : (
																	<td className="money min-width-table">
																		{getSymbolFromCurrency(
																			parts.currency_symbol_html
																		)}{" "}
																		{parts.cost_price.toFixed(
																			2
																		)}
																	</td>
																)
															) : null}

															{parts.is_included && !this.props.first_form.only_parts ? (
																<>
																	<td className="money min-width-table">
																		Included
																		</td>
																	<td>{parts.quantity}</td>
																	<td className="money">
																		Included
																		</td>
																</>
															) : (
																<>
																	<td className="money min-width-table">
																		{getSymbolFromCurrency(
																			parts.currency_symbol_html
																		)}{" "}
																		{parts.price.toFixed(
																			2
																		)}
																	</td>
																	<td>
																		<div className="client-counter row">
																			<button
																				onClick={e =>
																					this.onQuantityChange(
																						i,
																						parts.quantity,
																						"minus"
																					)
																				}
																				className="plus-minus"
																			>
																				-
																				</button>
																			<input
																				readOnly
																				type="number"
																				min="0"
																				max="100"
																				value={
																					parts.quantity <
																						10
																						? "0" +
																						parts.quantity
																						: parts.quantity
																				}
																			/>

																			{parts.custom_quantity &&
																				parts.custom_quantity !==
																				null ? (
																				<Tooltip
																					placement="top"
																					title={
																						"Please select " +
																						parts.custom_quantity +
																						" of this machine part."
																					}
																				>
																					<button
																						onClick={e =>
																							this.onQuantityChange(
																								i,
																								parts.quantity,
																								"plus"
																							)
																						}
																						className="plus-minus"
																					>
																						+
																						</button>
																				</Tooltip>
																			) : (
																				<button
																					onClick={e =>
																						this.onQuantityChange(
																							i,
																							parts.quantity,
																							"plus"
																						)
																					}
																					className="plus-minus"
																				>
																					+
																				</button>
																			)}
																		</div>
																	</td>
																	<td className="money">
																		{getSymbolFromCurrency(
																			parts.currency_symbol_html
																		)}{" "}
																		{parts.total_price.toFixed(
																			2
																		)}
																	</td>
																</>
															)}
															<td>
																{parts.machine_part_image !==
																	null ? (
																	<i
																		onClick={() =>
																			this.openModal(
																				parts.machine_part_image
																			)
																		}
																		className="fas fa-camera-retro"
																	></i>
																) : null}
															</td>
														</tr>
													)
												) : null
										);
										return [
											<tr
												style={{
													backgroundColor: "#B9373D",
													color: "#ffffff"
												}}
											>
												<td
													colSpan={5}
													style={{
														color: "white",
														fontWeight: 900,
														fontSize: 15
													}}
												>
													{this.camelCasetoWords(type)}
												</td>
												<td></td>
												<td></td>
												{this.props.cost_price_visible ? (
													<td></td>
												) : null}
												<td></td>
											</tr>,
											...k
										];
									})
									: null}
							</tbody>
						</table>

						<table className="table table-striped mt-5">
							<tbody>
								<tr
									style={{
										backgroundColor: "#B9373D",
										color: "#ffffff"
									}}
								>
									<td
										nowrap="nowrap"
										style={{
											color: "white",
											fontWeight: 900,
											fontSize: 15
										}}
									>
										Manual Adjustments
									</td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td>
										<button
											className="client-login px-4"
											style={{
												backgroundColor: "black"
											}}
											onClick={() => this.addManualAdjustmentField()}
										>
											ADD
										</button>
									</td>
								</tr>
								{this.state.manualAdjustments.length > 0 ? (
									<tr className="thead-dark">
										<th scope="col">Model Number</th>
										<th scope="col">Description</th>
										<th scope="col">Note</th>
										<th scope="col"></th>
										<th scope="col">Price</th>
										<th scope="col">Quantity</th>
										<th scope="col">Total Price</th>
										<th scope="col">Actions</th>
									</tr>
								) : null}
								{this.state.manualAdjustments !== null &&
									this.state.manualAdjustments !== []
									? this.state.manualAdjustments.map(
										(adjustment, i) => (
											<tr key={i}>
												<td className="min-width-table">
													<div className="wrap">
														<input
															onChange={e =>
																this.handleAdjustmentChange(
																	e.target.value,
																	"manual_adjustment_model_number",
																	i
																)
															}
															className="full client-custom-border"
															palceholder="Model Number"
															value={
																adjustment.manual_adjustment_model_number
															}
														/>
													</div>
												</td>
												<td className="min-width-table">
													<input
														onChange={e =>
															this.handleAdjustmentChange(
																e.target.value,
																"manual_adjustment_name",
																i
															)
														}
														className="full client-custom-border"
														palceholder="Name"
														value={
															adjustment.manual_adjustment_name
														}
													/>
												</td>
												<td className="min-width-table">
													<input
														onChange={e =>
															this.handleAdjustmentChange(
																e.target.value,
																"manual_adjustment_note",
																i
															)
														}
														className="full client-custom-border"
														palceholder="Note"
														value={
															adjustment.manual_adjustment_note
														}
													/>
												</td>
												<td lassName="min-width-table"></td>
												<td className="money min-width-table">
													{getSymbolFromCurrency(
														adjustment.currency_symbol_html
													) + " "}
													<input
														onChange={e =>
															this.handleAdjustmentChange(
																e.target.value,
																"manual_adjustment_price",
																i
															)
														}
														className="client-custom-border"
														palceholder="Price"
														value={
															adjustment.manual_adjustment_price
														}
													/>
												</td>
												<td>
													<div className="client-counter row">
														<button
															onClick={e =>
																this.onAdjustmentsQuantityChange(
																	i,
																	adjustment.manual_adjustment_quantity,
																	"minus"
																)
															}
															className="plus-minus"
														>
															-
															</button>
														<input
															type="number"
															min="0"
															max="100"
															value={
																adjustment.manual_adjustment_quantity <
																	10
																	? "0" +
																	adjustment.manual_adjustment_quantity
																	: adjustment.manual_adjustment_quantity
															}
														/>
														<button
															onClick={e =>
																this.onAdjustmentsQuantityChange(
																	i,
																	adjustment.manual_adjustment_quantity,
																	"plus"
																)
															}
															className="plus-minus"
														>
															+
															</button>
													</div>
												</td>
												<td className="money">
													{getSymbolFromCurrency(
														adjustment.currency_symbol_html
													) + " "}
													{(
														parseFloat(
															adjustment.manual_adjustment_quantity
														) *
														parseFloat(
															adjustment.manual_adjustment_price
														)
													).toFixed(2)}
												</td>
												<td>
													<button
														className="plus-minus"
														onClick={() =>
															this.onAdjustMentDelete(i)
														}
													>
														X
														</button>
												</td>
											</tr>
										)
									)
									: null}
							</tbody>
						</table>
						<div className="col"></div>
						<div className="col">
							<div className="text-center mt-3 mb-3">
								<button
									type="submit"
									onClick={this.onBack}
									className="client-roboto-condensed text-uppercase client-login m-2 px-5"
								>
									Back
								</button>
								<button
									type="submit"
									onClick={this.onNext}
									className="client-roboto-condensed text-uppercase client-login m-2 px-5"
								>
									Next
								</button>
							</div>
						</div>
						<div className="col"></div>
					</div>
				);
			}
		}
	}
}
const mapStateToProps = state => {
	return {
		loading: state.clientConfigurationReducer.loading,
		first_form: state.clientConfigurationReducer.first_form,
		second_form: state.clientConfigurationReducer.second_form,
		language: state.languageReducer.selectedLanguage,
		cost_price_visible: state.loginReducer.user.cost_price_visible,
		country_id: state.loginReducer.user.user.country_id,
		country: state.loginReducer.user.country
	};
};

export default connect(mapStateToProps, {
	save_second_form
})(SecondForm);
