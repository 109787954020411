import React, { Component } from "react";
import "./../css/styles.css";
import { NavLink, Link } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "./../login/action";
import logo from "./../CPQ/logo.png";
import banner from "./../CPQ/banner.png";
import { language_change } from "./../language/action";
import { Api } from "./../config/api";
import strings from "../config/language";
import config from "../config/config";
import { setToken } from "./../config/api";
import { Badge } from "antd";
import { BellOutlined } from "@ant-design/icons";

class SiteHeader extends Component {
	constructor(props) {
		super(props);
		this.state = {
			language: false,
			total_notifications: 0
		};
		this.logout = this.logout.bind(this);
		this.onLanguageChange = this.onLanguageChange.bind(this);
		this.updateNotification = this.updateNotification.bind(this);
	}

	async onLanguageChange(name, value) {
		let that = this;
		let params = {
			country: name,
			language: value
		};
		await this.props.language_change(params);
		that.forceUpdate();
	}

	logout(e) {
		e.preventDefault();
		this.props.logout(this.props.token);
	}

	async updateNotification() {
		let that = this;
		if (this.props.is_manager === 1) {
		  await Api.get(
			"discount-notification/getAllDiscountForManager/" + this.props.user_id
		  )
			.then(function(response) {
			  that.setState({ total_notifications: response.data.all_unreplied_requested_notification.length });  
			})
			.catch(function(error) {
			});
	  
		} else {
		  await Api.get(
			"discount-notification/getAllDiscount/" + this.props.user_id
		  )
			.then(function(response) {
			  that.setState({ total_notifications: response.data.length });
			})
			.catch(function(error) {
			});
		}
	  }

	async componentDidMount() {
		await setToken(this.props.token);
		let language = await Api.get("country")
			.then(function (response) {
				return response.data;
			})
			.catch(function (error) { });

		await this.updateNotification();

		this.setState({ language: language });
	}

	render() {
		return (
			<div>
				<div className="container">
					<nav className="navbar navbar-expand-lg ml-auto navbar-light">
						<NavLink className="navbar-brand" to="/">
							<img loading="lazy"
								alt="nextlevelvalue-logo"
								height="120px"
								className="logo"
								src={logo}
							/>
						</NavLink>
						{/* {this.props.language} */}
						<button
							className="navbar-toggler"
							type="button"
							data-toggle="collapse"
							data-target="#navbarSupportedContent"
							aria-controls="navbarSupportedContent"
							aria-expanded="false"
							aria-label="Toggle navigation"
						>
							<span className="navbar-toggler-icon"></span>
						</button>

						<div
							className="collapse navbar-collapse"
							id="navbarSupportedContent"
						>
							<ul className="navbar-nav ml-auto">
								<li className="nav-item">
									<NavLink
										activeClassName="red-link"
										className="nav-link"
										to="/home"
									>
										{strings.home}
									</NavLink>
								</li>
								<li className="nav-item">
									{
										this.props.is_manager ?
											<NavLink
												activeClassName="red-link"
												className="nav-link"
												to="/team-configurations"
											>
												{strings.configurations}
											</NavLink> :
											<NavLink
												activeClassName="red-link"
												className="nav-link"
												to="/configurations"
											>
												{strings.configurations}
											</NavLink>
									}
								</li>
								<li className="nav-item">
									{
										this.props.is_manager ?
											<NavLink
												activeClassName="red-link"
												className="nav-link"
												to="/team-quotes"
											>
												{strings.quotes}
											</NavLink> :
											<NavLink
												activeClassName="red-link"
												className="nav-link"
												to="/quotes"
											>
												{strings.quotes}
											</NavLink>

									}
								</li>
								<li className="nav-item">
									{
										this.props.is_manager ?
											<NavLink
												activeClassName="red-link"
												className="nav-link"
												to="/team-orders"
											>
												{strings.orders}
											</NavLink> :
											<NavLink
												activeClassName="red-link"
												className="nav-link"
												to="/orders"
											>
												{strings.orders}
											</NavLink>

									}
								</li>
								<li className="nav-item dropdown">
									<Link
										className="nav-link dropdown-toggle"
										to="#"
										id="navbarDropdown"
										role="button"
										data-toggle="dropdown"
										aria-haspopup="true"
										aria-expanded="false"
									>
										{this.props.user.name ? this.props.user.name : ""}
									</Link>
									<div
										className="dropdown-menu"
										aria-labelledby="navbarDropdown"
									>
										<Link className="dropdown-item" to="/profile">
											<i className="icon fa fa-user"></i>Profile
										</Link>
										<Link
											className="dropdown-item"
											to="/add-customer-feedback"
										>
											<i className="icon fa fa-commenting"></i>
											System Support
										</Link>
										<Link
											className="dropdown-item"
											to="/notifications"
										>
											<i className="icon fas fa-bell"></i>
											Discount Requests
										</Link>
										{/* {this.props.is_support ? (
											<Link
												className="dropdown-item"
												to="/dashboard"
											>
												<i className="icon fa fa-chart-area"></i>
												Dashboard
											</Link>
										) : null} */}
										<Link
											to="#"
											className="dropdown-item"
											onClick={this.logout}
										>
											<i className="icon fas fa-sign-out-alt"></i>
											Logout
										</Link>
									</div>
								</li>
								<li className="nav-item">
									{this.state.language
										? this.state.language.map((item, i) =>
											this.props.user.country_id === item.id ? (
												<NavLink
													className="nav-link"
													style={{
														color: "rgba(0,0,0,.5)"
													}}
													to="#"
													key={i}
												>
													<img loading="lazy"
														alt="country-flag"
														height="24px"
														width="24px"
														src={
															config.APP_URL +
															"/api/storage/" +
															item.country_flag_image
														}
														className="mr-2"
													/>
													{item.country_name}
												</NavLink>
											) : null
										)
										: null}
								</li>
								<li className="nav-item mt-1">
									<Link to="/notifications">
										<Badge
											overflowCount={9}
											count={this.state.total_notifications}
										>
											<BellOutlined
												style={{
													fontSize: 20,
													color: "rgba(0,0,0,.5)",
													fontWeight: 900
												}}
											/>
										</Badge>
									</Link>
								</li>
							</ul>
						</div>
					</nav>
				</div>
				<div className="header-seperator"></div>
				<div className="cotainer-fluid" id="banner">
					{this.props.user_banner_image &&
						this.props.user_banner_image !== null ? (
							<img loading="lazy"
								alt="nextlevelvalue-banner"
								width="100%"
								height="100%"
								src={
									config.APP_URL +
									"/api/storage/" +
									this.props.user_banner_image
								}
							/>
						) : (
							<img loading="lazy"
								alt="nextlevelvalue-banner"
								width="100%"
								height="100%"
								src={banner}
							/>
						)}
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		token: state.loginReducer.token,
		user: state.loginReducer.user.user,
		userRole: state.loginReducer.user.role,
		language: state.languageReducer.selectedLanguage,
		country: state.languageReducer.selectedCountry,
		is_support: state.loginReducer.user.is_support,
		user_id: state.loginReducer.user.user.id,
		user_banner_image: state.loginReducer.user.user_banner_image,
		is_manager: state.loginReducer.user.is_manager
	};
};

export default connect(mapStateToProps, {
	language_change,
	logout
})(SiteHeader);
