import { Api } from "./../../config/api";

function showCurrencies() {
  let data = Api.get("currency")
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {});

  if (data) {
    return data;
  } else {
    return false;
  }
}

function showSingleCurrency(params) {
  let data = Api.get("currency/" + params)
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {});

  if (data) {
    return data;
  } else {
    return false;
  }
}

function addCurrency(params) {
  let data = Api.post("currency", params)
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {});

  if (data) {
    return true;
  } else {
    return false;
  }
}

function editCurrency(params) {
  let data = Api.post("currency/edit/" + params.get("id"), params)
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {});

  if (data) {
    return true;
  } else {
    return false;
  }
}

function deleteCurrency(params) {
  let data = Api.get("currency/delete/" + params)
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {});

  if (data) {
    return data;
  } else {
    return false;
  }
}

export const CurrencyService = {
  showCurrencies,
  showSingleCurrency,
  addCurrency,
  editCurrency,
  deleteCurrency
};
