import { combineReducers } from "redux";
import loginActionTypes from "./../login/actionTypes";
import brandReducer from "./../admin/brand/reducer";
import yearReducer from "./../admin/year/reducer";
import countryReducer from "./../admin/country/reducer";
import quoteReducer from "./../admin/quote/reducer";
import showMachinePartReducer from "./../admin/machine_parts/reducer";
import showMachineCategoryReducer from "./../admin/machine_category/reducer";
import compatibilityReducer from "./../admin/compatibility/reducer";
import configurationReducer from "./../admin/configured_machines/reducer";
import loginReducer from "./../login/reducer";
import languageReducer from "./../language/reducer";
import orderReducer from "./../admin/order/reducer";
import companyRateReducer from "./../admin/company_rates/reducer";
import spreadsheetReducer from "./../admin/spreadsheet/reducer";
import specificationReducer from "./../admin/specifications/reducer";
import featureReducer from "./../admin/featureset/reducer";
import keyModelFeatureReducer from "./../admin/keymodelfeature/reducer";
import sequenceReducer from "./../admin/sequence/reducer";
import noteAndRemarkReducer from "./../admin/note_and_remark/reducer";
import roleManagementReducer from "./../admin/role/reducer";

import clientOrderReducer from "./../client/order/reducer";
import clientProfileReducer from "./../client/profile/reducer";
import clientQuoteReducer from "./../client/quotes/reducer";
import clientConfigurationReducer from "./../client/configuration/reducer";

const appReducer = combineReducers({

    // Admin Reducers 
    
    //brand reducer
    brandReducer: brandReducer,

    // reducer from year component
    yearReducer: yearReducer,

    // reducer from country component
    countryReducer: countryReducer,

    // reducer from quote component
    quoteReducer: quoteReducer,

    // reducer from machine_part component
    showMachinePartReducer: showMachinePartReducer,

    // reducer from machine_category component
    showMachineCategoryReducer: showMachineCategoryReducer,

    // reducer from compatibility component
    compatibilityReducer: compatibilityReducer,

    // reducer from configuration component
    configurationReducer: configurationReducer,

    // reducer from language component
    languageReducer: languageReducer,

    // reducer from login component
    loginReducer: loginReducer,

    // reducer from spreadsheet component
    spreadsheetReducer: spreadsheetReducer,

    // reducer from order component
    orderReducer: orderReducer,

    // reducer from company_rate component
    companyRateReducer: companyRateReducer,
    //Admin Reducers End

    specificationReducer: specificationReducer,

    featureReducer: featureReducer,
	keyModelFeatureReducer: keyModelFeatureReducer,

    sequenceReducer: sequenceReducer,

    noteAndRemarkReducer: noteAndRemarkReducer,

    roleManagementReducer: roleManagementReducer,


    //Client Reducer Start

    clientOrderReducer: clientOrderReducer,
    clientQuoteReducer: clientQuoteReducer,
    clientProfileReducer: clientProfileReducer,
	clientConfigurationReducer: clientConfigurationReducer
    //Client Reducer End
});

const rootReducer = (state, action) => {
    if (action.type === loginActionTypes.LOGOUT_SUCCESS) {
		state = undefined;
    }
	return appReducer(state, action);
};

export default rootReducer;
