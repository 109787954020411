import { Api } from "./../../config/api";

async function getAllRoles() {
  let data = Api.get("user-role").then(function(response) {
    return response.data;
  });

  if (data) {
    return data;
  } else {
    return false;
  }
}

async function getCurrentRoles(id){
  let data = Api.get("user-role/"+id).then(function(response) {
    return response.data;
  });

  if (data) {
    return data;
  } else {
    return false;
  }
}

async function getTemplatesForRole(role_id){
  let data = Api.get("template/getTemplatesForRole/"+role_id).then(function(response) {
    return response.data;
  });

  if (data) {
    return data;
  } else {
    return false;
  }
}

async function getCurrentTemplateForRole(role_id){
  let data = Api.get("template/getCurrentTemplatesForRole/"+role_id).then(function(response) {
    return response.data;
  });

  if (data) {
    return data;
  } else {
    return false;
  }
}

async function updateTemplateForRole(role_id, params){
  let data = Api.post("template/updateTemplatesForRole/"+role_id, params).then(function(response) {
    return response.data;
  });

  if (data) {
    return data;
  } else {
    return false;
  }
}

async function getCategoriesForRole(role_id){
  let data = Api.get("machine-category/getMachineCategoriesForRole/"+role_id).then(function(response) {
    return response.data;
  });

  if (data) {
    return data;
  } else {
    return false;
  }
}

async function getCurrentCategoriesForRole(role_id){
  let data = Api.get("machine-category/getCurrentMachineCategoriesForRole/"+role_id).then(function(response) {
    return response.data;
  });

  if (data) {
    return data;
  } else {
    return false;
  }
}


async function updateCategoriesForRole(role_id, params){
  let data = Api.post("machine-category/updateMachineCategoriesForRole/"+ role_id , params).then(function(response) {
    return response.data;
  });

  if (data) {
    return data;
  } else {
    return false;
  }
}

async function getCurrentCostPriceVisibility(role_id){
  let data = Api.get("/getCurrentCostPriceVisibility/"+ role_id).then(function(response) {
    return response.data;
  });

  if (data) {
    return data;
  } else {
    return false;
  }
}

async function updateCostPriceVisibilityForRole(role_id, params){
  let data = Api.post("/updateCostPriceVisibilityForRole/"+ role_id , params).then(function(response) {
    return response.data;
  });

  if (data) {
    return data;
  } else {
    return false;
  }
}


export const RoleManagementService = {
  getAllRoles,
  getCurrentRoles,
  getTemplatesForRole,
  getCategoriesForRole,
  getCurrentTemplateForRole,
  getCurrentCategoriesForRole,
  updateTemplateForRole,
  updateCategoriesForRole,
  getCurrentCostPriceVisibility,
  updateCostPriceVisibilityForRole
};
