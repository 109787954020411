import React, { Component } from "react";
import { connect } from "react-redux";
import { css } from "@emotion/react";
import { PulseLoader } from "react-spinners";
import { Api } from "../../config/api";
import { Table } from "antd";
import {
	CheckCircleOutlined,
	CloseCircleOutlined,
	LeftOutlined
} from "@ant-design/icons";
import { Link } from "react-router-dom";

class NotificationLogs extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activeIndex: 0,
			requestedNotificationsLogs: false,
			repliedNotificationsLogs: false,
			managerNotificationLogs: false
		};
	}

	async componentDidMount() {
		let that = this;
		await Api.get("discount-notification/getOldLogs/" + this.props.user_id)
			.then(function (response) {
				that.setState({
					repliedNotificationsLogs: response.data.replied_notification_log
				});
				that.setState({
					requestedNotificationsLogs:
					response.data.requested_notification_log
				});
			})
			.catch(function (error) { });


		await Api.get(
			"discount-notification/getAllDiscountForManager/" + this.props.user_id
		)
			.then(function (response) {
				that.setState({ managerNotificationLogs: response.data.all_replied_notification_user_name });
			})
			.catch(function (error) { });

	}

	render() {
		const managerColumns = [
			{
				title: "Requested By",
				dataIndex: "name",
				width: 150,
				fixed: "left"
			},
			{
				title: "Requested Discount %",
				dataIndex: "requested_discount_percentage"
			},
			{
				title: "Approved Discount %",
				dataIndex: "provided_discount"
			},
			{
				title: "Configuration Name",
				dataIndex: "configuration_name",
				width: 200
			},
			{
				title: "Reply Comment",
				dataIndex: "comment",
				width: 200
			},
			{
				title: "Requested At",
				dataIndex: "requested_at",
				width: 200
			},
			{
				title: "Replied By",
				dataIndex: "replied_by_name",
				width: 200
			},
			{
				title: "Replied At",
				dataIndex: "published_at",
				width: 200
			},
			{
				title: "Configuration Link",
				dataIndex: "requested_configuration_id",
				render: text => (
					<Link className="red-link" to={"/configuration/" + text}>
						See Configuration
					</Link>
				),
				width: 150,
				fixed: "right"
			}
		];
		const columns = [
			{
				title: "Requested By",
				dataIndex: "name",
				width: 150,
				fixed: "left"
			},
			{
				title: "Requested Discount %",
				dataIndex: "requested_discount_percentage"
			},
			{
				title: "Approved Discount %",
				dataIndex: "provided_discount"
			},
			{
				title: "Configuration Name",
				dataIndex: "configuration_name",
				width: 200
			},
			{
				title: "Reply Comment",
				dataIndex: "comment",
				width: 200
			},
			{
				title: "Requested At",
				dataIndex: "requested_at",
				width: 200
			},
			{
				title: "Replied At",
				dataIndex: "published_at",
				width: 200
			},
			{
				title: "Configuration Link",
				dataIndex: "requested_configuration_id",
				render: text => (
					<Link className="red-link" to={"/configuration/" + text}>
						See Configuration
					</Link>
				),
				width: 150,
				fixed: "right"
			}
		];
		const columns1 = [
			{
				title: "Replied By",
				dataIndex: "name",
				width: 150,
				fixed: "left"
			},
			{
				title: "Reply Status",
				dataIndex: "is_approved",
				render: text =>
					text ? (
						<CheckCircleOutlined
							style={{
								fontSize: 26,
								color: "#4CAF50"
							}}
						/>
					) : (
						<CloseCircleOutlined
							style={{
								fontSize: 26,
								color: "#F44336"
							}}
						/>
					),
				width: 100,
				fixed: "left"
			},
			{
				title: "Requested Discount %",
				dataIndex: "requested_discount_percentage"
			},
			{
				title: "Approved Discount %",
				dataIndex: "provided_discount"
			},
			{
				title: "Configuration Name",
				dataIndex: "configuration_name",
				width: 200
			},
			{
				title: "Reply Comment",
				dataIndex: "comment",
				width: 200
			},
			{
				title: "Requested At",
				dataIndex: "requested_at",
				width: 200
			},
			{
				title: "Replied At",
				dataIndex: "published_at",
				width: 200
			},
			{
				title: "Configuration Link",
				dataIndex: "requested_configuration_id",
				render: text => (
					<Link className="red-link" to={"/configuration/" + text}>
						See Configuration
					</Link>
				),
				width: 150,
				fixed: "right"
			}
		];
		const override = css`
			display: block;
			margin: 0 auto;
			border-color: red;
		`;
		if (this.props.loginLoading) {
			return (
				<div className="sweet-loading">
					<div className="container loading-container">
						<div className="row m-5 p-5">
							<PulseLoader
								css={override}
								sizeUnit={"px"}
								size={24}
								color={"#b9373d"}
							/>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div className="my-4 client-roboto-condensed container">
					<div className="row my-2">
						<div className="col-2 align-left">
							<Link className="red-link" to="/notifications">
								<LeftOutlined />
								<span
									class="anticon anticon-left"
								>
									Back
								</span>
							</Link>
						</div>
						<div className="col-8 text-center">
							<h4>Discount History</h4>
						</div>
						<div className="col-2"></div>
					</div>
					<div className="row">
						{
							this.props.is_manager ?
								<div className=" my-4 col-12">
									{/* <h5 className="my-4 text-center">Replied Requests [System]</h5> */}
									{this.state.repliedNotificationsLogs ? (
										<Table
											columns={managerColumns}
											dataSource={this.state.managerNotificationLogs}
											scroll={{ x: 1500, y: 700 }}
										/>
									) : null}
								</div> : null}
						{
							!this.props.is_manager ?
								this.state.repliedNotificationsLogs &&
								this.state.repliedNotificationsLogs.length !== 0 ? (
									<div className=" my-4 col-12">
										<h5 className="my-4 text-center">Received Requests</h5>
										{this.state.repliedNotificationsLogs ? (
											<Table
												columns={columns}
												dataSource={this.state.repliedNotificationsLogs}
												scroll={{ x: 1500, y: 300 }}
											/>
										) : null}
									</div>
								) : null : null}
						{
							!this.props.is_manager ?
								this.state.requestedNotificationsLogs &&
								this.state.requestedNotificationsLogs.length !== 0 ? (
									<div className="my-4 col-12">
										<h5 className="my-4 text-center">My Requests</h5>
										{this.state.requestedNotificationsLogs ? (
											<Table
												columns={columns1}
												dataSource={this.state.requestedNotificationsLogs}
												scroll={{ x: 1500, y: 300 }}
											/>
										) : null}
									</div>
								) : null
								: null}
					</div>
				</div>
			);
		}
	}
}
const mapStateToProps = state => {
	return {
		isAuthenticated: state.loginReducer.isAuthenticated,
		userRole: state.loginReducer.user.role,
		name: state.loginReducer.user.user.name,
		loginLoading: state.loginReducer.loading,
		user_id: state.loginReducer.user.user.id,
		role_id: state.loginReducer.user.role_id,
		token: state.loginReducer.token,
		language: state.languageReducer.selectedLanguage,
		is_manager: state.loginReducer.user.is_manager
	};
};

export default connect(mapStateToProps, {})(NotificationLogs);
