import LocalizedStrings from "react-localization";

let strings = new LocalizedStrings({
  en: {
    currency: 163,
    hello: "Hello",
    configurations: "Configurations",
    quotes: "Quotes",
    orders: "Orders",
    home: "Home"
  },
  du: {
    currency: 163,
    hello: "Hallo",
    configurations: "Configuraties",
    quotes: "Citaten",
    orders: "Bestellingen",
    home: "Huis"
  },
  ge: {
    currency: 163,
    hello: "Hallo",
    configurations: "Konfigurationen",
    quotes: "Zitate",
    orders: "Aufträge",
    home: "Zuhause"
  }
});

export default strings;
