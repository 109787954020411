import React, { Component } from "react";
import { connect } from "react-redux";
import { ClientQuoteService } from "./../service";
import { Link } from "react-router-dom";
import { clean_quote_message, order_quote } from "./../action";
import { css } from "@emotion/react";
import { PulseLoader } from "react-spinners";
import { Api } from "./../../../config/api";
import Swal from "sweetalert2";
import { Calendar } from "primereact/calendar";
import { GenericQuestionService } from "./../../../admin/generic_questions/service";
import { Modal } from "antd";
import getSymbolFromCurrency from "currency-symbol-map";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

class ChildrenQuotes extends Component {
	constructor(props) {
		super(props);
		this.state = {
			quotes: false,
			search_quotes: false,
			search: "",
			total_shown_configuration: 8,
			status: "pending",
			unique_status: [],
			generic_reasons: [],
			visible: false,
			quote_id: false,
			custom_comment: "",
			generic_id: false,
			loading: true,
			user: "",
			unique_user: []
		};
		this.onSearchChange = this.onSearchChange.bind(this);
		this.formatDate = this.formatDate.bind(this);
		this.handleSelectChange = this.handleSelectChange.bind(this);
		this.handleOrderOpportunityEditable = this.handleOrderOpportunityEditable.bind(
			this
		);
		this.handleExpectedDeliveryDateEditable = this.handleExpectedDeliveryDateEditable.bind(
			this
		);
		this.handleOrderOpportunityChange = this.handleOrderOpportunityChange.bind(
			this
		);
		this.handleOrderOpportunitySubmit = this.handleOrderOpportunitySubmit.bind(
			this
		);
		this.handleExpectedDeliveryDateChange = this.handleExpectedDeliveryDateChange.bind(
			this
		);
		this.handleExpectedDeliveryDateSubmit = this.handleExpectedDeliveryDateSubmit.bind(
			this
		);
		this.handleStatusEditable = this.handleStatusEditable.bind(this);
		this.handleStatusChange = this.handleStatusChange.bind(this);
		this.handleStatusSubmit = this.handleStatusSubmit.bind(this);
		this.showModal = this.showModal.bind(this);
		this.handleOk = this.handleOk.bind(this);
		this.handleCancel = this.handleCancel.bind(this);
	}

	handleTextChange(name, value) {
		switch (name) {
			case "generic_id":
				this.setState({ generic_id: value });
				break;

			case "custom_comment":
				this.setState({ custom_comment: value });
				break;

			default:
				break;
		}
	}

	showModal = () => {
		this.setState({
			visible: true
		});
	};

	handleOk = (e, id) => {
		this.setState({
			visible: false
		});
	};

	handleCancel = e => {
		this.setState({ generic_id: false });
		this.setState({ custom_comment: "" });
		this.setState({
			visible: false
		});
	};

	async handleStatusEditable(quote_id) {
		let quote = this.state.quotes;

		this.setState({ quote_id: quote_id });
		this.setState({ generic_id: false });
		this.setState({ custom_comment: "" });

		quote.forEach(element => {
			if (element.id === quote_id) {
				element.status_editable = true;
			}
		});


		this.setState({ quotes: quote });
	}

	async handleStatusChange(i, value) {
		let quote = this.state.quotes;

		if (!value.toLowerCase().includes("order")) {
			this.showModal();
		}

		if (!value || value === undefined || value === "" || value === null) {
			value = "pending";
		}

		quote.forEach(element => {
			if (element.id === i) {
				element.status = value;
			}
		});

		this.setState({ quotes: quote });
	}

	async handleStatusSubmit(i, user_id, id) {
		let quote = this.state.quotes;
		let that = this;

		let value = 0;
		quote.forEach(element => {
			if (element.id === i) {
				value = element.status;
			}
		});

		let params = {
			status: value,
			generic_id: this.state.generic_id,
			custom_comment: this.state.custom_comment
		};

		if (value.toLowerCase().includes("order")) {
			let result = await Swal.fire({
				title: "Are you sure?",
				text: "Do you want to order this?",
				icon: "info",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				cancelButtonColor: "#d33",
				confirmButtonText: "Yes, order it!"
			}).then(result => {
				return result;
			});

			if (result.value) {
				let params = {
					order_name: quote[0].quotation_name + "-order",
					configuration_id: quote[0].configuration_id,
					quote_id: quote[0].id
				};
				await that.props.order_quote(
					quote[0].user_id,
					params
				);
				// if (this.props.is_support) {
				// 	await Api.post(
				// 		"quote/changeStatusOfQuote/" + user_id + "/" + id,
				// 		params
				// 	)
				// 		.then(function (response) {
				// 			Swal.fire({
				// 				title: "Info!",
				// 				text: response.data.message,
				// 				icon: "success",
				// 				confirmButtonText: "Ok"
				// 			});
				// 		})
				// 		.catch(function (error) { });
				// } else {
				// 	await Api.post(
				// 		"quote/changeStatusOfQuote/" + this.props.user_id + "/" + id,
				// 		params
				// 	)
				// 		.then(function (response) {
				// 			Swal.fire({
				// 				title: "Info!",
				// 				text: response.data.message,
				// 				icon: "success",
				// 				confirmButtonText: "Ok"
				// 			});
				// 		})
				// 		.catch(function (error) { });
				// }

				quote.forEach(element => {
					if (element.id === i) {
						element.status_editable = false;
					}
				});

				this.setState({ quotes: quote });

				await this.handleSelectChange(this.state.status, this.state.search);
			}
		} else {
			if (this.props.is_support) {
				await Api.post(
					"quote/changeStatusOfQuote/" + user_id + "/" + id,
					params
				)
					.then(function (response) {
						Swal.fire({
							title: "Info!",
							text: response.data.message,
							icon: "success",
							confirmButtonText: "Ok"
						});
					})
					.catch(function (error) { });
			} else {
				await Api.post(
					"quote/changeStatusOfQuote/" + this.props.user_id + "/" + id,
					params
				)
					.then(function (response) {
						Swal.fire({
							title: "Info!",
							text: response.data.message,
							icon: "success",
							confirmButtonText: "Ok"
						});
					})
					.catch(function (error) { });
			}

			quote.forEach(element => {
				if (element.id === i) {
					element.status_editable = false;
				}
			});

			this.setState({ quotes: quote });

			await this.handleSelectChange(this.state.status, this.state.search);
		}
	}

	async handleExpectedDeliveryDateSubmit(i, id) {
		let quote = this.state.quotes;
		let value = 0;
		quote.forEach(element => {
			if (element.id === i) {
				if(element.expected_delivery_date === null){
					element.expected_delivery_date = new Date();
				}
				if(typeof element.expected_delivery_date === 'string'){
					element.expected_delivery_date = new Date(element.expected_delivery_date);
				}
				let month = parseInt(element.expected_delivery_date.getMonth()) + 1;
				value =
					element.expected_delivery_date.getFullYear() +
					"-" +
					month +
					"-" +
					element.expected_delivery_date.getDate();
			}
		});

		let params = {
			expected_delivery_date: value
		};

		await Api.post("quote/editExpectedDeliveryDate/" + id, params)
			.then(function (response) {
				Swal.fire({
					title: "Info!",
					text: response.data.message,
					icon: "success",
					confirmButtonText: "Ok"
				});
			})
			.catch(function (error) { });

		quote.forEach(element => {
			if (element.id === i) {
				element.extected_delivery_date_editable = false;
			}
		});

		this.setState({ quotes: quote });
	}

	async handleOrderOpportunitySubmit(i, id) {
		let quote = this.state.quotes;

		let value = 0;
		quote.forEach(element => {
			if (element.id === i) {
				value = element.order_opportunity;
			}
		});

		let params = {
			order_opportunity: value
		};

		await Api.post("quote/editOrderOpportunity/" + id, params)
			.then(function (response) {
				Swal.fire({
					title: "Info!",
					text: response.data.message,
					icon: "success",
					confirmButtonText: "Ok"
				});
			})
			.catch(function (error) { });

		quote.forEach(element => {
			if (element.id === i) {
				element.order_opportunity_editable = false;
			}
		});

		this.setState({ quotes: quote });
	}

	handleExpectedDeliveryDateChange(i, value) {
		let quote = this.state.quotes;
		let date = value;

		quote.forEach(element => {
			if (element.id === i) {
				element.expected_delivery_date = date;
			}
		});

		this.setState({ quotes: quote });
	}

	handleOrderOpportunityChange(i, value) {
		let quote = this.state.quotes;

		if (!value || value === undefined || value === "" || value === null) {
			value = 0;
		}

		if (!isNaN(value)) {
			if (value <= 100) {
				quote.forEach(element => {
					if (element.id === i) {
						element.order_opportunity = value;
					}
				});
				this.setState({ quotes: quote });
			}
		}
	}

	handleExpectedDeliveryDateEditable(i) {
		let quote = this.state.quotes;

		quote.forEach(element => {
			if (element.id === i) {
				element.extected_delivery_date_editable = true;
			}
		});

		this.setState({ quotes: quote });
	}

	handleOrderOpportunityEditable(i) {
		let quote = this.state.quotes;

		quote.forEach(element => {
			if (element.id === i) {
				element.order_opportunity_editable = true;
			}
		});

		this.setState({ quotes: quote });
	}

	handleSelectChange(value, search, user) {
		let redirect = this;
		redirect.setState({ status: value });

		let parts = redirect.state.quotes;

		if (this.props.is_support) {
			parts = parts.filter(
				item =>
					(item.quotation_name.toLowerCase().includes(search) ||
						item.quotation_for.toLowerCase().includes(search) ||
						item.name.toLowerCase().includes(search)) &&
					item.status.toLowerCase().includes(value) &&
					item.user_name.toLowerCase().includes(user)
			);
		} else {
			parts = parts.filter(
				item =>
					(item.quotation_name.toLowerCase().includes(search) ||
						item.quotation_for.toLowerCase().includes(search)) &&
					item.status.toLowerCase().includes(value) &&
					item.user_name.toLowerCase().includes(user)
			);
		}

		redirect.setState({ search_quotes: parts });
		if (parts.length === 0) {
		}
	}

	formatDate(date) {
		if (date !== null && date !== "" && date !== undefined) {
			let formatted_date = new Date(date);
			let newMonth = parseInt(formatted_date.getMonth()) + 1;

			return (
				formatted_date.getFullYear() +
				"-" +
				newMonth +
				"-" +
				formatted_date.getDate()
			);
		}
	}

	async handleSelectUserChange(value, search, status) {
		let redirect = this;
		redirect.setState({ user: value });

		let parts = redirect.state.quotes;

		let parts1 = parts.filter(
			item =>
				item.user_name.toLowerCase().includes(value) &&
				item.quotation_name.toLowerCase().includes(search.toLowerCase())
		);

		parts = parts.filter(
			item =>
				item.user_name.toLowerCase().includes(value) &&
				item.quotation_name.toLowerCase().includes(search.toLowerCase()) &&
				item.status.toLowerCase().includes(status)
		);

		let x = false;
		let search_status = [];
		for (x of parts1) {
			await search_status.push(x.status);
		}
		let unique_status = await [...new Set(search_status)];

		await redirect.setState({ unique_status: unique_status });

		redirect.setState({ search_quotes: parts });
	}

	async componentDidMount() {
		let redirect = this;
		let quotes = [];

		let generic_reasons = await GenericQuestionService.showAllGenericQuestions();

		redirect.setState({ generic_reasons: generic_reasons });

		quotes = await ClientQuoteService.showChildrenQuotes(this.props.user_id);

		let edited_quote = null;

		for (edited_quote of quotes) {
			if (
				edited_quote.order_opportunity === null ||
				edited_quote.order_opportunity === ""
			) {
				edited_quote.order_opportunity = 10;
			}
			edited_quote.order_opportunity_editable = false;
			edited_quote.extected_delivery_date_editable = false;
			edited_quote.status_editable = false;
		}

		let x = false;
		let status = [];
		for (x of quotes) {
			if (!x.status.toLowerCase().includes("order")) {
				await status.push(x.status);
			}
		}
		let unique_status = await [...new Set(status)];

		await redirect.setState({ unique_status: unique_status });

		quotes = quotes.filter(
			item => !item.status.toLowerCase().includes("order")
		);

		redirect.setState({ quotes: quotes });
		redirect.setState({ search_quotes: quotes });

		let y = false;
		let users = [];
		for (y of quotes) {
			await users.push(y.user_name);
		}
		let unique_user = await [...new Set(users)];

		await redirect.setState({ unique_user: unique_user });
		if (quotes === undefined) {
		} else {
		}

		await this.props.clean_quote_message();

		await this.handleSelectChange(
			this.state.status,
			this.state.search,
			this.state.user
		);
		await this.setState({ loading: false });
	}

	onSearchBrandChange(value) {
		let redirect = this;
		redirect.setState({ searchBrand: value });
		let parts = redirect.state.quotes;
		parts = parts.filter(
			item =>
				(item.configurations && item.configurations.length > 0 && item.configurations[0].brand_name &&
					item.configurations[0].brand_name.toLowerCase().includes(value.toLowerCase())) &&
				item.status.toLowerCase().includes(this.state.status)
		);
		redirect.setState({ search_quotes: parts });
	}

	onSearchChange(value) {
		let redirect = this;
		redirect.setState({ search: value });

		let parts = redirect.state.quotes;
		if (this.props.is_support) {
			parts = parts.filter(
				item =>
					item.quotation_name
						.toLowerCase()
						.includes(value.toLowerCase()) ||
					item.quotation_for.toLowerCase().includes(value.toLowerCase()) ||
					item.name.toLowerCase().includes(value.toLowerCase()) ||
					item.user_name.toLowerCase().includes(value.toLowerCase())
			);
		} else {
			parts = parts.filter(
				item =>
					item.quotation_name
						.toLowerCase()
						.includes(value.toLowerCase()) ||
					item.quotation_for.toLowerCase().includes(value.toLowerCase()) ||
					item.user_name.toLowerCase().includes(value.toLowerCase())
			);
		}

		redirect.setState({ search_quotes: parts });
		if (parts.length === 0) {
		}
	}

	render() {
		const override = css`
			display: block;
			margin: 0 auto;
			border-color: red;
		`;
		if (
			!this.state.search_quotes ||
			this.props.loginLoading ||
			this.props.loading ||
			this.state.loading
		) {
			return (
				<div className="sweet-loading">
					<div className="container loading-container">
						<div className="row m-5 p-5">
							<PulseLoader
								css={override}
								sizeUnit={"px"}
								size={24}
								color={"#B9373D"}
							/>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div>
					<div className="mt-4 mb-4 client-roboto-condensed container">
						<div className="text-center">
							<h3>Team Quotes</h3>
							<Modal
								title="Status Change Reason"
								visible={this.state.visible}
								onOk={this.handleOk}
								onCancel={this.handleCancel}
							>
								<h6>
									Why do you want to change the status of this
									quotation? {this.state.quote_id}
								</h6>
								<div className="row">
									<div className="col">
										<div className="form-group">
											<label
												htmlFor="brand_name"
												className="control-label mb-1"
											>
												Generic Reason
											</label>
											<select
												onChange={e =>
													this.handleTextChange(
														"generic_id",
														e.target.value
													)
												}
												value={
													this.state.generic_id
														? this.state.generic_id
														: null
												}
												className="custom-select"
											>
												<option>Select a generic reason</option>
												{this.state.generic_reasons.map(
													(reason, i) => (
														<option key={i} value={reason.generic_id}>
															{reason.generic_question}
														</option>
													)
												)}
											</select>
										</div>
										<div className="form-group">
											<label
												htmlFor="brand_name"
												className="control-label mb-1"
											>
												Custom Comment
											</label>
											<input
												id="brand_name"
												name="brand_name"
												type="text"
												className="form-control"
												aria-required="true"
												onChange={e =>
													this.handleTextChange(
														"custom_comment",
														e.target.value
													)
												}
												value={this.state.custom_comment}
											/>
										</div>
									</div>
								</div>
							</Modal>
						</div>
						<div className="row mt-3">
							<div className="col-sm"></div>
							<div className="col-sm-12 client-cards-list">
								{/* <div className="my-2">
									<Link
										to={"/create-quote"}
										className="btn client-login"
										style={{
											width: 200
										}}
									>
										Create Quote
									</Link>
								</div> */}
								<div
									style={{
										width: 200,
									}}
									className="my-2"
								>
									<Link
										to={"/quotes"}
										className="btn client-login"
										style={{
											width: 170,
										}}
									>
										My Quotes
								</Link></div>
								<div
									className="my-2"
									style={{
										width: 200
									}}
								>
									<select
										value={this.state.status}
										onChange={e =>
											this.handleSelectChange(
												e.target.value,
												this.state.search,
												this.state.user
											)
										}
										className="custom-select"
									>
										<option value="">Select Status</option>
										{this.state.unique_status.map((status, i) => (
											<option key={i} value={status.toLowerCase()}>
												{status}
											</option>
										))}
									</select>
								</div>
								<div
									className="my-2"
									style={{
										width: 200
									}}
								>
									<select
										value={this.state.user}
										onChange={e =>
											this.handleSelectUserChange(
												e.target.value,
												this.state.search,
												this.state.status
											)
										}
										className="custom-select"
									>
										<option value="">Select User</option>
										{this.state.unique_user.map((status, i) => (
											<option key={i} value={status.toLowerCase()}>
												{status}
											</option>
										))}
									</select>
								</div>
								<div
									className="my-2"
									style={{
										width: 200
									}}
								>
									<div className="form-group">
										<input
											className="form-control"
											type="text"
											onChange={e =>
												this.onSearchChange(e.target.value)
											}
											value={this.state.search}
											placeholder="Search Quotes"
										/>
									</div>
								</div>
								{ parseInt(this.props.show_brand) === 1 && ( 
								<div
									className="my-2"
									style={{
										width: 170
									}}
								>
									<div className="form-group">
										<input
											className="form-control"
											type="text"
											onChange={e =>
												this.onSearchBrandChange(e.target.value)
											}
											value={this.state.searchBrand}
											placeholder="Search Brand"
										/>
									</div>
								</div>
								)
								}
							</div>
							<div className="col-sm"></div>
						</div>
						<div className="row">
							<div className="col-sm"></div>
							<div className="col-sm-12">
								{
									this.props.is_manager ?

										<div className="table-responsive" style={{'overflow-x': 'unset'}}>
											<table className="table">
												<thead>
													<tr>
														<th className="five-percent" scope="col">#</th>
														<th scope="col">Quote Name</th>
														<th>Quote For</th>
														<th>Created At</th>
														{this.props.is_support ? (
															<th>Created By</th>
														) : null}
														<th>Order Opportunity</th>
														<th>E. Delivery Date</th>
														<th>Status</th>
														{parseInt(this.props.show_brand) === 1 &&
														<th>Brand</th>
														}
													</tr>
												</thead>
												<tbody>
													{this.state.search_quotes
														? this.state.search_quotes.map((quote, i) =>
															i <
																this.state.total_shown_configuration ? (
																<>
																	<tr key={i} style={{
																		borderBottom: 'solid 0.5px #000000'
																	}} className="accordion-toggle collapsed" id={"accordion" + (i + 1)} data-toggle="collapse" data-parent={"#accordion" + (i + 1)} href={"#collapse" + (i + 1)}>
																		<td className="expand-button"></td>
																		<td>
																			<Link to={"/quote/" + quote.id}>
																				{quote.quotation_name}
																			</Link>
																		</td>
																		<td>{quote.quotation_for}</td>
																		<td>
																			{this.formatDate(
																				quote.created_at.replace(
																					/-/g,
																					"/"
																				)
																			)}
																		</td>
																		{this.props.is_support ? (
																			<td>{quote.user_name}</td>
																		) : null}
																		<td>
																			{!quote.order_opportunity_editable ? (
																				<>
																					{quote.order_opportunity !==
																						null &&
																						quote.order_opportunity !==
																						"" &&
																						quote.order_opportunity !==
																						undefined
																						? quote.order_opportunity +
																						"%"
																						: null}
																					<i
																						onClick={() =>
																							this.handleOrderOpportunityEditable(
																								quote.id
																							)
																						}
																						className="ml-2 fas fa-edit"
																					></i>
																				</>
																			) : (
																				<>
																					<select
																						onChange={e =>
																							this.handleOrderOpportunityChange(
																								quote.id,
																								e.target.value
																							)
																						}
																						className="half custom-select"
																						value={
																							quote.order_opportunity
																						}
																					>
																						<option value={0}>
																							0 %
																			</option>
																						<option value={10}>
																							10 %
																			</option>
																						<option value={30}>
																							30 %
																			</option>
																						<option value={70}>
																							70 %
																			</option>
																						<option value={90}>
																							90 %
																			</option>
																					</select>
																					<i
																						onClick={() =>
																							this.handleOrderOpportunitySubmit(
																								quote.id,
																								quote.id
																							)
																						}
																						className="ml-2 fas fa-check"
																					></i>
																				</>
																			)}
																		</td>
																		<td>
																			{!quote.extected_delivery_date_editable ? (
																				<>
																					{this.formatDate(
																						quote.expected_delivery_date
																					)}
																					<i
																						onClick={() =>
																							this.handleExpectedDeliveryDateEditable(
																								quote.id
																							)
																						}
																						className="ml-2 fas fa-edit"
																					></i>
																				</>
																			) : (
																				<>
																					<Calendar
																						dateFormat="yy-mm-dd"
																						style={{
																							width: 125
																						}}
																						readOnlyInput
																						inputStyle={{
																							width: 125
																						}}
																						onChange={e =>
																							this.handleExpectedDeliveryDateChange(
																								quote.id,
																								e.value
																							)
																						}
																						value={
																							quote.expected_delivery_date !==
																								null &&
																								quote.expected_delivery_date !==
																								""
																								? new Date(
																									quote.expected_delivery_date
																								)
																								: new Date()
																						}
																					></Calendar>
																					<i
																						onClick={() =>
																							this.handleExpectedDeliveryDateSubmit(
																								quote.id,
																								quote.id
																							)
																						}
																						className="ml-4 mt-2 fas fa-check"
																					></i>
																				</>
																			)}
																		</td>
																		<td>
																			{!quote.status_editable ? (
																				<>
																					{quote.status}
																					<i
																						onClick={() =>
																							this.handleStatusEditable(
																								quote.id
																							)
																						}
																						className="ml-2 fas fa-edit"
																					></i>
																				</>
																			) : (
																				<>
																					<select
																						onChange={e =>
																							this.handleStatusChange(
																								quote.id,
																								e.target.value
																							)
																						}
																						className="half custom-select"
																						value={quote.status}
																					>
																						<option value="pending">
																							Pending
																			</option>
																						<option value="lost">
																							Lost
																			</option>
																						<option value="pending order">
																							Ordered
																			</option>
																						<option value="postponed">
																							Postponed
																			</option>
																					</select>
																					<i
																						onClick={() =>
																							this.handleStatusSubmit(
																								quote.id,
																								quote.user_id,
																								quote.id
																							)
																						}
																						className="ml-2 fas fa-check"
																					></i>
																				</>
																			)}
																		</td>
																		{parseInt(this.props.show_brand) === 1 &&
																		<td>
																		    {quote && quote.configurations && quote.configurations.length > 0 && Array.from(new Set(quote.configurations.map(x=> x.brand_name))).join(",")}
																		</td>
																		}	
																	</tr>
																	<tr id={"collapse" + (i + 1)} className="hide-table-padding collapse in p-3" style={{
																		backgroundColor: 'rgba(0,0,0,0.24)',
																		fontSize: 12
																	}}>
																		<td className={"px-1 py-2"}></td>
																		<td className={"px-1 py-2 font-weight-bold"}>S.N.</td>
																		<td className={"px-1 py-2 font-weight-bold"}>Configuration Name</td>
																		<td className={"px-1 py-2 font-weight-bold"}>Discount Percentage</td>
																		<td className={"px-1 py-2 font-weight-bold"}>Discount Amount</td>
																		<td className={"px-1 py-2 font-weight-bold"}>Requested Discount</td>
																		<td className={"px-1 py-2 font-weight-bold"}>Approved Discount</td>
																		<td className={"px-1 py-2 font-weight-bold"}>Brand</td>
																	</tr>
																	{
																		quote.configurations.map((configuration, j) =>
																			j % 2 === 0 ?
																				<tr key={j} className="hide-table-padding collapse in p-3" id={"collapse" + (i + 1)} style={{
																					fontSize: 12
																				}}>
																					<td className={"px-1 py-2"}></td>
																					<td className={"px-1 py-2"}>{j + 1}</td>
																					<td  className={"px-1 py-2"}>{configuration.name}</td>
																					<td
																						className={"px-1 py-2"}>{configuration.discount_percentage + " %"}</td>
																					<td
																						className={"px-1 py-2"}>{configuration.discount_amount}{" "}
																						{getSymbolFromCurrency(
																							this.props.country[0]
																								.currency_symbol_html
																						)}</td>
																					{
																						configuration.discount_data.length !== 0 ?
																							<>
																								<td
																									className={"px-1 py-2"}>{configuration.discount_data[0].requested_discount_percentage + " %"}</td>
																								<td
																									className={"px-1 py-2"}>{configuration.discount_data[0].provided_discount + " %"}</td>
																								<td className={"px-1 py-2"}>{configuration.brand_name}</td>
																							</> :
																							<>
																								<td className={"px-1 py-2"}></td>
																								<td className={"px-1 py-2"}></td>
																								<td className={"px-1 py-2"}>{configuration.brand_name}</td>
																							</>
																					}
																				</tr> :
																				<tr  id={"collapse" + (i + 1)} className="hide-table-padding collapse in p-3" style={{
																					backgroundColor: 'rgba(0,0,0,0.075)',
																					fontSize: 12
																				}}>
																					<td className={"px-1 py-2"}></td>
																					<td className={"px-1 py-2"}>{j + 1}</td>
																					<td className={"px-1 py-2"}>{configuration.name}</td>
																					<td
																						className={"px-1 py-2"}>{configuration.discount_percentage + " %"}</td>
																					<td
																						className={"px-1 py-2"}>{configuration.discount_amount}{" "}
																						{getSymbolFromCurrency(
																							this.props.country[0]
																								.currency_symbol_html
																						)}</td>
																					{
																						configuration.discount_data.length !== 0 ?
																							<>
																								<td
																									className={"px-1 py-2"}>{configuration.discount_data[0].requested_discount_percentage + " %"}</td>
																								<td
																									className={"px-1 py-2"}>{configuration.discount_data[0].provided_discount + " %"}</td>
																								<td className={"px-1 py-2"}>{configuration.brand_name}</td>
																							</> :
																							<>
																								<td className={"px-1 py-2"}></td>
																								<td className={"px-1 py-2"}></td>
																								<td className={"px-1 py-2"}>{configuration.brand_name}</td>
																							</>

																					}
																				</tr>
																		)
																	}
																</>
															) : null
														)
														: null}
												</tbody>
											</table>
										</div> :
										<div className="table-responsive" style={{'overflow-x': 'unset'}}>
											<table className="table">
												<thead>
													<tr>
														<th className="five-percent" scope="col">#</th>
														<th scope="col">Quote Name</th>
														<th>Quote For</th>
														<th>Created At</th>
														{this.props.is_support ? (
															<th>Created By</th>
														) : null}
														<th>Order Opportunity</th>
														<th>E. Delivery Date</th>
														<th>Status</th>
														{ parseInt(this.props.show_brand) === 1 &&
														<th>Brand</th>}
													</tr>
												</thead>
												<tbody>
													{this.state.search_quotes
														? this.state.search_quotes.map((quote, i) =>
															i <
																this.state.total_shown_configuration ? (
																<>
																	<tr key={i} style={{
																		borderBottom: 'solid 0.5px #000000'
																	}}>
																		<td>{i + 1}</td>
																		<td>
																			<Link to={"/quote/" + quote.id}>
																				{quote.quotation_name}
																			</Link>
																		</td>
																		<td>{quote.quotation_for}</td>
																		<td>
																			{this.formatDate(
																				quote.created_at.replace(
																					/-/g,
																					"/"
																				)
																			)}
																		</td>
																		{this.props.is_support ? (
																			<td>{quote.user_name}</td>
																		) : null}
																		<td>
																			{!quote.order_opportunity_editable ? (
																				<>
																					{quote.order_opportunity !==
																						null &&
																						quote.order_opportunity !==
																						"" &&
																						quote.order_opportunity !==
																						undefined
																						? quote.order_opportunity +
																						"%"
																						: null}
																					<i
																						onClick={() =>
																							this.handleOrderOpportunityEditable(
																								quote.id
																							)
																						}
																						className="ml-2 fas fa-edit"
																					></i>
																				</>
																			) : (
																				<>
																					<select
																						onChange={e =>
																							this.handleOrderOpportunityChange(
																								quote.id,
																								e.target.value
																							)
																						}
																						className="half custom-select"
																						value={
																							quote.order_opportunity
																						}
																					>
																						<option value={0}>
																							0 %
																				</option>
																						<option value={10}>
																							10 %
																				</option>
																						<option value={30}>
																							30 %
																				</option>
																						<option value={70}>
																							70 %
																				</option>
																						<option value={90}>
																							90 %
																				</option>
																					</select>
																					<i
																						onClick={() =>
																							this.handleOrderOpportunitySubmit(
																								quote.id,
																								quote.id
																							)
																						}
																						className="ml-2 fas fa-check"
																					></i>
																				</>
																			)}
																		</td>
																		<td>
																			{!quote.extected_delivery_date_editable ? (
																				<>
																					{this.formatDate(
																						quote.expected_delivery_date
																					)}
																					<i
																						onClick={() =>
																							this.handleExpectedDeliveryDateEditable(
																								quote.id
																							)
																						}
																						className="ml-2 fas fa-edit"
																					></i>
																				</>
																			) : (
																				<>
																					<Calendar
																						dateFormat="yy-mm-dd"
																						readOnlyInput
																						style={{
																							width: 125
																						}}
																						inputStyle={{
																							width: 125
																						}}
																						onChange={e =>
																							this.handleExpectedDeliveryDateChange(
																								quote.id,
																								e.value
																							)
																						}
																						value={
																							quote.expected_delivery_date !==
																								null &&
																								quote.expected_delivery_date !==
																								""
																								? new Date(
																									quote.expected_delivery_date
																								)
																								: new Date()
																						}
																					></Calendar>
																					<i
																						onClick={() =>
																							this.handleExpectedDeliveryDateSubmit(
																								quote.id,
																								quote.id
																							)
																						}
																						className="ml-4 mt-2 fas fa-check"
																					></i>
																				</>
																			)}
																		</td>
																		<td>
																			{!quote.status_editable ? (
																				<>
																					{quote.status}
																					<i
																						onClick={() =>
																							this.handleStatusEditable(
																								quote.id
																							)
																						}
																						className="ml-2 fas fa-edit"
																					></i>
																				</>
																			) : (
																				<>
																					<select
																						onChange={e =>
																							this.handleStatusChange(
																								quote.id,
																								e.target.value
																							)
																						}
																						className="half custom-select"
																						value={quote.status}
																					>
																						<option value="pending">
																							Pending
																				</option>
																						<option value="lost">
																							Lost
																				</option>
																						<option value="pending order">
																							Ordered
																				</option>
																						<option value="postponed">
																							Postponed
																				</option>
																					</select>
																					<i
																						onClick={() =>
																							this.handleStatusSubmit(
																								quote.id,
																								quote.user_id,
																								quote.id
																							)
																						}
																						className="ml-2 fas fa-check"
																					></i>
																				</>
																			)}
																		</td>
																		{ parseInt(this.props.show_brand) === 1 &&
																		<td>
																			{quote && quote.configurations && quote.configurations.length > 0 && Array.from(new Set(quote.configurations.map(x=> x.brand_name))).join(",")}
																		</td>
																		}
																	</tr>
																</>
															) : null
														)
														: null}
												</tbody>
											</table>
										</div>
								}
							</div>
							<div className="col-sm"></div>
						</div>
						{this.state.search_quotes.length >
							this.state.total_shown_configuration ? (
							<div className="text-center">
								<button
									onClick={e =>
										this.setState({
											total_shown_configuration: 1000000
										})
									}
									type="submit"
									className="client-roboto-condensed client-login mr-2 ml-2"
								>
									Load More
								</button>
							</div>
						) : null}
					</div>
				</div>
			);
		}
	}
}
const mapStateToProps = state => {
	return {
		user_id: state.loginReducer.user.user.id,
		role: state.loginReducer.user.role,
		show_brand: state.loginReducer.user.show_brand,
		country: state.loginReducer.user.country,
		loading: state.clientQuoteReducer.loading,
		loginLoading: state.loginReducer.loading,
		is_support: state.loginReducer.user.is_support,
		is_manager: state.loginReducer.user.is_manager
	};
};

export default connect(mapStateToProps, {
	clean_quote_message,
	order_quote
})(ChildrenQuotes);
