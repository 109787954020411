import clientConfigurationActionTypes from "./actionTypes";

function save_first_form(params) {
  return {
    type: clientConfigurationActionTypes.SAVE_FIRST_FORM_REQUEST,
    loading: true,
    params: params
  };
}

function save_second_form(params) {
  return {
    type: clientConfigurationActionTypes.SAVE_SECOND_FORM_REQUEST,
    loading: true,
    params: params
  };
}

function save_third_from(params) {
  return {
    type: clientConfigurationActionTypes.SAVE_THIRD_FORM_REQUEST,
    loading: true,
    params: params
  };
}

function create_configuration(params) {
  return {
    type: clientConfigurationActionTypes.CREATE_CONFIGURATION_REQUEST,
    loading: true,
    params: params
  };
}

function edit_library_configuration(id, params, role) {
  return {
    type: clientConfigurationActionTypes.EDIT_LIBRARY_CONFIGURATION_REQUEST,
    loading: true,
    id: id,
    params: params,
    role: role
  };
}

function edit_configuration(id, params, role, from = null) {
  return {
    type: clientConfigurationActionTypes.EDIT_CONFIGURATION_REQUEST,
    loading: true,
    id: id,
    params: params,
    role: role,
    from: from
  };
}

function show_user_configurations(user) {
  return {
    type: clientConfigurationActionTypes.SHOW_USER_CONFIGURATIONS_REQUEST,
    loading: true,
    user: user
  };
}

function show_single_library_configuration(params) {
  return {
    type:
      clientConfigurationActionTypes.SHOW_SINGLE_LIBRARY_CONFIGURATION_REQUEST,
    loading: true,
    params: params
  };
}

function show_single_user_configuration(user, params, role) {
  return {
    type: clientConfigurationActionTypes.SHOW_SINGLE_USER_CONFIGURATION_REQUEST,
    loading: true,
    user: user,
    params: params,
    role: role
  };
}

function show_single_library_configuration_for_edit(user, params, role) {
  return {
    type:
      clientConfigurationActionTypes.SHOW_SINGLE_LIBRARY_CONFIGURATION_FOR_EDIT_REQUEST,
    loading: true,
    user: user,
    params: params,
    role: role
  };
}

function show_single_user_configuration_for_edit(user, params, role) {
  return {
    type:
      clientConfigurationActionTypes.SHOW_SINGLE_USER_CONFIGURATION_FOR_EDIT_REQUEST,
    loading: true,
    user: user,
    params: params,
    role: role
  };
}

function clear_froms() {
  return {
    type: clientConfigurationActionTypes.CLEAR_FORMS_REQUEST,
    loading: true
  };
}

function clear_second_froms() {
  return {
    type: clientConfigurationActionTypes.CLEAR_SECOND_FORM_REQUEST,
    loading: true
  };
}

function clear_message() {
  return {
    type: clientConfigurationActionTypes.CLEAR_MESSAGE_REQUEST,
    loading: true
  };
}

export {
  edit_library_configuration,
  show_single_library_configuration_for_edit,
  show_single_library_configuration,
  clear_message,
  save_first_form,
  clear_froms,
  clear_second_froms,
  save_second_form,
  save_third_from,
  create_configuration,
  edit_configuration,
  show_user_configurations,
  show_single_user_configuration,
  show_single_user_configuration_for_edit
};
