const machinePartsActionTypes = {
  SHOW_MACHINE_PARTS_REQUEST: "show.machine.parts.request",
  SHOW_MACHINE_PARTS_SUCCESS: "show.machine.parts.success",
  SHOW_MACHINE_PARTS_FALIURE: "show.machine.parts.faliure",

  ADD_MACHINE_PARTS_REQUEST: "add.machine.parts.request",
  ADD_MACHINE_PARTS_SUCCESS: "add.machine.parts.success",
  ADD_MACHINE_PARTS_FALIURE: "add.machine.parts.faliure",

  EDIT_MACHINE_PARTS_REQUEST: "edit.machine.parts.request",
  EDIT_MACHINE_PARTS_SUCCESS: "edit.machine.parts.success",
  EDIT_MACHINE_PARTS_FALIURE: "edit.machine.parts.faliure",

  EDIT_MACHINE_PARTS_ONLY_REQUEST: "edit.machine.parts.only.request",
  EDIT_MACHINE_PARTS_ONLY_SUCCESS: "edit.machine.parts.only.success",
  EDIT_MACHINE_PARTS_ONLY_FALIURE: "edit.machine.parts.only.faliure",

  DELETE_MACHINE_PARTS_REQUEST: "delete.machine.parts.request",
  DELETE_MACHINE_PARTS_SUCCESS: "delete.machine.parts.success",
  DELETE_MACHINE_PARTS_FALIURE: "delete.machine.parts.faliure",

  ADD_MACHINE_PART_DATA_REQUEST: "add.machine.part.data.request",
  ADD_MACHINE_PART_DATA_SUCCESS: "add.machine.part.data.success",
  ADD_MACHINE_PART_DATA_FALIURE: "add.machine.part.data.faliure",

  CLEAN_MESSAGE_REQUEST: "clean.message.request",
  CLEAN_MESSAGE_SUCCESS: "clean.message.success",
  CLEAN_MESSAGE_FALIURE: "clean.message.faliure"
};
export default machinePartsActionTypes;
