import roleActionTypes from "./actionTypes";
const INITIAL_STATE = {
  message: "",
  loading: false,
  costPriceVisibility: false
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case roleActionTypes.GET_COST_PRICE_VISIBILITY_FOR_ROLE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case roleActionTypes.GET_COST_PRICE_VISIBILITY_FOR_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
        costPriceVisibility: action.costPriceVisibility
      };
    case roleActionTypes.GET_COST_PRICE_VISIBILITY_FOR_ROLE_FALIURE:
      return {
        ...state,
        loading: false,
        message: action.message
      };

    default:
      return state;
  }
}
