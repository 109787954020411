import machinePartsActionTypes from "./actionTypes";
const INITIAL_STATE = {
  message: "",
  loading: false,
  machine_parts: [],
  machine_part: []
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case machinePartsActionTypes.SHOW_MACHINE_PARTS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case machinePartsActionTypes.SHOW_MACHINE_PARTS_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
        machine_parts: action.machine_parts
      };
    case machinePartsActionTypes.SHOW_MACHINE_PARTS_FALIURE:
      return {
        ...state,
        loading: false,
        message: action.message,
        machine_part: action.machine_part
      };

    case machinePartsActionTypes.ADD_MACHINE_PARTS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case machinePartsActionTypes.ADD_MACHINE_PARTS_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message
      };
    case machinePartsActionTypes.ADD_MACHINE_PARTS_FALIURE:
      return {
        ...state,
        loading: false,
        message: action.message
      };

    case machinePartsActionTypes.EDIT_MACHINE_PARTS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case machinePartsActionTypes.EDIT_MACHINE_PARTS_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message
      };
    case machinePartsActionTypes.EDIT_MACHINE_PARTS_FALIURE:
      return {
        ...state,
        loading: false,
        message: action.message
      };

    case machinePartsActionTypes.EDIT_MACHINE_PARTS_ONLY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case machinePartsActionTypes.EDIT_MACHINE_PARTS_ONLY_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message
      };
    case machinePartsActionTypes.EDIT_MACHINE_PARTS_ONLY_FALIURE:
      return {
        ...state,
        loading: false,
        message: action.message
      };

    case machinePartsActionTypes.DELETE_MACHINE_PARTS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case machinePartsActionTypes.DELETE_MACHINE_PARTS_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message
      };
    case machinePartsActionTypes.DELETE_MACHINE_PARTS_FALIURE:
      return {
        ...state,
        loading: false,
        message: action.message
      };

    case machinePartsActionTypes.ADD_MACHINE_PART_DATA_REQUEST:
      return {
        ...state,
        loading: true
      };
    case machinePartsActionTypes.ADD_MACHINE_PART_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message
      };
    case machinePartsActionTypes.ADD_MACHINE_PART_DATA_FALIURE:
      return {
        ...state,
        loading: false,
        message: action.message
      };

    case machinePartsActionTypes.CLEAN_MESSAGE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case machinePartsActionTypes.CLEAN_MESSAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        message: ""
      };
    case machinePartsActionTypes.CLEAN_MESSAGE_FALIURE:
      return {
        ...state,
        loading: false
      };

    default:
      return state;
  }
}
