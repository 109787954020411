import React, { Component } from "react";
import { css } from "@emotion/react";
import { PulseLoader } from "react-spinners";
import { connect } from "react-redux";
import { ArticleService } from "./../../admin/article/service";

class PrivacyStatement extends Component {
	constructor(props) {
		super(props);
		this.state = {
			article: false,
			title: false,
			description: false,
			loading: true
		};
	}

	async componentDidMount() {
		let that = this;

		let data = await ArticleService.showAllArticles();

		data = data.filter(item =>
			item.article_title.toLowerCase().includes("privacy")
		);
		this.setState({ article: data });
		that.setState({ loading: false });
	}

	render() {
		const override = css`
			display: block;
			margin: 0 auto;
			border-color: red;
		`;
		if (this.state.loading) {
			return (
				<div className="sweet-loading">
					<div className="container loading-container">
						<div className="row m-5 p-5">
							<PulseLoader
								css={override}
								sizeUnit={"px"}
								size={24}
								color={"#B9373D"}
							/>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div>
					<style>
						{
							"\
                        ul{\
                            margin-left: 5%;\
                        }\
                    "
						}
					</style>
					<div className="mt-4 mb-4 client-roboto-condensed container">
						<h5 className="text-center my-5">
							{this.state.article
								? this.state.article[0].article_title
								: ""}
						</h5>
						<div className="row">
							<div
								className="roboto-condensed font-weight-bold"
								dangerouslySetInnerHTML={{
									__html: this.state.article
										? this.state.article[0].article_description
										: ""
								}}
							/>
						</div>
					</div>
				</div>
			);
		}
	}
}

const mapStateToProps = state => {
	return {
	};
};

export default connect(mapStateToProps, {})(PrivacyStatement);
