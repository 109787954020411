import sequenceActionTypes from "./actionTypes";
const INITIAL_STATE = {
  message: "",
  loading: false,
  sequences: false,
  sequence: false
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case sequenceActionTypes.SHOW_SEQUENCE_FOR_MACHINE_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case sequenceActionTypes.SHOW_SEQUENCE_FOR_MACHINE_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
        sequences: action.sequences
      };
    case sequenceActionTypes.SHOW_SEQUENCE_FOR_MACHINE_CATEGORY_FALIURE:
      return {
        ...state,
        loading: false,
        message: action.message
      };

    case sequenceActionTypes.ADD_OR_EDIT_SEQUENCE_FOR_MACHINE_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case sequenceActionTypes.ADD_OR_EDIT_SEQUENCE_FOR_MACHINE_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message
      };
    case sequenceActionTypes.ADD_OR_EDIT_SEQUENCE_FOR_MACHINE_CATEGORY_FALIURE:
      return {
        ...state,
        loading: false,
        message: action.message
      };

    case sequenceActionTypes.EDIT_SEQUENCE_FOR_MACHINE_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case sequenceActionTypes.EDIT_SEQUENCE_FOR_MACHINE_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message
      };
    case sequenceActionTypes.EDIT_SEQUENCE_FOR_MACHINE_CATEGORY_FALIURE:
      return {
        ...state,
        loading: false,
        message: action.message
      };

    case sequenceActionTypes.CLEAN_MESSAGE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case sequenceActionTypes.CLEAN_MESSAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message
      };
    case sequenceActionTypes.CLEAN_MESSAGEG_FALIURE:
      return {
        ...state,
        loading: false,
        message: action.message
      };

    default:
      return state;
  }
}
