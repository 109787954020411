import { fork } from "redux-saga/effects";
import {
	showYear,
	showSingleYear,
	addYear,
	editYear,
	deleteYear,
	cleanYearMessage
} from "./../admin/year/saga";
import {
	showUsers,
	validateUser,
	cleanUserMessage
} from "./../admin/user/saga";
import {
	showCountries,
	addCountries,
	editCountries,
	deleteCountries,
	cleanCountryMessage
} from "../admin/country/saga";
import { showQuotes, showSingleQuote } from "./../admin/quote/saga";
import {
	showMachineParts,
	addMachineParts,
	editMachineParts,
	deleteMachineParts,
	addMachinePartData,
	cleanMachinePartMessage,
	editMachinePartsOnly
} from "../admin/machine_parts/saga";
import {
	showMachineCategories,
	addMachineCategories,
	editMachineCategories,
	deleteMachineCategories,
	cleanMachineCategoryMessage
} from "../admin/machine_category/saga";
import { showDashboardData } from "./../admin/dashboard/saga";
import { showConfiguredMachines } from "../admin/configured_machines/saga";
import {
	showCompatiblePartsForCategory,
	showAllCategories,
	showAllMachineParts,
	updateCompatibility,
	getTreeCategory
} from "../admin/compatibility/saga";
import {
	showCompanyRates,
	addCompanyRates,
	editCompanyRates,
	deleteCompanyRate
} from "../admin/company_rates/saga";
import {
	login,
	logout,
	register,
	registerAdmin,
	getUser,
	cleanLoginMessage,
	getLoginInfoWithToken
} from "../login/saga";
import { showOrders, showDetailedOrder } from "../admin/order/saga";
import { downloadXlsxFiles, uploadXlsxFile } from "../admin/spreadsheet/saga";
import {
	showFeatures,
	showSingleFeatures,
	addFeature,
	editFeature,
	deleteFeature,
	addFeaturesToCategory,
	cleanFeatureMessage
} from "../admin/featureset/saga";

import {
	showKeyModelFeatures,
	showSingleKeyModelFeatures,
	addKeyModelFeature,
	editKeyModelFeature,
	deleteKeyModelFeature,
	addKeyModelFeaturesToCategory,
	cleanKeyModelFeatureMessage
} from "../admin/keymodelfeature/saga";

import {
	showSingleSpecification,
	showAllSpecificationForMachineCateogry,
	addSpecificationForMachineCategory,
	editSpecificationForMachineCategory,
	deleteSpecificationForMachineCategory,
	cleanSpecificationMessage
} from "../admin/specifications/saga";

import {
	showClientQuotes,
	showSingleClientQuote,
	createClientQuote,
	saveQuoteFirstForm,
	saveQuoteSecondForm,
	saveQuoteThirdForm,
	getDataForEditing,
	editClientQuote,
	orderQuote,
	downloadDocx,
	downloadXlsx,
	cleanQuoteMessage,
	cleanQuoteForms
} from "../client/quotes/saga";
import { showProfileData } from "../client/profile/saga";
import {
	showSingleClientOrder,
	createClientOrder,
	showClientOrders
} from "../client/order/saga";
import {
	saveFirstForm,
	saveSecondForm,
	saveThirdForm,
	saveConfiguration,
	createConfiguration,
	editCofiguration,
	showUserConfigurations,
	showSingleUserConfiguration,
	clearForms,
	clearSecondForms,
	clearConfigurationMessage,
	showSingleUserConfigurationForEdit,
	showSingleLibraryConfiguration,
	showSingleLibraryConfigurationForEdit,
	editLibraryCofiguration
} from "../client/configuration/saga";
import { changeLanguage } from "../language/saga";
import {
	showBrands,
	addBrand,
	editBrand,
	deleteBrand
} from "../admin/brand/saga";
import {
	showSequenceForMachineCategory,
	editSequenceForMachineCategory,
	addOrEditSequenceForMachineCategory,
	sequenceCleanMessage
} from "../admin/sequence/saga";
import {
	showNotesAndRemarks,
	updateNoteAndRemark,
	cleanNoteAndRemarkMessage
} from "../admin/note_and_remark/saga";
import { getCostPriceVisibilityForRole } from "../admin/role/saga";

export default function* Saga() {
	//Language change saga
	yield fork(changeLanguage);

	//Admin sagas start

	//saga for brand component
	yield fork(showBrands);
	yield fork(addBrand);
	yield fork(editBrand);
	yield fork(deleteBrand);

	//saga from the year component
	yield fork(showYear);
	yield fork(showSingleYear);
	yield fork(addYear);
	yield fork(editYear);
	yield fork(deleteYear);
	yield fork(cleanYearMessage);

	// saga from user component
	yield fork(showUsers);
	yield fork(validateUser);
	yield fork(cleanUserMessage);

	// saga from dashboard component
	yield fork(showDashboardData);

	// saga from country component
	yield fork(showCountries);
	yield fork(addCountries);
	yield fork(editCountries);
	yield fork(deleteCountries);
	yield fork(cleanCountryMessage);

	// saga from quote component
	yield fork(showQuotes);
	yield fork(showSingleQuote);

	// saga from machine_parts component
	yield fork(showMachineParts);
	yield fork(addMachineParts);
	yield fork(editMachineParts);
	yield fork(editMachinePartsOnly);
	yield fork(deleteMachineParts);
	yield fork(addMachinePartData);
	yield fork(cleanMachinePartMessage);

	//saga from spreadsheet component
	yield fork(downloadXlsxFiles);
	yield fork(uploadXlsxFile);

	// saga from machine_categories component
	yield fork(showMachineCategories);
	yield fork(addMachineCategories);
	yield fork(editMachineCategories);
	yield fork(deleteMachineCategories);
	yield fork(cleanMachineCategoryMessage);

	// saga from configuration component
	yield fork(showConfiguredMachines);

	// saga from compatibility component
	yield fork(showCompatiblePartsForCategory);
	yield fork(showAllCategories);
	yield fork(showAllMachineParts);
	yield fork(updateCompatibility);
	yield fork(getTreeCategory);

	// saga from company_rates component
	yield fork(showCompanyRates);
	yield fork(addCompanyRates);
	yield fork(editCompanyRates);
	yield fork(deleteCompanyRate);

	// saga from login component
	yield fork(login);
	yield fork(getLoginInfoWithToken);
	yield fork(getUser);
	yield fork(logout);
	yield fork(cleanLoginMessage);

	// saga from register component
	yield fork(register);
	yield fork(registerAdmin);

	// saga from order component
	yield fork(showOrders);
	yield fork(showDetailedOrder);

	yield fork(showFeatures);
	yield fork(showSingleFeatures);
	yield fork(addFeature);
	yield fork(editFeature);
	yield fork(deleteFeature);
	yield fork(addFeaturesToCategory);
	yield fork(cleanFeatureMessage);

	yield fork(showKeyModelFeatures);
	yield fork(showSingleKeyModelFeatures);
	yield fork(addKeyModelFeature);
	yield fork(editKeyModelFeature);
	yield fork(deleteKeyModelFeature);
	yield fork(addKeyModelFeaturesToCategory);
	yield fork(cleanKeyModelFeatureMessage);

	yield fork(showSingleSpecification);
	yield fork(showAllSpecificationForMachineCateogry);
	yield fork(addSpecificationForMachineCategory);
	yield fork(editSpecificationForMachineCategory);
	yield fork(deleteSpecificationForMachineCategory);
	yield fork(cleanSpecificationMessage);

	yield fork(showNotesAndRemarks);
	yield fork(updateNoteAndRemark);
	yield fork(cleanNoteAndRemarkMessage);

	yield fork(getCostPriceVisibilityForRole);
	//Admin sagas end

	// Client Saga Starts
	yield fork(showClientQuotes);
	yield fork(showSingleClientQuote);
	yield fork(createClientQuote);
	yield fork(editClientQuote);
	yield fork(orderQuote);
	yield fork(downloadDocx);
	yield fork(downloadXlsx);
	yield fork(cleanQuoteMessage);
	yield fork(cleanQuoteForms);

	yield fork(showProfileData);

	yield fork(showClientOrders);
	yield fork(showSingleClientOrder);
	yield fork(createClientOrder);

	yield fork(saveQuoteFirstForm);
	yield fork(saveQuoteSecondForm);
	yield fork(saveQuoteThirdForm);
	yield fork(getDataForEditing);

	yield fork(saveFirstForm);
	yield fork(saveSecondForm);
	yield fork(saveThirdForm);
	yield fork(saveConfiguration);
	yield fork(createConfiguration);
	yield fork(editCofiguration);
	yield fork(showUserConfigurations);
	yield fork(showSingleUserConfiguration);
	yield fork(clearForms);
	yield fork(clearSecondForms);
	yield fork(clearConfigurationMessage);
	yield fork(showSingleUserConfigurationForEdit);
	yield fork(showSingleLibraryConfiguration);
	yield fork(showSingleLibraryConfigurationForEdit);
	yield fork(editLibraryCofiguration);

	yield fork(showSequenceForMachineCategory);
	yield fork(addOrEditSequenceForMachineCategory);
	yield fork(editSequenceForMachineCategory);
	yield fork(sequenceCleanMessage);
	// Client Saga End
}
