import React, { Component } from "react";
import { connect } from "react-redux";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Api } from "./../../../config/api";
import config from "../../../config/config";

class ListBackupFiles extends Component {
	constructor(props) {
		super(props);
		this.state = {
			files: false
		};
	}

	async componentDidMount() {
		let that = this;

		await Api.get("showAllBackupFiles").then(function(response) {
			that.setState({ files: response.data });
		});
	}

	render() {
		if (!this.props.loading) {
			return (
				<div>
					<div
						className="content"
						style={{
							minHeight: 500
						}}
					>
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-header">
										<strong className="card-title">
											Backup Files
										</strong>
									</div>
									<div className="card-body">
										<div className="card-content">
											<div className="todo-list">
												<div className="tdl-holder">
													<div className="tdl-content">
														<ul>
															{this.state.files
																? this.state.files.map(
																		(file, i) => (
																			<li key={i}>
																				<label
																					style={{
																						color: "black"
																					}}
																				>
																					<div className="row">
																						<div className="col-sm-9">
																							{file.replace(
																								"Next-Level-Value---CPQ/",
																								""
																							)}
																						</div>
																						<div className="col-sm-3">
																							<a
																								href={
																									config.APP_URL +
																									"/api/storage/" +
																									file
																								}
																							>
																								<i className="fa text-dark fa-download"></i>
																							</a>
																						</div>
																					</div>
																				</label>
																			</li>
																		)
																  )
																: null}
														</ul>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div>
					<div
						className="content"
						style={{
							minHeight: 500
						}}
					>
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-header">
										<strong className="card-title">
											Backup Files
										</strong>
									</div>
									<div className="card-body">
										<div className="card-content">
											<div className="todo-list">
												<div className="tdl-holder">
													<div className="tdl-content">
														<div className="row">
															<div className="col"></div>
															<div className="col">
																<div className="text-center">
																	<Loader
																		type="Rings"
																		color="#00BFFF"
																		height={100}
																		width={100}
																	/>
																	<div className="text-center client-roboto-condensed">
																		LOADING...
																	</div>
																</div>
															</div>
															<div className="col"></div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}
}
const mapStateToProps = state => {
	return {};
};

export default connect(mapStateToProps, {})(ListBackupFiles);
