import { ClientQuoteService } from "./service";
import { take, call, put } from "redux-saga/effects";
import clientQuoteActionTypes from "./actionTypes";

function* saveQuoteFirstForm() {
  while (true) {
    const { params } = yield take(
      clientQuoteActionTypes.SAVE_QUOTE_FIRST_FORM_REQUEST
    );

    const firstForm = params;

    if (firstForm !== "" && firstForm !== null && firstForm !== [] && firstForm) {
      yield put({
        type: clientQuoteActionTypes.SAVE_QUOTE_FIRST_FORM_SUCCESS,
        message: "Saving first form data success",
        firstForm: firstForm
      });
    } else {
      yield put({
        type: clientQuoteActionTypes.SAVE_QUOTE_FIRST_FORM_FALIURE,
        message: "Saving first form data faliure"
      });
    }
  }
}

function* saveQuoteSecondForm() {
  while (true) {
    const { params } = yield take(
      clientQuoteActionTypes.SAVE_QUOTE_SECOND_FORM_REQUEST
    );

    if (params !== "" && params !== null && params !== [] && params) {
      yield put({
        type: clientQuoteActionTypes.SAVE_QUOTE_SECOND_FORM_SUCCESS,
        message: "Saving second form data success",
        secondForm: params
      });
    } else {
      yield put({
        type: clientQuoteActionTypes.SAVE_QUOTE_SECOND_FORM_FALIURE,
        message: "Saving second form data faliure"
      });
    }
  }
}

function* saveQuoteThirdForm() {
  while (true) {
    const { params } = yield take(
      clientQuoteActionTypes.SAVE_QUOTE_THIRD_FORM_REQUEST
    );

    if (params !== "" && params !== null && params !== [] && params) {
      yield put({
        type: clientQuoteActionTypes.SAVE_QUOTE_THIRD_FORM_SUCCESS,
        message: "Saving second form data success",
        secondForm: params
      });
    } else {
      yield put({
        type: clientQuoteActionTypes.SAVE_QUOTE_THIRD_FORM_FALIURE,
        message: "Saving second form data faliure"
      });
    }
  }
}

function* showClientQuotes(user) {
  while (true) {
    const { user } = yield take(clientQuoteActionTypes.SHOW_QUOTES_REQUEST);

    const quotes = yield call(ClientQuoteService.showQuotes, user);

    if (quotes !== "" && quotes !== null && quotes !== [] && quotes) {
      yield put({
        type: clientQuoteActionTypes.SHOW_QUOTES_SUCCESS,
        message: "Getting user quotes success",
        quotes: quotes
      });
    } else {
      yield put({
        type: clientQuoteActionTypes.SHOW_QUOTES_FALIURE,
        message: "Getting user quotes faliure"
      });
    }
  }
}

function* showSingleClientQuote(user, params) {
  while (true) {
    const { user, params, role } = yield take(
      clientQuoteActionTypes.SHOW_SINGLE_QUOTE_REQUEST
    );

    let quote = [];
    if (role) {
      quote = yield call(
        ClientQuoteService.showSingleQuoteSalesSupport,
        user,
        params
      );
    } else {
      quote = yield call(ClientQuoteService.showSingleQuote, user, params);
    }

    if (quote !== "" && quote !== null && quote !== [] && quote) {
      yield put({
        type: clientQuoteActionTypes.SHOW_SINGLE_QUOTE_SUCCESS,
        message: "Getting specific user quote success",
        quote: quote
      });
    } else {
      yield put({
        type: clientQuoteActionTypes.SHOW_SINGLE_QUOTE_FALIURE,
        message: "Getting specificuser quote faliure"
      });
    }
  }
}

function* createClientQuote(params) {
  while (true) {
    const { params } = yield take(clientQuoteActionTypes.CREATE_QUOTE_REQUEST);

    const quote = yield call(ClientQuoteService.createQuote, params);
    

    if (quote !== "" && quote !== null && quote !== [] && quote) {
      yield put({
        type: clientQuoteActionTypes.CREATE_QUOTE_SUCCESS,
        message: "Creating user quote success",
        quote: quote
      });
    } else {
      yield put({
        type: clientQuoteActionTypes.CREATE_QUOTE_FALIURE,
        message: "Creating user quote faliure"
      });
    }
  }
}

function* editClientQuote() {
  while (true) {
    const { id, params } = yield take(
      clientQuoteActionTypes.EDIT_QUOTE_REQUEST
    );

    const quote = yield call(ClientQuoteService.editClientQuote, id, params);

    if (quote !== "" && quote !== null && quote !== [] && quote) {
      yield put({
        type: clientQuoteActionTypes.EDIT_QUOTE_SUCCESS,
        message: "Editing user quote success"
      });
    } else {
      yield put({
        type: clientQuoteActionTypes.EDIT_QUOTE_FALIURE,
        message: "Editing user quote faliure"
      });
    }
  }
}

function* getDataForEditing() {
  while (true) {
    const { params } = yield take(
      clientQuoteActionTypes.GET_DATA_FOR_EDITING_REQUEST
    );

    const quote = yield call(ClientQuoteService.getDataForEditing, params);

    let firstForm = false;
    let secondForm = false;
    if (quote !== "" && quote !== null && quote !== [] && quote) {
      firstForm = {
        quotation_name: quote.quote[0].quotation_name,
        quotation_for: quote.quote[0].quotation_for
      };

      secondForm = {
        selected_configurations: quote.configurations
      };
    }

    if (quote !== "" && quote !== null && quote !== [] && quote) {
      yield put({
        type: clientQuoteActionTypes.GET_DATA_FOR_EDITING_SUCCESS,
        message: "Getting data for success",
        firstForm: firstForm,
        secondForm: secondForm
      });
    } else {
      yield put({
        type: clientQuoteActionTypes.GET_DATA_FOR_EDITING_FALIURE,
        message: "Getting data for faliure"
      });
    }
  }
}

function* orderQuote() {
  while (true) {
    const { user_id, params } = yield take(
      clientQuoteActionTypes.ORDER_QUOTE_REQUEST
    );

    const quote = yield call(ClientQuoteService.orderQuote, user_id, params);

    if (quote !== "" && quote !== null && quote !== [] && quote) {
      yield put({
        type: clientQuoteActionTypes.ORDER_QUOTE_SUCCESS,
        message: "Ordering quote success"
      });
    } else {
      yield put({
        type: clientQuoteActionTypes.ORDER_QUOTE_FALIURE,
        message: "Ordering quote faliure"
      });
    }
  }
}

function* downloadDocx() {
  while (true) {
    const { quote_id } = yield take(
      clientQuoteActionTypes.DOWNLOAD_DOCX_REQUEST
    );

    const quote = yield call(ClientQuoteService.downloadDocx, quote_id);

    if (quote !== "" && quote !== null && quote !== [] && quote) {
      yield put({
        type: clientQuoteActionTypes.DOWNLOAD_DOCX_SUCCESS,
        message: "Document download successful",
        quote: quote
      });
    } else {
      yield put({
        type: clientQuoteActionTypes.DOWNLOAD_DOCX_FALIURE,
        message: "Document download faliure"
      });
    }
  }
}

function* downloadXlsx() {
  while (true) {
    const { quote_id } = yield take(
      clientQuoteActionTypes.DOWNLOAD_XLSX_REQUEST
    );

    const quote = yield call(ClientQuoteService.downloadXlsx, quote_id);

    if (quote !== "" && quote !== null && quote !== [] && quote) {
      yield put({
        type: clientQuoteActionTypes.DOWNLOAD_XLSX_SUCCESS,
        message: "Spreadsheet download successful",
        quote: quote
      });
    } else {
      yield put({
        type: clientQuoteActionTypes.DOWNLOAD_XLSX_FALIURE,
        message: "Spreadsheet download faliure"
      });
    }
  }
}

function* cleanQuoteMessage() {
  while (true) {
    yield take(clientQuoteActionTypes.CLEAN_MESSAGE_REQUEST);

    yield put({
      type: clientQuoteActionTypes.CLEAN_MESSAGE_SUCCESS
    });
  }
}

function* cleanQuoteForms() {
  while (true) {
    yield take(clientQuoteActionTypes.CLEAN_FORM_REQUEST);

    yield put({
      type: clientQuoteActionTypes.CLEAN_FORM_SUCCESS
    });
  }
}

export {
  cleanQuoteForms,
  saveQuoteFirstForm,
  cleanQuoteMessage,
  editClientQuote,
  orderQuote,
  getDataForEditing,
  saveQuoteSecondForm,
  saveQuoteThirdForm,
  showClientQuotes,
  showSingleClientQuote,
  createClientQuote,
  downloadDocx,
  downloadXlsx
};
