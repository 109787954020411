import React, { Component } from "react";
import { SpreadsheetService } from "./../service";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import config from "../../../config/config";
import Axios from "axios";

export default class SpreadSheet extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true
		};
		this.onSelectValue = this.onSelectValue.bind(this);
		this.downloadSpreadsheetCategory = this.downloadSpreadsheetCategory.bind(
			this
		);
		this.generateSpreadsheet = this.generateSpreadsheet.bind(this);
		this.downloadSpreadsheetParts = this.downloadSpreadsheetParts.bind(this);
		this.downloadSpreadsheetCategoryOnly = this.downloadSpreadsheetCategoryOnly.bind(
			this
		);
		this.downloadAllMachineCategories = this.downloadAllMachineCategories.bind(
			this
		);
		this.downloadAllMachineParts = this.downloadAllMachineParts.bind(this);
	}

	async downloadAllMachineParts() {
		Axios({
			url: config.APP_URL + "/api/downloadAllMachineParts",
			method: "GET",
			responseType: "blob" // important
		})
			.then(response => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", "all_machine_parts.xlsx");
				document.body.appendChild(link);
				link.click();
				return "all_machine_parts.xlsx ready for download";
			})
			.catch(function(error) {
				return false;
			});
	}

	async downloadAllMachineCategories() {
		Axios({
			url: config.APP_URL + "/api/downloadAllMachineCategories",
			method: "GET",
			responseType: "blob" // important
		})
			.then(response => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", "all_machine_categories.xlsx");
				document.body.appendChild(link);
				link.click();
				return "all_machine_categories.xlsx ready for download";
			})
			.catch(function(error) {
				return false;
			});
	}

	onSelectValue(event) {
		this.setState({ type: event });
	}

	async downloadSpreadsheetParts() {
		await SpreadsheetService.downloadSpreadsheetForParts();
	}

	async downloadSpreadsheetCategory() {
		await SpreadsheetService.downloadSpreadsheetForType();
	}

	async downloadSpreadsheetCategoryOnly() {
		await SpreadsheetService.downloadSpreadsheetForCategoryOnly();
	}

	async generateSpreadsheet() {
		await this.setState({ loading: true });

		await SpreadsheetService.generateSpreadsheetForType();
		await SpreadsheetService.generateSpreadsheetForMachineParts();
		await SpreadsheetService.generateSpreadsheetForCategoryOnly();
		await this.setState({ loading: false });
	}

	async componentDidMount() {
		this.setState({ loading: false });
	}

	render() {
		if (this.state.loading) {
			return (
				<div>
					<div
						className="content"
						style={{
							minHeight: 500
						}}
					>
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-header">
										<strong className="card-title">
											Generate and Download Spreadsheet
										</strong>
									</div>
									<div className="card-body">
										<div className="card-content">
											<div className="todo-list">
												<div className="tdl-holder">
													<div className="tdl-content">
														<div className="row">
															<div className="col"></div>
															<div className="col">
																<div className="text-center">
																	<Loader
																		type="Rings"
																		color="#00BFFF"
																		height={100}
																		width={100}
																	/>
																	<div className="text-center client-roboto-condensed">
																		LOADING...
																	</div>
																</div>
															</div>
															<div className="col"></div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div>
					<div
						className="content"
						style={{
							minHeight: 500
						}}
					>
						<div className="row mb-4">
							<div className="col-sm-6">
								<div className="card">
									<div className="card-header">
										<strong className="card-title">
											Download machine parts excelsheets
										</strong>
									</div>
									<div className="card-body">
										<div className="card-content">
											<div className="todo-list">
												<div className="tdl-holder">
													<div className="tdl-content">
														<div className="row mb-3 text-center">
															<div className="col my-2">
																<button
																	className="btn btn-secondary"
																	onClick={
																		this
																			.downloadAllMachineParts
																	}
																	rel="noopener noreferrer"
																	target="_blank"
																	download
																>
																	Download All Machine Parts
																</button>
															</div>
															{/* <div className="col my-2">
																<a
																	className="btn btn-secondary"
																	href={
																		config.APP_URL +
																		"/api/downloadAllMachinePartsCostPrice"
																	}
																	rel="noopener noreferrer"
																	target="_blank"
																	download
																>
																	Download All Machine Parts
																	Cost Price
																</a>
															</div> */}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-sm-6">
								<div className="card">
									<div className="card-header">
										<strong className="card-title text-center">
											Download machine categories excelsheets
										</strong>
									</div>
									<div className="card-body">
										<div className="card-content">
											<div className="todo-list">
												<div className="tdl-holder">
													<div className="tdl-content">
														<div className="row mb-3 text-center">
															<div className="col my-2">
																<button
																	onClick={this.downloadAllMachineCategories}
																	rel="noopener noreferrer"
																	target="_blank"
																	download
																	className="btn btn-secondary"
																>
																	Download All Machine
																	Categories
																</button>
															</div>
															{/* <div className="col my-2">
																<a
																	href={
																		config.APP_URL +
																		"/api/downloadAllMachineCategoriesCostPrice"
																	}
																	rel="noopener noreferrer"
																	target="_blank"
																	download
																	className="btn btn-secondary"
																>
																	Download All Machine
																	Categories Cost Price
																</a>
															</div> */}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-header">
										<strong className="card-title">
											Generate and Download Spreadsheet
										</strong>
									</div>
									<div className="card-body">
										<div className="card-content">
											<div className="todo-list">
												<div className="tdl-holder">
													<div className="tdl-content">
														<div className="row">
															<div className="col"></div>
															<div className="col text-center">
																<button
																	onClick={
																		this.generateSpreadsheet
																	}
																	className="btn btn-rounded btn-secondary text-center"
																>
																	Generate Spreadsheet
																</button>
															</div>
															<div className="col"></div>
														</div>
														<div className="row m-5">
															<div className="col text-center">
																<button
																	onClick={
																		this
																			.downloadSpreadsheetCategory
																	}
																	className="btn btn-rounded btn-secondary text-center mx-2"
																>
																	Download Category with parts
																</button>

																<button
																	onClick={
																		this
																			.downloadSpreadsheetParts
																	}
																	className="btn btn-rounded btn-secondary text-center mx-2"
																>
																	Download Parts
																</button>

																<button
																	onClick={
																		this
																			.downloadSpreadsheetCategoryOnly
																	}
																	className="btn btn-rounded btn-secondary text-center mx-2"
																>
																	Download Categories Only
																</button>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}
}
