import machinePartActionTypes from "./actionTypes";

function clean_message() {
  return {
    type: machinePartActionTypes.CLEAN_MESSAGE_REQUEST,
    loading: true
  };
}

function show_machine_parts() {
  return {
    type: machinePartActionTypes.SHOW_MACHINE_PARTS_REQUEST,
    loading: true
  };
}

function add_machine_parts(params) {
  return {
    type: machinePartActionTypes.ADD_MACHINE_PARTS_REQUEST,
    loading: true,
    params: params
  };
}

function edit_machine_parts(params, id) {
  return {
    type: machinePartActionTypes.EDIT_MACHINE_PARTS_REQUEST,
    loading: true,
    params: params,
    id: id
  };
}

function edit_machine_parts_only(params, id) {
  return {
    type: machinePartActionTypes.EDIT_MACHINE_PARTS_ONLY_REQUEST,
    loading: true,
    params: params,
    id: id
  };
}

function delete_machine_parts(params) {
  return {
    type: machinePartActionTypes.DELETE_MACHINE_PARTS_REQUEST,
    loading: true,
    params: params
  };
}

function add_machine_part_data(params) {
  return {
    type: machinePartActionTypes.ADD_MACHINE_PART_DATA_REQUEST,
    loading: true,
    params: params
  };
}

export {
  edit_machine_parts_only,
  show_machine_parts,
  clean_message,
  add_machine_parts,
  edit_machine_parts,
  delete_machine_parts,
  add_machine_part_data
};
