import React, { Component } from "react";
import { connect } from "react-redux";
import { show_single_user_configuration } from "./../action";
import { Link } from "react-router-dom";
import noimage from "./../../../CPQ/noimage.png";
import { css } from "@emotion/react";
import { PulseLoader } from "react-spinners";
import { Api } from "../../../config/api";
import { Redirect } from "react-router-dom";
import strings from "./../../../config/language";
import config from "./../../../config/config";
import Loader from "react-loader-spinner";
import CurrencyFormat from "react-currency-format";
import getSymbolFromCurrency from "currency-symbol-map";
import { Modal, notification, Descriptions } from "antd";
import SimpleReactValidator from "simple-react-validator";
import LoadingOverlay from "react-loading-overlay";
import Swal from "sweetalert2";
class MyDetailedConfiguration extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			visible: false,
			configuration: [],
			parts: [],
			id: [],
			ordered: false,
			delete: false,
			category_details: this.props.category_details
				? this.props.category_details
				: false,
			requested_discount_percentage: 0,
			requested_comment: "",
			disable_while_submitting: false
		};
		this.validator = new SimpleReactValidator({ autoForceUpdate: this });
		this.deleteConfiguration = this.deleteConfiguration.bind(this);
		this.submitDiscountRequest = this.submitDiscountRequest.bind(this);
		this.showModal = this.showModal.bind(this);
		this.handleOk = this.handleOk.bind(this);
		this.handleCancel = this.handleCancel.bind(this);
	}

	showModal = () => {
		this.setState({
			visible: true
		});
	};

	handleOk = (e, id) => {
		this.setState({
			visible: false
		});
	};

	handleCancel = e => {
		this.setState({ requested_discount_percentage: 0 });
		this.setState({ requested_comment: "" });
		this.setState({
			visible: false
		});
	};

	handleTextChange(name, value) {
		let that = this;
		switch (name) {
			case "requested_discount_percentage":
				if (value < 100 && !isNaN(value)) {
					that.setState({ requested_discount_percentage: value });
				}
				break;

			case "requested_comment":
				that.setState({ requested_comment: value });
				break;

			default:
				break;
		}
	}

	async submitDiscountRequest(e) {
		e.preventDefault();
		this.setState({ disable_while_submitting: true })
		let that = this;
		let params = {
			requested_discount_percentage: this.state
				.requested_discount_percentage,
			requested_comment: that.state.requested_comment,
			requested_configuration_id: that.props.match.params.id
		};

		if (this.validator.allValid()) {
			await Api.post(
				"discount-notification/requestDiscountNotification/" +
				this.props.user_id,
				params
			)
				.then(function (response) {
					Swal.fire({
						title: "Discount Requested",
						text: "Discount requested successfully",
						icon: "success",
						confirmButtonText: "OK"
					});
					that.setState({
						visible: false
					});
				})
				.catch(function (error) {
					notification.error({
						message: "Error!",
						description: "Discount request notification failed."
					});
				});
		} else {
			this.validator.showMessages();
		}
		this.setState({ disable_while_submitting: false })
	}

	async componentDidMount() {
		let that = this;

		let { id } = that.props.match.params;
		that.setState({ id: id });
		await that.props.show_single_user_configuration(
			that.props.user_id,
			id,
			this.props.is_support
		);

		await Api.get("configuration/checkIfConfigurationIsOrderd/" + id).then(
			function (response) {
				if (response.data.message.toLowerCase().includes("not")) {
					that.setState({ ordered: false });
				} else {
					that.setState({ ordered: true });
				}
			}
		);
		await that.setState({ loading: false });
	}

	async deleteConfiguration(id) {
		// alert(this.props.configuration[0].quote_id !== null);
		let that = this;
		if (
			this.props.configuration[0].quote_id !== "" &&
			this.props.configuration[0].quote_id !== null
		) {
			alert(
				"This configuration is already used in a quote so you cannot delete it."
			);
		} else {
			let confirmation = window.confirm("Are you sure?");
			if (confirmation) {
				await Api.get("/configuration/delete/" + id)
					.then(function (response) {
						that.setState({ delete: true });
					})
					.catch(function (error) { });
			}
		}
	}

	render() {
		strings.setLanguage(this.props.language);
		const override = css`
			display: block;
			margin: 0 auto;
			border-color: red;
		`;
		if (this.state.delete) {
			return <Redirect to="/configurations" />;
		} else {
			if (this.props.loginLoading) {
				return (
					<div className="sweet-loading">
						<div className="container loading-container">
							<div className="row m-5 p-5">
								<PulseLoader
									css={override}
									sizeUnit={"px"}
									size={24}
									color={"#B9373D"}
								/>
							</div>
						</div>
					</div>
				);
			} else {
				return this.props.configuration &&
					!this.props.configuration_loading ? (
					<div>
						<Modal
							title="Discount Request"
							visible={this.state.visible}
							onOk={this.handleOk}
							onCancel={this.handleCancel}
							footer={null}
						>
							<LoadingOverlay
								active={this.state.disable_while_submitting}
								spinner
								text='Submitting...'
							>
								<div className="row">
									<div className="col"></div>
									<div className="m-3 col-6">
										<div className="col-12">
											<div className="form-group">
												<label
													htmlFor="requested_discount_percentage"
													className="control-label mb-1"
												>
													Discount Percentage
										</label>
												<input
													id="requested_discount_percentage"
													name="requested_discount_percentage"
													type="text"
													className="form-control"
													onChange={e =>
														this.handleTextChange(
															"requested_discount_percentage",
															e.target.value
														)
													}
													value={
														this.state.requested_discount_percentage
													}
													aria-required="true"
												/>
												<div
													className="mt-4"
													style={{
														color: "red"
													}}
												>
													{this.validator.message(
														"requested_discount_percentage",
														this.state.requested_discount_percentage,
														"required|numeric"
													)}
												</div>
											</div>
										</div>
										<div className="col-12">
											<div className="form-group">
												<label
													htmlFor="requested_comment"
													className="control-label mb-1"
												>
													Comment
										</label>
												<textarea
													rows={6}
													id="requested_comment"
													name="requested_comment"
													type="text"
													className="form-control"
													onChange={e =>
														this.handleTextChange(
															"requested_comment",
															e.target.value
														)
													}
													value={this.state.requested_comment}
													aria-required="true"
												/>
												<div
													className="mt-4"
													style={{
														color: "red"
													}}
												>
													{this.validator.message(
														"requested_comment",
														this.state.requested_comment,
														"string"
													)}
												</div>
											</div>
										</div>

										<div className="col text-center">
											<button
												onClick={this.submitDiscountRequest}
												id="payment-button"
												type="submit"
												className="btn btn-secondary btn-block text-center"
											>
												<span id="payment-button-amount">
													Request Discount
										</span>
											</button>
										</div>
									</div>
									<div className="col"></div></div>
							</LoadingOverlay>
						</Modal>
						<div className="my-4 client-roboto-condensed container">
							<div className="text-center">
								<h4
									className="my-5"
									style={{
										fontWeight: 900
									}}
								>
									Configuration Details
								</h4>
							</div>
							<div className="row my-5">
								<div className="col-sm-4">
									<div className="">
										{this.props.configuration[0]
											.machine_category_image !== [] &&
											this.props.configuration[0]
												.machine_category_image !== null ? (
											<img loading="lazy"
												width="70%"
												height="70%"
												className="img-responsive"
												alt=""
												src={
													config.APP_URL +
													"/api/storage/" +
													this.props.configuration[0]
														.machine_category_image
												}
											/>
										) : (
											<img loading="lazy"
												width="70%"
												height="70%"
												className="img-responsive"
												alt=""
												src={noimage}
											/>
										)}
									</div>
								</div>
								<div className="col-sm-8">
									<div className="client-config-details ml-5 mr-5 mb-5">
										<Descriptions
											title=""
											layout="hotizontal"
											bordered
											size="small"
											column={1}
										>
											<Descriptions.Item label="Configuration Name">
												{this.props.configuration !== []
													? this.props.configuration[0].name
													: ""}
											</Descriptions.Item>
											<Descriptions.Item label="Product Category">
												{this.props.configuration !== []
													? this.props.configuration[0]
														.machine_category
													: ""}
											</Descriptions.Item>
											<Descriptions.Item label="Retail Price">
												{this.props.configuration !== []
													? parseFloat(
														this.props.configuration[0]
															.machine_total_price /
														this.props.configuration[0]
															.quantity
													).toFixed(2)
													: ""}{" "}
												{getSymbolFromCurrency(
													this.props.country[0]
														.currency_symbol_html
												)}
											</Descriptions.Item>
											{this.props.configuration !== [] &&
												this.props.configuration[0]
													.discount_percentage !== 0 ? (
												this.props.is_dealer ? (
													<Descriptions.Item label="Standard Dealer Terms %">
														{
															this.props.configuration[0]
																.discount_percentage
														}{" "}
														%
													</Descriptions.Item>
												) : (
													<Descriptions.Item label="Discount Percentage">
														{
															this.props.configuration[0]
																.discount_percentage
														}{" "}
														%
													</Descriptions.Item>
												)
											) : null}
											{this.props.configuration !== [] &&
												this.props.configuration[0].discount_amount !==
												0 ? (
												this.props.is_dealer ? (
													<Descriptions.Item label="Dealer Discount Amount">
														{
															this.props.configuration[0]
																.discount_amount
														}{" "}
														{getSymbolFromCurrency(
															this.props.configuration[0]
																.currency_symbol_html
														)}
													</Descriptions.Item>
												) : (
													<Descriptions.Item label="Discount Amount">
														{
															this.props.configuration[0]
																.discount_amount
														}{" "}
														{getSymbolFromCurrency(
															this.props.configuration[0]
																.currency_symbol_html
														)}
													</Descriptions.Item>
												)
											) : null}

											{this.props.configuration !== [] &&
												this.props.configuration[0]
													.dealer_discount_amount !== 0 ? (
												this.props.extra_discount_visible ? (
													<Descriptions.Item label="Extra Discount">
														{
															this.props.configuration[0]
																.dealer_discount_amount
														}{" "}
														{getSymbolFromCurrency(
															this.props.configuration[0]
																.currency_symbol_html
														)}
													</Descriptions.Item>
												) : null
											) : null}
											{this.props.is_dealer ? (
												<Descriptions.Item label="Dealer Net Total">
													{this.props.configuration !== [] ? (
														<CurrencyFormat
															className="py-1 pr-2 text-right"
															displayType={"text"}
															decimalSeparator={"."}
															decimalScale={2}
															fixedDecimalScale={true}
															value={parseFloat(
																this.props.configuration[0]
																	.machine_total_price /
																this.props.configuration[0]
																	.quantity -
																(this.props.configuration[0]
																	.discount_percentage *
																	(this.props
																		.configuration[0]
																		.machine_total_price /
																		this.props
																			.configuration[0]
																			.quantity)) /
																100 -
																this.props.configuration[0]
																	.discount_amount -
																this.props.configuration[0]
																	.dealer_discount_amount
															)}
															thousandSeparator={true}
															suffix={
																" " +
																getSymbolFromCurrency(
																	this.props.configuration[0]
																		.currency_symbol_html
																)
															}
														/>
													) : null}
												</Descriptions.Item>
											) : (
												<Descriptions.Item label="Net Total">
													{this.props.configuration !== [] ? (
														<CurrencyFormat
															className="py-1 pr-2 text-right"
															displayType={"text"}
															decimalSeparator={"."}
															decimalScale={2}
															fixedDecimalScale={true}
															value={parseFloat(
																this.props.configuration[0]
																	.machine_total_price /
																this.props.configuration[0]
																	.quantity -
																(this.props.configuration[0]
																	.discount_percentage *
																	(this.props
																		.configuration[0]
																		.machine_total_price /
																		this.props
																			.configuration[0]
																			.quantity)) /
																100 -
																this.props.configuration[0]
																	.discount_amount -
																this.props.configuration[0]
																	.dealer_discount_amount
															)}
															thousandSeparator={true}
															suffix={
																" " +
																getSymbolFromCurrency(
																	this.props.configuration[0]
																		.currency_symbol_html
																)
															}
														/>
													) : null}
												</Descriptions.Item>
											)}

											{this.props.is_dealer ? (
												<>
													<Descriptions.Item label="OEM Programme">
														{
															this.props.configuration[0]
																.oem_discount
														}
														{" " +
															getSymbolFromCurrency(
																this.props.configuration[0]
																	.currency_symbol_html
															)}
													</Descriptions.Item>
													<Descriptions.Item label="Net Net Purchase Price">
														<CurrencyFormat
															className="py-1 pr-2 text-right"
															displayType={"text"}
															decimalSeparator={"."}
															decimalScale={2}
															fixedDecimalScale={true}
															value={parseFloat(
																this.props.configuration[0]
																	.machine_total_price /
																this.props.configuration[0]
																	.quantity -
																(this.props.configuration[0]
																	.discount_percentage *
																	(this.props
																		.configuration[0]
																		.machine_total_price /
																		this.props
																			.configuration[0]
																			.quantity)) /
																100 -
																this.props.configuration[0]
																	.discount_amount -
																this.props.configuration[0]
																	.dealer_discount_amount -
																this.props.configuration[0]
																	.oem_discount
															).toFixed(2)}
															thousandSeparator={true}
															suffix={
																" " +
																getSymbolFromCurrency(
																	this.props.configuration[0]
																		.currency_symbol_html
																)
															}
														/>
													</Descriptions.Item>
												</>
											) : null}
											{this.props.is_dealer ? (
												<>
													<Descriptions.Item label="Extra Discount">
														{
															this.props.configuration[0]
																.extra_discount
														}
														{" %"}
													</Descriptions.Item>
													<Descriptions.Item label="Reesink Extra Extra Support">
														<CurrencyFormat
															className="py-1 pr-2 text-right"
															displayType={"text"}
															decimalSeparator={"."}
															decimalScale={2}
															fixedDecimalScale={true}
															value={parseFloat(
																(this.props.configuration[0]
																	.extra_discount *
																	parseFloat(
																		this.props
																			.configuration[0]
																			.machine_total_price /
																		this.props
																			.configuration[0]
																			.quantity -
																		(this.props
																			.configuration[0]
																			.discount_percentage *
																			(this.props
																				.configuration[0]
																				.machine_total_price /
																				this.props
																					.configuration[0]
																					.quantity)) /
																		100 -
																		this.props
																			.configuration[0]
																			.discount_amount -
																		this.props
																			.configuration[0]
																			.dealer_discount_amount -
																		this.props
																			.configuration[0]
																			.oem_discount
																	).toFixed(2)) /
																100
															).toFixed(2)}
															thousandSeparator={true}
															suffix={
																" " +
																getSymbolFromCurrency(
																	this.props.configuration[0]
																		.currency_symbol_html
																)
															}
														/>
													</Descriptions.Item>
												</>
											) : null}
											{this.props.is_dealer ? (
												<Descriptions.Item label="Net Net Net Price">
													<CurrencyFormat
														className="py-1 pr-2 text-right"
														displayType={"text"}
														decimalSeparator={"."}
														decimalScale={2}
														fixedDecimalScale={true}
														value={parseFloat(
															this.props.configuration[0]
																.machine_total_price /
															this.props.configuration[0]
																.quantity -
															(this.props.configuration[0]
																.discount_percentage *
																(this.props.configuration[0]
																	.machine_total_price /
																	this.props
																		.configuration[0]
																		.quantity)) /
															100 -
															this.props.configuration[0]
																.discount_amount -
															this.props.configuration[0]
																.dealer_discount_amount -
															this.props.configuration[0]
																.oem_discount -
															parseFloat(
																(this.props.configuration[0]
																	.extra_discount *
																	parseFloat(
																		this.props
																			.configuration[0]
																			.machine_total_price /
																		this.props
																			.configuration[0]
																			.quantity -
																		(this.props
																			.configuration[0]
																			.discount_percentage *
																			(this.props
																				.configuration[0]
																				.machine_total_price /
																				this.props
																					.configuration[0]
																					.quantity)) /
																		100 -
																		this.props
																			.configuration[0]
																			.discount_amount -
																		this.props
																			.configuration[0]
																			.dealer_discount_amount -
																		this.props
																			.configuration[0]
																			.oem_discount
																	).toFixed(2)) /
																100
															).toFixed(2)
														).toFixed(2)}
														thousandSeparator={true}
														suffix={
															" " +
															getSymbolFromCurrency(
																this.props.configuration[0]
																	.currency_symbol_html
															)
														}
													/>
												</Descriptions.Item>
											) :
												null}
											{this.props.configuration !== [] &&
												this.props.configuration[0].sales_margin !==
												0 ? (
												this.props.sales_margin_visible ? (
													this.props.is_dealer ? (
														<>
															<Descriptions.Item label="End Customer Purchase Price">
																{parseFloat(
																	this.props.configuration[0]
																		.end_user_sales_price
																).toFixed(2)}{" "}
																{getSymbolFromCurrency(
																	this.props.country[0]
																		.currency_symbol_html
																)}
															</Descriptions.Item>
															<Descriptions.Item label="Dealer Margin Amount">
																<CurrencyFormat
																	className="py-1 pr-2 text-right"
																	displayType={"text"}
																	decimalSeparator={"."}
																	decimalScale={2}
																	fixedDecimalScale={true}
																	value={
																		this.props
																			.configuration[0]
																			.sales_margin_amount
																	}
																	thousandSeparator={true}
																	suffix={
																		" " +
																		getSymbolFromCurrency(
																			this.props
																				.configuration[0]
																				.currency_symbol_html
																		)
																	}
																/>
															</Descriptions.Item>
															<Descriptions.Item label="Dealer Margin">
																{
																	this.props.configuration[0]
																		.sales_margin
																}
																{" %"}
															</Descriptions.Item>
														</>
													) : (
														<>
															<Descriptions.Item label="Sales Margin">
																{
																	this.props.configuration[0]
																		.sales_margin
																}
																{" %"}
															</Descriptions.Item>
															<Descriptions.Item label="Total Invoice for customer">
																{parseFloat(
																	this.props.configuration[0]
																		.end_user_sales_price
																).toFixed(2)}{" "}
																{getSymbolFromCurrency(
																	this.props.country[0]
																		.currency_symbol_html
																)}
															</Descriptions.Item>
														</>
													)
												) : null
											) : null}
											{this.props.customer_discount_visible &&
												!this.props.is_dealer ? (
												<Descriptions.Item label="Customer Discount">
													{parseFloat(
														(Math.abs(
															this.props.configuration[0]
																.machine_total_price -
															parseFloat(
																this.props.configuration[0]
																	.machine_total_price -
																(this.props
																	.configuration[0]
																	.machine_total_price *
																	this.props
																		.configuration[0]
																		.discount_percentage) /
																100 -
																this.props
																	.configuration[0]
																	.discount_amount -
																this.props
																	.configuration[0]
																	.dealer_discount_amount -
																this.props
																	.configuration[0]
																	.oem_discount -
																parseFloat(
																	(this.props
																		.configuration[0]
																		.extra_discount *
																		parseFloat(
																			this.props
																				.configuration[0]
																				.machine_total_price /
																			this.props
																				.configuration[0]
																				.quantity -
																			(this.props
																				.configuration[0]
																				.discount_percentage *
																				(this.props
																					.configuration[0]
																					.machine_total_price /
																					this
																						.props
																						.configuration[0]
																						.quantity)) /
																			100 -
																			this.props
																				.configuration[0]
																				.discount_amount -
																			this.props
																				.configuration[0]
																				.dealer_discount_amount -
																			this.props
																				.configuration[0]
																				.oem_discount
																		).toFixed(2)) /
																	100
																).toFixed(2) +
																(this.props
																	.configuration[0]
																	.sales_margin *
																	(this.props
																		.configuration[0]
																		.machine_total_price -
																		(this.props
																			.configuration[0]
																			.machine_total_price *
																			this.props
																				.configuration[0]
																				.discount_percentage) /
																		100 -
																		this.props
																			.configuration[0]
																			.discount_amount -
																		this.props
																			.configuration[0]
																			.dealer_discount_amount -
																		this.props
																			.configuration[0]
																			.oem_discount -
																		parseFloat(
																			(this.props
																				.configuration[0]
																				.extra_discount *
																				parseFloat(
																					this.props
																						.configuration[0]
																						.machine_total_price /
																					this
																						.props
																						.configuration[0]
																						.quantity -
																					(this
																						.props
																						.configuration[0]
																						.discount_percentage *
																						(this
																							.props
																							.configuration[0]
																							.machine_total_price /
																							this
																								.props
																								.configuration[0]
																								.quantity)) /
																					100 -
																					this
																						.props
																						.configuration[0]
																						.discount_amount -
																					this
																						.props
																						.configuration[0]
																						.dealer_discount_amount -
																					this
																						.props
																						.configuration[0]
																						.oem_discount
																				).toFixed(
																					2
																				)) /
																			100
																		).toFixed(2))) /
																100
															).toFixed(2)
														) /
															this.props.configuration[0]
																.machine_total_price) *
														100
													).toFixed(2)}
													{" %"}
												</Descriptions.Item>
											) : null}
											{this.props.configuration !== [] &&
												this.props.configuration[0].quantity !== 0 ? (
												<Descriptions.Item label="Configuration Quantity">
													{this.props.configuration[0].quantity}
												</Descriptions.Item>
											) : null}
											<Descriptions.Item label="Final Net total">
												{this.props.configuration !== [] ? (
													<CurrencyFormat
														className="py-1 pr-2 text-right"
														displayType={"text"}
														decimalSeparator={"."}
														decimalScale={2}
														fixedDecimalScale={true}
														value={
															parseFloat(
																this.props.configuration[0]
																	.machine_total_price /
																this.props.configuration[0]
																	.quantity -
																(this.props.configuration[0]
																	.discount_percentage *
																	(this.props
																		.configuration[0]
																		.machine_total_price /
																		this.props
																			.configuration[0]
																			.quantity)) /
																100 -
																this.props.configuration[0]
																	.discount_amount -
																this.props.configuration[0]
																	.dealer_discount_amount -
																this.props.configuration[0]
																	.oem_discount -
																parseFloat(
																	(this.props
																		.configuration[0]
																		.extra_discount *
																		parseFloat(
																			this.props
																				.configuration[0]
																				.machine_total_price /
																			this.props
																				.configuration[0]
																				.quantity -
																			(this.props
																				.configuration[0]
																				.discount_percentage *
																				(this.props
																					.configuration[0]
																					.machine_total_price /
																					this.props
																						.configuration[0]
																						.quantity)) /
																			100 -
																			this.props
																				.configuration[0]
																				.discount_amount -
																			this.props
																				.configuration[0]
																				.dealer_discount_amount -
																			this.props
																				.configuration[0]
																				.oem_discount
																		).toFixed(2)) /
																	100
																).toFixed(2)
															) *
															this.props.configuration[0]
																.quantity
														}
														thousandSeparator={true}
														suffix={
															" " +
															getSymbolFromCurrency(
																this.props.configuration[0]
																	.currency_symbol_html
															)
														}
													/>
												) : null}
											</Descriptions.Item>
											<Descriptions.Item label="Parts Exchange [trade - in]">
												{this.props.configuration[0].trade_in_value}
											</Descriptions.Item>
											<Descriptions.Item label="Price for the customer [sales price - part exchange]">
												{this.props.configuration !== [] ? (
													<CurrencyFormat
														className="py-1 pr-2 text-right"
														displayType={"text"}
														decimalSeparator={"."}
														decimalScale={2}
														fixedDecimalScale={true}
														value={
															parseFloat(
																this.props.configuration[0]
																	.machine_total_price /
																this.props.configuration[0]
																	.quantity -
																(this.props.configuration[0]
																		.discount_percentage *
																	(this.props
																			.configuration[0]
																			.machine_total_price /
																		this.props
																			.configuration[0]
																			.quantity)) /
																100 -
																this.props.configuration[0]
																	.discount_amount -
																this.props.configuration[0]
																	.dealer_discount_amount -
																this.props.configuration[0]
																	.oem_discount -
																parseFloat(
																	(this.props
																			.configuration[0]
																			.extra_discount *
																		parseFloat(
																			this.props
																				.configuration[0]
																				.machine_total_price /
																			this.props
																				.configuration[0]
																				.quantity -
																			(this.props
																					.configuration[0]
																					.discount_percentage *
																				(this.props
																						.configuration[0]
																						.machine_total_price /
																					this.props
																						.configuration[0]
																						.quantity)) /
																			100 -
																			this.props
																				.configuration[0]
																				.discount_amount -
																			this.props
																				.configuration[0]
																				.dealer_discount_amount -
																			this.props
																				.configuration[0]
																				.oem_discount
																		).toFixed(2)) /
																	100
																).toFixed(2)
															) *
															this.props.configuration[0]
																.quantity - this.props.configuration[0].trade_in_value
														}
														thousandSeparator={true}
														suffix={
															" " +
															getSymbolFromCurrency(
																this.props.configuration[0]
																	.currency_symbol_html
															)
														}
													/>
												) : null}
											</Descriptions.Item>
										</Descriptions>
									</div>
								</div>
							</div>
							<div className="row my-5">
								<div className="client-selected-parts">
									<table className="table table-striped table-responsive-md">
										<thead className="thead-dark">
											<tr>
												<th scope="col" className="min-width-table">
													Model Number
												</th>
												<th scope="col" className="min-width-table">
													Description
												</th>
												<th scope="col" className="min-width-table">
													Remarks
												</th>
												<th scope="col" className="min-width-table">
													Note
												</th>
												<th scope="col" className="min-width-table">
													Price
												</th>
												<th scope="col" className="min-width-table">
													Quantity
												</th>
												<th scope="col" className="min-width-table">
													Total Price
												</th>
											</tr>
										</thead>
										<tbody>
											<tr
												style={{
													backgroundColor: "#B9373D",
													color: "#ffffff"
												}}
											>
												<td
													style={{
														color: "white",
														fontWeight: 900,
														fontSize: 15
													}}
												>
													Components
												</td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
											</tr>
											<tr>
												{this.props.loading ? null : (
													<>
														<td className="min-width-table">
															{this.props.category_details &&
																this.props.category_details !==
																null
																? this.props.category_details[0]
																	.machine_category_model_number
																: "#"}
														</td>
														<td className="min-width-table">
															{this.props.category_details
																? this.props.category_details[0]
																	.machine_category
																: null}
														</td>
														<td className="min-width-table"></td>
														<td className="min-width-table"></td>
														<td className="money min-width-table">
															{getSymbolFromCurrency(
																this.props.category_details[0]
																	.currency_symbol_html
															)}{" "}
															{this.props.category_details
																? parseFloat(
																	this.props
																		.category_details[0]
																		.price
																).toFixed(2)
																: null}
														</td>
														<td>{this.props.first_form ?
															this.props.first_form.only_parts ? 0 : 1 : 1}</td>
														<td className="money">
															{getSymbolFromCurrency(
																this.props.category_details[0]
																	.currency_symbol_html
															)}{" "}

															{
																this.state.category_details ?
																	this.props.first_form ?
																		this.props.first_form.only_parts ? 0 : parseFloat(this.props.category_details[0].price).toFixed(2) : parseFloat(this.props.category_details[0].price).toFixed(2)
																	: null
															}
															{/* {this.props.category_details
																	? parseFloat(
																		this.props
																			.category_details[0]
																			.price
																	)
																	: null} */}
														</td>
													</>
												)}
											</tr>
											<tr
												style={{
													backgroundColor: "#B9373D",
													color: "#ffffff"
												}}
											>
												<td
													style={{
														color: "white",
														fontWeight: 900,
														fontSize: 15
													}}
												>
													Parts
												</td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
											</tr>
											{this.props.parts !== []
												? this.props.parts.map((part, i) => (
													<tr key={i}>
														<td className="min-width-table">
															{part.machine_part_model_number}
														</td>
														<td className="min-width-table">
															{part.machine_part_name}
														</td>
														<td className="min-width-table">
															{part.custom_remark === "" ||
																part.custom_remark === null
																? part.machine_part_remarks !==
																	null
																	? part.machine_part_remarks
																	: null
																: part.custom_remark}
														</td>
														<td className="min-width-table">
															{part.custom_note === "" ||
																part.custom_note === null
																? part.machine_part_note !==
																	null
																	? part.machine_part_note
																	: null
																: part.custom_note}
														</td>
														{part.is_included ? (
															<>
																<td className="min-width-table">
																	Included
																	</td>
																<td className="min-width-table">
																	{part.quantity}
																</td>
																<td className="min-width-table">
																	Included
																	</td>
															</>
														) : (
															<>
																<td className="min-width-table">
																	{getSymbolFromCurrency(
																		part.currency_symbol_html
																	)}{" "}
																	{part.price.toFixed(2)}
																</td>
																<td className="min-width-table">
																	{part.quantity}
																</td>
																<td className="min-width-table">
																	{getSymbolFromCurrency(
																		part.currency_symbol_html
																	)}{" "}
																	{(
																		part.quantity *
																		part.price
																	).toFixed(2)}
																</td>
															</>
														)}
													</tr>
												))
												: null}
										</tbody>
									</table>

									<table className="table table-striped table-responsive-md mt-5">
										<tbody>
											{this.props.manual_adjustments.length > 0 ? (
												<>
													<tr
														style={{
															backgroundColor: "#B9373D",
															color: "#ffffff"
														}}
													>
														<td
															style={{
																color: "white",
																fontWeight: 900,
																fontSize: 15
															}}
														>
															Manual Adjustments
														</td>
														<td></td>
														<td></td>
														<td></td>
														<td></td>
														<td></td>
													</tr>
													<tr className="thead-dark">
														<th scope="col">Model Number</th>
														<th scope="col">Description</th>
														<th scope="col">Note</th>
														<th scope="col">Price</th>
														<th scope="col">Quantity</th>
														<th scope="col">Total Price</th>
													</tr>
												</>
											) : null}
											{this.props.manual_adjustments !== null &&
												this.props.manual_adjustments !== []
												? this.props.manual_adjustments.map(
													(adjustment, i) => (
														<tr key={i}>
															<td className="min-width-table">
																{" "}
																{
																	adjustment.manual_adjustment_model_number
																}
															</td>
															<td className="min-width-table">
																{
																	adjustment.manual_adjustment_name
																}
															</td>
															<td className="min-width-table">
																{
																	adjustment.manual_adjustment_note
																}
															</td>
															<td className="money min-width-table">
																{getSymbolFromCurrency(
																	adjustment.currency_symbol_html
																)}{" "}
																{parseFloat(
																	adjustment.manual_adjustment_price
																)}
															</td>
															<td>
																<div className="client-counter row">
																	{" "}
																	{
																		adjustment.manual_adjustment_quantity
																	}
																</div>
															</td>
															<td className="money">
																{getSymbolFromCurrency(
																	adjustment.currency_symbol_html
																)}
																{(
																	parseFloat(
																		adjustment.manual_adjustment_quantity
																	) *
																	parseFloat(
																		adjustment.manual_adjustment_price
																	)
																).toFixed(2)}
															</td>
														</tr>
													)
												)
												: null}
										</tbody>
									</table>
								</div>
							</div>
							<div className="row">
								{!this.state.ordered ? (
									<div className="col">
										<Link
											to={
												"/edit-configuration-process/" +
												this.state.id
											}
											className="btn client-login m-2 px-5"
										>
											Edit Configuration
										</Link>
									</div>
								) : null}
								{this.props.lease_process_visible ? (
									<div className="col">
										<Link
											to={"/lease-calculation/" + this.state.id}
											className="btn client-login m-2"
										>
											Lease Calculation
										</Link>
									</div>
								) : null} 
								
								<div className="col">
									<Link
										to={"/configuration-process/" + this.state.id}
										className="btn client-login m-2 px-5"
									>
										Duplicate and Edit
									</Link>
								</div>
								<div className="col">
									<Link
										to="#"
										onClick={() =>
											this.deleteConfiguration(this.state.id)
										}
										className="btn client-login m-2 px-5"
									>
										Delete Configuration
									</Link>
								</div>
								{this.props.higher_discount_request ? (
									<div className="col">
										<button
											onClick={this.showModal}
											className="btn client-login m-2 px-5"
										>
											Higher Discount Request
										</button>
									</div>
								) : null}
							</div>
						</div>
					</div>
				) : (
					<>
						<div
							className="modal-body"
							style={{
								justifyContent: "center"
							}}
						>
							<div
								className="row my-5"
								style={{
									justifyContent: "center",
									flexDirection: "row"
								}}
							>
								{this.props.configuration_message.includes(
									"Getting single user configuration faliure"
								) ? (
									<strong>
										Either there is something wrong with the configuration (can be deleted by the user) or you don't have proper authority to access this configuration, please contact the administrator. Then we will try to fix it.
									</strong>
								) : (
									<Loader
										type="Rings"
										color="#00BFFF"
										height={100}
										width={100}
									/>
								)}
							</div>
						</div>
					</>
				);
			}
		}
	}
}
const mapStateToProps = state => {
	return {
		first_form: state.clientConfigurationReducer.first_form,
		user_id: state.loginReducer.user.user.id,
		role: state.loginReducer.user.role,
		country: state.loginReducer.user.country,
		configuration:
			state.clientConfigurationReducer.configuration.configuration,
		loading: state.clientConfigurationReducer.loading,
		parts: state.clientConfigurationReducer.configuration.parts,
		manual_adjustments:
			state.clientConfigurationReducer.configuration.manual_adjustments,
		category_details: state.clientConfigurationReducer.category_details,
		loginLoading: state.loginReducer.loading,
		language: state.languageReducer.selectedLanguage,
		configuration_loading: state.clientConfigurationReducer.loading,
		configuration_message: state.clientConfigurationReducer.message,
		lease_process_visible: state.loginReducer.user.lease_process_visible,
		is_support: state.loginReducer.user.is_support,
		higher_discount_request: state.loginReducer.user.higher_discount_request,
		is_dealer: state.loginReducer.user.is_dealer,
		sales_margin_visible: state.loginReducer.user.sales_margin_visible,
		customer_discount_visible:
			state.loginReducer.user.customer_discount_visible,
		extra_discount_visible: state.loginReducer.user.extra_discount_visible,
		extra_discount_visible_and_editable:
			state.loginReducer.user.extra_discount_visible_and_editable,
		oem_editable: state.loginReducer.user.oem_editable,
		extra_extra_discount_editable:
			state.loginReducer.user.extra_extra_discount_editable
	};
};

export default connect(mapStateToProps, {
	show_single_user_configuration
})(MyDetailedConfiguration);
