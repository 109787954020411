import specificationActionTypes from "./actionTypes";
const INITIAL_STATE = {
  message: "",
  loading: false,
  specifications: [],
  specification: []
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case specificationActionTypes.SHOW_SPECIFICATION_FOR_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case specificationActionTypes.SHOW_SPECIFICATION_FOR_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
        specifications: action.specifications
      };
    case specificationActionTypes.SHOW_SPECIFICATION_FOR_CATEGORY_FALIURE:
      return {
        ...state,
        loading: false,
        message: action.message
      };

    case specificationActionTypes.SHOW_SINGLE_SPECIFICATION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case specificationActionTypes.SHOW_SINGLE_SPECIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
        specification: action.specification
      };
    case specificationActionTypes.SHOW_SINGLE_SPECIFICATION_FALIURE:
      return {
        ...state,
        loading: false,
        message: action.message
      };

    case specificationActionTypes.ADD_SPECIFICATION_FOR_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case specificationActionTypes.ADD_SPECIFICATION_FOR_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message
      };
    case specificationActionTypes.ADD_SPECIFICATION_FOR_CATEGORY_FALIURE:
      return {
        ...state,
        loading: false,
        message: action.message
      };

    case specificationActionTypes.UPDATE_SPECIFICATION_FOR_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case specificationActionTypes.UPDATE_SPECIFICATION_FOR_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message
      };
    case specificationActionTypes.UPDATE_SPECIFICATION_FOR_CATEGORY_FALIURE:
      return {
        ...state,
        loading: false,
        message: action.message
      };

    case specificationActionTypes.DELETE_SPECIFICATION_FOR_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case specificationActionTypes.DELETE_SPECIFICATION_FOR_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message
      };
    case specificationActionTypes.DELETE_SPECIFICATION_FOR_CATEGORY_FALIURE:
      return {
        ...state,
        loading: false,
        message: action.message
      };

    case specificationActionTypes.CLEAN_MESSAGE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case specificationActionTypes.CLEAN_MESSAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        message: ""
      };
    case specificationActionTypes.CLEAN_MESSAGE_FALIURE:
      return {
        ...state,
        loading: false
      };

    default:
      return state;
  }
}
