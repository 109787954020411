import React, { Component } from "react";
import { connect } from "react-redux";
import { show_user_configurations } from "./../../configuration/action";
import { show_quotes } from "./../../quotes/action";
import { show_orders } from "./../../order/action";
import { getUser } from './../../../login/action';
// import { Link } from "react-router-dom";
import { css } from "@emotion/react";
import { PulseLoader } from "react-spinners";
import { Descriptions } from "antd";
import { Api } from "../../../config/api";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: this.props.user !== [] ? this.props.user : false,
      loading: false
    };
    this.handleSubscribe = this.handleSubscribe.bind(this);
  }

  async handleSubscribe() {
    let that = this;
    await Api.get('/auth/subscribe/' + this.state.user.id).then(async function (response) {

      let qw = localStorage.getItem("AppTree");
      if (qw && qw !== null && qw !== undefined && qw !== "") {
        let token = JSON.parse(qw).loginReducer.token;
        if (token && token !== "" && token !== null && token !== undefined) {
          await that.props.getUser(token);
        }
      }
    })
  }

  async componentDidMount() {
    let that = this;
    await that.setState({ loading: true });
  }

  render() {
    const override = css`
      display: block;
      margin: 0 auto;
      border-color: red;
    `;
    if (
      !this.state.user ||
      !this.state.loading ||
      this.props.configurationLoading ||
      this.props.quoteLoading ||
      this.props.orderLoading ||
      this.props.loginLoading
    ) {
      return (
        <div className="sweet-loading">
          <div className="container loading-container">
            <div className="row m-5 p-5">
              <PulseLoader
                css={override}
                sizeUnit={"px"}
                size={24}
                color={"#B9373D"}
              />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div className="mt-5 mb-5 client-roboto-condensed container">
            <div className="text-center">
              <h4>User Details</h4>
            </div>
            <div className="row m-5">
              <div className="col-sm-12">
                <div className="client-config-details">
                  <Descriptions
                    title="User Details"
                    layout="hotizontal"
                    bordered
                    size="small"
                    column={1}>
                    <Descriptions.Item label="User Name">
                      {this.state.user.name}
                    </Descriptions.Item>
                    <Descriptions.Item label="User Email">
                      {this.state.user.email}
                    </Descriptions.Item>
                    <Descriptions.Item label="User Type">
                      {this.props.userRole}
                    </Descriptions.Item>
                    <Descriptions.Item label="Email Subscribed">
                      {this.props.isSubscribed ? 'Yes' : <button onClick={() => this.handleSubscribe()} className="btn client-login px-3">Subscribe</button>}
                    </Descriptions.Item>
                  </Descriptions>
                </div>
              </div>
            </div>
            <div className="row m-5">
            </div>
          </div>
        </div>
      );
    }
  }
}
const mapStateToProps = state => {
  return {
    loginLoading: state.loginReducer.loading,
    configurationLoading: state.clientConfigurationReducer.loading,
    quoteLoading: state.clientQuoteReducer.loading,
    orderLoading: state.clientOrderReducer.loading,
    user: state.loginReducer.user.user,
    userRole: state.loginReducer.user.role,
    isSubscribed: state.loginReducer.user.user.is_subscribed,
    user_id: state.loginReducer.user.user.id,
    configurations: state.clientConfigurationReducer.configurations,
    orders: state.clientOrderReducer.orders,
    quotes: state.clientQuoteReducer.quotes,
    is_support: state.loginReducer.user.is_support
  };
};

export default connect(mapStateToProps, {
  show_user_configurations,
  show_quotes,
  show_orders,
  getUser
})(Profile);
