import specificationActionTypes from "./actionTypes";

function clean_message() {
  return {
    type: specificationActionTypes.CLEAN_MESSAGE_REQUEST,
    loading: true
  };
}

function show_all_specification_for_machine_category(params) {
  return {
    type: specificationActionTypes.SHOW_SPECIFICATION_FOR_CATEGORY_REQUEST,
    loading: true,
    params: params
  };
}

function show_single_specification(params) {
  return {
    type: specificationActionTypes.SHOW_SINGLE_SPECIFICATION_REQUEST,
    loading: true,
    params: params
  };
}

function add_specification_for_machine_category(params) {
  return {
    type: specificationActionTypes.ADD_SPECIFICATION_FOR_CATEGORY_REQUEST,
    loading: true,
    params: params
  };
}

function edit_specification_for_machine_category(params) {
  return {
    type: specificationActionTypes.UPDATE_SPECIFICATION_FOR_CATEGORY_REQUEST,
    loading: true,
    params: params
  };
}

function delete_specification_for_machine_category(params) {
  return {
    type: specificationActionTypes.DELETE_SPECIFICATION_FOR_CATEGORY_REQUEST,
    loading: true,
    params: params
  };
}

export {
  clean_message,
  show_all_specification_for_machine_category,
  show_single_specification,
  add_specification_for_machine_category,
  edit_specification_for_machine_category,
  delete_specification_for_machine_category
};
