const yearActionTypes = {
  SHOW_YEAR_REQUEST: "show.year.request",
  SHOW_YEAR_SUCCESS: "show.year.success",
  SHOW_YEAR_FALIURE: "show.year.faliure",

  SHOW_SINGLE_YEAR_REQUEST: "show.single.year.request",
  SHOW_SINGLE_YEAR_SUCCESS: "show.single.year.success",
  SHOW_SINGLE_YEAR_FALIURE: "show.single.year.faliure",

  ADD_YEAR_REQUEST: "add.year.request",
  ADD_YEAR_SUCCESS: "add.year.success",
  ADD_YEAR_FALIURE: "add.year.faliure",

  EDIT_YEAR_REQUEST: "edit.year.request",
  EDIT_YEAR_SUCCESS: "edit.year.success",
  EDIT_YEAR_FALIURE: "edit.year.faliure",

  DELETE_YEAR_REQUEST: "delete.year.request",
  DELETE_YEAR_SUCCESS: "delete.year.success",
  DELETE_YEAR_FALIURE: "delete.year.faliure",

  CLEAN_MESSAGE_REQUEST: "clean.message.request",
  CLEAN_MESSAGE_SUCCESS: "clean.message.success",
  CLEAN_MESSAGE_FALIURE: "clean.message.faliure"
};
export default yearActionTypes;
