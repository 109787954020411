import React, { Component } from "react";
import { connect } from "react-redux";
import { RoleManagementService } from "./../service";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Api } from "../../../config/api";

class UpdateCategoriesForRole extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			categories: false,
			current_roles: false
		};
		this.onDiscountEditable = this.onDiscountEditable.bind(this);
	}

	onDiscountEditable(i) {
		let categories = this.state.categories;

		categories[i].max_discount_editable = true;

		this.setState({ categories: categories });
	}

	onDiscountChange(i, value) {
		let categories = this.state.categories;

		if (!isNaN(value) && value <= 100) {
			categories[i].max_discount_category = value;
		}

		this.setState({ categories: categories });
	}

	onDiscountSubmit(i) {
		let that = this;
		let categories = this.state.categories;
		let { id } = this.props.match.params;
		let machine_category_id = categories[i].machine_category_id;
		let params = {
			max_discount_category: categories[i].max_discount_category
		};


		Api.post(
			"machine-category/updateMachineCategoryMaxDiscountByRole/" +
				id +
				"/" +
				machine_category_id,
			params
		)
			.then(function(response) {
				categories[i].max_discount_editable = false;

				that.setState({ categories: categories });
			})
			.catch(function(response) {});
	}

	async componentDidMount() {
		let { id } = this.props.match.params;

		let categories = await RoleManagementService.getCategoriesForRole(id);

		let current_roles = await RoleManagementService.getCurrentRoles(id);


		await this.setState({ current_roles: current_roles[0] });

		await this.setState({ categories: categories });

		await this.setState({ loading: false });
	}

	render() {
		if (!this.props.loading && !this.state.loading) {
			return (
				<div>
					<div
						className="content"
						style={{
							minHeight: 500
						}}
					>
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-header">
										<strong className="card-title">
											Show Available Categories For Role
											{this.state.current_roles
												? " [" +
												  this.state.current_roles.role_name +
												  "]"
												: null}
										</strong>
									</div>
									<div className="card-body">
										<div className="card-content">
											<div className="todo-list">
												<div className="tdl-holder">
													<div className="tdl-content">
														<ul>
															{this.state.categories
																? this.state.categories.map(
																		(category, i) =>
																			category.is_selectable ? (
																				<li key={i}>
																					<label
																						style={{
																							color:
																								"black"
																						}}
																					>
																						<div className="row">
																							<div className="col-sm-9">
																								{
																									category.machine_category
																								}
																							</div>
																							<div className="col-sm-3 float-right">
																								{category.max_discount_editable ? (
																									<>
																										<input
																											value={
																												category.max_discount_category
																											}
																											onChange={e =>
																												this.onDiscountChange(
																													i,
																													e
																														.target
																														.value
																												)
																											}
																										/>
																										<i
																											onClick={() =>
																												this.onDiscountSubmit(
																													i
																												)
																											}
																											className="ml-2 fas fa-check"
																										></i>
																									</>
																								) : (
																									<>
																										{
																											category.max_discount_category
																										}
																										<i
																											onClick={() =>
																												this.onDiscountEditable(
																													i
																												)
																											}
																											className="ml-2 fas fa-edit"
																										></i>
																									</>
																								)}
																								{/* <Link
																								to={
																									"/update-discount-for-category-by-role/" +
																									this
																										.props
																										.match
																										.params
																										.id +
																									"/" +
																									category.id
																								}
																							>
																								<i className="fa text-dark fa-pencil"></i>
																							</Link> */}
																							</div>
																						</div>
																					</label>
																				</li>
																			) : null
																  )
																: null}
														</ul>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div>
					<div
						className="content"
						style={{
							minHeight: 500
						}}
					>
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-header">
										<strong className="card-title">
											Show Available Categories For Role
										</strong>
									</div>
									<div className="card-body">
										<div className="card-content">
											<div className="todo-list">
												<div className="tdl-holder">
													<div className="tdl-content">
														<div className="row">
															<div className="col"></div>
															<div className="col">
																<div className="text-center">
																	<Loader
																		type="Rings"
																		color="#00BFFF"
																		height={100}
																		width={100}
																	/>
																	<div className="text-center client-roboto-condensed">
																		LOADING...
																	</div>
																</div>
															</div>
															<div className="col"></div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}
}
const mapStateToProps = state => {
	return {};
};

export default connect(mapStateToProps, {})(UpdateCategoriesForRole);
