import companyRatesActionTypes from "./actionTypes";

function show_company_rates() {
  return {
    type: companyRatesActionTypes.SHOW_COMPANY_RATES_REQUEST,
    loading: true
  };
}

function add_company_rates(params) {
  return {
    type: companyRatesActionTypes.ADD_COMPANY_RATES_REQUEST,
    loading: true,
    params: params
  };
}

function edit_company_rates(params) {
  return {
    type: companyRatesActionTypes.EDIT_COMPANY_RATES_REQUEST,
    loading: true,
    params: params
  };
}

function delete_company_rates(params) {
  return {
    type: companyRatesActionTypes.DELETE_COMPANY_RATES_REQUEST,
    loading: true,
    params: params
  };
}

export {
  show_company_rates,
  add_company_rates,
  edit_company_rates,
  delete_company_rates
};
