import React, { Component } from "react";
import { Tree } from "primereact/tree";
import { show_machine_categories, clean_message } from "./../action";
import { connect } from "react-redux";
import { MachineCategoryService } from "./../service";
import { Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { notification } from "antd";

class ListMachineCategories extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			machine_categories: [],
			tree: false,
			selectedNodeKey: ""
		};
		this.onDelete = this.onDelete.bind(this);
	}

	async onDelete(id) {
		let data = await MachineCategoryService.deleteMachineCategories(id);

		if (data.message.includes("success")) {
			notification.success({
				message: "Success!",
				description: data.message
			});
		} else {
			notification.error({
				message: "Error!",
				description: data.message
			});
		}

		let data1 = await MachineCategoryService.getTreeCategory(
			this.props.role_id
		);
		this.setState({ tree: data1 });
	}

	async componentDidMount() {
		let redirect = this;

		let data = await MachineCategoryService.getTreeSelectData();
		redirect.setState({ tree: data });

		if (
			redirect.props.message !== "" &&
			redirect.props.message !== undefined
		) {
			if (redirect.props.message.toLowerCase().includes("success")) {
				notification.success({
					message: "Success!",
					description: redirect.props.message
				});
			} else {
				notification.error({
					message: "Error!",
					description: redirect.props.message
				});
			}
		}

		await this.props.clean_message();
		this.setState({ loading: false });
	}

	render() {
		if (this.state.tree) {
			return (
				<div>
					<div
						className="content"
						style={{
							minHeight: 500
						}}
					>
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-header">
										<strong className="card-title">
											Machine Categories
										</strong>
									</div>
									<div className="card-body">
										<div className="card-content">
											<div className="todo-list">
												<div className="tdl-content">
													<div className="card">
														<div className="card-body">
															<div className="row">
																<div className="col text-left">
																	Edit Selected
																</div>
																<div className="col text-right">
																	{this.state
																		.selectedNodeKey ? (
																		<div>
																			<Link
																				to={
																					"/update-machine-category/" +
																					this.state
																						.selectedNodeKey
																				}
																			>
																				<i className="fa ml-1 mr-1 text-dark fa-pencil"></i>
																			</Link>
																			{/* <Link
                                        onClick={e =>
                                          this.onDelete(
                                            this.state.selectedNodeKey
                                          )
                                        }
                                      >
                                        <i className="fa ml-1 mr-1 text-dark fa-times"></i>
                                      </Link> */}
																		</div>
																	) : null}
																</div>
															</div>
														</div>
													</div>
													<Tree
														value={this.state.tree}
														selectionMode="single"
														selectionKeys={
															this.state.selectedNodeKey
														}
														onSelectionChange={e =>
															this.setState({
																selectedNodeKey: e.value
															})
														}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div>
					<div
						className="content"
						style={{
							minHeight: 500
						}}
					>
						<div className="row">
							<div className="col-sm-12">
								<div className="card col-sm-12">
									<div className="card-header">
										<strong className="card-title">
											Machine Categories
										</strong>
									</div>
									<div className="card-body">
										<div className="card-content">
											<div className="todo-list">
												<div className="tdl-holder">
													<div className="tdl-content">
														<div className="row">
															<div className="col"></div>
															<div className="col">
																<div className="text-center">
																	<Loader
																		type="Rings"
																		color="#00BFFF"
																		height={100}
																		width={100}
																	/>
																	<div className="text-center client-roboto-condensed">
																		LOADING...
																	</div>
																</div>
															</div>
															<div className="col"></div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}
}
const mapStateToProps = state => {
	return {
		machine_categories: state.showMachineCategoryReducer.machine_categories,
		message: state.showMachineCategoryReducer.message,
		role_id: state.loginReducer.user.role_id
	};
};

export default connect(mapStateToProps, {
	show_machine_categories,
	clean_message
})(ListMachineCategories);
