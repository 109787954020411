import { Api } from "./../../config/api";

// api call to show all brands
function showSequenceForMachineCategory(params) {
  let data = Api.get("sequence/" + params).then(function(response) {
    return response.data;
  });

  if (data) {
    return data;
  } else {
    return false;
  }
}

//api call to add a brand into the system
function addOrEditSequenceForMachineCategory(params) {
  let data = Api.post("sequence/" + params.machine_category_id, params).then(
    function(response) {
      return response.data;
    }
  );

  if (data) {
    return data;
  } else {
    return false;
  }
}

//api call to edit brand exisitng in the system
function editSequenceForMachineCategory(params) {
  let data = Api.post(
    "sequence/edit/" + params.machine_category_id,
    params
  ).then(function(response) {
    return response.data;
  });

  if (data) {
    return data;
  } else {
    return false;
  }
}

export const SequenceService = {
  showSequenceForMachineCategory,
  addOrEditSequenceForMachineCategory,
  editSequenceForMachineCategory
};
