import roleActionTypes from "./actionTypes";
import { RoleManagementService } from "./service";
import { take, call, put } from "redux-saga/effects";

function* getCostPriceVisibilityForRole() {
  while (true) {
    let {params} = yield take(roleActionTypes.GET_COST_PRICE_VISIBILITY_FOR_ROLE_REQUEST);


    const costPriceVisibility = yield call(RoleManagementService.getCurrentCostPriceVisibility, params);


    if (costPriceVisibility !== "" && costPriceVisibility !== null && costPriceVisibility && costPriceVisibility !== undefined) {
      yield put({
        type: roleActionTypes.GET_COST_PRICE_VISIBILITY_FOR_ROLE_SUCCESS,
        message: "Getting cost price visibility success",
        costPriceVisibility: costPriceVisibility
      });
    } else {
      yield put({
        type: roleActionTypes.GET_COST_PRICE_VISIBILITY_FOR_ROLE_FALIURE,
        message: "Getting cost price visibility faliure"
      });
    }
  }
}

export { getCostPriceVisibilityForRole };
