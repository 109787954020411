import React, { Component } from "react";
import { add_machine_part_data } from "./../action";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

class AddMachinePartData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      column_name: "",
      column_type: "",
      column_type_length: "",
      column_null: false
    };
    this.onValueChange = this.onValueChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(e) {
    e.preventDefault();
    let redirect = this;
    let params = {
      column_name: redirect.state.column_name,
      column_type: redirect.state.column_type,
      column_type_length: redirect.state.column_type_length,
      column_null: redirect.state.column_null
    };
    this.props.add_machine_part_data(params);
  }

  onValueChange(name, value) {
    let redirect = this;
    switch (name) {
      case "column_name":
        redirect.setState({ column_name: value });
        break;

      case "column_type":
        redirect.setState({ column_type: value });
        break;

      case "column_type_length":
        redirect.setState({ column_type_length: value });
        break;

      case "column_null":
        let sample = redirect.state.column_null;
        sample = sample ? false : true;
        redirect.setState({ column_null: sample });
        break;

      default:
        break;
    }
  }

  componentDidMount() {}

  render() {
    if (this.props.message.includes("Adding machine parts data success")) {
      return <Redirect to="/machine-part" />;
    } else {
      return (
        <div>
          <div
            className="content"
            style={{
              minHeight: 500
            }}
          >
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <strong className="card-title">
                      Add Machine Part Data
                    </strong>
                  </div>
                  <div className="card-body">
                    <form>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label
                              htmlFor="column_name"
                              className="control-label mb-1"
                            >
                              Column Name
                            </label>
                            <input
                              id="column_name"
                              name="column_name"
                              type="text"
                              className="form-control"
                              aria-required="true"
                              placeholder="E.g. machine_part_data"
                              onChange={e =>
                                this.onValueChange(
                                  "column_name",
                                  e.target.value
                                )
                              }
                              value={this.state.column_name}
                            />
                          </div>

                          <div className="form-group form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              onChange={e =>
                                this.onValueChange(
                                  "column_null",
                                  e.target.value
                                )
                              }
                              value={this.state.column_null}
                              id="defaultCheck1"
                            />
                            <label
                              htmlFor="country_code"
                              className="form-check-label"
                            >
                              Null or Not?
                            </label>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label
                              htmlFor="column_type"
                              className="control-label mb-1"
                            >
                              Column Type
                            </label>
                            <select
                              className="custom-select"
                              onChange={e =>
                                this.onValueChange(
                                  "column_type",
                                  e.target.value
                                )
                              }
                            >
                              <option>Select Data Type</option>
                              <option value="int">Integer</option>
                              <option value="float">
                                Floating point number
                              </option>
                              <option value="varchar">
                                Variable Character
                              </option>
                            </select>
                          </div>

                          <div className="form-group">
                            <label
                              htmlFor="column_type_length"
                              className="control-label mb-1"
                            >
                              Column Type Length
                            </label>
                            <input
                              id="column_type_length"
                              name="column_type_length"
                              type="text"
                              className="form-control"
                              placeholder="E.g. 11, 255"
                              aria-required="true"
                              onChange={e =>
                                this.onValueChange(
                                  "column_type_length",
                                  e.target.value
                                )
                              }
                              value={this.state.column_type_length}
                            />
                          </div>
                        </div>
                      </div>
                      <div>
                        <button
                          id="payment-button"
                          type="submit"
                          onClick={this.onSubmit}
                          className="btn btn-secondary btn-block"
                        >
                          <i className="fa fa-truck"></i>&nbsp;
                          <span id="payment-button-amount">
                            Add Machine Part Data
                          </span>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
const mapStateToProps = state => {
  return {
    message: state.showMachinePartReducer.message
  };
};

export default connect(mapStateToProps, { add_machine_part_data })(
  AddMachinePartData
);
