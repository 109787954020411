import React, { Component } from "react";
import { connect } from "react-redux";
import { ResidualPercentageService } from "./../service";
import { Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Swal from "sweetalert2";

class ListAllResidualPercentage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			residual_percentage: false
		};
		this.onDelete = this.onDelete.bind(this);
	}

	async onDelete(id) {
		let result = await Swal.fire({
			title: "Are you sure?",
			text: "Do you want to delete the residual percentage?",
			icon: "info",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!"
		}).then(result => {
			return result;
		});

		if (result.value) {
			if (this.state.residual_percentage.length >3) {
        let that = this;
        this.setState({ loading: true });
        ResidualPercentageService.deleteResidualPercentage(id);
  
        let residual_percentage = await ResidualPercentageService.showAllResudualPercentage();
  
        that.setState({ residual_percentage: residual_percentage });
  
        that.setState({ loading: false });
			}else{
        Swal.fire({
          title: "Information!",
          text: "There must at least be 3 residual percentage so for the lease calacuation!!",
          icon: "info",
          confirmButtonText: "OK"
        });
      }
		}
	}

	async componentDidMount() {
		let residual_percentage = await ResidualPercentageService.showAllResudualPercentage();

		let that = this;

		that.setState({ residual_percentage: residual_percentage });

		that.setState({ loading: false });

	}

	//function to trigger delete of certain brand in the list

	render() {
		if (!this.state.loading) {
			return (
				<div>
					<div
						className="content"
						style={{
							minHeight: 500
						}}
					>
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-header">
										<strong className="card-title">
											Residual Percentage
										</strong>
									</div>
									<div className="card-body">
										<div className="card-content">
											<div className="todo-list">
												<div className="tdl-holder">
													<div className="tdl-content">
														<ul>
															{this.state.residual_percentage
																? this.state.residual_percentage.map(
																		(
																			residual_percentage,
																			i
																		) => (
																			<li key={i}>
																				<label
																					style={{
																						color: "black"
																					}}
																				>
																					<div className="row">
																						<div className="col-sm-9">
																							{residual_percentage.year +
																								"-" +
																								residual_percentage.percentage}
																						</div>
																						<div className="col-sm-3">
																							<Link
																								onClick={e =>
																									this.onDelete(
																										residual_percentage.residual_id
																									)
																								}
																							>
																								<i className="fa text-dark fa-times"></i>
																							</Link>
																							<Link
																								to={
																									"/edit-residual-percentage/" +
																									residual_percentage.residual_id
																								}
																							>
																								<i className="fa text-dark fa-pencil"></i>
																							</Link>
																						</div>
																					</div>
																				</label>
																			</li>
																		)
																  )
																: null}
														</ul>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div>
					<div
						className="content"
						style={{
							minHeight: 500
						}}
					>
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-header">
										<strong className="card-title">
											Residual Percentage
										</strong>
									</div>
									<div className="card-body">
										<div className="card-content">
											<div className="todo-list">
												<div className="tdl-holder">
													<div className="tdl-content">
														<div className="row">
															<div className="col"></div>
															<div className="col">
																<div className="text-center">
																	<Loader
																		type="Rings"
																		color="#00BFFF"
																		height={100}
																		width={100}
																	/>
																	<div className="text-center client-roboto-condensed">
																		LOADING...
																	</div>
																</div>
															</div>
															<div className="col"></div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}
}
const mapStateToProps = state => {
	return {};
};

export default connect(mapStateToProps, {})(ListAllResidualPercentage);
