import clientQuoteActionTypes from "./actionTypes";

function save_first_form_data(params) {
  return {
    type: clientQuoteActionTypes.SAVE_QUOTE_FIRST_FORM_REQUEST,
    loading: true,
    params: params
  };
}

function save_second_form_data(params) {
  return {
    type: clientQuoteActionTypes.SAVE_QUOTE_SECOND_FORM_REQUEST,
    loading: true,
    params: params
  };
}

function save_third_form_data(params) {
  return {
    type: clientQuoteActionTypes.SAVE_QUOTE_THIRD_FORM_REQUEST,
    loading: true,
    params: params
  };
}

function show_quotes(user) {
  return {
    type: clientQuoteActionTypes.SHOW_QUOTES_REQUEST,
    loading: true,
    user: user
  };
}

function show_single_quotes(user, params, role) {
  return {
    type: clientQuoteActionTypes.SHOW_SINGLE_QUOTE_REQUEST,
    loading: true,
    user: user,
    params: params,
    role: role
  };
}

function create_quote(params) {
  return {
    type: clientQuoteActionTypes.CREATE_QUOTE_REQUEST,
    loading: true,
    params: params
  };
}

function edit_quote(id, params) {
  return {
    type: clientQuoteActionTypes.EDIT_QUOTE_REQUEST,
    loading: true,
    id: id,
    params: params
  };
}

function get_data_for_editing(params) {
  return {
    type: clientQuoteActionTypes.GET_DATA_FOR_EDITING_REQUEST,
    loading: true,
    params: params
  };
}

function order_quote(user_id, params) {
  return {
    type: clientQuoteActionTypes.ORDER_QUOTE_REQUEST,
    loading: true,
    user_id: user_id,
    params: params
  };
}

function download_docx(quote_id) {
  return {
    type: clientQuoteActionTypes.DOWNLOAD_DOCX_REQUEST,
    loading: true,
    quote_id: quote_id
  };
}

function download_xlsx(quote_id) {
  return {
    type: clientQuoteActionTypes.DOWNLOAD_XLSX_REQUEST,
    loading: true,
    quote_id: quote_id
  };
}

function clean_quote_message() {
  return {
    type: clientQuoteActionTypes.CLEAN_MESSAGE_REQUEST,
    loading: true
  };
}

function clean_form() {
  return {
    type: clientQuoteActionTypes.CLEAN_FORM_REQUEST,
    loading: true
  };
}

export {
  clean_form,
  clean_quote_message,
  save_first_form_data,
  download_docx,
  download_xlsx,
  order_quote,
  edit_quote,
  get_data_for_editing,
  save_second_form_data,
  save_third_form_data,
  show_quotes,
  show_single_quotes,
  create_quote
};
