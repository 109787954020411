import { Api } from "../../config/api";

function showKeyModelFeatures() {
	let data = Api.get("key-model-feature/keyModelFeature")
		.then(function(response) {
			return response.data;
		})
		.catch(function(error) {});

	if (data) {
		return data;
	} else {
		return false;
	}
}

function showSingleKeyModelFeatures(params) {
	let data = Api.get("key-model-feature/keyModelFeature/" + params)
		.then(function(response) {
			return response.data;
		})
		.catch(function(error) {});

	if (data) {
		return data;
	} else {
		return false;
	}
}

function addKeyModelFeature(params) {
	let data = Api.post("key-model-feature/keyModelFeature", params)
		.then(function(response) {
			return response.data;
		})
		.catch(function(error) {});

	if (data) {
		return data;
	} else {
		return false;
	}
}

function editKeyModelFeature(params) {
	let data = Api.put("key-model-feature/keyModelFeature/" + params.id, params)
		.then(function(response) {
			return response.data;
		})
		.catch(function(error) {});

	if (data) {
		return data;
	} else {
		return false;
	}
}

function deleteKeyModelFeature(params) {
	let data = Api.delete("key-model-feature/keyModelFeature/" + params)
		.then(function(response) {
			return response.data;
		})
		.catch(function(error) {});

	if (data) {
		return data;
	} else {
		return false;
	}
}

function getKeyModelFeaturesForId(params) {
	let data = Api.get("key-model-feature/getKeyModelFeaturesForId/" + params)
		.then(function(response) {
			return response.data;
		})
		.catch(function(error) {});

	if (data) {
		return data;
	} else {
		return false;
	}
}

function addKeyModelFeaturesToCategory(parts_array, machine_category_id) {
	let data = Api.post(
		"key-model-feature/addKeyModelFeature/" + machine_category_id,
		parts_array
	)
		.then(function(response) {
			return response.data;
		})
		.catch(function(error) {});

	if (data) {
		return data;
	} else {
		return false;
	}
}

export const KeyModelFeatureSetService = {
	showKeyModelFeatures,
	getKeyModelFeaturesForId,
	showSingleKeyModelFeatures,
	addKeyModelFeaturesToCategory,
	addKeyModelFeature,
	editKeyModelFeature,
	deleteKeyModelFeature
};
