import React, { Component } from "react";
import { connect } from "react-redux";
import { RoleManagementService } from "./../service";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Redirect } from "react-router";
import SimpleReactValidator from "simple-react-validator";

class UpdateCostPriceManagementForRole extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			role_id: "",
			visibility: 0,
			lease_editable: 0,
			lease_process_visible: 0,
			max_discount: 100,
			is_support: 0,
			redirect: false,
			current_roles: false,
			profit_margin_visible: 0,
			project_overview_visible: 0,
			higher_discount_request: 0,
			log_visibility: 0,
			order_form_visibility: 0,
			discount_request_visibility: 0,
			is_dealer: 0,
			sales_margin_visible: 0,
			customer_discount_visible: 0,
			extra_discount_visible: 0,
			extra_discount_visible_and_editable: 0,
			oem_editable: 0,
			extra_extra_discount_editable: 0,
			change_view: 0,
			cancel_order: 0,
			submit_order_form: 0,
			retail_margin: 0,
			is_manager: 0,
			need_approval: 0,
			show_brand: 0,
		};
		this.validator = new SimpleReactValidator({ autoForceUpdate: this });
		this.onTextChange = this.onTextChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	async onSubmit() {
		let { id } = this.props.match.params;
		let that = this;
		let params = {
			cost_price_visible: that.state.visibility,
			lease_value_editable: that.state.lease_editable,
			lease_process_visible: that.state.lease_process_visible,
			max_discount: that.state.max_discount,
			is_support: that.state.is_support,
			profit_margin_visible: that.state.profit_margin_visible,
			project_overview_visible: that.state.project_overview_visible,
			higher_discount_request: that.state.higher_discount_request,
			log_visibility: that.state.log_visibility,
			order_form_visibility: that.state.order_form_visibility,
			discount_request_visibility: that.state.discount_request_visibility,
			is_dealer: parseInt(that.state.is_dealer),
			sales_margin_visible: parseInt(that.state.sales_margin_visible),
			customer_discount_visible: parseInt(
				that.state.customer_discount_visible
			),
			extra_discount_visible: parseInt(that.state.extra_discount_visible),
			extra_discount_visible_and_editable: parseInt(
				that.state.extra_discount_visible_and_editable
			),
			oem_editable: parseInt(that.state.oem_editable),
			extra_extra_discount_editable: parseInt(
				that.state.extra_extra_discount_editable
			),
			change_view: parseInt(that.state.change_view),
			cancel_order: parseInt(that.state.cancel_order),
			submit_order_form: parseInt(that.state.submit_order_form),
			retail_margin: parseInt(that.state.retail_margin),
			is_manager: parseInt(that.state.is_manager),
			need_approval: parseInt(that.state.need_approval),
			show_brand: parseInt(that.state.show_brand)
		};

		if (this.validator.allValid()) {
			let checker = await RoleManagementService.updateCostPriceVisibilityForRole(
				id,
				params
			);

			if (
				checker.message.includes(
					"Cost price visibility updated according to role"
				)
			) {
				this.setState({ redirect: true });
			}
		} else {
			this.validator.showMessages();
		}
	}

	async onTextChange(name, value) {
		let redirect = this;
		switch (name) {
			case "visibility":
				if (
					value === 1 ||
					value === 0 ||
					value === "" ||
					value === "1" ||
					value === "0"
				) {
					redirect.setState({ visibility: value });
				}
				break;

			case "lease_editable":
				if (
					value === 1 ||
					value === 0 ||
					value === "" ||
					value === "1" ||
					value === "0"
				) {
					redirect.setState({ lease_editable: value });
				}
				break;

			case "lease_process_visible":
				if (
					value === 1 ||
					value === 0 ||
					value === "" ||
					value === "1" ||
					value === "0"
				) {
					redirect.setState({ lease_process_visible: value });
				}
				break;

			case "is_support":
				if (
					value === 1 ||
					value === 0 ||
					value === "" ||
					value === "1" ||
					value === "0"
				) {
					redirect.setState({ is_support: value });
				}
				break;

			case "profit_margin_visible":
				if (
					value === 1 ||
					value === 0 ||
					value === "" ||
					value === "1" ||
					value === "0"
				) {
					redirect.setState({ profit_margin_visible: value });
				}
				break;

			case "project_overview_visible":
				if (
					value === 1 ||
					value === 0 ||
					value === "" ||
					value === "1" ||
					value === "0"
				) {
					redirect.setState({ project_overview_visible: value });
				}
				break;

			case "higher_discount_request":
				if (
					value === 1 ||
					value === 0 ||
					value === "" ||
					value === "1" ||
					value === "0"
				) {
					redirect.setState({ higher_discount_request: value });
				}
				break;

			case "log_visibility":
				if (
					value === 1 ||
					value === 0 ||
					value === "" ||
					value === "1" ||
					value === "0"
				) {
					redirect.setState({ log_visibility: value });
				}
				break;

			case "order_form_visibility":
				if (
					value === 1 ||
					value === 0 ||
					value === "" ||
					value === "1" ||
					value === "0"
				) {
					redirect.setState({ order_form_visibility: value });
				}
				break;

			case "discount_request_visibility":
				if (
					value === 1 ||
					value === 0 ||
					value === "" ||
					value === "1" ||
					value === "0"
				) {
					redirect.setState({ discount_request_visibility: value });
				}
				break;

			case "is_dealer":
				if (
					value === 1 ||
					value === 0 ||
					value === "" ||
					value === "1" ||
					value === "0"
				) {
					redirect.setState({ is_dealer: value });
				}
				break;

			case "sales_margin_visible":
				if (
					value === 1 ||
					value === 0 ||
					value === "" ||
					value === "1" ||
					value === "0"
				) {
					redirect.setState({ sales_margin_visible: value });
				}
				break;

			case "customer_discount_visible":
				if (
					value === 1 ||
					value === 0 ||
					value === "" ||
					value === "1" ||
					value === "0"
				) {
					redirect.setState({ customer_discount_visible: value });
				}
				break;

			case "extra_discount_visible":
				if (
					value === 1 ||
					value === 0 ||
					value === "" ||
					value === "1" ||
					value === "0"
				) {
					redirect.setState({ extra_discount_visible: value });
				}
				break;

			case "extra_discount_visible_and_editable":
				if (
					value === 1 ||
					value === 0 ||
					value === "" ||
					value === "1" ||
					value === "0"
				) {
					redirect.setState({
						extra_discount_visible_and_editable: value
					});
				}
				break;

			case "oem_editable":
				if (
					value === 1 ||
					value === 0 ||
					value === "" ||
					value === "1" ||
					value === "0"
				) {
					redirect.setState({
						oem_editable: value
					});
				}
				break;

			case "extra_extra_discount_editable":
				if (
					value === 1 ||
					value === 0 ||
					value === "" ||
					value === "1" ||
					value === "0"
				) {
					redirect.setState({
						extra_extra_discount_editable: value
					});
				}
				break;

			case "change_view":
				if (
					value === 1 ||
					value === 0 ||
					value === "" ||
					value === "1" ||
					value === "0"
				) {
					redirect.setState({
						change_view: value
					});
				}
				break;

			case "cancel_order":
				if (
					value === 1 ||
					value === 0 ||
					value === "" ||
					value === "1" ||
					value === "0"
				) {
					redirect.setState({
						cancel_order: value
					});
				}
				break;

			case "submit_order_form":
				if (
					value === 1 ||
					value === 0 ||
					value === "" ||
					value === "1" ||
					value === "0"
				) {
					redirect.setState({
						submit_order_form: value
					});
				}
				break;

			case "retail_margin":
				if (
					value === 1 ||
					value === 0 ||
					value === "" ||
					value === "1" ||
					value === "0"
				) {
					redirect.setState({
						retail_margin: value
					});
				}
				break;


			case "is_manager":
				if (
					value === 1 ||
					value === 0 ||
					value === "" ||
					value === "1" ||
					value === "0"
				) {
					redirect.setState({
						is_manager: value
					});
				}
				break;


			case "need_approval":
				if (
					value === 1 ||
					value === 0 ||
					value === "" ||
					value === "1" ||
					value === "0"
				) {
					redirect.setState({
						need_approval: value
					});
				}
				break;
			
			case "show_brand":
				if (
					value === 1 ||
					value === 0 ||
					value === "" ||
					value === "1" ||
					value === "0"
				) {
					redirect.setState({ show_brand: value });
				}
				break;

			case "max_discount":
				redirect.setState({ max_discount: value });
				break;

			default:
				break;
		}
	}

	async componentDidMount() {
		let that = this;
		let { id } = this.props.match.params;
		let roles = await RoleManagementService.getCurrentCostPriceVisibility(id);


		if (roles.length !== 0) {
			if (
				roles[0].cost_price_visible !== "" &&
				roles[0].cost_price_visible !== null &&
				roles[0].cost_price_visible !== undefined
			) {
				await that.setState({ visibility: roles[0].cost_price_visible });
				await that.setState({
					lease_editable: roles[0].lease_value_editable
				});
				await that.setState({
					lease_process_visible: roles[0].lease_process_visible
				});
				await that.setState({
					max_discount: roles[0].max_discount
				});
				await that.setState({
					is_support: roles[0].is_support
				});
				await that.setState({
					profit_margin_visible: roles[0].profit_margin_visible
				});
				await that.setState({
					project_overview_visible: roles[0].project_overview_visible
				});
				await that.setState({
					higher_discount_request: roles[0].higher_discount_request
				});
				await that.setState({
					log_visibility: roles[0].log_visibility
				});
				await that.setState({
					order_form_visibility: roles[0].order_form_visibility
				});
				await that.setState({
					discount_request_visibility: roles[0].discount_request_visibility
				});
				await that.setState({
					is_dealer: roles[0].is_dealer
				});
				await that.setState({
					sales_margin_visible: roles[0].sales_margin_visible
				});
				await that.setState({
					customer_discount_visible: roles[0].customer_discount_visible
				});
				await that.setState({
					extra_discount_visible: roles[0].extra_discount_visible
				});
				await that.setState({
					extra_discount_visible_and_editable:
						roles[0].extra_discount_visible_and_editable
				});
				await that.setState({
					oem_editable: roles[0].oem_editable
				});
				await that.setState({
					extra_extra_discount_editable:
						roles[0].extra_extra_discount_editable
				});
				await that.setState({
					change_view: roles[0].change_view
				});
				await that.setState({
					cancel_order: roles[0].cancel_order
				});
				await that.setState({
					submit_order_form: roles[0].submit_order_form
				});
				await that.setState({
					retail_margin: roles[0].retail_margin
				});
				await that.setState({
					is_manager: roles[0].is_manager
				});
				await that.setState({
					need_approval: roles[0].need_approval
				});
				await that.setState({
					show_brand: roles[0].show_brand
				});
			}
		}

		let current_roles = await RoleManagementService.getCurrentRoles(id);

		await this.setState({ current_roles: current_roles[0] });

		await this.setState({ roles: roles });

		await this.setState({ loading: false });
	}

	render() {
		if (this.state.redirect) {
			return <Redirect to="/show-cost-price-management-for-role" />;
		} else {
			if (!this.props.loading && !this.state.loading) {
				return (
					<div>
						<div
							className="content"
							style={{
								minHeight: 500
							}}
						>
							<div className="row">
								<div className="col-sm-12">
									<div className="card">
										<div className="card-header">
											<strong className="card-title">
												Show Cost Price Visibility For Role
												{this.state.current_roles
													? " [" +
													this.state.current_roles.role_name +
													"]"
													: null}
											</strong>
										</div>
										<div className="card-body">
											<form>
												<div className="row">
													<div className="col-sm-6">
														<div className="form-group">
															<label
																htmlFor="machine_part_type"
																className="control-label mb-1"
															>
																Cost Price Visibility
															</label>
															<select
																className="custom-select"
																value={this.state.visibility}
																onChange={e =>
																	this.onTextChange(
																		"visibility",
																		e.target.value
																	)
																}
															>
																<option value={0}>No</option>
																<option value={1}>Yes</option>
															</select>
														</div>
													</div>
													<div className="col-sm-6">
														<div className="form-group">
															<label
																htmlFor="machine_part_type"
																className="text-center control-label mb-1"
															>
																Lease Price Editable
															</label>
															<select
																className="custom-select"
																value={
																	this.state.lease_editable
																}
																onChange={e =>
																	this.onTextChange(
																		"lease_editable",
																		e.target.value
																	)
																}
															>
																<option value={0}>No</option>
																<option value={1}>Yes</option>
															</select>
														</div>
													</div>
													<div className="col-sm-6">
														<div className="form-group">
															<label
																htmlFor="machine_part_type"
																className="text-center control-label mb-1"
															>
																Lease Process Visibility
															</label>
															<select
																className="custom-select"
																value={
																	this.state
																		.lease_process_visible
																}
																onChange={e =>
																	this.onTextChange(
																		"lease_process_visible",
																		e.target.value
																	)
																}
															>
																<option value={0}>No</option>
																<option value={1}>Yes</option>
															</select>
														</div>
													</div>
													<div className="col-sm-6">
														<div className="form-group">
															<label
																htmlFor="max_discount"
																className="control-label mb-1"
															>
																Maximum Discount
															</label>
															<input
																id="max_discount"
																name="max_discount"
																type="text"
																className="form-control"
																aria-required="true"
																onChange={e =>
																	this.onTextChange(
																		"max_discount",
																		e.target.value
																	)
																}
																value={this.state.max_discount}
															/>
														</div>
														<div
															className="mt-4"
															style={{
																color: "red"
															}}
														>
															{this.validator.message(
																"max_discount",
																this.state.max_discount,
																"required|numeric"
															)}
														</div>
													</div>
													<div className="col-sm-6">
														<div className="form-group">
															<label
																htmlFor="machine_part_type"
																className="text-center control-label mb-1"
															>
																Is Support?
															</label>
															<select
																className="custom-select"
																value={this.state.is_support}
																onChange={e =>
																	this.onTextChange(
																		"is_support",
																		e.target.value
																	)
																}
															>
																<option value={0}>No</option>
																<option value={1}>Yes</option>
															</select>
														</div>
													</div>
													<div className="col-sm-6">
														<div className="form-group">
															<label
																htmlFor="machine_part_type"
																className="text-center control-label mb-1"
															>
																Project Overview Visibility
															</label>
															<select
																className="custom-select"
																value={
																	this.state
																		.project_overview_visible
																}
																onChange={e =>
																	this.onTextChange(
																		"project_overview_visible",
																		e.target.value
																	)
																}
															>
																<option value={0}>No</option>
																<option value={1}>Yes</option>
															</select>
														</div>
													</div>
													<div className="col-sm-6">
														<div className="form-group">
															<label
																htmlFor="machine_part_type"
																className="text-center control-label mb-1"
															>
																Profit Margin Visibility
															</label>
															<select
																className="custom-select"
																value={
																	this.state
																		.profit_margin_visible
																}
																onChange={e =>
																	this.onTextChange(
																		"profit_margin_visible",
																		e.target.value
																	)
																}
															>
																<option value={0}>No</option>
																<option value={1}>Yes</option>
															</select>
														</div>
													</div>
													<div className="col-sm-6">
														<div className="form-group">
															<label
																htmlFor="machine_part_type"
																className="text-center control-label mb-1"
															>
																High Discount Request Permission
															</label>
															<select
																className="custom-select"
																value={
																	this.state
																		.higher_discount_request
																}
																onChange={e =>
																	this.onTextChange(
																		"higher_discount_request",
																		e.target.value
																	)
																}
															>
																<option value={0}>No</option>
																<option value={1}>Yes</option>
															</select>
														</div>
													</div>
													<div className="col-sm-6">
														<div className="form-group">
															<label
																htmlFor="discount_request_visibility"
																className="text-center control-label mb-1"
															>
																Discount Request Visibility
															</label>
															<select
																className="custom-select"
																value={
																	this.state
																		.discount_request_visibility
																}
																onChange={e =>
																	this.onTextChange(
																		"discount_request_visibility",
																		e.target.value
																	)
																}
															>
																<option value={0}>No</option>
																<option value={1}>Yes</option>
															</select>
														</div>
													</div>
													<div className="col-sm-6">
														<div className="form-group">
															<label
																htmlFor="machine_part_type"
																className="text-center control-label mb-1"
															>
																Log Visibility
															</label>
															<select
																className="custom-select"
																value={
																	this.state.log_visibility
																}
																onChange={e =>
																	this.onTextChange(
																		"log_visibility",
																		e.target.value
																	)
																}
															>
																<option value={0}>No</option>
																<option value={1}>Yes</option>
															</select>
														</div>
													</div>
													<div className="col-sm-6">
														<div className="form-group">
															<label
																htmlFor="machine_part_type"
																className="text-center control-label mb-1"
															>
																Order Form Visibility
															</label>
															<select
																className="custom-select"
																value={
																	this.state
																		.order_form_visibility
																}
																onChange={e =>
																	this.onTextChange(
																		"order_form_visibility",
																		e.target.value
																	)
																}
															>
																<option value={0}>No</option>
																<option value={1}>Yes</option>
															</select>
														</div>
													</div>
													<div className="col-sm-6">
														<div className="form-group">
															<label
																htmlFor="machine_part_type"
																className="text-center control-label mb-1"
															>
																Is Dealer?
															</label>
															<select
																className="custom-select"
																value={this.state.is_dealer}
																onChange={e =>
																	this.onTextChange(
																		"is_dealer",
																		e.target.value
																	)
																}
															>
																<option value={0}>No</option>
																<option value={1}>Yes</option>
															</select>
														</div>
													</div>
													<div className="col-sm-6">
														<div className="form-group">
															<label
																htmlFor="sales_margin_visible"
																className="text-center control-label mb-1"
															>
																Dealer Margin Visibility
															</label>
															<select
																className="custom-select"
																value={
																	this.state
																		.sales_margin_visible
																}
																onChange={e =>
																	this.onTextChange(
																		"sales_margin_visible",
																		e.target.value
																	)
																}
															>
																<option value={0}>No</option>
																<option value={1}>Yes</option>
															</select>
														</div>
													</div>
													<div className="col-sm-6">
														<div className="form-group">
															<label
																htmlFor="customer_discount_visible"
																className="text-center control-label mb-1"
															>
																Customer Discount Visibility
															</label>
															<select
																className="custom-select"
																value={
																	this.state
																		.customer_discount_visible
																}
																onChange={e =>
																	this.onTextChange(
																		"customer_discount_visible",
																		e.target.value
																	)
																}
															>
																<option value={0}>No</option>
																<option value={1}>Yes</option>
															</select>
														</div>
													</div>
													<div className="col-sm-6">
														<div className="form-group">
															<label
																htmlFor="extra_discount_visible"
																className="text-center control-label mb-1"
															>
																Extra Discount Visibility
															</label>
															<select
																className="custom-select"
																value={
																	this.state
																		.extra_discount_visible
																}
																onChange={e =>
																	this.onTextChange(
																		"extra_discount_visible",
																		e.target.value
																	)
																}
															>
																<option value={0}>No</option>
																<option value={1}>Yes</option>
															</select>
														</div>
													</div>
													<div className="col-sm-6">
														<div className="form-group">
															<label
																htmlFor="extra_discount_visible_and_editable"
																className="text-center control-label mb-1"
															>
																Extra Discount Visibility and
																Editability
															</label>
															<select
																className="custom-select"
																value={
																	this.state
																		.extra_discount_visible_and_editable
																}
																onChange={e =>
																	this.onTextChange(
																		"extra_discount_visible_and_editable",
																		e.target.value
																	)
																}
															>
																<option value={0}>No</option>
																<option value={1}>Yes</option>
															</select>
														</div>
													</div>
													<div className="col-sm-6">
														<div className="form-group">
															<label
																htmlFor="oem_editable"
																className="text-center control-label mb-1"
															>
																OEM Editability
															</label>
															<select
																className="custom-select"
																value={this.state.oem_editable}
																onChange={e =>
																	this.onTextChange(
																		"oem_editable",
																		e.target.value
																	)
																}
															>
																<option value={0}>No</option>
																<option value={1}>Yes</option>
															</select>
														</div>
													</div>
													<div className="col-sm-6">
														<div className="form-group">
															<label
																htmlFor="extra_extra_discount_editable"
																className="text-center control-label mb-1"
															>
																Extra Extra Discount Editability
															</label>
															<select
																className="custom-select"
																value={
																	this.state
																		.extra_extra_discount_editable
																}
																onChange={e =>
																	this.onTextChange(
																		"extra_extra_discount_editable",
																		e.target.value
																	)
																}
															>
																<option value={0}>No</option>
																<option value={1}>Yes</option>
															</select>
														</div>
													</div>
													<div className="col-sm-6">
														<div className="form-group">
															<label
																htmlFor="change_view"
																className="text-center control-label mb-1"
															>
																Change View Permission
															</label>
															<select
																className="custom-select"
																value={this.state.change_view}
																onChange={e =>
																	this.onTextChange(
																		"change_view",
																		e.target.value
																	)
																}
															>
																<option value={0}>No</option>
																<option value={1}>Yes</option>
															</select>
														</div>
													</div>
													<div className="col-sm-6">
														<div className="form-group">
															<label
																htmlFor="cancel_order"
																className="text-center control-label mb-1"
															>
																Can Cancel Order?
															</label>
															<select
																className="custom-select"
																value={this.state.cancel_order}
																onChange={e =>
																	this.onTextChange(
																		"cancel_order",
																		e.target.value
																	)
																}
															>
																<option value={0}>No</option>
																<option value={1}>Yes</option>
															</select>
														</div>
													</div>
													<div className="col-sm-6">
														<div className="form-group">
															<label
																htmlFor="cancel_order"
																className="text-center control-label mb-1"
															>
																Can Submit Order Form?
															</label>
															<select
																className="custom-select"
																value={
																	this.state.submit_order_form
																}
																onChange={e =>
																	this.onTextChange(
																		"submit_order_form",
																		e.target.value
																	)
																}
															>
																<option value={0}>No</option>
																<option value={1}>Yes</option>
															</select>
														</div>
													</div>
													<div className="col-sm-6">
														<div className="form-group">
															<label
																htmlFor="cancel_order"
																className="text-center control-label mb-1"
															>
																Can see retail margin?
															</label>
															<select
																className="custom-select"
																value={this.state.retail_margin}
																onChange={e =>
																	this.onTextChange(
																		"retail_margin",
																		e.target.value
																	)
																}
															>
																<option value={0}>No</option>
																<option value={1}>Yes</option>
															</select>
														</div>
													</div>
													<div className="col-sm-6">
														<div className="form-group">
															<label
																htmlFor="cancel_order"
																className="text-center control-label mb-1"
															>
																Is Manager?
															</label>
															<select
																className="custom-select"
																value={this.state.is_manager}
																onChange={e =>
																	this.onTextChange(
																		"is_manager",
																		e.target.value
																	)
																}
															>
																<option value={0}>No</option>
																<option value={1}>Yes</option>
															</select>
														</div>
													</div>
													<div className="col-sm-6">
														<div className="form-group">
															<label
																htmlFor="need_approval"
																className="text-center control-label mb-1"
															>
																Needs Approval?
															</label>
															<select
																className="custom-select"
																value={this.state.need_approval}
																onChange={e =>
																	this.onTextChange(
																		"need_approval",
																		e.target.value
																	)
																}
															>
																<option value={0}>No</option>
																<option value={1}>Yes</option>
															</select>
														</div>
													</div>
													<div className="col-sm-6">
														<div className="form-group">
															<label
																htmlFor="cancel_order"
																className="text-center control-label mb-1"
															>
																Show Brand in Quotes ?
															</label>
															<select
																className="custom-select"
																value={
																	this.state.show_brand
																}
																onChange={e =>
																	this.onTextChange(
																		"show_brand",
																		e.target.value
																	)
																}
															>
																<option value={0}>No</option>
																<option value={1}>Yes</option>
															</select>
														</div>
													</div>
												</div>
											</form>

											<div className="mt-3 text-center">
												<button
													onClick={this.onSubmit}
													className="btn btn-secondary"
													style={{
														borderRadius: "10em"
													}}
												>
													Confirm Cost Price Visibility For Role
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				);
			} else {
				return (
					<div>
						<div
							className="content"
							style={{
								minHeight: 500
							}}
						>
							<div className="row">
								<div className="col-sm-12">
									<div className="card">
										<div className="card-header">
											<strong className="card-title">
												Show Cost Price Visibility For Role
											</strong>
										</div>
										<div className="card-body">
											<div className="card-content">
												<div className="todo-list">
													<div className="tdl-holder">
														<div className="tdl-content">
															<div className="row">
																<div className="col"></div>
																<div className="col">
																	<div className="text-center">
																		<Loader
																			type="Rings"
																			color="#00BFFF"
																			height={100}
																			width={100}
																		/>
																		<div className="text-center client-roboto-condensed">
																			LOADING...
																		</div>
																	</div>
																</div>
																<div className="col"></div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				);
			}
		}
	}
}
const mapStateToProps = state => {
	return {};
};

export default connect(mapStateToProps, {})(UpdateCostPriceManagementForRole);
