import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "./../login/action";
import logo from "./../CPQ/logo.png";

class AdminSidebar extends Component {
	constructor(props) {
		super(props);
		this.logout = this.logout.bind(this);
	}

	logout(e) {
		e.preventDefault();
		this.props.logout(this.props.token);
	}

	componentDidMount() {}

	render() {
		return (
			<>
				<div id="show-sidebar" className="btn btn-sm btn-dark">
					<i className="fas fa-bars"></i>
				</div>
				<nav id="sidebar" className="sidebar-wrapper">
					<div className="sidebar-content">
						<div className="sidebar-brand text-white">
							<Link to="/">
								<img
									loading="lazy"
									className="img-responsive img-rounded"
									src={logo}
									alt="User"
									height={40}
								/>
							</Link>
							<div id="close-sidebar">
								<i className="fas fa-times"></i>
							</div>
						</div>
						<div className="sidebar-header">
							<div className="user-pic">
								<img
									loading="lazy"
									className="img-responsive img-rounded"
									src="https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png"
									alt="User"
								/>
							</div>
							<div className="user-info">
								<span className="user-name">
									<strong>{this.props.user.name}</strong>
								</span>
								<span className="user-role">
									{this.props.role.role}
								</span>
								<span className="user-status">
									<i className="fa fa-circle"></i>
									<span>Online</span>
								</span>
							</div>
						</div>
						<div className="sidebar-menu">
							<ul>
								<li className="header-menu">
									<span>System Data</span>
								</li>
								<li className="sidebar-dropdown">
									<Link>
										<i className="fa fa-tachometer"></i>
										<span>Company Data</span>
									</Link>
									<div className="sidebar-submenu">
										<ul>
											<li>
												<Link to="/company-rates">List Rates</Link>
											</li>
											<li>
												<Link to="/add-company-rate">Add Rate</Link>
											</li>
										</ul>
									</div>
								</li>
								<li className="sidebar-dropdown">
									<Link href="#">
										<i className="fas fa-user-tag"></i>
										<span>Templates</span>
									</Link>
									<div className="sidebar-submenu">
										<ul>
											<li>
												<Link to="/template">List Templates</Link>
											</li>
											<li>
												<Link to="/add-template">Add Template</Link>
											</li>
										</ul>
									</div>
								</li>
								<li className="sidebar-dropdown">
									<Link href="#">
										<i className="fas fa-user-tag"></i>
										<span>Role Management</span>
									</Link>
									<div className="sidebar-submenu">
										<ul>
											<li>
												<Link to="/show-template-for-role">
													List Template [for role]
												</Link>
											</li>
											<li>
												<Link to="/show-template-update-for-role">
													Update Template [for role]
												</Link>
											</li>
											<li>
												<Link to="/show-cost-price-management-for-role">
													Cost Price Role Management
												</Link>
											</li>
											<li>
												<Link to="/show-categories-for-role">
													List Categories [for role]
												</Link>
											</li>
											<li>
												<Link to="/show-categories-update-for-role">
													Update Categories [for role]
												</Link>
											</li>
											<li>
												<Link to="/show-policy-document-update-for-role">
													Update Policy Document [for role]
												</Link>
											</li>
											<li>
												<Link to="/show-bulletin-update-for-role">
													Update Bulletin [for role]
												</Link>
											</li>
										</ul>
									</div>
								</li>
								<li className="sidebar-dropdown">
									<Link href="#">
										<i className="fa fa-user"></i>
										<span>Users</span>
									</Link>
									<div className="sidebar-submenu">
										<ul>
											<li>
												<Link to="/register">Register</Link>
											</li>
											<li>
												<Link to="/users">Manage Users</Link>
											</li>
											<li>
												<Link to="/register-admin">
													Register Admin
												</Link>
											</li>
										</ul>
									</div>
								</li>
								<li className="sidebar-dropdown">
									<Link href="#">
										<i className="fas fa-user-tag"></i>
										<span>User Role</span>
									</Link>
									<div className="sidebar-submenu">
										<ul>
											<li>
												<Link to="/user-role">List Roles</Link>
											</li>
											<li>
												<Link to="/create-user-role">Add Role</Link>
											</li>
										</ul>
									</div>
								</li>
								<li className="sidebar-dropdown">
									<Link href="#">
										<i className="fas fa-calendar"></i>
										<span>Updates</span>
									</Link>
									<div className="sidebar-submenu">
										<ul>
											<li>
												<Link to="/updates">List Updates</Link>
											</li>
											<li>
												<Link to="/add-update">Add Update</Link>
											</li>
										</ul>
									</div>
								</li>
								<li className="sidebar-dropdown">
									<Link href="#">
										<i className="fas fa-calendar"></i>
										<span>Year Data</span>
									</Link>
									<div className="sidebar-submenu">
										<ul>
											<li>
												<Link to="/year">List Years</Link>
											</li>
											<li>
												<Link to="/add-year">Add Year</Link>
											</li>
										</ul>
									</div>
								</li>
								<li className="sidebar-dropdown">
									<Link href="#">
										<i className="fas fa-calendar"></i>
										<span>Residual Percentage</span>
									</Link>
									<div className="sidebar-submenu">
										<ul>
											<li>
												<Link to="/list-all-residual-percnentage">
													List Residual Percentage
												</Link>
											</li>
											<li>
												<Link to="/add-residual-percentage">
													Add Residual Percentage
												</Link>
											</li>
										</ul>
									</div>
								</li>
								<li className="sidebar-dropdown">
									<Link href="#">
										<i className="fas fa-flag"></i>
										<span>Country</span>
									</Link>
									<div className="sidebar-submenu">
										<ul>
											<li>
												<Link to="/country">List Countries</Link>
											</li>
											<li>
												<Link to="/add-country">
													Add Country Data
												</Link>
											</li>
											<li>
												<Link to="/currency">List Currencies</Link>
											</li>
											<li>
												<Link to="/add-currency">
													Add Currency Data
												</Link>
											</li>
										</ul>
									</div>
								</li>
								<li className="sidebar-dropdown">
									<Link href="#">
										<i className="fas fa-copyright"></i>
										<span>Articles</span>
									</Link>
									<div className="sidebar-submenu">
										<ul>
											<li>
												<Link to="/article">List Articles</Link>
											</li>
											<li>
												<Link to="/add-article">Add Article</Link>
											</li>
										</ul>
									</div>
								</li>
								<li className="sidebar-dropdown">
									<Link href="#">
										<i className="fas fa-copyright"></i>
										<span>Brands</span>
									</Link>
									<div className="sidebar-submenu">
										<ul>
											<li>
												<Link to="/brand">List Brands</Link>
											</li>
											<li>
												<Link to="/add-brand">Add Brand</Link>
											</li>
										</ul>
									</div>
								</li>
								<li className="sidebar-dropdown">
									<Link href="#">
										<i className="fas fa-copyright"></i>
										<span>Sales Desk</span>
									</Link>
									<div className="sidebar-submenu">
										<ul>
											<li>
												<Link to="/sales-desk">
													List Sales Desk
												</Link>
											</li>
											<li>
												<Link to="/add-sales-desk">
													Add Sales Desk
												</Link>
											</li>
										</ul>
									</div>
								</li>
								<li className="sidebar-dropdown">
									<Link href="#">
										<i className="fas fa-copyright"></i>
										<span>Bulletin</span>
									</Link>
									<div className="sidebar-submenu">
										<ul>
											<li>
												<Link to="/bulletin">List Bulletins</Link>
											</li>
											<li>
												<Link to="/add-bulletin">Add Bulletin</Link>
											</li>
										</ul>
									</div>
								</li>
								<li className="sidebar-dropdown">
									<Link href="#">
										<i className="fas fa-copyright"></i>
										<span>Generic Reasons</span>
									</Link>
									<div className="sidebar-submenu">
										<ul>
											<li>
												<Link to="/generic-question">
													List Generic Reason
												</Link>
											</li>
											<li>
												<Link to="/add-generic-question">
													Add Generic Reason
												</Link>
											</li>
										</ul>
									</div>
								</li>
								<li className="sidebar-dropdown">
									<Link href="#">
										<i className="fas fa-copyright"></i>
										<span>Policy Documents</span>
									</Link>
									<div className="sidebar-submenu">
										<ul>
											<li>
												<Link to="/policy-documents">
													List Policy Documents
												</Link>
											</li>
											<li>
												<Link to="/add-policy-document">
													Add Policy Documents
												</Link>
											</li>
										</ul>
									</div>
								</li>
								<li className="header-menu">
									<span>Machine</span>
								</li>
								<li className="sidebar-dropdown">
									<Link href="#">
										<i className="fas fa-cubes"></i>
										<span>Machine Parts</span>
									</Link>
									<div className="sidebar-submenu">
										<ul>
											<li>
												<Link to="/machine-part-only">
													List Parts
												</Link>
											</li>
											<li>
												<Link to="/machine-part">
													List Parts [Price]
												</Link>
											</li>
											<li>
												<Link to="/create-machine-part">
													Add Parts
												</Link>
											</li>
											<li>
												<Link to="/spreadsheet">Download XLSX</Link>
											</li>
											<li>
												<Link to="/upload-spreadsheet">
													Upload Parts
												</Link>
											</li>
										</ul>
									</div>
								</li>
								<li className="sidebar-dropdown">
									<Link href="#">
										<i className="fas fa-layer-group"></i>
										<span>Machine Categories</span>
									</Link>
									<div className="sidebar-submenu">
										<ul>
											<li>
												<Link to="/machine-category">
													Show Categories
												</Link>
											</li>
											<li>
												<Link to="/machine-category-price">
													Show Categories [Price]
												</Link>
											</li>
											<li>
												<Link to="/create-machine-category">
													Add Category
												</Link>
											</li>
											<li>
												<Link to="/show-compatible-categories">
													Compatible Parts
												</Link>
											</li>
											<li>
												<Link to="/add-feature-category">
													Add Feature
												</Link>
											</li>
											<li>
												<Link to="/add-key-model-feature-category">
													Add Key Model Feature
												</Link>
											</li>
											<li>
												<Link to="/spreadsheet">
													Download XLSX Parts
												</Link>
											</li>
											<li>
												<Link to="/upload-category-with-parts">
													Upload Category
												</Link>
											</li>
											<li>
												<Link to="/upload-category-only">
													Upload Category Only
												</Link>
											</li>
										</ul>
									</div>
								</li>
								<li className="sidebar-dropdown">
									<Link href="#">
										<i className="fas fa-clipboard-list"></i>
										<span>Specifications</span>
									</Link>
									<div className="sidebar-submenu">
										<ul>
											<li>
												<Link to="/add-specification-category">
													Show Specifications
												</Link>
											</li>
											<li>
												<Link to="/add-specification">
													Add Specification
												</Link>
											</li>
										</ul>
									</div>
								</li>
								<li className="sidebar-dropdown">
									<Link href="#">
										<i className="fas fa-level-up-alt"></i>
										<span>Sequence</span>
									</Link>
									<div className="sidebar-submenu">
										<ul>
											<li>
												<Link to="/sequence">List Sequence</Link>
											</li>
										</ul>
									</div>
								</li>
								<li className="sidebar-dropdown">
									<Link href="#">
										<i className="fas fa-check-double"></i>
										<span>Features</span>
									</Link>
									<div className="sidebar-submenu">
										<ul>
											<li>
												<Link to="/features">List Features</Link>
											</li>
											<li>
												<Link to="/add-feature">Add Feature</Link>
											</li>
										</ul>
									</div>
								</li>
								<li className="sidebar-dropdown">
									<Link href="#">
										<i className="fas fa-check-double"></i>
										<span>Key Model Feature</span>
									</Link>
									<div className="sidebar-submenu">
										<ul>
											<li>
												<Link to="/key-model-feature">
													List Features
												</Link>
											</li>
											<li>
												<Link to="/add-key-model-feature">
													Add Feature
												</Link>
											</li>
										</ul>
									</div>
								</li>
								<li className="sidebar-dropdown">
									<Link href="#">
										<i className="fas fa-puzzle-piece"></i>
										<span>Compatibility</span>
									</Link>
									<div className="sidebar-submenu">
										<ul>
											<li>
												<Link to="/show-category-for-compatibility">
													List Categories
												</Link>
											</li>
										</ul>
									</div>
								</li>
								<li className="sidebar-dropdown">
									<Link href="#">
										<i className="fas fa-quote-right"></i>
										<span>User Quote</span>
									</Link>
									<div className="sidebar-submenu">
										<ul>
											<li>
												<Link to="/quote">Show User Quotes</Link>
											</li>
										</ul>
									</div>
								</li>
								<li className="header-menu">
									<span>System Support</span>
								</li>
								<li className="sidebar-dropdown">
									<Link href="#">
										<i className="fas fa-check-double"></i>
										<span>System Support</span>
									</Link>
									<div className="sidebar-submenu">
										<ul>
											<li>
												<Link to="/show-customer-feedbacks">
													List Feedbacks
												</Link>
											</li>
										</ul>
									</div>
								</li>
								<li className="sidebar-dropdown">
									<Link href="#">
										<i className="fas fa-check-double"></i>
										<span>Feedback type</span>
									</Link>
									<div className="sidebar-submenu">
										<ul>
											<li>
												<Link to="/list-customer-feedback-types">
													List feedback type
												</Link>
											</li>
											<li>
												<Link to="/add-customer-feedback-type">
													Add feedback type
												</Link>
											</li>
										</ul>
									</div>
								</li>
								<li className="sidebar-dropdown">
									<Link href="#">
										<i className="fas fa-check-double"></i>
										<span>Feedback severity</span>
									</Link>
									<div className="sidebar-submenu">
										<ul>
											<li>
												<Link to="/list-customer-feedback-severity">
													List feedback severity
												</Link>
											</li>
											<li>
												<Link to="/add-customer-feedback-severity">
													Add feedback severity
												</Link>
											</li>
										</ul>
									</div>
								</li>
								<li className="sidebar-dropdown">
									<Link href="#">
										<i className="fas fa-check-double"></i>
										<span>Feedback frequency</span>
									</Link>
									<div className="sidebar-submenu">
										<ul>
											<li>
												<Link to="/list-customer-feedback-frequency">
													List feedback frequency
												</Link>
											</li>
											<li>
												<Link to="/add-customer-feedback-frequency">
													Add feedback frequency
												</Link>
											</li>
										</ul>
									</div>
								</li>
								<li className="header-menu">
									<span>Backup</span>
								</li>
								<li className="sidebar-dropdown">
									<Link href="#">
										<i className="fas fa-database"></i>
										<span>Backup Files</span>
									</Link>
									<div className="sidebar-submenu">
										<ul>
											<li>
												<Link to="/backup-files">
													Show List Files
												</Link>
											</li>
										</ul>
									</div>
								</li>
							</ul>
						</div>
						{/* <!-- sidebar-menu  --> */}
					</div>
					{/* <!-- sidebar-content  --> */}
					<div className="sidebar-footer">
						<Link onClick={this.logout}>
							<i className="fa fa-power-off"></i>
						</Link>
					</div>
				</nav>
			</>
		);
	}
}

const mapStateToProps = state => {
	return {
		isAuthenticated: state.loginReducer.isAuthenticated,
		token: state.loginReducer.token,
		user: state.loginReducer.user.user,
		role: state.loginReducer.user
	};
};

export default connect(mapStateToProps, {
	logout
})(AdminSidebar);
