import React, { Component } from "react";
import { connect } from "react-redux";
import { RoleManagementService } from "./../service";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "primereact/resources/themes/nova-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { PickList } from "primereact/picklist";
import { Redirect } from "react-router";
import {BulletinService} from './../../bulletin/service'

class UpdateBulletinUpdateForRole extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			source: false,
			target: false,
			redirect: false,
			current_roles: false
		};
		this.onChange = this.onChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	async onChange(event) {
		await this.setState({ source: event.source, target: event.target });
	}


	pickListTemplate(templates) {
		return (
			<div className="p-clearfix">
				<div
					style={{
						fontSize: "14px",
						float: "right",
						margin: "15px 5px 0 0"
					}}
				>
					{templates.bulletin_name}
				</div>
			</div>
		);
	}

	async onSubmit(){
		let redirect = this
		let {id} = this.props.match.params

		let target = redirect.state.target;
		let parts_array = new FormData();
		parts_array.append("target", JSON.stringify(target));
		parts_array.append(
			"role_id", id
		);

		let checker = await BulletinService.addOrUpdateBulletinForRole(id, parts_array);

		if(checker.message.includes('Bulletin documents updated according to role')){
			this.setState({redirect: true})
		}

	}

	async componentDidMount() {
		let {id} = this.props.match.params

		let roles = await BulletinService.getCurrentBulletinForRole(id);

		let current_roles = await RoleManagementService.getCurrentRoles(id);

		await this.setState({source: roles.unselected_templates})
		await this.setState({target: roles.selected_templates})

		await this.setState({current_roles: current_roles[0]})

		await this.setState({ roles: roles });

		await this.setState({loading: false})
	}

	render() {
		if(this.state.redirect){
			return(
				<Redirect to="/show-bulletin-update-for-role" />
			)
		}else{
			if (!this.props.loading && !this.state.loading) {
				return (
					<div>
						<div
							className="content"
							style={{
								minHeight: 500
							}}
						>
							<div className="row">
								<div className="col-sm-12">
									<div className="card">
										<div className="card-header">
											<strong className="card-title">
												Update Bulletin For Role  
												{
													this.state.current_roles ?
														' ['+this.state.current_roles.role_name+']'
														:
														null
												}
											</strong>
										</div>
										<div className="card-body">
											<div className="card-content">
												<div className="todo-list">
													<div className="tdl-holder">
														<div className="tdl-content">
																<PickList
																	source={this.state.source}
																	target={this.state.target}
																	itemTemplate={this.pickListTemplate}
																	sourceHeader="Available Templates"
																	targetHeader="Selected Templates"
																	responsive={true}
																	showSourceControls={false}
																	showTargetControls={true}
																	sourceStyle={{
																		height: "400px"
																	}}
																	targetStyle={{
																		height: "400px"
																	}}
																	onChange={this.onChange}
																></PickList>
	
																
															<div className="mt-3 text-center">
																<button
																	onClick={this.onSubmit}
																	className="btn btn-secondary"
																	style={{
																		borderRadius: "10em"
																	}}
																>
																	Confirm Bulletin For Role
																</button>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				);
			} else {
				return (
					<div>
						<div
							className="content"
							style={{
								minHeight: 500
							}}
						>
							<div className="row">
								<div className="col-sm-12">
									<div className="card">
										<div className="card-header">
											<strong className="card-title">
												Update Bulletin For Role
											</strong>
										</div>
										<div className="card-body">
											<div className="card-content">
												<div className="todo-list">
													<div className="tdl-holder">
														<div className="tdl-content">
															<div className="row">
																<div className="col"></div>
																<div className="col">
																	<div className="text-center">
																		<Loader
																			type="Rings"
																			color="#00BFFF"
																			height={100}
																			width={100}
																		/>
																		<div className="text-center client-roboto-condensed">
																			LOADING...
																		</div>
																	</div>
																</div>
																<div className="col"></div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				);
			}

		}
	}
}
const mapStateToProps = state => {
	return {};
};

export default connect(mapStateToProps, {})(UpdateBulletinUpdateForRole);
