import Axios from "axios";
import { Api } from "./../../config/api";
import config from "./../../config/config";

function downloadXlsxFiles(params) {
  let data = Api.get("")
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {
      return false;
    });

  if (data) {
    return data;
  } else {
    return false;
  }
}


function uploadCategoryOnly(params) {
  let data = Axios.post(config.APP_URL + "/api/uploadCategoriesOnly", params)
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {
      return false;
    });

  if (data) {
    return data;
  } else {
    return false;
  }
}

function uploadPartsWithCategory(params) {
  let data = Axios.post(config.APP_URL + "/api/uploadCategoryWithCompatibleParts", params)
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {
      return false;
    });

  if (data) {
    return data;
  } else {
    return false;
  }
}

function uploadXlsxFile(params) {
  let data = Axios.post(config.APP_URL + "/api/uploadParts", params)
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {
      return false;
    });

  if (data) {
    return data;
  } else {
    return false;
  }
}

function downloadSpreadsheetForParts() {
  let data = Axios({
    url: config.APP_URL + "/api/downloadSpreadsheetForParts",
    method: "GET",
    responseType: "blob" // important
  })
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "machine_parts_upload.xlsx");
      document.body.appendChild(link);
      link.click();
      return "machinepartsupload.xlsx ready for download";
    })
    .catch(function(error) {
      return false;
    });

  if (data) {
    return data;
  } else {
    return false;
  }
}

function downloadSpreadsheetForType() {
  let data = Axios({
    url: config.APP_URL + "/api/downloadSpreadsheetForCategory",
    method: "GET",
    responseType: "blob" // important
  })
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "machinecategory_with_part_upload.xlsx");
      document.body.appendChild(link);
      link.click();
      return "machinepartsupload.xlsx ready for download";
    })
    .catch(function(error) {
      return false;
    });

  if (data) {
    return data;
  } else {
    return false;
  }
}

function downloadSpreadsheetForCategoryOnly(){
  let data = Axios({
    url: config.APP_URL + "/api/downloadSpreadsheetForCategoryOnly",
    method: "GET",
    responseType: "blob" // important
  })
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "machine_category_only.xlsx");
      document.body.appendChild(link);
      link.click();
      return "machine_category_only.xlsx ready for download";
    })
    .catch(function(error) {
      return false;
    });

  if (data) {
    return data;
  } else {
    return false;
  }
}

function generateSpreadsheetForMachineParts() {
  let data = Api.get("createAllMachineCategoryWithPartsSpreadSheet")
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {
      return false;
    });
  if (data) {
    return data;
  } else {
    return false;
  }
}

function generateSpreadsheetForType() {
  let data = Api.get("createNewSpreadsheet")
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {
      return false;
    });
  if (data) {
    return data;
  } else {
    return false;
  }
}

function generateSpreadsheetForCategoryOnly(){
  let data = Api.get("createMachineCategoryOnlySpreadsheet")
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {
      return false;
    });
  if (data) {
    return data;
  } else {
    return false;
  }
}

function getTypes() {
  let data = Api.get("getTypes")
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {
      return false;
    });

  if (data) {
    return data;
  } else {
    return false;
  }
}

export const SpreadsheetService = {
  uploadXlsxFile,
  downloadSpreadsheetForType,
  downloadSpreadsheetForCategoryOnly,
  generateSpreadsheetForType,
  getTypes,
  generateSpreadsheetForCategoryOnly,
  downloadSpreadsheetForParts,
  downloadXlsxFiles,
  uploadPartsWithCategory,
  uploadCategoryOnly,
  generateSpreadsheetForMachineParts
};
